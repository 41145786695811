import React, { useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getSaleServices, getServiceEmployee, getServiceGroupCalendar } from '../../../../../Adapters/Api/saleServices'
import Svgs from '../../../../../Assets/svgs'
import { OPENING_TIME, APPOINTMENT_TIME } from '../../../../../Constants/Data/OpeningHours'
import Dropwdown from '../../../Elements/Dropwdown'
import LoginInput from '../../../Elements/LoginInput'
import Popup from 'new-components/popup'
import { useLocation } from './../../../../../new-components/location/helper';
import { apiErrorMessage } from 'Constants/Data/Errors'
import useAppointmentErrorHandling from 'Hooks/useAppointmentErrorHandling'
import PromotionDisplayAppointmentCard from '../AddAppointment/DiscountPromotion/PromotionsDisplayAppointmentCard'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox'
// import { getServiceForCalendar } from 'Adapters/Api/services'
// import moment from "moment";
// import { getservice } from '../../../../../Adapters/Api/services'
// import DiscountPromotion from '../AddAppointment/DiscountPromotion';


const AddAppointmentCard = ({ addMissedOpportunity, submitButtonClick, action, serviceGroupArray, setSelServGroups, setNoPromotionAvailed, location_currency, time_range, onRemoveOffer, is_last_card, select_service_serviceGroups, appointmentData, addOffer, filterOffer, setAppointmentData, servicesRedux, setAvailOfferPopup, availOfferPopup, data, index, onUpdate, appointment_date, onRemove, onDelete, setPossibleError, possible_error, length, durationOption, appointmentServices, search, select, setselect, searchfunction, FilterDataGroup, selectedLocation, checkEmployeetiming, memberNotRequired, custom_padding, ...props }) => {
    const { locationName } = useLocation();

    const {
        service_future_error,
        booking_error,
        setBookingError
    } = useAppointmentErrorHandling({ ...data, appointment_date, appointment_time: data?.date_time, index });

    const state = useSelector(state => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const selectedCurrency = state?.utility?.currency?.location_currency;
    // const reduxServices = state?.saleService?.saleServices;

    const [serviceEmployee, setServiceEmployee] = useState([])
    const [SelectServices, setSelectServices] = useState(false)
    const [employees_updated, setEmployeeUpdated] = useState(false)
    const [filteredEmployees, setFilteredEmployees] = useState([])
    const [serviceEmployeeLoader, setServiceEmployeeLoader] = useState(false)

    const dispatch = useDispatch()



    // useEffect(() => {
    //     dispatch(getservice())
    //     if (reduxServices?.length === 0) {
    //         dispatch(getSaleServices())
    //     }
    // }, [])

    const handleServices = (id, name) => {
        setServiceEmployeeLoader(false)
        getServiceEmployee(id)
            .then((result) => {
                const employees = result?.response?.data;
                setServiceEmployee(employees)
            }).catch((error) => {
                toast.error(apiErrorMessage, { toastId: "toast" })
            })
    }
    useEffect(() => {
        if (serviceEmployee?.length > 0) {
            setServiceEmployeeLoader(true)
        }
    }, [serviceEmployee])


    //get duration of selected service
    const selectedServiceDurations = () => {
        let result = appointmentServices()?.map(itm => {
            let selected_serv = itm?.services?.find((srv) => srv?.id === data?.service)

            if (selected_serv) {
                return selected_serv?.priceservice
            } else {
                return false
            }
        })

        result = result.filter(itm => itm)
        return result;

    }
    selectedServiceDurations()


    //handle change
    const handleChange = (e, book = "", online_slot = "", additional_data = {}) => {

        let { name, value } = e.target

        if (name === "service") {
            setBookingError()
            dispatch({
                type: "REMOVE_HOURS_ERRORS",
                payload: { id: index }
            })
            onUpdate({ name: name, value: value }, {}, book, online_slot, additional_data)
        } else if (name === "is_favourite") {
            onUpdate({ name: name, value: e.target.checked })
        } else {
            onUpdate({ name: name, value: value })
        }
    }

    const removeAppointmentClick = () => {
        setBookingError()
        dispatch({
            type: "REMOVE_HOURS_ERRORS",
            payload: { id: index }
        })
        onRemove(index)
    }

    useEffect(() => {
        //employee for dropdown list
        //filter employees which are active
        const activeEmployeesFiltered = state.employee.employees
        //filter employee on the base of slected service and selected locations
        const selected_location_employee = activeEmployeesFiltered?.filter(empl => {
            if (serviceEmployee.includes(empl.id)) {
                return true
            }
            return false
        })
        // added level to employee of selected service 
        const returnData = selected_location_employee.map((ds) => {
            const result = ds?.services?.find((itm) => itm?.service === data?.service);
            return {
                ...ds,
                level: result?.level
            }
        })
        setFilteredEmployees(returnData)
        setTimeout(() => {
            setEmployeeUpdated(true)
        }, 500)
    }, [serviceEmployee, data?.service, selectedLocation, state.employee.employees])

    useEffect(() => {
        let res = state.service.services.find(itm => itm.id == data.service);
        handleServices(data.service, res?.name)
    }, [data.service])

    const removePromotions = () => {
        // console.log("removePromotions", appointmentData);
        if (appointmentData && appointmentData?.selected_promotion) {
            let data = { ...appointmentData }

            data.appointments = []

            delete data?.selected_promotion
            delete data?.selected_promotion_type
            delete data?.selected_promotion_id
            delete data?.is_promotion_availed
            setAppointmentData(data)
        }
        setNoPromotionAvailed(true)
        // Modify the serviceGroups by removing discount_price
        if (serviceGroupArray?.length > 0) {
            const updatedServiceGroups = serviceGroupArray?.map((serviceGroup) => {
                const updatedServices = serviceGroup?.services?.map((service) => {
                    const updatedPriceservice = service?.priceservice?.map((priceService) => {
                        // Use destructuring to omit the discount_price property
                        const { discount_price, ...rest } = priceService;
                        return rest;
                    });

                    return { ...service, priceservice: updatedPriceservice };
                });

                return { ...serviceGroup, services: updatedServices };
            });
            // Use the spread operator to update the state
            setSelServGroups(updatedServiceGroups);
        }

    }

    return (
        <>
            <div className='flex gap-6'>
                {!action &&
                    <div className='relative'>
                        {
                            index > 0 &&
                            <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2'></div>
                        }
                        <div className='flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 '>
                            <div className='h-[1.8rem] w-[1.8rem] rounded-full bg-primary flex items-center justify-center'>
                                <Svgs.Check />
                            </div>
                        </div>
                        <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2'></div>
                    </div>
                }
                <div className={`mt-[2rem] border px-4 py-3 bg-white rounded-md w-full flex flex-col gap-4 relative ${custom_padding ? custom_padding : ''}`}>
                    <div className='absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2'
                        onClick={() => {
                            removeAppointmentClick()
                        }}>
                        <Svgs.CirlceClose size='1.75rem' fill='#ED0F1C' />
                    </div>
                    {
                        is_last_card && appointmentData?.selected_promotion &&
                        <PromotionDisplayAppointmentCard data={appointmentData?.selected_promotion} onRemoveOffer={() => removePromotions()} servicesRedux={servicesRedux} />
                    }
                    <div className='grid lg:grid-cols-2 gap-4'>
                        <Dropwdown
                            name={"date_time"}
                            value={data && data?.date_time}
                            onChange={(e) => {
                                checkEmployeetiming({ ...data, date_time: e.target.value, appointment_date })
                                handleChange(e)
                            }}
                            required
                            options={time_range}
                            // options={
                            //     OPENING_TIME?.filter((tm, index) => {
                            //         let date = new Date();
                            //         let time_formate = moment(date).format('HH:mm:ss')
                            //         let short_date = date.toLocaleDateString();
                            //         let formate_current_date = moment(short_date).format('YYYY-MM-DD')
                            //         let app_date = moment(appointment_date).format('YYYY-MM-DD')
                            //         if ((app_date > formate_current_date)) {
                            //             return true
                            //         } else {
                            //             if ((app_date == formate_current_date) && (tm?.value > time_formate)) {
                            //                 return true
                            //             } else {
                            //                 return false
                            //             }
                            //         }
                            //     }).map((tm) => {
                            //         return {
                            //             label: tm?.label,
                            //             value: tm?.value,
                            //         }
                            //     })
                            // }
                            title={selectedLanguage?.name == "English" ? "Time" : translation[1728]?.value ? translation[1728]?.value : "Time"}
                            placeholder={selectedLanguage?.name == "English" ? "Select time" : translation[130]?.value ? translation[130]?.value : "Select time"}
                        />
                        <div>
                            <p className='mb-2 text-sm' >
                                {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}
                                <span class="text-red-600"> *</span></p>
                            <div className='inputdesign overflow-hidden overflow-x-auto' onClick={() => setSelectServices(!SelectServices)}>
                                <p className='!text-black placeholder'>
                                    {data.srv_name} ({data.srv_duration}, {selectedCurrency} {data.discount_price || data.discount_price == 0 ? data.discount_price : data.price})

                                </p>
                            </div>
                        </div>
                    </div>
                    {/* {(!isVailible && selecteMember?.full_name) && <div className='flex items-center gap-1'>
                        <Svgs.I />
                        <p className='text-xs text-[#555555]'>
                            {selecteMember?.full_name} isn’t available between {hr_and_mints.join(":")} and {hr >= 10 ? hr : "0" + hr} :{min >= 10 ? min : "0" + min},
                            but your team member can still book appointments for them.</p>
                    </div>} */}
                    <div className='grid lg:grid-cols-2 gap-4'>
                        <Dropwdown
                            required={true}
                            name={"duration"}
                            value={data.duration}
                            onChange={(e) => {
                                checkEmployeetiming({ ...data, duration: e.target.value, appointment_date })
                                handleChange(e)
                            }}
                            options={APPOINTMENT_TIME}
                            title={selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                            placeholder={selectedLanguage?.name == "English" ? "Select duration" : translation[115]?.value ? translation[115]?.value : "Select duration"}

                        />
                        <Dropwdown
                            name={"member"}
                            required={memberNotRequired ? false : true}
                            value={data.member}
                            onChange={(e) => {
                                checkEmployeetiming({ ...data, member: e.target.value, appointment_date })
                                handleChange(e)
                            }}
                            error={(submitButtonClick && !data.member && !memberNotRequired) ? true : false}
                            options={filteredEmployees?.sort(itm => itm.level == "Above_Averge" ? -1 : 1)?.map((empl, i) => {
                                return {
                                    label: empl.full_name,
                                    value: empl.id,
                                    otherData: {
                                        image: empl?.image,
                                        DataOne: empl.email,
                                        showImage: true,
                                        index: i
                                    },
                                    email: empl.email,
                                    mobile_number: empl.mobile_number
                                };
                            })}
                            filterOption={(option, inputValue) => {
                                const { mobile_number, label, email } = option?.data
                                const normalizedInput = inputValue ? inputValue?.toLowerCase() : '';
                                return label?.toLowerCase()?.includes(normalizedInput)
                                    || (mobile_number && mobile_number?.includes(normalizedInput))
                                    || (email && email?.includes(normalizedInput));
                            }}
                            title={selectedLanguage?.name == "English" ? "Team Member" : translation[116]?.value ? translation[116]?.value : "Team Member"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Team Member" : translation[117]?.value ? translation[117]?.value : "Select Team Member"}
                        />
                    </div>
                    {!addMissedOpportunity &&
                        (data && data?.message) && <div className='flex items-center gap-1'>
                            <Svgs.I />
                            <p className='text-xs text-[#555555]'>
                                {data?.message}</p>
                        </div>
                    }
                    {
                        ((filteredEmployees?.length == 0 && employees_updated && state.employee.employee_updated && !addMissedOpportunity && serviceEmployeeLoader) &&
                            <p className='text-xs text-[#eb3b3b]'>
                                {selectedLanguage?.name == "English" ? "No staff available for this service" : translation[116]?.value ? translation[116]?.value : "No staff available for this service"}.
                            </p>)
                    }
                    {(service_future_error && !addMissedOpportunity) && <div className='flex items-center gap-1'>
                        <Svgs.I />
                        <p className='text-xs text-[#eb3b3b]'>
                            {service_future_error}</p>
                    </div>}
                    {(booking_error && !addMissedOpportunity) && <div className='flex items-center gap-1'>
                        <Svgs.I />
                        <p className='text-xs text-[#eb3b3b]'>
                            {booking_error}</p>
                    </div>}
                    {!addMissedOpportunity &&
                        <div>
                            <CheckBox
                                name="is_favourite"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                label_Class={'!normal-case text-[#7B7B7B]'}
                                value={data.is_favourite}
                                checked={data.is_favourite}
                                label={selectedLanguage?.name == "English" ? "Request staff for this appointment" : translation[1555]?.value ? translation[1555]?.value : "Request staff for this appointment"}
                            />
                        </div>
                    }

                </div>
            </div>
            {SelectServices &&
                <Popup size='md' heading={selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"} handleClose={() => { setSelectServices(false) }}>

                    <div className="flex flex-col gap-4 max-h-[40vh]">
                        <LoginInput
                            title=''
                            placeholder={selectedLanguage?.name == "English" ? "Search service" : translation[1266]?.value ? translation[1266]?.value : "Search service"}
                            padding='py-1'
                            leftIcon={<Svgs.Search />}
                            value={search}
                            onChange={searchfunction}
                        />
                        {select_service_serviceGroups?.length > 0 &&
                            select_service_serviceGroups?.map((srv) => {
                                return (
                                    <div className='flex flex-col gap-2'>
                                        <h1 className='font-semibold text-[#101928]'>{srv?.name}</h1>
                                        {srv?.services?.map((itm) => {
                                            const filteredServices = itm?.priceservice?.filter(itm => itm?.currency_name?.toLowerCase() == location_currency?.toLowerCase())
                                            return (
                                                <>
                                                    {
                                                        filteredServices?.map((dt) => {
                                                            return (
                                                                <>
                                                                    <div
                                                                        className='flex items-center justify-between gap-3 border-b py-2 px-1 hover:bg-gray-50 rounded-md cursor-pointer'
                                                                        onClick={(e) => {
                                                                            handleChange({
                                                                                ...e,
                                                                                target: {
                                                                                    ...e.target,
                                                                                    name: "service",
                                                                                    value: itm.id
                                                                                }
                                                                            },
                                                                                itm?.client_can_book,
                                                                                itm?.slot_availible_for_online,
                                                                                {
                                                                                    srv_name: itm?.name,
                                                                                    price: dt.price,
                                                                                    discount_price: dt.discount_price,
                                                                                    srv_duration: dt?.duration.toLowerCase().replaceAll("_", " ")
                                                                                }

                                                                            )
                                                                            setSelectServices(!SelectServices)
                                                                        }
                                                                        }
                                                                    >
                                                                        <div className=''>
                                                                            <h1 className='text-[#101928]'>{itm?.name}</h1>
                                                                            <p className='text-xs text-[#949494]'>{dt?.duration.replaceAll("_", " ")}</p>
                                                                        </div>
                                                                        <div className="d-flex flex-col text-end">
                                                                            <p className="text-primary text-sm font-semibold">
                                                                                {selectedCurrency ? selectedCurrency : ""}{" "}
                                                                                {dt?.price}
                                                                            </p>
                                                                            {(dt?.discount_price || dt?.discount_price == 0) &&
                                                                                <p className="text-xs text-[#949494]">
                                                                                    Discount price : {selectedCurrency ? selectedCurrency : ""}{" "}
                                                                                    {dt?.discount_price}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </>
                                            )
                                        })}
                                    </div>

                                )
                            })}
                    </div>
                </Popup>
            }
        </>
    )
}


AddAppointmentCard.defaultProps = {
    data: {},
    index: undefined,
    onUpdate: () => { },
    onDelete: () => { },
}

const mapStateToProps = (state) => {
    return state

}

export default connect(mapStateToProps, null)(AddAppointmentCard)