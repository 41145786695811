import React from 'react'
import Popup from "Components/TenantApp/Elements/Popup";
// import useDetailCompaignPopup from './helper';
import StatusBtn from 'Components/TenantApp/Elements/StatusBtn';

const DetailConversion = ({ v, ViewDetails, setViewDetails }) => {

    // const {

    //     } = useDetailSegmentPopup

    return (
        <Popup
            size={"md"}
            heading={"ConversionTracker Details"}
            open={ViewDetails}
            close={setViewDetails}
        >
            <div className="space-y-5">
                <div className="cursor-pointer h-full flex items-center gap-3">
                    <div>
                        <div className="h-[2.5rem] w-[2.5rem] rounded-full"><img className="w-full h-full" src={v.img} alt="img" /></div>
                    </div>
                    <h5 className="text-[18px] font-semibold text-primary">{v.customer_name}</h5>
                </div>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-1">
                        <h5 className="text-[16px] font-semibold">Type</h5>
                        <p className="text-[14px] font-normal">Dynamic</p>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h5 className="text-[16px] font-semibold">Date & Time</h5>
                        <p className="text-[14px] font-normal">25 Oct to 2 Nov / 10:30PM</p>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h5 className="text-[16px] font-semibold">Compaingn Type</h5>
                        <StatusBtn
                            status={v.is_active ? "Active" : "Inactive"}
                            text={v.is_active ? "Email" : "SMS"}
                        />
                    </div>

                </div>
            </div>
        </Popup>
    )
}

export default DetailConversion