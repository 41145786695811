import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import StaffData from "./StaffData";
import StaffProdcut from "./StaffProdcut";
import StaffService from "./StaffService";
import StaffAssetes from "./StaffAssetes";
import LoginBtn from "../../../Elements/LoginBtn";

import Svgs from "Assets/svgs";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const Reports = () => {
  const navigate = useNavigate();
  const [ShowDetails, setShowDetails] = useState(0);

  const [ReportToggle, setReportToggle] = useState("Staff Data");
  const state = useSelector((state) => state);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
            </h2>
          </div>
          {loadingPermission ?
            <Shimmer className={'w-40 lg:w-44'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            :
            !employee ||
              (employee &&
                employeePermissions?.employee_reports?.includes("create")) ? (
              <div className="flex items-center sm:gap-4 gap-2">
                <LoginBtn
                  onClick={() => {
                    navigate("/dashboard/employee/employees/add-employee");
                  }}
                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "New Employee" : translation[423]?.value ? translation[423]?.value : "New Employee"}
                    </p>
                  </div>
                </LoginBtn>
              </div>
            ) : (
              ""
            )}
        </div>
        <div className="mb-[1.5rem]">
          <div className="border-b flex items-end gap-6 text-sm font-semibold">
            <p
              onClick={() => {
                setReportToggle("Staff Data");
              }}
              className={`border-b-4 ${ReportToggle == "Staff Data"
                ? "border-primary"
                : "border-transparent"
                } pb-4 w-fit cursor-pointer`}
            >
              {selectedLanguage?.name == "English" ? "Staff Data" : translation[592]?.value ? translation[592]?.value : "Staff Data"}
            </p>
            {/*
             <p
              onClick={() => {
                setReportToggle("Staff Prodcut Sales");
              }}
              className={`border-b-4 ${ReportToggle == "Staff Prodcut Sales"
                  ? "border-primary"
                  : "border-transparent"
                } pb-4 w-fit cursor-pointer`}
            >
              Staff Prodcut Sales
            </p>
            <p
              onClick={() => {
                setReportToggle("Staff Service Sales");
              }}
              className={`border-b-4 ${ReportToggle == "Staff Service Sales"
                  ? "border-primary"
                  : "border-transparent"
                } pb-4 w-fit cursor-pointer`}
            >
              Staff Service Sales
            </p> */}
            {/* <p
              onClick={() => {
                setReportToggle("Staff Assets");
              }}
              className={`border-b-4 ${
                ReportToggle == "Staff Assets"
                  ? "border-primary"
                  : "border-transparent"
              } pb-4 w-fit cursor-pointer`}
            >
              Staff Assets
            </p> */}
          </div>
        </div>
        {ReportToggle == "Staff Data" && <StaffData />}
        {ReportToggle == "Staff Prodcut Sales" && <StaffProdcut />}
        {ReportToggle == "Staff Service Sales" && <StaffService />}
        {ReportToggle == "Staff Assets" && <StaffAssetes />}
      </div>
    </>
  );
};

export default Reports;
