import { useState, useMemo } from "react";
import { useSelector } from "react-redux";

const useAppointmentByServiceGroupHelper = () => {
    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation;
    const selectedLanguage = state?.languages?.selected_language;
    const location_currency = state?.utility?.currency?.location_currency;
    const appointmentSummaryData = state?.saleReport?.appointmentSummaryData;
    const ServiceGroup = appointmentSummaryData?.service_groups;

    // states
    const [searchText, setSearchText] = useState("");

    // Debounced search filtering
    const filteredServiceGroup = useMemo(() => {
        const allData = ServiceGroup || []
        if (!searchText) return allData;
        return allData?.filter(srv =>
            srv?.group_name?.toLowerCase().includes(searchText?.toLowerCase())
        );
    }, [searchText, ServiceGroup]);

    return {
        translation, selectedLanguage, location_currency, searchText, setSearchText, filteredServiceGroup
    };
};

export default useAppointmentByServiceGroupHelper;
