import Svgs from 'Assets/svgs';
import { LogoSvg } from 'Assets/svgs/Logo/LogoSvg';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate()
    const footerSections = {
        'About NStyle': [
            { name: 'Blog', route: '/blogs' },
            { name: 'Contact us', route: '/contact' },
            { name: 'About us', route: '/about' },
        ],
        'For Business': [
            { name: 'Pricing', route: '/pricing' },
        ],
        'Legal': [
            { name: 'Privacy Policy', route: '/privacy-policy-v2' },
        ]
    };

    return (
        <footer className="bg-primary-black relative text-white pt-16 pb-4">
            <div className="max-w-7xl mx-auto relative z-10 px-4 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-4 gap-8">
                {/* Logo Section */}
                <div className="xs:col-span-2 sm:col-span-4">
                    <LogoSvg height="3.75rem" width="3.5rem" />
                </div>

                {/* Footer Sections */}
                {Object.entries(footerSections).map(([title, items]) => (
                    <div key={title} className="font-manrope">
                        <h3 className="font-bold text-xl mb-4">{title}</h3>
                        <ul className="space-y-2">
                            {items.map((item) => (
                                <li key={item}>
                                    <a onClick={() => navigate(item?.route)} className="text-white hover:underline cursor-pointer text-sm transition-colors">
                                        {item?.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}

                {/* Get in Touch Section */}
                <div className="">
                    <h3 className="font-semibold text-lg mb-4">Get in Touch</h3>
                    <div className="flex gap-2">
                        <a className="text-white p-2 cursor-pointer">
                            <Svgs.NewFBIcon />
                        </a>
                        <a className="text-white p-2 cursor-pointer">
                            <Svgs.NewXIcon fill={'#fff'} />
                        </a>
                        <a className="text-white p-px cursor-pointer">
                            <Svgs.NewLinkedInIcon className={'h-7 w-7'} />
                        </a>
                    </div>
                </div>

                <div className="sm:hidden flex gap-1.5 sm:flex-1 sm:justify-center">
                    <img src="/images/App-Store.png" alt="App Store" className="h-10" />
                    <img src="/images/Play-Store.png" alt="Google Play" className="h-10" />
                </div>
            </div>

            {/* App Store Buttons and Copyright */}
            <div className="max-w-7xl mx-auto relative z-10 px-4 mt-12 mb-4">
                <div className="flex flex-row justify-between items-center pt-8 border-t border-gray-800">
                    <div className="hidden sm:flex gap-1.5 sm:flex-1 sm:justify-center">
                        <img src="/images/App-Store.png" alt="App Store" className="h-10" />
                        <img src="/images/Play-Store.png" alt="Google Play" className="h-10" />
                    </div>
                    <p className="mx-auto sm:mx-0 text-gray-400 text-sm">
                        © {moment().format('YYYY')} NStyle All right reserved
                    </p>
                </div>
            </div>
            <div className="hidden absolute top-0 right-0 h-60 w-[25rem] sm:flex ">
                <img src="/images/footer-top.png" alt="" className='w-full h-full' />
            </div>
            <div className="hidden absolute bottom-0 left-0 h-[23.5rem] w-[10.5rem] sm:flex ">
                <img src="/images/footer-left.png" alt="" className='w-full h-full' />
            </div>
        </footer>
    );
};

export default Footer;