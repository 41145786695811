import { getsProducts } from "Adapters/Api/Products"
import { getSaleServices } from "Adapters/Api/saleServices"
import Svgs from "Assets/svgs"
import moment from "moment"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getProducts } from "Redux/TenantRedux/Actions/product"

const BuyRetailGetFreeServiceCard = ({ selected, data, onSelect, onRemoveOffer, cartData }) => {
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const product = data?.promotion?.filter((retail, index) => retail?.promotion_type == "Product")
    const brand = data?.promotion?.filter((retail, index) => retail?.promotion_type == "Brand")
    const filteredServices = cartData?.ids?.filter(itm => itm?.selection_type == 'SERVICE')

    return (
        <>
            {data?.promotion?.length > 0 &&
                <div
                    className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                        }  border   rounded-md p-2 mt-1`}
                >
                    <div className="flex items-center justify-between mb-2">
                        <p className="text-[#A1A1A1] font-bold p-2">Buy Retail & Get Service Free</p>
                        {onRemoveOffer && selected &&
                            <button
                                className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                                onClick={onRemoveOffer}
                            >
                                <Svgs.Delete fill="#767A87" />
                            </button>
                        }
                    </div>

                    <div className="px-2 text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

                    <div className="">
                        {
                            product?.length > 0 && (
                                <>
                                    <div className="flex items-center justify-between my-1 p-2">
                                        <p className="text-[#2a2a2a] font-bold">
                                            {selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}
                                        </p>
                                        <span className="text-[#2a2a2a] font-bold">
                                            {selectedLanguage?.name == "English" ? "Free Service" : translation[1062]?.value ? translation[1062]?.value : "Free Service"}
                                        </span>
                                    </div>
                                    {
                                        product
                                            ?.filter(prd => filteredServices?.some(srv => srv?.id === prd?.service?.id))
                                            ?.map((retail, index) => {
                                                if (retail?.promotion_type === "Product") {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`${selected ? "" : "border"} border-[#B8C6F5] cursor-pointer flex mb-2 items-center w-full justify-between p-2 rounded-md`}
                                                            onClick={() =>
                                                                onSelect &&
                                                                onSelect(
                                                                    {
                                                                        ...data,
                                                                        serviceId: retail?.service?.id,
                                                                        promotion: [retail]
                                                                    },
                                                                    retail?.promotion_type
                                                                )
                                                            }
                                                        >
                                                            <div className="flex items-center">
                                                                <p className="text-[#343434] font-medium line-clamp-1">{retail?.product?.name}</p>
                                                                <span className="whitespace-nowrap ml-1 text-sm text-gray-600">
                                                                    (AED {retail?.product?.location_based_retail_price})
                                                                </span>
                                                            </div>
                                                            <p>{retail?.service?.name}</p>
                                                        </div>
                                                    );
                                                }
                                                return null; // Ensures the function always returns something
                                            })
                                    }

                                </>
                            )
                        }
                        {brand.length > 0 && (
                            <>
                                <div className="flex items-center justify-between my-1 p-2">
                                    <p className="text-[#2a2a2a] font-bold">
                                        {selectedLanguage?.name == "English" ? "Brands" : translation[836]?.value ? translation[836]?.value : "Brands"}
                                    </p>
                                    <span className="text-[#2a2a2a] font-bold">
                                        {selectedLanguage?.name == "English" ? "Free Service" : translation[1062]?.value ? translation[1062]?.value : "Free Service"}
                                    </span>
                                </div>

                                {brand?.map((retail, index) => {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className={`${selected ? "" : "border"} border-[#B8C6F5] cursor-pointer flex mb-2 items-center w-full justify-between p-2 rounded-md`}
                                                onClick={() => onSelect &&
                                                    onSelect(
                                                        {
                                                            ...data,
                                                            promotion: [retail]
                                                        },
                                                        retail?.promotion_type
                                                    )}

                                            >
                                                <div className="flex items-center">
                                                    <p className="text-[#343434] font-medium line-clamp-1 ">{retail?.brand?.name}</p>
                                                    {/* <span className="whitespace-nowrap ml-1 text-sm text-gray-600">(AED {retail?.brand?.location_based_retail_price})</span> */}
                                                </div>
                                                <p>{retail?.service?.name}</p>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </>
                        )}
                    </div>
                    {/* Date duration */}
                    <div className="flex items-center justify-between w-full px-2 gap-2">
                        <div className="flex items-center gap-2">
                            <p className="text-[#A1A1A1] text-sm">
                                {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                            </p>
                            <p className="text-[#101928] font-semibold">
                                {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <p className="text-[#A1A1A1] text-sm">
                                {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                            </p>
                            <p className="text-[#101928] font-semibold">
                                {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

BuyRetailGetFreeServiceCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const BuyRetailGetFreeService = ({ retailPromotion, setProductsData, onSelect, cartData, setCartData }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const [retail_data, setRetailData] = useState([])

    const handle_select = (data, type) => {
        let new_items = []

        if (type === "Product") {
            handleProduct(data)
        }

        if (type === "Brand") {
            data?.promotion?.forEach(promotion => {
                if (promotion.promotion_type === "Brand") {
                    new_items?.push({
                        ...promotion.service,
                        selection_type: "SERVICE",
                        quantity: 1,
                        price: 0,
                        discount_price: undefined,
                        buy_retail_redemption: true
                    })
                }
            })

            handle_select_product(data)
        }

        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': new_items,
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
        toast.success("Items added to cart", { toastId: "toast" });
        onSelect(
            [],
            false,
        )
    }

    const handleProduct = (data) => {
        let total_discount_price = 0;
        setCartData(prev => {
            let updatedCart = prev && typeof prev === "object" ? { ...prev } : { ids: [] };
            updatedCart.ids = updatedCart.ids || [];

            // Update items in the cart
            updatedCart.ids = updatedCart.ids.map(item => {
                if (item?.selection_type === 'SERVICE' && data?.serviceId === item?.id) {
                    return {
                        ...item,
                        discount_price: 0,
                        discount_percentage: 100,
                        is_promotion: true,
                        is_promotion_availed: true
                    };
                }
                return item;
            });
            const services = updatedCart.ids?.filter(itm => itm?.is_promotion)
            // Calculate total discount price
            total_discount_price = services?.reduce((sum, item) => {
                return sum + (item?.price || 0);
            }, 0);

            return {
                ...updatedCart,
                selected_promotion_type: data?.type || null,
                selected_promotion_id: data?.id || null,
                is_promotion: true,
                is_promotion_availed: true,
                promotion_discount_sum: total_discount_price
            };
        });


        dispatch({
            type: "UPDATE_CART_DISCOUNTS",
            payload: {
                cart_items: state?.cartItem?.cart_items?.map(item => {
                    if (item?.selection_type == 'SERVICE' && data?.serviceId === item?.id) {
                        return {
                            ...item,
                            discount_price: 0,
                            discount_percentage: 100,
                            is_promotion: true,
                            is_promotion_availed: true
                        };
                    }
                    return item;
                }),
                is_promotion: true,
                selected_promotion_type: data?.type || null,
                selected_promotion_id: data?.id || null,
                is_promotion_availed: true,
                promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
            }
        });

        onSelect?.()
    }

    const handle_select_product = (data) => {
        let services_disc = data?.promotion?.find(itm => itm)
        if (!services_disc) {
            return
        }

        setProductsData(prev => {
            let this_brand_products = prev.filter(obj => obj.brand.id == services_disc?.brand?.id)
            return this_brand_products
        })

    }

    const get_products = async () => {
        const response = await getsProducts();

        if (response.status === 200) {
            let productList = response?.data?.response?.products;
            dispatch(getProducts(productList));
        }
    };

    useEffect(() => {
        if (!state.product.products_updated) {
            get_products()
            return
        }

        if (!state.saleService?.saleServices_updated) {
            dispatch(getSaleServices());
            return
        }
        let retails = [
            ...retailPromotion
        ]


        let selected_location = state.locations.selected_location
        selected_location = state.locations.locations?.find(itm => itm.id == selected_location)
        let selected_currency_id = undefined

        if (selected_location && selected_location.currency) {
            selected_currency_id = selected_location.currency.id
        }

        retails.forEach(itm => {
            itm.promotion?.forEach(retail => {
                const filteredServices = cartData?.ids?.filter(itm => itm?.selection_type == 'SERVICE')

                let this_service = filteredServices?.find(srv => srv.id == retail.service)
                if (this_service) {
                    retail.service = this_service
                } else {
                    retail.service = undefined
                }

                let this_product = state.product.products?.find(prod => prod.id == retail.product)
                if (this_product) {
                    let retail_price = this_product?.currency_retail_price?.find(rt_price => rt_price.currency == selected_currency_id)
                    retail.product = this_product
                    if (retail_price && retail_price?.retail_price) {
                        retail.product.location_based_retail_price = retail_price?.retail_price
                    }
                    else {
                        retail.product.location_based_retail_price = 0
                    }
                }
            })
        })
        const filteredRetailData = retails?.map(item => ({
            ...item,
            promotion: item?.promotion?.filter(promo => promo?.service?.id)
        })).filter(item => item?.promotion?.length > 0);

        setRetailData(filteredRetailData, "filteredRetailData")

    }, [state.product.products_updated, state.saleService?.saleServices_updated])

    return (
        <>
            {
                retail_data?.length > 0 ?
                    retail_data?.map((retail, index) => {
                        return (
                            <>
                                <BuyRetailGetFreeServiceCard
                                    data={retail}
                                    key={index}
                                    onSelect={handle_select}
                                    cartData={cartData}
                                />
                            </>
                        )
                    }) : null
            }
        </>
    )
}

BuyRetailGetFreeService.defaultProps = {
    spend_some_amount: []
}


export default BuyRetailGetFreeService