import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeDropDownList } from "Adapters/Api/employee";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getsProductsDropdownListApi } from "Adapters/Api/Products";
import { getserviceDropdownList } from "Adapters/Api/services";

const useSaleReportFilterHelper = (type, appliedFilterData) => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const current_location = getDataFromLocalStorage("selected_location");

    // redux states
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const reduxEmployees = state.employee?.employees;
    const productRedux = state?.product?.products
    const serviceRedux = state?.service?.services;
    const selectedLocation = state?.locations?.selected_location || current_location

    // states
    const [filterData, setFilterData] = useState(appliedFilterData)

    // handle on change filter
    const handleChange = (e) => {
        const { name, value } = e?.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    useEffect(() => {
        if (selectedLocation) {
            dispatch(getEmployeeDropDownList(selectedLocation))
        }
    }, [selectedLocation])

    useEffect(() => {
        setFilterData(appliedFilterData)
    }, [appliedFilterData])

    useEffect(() => {
        if (type === "product") {
            dispatch(getsProductsDropdownListApi());
        } else if (type === "service") {
            dispatch(getserviceDropdownList());
        } else if (type === "all") {
            dispatch(getsProductsDropdownListApi());
            dispatch(getserviceDropdownList());
        }
    }, [type, dispatch]);


    return {
        translation, selectedLanguage, filterData, handleChange, reduxEmployees, productRedux, serviceRedux
    }
}

export default useSaleReportFilterHelper
