import { useEffect, useState } from "react";
import DirectFlat from "./DirectFlat";
import { FreeItemForOtherPurchase } from "./FreeItemForOtherPurchase";
import { SpecificGroup } from "./SpecificGroup";
import FixedPriceService from "./FixedPriceService";
import DiscountOnBrandNServiceGroup from "./DiscountOnBrandNServiceGroup";
import BuyRetailGetFreeService from "./BuyRetailAndGetFreeService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getsProducts } from "Adapters/Api/Products";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getSaleServices } from "Adapters/Api/saleServices";
import ComplimentaryVoucher from "./ComplimentaryVoucher";
// import PurchaseAndDiscount from "./PurchaseNDiscount";
// import FreeItemSpendAmount from "./FreeItemSpendAmount";
// import UserRestrictedDiscount from "./UserRestrictedDiscount";
// import PackagesPromotions from "./Packages";

const InnerSection = ({ children, selectedLanguage, translation }) => {
    const [not_found, setNotFound] = useState(false)

    useEffect(() => {
        if (!children || (Array.isArray(children) && children.length === 0)) {
            setNotFound(true);
            return;
        }

        let hasValidChildren = Array.isArray(children)
            ? children.some(itm => itm) // Check if there's at least one truthy element
            : !!children; // If not an array, just check if it's truthy

        setNotFound(!hasValidChildren);
    }, [children]);

    return (
        <>
            {
                not_found ?
                    <p>
                        {selectedLanguage?.name == "English" ? "You have not added any offer yet, go to" : translation[138]?.value ? translation[138]?.value : "You have not added any offer yet, go to"}
                        {" "}
                        <Link to='/dashboard/clients/discounts-promotions' >
                            <span className="cursor-pointer text-[#3e5bd0]">
                                {selectedLanguage?.name == "English" ? "promotions & discounts" : translation[139]?.value ? translation[139]?.value : "promotions & discounts"}
                            </span>
                        </Link>
                        {" "}
                        {selectedLanguage?.name == "English" ? "to add" : translation[140]?.value ? translation[140]?.value : "to add"}
                    </p>
                    :
                    children
            }
        </>
    )

}

const DiscountPromotion = ({
    appointmentData,
    promotions,
    MODULE_TYPE,
    setAvailOfferPopup,
    setNotFound,
    cartData,
    setCartData,
    appointmentServices,
    setAppointmentService
}) => {
    const dispatch = useDispatch()

    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const [promotionsData, setPromotionsData] = useState({})
    const [haveDirectOfFlat, setHavDirectOrFlat] = useState(true)

    const handle_selected_data = () => {
        toast.success(selectedLanguage?.name == "English" ? "Selected discount or promotion has been applied successfully" : translation[1724]?.value ? translation[1724]?.value : "Selected discount or promotion has been applied successfully", { toastId: 'toast' })
        setAvailOfferPopup(false)
    }

    const get_products = async () => {
        const response = await getsProducts();
        if (response.status === 200) {
            let productList = response?.data?.response?.products;
            dispatch(getProducts(productList));
        }
    };

    useEffect(() => {
        setPromotionsData(promotions)
    }, [promotions])

    useEffect(() => {
        if (!state.product.products_updated) {
            get_products()
            return
        }

        if (!state.saleService?.saleServices_updated) {
            dispatch(getSaleServices());
            return
        }
    }, [])

    return (
        <InnerSection selectedLanguage={selectedLanguage} translation={translation} >
            {promotionsData?.directFlat && promotionsData?.directFlat?.length > 0 && haveDirectOfFlat &&
                <DirectFlat //#1 Direct or Flat Discount
                    onSelect={handle_selected_data}
                    discount_data={promotionsData?.directFlat}
                    MODULE_TYPE={MODULE_TYPE}
                    haveDirectOfFlat={haveDirectOfFlat}
                    setHavDirectOrFlat={setHavDirectOrFlat}
                    cartData={cartData}
                    setCartData={setCartData}
                    appointmentServices={appointmentServices}
                    setAppointmentService={setAppointmentService}
                />
            }
            {
                promotionsData?.specificCategoryGroup && promotionsData?.specificCategoryGroup?.length > 0 &&
                <SpecificGroup //#2 Discount on Specific groups of category
                    specific_group={promotionsData?.specificCategoryGroup}
                    onSelect={handle_selected_data}
                    appointmentData={appointmentData}
                    MODULE_TYPE={MODULE_TYPE}
                    setNotFound={setNotFound}
                    cartData={cartData}
                    setCartData={setCartData}
                    appointmentServices={appointmentServices}
                    setAppointmentService={setAppointmentService}
                />
            }
            {
                promotionsData?.specificBrandServiceGroup && promotionsData?.specificBrandServiceGroup?.length > 0 &&
                <DiscountOnBrandNServiceGroup //#3 Get Discount on Specific Brand and Service Group
                    specificBrandServiceGroup={promotionsData?.specificBrandServiceGroup}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    cartData={cartData}
                    setCartData={setCartData}
                    appointmentServices={appointmentServices}
                    setAppointmentService={setAppointmentService}
                />
            }
            {// ONLY FOR PRODUCTS IN QUICK SALES
                (MODULE_TYPE == 'quickSales') && promotionsData?.retailPromotion && promotionsData?.retailPromotion.length > 0 &&
                <BuyRetailGetFreeService //#4 Buy retail and get specific services free
                    retailPromotion={promotionsData?.retailPromotion}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    cartData={cartData}
                    setCartData={setCartData}
                />
            }
            {
                (cartData?.ids?.find(itm => itm?.selection_type == 'SERVICE') || MODULE_TYPE == 'APPOINTMENT') && promotionsData?.fixedPrice && promotionsData?.fixedPrice?.length > 0 &&
                <FixedPriceService //#5 Get an Item for a fixed price or in the group
                    fixed_price_services={promotionsData?.fixedPrice}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    setPromotionsData={setPromotionsData} // will be removed after calendar integration
                    cartData={cartData}
                    setCartData={setCartData}
                    appointmentServices={appointmentServices}
                    setAppointmentService={setAppointmentService}
                />
            }
            {
                (cartData?.ids?.find(itm => itm?.selection_type == 'SERVICE') || MODULE_TYPE == 'APPOINTMENT') && promotionsData?.get_free_item_for_other_purchase && promotionsData?.get_free_item_for_other_purchase?.length > 0 &&
                <FreeItemForOtherPurchase //#6 Get free item when purchase specific service/retail
                    get_free_item_for_other_purchase={promotionsData?.get_free_item_for_other_purchase}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    cartData={cartData}
                    setCartData={setCartData}
                    appointmentServices={appointmentServices}
                    setAppointmentService={setAppointmentService}
                />
            }
            {
                (MODULE_TYPE == 'APPOINTMENT') && promotionsData?.complimentaryVoucher && promotionsData?.complimentaryVoucher.length > 0 &&
                <ComplimentaryVoucher //#7 Complimentary vouchers
                    complimentaryVoucher={promotionsData?.complimentaryVoucher}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    cartData={cartData}
                    setCartData={setCartData}
                    appointmentServices={appointmentServices}
                    setAppointmentService={setAppointmentService}
                />
            }
            {/* 
            {
                promotionsData?.purchase && promotionsData?.purchase.filter(itm => itm.select_type?.toLowerCase() == (MODULE_TYPE == 'APPOINTMENT' ? 'service' : MODULE_TYPE?.toLowerCase()))?.length > 0 &&
                <PurchaseAndDiscount // 7 Done with selection type & Working
                    // is_promotion : true,
                    purchases_discount={promotionsData?.purchase.filter(itm => itm.select_type?.toLowerCase() == (MODULE_TYPE == 'APPOINTMENT' ? 'service' : MODULE_TYPE?.toLowerCase()))}
                    servicesRedux={servicesRedux}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    setServiceData={setServiceData}
                    setNotFound={setNotFound}
                />
            }
            {
                (MODULE_TYPE == 'APPOINTMENT') && promotionsData?.spendSomeAmount && promotionsData?.spendSomeAmount.length > 0 &&
                <FreeItemSpendAmount
                    // is_promotion : true,
                    spend_some_amount={promotionsData?.spendSomeAmount}
                    servicesRedux={servicesRedux}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    setServiceData={setServiceData}
                    setNotFound={setNotFound}
                />
            }

            {
                promotionsData?.userRestricted && promotionsData?.userRestricted.length > 0 &&
                <UserRestrictedDiscount
                    userRestricted={promotionsData?.userRestricted}
                    servicesRedux={servicesRedux}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    setServiceData={setServiceData}
                    setSelServGroups={setSelServGroups}
                    setProductsData={setProductsData}
                    setNotFound={setNotFound}
                />
            }
           
            {
                (MODULE_TYPE == 'APPOINTMENT') && promotionsData?.packages && promotionsData?.packages.length > 0 &&
                <PackagesPromotions
                    packages={promotionsData?.packages}
                    servicesRedux={servicesRedux}
                    onSelect={handle_selected_data}
                    MODULE_TYPE={MODULE_TYPE}
                    setServiceData={setServiceData}
                    setSelServGroups={setSelServGroups}
                    setNotFound={setNotFound}
                />
            } */}
        </InnerSection>
    )
}

DiscountPromotion.defaultProps = {
    data: {}
}

export default DiscountPromotion;

