import Svgs from "../../Assets/svgs"


export const PAYMENT_METHODS = [
    { label: 'Cash', value: 'Cash', icon: <Svgs.Cash fill='#5679FF' /> },
    { label: 'Voucher', value: 'Voucher', icon: <Svgs.VoucherSm /> },
    { label: 'Split Bill', value: 'SplitBill', icon: <Svgs.SplitBill /> },
    { label: 'Master Card', value: 'MasterCard', icon: <Svgs.MasterCardSM /> },
]


export const ALLOWED_PAYMENT_METHODS = [
    { name: 'Cash', icon: <Svgs.Cash_green /> },
    { name: 'Mastercard', icon: <Svgs.MasterCard /> },
    { name: 'Visa', icon: <Svgs.VISA /> },
    { name: 'Paypal', icon: <Svgs.paypal /> },
    { name: 'GooglePay', icon: <Svgs.Gpay /> },
    { name: 'ApplePay', icon: <Svgs.ApplePay /> },
]

export const CHECKOUT_PAYMENT_METHODS = [
    { name: 'Cash', icon: <Svgs.CashIcon /> },
    { name: 'Mastercard', icon: <Svgs.MasterCardIcon /> },
    { name: 'Visa', icon: <Svgs.VisaIcon /> },
    { name: 'Paypal', icon: <Svgs.paypal /> },
    { name: 'Alipay', icon: <Svgs.AliPayIcon /> },
]

export const OTHER_PAYMENT_METHODS = [
    { name: 'Voucher', icon: <Svgs.VoucherColorIcon width={"1.25rem"} height={"1.25rem"} /> },
    { name: 'Membership', icon: <Svgs.MembershipColorIcon width={"1.375rem"} height={"1.25rem"} /> },
    { name: 'LoyalityPoints', icon: <Svgs.Loyality width={"1.375rem"} height={"1.25rem"} /> },
    { name: 'Coupon', icon: <Svgs.CouponColorIcon width={"1.25rem"} height={"1.25rem"} /> },
    { name: 'GiftCard', icon: <Svgs.GiftCard width={"1.375rem"} height={"1.25rem"} /> },
    { name: 'Promotion', icon: <Svgs.GiftCard width={"1.375rem"} height={"1.25rem"} /> },
]


export const OTHER_PAYMENT_METHODS_SALES = [
    { name: 'Voucher', icon: <Svgs.newVoucher width={"1.625rem"} height={"1.25rem"} /> },
    { name: 'Membership', icon: <Svgs.MembershipIcon width={"1.625rem"} height={"1.25rem"} /> },
    { name: 'LoyalityPoints', icon: <Svgs.Loyality width={"1.4375rem"} height={"1.25rem"} /> },
    { name: 'Coupon', icon: <Svgs.Coupon width={"1.375rem"} height={"1.25rem"} /> },
    { name: 'GiftCard', icon: <Svgs.GiftCard width={"1.375rem"} height={"1.25rem"} /> },
]