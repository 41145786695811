import React, { useEffect, useState } from "react";
import { ACCOUNT_TYPE } from "../../../../Constants/Variables";
import LoginBtn from "../../../TenantApp/Elements/LoginBtn";
import LoginInput from "../../../TenantApp/Elements/LoginInput";
import PasswordInput from "../../../TenantApp/Elements/PasswordInput";
import PhoneInput from "../../../TenantApp/Elements/PhoneInput";
import usePersonlInfoForm from "./usePersonlInfoForm";
import CheckBox from "../../../TenantApp/Elements/Checkbox/CheckBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signupUserPersonalInfo } from "../../../../Redux/NstyleRedux/Actions/sigupAction";
import Dropwdown from "../../../TenantApp/Elements/Dropwdown";
import { getBusinessTypes } from "../../../../Adapters/Api";
import { PasswordValidation } from "../../../../Constants/Functions/Validations";
import PasswordValidationBar from "../../../Utility/PasswordBar";
import Popup from "Components/TenantApp/Elements/Popup";
import useAddress from "Hooks/useAddress";
import Svgs from "Assets/svgs";
import FormStepsSignUp from "Components/TenantApp/Elements/FormStepsSignUp";
import LoginWithGoogle from "Components/NstyleApp/SocialsLogin/LoginWithGoogle";
import LoginWithFacebook from "Components/NstyleApp/SocialsLogin/LoginWithFacebook";
import useObjectKeys from "Hooks/useObjectKeys";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import AutoPlaceInput from "Components/TenantApp/Elements/AutoPlaceInput";

const PersonalInfo = ({ setStep, setCheckAccountType, categories }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const signup_type = searchParams.get("signup_type");
  const account_type = searchParams.get("account_type");

  const [password_strength, setPasswordStrength] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);

  const { objectKeyConvertToArray } = useObjectKeys();
  const info_data = useSelector((state) => state.userData.personalInfo);
  const {
    errors,
    setErrors,
    validation,
    onFocusHandler,
    onBlurHandler,
    handleChange,
    handleSubmit,
    getSearchParams,
    loading,
    clientStats,
    handleClientDetails,
    submitClientDetails,
    TermConditionPopup,
    setTermConditionPopup,
    formRef
  } = usePersonlInfoForm({ setStep });
  const {
    business_name,
    business_industry,
    first_name,
    last_name,
    city,
    email,
    mobile_number,
    country,
    state,
    password,
    confirm_password,
    terms_condition,
    is_subscribed,
    zip_code,
    address
  } = info_data;

  const { countriesList, stateList } = useAddress(country);


  useEffect(() => {
    getSearchParams(ACCOUNT_TYPE);
    const urlAccountTypeValue = searchParams.get(ACCOUNT_TYPE);
    dispatch(
      signupUserPersonalInfo({
        ...info_data,
        account_type: urlAccountTypeValue,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      signupUserPersonalInfo({
        ...info_data,
        city: '',
      })
    );
  }, [country]);

  useEffect(() => {
    if (
      signup_type &&
      (signup_type.toLowerCase() == "google" ||
        signup_type.toLowerCase() == "facebook")
    ) {
      if (!info_data.social_account) {
        navigate("/auth/signup/");
      }
    }
    dispatch(getBusinessTypes());
  }, []);

  useEffect(() => {
    if (password != undefined) {
      const strength_value = PasswordValidation(password);
      setPasswordStrength(strength_value);
    }
    setCheckAccountType(account_type);
  }, [password]);

  // ---------- Steps SignUp Form -----------
  const nextFunc = () => {
    let requiredFields
    if (currentStep == 1) {
      requiredFields = {
        business_name, business_industry
      }
    }
    if (currentStep == 2) {
      requiredFields = { country, city, zip_code, address }
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if ((objectKeyConvertToArray(result)?.length === 0)) {
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const prevFunc = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <>
      {account_type === "business" && (
        <div className="flex flex-col justify-start gap-5 h-full w-full overflow-hidden">

          <div className="overflow-x-hidden w-full" ref={formRef}>
            <div className="py-5 pb-10 md:py-0 md:pb-5">
              <FormStepsSignUp
                className={""}
                currentStep={currentStep}
                setStep={setCurrentStep}
                active={currentStep}
                steps={
                  [
                    "Business",
                    "Address",
                    "Login"
                  ]
                }
              />
            </div>

            {/* Step1  */}
            {currentStep == 1 && (
              <div className="overflow-x-hidden w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 sm:py-7 py-8  gap-5 overflow-x-hidden">
                  {/* {info_data?.account_type?.toLowerCase() == "business" && ( */}
                  <>
                    <LoginInput
                      parentClass={"col-span-2 md:col-span-1 w-full"}
                      required={true}
                      name="business_name"
                      title="Business Name"
                      value={business_name}
                      // charactersMaxLength={25}
                      onChange={handleChange}
                      inputPadding={"!py-[0.45rem] md:!py-[0.4rem]"}
                      specialCharacterNotAllowed={true}
                      placeholder="Enter your Business name"
                      error={errors.business_name}
                      onEnterSubmit={handleSubmit}
                    />
                    {/* <DropwdownHeadings
                              className={"h-full col-span-2 md:col-span-1"}
                              custom_min_width={"w-full"}
                  
                              title={"Business Industry"}
                              placeholder={"Select Business Industry"}
                              required
                              name={"business_industry"}
                              value={business_industry}
                              options={[
                                {
                                  label: 'Hair Cut',
                                    options: [
                                      {
                                      label: 'Hair Cut',
                                      value: 'Hair Cut',
                                    },
                                    {
                                      label: 'Blow Dry',
                                      value: 'Blow Dry',
                                    },
                                    {
                                      label: 'Coloring',
                                      value: 'Coloring',
                                    },
                                    {
                                      label: 'Styling',
                                      value: 'Styling',
                                    },
                                  ],
                                },
                                {
                                  label: 'Nail',
                                    options: [
                                      {
                                      label: 'Manicure',
                                      value: 'Manicure',
                                    },
                                    {
                                      label: 'Pedicure',
                                      value: 'Pedicure',
                                    },
                                  ],
                                },
                                 
                                ]}
                              onChange={handleChange}
                              onBlur={() =>
                                onBlurHandler({ business_industry: business_industry })
                              }
                              error={errors?.business_industry}
                            /> */}
                    <Dropwdown
                      className={"h-full col-span-2 md:col-span-1"}
                      custom_min_width={"w-full"}
                      Class={" w-[100vw!important]"}
                      title={"Business Industry"}
                      placeholder={"Select Business Industry"}
                      required
                      name={"business_industry"}
                      value={business_industry}
                      options={categories}
                      onChange={handleChange}
                      onBlur={() =>
                        onBlurHandler({ business_industry: business_industry })
                      }
                      error={errors?.business_industry}
                    />
                  </>
                  {/* )} */}
                </div>
              </div>
            )}
            {/* Step2 */}
            {currentStep == 2 && (
              <div className="overflow-x-hidden w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pb-10 lg:pb-5 overflow-x-hidden">
                  <div className="col-span-2">
                    <Dropwdown
                      className={"h-full"}
                      required={true}
                      title={"Country"}
                      placeholder={"Select Country"}
                      name={"country"}
                      value={country}
                      onChange={handleChange}
                      error={errors.country}
                      options={countriesList}
                      onEnterSubmit={handleSubmit}
                      custom_min_width={"w-full"}
                    />
                  </div>
                  <div className="col-span-2">
                    <Dropwdown
                      required={false}
                      title={"State/Province"}
                      placeholder={"Select State"}

                      name="state"
                      value={state}
                      onChange={handleChange}
                      options={stateList}
                    />
                  </div>
                  <LoginInput
                    parentClass={"col-span-2 md:col-span-1 w-full h-full"}
                    required={true}
                    name="city"
                    title="City"
                    // charactersMaxLength={25}
                    placeholder="Enter your city"
                    value={city}
                    error={errors?.city}
                    onChange={handleChange}
                    disabled={!state || stateList?.length == 0}
                  />
                  <LoginInput
                    parentClass={"col-span-2 md:col-span-1 w-full h-full"}
                    required={true}
                    name="zip_code"
                    title="Zip / Post Code"
                    onlyNumericAllowed={true}
                    placeholder="Enter post code"
                    value={zip_code}
                    error={errors?.zip_code}
                    onChange={handleChange}
                  />
                  <div className="col-span-2 h-full">
                    {/* <LoginInput
                      name="address"
                      required={true}
                      error={errors.address}
                      title="Street Address"
                      placeholder="Address"
                      value={address}
                      onChange={handleChange}
                    /> */}
                    <AutoPlaceInput
                      onChange={handleChange}
                      name={"address"}
                      value={address}
                      required={true}
                      error={errors?.address}
                      title="Street Address"
                      placeholder="Address"
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Step3 */}
            {currentStep == 3 && (
              <div className="grid md:grid-cols-2 gap-5 pb-5 overflow-x-hidden w-full">
                <LoginInput
                  parentClass={"col-span-2 md:col-span-1 w-full h-full"}
                  required={true}
                  name="first_name"
                  title="First Name"
                  value={first_name}
                  onChange={handleChange}
                  // charactersMaxLength={25}
                  specialCharacterNotAllowed={true}
                  placeholder="Enter your first name"
                  error={errors.first_name}
                  onEnterSubmit={handleSubmit}
                />
                <LoginInput
                  parentClass={"col-span-2 md:col-span-1 w-full h-full"}
                  required={true}
                  name="last_name"
                  title="Last Name"
                  value={last_name}
                  onChange={handleChange}
                  // charactersMaxLength={25}
                  specialCharacterNotAllowed={true}
                  placeholder="Enter your last name"
                  error={errors.last_name}
                  onEnterSubmit={handleSubmit}
                />
                <LoginInput
                  required={true}
                  name="email"
                  type={"email"}
                  title="Email Address"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  error={errors.email}
                  disabled={info_data.social_account && email ? true : false}
                  onEnterSubmit={handleSubmit}
                />
                <PhoneInput
                  required={true}
                  name="mobile_number"
                  title="Phone Number"
                  value={mobile_number}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  error={errors?.mobile_number}
                  onEnterSubmit={handleSubmit}
                />
                {!info_data?.social_account && (
                  <>
                    <div className={"col-span-2 md:col-span-1 w-full"}>
                      <PasswordInput
                        onEnterSubmit={handleSubmit}
                        hideError={true}
                        required={true}
                        title="Password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        onBlur={() => onBlurHandler({ password: password })}
                        placeholder="Enter 6 digit password"
                        error={errors?.password}
                      />

                    </div>
                    <div className={"col-span-2 md:col-span-1 w-full"}>
                      <PasswordInput
                        onEnterSubmit={handleSubmit}
                        title="Confirm Password"
                        required={true}
                        name="confirm_password"
                        value={confirm_password}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurHandler({ confirm_password: confirm_password })
                        }
                        placeholder="Enter 6 digit password"
                        error={errors.confirm_password}
                      />
                    </div>

                    <div className="col-span-2">
                      <PasswordValidationBar value={password_strength} />
                    </div>
                  </>
                )}
                <div className="flex flex-col gap-5 col-span-2">
                  <CheckBox
                    label_text_transform='normal-case'
                    // className={"w-full"}
                    name="terms_condition"
                    onChange={handleChange}
                    value={terms_condition}
                    checked={terms_condition}
                    error={errors?.terms_condition}
                    label={"I agree to all "}
                    colorLabel1={" Terms"}
                    colorLabel2={<> Conditions <span className="text-red-600">*</span></>}
                    onClickLabel1={() => setTermConditionPopup(!TermConditionPopup)}
                    onClickLabel2={() => setTermConditionPopup(!TermConditionPopup)}
                  />
                  <CheckBox
                    label_text_transform='normal-case'
                    name="is_subscribed"
                    onChange={handleChange}
                    value={is_subscribed}
                    checked={is_subscribed}
                    error={errors?.is_subscribed}
                    label={"Subscribe to our "}
                    colorLabel1={" Newsletter"}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={`flex items-center gap-5`}>
            {
              currentStep > 1 && (
                <LoginBtn className={"w-[100%!important] flex justify-center"} onClick={prevFunc}  >
                  <div className="flex items-center gap-2">
                    <span className="rotate-180">
                      <Svgs.NextArrow />
                    </span>
                    <div className="">
                      Back
                    </div>
                  </div>
                </LoginBtn>
              )
            }
            {
              currentStep === 3 ? (
                <LoginBtn
                  className={"w-[100%!important] flex justify-center"}
                  loading={loading}
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={loading}
                  text={"Sign up"}
                />
              ) :
                <LoginBtn className={"w-[100%!important] flex justify-center"}
                  onClick={() => {
                    nextFunc(currentStep);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <div className="">
                      Next
                    </div>
                    <Svgs.NextArrow />
                  </div>
                </LoginBtn>
            }

          </div>
          {/* <div className="flex flex-col items-center justify-center gap-3">
            <p className="text-sm text-[#808080]">or continue with</p>
            <div className="flex justify-center gap-3">
              <LoginWithFacebook
                login={true}
              />
              <LoginWithGoogle login={true}
              />
            </div>
            <p className="block md:hidden text-[#5679FF] text-base font-medium pt-2" onClick={() => {
              navigate('/help')
            }}>Help?</p>
          </div> */}
        </div>
      )}

      {account_type === "everyone" && (
        <div className="h-full w-full flex items-center slide-in-right">
          <div className="flex flex-col gap-5 w-full">
            {/* <PersonalInfo /> */}
            <h3 className="font-semibold text-3xl text-[#374168]">
              Register your account
              {/* <br /> Personal & business details */}
            </h3>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
              <LoginInput
                required={true}
                name="full_name"
                title="Full Name"
                value={clientStats?.full_name}
                onChange={handleClientDetails}
                onBlur={() =>
                  onBlurHandler({ full_name: clientStats?.full_name })
                }
                placeholder="Enter your first name"
                error={errors.full_name}
                onEnterSubmit={handleSubmit}
              />
              <LoginInput
                required={true}
                name="email"
                type={"email"}
                title="Email Address"
                value={clientStats?.email}
                onChange={handleClientDetails}
                onBlur={() => onBlurHandler({ email: clientStats?.email })}
                placeholder="Enter your email address"
                error={errors.email}
                disabled={info_data.social_account && email ? true : false}
                onEnterSubmit={handleSubmit}
              />

              <PhoneInput
                required={true}
                name="mobile_number"
                title="Phone Number"
                value={clientStats?.mobile_number}
                onChange={handleClientDetails}
                onBlur={() =>
                  onBlurHandler({ mobile_number: clientStats?.mobile_number })
                }
                onFocus={() => onFocusHandler({ mobile_number: "" })}
                placeholder="Enter your phone number"
                error={errors?.mobile_number}
                onEnterSubmit={handleSubmit}
              />

              {!info_data?.social_account && (
                <>
                  <div>
                    <PasswordInput
                      onEnterSubmit={handleSubmit}
                      hideError={true}
                      required={true}
                      title="Password"
                      name="password"
                      value={clientStats?.password}
                      onChange={handleClientDetails}
                      onBlur={() =>
                        onBlurHandler({ password: clientStats?.password })
                      }
                      placeholder="Enter 6 digit password"
                      error={errors?.password}
                    />
                    {/* <PasswordValidationBar value={password_strength} /> */}
                  </div>
                  <PasswordInput
                    onEnterSubmit={handleSubmit}
                    title="Confirm Password"
                    required={true}
                    name="confirm_password"
                    value={clientStats?.confirm_password}
                    onChange={handleClientDetails}
                    onBlur={() =>
                      onBlurHandler({
                        confirm_password: clientStats?.confirm_password,
                      })
                    }
                    placeholder="Enter 6 digit password"
                    error={errors.confirm_password}
                  />
                </>
              )}
            </div>
            <CheckBox
              name="terms_condition"
              onChange={handleClientDetails}
              value={clientStats?.terms_condition}
              checked={clientStats?.terms_condition}
              error={errors?.terms_condition}
              label={"I agree to all "}
              colorLabel1={" Terms"}
              colorLabel2={" Conditions"}
              onClickLabel1={() => setTermConditionPopup(!TermConditionPopup)}
              onClickLabel2={() => setTermConditionPopup(!TermConditionPopup)}
            />
            <CheckBox
              name="is_subscribed"
              onChange={handleClientDetails}
              value={clientStats?.is_subscribed}
              checked={clientStats?.is_subscribed}
              error={errors?.is_subscribed}
              label={"Subscribe to our "}
              colorLabel1={" Newsletter"}
            />
            <LoginBtn
              loading={loading}
              type="submit"
              onClick={() => {
                submitClientDetails();
              }}
              disabled={
                loading ||
                !clientStats?.full_name ||
                !clientStats?.email ||
                !clientStats?.mobile_number ||
                !clientStats.password ||
                !clientStats.confirm_password ||
                !clientStats?.terms_condition
              }
              text="Next"
            >
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8H17M10 1L17 8L10 15"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </LoginBtn>
          </div>
        </div>
      )}

      {TermConditionPopup && (
        <>
          <Popup
            heading={"Terms and conditions"}
            size={"lg"}
            open={TermConditionPopup}
            close={setTermConditionPopup}
          >
            <div className="flex flex-col gap-4">
              Hi! We are X. We've worked hard to make our Terms and Conditions (Terms of Service) easy to read. We've summarised key points of our Terms of Service below, but you must also read the full version that follows.
              <br /><br />
              X provides X.com and the X app (together, our X Platform), which allows you to discover and book online with (or purchase Products from) spas, salons, beauticians, trainers and other businesses. You can buy or book Partner Services (including any Products that Partners have for sale) by making an Appointment or an Order through the X Platform.
              The Partner Services you can book or buy via X are sold by our Partners and not by us. We are only responsible for arranging and concluding your Appointment or Order, and we have been appointed by our Partners to act as their commercial agent to do so.<br /><br />
              If you pay for Partner Services using the X Payment Services, we may collect and receive your payment on behalf of the relevant Partner in our capacity as their commercial agent. When we receive your payment, this will discharge your debt to the Partner for the Partner Services.
              The contract for the Partner Services is directly between you and the relevant Partner. We are not responsible for the Partner Services you receive from our Partners. However, we would like to know if you are less than happy with any Partner. You can contact us at support@X.com, and we'll see if there's anything we can do to help.
              If you make an Appointment or Order, you agree to a Partner Contract (including any Partner Terms of Sale) with the relevant Partner. X is not part of this agreement. Please thoroughly check all details and any restrictions relating to an Appointment or Order before confirming it.<br /><br />
              X may deactivate your X Account if you breach any part of these Terms of Service or our Terms of Use, or if you act in a way that is inappropriate, abusive or otherwise unacceptable towards a Partner or our staff.
              <br /><br />
              If you would like to reschedule or cancel an Appointment or Order, or return or exchange a Product, first check these Terms of Service and any Partner Contract to see if you are able to do so. If you are, you can do this through:
              Submitting a request through the X Platform,
              emailing or calling the Partner directly, or
              emailing our support team at support@X.com.
              If you want to talk to us, please get in touch with our team and we'd be happy to help at support@X.com.
            </div>
          </Popup>
        </>
      )}
    </>
  );
};

export default PersonalInfo;
