import { getSingleInvoiceDetail } from "Adapters/Api/saleOrders";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useOpenDetailInvoicehelper = ({ checkoutId }) => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')
    const current_location = getDataFromLocalStorage("selected_location");

    // redux states
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const invoice_titles = state?.saleOrder?.invoice_titles;
    const selectedLocation = state?.locations?.selected_location || current_location

    // states
    const [InvoicePopup, setInvoicePopup] = useState(false);
    const [detailLoading, setDetailLoading] = useState(true)
    const [selectedInvice, setSelectedInvice] = useState();

    const calculate_totals = (item) => {
        let total_bill = 0;
        if (typeof item?.membership == "object") {
            if (item?.membership?.length > 0) {
                item?.membership?.forEach((itm) => {
                    total_bill += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.voucher == "object") {
            if (item?.voucher?.length > 0) {
                item?.voucher?.forEach((itm) => {
                    total_bill += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.service == "object") {
            if (item?.service?.length > 0) {
                item?.service?.forEach((single_service) => {
                    total_bill += (single_service?.price) * (single_service?.quantity ? single_service?.quantity : 1);
                });
            }
        }

        if (typeof item?.product == "object") {
            if (item?.product?.length > 0) {
                item?.product?.forEach((itm) => {
                    total_bill += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (item?.order_type === "Appointment") {
            total_bill += item?.total_price;
        }
        let total = total_bill?.toFixed(2)
        return total;
    };

    // get invoice detail
    const getInvoiceDetailData = () => {
        setInvoicePopup(!InvoicePopup);
        dispatch(getSingleInvoiceDetail(
            access_token, checkoutId, selectedLocation, setDetailLoading,
            (result) => {
                setSelectedInvice(result);
            },
            () => { }
        ));
    }

    useEffect(() => {
        if (checkoutId) {
            getInvoiceDetailData(checkoutId)
        }
    }, [checkoutId])

    return {
        translation, selectedLanguage, detailLoading, InvoicePopup, setInvoicePopup, selectedInvice, calculate_totals,
        invoice_titles
    }
}

export default useOpenDetailInvoicehelper
