import Svgs from 'Assets/svgs';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import React, { useEffect } from 'react'

import moment from "moment";

import { useState } from 'react';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import Pagination from 'new-components/Pagination';
import NoSalesFound from '../../Sales/Elements/NoSalesFound';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer';
import { useBookingReports } from './helper';
const PageSize = 10

const AppointmentReports = () => {
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const { translation, selectedLanguage, onChange, setCurrentPage, currentPage, appointmentReportsData, pages, startDate, setStartDate, endDate, setEndDate, search_text, setSearchText, exportLoader, loading } = useBookingReports();
    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative">
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                url={'/dashboard/reports/'}
                second={selectedLanguage?.name == "English" ? "Appointment Reports" : translation[1786]?.value ? translation[1786]?.value : "Appointment Reports"}
            />

            <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

                <div className="flex items-center gap-4">
                    <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                        {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                    </h2>
                    {/* <LocationSelection /> */}
                </div>
            </div>

            <div className="">
                <div className="border rounded-lg bg-white">
                    <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                        <div className="flex items-center lg:gap-4 gap-3 self-start">
                            <div className="flex items-center sm:gap-4 gap-2 ">
                                <h2 className="font-semibold text-xl sm:whitespace-nowrap">
                                    {selectedLanguage?.name == "English" ? "Appointment Reports" : translation[1786]?.value ? translation[1786]?.value : "Appointment Reports"}
                                </h2>

                                {loading ?
                                    <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                        <p className="rounded-md h-5 w-12 mx-1"> </p>
                                    </div>
                                    : <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                        <p className="text-primary">
                                            {appointmentReportsData?.length ? appointmentReportsData?.length : 0}{" "}
                                            {appointmentReportsData?.length && appointmentReportsData?.length > 1 ?
                                                selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                                : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                            }
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        {loading ?
                            <EmployeesHeadShimmer classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} has3Itm classOn1={'w-44 lg:w-48'} />
                            :
                            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                                <div className="mb-00 flex-1">
                                    <LoginInput
                                        title=""
                                        name={"search_text"}
                                        placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                                        value={search_text}
                                        onChange={onChange}
                                        padding="py-1"
                                        leftIcon={<Svgs.Search />}
                                    />
                                </div>

                                <div className='flex items-center gap-3'>
                                    <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                        <div className='sm:p-[0.45rem] p-2 relative gap-4 flex items-center'>
                                            <p onClick={() => {
                                                setToggleDateRange(!toggleDateRange)
                                            }} className='cursor-pointer'>
                                                {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                            </p>
                                            <span className='cursor-pointer' onClick={() => {
                                                setStartDate()
                                                setEndDate()
                                            }}>Clear</span>

                                            {
                                                toggleDateRange && <>
                                                    <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                                    <div className='absolute top-full right-0 z-[2]'>
                                                        <DateRangePicker
                                                            start={startDate}
                                                            end={endDate}
                                                            setStartDate={setStartDate}
                                                            setEndDate={setEndDate}
                                                            setToggleDateRange={setToggleDateRange}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                    {loading ?
                        <TableShimer cols={5} colsHeight={7} />
                        :
                        <div className="overflow-x-auto">
                            <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                                <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Total Appointments" : translation[1787]?.value ? translation[1787]?.value : "Total Appointments"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1788]?.value ? translation[1788]?.value : "Cancel"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Start Delay" : translation[1789]?.value ? translation[1789]?.value : "Start Delay"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Completed" : translation[322]?.value ? translation[322]?.value : "Completed"}</p>
                            </div>
                            <div
                                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                            >
                                {appointmentReportsData.length > 0 ? (
                                    appointmentReportsData?.map((item, index) => {

                                        return (
                                            <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] min-w-full w-fit">
                                                <div className="flex items-center">
                                                    <p className="text-sm line-clamp-1 pr-3">
                                                        {item?.location}
                                                    </p>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-sm line-clamp-1 pr-3">
                                                        {item?.total_appointments}
                                                    </p>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-sm line-clamp-2">
                                                        {item?.cancel_appointments}
                                                    </p>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-sm">
                                                        {item?.total_late_appointments}
                                                    </p>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-sm ">
                                                        {item?.completed_appointments}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <NoSalesFound />
                                )}
                            </div>
                        </div>
                    }
                    {/* Pagination */}
                    {/* {(appointmentReportsData.length > 0 && !loading) &&
                        <Pagination
                            currentPage={currentPage}
                            totalCount={Math.ceil(pages)}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    } */}

                </div>
            </div>


        </div>
    )
}

export default AppointmentReports