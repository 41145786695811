import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "Components/TenantApp/Elements/Popup";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  discountRoutes,
  discountRoutes_2,
  useDiscountPromotion,
} from "./helper";
import DiscountCard from "./Elements/DiscountCard";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import NoDiscountAndPromotionFound from "Components/Utility/NoDiscountAndPromotionFound";
import PromotionCardShimmer from "Components/Utility/Shimmers/PromotionCardShimmer";
import DiscountPromotionShimmer from "Components/Utility/Shimmers/DiscountPromotionShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const Index = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);



  const {
    create,
    setCreate,
    deleteDiscountPromotion,
    navigate,
    discountPromotionData,
    DiscountARR,
    discountPromotion,
    deleteLoading,
    editDiscount,
    deleteModal,
    setDeleteModal,
    deleteDiscount,
    translation, selectedLanguage,
    loader,
    getLoader
  } = useDiscountPromotion();

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">

        <Breadcrumb
          url={"/dashboard/clients/"}
          first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
          second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
        />
        <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
          <div className="flex items-center gap-4 justify-between w-full">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
            </h2>
            {loadingPermission ?
              <Shimmer className={'w-32 lg:w-36'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              :
              !employee || (employee && employeePermissions?.client_discount?.includes("create")) ? (
                <LoginBtn
                  onClick={() => {
                    setCreate(!create);
                  }}
                  text={
                    <>
                      <div className="flex items-center gap-2">
                        <Svgs.Plus />
                        <p className="">
                          {selectedLanguage?.name == "English" ? "Create New" : translation[639]?.value ? translation[639]?.value : "Create New"}
                        </p>
                      </div>
                    </>
                  }
                />
              ) : (
                ""
              )}
          </div>
        </div>

        {loader || getLoader || (discountPromotionData === false) ?
          <div className="flex flex-col gap-4 md:w-[40vw] w-full">
            <DiscountPromotionShimmer />
            <DiscountPromotionShimmer />
            <DiscountPromotionShimmer />
            <DiscountPromotionShimmer />
          </div>
          :
          discountPromotion?.length > 0 ?
            <div className="flex flex-col gap-4 md:w-[40vw] w-full">
              {discountPromotion.map((data, i) => {
                return (
                  <DiscountCard
                    i={i}
                    data={data}
                    deleteDiscountPromotion={deleteDiscountPromotion}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    navigate={navigate}
                    editDiscount={editDiscount}
                    translation={translation}
                    selectedLanguage={selectedLanguage}
                  />
                );
              })}
            </div>
            : <NoDiscountAndPromotionFound className={"my-[6rem]"} />
        }
      </div>


      {create && (
        <Popup
          size={"md"}
          heading={selectedLanguage?.name == "English" ? "Create Discount" : translation[651]?.value ? translation[651]?.value : "Create Discount"}
          open={create}
          close={setCreate}
        >
          <div className="flex flex-col gap-3">
            <h1 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}</h1>
            {discountRoutes.map((ele, i) => {
              return (
                <>
                  <p
                    onClick={() => {
                      navigate(
                        `/dashboard/clients/discounts-promotions${ele.slug}`
                      );
                    }}
                    className="p-2 cursor-pointer rounded-md text-sm hover:bg-[#E0EBFD]"
                  >
                    {ele.name}
                  </p>
                </>
              );
            })}
            <h1 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}</h1>
            {discountRoutes_2.map((ele, i) => {
              return (
                <p
                  onClick={() => {
                    navigate(
                      `/dashboard/clients/discounts-promotions${ele.slug}`
                    );
                  }}
                  className="p-2 cursor-pointer rounded-md text-sm hover:bg-[#E0EBFD]"
                >
                  {ele.name}
                </p>
              );
            })}
          </div>
        </Popup>
      )}
      {deleteModal && (
        <>
          <DeleteDiscountPromotion
            setDeletePopup={setDeleteModal}
            deleteDiscount={deleteDiscount}
            deleteLoading={deleteLoading}
            translation={translation}
            selectedLanguage={selectedLanguage}
          />
        </>
      )}
    </>
  );
};

export default Index;

export const DeleteDiscountPromotion = ({
  setDeletePopup,
  deleteLoading,
  deleteDiscount,
  translation,
  selectedLanguage
}) => {
  const handleClose = () => {
    setDeletePopup(false);
  };

  return (
    <Popup
      heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Service?" : translation[652]?.value ? translation[652]?.value : "Are you sure want to delete this Service?"}
      onclose={handleClose}
    >
      <p>
        {selectedLanguage?.name == "English" ? "Are you sure you want to delete this service? You won't be able to see this again." : translation[606]?.value ? translation[606]?.value : "Are you sure want to delete this service? You won't be able to see this again."}

      </p>
      <div className="flex justify-end mt-4">
        <LoginBtn
          loading={deleteLoading}
          text="Yes! Delete"
          deleteBtn
          onClick={() => deleteDiscount(handleClose)}
        />
      </div>
    </Popup>
  );
};
