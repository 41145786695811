import { useState } from "react";
import { toast } from "react-toastify";

import { userLogin } from "Adapters/Api";
import useObjectKeys from "Hooks/useObjectKeys";
import useValidations from "Hooks/useValidations";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { customer_login } from "Adapters/Api/api_variables";
import { customerLogin } from "Adapters/Api/businessLocationInNstyleApp";
import { CLIENT_SIGNUP } from "Redux/NstyleRedux/ActionTypes/signupTypes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useLoginSection = () => {
  let active = false;

  const {
    errors,
    setErrors,
    validation,
    onBlurHandler,
    onFocusHandler,
    removeWhiteSpacesFromStartAndEnd,
  } = useValidations();

  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const { objectKeyConvertToArray } = useObjectKeys();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });

  //enabled Next here
  if (!Object.values(loginCredentials).includes("")) {
    active = true;
  }

  // handle change email password
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'remember_me') {
      setLoginCredentials((prev) => ({
        ...prev,
        [name]: e.target?.checked,
      }));
    } else {
      const result = removeWhiteSpacesFromStartAndEnd(value);

      setLoginCredentials((prev) => ({
        ...prev,
        [name]: result,
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  // handle submit
  const onSubmitHandler = async (data, onSuccess) => {
    const requiredFields = {
      email: loginCredentials?.email,
      password: loginCredentials?.password,
    }
    setErrors(validation(requiredFields));
    const result = validation(requiredFields);
    if (objectKeyConvertToArray(result)?.length === 0) {
      setLoading(true);
      let response = await userLogin(requiredFields);
      console.log(response, "response")
      setLoading(false);
      response = response.data;
      onSuccess && onSuccess(response);
      if (response.status_code == 200) {
        let userData = response.response.data;
        storeDataInLocalStorage("loginData", userData);
        storeDataInLocalStorage("access_token", userData.access_token);
        let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
        if (typeof window !== 'undefined') {
          hostName = window.location.host;
        }
        const hostArr = hostName.split(".");
        hostArr.unshift(`${userData.domain}`);
        const result = hostArr.join(".");
        setLoading(false);
        console.log("data ::", userData);
        let is_asked_for_data_update = userData.is_asked_for_data_update
        // navigate("/auth/login/?account_type=business")

        let redirect_params = ''
        if (!is_asked_for_data_update && !userData?.employee) {
          redirect_params += `is_asked_for_data_update=${is_asked_for_data_update}&`
        }
        if (!is_asked_for_data_update) {
          localStorage.removeItem('ask_for_change_default_data')
        } else {
          localStorage.setItem('ask_for_change_default_data', true)
        }
        //comment for now we don't wanna go with sub domain
        window.location = `http://${result}/authentication/login/auto_login/?user_login_id=${userData.id}&access_token=${userData.access_token}&next_path=/dashboard/account/setup/business-settings/additional-information&redirection_source=nstyle_app&redirection_purpose=account_and_mobile_otp_verification&isEmployee=${userData?.employee}${userData?.selected_location ? `&selected_location=${userData?.selected_location}` : ''}&${redirect_params}`;
        toast.success("Login successfully", { toastId: "toast" });
      } else if (
        response?.status_code != 4009 &&
        response?.status_code != 4010
      ) {
        if (response?.response?.fields?.includes("email")) {
          setErrors((prev) => ({
            ...prev,
            email: response?.response?.message,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            password: response?.response?.message,
          }));
        }
        toast.error(response?.response?.message, { toastId: "toast" });
        setLoading(false);
      }
    }
  };

  //customer login implemented here
  const businessClientLogin = async (hash, business, url) => {
    setErrors(validation(loginCredentials));
    const result = validation(loginCredentials);
    let tenant_id = getDataFromLocalStorage('hash')

    if (objectKeyConvertToArray(result)?.length === 0) {
      setLoading(true);
      const response = await customerLogin({
        login_credentials: loginCredentials,
        hash_id: tenant_id ? tenant_id : ''
      });
      if (response?.data?.status_code === 200) {
        dispatch({
          type: CLIENT_SIGNUP.CLIENT_INFO,
          payload: { ...response?.data?.response?.data, hash, business },
        });
        storeDataInLocalStorage(
          "client_email",
          loginCredentials.email
        );
        storeDataInLocalStorage(
          "client_token",
          response?.data?.response?.data?.access_token
        );
        if (response?.data?.response?.data?.client_id) {
          storeDataInLocalStorage(
            "client_id",
            response?.data?.response?.data?.client_id
          );
        }
        storeDataInLocalStorage("business", business);
        navigate(url);
      } else {
        setErrors((prev) => ({
          ...prev,
          email: response?.data?.response?.message,
        }));
        setLoading(false);
        toast.error(response?.data?.response?.message, { toastId: "toast" });
      }
    }
  };

  return {
    errors,
    active,
    loading,
    loginCredentials,
    handleChange,
    onBlurHandler,
    onFocusHandler,
    onSubmitHandler,
    businessClientLogin,
  };
};

export default useLoginSection;