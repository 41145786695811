import LabelAvatar from 'Components/TenantApp/Elements/LabelAvatar'
import React from 'react'
import { useSelector } from 'react-redux'

const AnalyticsCard = ({ data, animate, flexWrap, index }) => {
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        !data && animate ?
            <div className='border rounded-xl p-2 animate-pulse'>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-start gap-2'>
                        <div className=' bg-gray-200 rounded-full h-[3rem] w-[3rem]'></div>
                        <div className='flex-1'>
                            <p className={`bg-gray-200 rounded-md h-6 mx-1`}></p>
                            <div className='flex items-center gap-1.5 justify-between my-2 text-xs'>
                                <p className={`bg-gray-200 rounded-md w-[30%] h-6 mx-1`}></p>
                                <p className={`bg-gray-200 rounded-md w-[30%] h-6 mx-1`}></p>
                                <p className={`bg-gray-200 rounded-md w-[30%] h-6 mx-1`}></p>
                            </div>
                        </div>
                    </div>
                    <p className={`bg-gray-200 rounded-md w-full h-6 mx-1`}></p>
                </div>
            </div>
            :
            <div className='border rounded-xl p-2 bg-white'>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-start gap-2'>
                        {data?.image ?
                            <div>
                                <img src={data.image} alt="" className='h-[3rem] w-[3rem] rounded-full object-cover' />
                            </div>
                            :
                            <LabelAvatar className='!h-[3rem] !w-[3rem] rounded-full ' fontSize={'text-2xl'} index={index} iconLabel={data?.full_name} />
                        }
                        <div className='flex-1'>
                            <h1 className='font-semibold text-lg'>{data?.full_name}</h1>
                            <div className={`flex items-center gap-1.5 text-xs ${flexWrap}`}>
                                <div className='flex items-center'>
                                    <p>{selectedLanguage?.name == "English" ? "Morning" : translation[1238]?.value ? translation[1238]?.value : "Morning"}:</p>
                                    <p className='font-semibold'>{data?.morning_count}</p>
                                </div>
                                <div className='flex items-center'>
                                    <p>{selectedLanguage?.name == "English" ? "Afternoon" : translation[1237]?.value ? translation[1237]?.value : "Afternoon"}:</p>
                                    <p className='font-semibold'>{data?.afternoon_count}</p>
                                </div>
                                <div className='flex items-center'>
                                    <p>{selectedLanguage?.name == "English" ? "Evening" : translation[1239]?.value ? translation[1239]?.value : "Evening"}:</p>
                                    <p className='font-semibold'>{data?.evening_count}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data?.overall_hint &&
                        <div className='bg-gray-50 p-2 rounded-xl text-xs'>
                            <span className='font-semibold'>Hint:</span> {data?.overall_hint}
                        </div>
                    }
                </div>
            </div>
    )
}

export default AnalyticsCard