import Popup from 'Components/TenantApp/Elements/Popup'
import React from 'react'
import moment from 'moment'
import NoReportDataFound from 'Components/Utility/NoReportDataFound'
import { statusClasses } from 'Constants/Data/Months'
import TableShimer from 'Components/Utility/Shimmers/TableShimer'

const ViewDetailPopup = ({ data, open, close, loading }) => {
    return (
        <Popup open={open} close={close} size={'md'} heading={"View Maintenance Detail"}>
            <div className="grid grid-cols-2 bg-secondary-white border rounded-xl shadow-lg p-4 gap-4 ">
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-1">
                        <h1 className='text-lg font-semibold'>Title:</h1>
                        <h1 className='text-base'>{open?.title || "------"}</h1>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className='text-lg font-semibold'>Description:</h1>
                        <h1 className='text-base'>{open?.description || "------"}</h1>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className='text-lg font-semibold'>Date:</h1>
                        <h1 className='text-base'>{open?.created_at ? moment(open?.created_at).format('DD-MM-YYYY') : "------"}</h1>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-1">
                        <h1 className='text-lg font-semibold'>Priority Level:</h1>
                        <h1 className='text-base'>{open?.priority_level || "------"}</h1>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className='text-lg font-semibold'>Requested By:</h1>
                        <h1 className='text-base'>{open?.requested_by || "------"}</h1>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className='text-lg font-semibold'>Resolved By:</h1>
                        <h1 className='text-base'>{open?.resolved_by || "------"}</h1>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className='text-lg font-semibold'>Status:</h1>
                        <h1 className={`w-base w-full max-w-32 px-2 py-1 rounded text-sm font-bold ${statusClasses[open?.status] || 'bg-gray-100 text-gray-800'}`}
                        >{open?.status || "------"}</h1>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 w-full bg-white border rounded-xl mt-5 shadow-md py-4'>
                <h1 className='text-xl font-bold border-b pb-1 px-4'>Maintenance History</h1>
                {loading
                    ? <TableShimer cols={3} rows={4} />
                    : <div className="overflow-x-auto">
                        <div className={`inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                            <p className=''>Updated By</p>
                            <p className=''>Date</p>
                            <p className=''>Status</p>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((item, index) => {
                                return (
                                    <div key={index} className={`text-xs items-center  px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))]  min-w-full w-fit`}>
                                        <p className='pr-2'>
                                            {item?.changed_by || "------"}
                                        </p>
                                        <p className='pr-2'>
                                            {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY') : "------"}
                                        </p>
                                        <p className={`px-2 py-1 rounded text-sm font-bold ${statusClasses[item?.status] || 'bg-gray-100 text-gray-800'} w-fit`}>
                                            {item?.status || "------"}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <NoReportDataFound />
                        )}
                    </div>
                }
            </div>
        </Popup >
    )
}

export default ViewDetailPopup
