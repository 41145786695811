import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BusinessT from 'Assets/svgs/Login/BusinessT'
import { getBusinessTypes } from 'Adapters/Api';
import NotFound from '../../../Utility/NotFound';

const BusinessType = ({ onSelectType, selected_types }) => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    useEffect(() => {
        dispatch(getBusinessTypes())
    }, []);

    return (
        <div className='w-full flex items-center'>
            <div className='flex flex-col gap-5 w-full lg:items-start items-center text-center lg:text-left'>
                <BusinessT />
                <h3 className='font-semibold text-2xl'>
                    {selectedLanguage?.name == "English" ? "Choose Your Business Type" : translation[698]?.value ? translation[698]?.value : "Choose Your Business Type"}
                </h3>
                <p className='text-[#7B7B7B]'>
                    {selectedLanguage?.name == "English" ? "Please provide information about the type of your business." : translation[699]?.value
                        ? translation[699]?.value : "Please provide information about the type of your business."}
                </p>
                <div className='flex items-center gap-8 flex-wrap'>
                    {
                        state.business?.business_types_updated ?
                            state.business?.business_types?.length > 0 ?
                                state.business.business_types?.map((type, i) => {
                                    return (
                                        <div
                                            key={i}
                                            onClick={(e) => {
                                                onSelectType(type.id)
                                            }}
                                            className={`px-8 py-4 border-[2px] bg-white rounded-lg flex flex-col gap-3 justify-center cursor-pointer text-center ${selected_types.includes(type.id) ? 'border-primary bg-[#F2F7F780]' : ''}`}>
                                            <img src={`${type.image}`} className="mx-auto" />
                                            <p className='font-semibold text-sm'>{type.name}</p>
                                        </div>
                                    )
                                })
                                :
                                <>
                                    <NotFound />
                                </>
                            :
                            <>

                                {selectedLanguage?.name == "English" ? "loading" : translation[160]?.value ? translation[160]?.value : "loading"} ...
                            </>
                    }
                </div>

            </div>
        </div>
    )
}

export default BusinessType