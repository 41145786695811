import React from "react";
import LocationSelection from "new-components/location";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import useMembershipSoldHelper from "./helper";
import MembershipSaleReport from "../../Reports/SalesReports/Tabs/MembershipReport";

const MembershipSold = () => {
    const {
        translation, selectedLanguage, storeDataInLocalStorage
    } = useMembershipSoldHelper();

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative bg-primary-background">
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                url={"/dashboard/clients/"}
                second={"Membership Report"}
            />

            <div className="flex items-center justify-between sm:gap-4 gap-2 my-[1rem]">
                <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                    Membership Sold Report
                </h2>

                <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
            </div>
            <MembershipSaleReport isMemberShipClient={true} />
        </div>
    );
};

export default MembershipSold;
