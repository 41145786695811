import Svgs from 'Assets/svgs';
import Footer from 'Components/Utility/Footer';
import Navbar from 'Components/Utility/Navbar';
import React, { useState } from 'react';

const PrivacyPolicyV2 = () => {
  const [activeSection, setActiveSection] = useState('introduction');

  const sidebarItems = [
    { id: 'introduction', label: 'Introduction' },
    { id: 'information', label: 'Information We Collect' },
    { id: 'how-we-use', label: 'How We Use Your Information' },
    { id: 'data-protection', label: 'Data Protection & Security' },
    { id: 'sharing', label: 'Sharing Your Information' },
    { id: 'cookies', label: 'Cookies & Tracking' },
    { id: 'rights', label: 'Your Rights & Choices' },
    { id: 'updates', label: 'Policy Updates' },
    { id: 'contact', label: 'Contact Us' },
  ];

  return (
    <div className="flex flex-col w-full font-manrope landing_page">
      <Navbar />
      <div className="flex w-full bg-secondary-white h-60">
        <div className="flex justify-center items-center h-full relative mx-auto w-[95%] max-w-5xl">
          <div className="flex gap-6 sm:gap-12 z-10 items-center md:-ml-40">
            <div><Svgs.PrivacyIcon /></div>
            <h1 className='text-6xl font-bold'>Privacy Policy</h1>
          </div>

          <div className="absolute bottom-0 h-[13.5rem] overflow-hidden right-0">
            <Svgs.PrivacyRightIcon />
          </div>
          <div className="absolute bottom-0 left-10">
            <Svgs.PrivacyLeftIcon />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 mx-auto w-[95%] max-w-[1200px]">
        {/* Sidebar */}
        <div className="bg-white pr-4 sm:pr-9">
          <div className="flex flex-col py-7 sm:py-14">
            {sidebarItems.map((item) => (
              <div
                key={item.id}
                className={`py-3 sm:py-5 border-b text-base font-bold cursor-pointer ${activeSection === item?.id ? 'text-primary-blue' : 'text-black'}`}
                onClick={() => setActiveSection(item.id)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="col-span-2 px-5 border-l border-[#DBDEE2]">
          <div className="flex flex-col gap-8 py-8 sm:py-16">
            <div className="flex flex-col gap-2.5">
              <h1 className="text-3xl font-bold mb-4">Welcome to NStyle</h1>
              <p className="text-sm">
                Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our website.
              </p>
            </div>

            {/* Types of Personal Data */}
            <div className="flex flex-col gap-5">
              <h2 className="text-2xl font-bold">
                We may collect the following types of personal data when you visit or interact with our website:
              </h2>
              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <div className="flex flex-1 flex-col text-sm">
                  <h3 className="font-bold">Personal Information:</h3>
                  <p className="font-normal">Name, email, phone number, and address (if provided).</p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <div className="flex flex-1 flex-col text-sm">
                  <h3 className="font-bold">Booking Details:</h3>
                  <p className="font-normal">Information related to your salon appointments.</p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <div className="flex flex-1 flex-col text-sm">
                  <h3 className="font-bold">Payment Information:</h3>
                  <p className="font-normal">Secure transaction details (we do not store card details).</p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <div className="flex flex-1 flex-col text-sm">
                  <h3 className="font-bold">Website Usage Data:</h3>
                  <p className="font-normal">IP address, browser type, and pages visited.</p>
                </div>
              </div>
            </div>

            {/* How We Use Your Information */}
            <div className="flex flex-col gap-5">
              <h2 className="text-2xl font-bold">How We Use Your Information</h2>

              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <p className="flex-1 text-sm font-normal">Process bookings and manage appointments.</p>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <p className="flex-1 text-sm font-normal">Improve our services and customer experience.</p>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <p className="flex-1 text-sm font-normal">Respond to inquiries and provide customer support.</p>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <p className="flex-1 text-sm font-normal">Send promotional offers, updates, and newsletters (only with your consent).</p>
              </div>
            </div>

            {/* Sharing Your Information */}
            <div className="flex flex-col gap-5">
              <h2 className="text-2xl font-bold">Sharing Your Information</h2>

              <p className="flex-1 text-sm font-bold">Legal authorities if required by law.</p>

              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <p className="flex-1 text-sm font-normal">Trusted service providers (payment processors, booking systems).</p>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex-shrink-0 mt-1"><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                <p className="flex-1 text-sm font-normal">Legal authorities if required by law.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default PrivacyPolicyV2;