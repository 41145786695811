import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Textarea from "../Elements/Textarea";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Header from "Components/NstyleApp/Wrappers/Header";
import StarRatings from "./StarsRating";
import { submitCustomerFeedbackAPI } from "Adapters/Api/complaints";

const CustomerComplaintFeedBack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams()
  const tenant = searchParams.get("tenant");

  const { id } = useParams();

  // useStates
  const [input, setInput] = useState({
    rating: 0,
    description: "",
  });
  const [error, setError] = useState({
    rating: "",
    description: "",
  });

  const [btn_loading, setBtnLoading] = useState(false);

  // onChange function
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  // validating input fields
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "rating":
          if (!value || value === 0) {
            stateObj[name] = "Please select a rating.";
          }
          break;

        case "description":
          if (!value) {
            stateObj[name] = "Please enter your feedback.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  // function to submit customer feedback
  const handleSubmitFeedback = () => {
    setBtnLoading(true);

    // Validate all fields
    const fields = ["rating", "description"];
    let hasError = false;

    fields.forEach(field => {
      if (!input[field] || (field === "rating" && input[field] === 0)) {
        setError(prev => ({ ...prev, [field]: `Please ${field === "rating" ? "select a rating" : "enter your feedback"}.` }));
        hasError = true;
      }
    });

    if (hasError) {
      setBtnLoading(false);
      return;
    }

    const success = (result) => {
      setBtnLoading(false);
      navigate("/");
    }

    const fail = (error) => {
      if (error?.response?.status == 400) {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
      setBtnLoading(false);
    }

    const payload = {
      id: id,
      rating: input?.rating,
      feedback_text: input?.description,
      tenant: tenant
    }

    dispatch(submitCustomerFeedbackAPI(payload, success, fail));
  };

  return (
    <>
      <Header />
      <div className="h-full flex items-center justify-center top-3.5 m-auto w-[95%] max-w-2xl mt-20">
        <div className="w-full shadow-md relative p-5 border rounded-xl">
          <div className="flex flex-col gap-4">
            <h2 className="font-semibold text-xl mb-5">Customer Feedback</h2>

            <div className="space-y-2">
              <h4 className="">Rating <span className="text-red-500">*</span> </h4>
              <StarRatings
                name={"ratings"}
                className="h-7 items-center"
                value={input.rating}
                totalStars={5}
                allowHalfStar={false}
                onChange={(event) => {
                  setInput((prev) => ({
                    ...prev,
                    rating: event.target.value
                  }))
                  setError((prev) => ({
                    ...prev,
                    rating: ""
                  }))
                }}
              />
              {error.rating && (
                <p className="text-red-500 text-sm">{error.rating}</p>
              )}
            </div>
            <div>
              <Textarea
                name="description"
                title="Feedback"
                required={true}
                placeholder="Please share your experience..."
                value={input.description}
                onChange={onInputChange}
                onBlur={validateInput}
                error={error.description}
              />
            </div>

            <LoginBtn
              text="Submit Feedback"
              loading={btn_loading}
              onClick={handleSubmitFeedback}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerComplaintFeedBack;
