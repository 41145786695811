import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';

import Svgs from '../../../../../../Assets/svgs'
import CircleIcon from '../../../../Elements/CircleIcon'
import Dropwdown from '../../../../Elements/Dropwdown'
import InputWithIcon from '../../../../Elements/InputWithIcon'
import LoginBtn from '../../../../Elements/LoginBtn'
import LoginInput from '../../../../Elements/LoginInput'
import CardTile from '../../../CardTile'
import { useNavigate } from 'react-router-dom'
import ToggleWithText from '../../../../Elements/ToggleWithText'
import useSetDataInLocalStorage from './../../../../../../Hooks/useSetDataInLocalStorage';
// import { getBusinessNotificationSettings } from '../../../../../../Adapters/Api'
import useNotication from './useNotication'
import useObjectKeys from '../../../../../../Hooks/useObjectKeys'
import { addNotifications } from '../../../../../../Redux/TenantRedux/Actions/notificationActions'
import { useSelector } from 'react-redux';
import { getBusinessNotificationSettings } from '../../../../../../Adapters/Api/notificationsSettings';

const Notification = () => {
    const dispatch = useDispatch();
    const [submiting, setSubmiting] = useState(false)
    const [loading, setLoading] = useState(true)
    // const state = useSelector((state) => state.notification.notifications);
    const state = useSelector((state) => state);

    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);


    const { notificationSettings, selectedNotificationsSetttings, setNotificationSettings, onSaveChanges } = useNotication();

    const {
        sms_daily_sale,
        email_daily_sale,
        sms_purchase_plan,
        sms_for_rewards_on_quick_sale,
        sms_pending_services_quicksale,
        sms_for_ewallet_balance_on_quick_sale,
        sms_pending_payment,
        email_notify_on_purchase_plan,
        sms_quick_sale,
        sms_appoinment,
        sms_appoinment_reschedule,
        sms_birthday,
        sms_notify_on_appoinment,
        sms_notify_on_quick_sale,
        sms_notify_for_daily_book,
        email_notify_on_appoinment,
        email_notify_on_quick_sale,
        email_notify_on_daily_book,
        notify_for_lowest_stock,
        notify_stock_turnover,
    } = notificationSettings;
    const navigate = useNavigate();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const businessData = getDataFromLocalStorage("business_data")
    const access_token = getDataFromLocalStorage("access_token");

    const getBusinessNiticationSettingsDetails = async (business_id) => {
        const response = await getBusinessNotificationSettings(business_id, access_token);
        setLoading(false)
        if (response.status === 200) {
            const settings = response?.data?.response?.settings;
            setNotificationSettings(
                {
                    sms_daily_sale: settings.sms_daily_sale,
                    email_daily_sale: settings.email_daily_sale,
                    sms_purchase_plan: settings.sms_purchase_plan,
                    sms_for_rewards_on_quick_sale: settings.sms_for_rewards_on_quick_sale,
                    sms_pending_services_quicksale: settings.sms_pending_services_quicksale,
                    sms_for_ewallet_balance_on_quick_sale: settings.sms_for_ewallet_balance_on_quick_sale,
                    sms_pending_payment: settings.sms_pending_payment,
                    email_notify_on_purchase_plan: settings.email_notify_on_purchase_plan,
                    sms_quick_sale: settings.sms_quick_sale,
                    sms_appoinment: settings.sms_appoinment,
                    sms_appoinment_reschedule: settings.sms_appoinment_reschedule,
                    sms_birthday: settings.sms_birthday,
                    sms_notify_on_appoinment: settings.sms_notify_on_appoinment,
                    sms_notify_on_quick_sale: settings.sms_notify_on_quick_sale,
                    sms_notify_for_daily_book: settings.sms_notify_for_daily_book,
                    email_notify_on_appoinment: settings.email_notify_on_appoinment,
                    email_notify_on_quick_sale: settings.email_notify_on_quick_sale,
                    email_notify_on_daily_book: settings.email_notify_on_daily_book,
                    notify_for_lowest_stock: settings.notify_for_lowest_stock,
                    notify_stock_turnover: settings.notify_stock_turnover,

                }
            )
            dispatch(addNotifications(settings));
        }
    }


    useEffect(() => {
        if (businessData.id) {
            getBusinessNiticationSettingsDetails(businessData.id)
        }
    }, []);


    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >
                <p className='text-[#7B7B7B] text-sm'>
                    <span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/') }}>
                        {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
                    </span>
                    {' '} &gt; {' '}
                    <span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/setup/') }}>
                        {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
                    </span>
                    {' '} &gt; {' '}
                    <span className='text-primary font-semibold'>
                        {selectedLanguage?.name == "English" ? "Notification" : translation[768]?.value ? translation[768]?.value : "Notification"}
                    </span>
                </p>
                <div className='my-[1rem] flex items-center justify-between gap-4 flex-wrap'>
                    <div className='flex items-center sm:gap-4 gap-2'>

                        <h2 className='text-primary font-bold lg:text-3xl text-xl'>
                            {selectedLanguage?.name == "English" ? "Notification" : translation[768]?.value ? translation[768]?.value : "Notification"}
                        </h2>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Staff />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>
                                {selectedLanguage?.name == "English" ? "Staff Notifications" : translation[769]?.value ? translation[769]?.value : "Staff Notifications"}
                            </h2>
                            <p className='text-[#7B7B7B] text-sm'>
                                {selectedLanguage?.name == "English"
                                    ? "Select notifications that you want to send to the staff."
                                    : translation[770]?.value
                                        ? translation[770]?.value
                                        : "Select notifications that you want to send to the staff."
                                }
                            </p>
                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-6'>
                        <ToggleWithText
                            disabled={loading}
                            value={sms_daily_sale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_daily_sale: !sms_daily_sale }))}
                            checked={sms_daily_sale}
                            name={"sms_daily_sale"}
                            title={selectedLanguage?.name == "English" ? "Emails notification on daily sales." : translation[771]?.value ? translation[771]?.value : "Emails notification on daily sales."}
                        />

                        {/* <ToggleWithText
                            disabled={loading}
                            value={email_daily_sale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, email_daily_sale: !email_daily_sale }))}
                            checked={email_daily_sale}
                            name={"email_daily_sale"}
                            title='Email notification on daily sales' /> */}

                    </div>

                    <hr className='bg-[#3F3F441A] my-[1.5rem]' />

                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Client />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>
                                {selectedLanguage?.name == "English" ? "Client Notifications" : translation[772]?.value ? translation[772]?.value : "Client Notifications"}
                            </h2>
                            <p className='text-[#7B7B7B] text-sm'>
                                {selectedLanguage?.name == "English"
                                    ? "Select notifications that your clients must receive on sales/appointments, etc"
                                    : translation[773]?.value
                                        ? translation[773]?.value
                                        : "Select notifications that your clients must receive on sales/appointments, etc"
                                }.</p>
                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-6'>
                        <ToggleWithText
                            disabled={loading}
                            value={sms_purchase_plan}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_purchase_plan: !sms_purchase_plan }))}
                            checked={sms_purchase_plan}
                            name={"sms_purchase_plan"}
                            title={selectedLanguage?.name == "English" ? "Email notification on the purchase plan" : translation[771]?.value ? translation[771]?.value : "Email notification on the purchase plan"}
                        />

                        {/* <ToggleWithText
                            disabled={loading}
                            value={email_notify_on_purchase_plan}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, email_notify_on_purchase_plan: !email_notify_on_purchase_plan }))}
                            checked={email_notify_on_purchase_plan}
                            name={"email_notify_on_purchase_plan"}
                            title='Email notification on the purchase plan' /> */}

                        {/* <ToggleWithText
                            disabled={loading}
                            value={sms_for_rewards_on_quick_sale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_for_rewards_on_quick_sale: !sms_for_rewards_on_quick_sale }))}
                            checked={sms_for_rewards_on_quick_sale}
                            name={"sms_for_rewards_on_quick_sale"}
                            title='Email Notification for Rewards on Quick Sale' /> */}

                        <ToggleWithText
                            disabled={loading}
                            value={sms_quick_sale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_quick_sale: !sms_quick_sale }))}
                            checked={sms_quick_sale}
                            name={"sms_quick_sale"}
                            title={selectedLanguage?.name == "English" ? "Quick sales email" : translation[775]?.value ? translation[775]?.value : "Quick sales email"}
                        />

                        {/* <ToggleWithText
                            disabled={loading}
                            value={sms_pending_services_quicksale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_pending_services_quicksale: !sms_pending_services_quicksale }))}
                            checked={sms_pending_services_quicksale}
                            name={"sms_pending_services_quicksale"}
                            title={selectedLanguage?.name == "English" ? "Pending services quick sale email" : translation[776]?.value ? translation[776]?.value : "Pending services quick sale email"}
                        /> */}

                        <ToggleWithText
                            disabled={loading}
                            value={sms_appoinment}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_appoinment: !sms_appoinment }))}
                            checked={sms_appoinment}
                            name={"sms_appoinment"}
                            title={selectedLanguage?.name == "English" ? "Appointment email" : translation[777]?.value ? translation[777]?.value : "Appointment email"}
                        />

                        {/* <ToggleWithText
                            disabled={loading}
                            value={sms_for_ewallet_balance_on_quick_sale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_for_ewallet_balance_on_quick_sale: !sms_for_ewallet_balance_on_quick_sale }))}
                            checked={sms_for_ewallet_balance_on_quick_sale}
                            name={"sms_for_ewallet_balance_on_quick_sale"}
                            title='SMS Notification for Ewallet Balance on Quick Sale' /> */}

                        <ToggleWithText
                            disabled={loading}
                            value={sms_appoinment_reschedule}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_appoinment_reschedule: !sms_appoinment_reschedule }))}
                            checked={sms_appoinment_reschedule}
                            name={"sms_appoinment_reschedule"}
                            title={selectedLanguage?.name == "English" ? "Appointment reschedule email" : translation[778]?.value ? translation[778]?.value : "Appointment reschedule email"}
                        />

                        {/* <ToggleWithText
                            disabled={loading}
                            value={sms_pending_payment}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_pending_payment: !sms_pending_payment }))}
                            checked={sms_pending_payment}
                            name={"sms_pending_payment"}
                            title='Pending Payment Email' />

                        <ToggleWithText
                            disabled={loading}
                            value={sms_birthday}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_birthday: !sms_birthday }))}
                            checked={sms_birthday}
                            name={"sms_birthday"}
                            title='Birthday SMS' /> */}

                    </div>

                    <hr className='bg-[#3F3F441A] my-[1.5rem]' />

                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Profile_Cog />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>
                                {selectedLanguage?.name == "English" ? "Admin Notifications" : translation[779]?.value ? translation[779]?.value : "Admin Notifications"}
                            </h2>
                            <p className='text-[#7B7B7B] text-sm'>
                                {selectedLanguage?.name == "English"
                                    ? "Manage essential notifications for admin on sales, appointment, stock, etc."
                                    : translation[780]?.value
                                        ? translation[780]?.value
                                        : "Manage essential notifications for admin on sales, appointment, stock, etc."}

                            </p>
                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-6'>
                        <ToggleWithText
                            disabled={loading}
                            value={sms_notify_on_appoinment}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_notify_on_appoinment: !sms_notify_on_appoinment }))}
                            checked={sms_notify_on_appoinment}
                            name={"sms_notify_on_appoinment"}
                            title={selectedLanguage?.name == "English" ? "Email notification on appointment" : translation[781]?.value ? translation[781]?.value : "Email notification on appointment"}

                        />
                        {/* 
                        <ToggleWithText
                            disabled={loading}
                            value={email_notify_on_appoinment}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, email_notify_on_appoinment: !email_notify_on_appoinment }))}
                            checked={email_notify_on_appoinment}
                            name={"email_notify_on_appoinment"}
                            title='SMS Notification on Redeem' /> 

                        <ToggleWithText
                            disabled={loading}
                            value={sms_notify_on_quick_sale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_notify_on_quick_sale: !sms_notify_on_quick_sale }))}
                            checked={sms_notify_on_quick_sale}
                            name={"sms_notify_on_quick_sale"}
                            title='Email Notification on Appointment Remainder' />
                            */}
                        {/* 
                        <ToggleWithText
                            disabled={loading}
                            value={email_notify_on_quick_sale}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, email_notify_on_quick_sale: !email_notify_on_quick_sale }))}
                            checked={email_notify_on_quick_sale}
                            name={"email_notify_on_quick_sale"}
                            title='Email Notification For Daily Booking' /> */}

                        <ToggleWithText
                            disabled={loading}
                            value={email_notify_on_daily_book}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, email_notify_on_daily_book: !email_notify_on_daily_book }))}
                            checked={email_notify_on_daily_book}
                            name={"email_notify_on_daily_book"}
                            title={selectedLanguage?.name == "English" ? "Email notification for daily booking" : translation[782]?.value ? translation[782]?.value : "Email notification for daily booking"}

                        />

                        {/* <ToggleWithText
                            disabled={loading}
                            value={sms_notify_for_daily_book}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, sms_notify_for_daily_book: !sms_notify_for_daily_book }))}
                            checked={sms_notify_for_daily_book}
                            name={"sms_notify_for_daily_book"}
                            title='SMS Notification For Daily Book' /> */}

                    </div>

                    <hr className='bg-[#3F3F441A] my-[1.5rem]' />

                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Stock />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>
                                {selectedLanguage?.name == "English" ? "Stock Notifications" : translation[783]?.value ? translation[783]?.value : "Stock Notifications"}

                            </h2>
                            <p className='text-[#7B7B7B] text-sm'>
                                {selectedLanguage?.name == "English"
                                    ? "Manage stock notifications for admin to receive the stock updates."
                                    : translation[784]?.value
                                        ? translation[784]?.value
                                        : "Manage stock notifications for admin to receive the stock updates."}
                            </p>
                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-6'>
                        <ToggleWithText
                            disabled={loading}
                            value={notify_for_lowest_stock}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, notify_for_lowest_stock: !notify_for_lowest_stock }))}
                            checked={notify_for_lowest_stock}
                            name={"notify_for_lowest_stock"}
                            title={selectedLanguage?.name == "English" ? "Email alert when stock becomes the lowest" : translation[785]?.value ? translation[785]?.value : "Email alert when stock becomes the lowest"}

                        />

                        <ToggleWithText
                            disabled={loading}
                            value={notify_stock_turnover}
                            onChange={() => setNotificationSettings((prev) => ({ ...prev, notify_stock_turnover: !notify_stock_turnover }))}
                            checked={notify_stock_turnover}
                            name={"notify_stock_turnover"}
                            title={selectedLanguage?.name == "English" ? "Email alert on highest/lowest turnover" : translation[786]?.value ? translation[786]?.value : "Email alert on highest/lowest turnover"}
                        />

                    </div>
                    <div className='flex items-center justify-start mt-[2rem]'>
                        {!employee ? (
                            <LoginBtn
                                padding=''
                                onClick={async () => {
                                    setSubmiting(true)
                                    onSaveChanges()
                                    setTimeout(() => {
                                        setSubmiting(false)
                                    }, 500);
                                }}

                                loading={submiting}
                            >
                                {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                            </LoginBtn>
                        ) : employee && employeePermissions?.account_notification_setting?.includes("edit") ? (
                            <LoginBtn
                                padding=''
                                onClick={async () => {
                                    setSubmiting(true)
                                    onSaveChanges()
                                    setTimeout(() => {
                                        setSubmiting(false)
                                    }, 500);
                                }}

                                loading={submiting}
                            >
                                {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                            </LoginBtn>
                        ) : employee && employeePermissions?.pos_root_access?.includes("view") ?
                            (
                                <LoginBtn

                                    disabled={true}
                                >
                                    {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                                </LoginBtn>
                            ) : ("")}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification