import React from 'react'
import useTaxReports from './helpers';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import { DateRange } from 'react-date-range';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import TaxReportTable from './TaxReportTable';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import LocationSelection from "new-components/location";
import TaxChart from '../../Calendar/Elements/TaxChart';

const TaxReports = () => {
    const {
        selectedDateRange, selectedLanguage, storeDataInLocalStorage, translation, loading, ToggleCalendarRange, setToggleCalendarRange, handleDateChange, StartDate, EndDate,
        taxReportData, taxReportTableFields, chartLoading, taxReportChartData, onSubmit, location_currency, topRef
    } = useTaxReports()

    return (
        <>
            <div ref={topRef} className="lg:px-[2rem] lg:pt-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-Fright bg-primary-background">
                <div className="flex h-fit flex-col gap-5">
                    <div className="flex flex-col items-start gap-2 md:gap-4">
                        <Breadcrumb
                            first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                            url={'/dashboard/reports/'}
                            second={selectedLanguage?.name == "English" ? 'Tax Reports' : translation[378]?.value ? translation[378]?.value : 'Tax Reports'}
                        />
                        <div className="flex items-center justify-between md:gap-5 gap-7 flex-wrap">
                            <div className="flex justify-between gap-3 flex-1">
                                <div>
                                    <div className="font-semibold lg:text-2xl text-xl">
                                        Tax Reports Bar Chart
                                    </div>
                                </div>
                                <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
                            </div>
                        </div>
                    </div>
                    <div className="flex  gap-5 justify-end">
                        <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                            <div className='p-2 relative'>
                                <p onClick={() => { setToggleCalendarRange(!ToggleCalendarRange) }} className='cursor-pointer'>{`${StartDate} - ${EndDate}`}</p>
                                {
                                    ToggleCalendarRange && <>
                                        <div className='fixed inset-0 z-1' onClick={() => { setToggleCalendarRange(false) }}></div>
                                        <div className='absolute top-full right-0 z-[2]'>
                                            <DateRange
                                                ranges={selectedDateRange}
                                                onChange={handleDateChange}
                                                maxDate={new Date()}
                                                className='border border-primary rounded-lg' />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <LoginBtn
                            title={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                            text={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                            onClick={() => { onSubmit() }}
                        />

                    </div>
                    <div className="h-fit xl:h-[32rem] p-3 border bg-white border-[#3F3F441A] rounded-lg !pt-5">
                        <TaxChart data={taxReportChartData} loading={chartLoading} />
                    </div>

                    <div className="rounded-xl bg-white overflow-hidden border-[#3F3F441A] border">
                        {loading
                            ? <TableShimer cols={5} rows={10} />
                            : <TaxReportTable data={taxReportData} tableFields={taxReportTableFields} location_currency={location_currency} />
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default TaxReports
