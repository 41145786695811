import Svgs from "../../../../../../Assets/svgs";
import React, { useEffect, useState, useMemo } from "react";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import LoginInput from "../../../../Elements/LoginInput";
import Dropwdown from "../../../../Elements/Dropwdown";
import LoginBtn from "../../../../Elements/LoginBtn";
import { useSelector } from "react-redux";
import Textarea from "../../../../Elements/Textarea";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import { ValidityTime } from "Constants/Data/AppointmentsData";
// import NumberInput from "Components/TenantApp/Elements/NumberInput";
let initialState = {
    title: "",
    description: "",
    currency_gift_card_price: [],
    valid_till: "",
    term_condition: "",
}

const AddGiftCard = ({
    data,
    onDelete,
    is_edit,
    onUpdate,
    index,
    onEditClick,
    currency,
    editError,
    onBlurHandler,
    errors,
    setErrors,
    setEditError,
    updateLoading,
    translation, selectedLanguage, formRef, submitButtonClick,
    ...props
}) => {
    const state = useSelector((state) => state);
    const locationList = state.locations.locations;
    const currencyList = state?.utility?.currency?.currency_list;
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");

    let business = businessData?.id;

    // let pricingType = "test";
    const [giftCardData, setGiftCardData] = useState(initialState);
    const { title, valid_till, currency_gift_card_price, description, term_condition, is_active } = giftCardData;

    const [price, setPrice] = useState('')
    const [retail_price, setRetail_price] = useState('')
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [editId, seteditId] = useState("");
    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);

    let allUinqueCurrencyList = [];

    locationList?.filter((item) => item?.currency?.id).filter((itm) => {
        if (!allUinqueCurrencyList.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
            allUinqueCurrencyList.push(itm)
            return true
        }
    })

    useEffect(() => {
        if (allUinqueCurrencyList?.length == giftCardData?.currency_gift_card_price?.length) {
            setEditError({
                ...editError,
                price: '',
            });
        }
    }, [giftCardData?.currency_gift_card_price?.length, allUinqueCurrencyList?.length])

    //set currency and membership price object here
    const addNewItem = () => {
        setGiftCardData((prev) => ({
            ...prev,
            currency_gift_card_price: [
                ...prev?.currency_gift_card_price,
                { currencies: selectedCurrency, price: price, retail_price: retail_price }
            ]
        }))
        setSelectedCurrency();
        setRetail_price()
        setPrice()
    }

    // **************************** remove selected items *********************************
    const removedItem = (value, type) => {
        if ((type === "price")) {
            if (giftCardData?.currency_gift_card_price?.find((itm) => itm?.currencies == value)) {
                setGiftCardData((prev) => ({
                    ...prev,
                    currency_gift_card_price: [...prev?.currency_gift_card_price?.filter((itm) => itm?.currencies != value)],
                }));
            }
        } else {
            if (giftCardData?.currency_gift_card_price?.find((itm) => itm?.currencies == value)) {
                setGiftCardData((prev) => ({
                    ...prev,
                    currency_gift_card_price: [...prev?.currency_gift_card_price?.map((itm) => {
                        if (itm?.currencies === value) {
                            return {
                                ...itm,
                                is_deleted: true
                            }
                        }
                        return itm
                    })],
                }));
            }
        }

    };

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    const handleGiftCardData = (e) => {
        const { name, value } = e.target;
        let trimmedValue = value;

        if (name === "description") {
            trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters

            if (value.length > 150) {
                setErrors((prev) => ({
                    ...prev,
                    // description: "You can not add more than 150 characters",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    description: "",
                }));
            }
        }

        if (name === "term_condition") {
            trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters

            if (value.length > 150) {
                setErrors((prev) => ({
                    ...prev,
                    // term_condition: "You can not add more than 150 characters",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    term_condition: "",
                }));
            }
        }
        setGiftCardData({
            ...giftCardData,
            [name]: trimmedValue,
        });
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
        setEditError({
            ...editError,
            [name]: false,
        });
    };

    useEffect(() => {
        setGiftCardData({
            ...data,
            currency_gift_card_price: data?.gift_card_details?.map(currency => currency),
        });
    }, [editId]);

    useEffect(() => {
        if (allUinqueCurrencyList?.length == giftCardData?.currency_gift_card_price?.length) {
            setErrors((prev) => ({
                ...prev,
                price: ""
            }))
        }
    }, [giftCardData?.currency_gift_card_price])

    return (
        <>
            <div className="flex gap-6">
                <div className="relative">
                    {index != 0 && (
                        <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                    )}
                    <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                        <div className="h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white">
                            <Svgs.Appointment_Bullet />
                        </div>
                    </div>
                    <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2"></div>
                </div>
                {editId === giftCardData?.id ? (
                    <>
                        <div className='mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4 bg-white'>

                            <LoginInput
                                name={"title"}
                                required={true}
                                title={selectedLanguage?.name == "English" ? "Title" : translation[1426]?.value ? translation[1426]?.value : "Title"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Title" : translation[1428]?.value ? translation[1428]?.value : "Enter Title"}
                                value={title}
                                // charactersMaxLength={25}
                                onChange={handleGiftCardData}
                                // onEnterSubmit={membersubmithandler}
                                onBlur={() => onBlurHandler({ name: title })}
                                error={editError?.title}
                            />

                            <Textarea
                                required={true}
                                name={"description"}
                                value={description}
                                onChange={handleGiftCardData}
                                onBlur={() => onBlurHandler({ description: description })}
                                error={editError?.description}
                                title={selectedLanguage?.name == "English" ? "Description" : translation[1167]?.value ? translation[1167]?.value : "Description"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Description" : translation[1168]?.value ? translation[1168]?.value : "Enter Description"}
                            />

                            <div className='md:grid md:grid-cols-7 flex flex-col gap-4'>
                                <div className='col-span-2'>
                                    <FloatInput
                                        required={true}
                                        name={"price"}
                                        value={price}
                                        parentClass={editError?.price && 'mb-5'}
                                        // onChange={handleGiftCardData}
                                        disabled={allUinqueCurrencyList?.length == giftCardData?.currency_gift_card_price?.length}
                                        onChange={(e) => {
                                            setPrice(e.target.value)
                                            setErrors((prev) => ({
                                                ...prev,
                                                price: ""
                                            }));
                                        }}
                                        onBlur={() => onBlurHandler({ price: price })}
                                        error={editError?.price}
                                        title={selectedLanguage?.name == "English" ? "Pricing" : translation[269]?.value ? translation[269]?.value : "Pricing"}
                                        placeholder={selectedLanguage?.name == "English" ? "Enter Pricing" : translation[270]?.value ? translation[270]?.value : "Enter Pricing"}
                                    />
                                </div>

                                <div className='col-span-2'>
                                    <FloatInput
                                        required={true}
                                        name={"retail_price"}
                                        value={retail_price}
                                        parentClass={editError?.retail_price && 'mb-5'}
                                        // onChange={handleGiftCardData}
                                        disabled={allUinqueCurrencyList?.length == giftCardData?.currency_gift_card_price?.length}
                                        onChange={(e) => {
                                            setRetail_price(e.target.value)
                                            setErrors((prev) => ({
                                                ...prev,
                                                retail_price: ""
                                            }));
                                        }}
                                        onBlur={() => onBlurHandler({ price: retail_price })}
                                        error={editError?.retail_price}
                                        title={selectedLanguage?.name == "English" ? "Selling Price" : translation[294]?.value ? translation[294]?.value : "Selling Price"}
                                        placeholder={selectedLanguage?.name == "English" ? "Enter selling price" : translation[944]?.value ? translation[944]?.value : "Enter selling price"}
                                    />
                                </div>

                                <div className='col-span-2'>
                                    <Dropwdown
                                        required={true}
                                        title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                                        placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                                        name={"selectedCurrency"}
                                        value={selectedCurrency}
                                        onChange={(e) => setSelectedCurrency(e.target.value)}
                                        options={allUinqueCurrencyList?.filter((itm) =>
                                            !currency_gift_card_price?.find(
                                                ({ currencies }) => currencies === itm?.currency?.id
                                            ))?.map((itm) => {
                                                return {
                                                    label: itm?.currency?.code,
                                                    value: itm?.currency?.id,
                                                };
                                            })}
                                    />
                                </div>

                                <div className='col-span-1 flex items-end'>
                                    <LoginBtn
                                        className={(selectedCurrency && price) && "shake-bottom"}
                                        btnCustomHight={'btnCustomHight lg:h-[2.1875rem]'}
                                        disabled={!selectedCurrency || !price || !retail_price}
                                        onClick={addNewItem}
                                    >
                                        <div className='flex items-center gap-2 text-sm'>
                                            <Svgs.Plus />
                                            <p>
                                                {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                            </p>
                                        </div>
                                    </LoginBtn>
                                </div>
                            </div>

                            {currency_gift_card_price?.length > 0 && (
                                <div className="flex items-center flex-wrap gap-2 mt-[2ch]">
                                    {currency_gift_card_price &&
                                        currency_gift_card_price?.map((item, i) => {
                                            const crrncy = currencyList?.find(
                                                (itm) => itm?.id === item?.currencies
                                            );
                                            return (
                                                !item?.is_deleted && (
                                                    <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                                        <p className="text-primary text-sm font-bold">{`${item?.price} ${crrncy?.code}`}</p>
                                                        <span
                                                            onClick={() =>
                                                                removedItem(item?.currencies, "price")
                                                            }
                                                        >
                                                            <Svgs.CirlceClose fill="#5679FF" />
                                                        </span>
                                                    </div>
                                                )
                                            );
                                        })}
                                </div>
                            )}

                            <div className='md:grid grid-cols-2 gap-4'>
                                <Dropwdown
                                    required={true}
                                    name={"valid_till"}
                                    value={valid_till}
                                    onChange={handleGiftCardData}
                                    onBlur={() => onBlurHandler({ valid_till: valid_till })}
                                    error={editError?.valid_till}
                                    options={ValidityTime}
                                    title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                                />
                            </div>
                            <Dropwdown
                                name={"is_active"}
                                value={is_active}
                                onChange={handleGiftCardData}
                                error={errors?.is_active}
                                required={true}
                                title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                placeholder={selectedLanguage?.name == "English" ? "Select status" : translation[483]?.value ? translation[483]?.value : "Select status"}
                                options={[
                                    {
                                        value: true,
                                        label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                    },
                                    {
                                        value: false,
                                        label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                                    },
                                ]}
                            />
                            <Textarea
                                required={true}
                                name={"term_condition"}
                                value={term_condition}
                                onChange={handleGiftCardData}
                                onBlur={() => onBlurHandler({ term_condition: term_condition })}
                                error={editError?.term_condition}
                                title={selectedLanguage?.name == "English" ? "Terms & Conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & Conditions"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Terms & Conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & Conditions"}

                            />
                            <LoginBtn
                                text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                                // disabled={
                                //   !giftCardData?.name ||
                                //   !giftCardData?.description ||
                                //   !giftCardData?.term_condition ||
                                //   // (giftCardData?.currency_gift_card_price?.length < allUinqueCurrencyList?.length) ||
                                //   (giftCardData?.products?.length === 0) ||
                                //   (giftCardData?.services?.length === 0) ||
                                //   !giftCardData?.validity ||
                                //   updateLoading
                                // }
                                disabled={updateLoading}
                                loading={updateLoading}
                                onClick={() => {
                                    onUpdate(giftCardData, seteditId);
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mt-[2rem] rounded-md w-full flex flex-col gap-4">
                            <div className="reward-gradient-1 rounded-lg px-6 py-4">
                                <div className="flex gap-2 flex-col">
                                    <div className="flex items-center justify-between gap-4">
                                        <div>
                                            <h1 className="text-white font-bold text-2xl">
                                                {data?.title}
                                            </h1>
                                            <p className="text-white">
                                                {data?.description}
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            {!employee ||
                                                (employee &&
                                                    employeePermissions?.client_sharing?.includes(
                                                        "edit"
                                                    )) ? (
                                                <div
                                                    className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                                    onClick={() => {
                                                        onEditClick(data?.id);
                                                        seteditId(data?.id);
                                                    }}
                                                >
                                                    <Svgs.Edit fill="#fff" />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {!employee ? (
                                                <div
                                                    className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                                    onClick={() => {
                                                        onDelete(data?.id);
                                                        // setDelete(true)
                                                    }}
                                                >
                                                    <Svgs.Delete fill="#FFF" />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-5 justify-between">
                                        <p className="flex items-center gap-4 flex-wrap">
                                            {
                                                data?.gift_card_details?.map((item, i) => {
                                                    const crrncy = currencyList?.find(
                                                        (itm) => itm?.id === item?.currencies
                                                    );
                                                    return (
                                                        <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                                            <p className="text-primary text-sm font-bold">{`${item?.price} ${crrncy?.code}`} / {`${item?.spend_amount} ${crrncy?.code}`}</p>
                                                        </div>
                                                    )
                                                })}
                                        </p>
                                    </div>
                                    <div className="text-white text-lg">
                                        <div className="flex flex-col gap-2">
                                            <div className="flex items-center gap-5 justify-end">
                                                <p className="text-sm text-white text-right">
                                                    {selectedLanguage?.name == "English" ? "Valid for:" : translation[1181]?.value ? translation[1181]?.value : "Valid for:"}
                                                    {data?.valid_till}{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

AddGiftCard.defaultProps = {
    data: {},
    index: undefined,
    onEditClick: () => { },
    onDelete: () => { },
    onUpdate: () => { },
};

export default AddGiftCard;
