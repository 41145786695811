import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { create_brand, getBrandDropdownlistPagination, get_business_brands, get_business_brands_with_no_pagination, update_brand } from "../../../Adapters/Api/Brand"
import Svgs from "../../../Assets/svgs"
import CircleIcon from "../Elements/CircleIcon"
import LoginBtn from "../Elements/LoginBtn"
import LoginInput from "../Elements/LoginInput"
import Popup from "../Elements/Popup"
import Textarea from "../Elements/Textarea"
import UploadFile from "../Elements/UploadFile"
import { toast } from 'react-toastify'
import Dropwdown from "../Elements/Dropwdown"
import useValidations from './../../../Hooks/useValidations';
import { apiErrorMessage } from "Constants/Data/Errors"
import useObjectKeys from "Hooks/useObjectKeys"



const AddBrandPopup = ({ onclose, edit, edit_data, search_text, setCurrentPage, setNoPaginationLoader }) => {
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const formRef = useRef(null);
    const dispatch = useDispatch()
    const [form_data, setFormData] = useState({ is_active: true });
    const { validateWebsiteUrl, validation } = useValidations()
    const { objectKeyConvertToArray } = useObjectKeys();
    const [error, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [websiteUrlError, setWebsiteUrlError] = useState("")

    const handleChange = (e) => {
        if (e.target.name == 'description') {
            if (e.target.value?.length <= 150) {
                form_data["description"] = e.target.value
                setFormData({ ...form_data })
                setErrors([...error.filter(itm => itm != e.target.name)])
            } else {
                if (!error?.includes("description")) {
                    setErrors([...error, "description"])
                }
            }
        }
        if (e.target.name != 'description') {
            form_data[e.target.name] = e.target.name == 'image' ? e.target.files[0] : e.target.value
            setFormData({ ...form_data })
            setErrors([...error.filter(itm => itm != e.target.name)])

        }
    }

    const blur_change = (e) => {
        if (!form_data[e.target.name]) {
            setErrors([...error.filter(itm => itm != e.target.name), e.target.name])
        }
    }

    const handle_click = () => {
        let checkValidationPayload = {
            name: form_data?.name
        }
        let result = validation(checkValidationPayload);
        setErrors(Object.keys(result));

        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoading(true)
            if (edit) {
                const result = form_data?.website && validateWebsiteUrl(form_data?.website);
                if ((form_data.website && result) || !form_data.website) {
                    dispatch(update_brand(
                        form_data,
                        () => {
                            setCurrentPage(1)
                            dispatch(getBrandDropdownlistPagination(1))
                            dispatch(get_business_brands(1, search_text))
                            dispatch(get_business_brands_with_no_pagination(search_text, setNoPaginationLoader))
                            toast.success('Brand update successfully!', { toastId: "toast" })
                            onclose()
                            setLoading(false)
                        },
                        (error) => {
                            toast.error(error ? error : apiErrorMessage, { toastId: "toast" })
                            setLoading(false)
                        }
                    ))
                } else {
                    setLoading(false)
                    setErrors(([...error, "website"]))
                    setWebsiteUrlError("Website link is incorrect")
                }
            }
            else {
                const result = form_data?.website && validateWebsiteUrl(form_data?.website);
                if ((form_data.website && result) || !form_data.website) {
                    dispatch(create_brand(
                        form_data,
                        (response) => {
                            if (response?.status_code == 201)
                                setCurrentPage(1)
                            dispatch(get_business_brands_with_no_pagination(search_text, setNoPaginationLoader))
                            dispatch(get_business_brands(1, search_text))
                            dispatch(getBrandDropdownlistPagination(1))
                            toast.success('Brand created successfully!', { toastId: "toast" })
                            onclose()
                            setLoading(false)
                        },
                        (error) => {
                            toast.error(error ? error : apiErrorMessage, { toastId: "toast" })
                            setLoading(false)
                        }
                    ))

                } else {
                    setLoading(false)
                    setErrors(([...error, "website"]))
                    setWebsiteUrlError("Website link is incorrect")
                }
            }
        } else {
            formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }

    useEffect(() => {
        if (edit) {
            setFormData(edit_data)
        }
    }, [])
    return (
        <Popup
            heading={edit
                ? selectedLanguage?.name == "English" ? "Update Brand" : translation[923]?.value ? translation[923]?.value : "Update Brand"
                : selectedLanguage?.name == "English" ? "Add Brand" : translation[918]?.value ? translation[918]?.value : "Add Brand"
            }
            close={onclose}
            open={true}
        >

            <div className='flex flex-col gap-4' ref={formRef}>
                <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]'>
                    <CircleIcon>
                        <Svgs.Inventory_Brands_2 />
                    </CircleIcon>
                    <div className='flex flex-col gap-1 lg:text-left text-center'>
                        <h2 className='text-primary font-semibold text-xl'>
                            {selectedLanguage?.name == "English" ? "Brand Details" : translation[919]?.value ? translation[919]?.value : "Brand Details"}
                        </h2>
                        <p className='text-[#7B7B7B] text-sm'>
                            {selectedLanguage?.name == "English" ? "Add brands related to your business and products." : translation[920]?.value ? translation[920]?.value : "Add brands related to your business and products."}
                        </p>
                    </div>
                </div>
                <LoginInput
                    error={error.includes('name')}
                    title={selectedLanguage?.name == "English" ? 'Brand Name' : translation[428]?.value ? translation[428]?.value : "Brand Name"}
                    placeholder={selectedLanguage?.name == "English" ? 'Enter brand name' : translation[921]?.value ? translation[921]?.value : "Enter brand name"}
                    name='name'
                    // charactersMaxLength={25}
                    onChange={handleChange}
                    onBlur={blur_change}
                    disabled={loading}
                    value={form_data.name}
                    onEnterSubmit={handle_click}
                />

                <Textarea
                    error={(error.includes('description')) &&
                        (selectedLanguage?.name == "English" ? "You can not add more than 150 characters" : translation[1200]?.value ? translation[1200]?.value : "You can not add more than 150 characters")
                    }
                    title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter description" : translation[93]?.value ? translation[93]?.value : "Enter description"}
                    rows='5'
                    limit='150'
                    name='description'
                    onChange={handleChange}
                    // onBlur={blur_change}
                    disabled={loading}
                    value={form_data.description}
                    required={false}
                />
                <LoginInput
                    title={selectedLanguage?.name == "English" ? "Website" : translation[917]?.value ? translation[917]?.value : "Website"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter website link" : translation[681]?.value ? translation[681]?.value : "Enter website link"}
                    name='website'
                    onChange={handleChange}
                    error={error.includes('website') && websiteUrlError !== "" ? websiteUrlError : error.includes('website')}
                    // onBlur={blur_change}
                    disabled={loading}
                    value={form_data.website}
                    required={false}
                    onEnterSubmit={handle_click}
                />
                <Dropwdown
                    required={true}
                    title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                    placeholder={selectedLanguage?.name == "English" ? "Select status" : translation[483]?.value ? translation[483]?.value : "Select status"}
                    options={[
                        {
                            value: true,
                            label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                        },
                        {
                            value: false,
                            label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                        },
                    ]}
                    value={form_data.is_active}
                    name='is_active'
                    onChange={(e) => { handleChange(e) }}
                    // onBlur={blur_change}
                    error={error.includes('is_active') && !form_data.status}
                    disabled={loading}
                />
                <div>
                    <UploadFile
                        title={selectedLanguage?.name == "English" ? "Brand Image" : translation[922]?.value ? translation[922]?.value : "Brand Image"}
                        onChange={handleChange}
                        onBlur={blur_change}
                        name='image'
                        multiple={true}
                        disabled={loading}
                        value={form_data.image ? form_data.image : undefined}
                        // value={edit_data.image}
                        edit={edit}
                        mb_file_size={10}
                        required={false}
                    />
                </div>
                <div className='flex items-center justify-end mt-[2rem]'>
                    <LoginBtn
                        onClick={() => { handle_click() }}
                        text={edit
                            ? selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                            : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                        }
                        // disabled={!(error.length == 0 && form_data.name && form_data.image)}
                        disabled={loading}
                        loading={loading}


                    />
                </div>
            </div>
        </Popup>
    )
}

export default AddBrandPopup