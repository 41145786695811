import { apiErrorMessage } from 'Constants/Data/Errors'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getsProductsDropdownList, getsProductsInMembership } from '../../../../../../Adapters/Api/Products'
import { getservice, getserviceDropdownList } from '../../../../../../Adapters/Api/services'
import { createMembers, deleteMember, getMembers, updateMember } from '../../../../../../Adapters/Api/vouchers'
import Svgs from '../../../../../../Assets/svgs'
import useValidations from '../../../../../../Hooks/useValidations'
import useObjectKeys from "Hooks/useObjectKeys";
import { getProducts } from '../../../../../../Redux/TenantRedux/Actions/product'
import { get_members } from '../../../../../../Redux/TenantRedux/Actions/voucherActions'
import NotFound from '../../../../../Utility/NotFound'
import CircleIcon from '../../../../Elements/CircleIcon'
import ColorPalette from '../../../../Elements/ColorPalette'
import Dropwdown from '../../../../Elements/Dropwdown'
import LoginBtn from '../../../../Elements/LoginBtn'
import LoginInput from '../../../../Elements/LoginInput'
import Popup from '../../../../Elements/Popup'
import PreloaderFull from '../../../../Elements/PreloaderFull'
import RadioBtn from '../../../../Elements/RadioBtn'
import Textarea from '../../../../Elements/Textarea'
import AddMembershipCard from './AddMembershipCard'
import { useMemo } from 'react'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox'
import { ValidityTime } from 'Constants/Data/AppointmentsData'
// import NumberInput from 'Components/TenantApp/Elements/NumberInput'


const Membership = () => {


  const formRef = useRef()
  const mainRef = useRef()
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const locationList = state.locations.locations;
  const currencyList = state?.utility?.currency?.currency_list;
  const productRedux = state?.product?.products
  const serviceRedux = state?.service?.services;
  const businessCurrency = state.business.business;
  const membership_updated = state.membership?.membership_updated;

  // const [promoData, setpromoData] = useState([]);

  // const [memberInput, setmemberInput] = useState({})
  const [is_delete, setDelete] = useState(undefined)
  const [btn_loading, setBtnLoading] = useState(false)
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [edit_id, setEditId] = useState(undefined)
  const [duration, setDuration] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [price, setPrice] = useState();
  const [selectedService, setSelectedService] = useState();
  const [product_percentage, setProduct_percentage] = useState('')
  const [servicePercentage, setServicePercentage] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [productPercentage, setProductPercentage] = useState();
  const [submitButtonClick, setSubmitButtonClick] = useState(false)
  // const [submitButtonClick, setSubmitButtonClick] = useState(false)

  const [memberError, setmemberError] = useState({})
  const [editMemberError, setEditMemberError] = useState({})

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const { setErrors, validation, setEditErrors, editErrors, errors, onBlurHandler, validateMoblieNumber } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();

  const [memberInput, setmemberInput] = useState({
    name: "",
    discount: "Free",
    // services: [{ percentage: "", duration: "7 Days", service: "" }],
    // products: [{ percentage: "", duration: "7 Days", product: "" }],
    services: [],
    products: [],
    valid_for: "",
    currency_membership_price: [],
    description: "",
    term_condition: "",
    is_installment: false,
    is_active: true
  })

  let allUinqueCurrencyList = [];

  locationList?.filter((item) => item?.currency?.id)?.filter((itm) => {
    if (!allUinqueCurrencyList?.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
      allUinqueCurrencyList?.push(itm)
      return true
    }
  })

  let serviceList = [];

  serviceRedux?.filter((item) => item?.id)?.filter((itm) => {
    if (!serviceList?.find((dt) => dt?.id === itm?.id)) {
      serviceList?.push(itm)
      return true
    }
  })

  let productList = [];
  productRedux?.filter((item) => item?.id)?.filter((itm) => {
    if (!productList?.find((dt) => dt?.id === itm?.id)) {
      productList?.push(itm)
      return true
    }
  })

  const { name, services, products, valid_for, currency_membership_price, description, term_condition, is_installment, is_active } = memberInput;

  const [pricingType, setpricingType] = useState('Free')

  const servicesDropdown = useMemo(() => {
    return serviceRedux?.map(obj => {
      return {
        label: obj?.name,
        value: obj?.id
      }
    })
  }, [serviceRedux])

  const productDropdown = useMemo(() => {
    return productRedux?.map(obj => {
      return {
        label: obj?.name,
        value: obj?.id
      }
    })
  }, [productRedux])


  const handlememberInput = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value;
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "description") {
      trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters

      if (value.length > 150) {
        setErrors((prev) => ({
          ...prev,
          // description: "You can not add more than 150 characters",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          description: "",
        }));
      }
    }

    if (name === "term_condition") {
      trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters

      if (value.length > 150) {
        setErrors((prev) => ({
          ...prev,
          // term_condition: "You can not add more than 150 characters",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          term_condition: "",
        }));
      }
    }


    setmemberInput({
      ...memberInput,
      [name]: trimmedValue,
    });

  }

  const isInstallmentChangeHandler = (e) => {
    const { name, checked } = e.target;
    setmemberInput({
      ...memberInput,
      [name]: checked,
    });
  }

  const membersubmithandler = () => {
    setSubmitButtonClick(true)
    memberInput['business'] = state?.business?.business?.id
    let requiredFields = {}
    if (memberInput?.discount === "Free") {
      requiredFields = { name, description, valid_for, term_condition, percentage: product_percentage }
    } else {
      requiredFields = { name, description, valid_for, term_condition }
    }
    // if ((Object.keys(memberInput).includes("products") && memberInput['products']) || (Object.keys(memberInput).includes("service") && memberInput['service'])) {
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if ((objectKeyConvertToArray(result)?.length === 0)) {
      if (allUinqueCurrencyList?.length != memberInput?.currency_membership_price?.length) {
        setErrors((prev) => ({
          ...prev,
          price: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
        }))
      }
      else {
        setErrors((prev) => ({
          ...prev,
          price: "" // Remove the error message
        }));

        setBtnLoading(true)
        dispatch(
          createMembers(
            memberInput,
            () => {
              setProduct_percentage('')
              setDuration('')
              setmemberError({})
              setServicePercentage()
              setProductPercentage()
              setBtnLoading(false)
              fetchMemberslist()
              setSubmitButtonClick(false)
              if (!isToastVisible) {
                toast.success("Membership created Successfully", {
                  toastId: "toast",
                  onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
                mainRef.current?.scrollIntoView({ behavior: "smooth" });
              }
              setmemberInput({
                name: "",
                discount: "Free",
                services: [],
                products: [],
                valid_for: "",
                currency_membership_price: [],
                description: "",
                term_condition: "",
                is_installment: false,
                is_active: true
              })
            },
            () => {
              setBtnLoading(false)
              setSubmitButtonClick(false)
              if (!isToastVisible) {
                toast.error("Membership did not created", {
                  toastId: "toast",
                  onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
              }
            }
          )
        )
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // } else {
    //     if (memberInput.membership_type === 'Service') {
    //         setmemberError({
    //             ...memberError,
    //             service: true
    //         })
    //     } else {
    //         setmemberError({
    //             ...memberError,
    //             product: true
    //         })
    //     }

    // }


    // }
  }


  const fetchProductslist = async () => {
    const response = await getsProductsDropdownList();

    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      dispatch(getProducts(productList))
    }
  }

  const fetchMemberslist = async () => {
    dispatch(
      getMembers()
    )
  }

  const onMemberDeleteHandler = (id) => {
    setDeleteLoader(true)
    dispatch(
      deleteMember(
        { id: id },
        () => {
          if (!isToastVisible) {
            toast.success('Membership Deleted successfully', {
              toastId: "toast",
              onClose: () => setIsToastVisible(false),
            });
            setIsToastVisible(true);
          }
          fetchMemberslist()
          setDeleteLoader(false)
        },
        () => {
          if (!isToastVisible) {
            toast.error(apiErrorMessage, {
              toastId: "toast",
              onClose: () => setIsToastVisible(false),
            });
            setIsToastVisible(true);
          }
          setDeleteLoader(false)
        }
      )
    )
  }



  useEffect(() => {
    dispatch(getserviceDropdownList())
    fetchProductslist()
    fetchMemberslist()
  }, [])


  const onMemberEditClickHnadler = (id) => {
    setEditId(id)
  }

  const onMemberUpdateHnadler = (edited_data, seteditId) => {
    const { name, valid_for, description, term_condition } = edited_data;
    setSubmitButtonClick(true)
    if (edited_data.membership === 'Service') {
      delete edited_data.product
    }
    else if (edited_data.membership === 'Product') {
      delete edited_data.service
    }
    let requiredFields = {}
    if (edited_data?.discount === "Free") {
      requiredFields = { name, description, valid_for, term_condition }
    } else {
      requiredFields = { name, description, valid_for, term_condition }
    }

    // if ((Object.keys(memberInput).includes("products") && memberInput['products']) || (Object.keys(memberInput).includes("service") && memberInput['service'])) {
    setEditErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if ((objectKeyConvertToArray(result)?.length === 0)) {
      let submit = false
      if (edited_data?.discount === "Free" && Array.isArray(edited_data.products)) {
        const allProductsHavePercentage = edited_data.products.every(product => {
          return product.hasOwnProperty('percentage');
        });
        if (allProductsHavePercentage) {
          submit = true
        } else {
          submit = false
        }
      } else {
        if (edited_data?.discount === "Limited") {
          if (edited_data?.products?.length == 0) {
            submit = false
          }
          else if (edited_data?.services?.length == 0) {
            submit = false
          } else {
            submit = true
          }
        }
      }
      if (submit == true) {
        if (allUinqueCurrencyList?.length != edited_data?.currency_membership?.length) {
          setEditMemberError((prev) => ({
            ...prev,
            price: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
          }))
          formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        else {
          setEditMemberError((prev) => ({
            ...prev,
            price: "" // Remove the error message
          }));
          if ((Object.keys(edited_data).includes("products") && edited_data['products']) || (Object.keys(edited_data).includes("services") && edited_data['services'])) {
            setUpdateLoading(true)
            dispatch(
              updateMember(
                edited_data,
                () => {
                  setProduct_percentage('')
                  setDuration('')
                  setmemberError({})
                  setServicePercentage()
                  setProductPercentage()
                  fetchMemberslist()
                  setSubmitButtonClick(false)
                  seteditId('')
                  setEditId(false)
                  setUpdateLoading(false)
                  if (!isToastVisible) {
                    toast.success('Membership Updated successfully', {
                      toastId: "toast",
                      onClose: () => setIsToastVisible(false),
                    });
                    setIsToastVisible(true);
                  }
                },
                () => {
                  if (!isToastVisible) {
                    toast.error(apiErrorMessage, {
                      toastId: "toast",
                      onClose: () => setIsToastVisible(false),
                    });
                    setIsToastVisible(true);
                  }
                  setUpdateLoading(false)
                }
              )
            )

          } else {
            if (edited_data.membership === 'Service') {
              setEditMemberError({
                ...editMemberError,
                service: true
              })
              formRef.current?.scrollIntoView({ behavior: "smooth" });
            } else {
              setEditMemberError({
                ...editMemberError,
                product: true
              })
              formRef.current?.scrollIntoView({ behavior: "smooth" });
            }

          }
        }
      } else {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }


  const addNewService = (data) => {
    let prev_servs = memberInput?.services ? memberInput?.services : []
    setmemberInput({
      ...memberInput,
      services: [
        ...prev_servs,
        { ...data }
      ]
    })
  }


  const addNewProduct = (data) => {
    let prev_prod = memberInput?.products ? memberInput?.products : []
    setmemberInput({
      ...memberInput,
      products: [
        ...prev_prod,
        { ...data }
      ]
    })
  }

  useEffect(() => {
    const serviceResult = memberInput?.services?.map((itm, i) => {
      return {
        percentage: duration,
        service: itm?.service,
      }
    });

    setmemberInput({ ...memberInput, services: serviceResult })

  }, [duration])

  useEffect(() => {
    setEditMemberError((prev) => ({
      ...prev,
      ...editErrors
    }))
  }, [editErrors])

  useEffect(() => {
    const prodcutResult = memberInput?.products?.map((itm, i) => {
      return {
        percentage: product_percentage,
        product: itm?.product,
      }
    });

    setmemberInput({
      ...memberInput, products: prodcutResult
    })

  }, [product_percentage])


  const removedSelectedItems = (id, type) => {

    if (type === "service") {
      setmemberInput((prev) => ({
        ...prev,
        services: memberInput?.services?.filter((itm) => itm.service !== id)
      }))
    }

    if (type === "product") {
      setmemberInput((prev) => ({
        ...prev,
        products: memberInput?.products?.filter((itm) => itm?.product !== id)
      }))
    }

  }

  //set currency and membership price object here
  const addNewItem = () => {
    setmemberInput((prev) => {
      let prev_mm = prev?.currency_membership_price ? prev?.currency_membership_price : []
      return ({
        ...prev,
        currency_membership_price: [
          ...prev_mm,
          { currency: selectedCurrency, price: price }
        ]
      })
    })
    setSelectedCurrency();
    setPrice()
  }

  //set selectedService and ServicePercentage object here
  const addNewServiceItem = () => {
    setmemberInput((prev) => {
      let prev_servs = prev?.services ? prev?.services : []
      return ({
        ...prev,
        services: [
          ...prev_servs,
          { service: selectedService, percentage: servicePercentage }
        ]
      })
    })
    setSelectedService();
    setServicePercentage()
  }

  // set selectedProduct and ProductPercentage object here
  const addNewProductItem = () => {
    setmemberInput((prev) => {
      let prev_producst = prev?.products ? prev?.products : []
      return {
        ...prev,
        products: [
          ...prev_producst,
          { product: selectedProduct, percentage: productPercentage }
        ]
      }
    })
    setSelectedProduct();
    setProductPercentage()
  }

  // **************************** remove selected items *********************************
  const removedItem = (value, type) => {

    if ((type === "price")) {
      if (memberInput?.currency_membership_price?.find((itm) => itm?.currency == value)) {
        setmemberInput((prev) => ({
          ...prev,
          currency_membership_price: prev?.currency_membership_price ? prev?.currency_membership_price?.filter((itm) => itm?.currency != value) : [],
        }));
      }
    } else {
      if (memberInput?.currency_membership_price?.find((itm) => itm?.currency == value)) {
        setmemberInput((prev) => ({
          ...prev,
          currency_membership_price: prev?.currency_membership_price ? prev?.currency_membership_price?.map((itm) => {
            if (itm?.currency === value) {
              return {
                ...itm,
                is_deleted: true
              }
            }
            return itm
          }) : [],
        }));
      }
    }

  };

  // **************************** remove selected Services *********************************

  const removedSelectedService = (value, type) => {
    if ((type === "servicePercentage")) {
      if (memberInput?.services?.find((itm) => itm?.service === value)) {
        setmemberInput((prev) => {
          let prev_servs = prev?.services ? prev?.services : []
          return ({
            ...prev,
            services: [...prev_servs?.filter((itm) => itm?.service !== value)],
          })
        });
      }
    } else {
      if (memberInput?.services?.find((itm) => itm?.service === value)) {
        setmemberInput((prev) => {
          let prev_servs = prev?.services ? prev?.services : []
          return ({
            ...prev,
            services: [...prev_servs?.map((itm) => {
              if (itm?.service === value) {
                return {
                  ...itm,
                  is_deleted: true
                }
              }
              return itm
            })],
          })
        });
      }
    }
  };

  // **************************** remove selected Products *********************************

  const removedSelectedProduct = (value, type) => {

    if ((type === "productPercentage")) {
      if (memberInput?.products?.find((itm) => itm?.product == value)) {
        setmemberInput((prev) => {
          let prev_products = prev?.products ? prev?.products : []
          return {
            ...prev,
            products: [...prev_products?.filter((itm) => itm?.product != value)],
          }
        });
      }
    } else {
      if (memberInput?.products?.find((itm) => itm?.product == value)) {
        setmemberInput((prev) => {
          let prev_products = prev?.products ? prev?.products : []
          return {
            ...prev,
            products: [...prev_products?.map((itm) => {
              if (itm?.product === value) {
                return {
                  ...itm,
                  is_deleted: true
                }
              }
              return itm
            })],
          }
        });
      }
    }

  };

  useEffect(() => {
    if (allUinqueCurrencyList?.length == memberInput?.currency_membership_price?.length) {
      setErrors((prev) => ({
        ...prev,
        price: ""
      }))
    }
  }, [memberInput?.currency_membership_price])

  return (
    (!membership_updated) ? <PreloaderFull /> : <>
      <div ref={mainRef} className='col-span-4 slide-in-bottom'>
        <div className='flex flex-col gap-5 '>
          <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
            <CircleIcon>
              <Svgs.Promotions />
            </CircleIcon>
            <div className='flex flex-col gap-1 lg:text-left text-center'>
              <h2 className='text-primary font-semibold text-xl'>
                {selectedLanguage?.name == "English" ? "Add Memberships" : translation[1165]?.value ? translation[1165]?.value : "Add Memberships"}
              </h2>
              <p className='text-[#7B7B7B] text-sm'>
                {selectedLanguage?.name == "English" ? "Add membership for particular products/services and sell to clients" : translation[1166]?.value ? translation[1166]?.value : "Add membership for particular products/services and sell to clients"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className=''>
              <>
                {
                  state.membership?.memberships?.length > 0 &&
                  state.membership?.memberships?.map((membership, index) => {
                    return (
                      <AddMembershipCard
                        editMemberError={editMemberError}
                        setEditMemberError={setEditMemberError}
                        currency={businessCurrency?.currency?.code}
                        key={index}
                        index={index}
                        data={membership}
                        is_edit={edit_id == membership.id ? true : false}
                        onUpdate={(edited_data, seteditId) => {
                          onMemberUpdateHnadler(edited_data, seteditId)
                        }}
                        formRef={formRef}
                        // onDelete={(id) => {
                        //   onMemberDeleteHandler(id)
                        // }}
                        onDelete={(id) => {
                          setDelete(id);
                        }}
                        submitButtonClick={submitButtonClick}
                        onEditClick={(id) => { onMemberEditClickHnadler(id) }}
                        onBlurHandler={onBlurHandler}
                        errors={errors}
                        setErrors={setErrors}
                        updateLoading={updateLoading}
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                      />
                    )
                  }
                  )
                }
              </>
              {(!employee || (employee && employeePermissions?.client_sharing?.includes("create"))) &&
                <div className='flex gap-6' ref={formRef}>
                  <div className='relative'>
                    <div className='flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 '>

                      <div className='h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center'>
                      </div>
                    </div>
                    {
                      state.membership?.memberships?.length != 0 &&
                      <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2'></div>
                    }
                  </div>
                  <div className='mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4 bg-white'>

                    <LoginInput
                      name={"name"}
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Membership Name" : translation[205]?.value ? translation[205]?.value : "Membership Name"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Membership Name" : translation[1730]?.value ? translation[1730]?.value : "Enter Membership Name"}
                      value={name}
                      // charactersMaxLength={25}
                      onChange={handlememberInput}
                      // onEnterSubmit={membersubmithandler}
                      onBlur={() => onBlurHandler({ name: name })}
                      error={errors?.name && !edit_id}
                    />

                    <Textarea
                      required={true}
                      name={"description"}
                      value={description}
                      onChange={handlememberInput}
                      onBlur={() => onBlurHandler({ description: description })}
                      error={errors?.description && !edit_id}
                      title={selectedLanguage?.name == "English" ? "Membership Description" : translation[1167]?.value ? translation[1167]?.value : "Membership Description"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Membership Description" : translation[1168]?.value ? translation[1168]?.value : "Enter Membership Description"}
                    />

                    <h1 className='font-semibold text-xl py-[1rem] text-center'>{selectedLanguage?.name == "English" ? "Selling Price" : translation[269]?.value ? translation[269]?.value : "Selling Price"}</h1>
                    <p>{selectedLanguage?.name == "English" ? "Membership Item Type *" : translation[1334]?.value ? translation[1334]?.value : "Membership Item Type *"}</p>

                    <div className='flex items-center gap-[2rem]'>
                      <RadioBtn
                        onClick={() => {
                          setmemberInput((prev) => ({
                            ...prev,
                            discount: "Free",
                            services: [],
                            products: [],
                          }))
                          setpricingType('Free')
                        }}
                        checked={pricingType == 'Free'}
                        id='1'
                        text={selectedLanguage?.name == "English" ? "Free" : translation[1170]?.value ? translation[1170]?.value : "Free"}

                      />
                      <RadioBtn
                        onClick={() => {
                          setmemberInput((prev) => ({
                            ...prev,
                            discount: "Limited",
                            services: [],
                            products: [],
                          }))
                          setpricingType('Limited')
                        }}
                        checked={pricingType == 'Limited'}
                        id='2'
                        text={selectedLanguage?.name == "English" ? "Limited" : translation[1171]?.value ? translation[1171]?.value : "Limited"}

                      />
                    </div>
                    {pricingType === 'Free' ? (

                      <>
                        <div className=' gap-4'>
                          {/* <div className='col-span-3'>
                            <Dropwdown
                              required={true}
                              name={"duration"}
                              value={duration}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setDuration(value)
                                setErrors((prev) => ({
                                  ...prev,
                                  duration: ""
                                }));
                              }}
                              onBlur={() => onBlurHandler({ duration: duration })}
                              error={errors?.duration}
                              options={[
                                { label: "7 Days", value: "7" },
                                { label: "14 Days", value: "14" },
                                { label: "30 Days", value: "30" },
                              ]}
                              title={selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                              placeholder={selectedLanguage?.name == "English" ? "Select Duration" : translation[115]?.value ? translation[115]?.value : "Select Duration"}
                            />

                          </div>
                          <div className='col-span-3'> */}
                          <Dropwdown
                            required={true}
                            name={"service"}
                            value={memberInput?.services?.service}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              addNewService({ service: value })
                            }}
                            error={(submitButtonClick && memberInput?.discount === "Free" && memberInput?.services?.length == 0 && !edit_id) && "At least select one service."}
                            options={servicesDropdown?.filter(({ value }) => (memberInput?.services?.find(obj => obj?.service == value)?.service) != value)}
                            // title={selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                            title={selectedLanguage?.name == "English" ? "Free Services" : translation[1036]?.value ? translation[1036]?.value : "Free Services"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                          />
                          {/* </div> */}
                        </div>

                        {<div className='flex items-center flex-wrap gap-2 py-3'>
                          {
                            memberInput?.services && memberInput?.services?.map((item) => {

                              let selected_service = serviceRedux?.find(srvc => srvc?.id == item?.service);
                              return (
                                selected_service?.name && <div className='flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]'>
                                  <p className='text-primary text-sm font-bold'>{selected_service?.name}</p>
                                  <span onClick={() => removedSelectedItems(item?.service, "service")}><Svgs.CirlceClose fill='#5679FF' /></span>
                                </div>
                              )
                            })
                          }
                        </div>
                        }
                        <div className='md:grid md:grid-cols-6 flex flex-col gap-4'>
                          <div className='col-span-3'>
                            <FloatInput
                              required={true}
                              isPercentage={true}
                              name={"percentage"}
                              value={product_percentage}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setProduct_percentage(value)
                                setErrors((prev) => ({
                                  ...prev,
                                  percentage: "" // Remove the error message
                                }));
                              }}
                              onBlur={() => onBlurHandler({ percentage: product_percentage })}
                              error={(errors?.percentage && !product_percentage) && errors?.percentage && !edit_id}
                              title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[1172]?.value ? translation[1172]?.value : "Discount Percentage"}
                              placeholder={'%'}
                            />
                          </div>
                          <div className='col-span-3'>
                            <Dropwdown
                              required={true}
                              name={"product"}
                              value={memberInput?.products?.product}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                addNewProduct({ percentage: product_percentage, product: value })
                              }}
                              // onBlur={() => onBlurHandler({ product: .product })}
                              // error={errors?.product}
                              options={productDropdown?.filter(({ value }) => (memberInput?.products?.find(obj => obj?.product == value)?.product) != value)}
                              title={selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                              placeholder={selectedLanguage?.name == "English" ? "Select Products" : translation[1025]?.value ? translation[1025]?.value : "Select Products"}
                              error={(submitButtonClick && memberInput?.discount === "Free" && memberInput?.products?.length == 0 && !edit_id) && "At least select one product."}
                            />
                          </div>
                        </div>

                        {<div className='flex items-center flex-wrap gap-2 py-3'>
                          {
                            memberInput?.products && memberInput?.products?.map((item) => {
                              let selected_product = productRedux?.find(prdct => prdct?.id == item?.product);
                              return (
                                selected_product?.name && <div className='flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]'>
                                  <p className='text-primary text-sm font-bold'>{selected_product?.name}</p>
                                  <span onClick={() => removedSelectedItems(item?.product, "product")}><Svgs.CirlceClose fill='#5679FF' /></span>
                                </div>
                              )
                            })
                          }
                        </div>}
                      </>
                    ) : (

                      <>

                        {/* service list start */}

                        <div className='md:flex-row flex flex-col gap-4'>
                          <div className='flex-1'>
                            <FloatInput
                              required={true}
                              name={"percentage"}
                              isPercentage={true}
                              value={servicePercentage}
                              onChange={(e) => setServicePercentage(e.target.value)}
                              onBlur={() => onBlurHandler({ percentage: servicePercentage })}
                              error={(!servicePercentage && errors?.servicePercentage && services?.length == 0) && errors?.servicePercentage && !edit_id}
                              disabled={serviceRedux?.filter((itm) => !services?.find(({ service }) => service === itm?.id))?.length == 0}
                              title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[1172]?.value ? translation[1172]?.value : "Discount Percentage"}
                              placeholder={'%'}
                            />
                          </div>
                          <div className='flex-1 flex items-end gap-4'>
                            <Dropwdown
                              required={true}
                              name={"service"}
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              // onBlur={() => onBlurHandler({ service: srvc?.service })}
                              // error={errors?.service}
                              error={(submitButtonClick && !selectedService && memberInput?.discount === 'Limited' && services?.length == 0 && !edit_id) && "At least select one service."}
                              options={serviceRedux
                                ?.filter(
                                  (itm) =>
                                    !services?.find(
                                      ({ service }) => service === itm?.id
                                    )
                                )
                                ?.map((srvc) => {
                                  return {
                                    label: srvc?.name,
                                    value: srvc?.id
                                  }
                                })}
                              title={selectedLanguage?.name == "English" ? "Service" : translation[89]?.value ? translation[89]?.value : "Service"}
                              placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                            />

                            <div className=' flex items-end'>
                              <LoginBtn
                                className={(selectedService && servicePercentage) && "shake-bottom"}
                                disabled={!selectedService || !servicePercentage}
                                btnCustomHight={'h-[2.1875rem]'}
                                onClick={() => {
                                  addNewServiceItem();
                                }}
                              >
                                <div className='flex items-center gap-2 text-sm'>
                                  <Svgs.Plus />
                                  <p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                  </p>
                                </div>
                              </LoginBtn>
                            </div>
                          </div>

                        </div>
                        {services?.length > 0 && (
                          <div className="flex items-center flex-wrap gap-2">
                            {services &&
                              services?.map((item, i) => {
                                const srv = serviceList?.find(
                                  (itm) => itm?.id === item?.service
                                );
                                return (
                                  !item?.is_deleted && (
                                    <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                      <p className="text-primary text-sm font-bold">{`${item?.percentage} % on ${srv?.name}`}</p>
                                      <span
                                        onClick={() =>
                                          removedSelectedService(item?.service, "servicePercentage")
                                        }
                                      >
                                        <Svgs.CirlceClose fill="#5679FF" />
                                      </span>
                                    </div>
                                  )
                                );
                              })}
                          </div>
                        )}

                        {/* service list end */}



                        {/* product list start */}
                        <div className='md:flex-row flex flex-col gap-4'>
                          <div className='flex-1'>
                            <FloatInput
                              required={true}
                              name={"percentage"}
                              isPercentage={true}
                              value={productPercentage}
                              onChange={(e) => setProductPercentage(e.target.value)}
                              disabled={productRedux
                                ?.filter(
                                  (itm) =>
                                    !products?.find(
                                      ({ product }) => product === itm?.id
                                    )
                                )?.length == 0}
                              onBlur={() => onBlurHandler({ percentage: productPercentage })}
                              error={(!productPercentage && errors?.productPercentage && products?.length == 0) && errors?.productPercentage && !edit_id}
                              title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[1172]?.value ? translation[1172]?.value : "Discount Percentage"}
                              placeholder={'%'}
                            />
                          </div>
                          <div className='flex-1 flex items-end gap-4'>
                            <Dropwdown
                              required={true}
                              name={"product"}
                              value={selectedProduct}
                              onChange={(e) => setSelectedProduct(e.target.value)}
                              // onBlur={() => onBlurHandler({ product: prdct?.product })}
                              // error={errors?.product}
                              error={(submitButtonClick && memberInput?.discount === 'Limited' && products?.length == 0 && !edit_id) && "At least select one product."}
                              options={productRedux
                                ?.filter(
                                  (itm) =>
                                    !products?.find(
                                      ({ product }) => product === itm?.id
                                    )
                                )
                                ?.map((prdct) => {
                                  return {
                                    label: prdct?.name,
                                    value: prdct?.id
                                  }
                                })}
                              title={selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                              placeholder={selectedLanguage?.name == "English" ? "Select Products" : translation[1025]?.value ? translation[1025]?.value : "Select Products"}

                            />
                            <div className='col-span-1 flex items-end'>
                              <LoginBtn
                                className={(selectedProduct && productPercentage) && "shake-bottom"}
                                disabled={!selectedProduct || !productPercentage}
                                btnCustomHight={'h-[2.1875rem]'}
                                onClick={() => {
                                  addNewProductItem();
                                }}
                              >
                                <div className='flex items-center gap-2 text-sm'>
                                  <Svgs.Plus />
                                  <p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                  </p>
                                </div>
                              </LoginBtn>
                            </div>
                          </div>

                        </div>
                        {products?.length > 0 && (
                          <div className="flex items-center flex-wrap gap-2">
                            {products &&
                              products?.map((item, i) => {
                                const prd = productList?.find(
                                  (itm) => itm?.id === item?.product
                                );
                                return (
                                  !item?.is_deleted && (
                                    <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                      <p className="text-primary text-sm font-bold">{`${item?.percentage} % on ${prd?.name}`}</p>
                                      <span
                                        onClick={() =>
                                          removedSelectedProduct(item?.product, "productPercentage")
                                        }
                                      >
                                        <Svgs.CirlceClose fill="#5679FF" />
                                      </span>
                                    </div>
                                  )
                                );
                              })}
                          </div>
                        )}

                        {/* product list end */}
                      </>
                    )
                    }


                    <div className='md:flex-row flex flex-col gap-4'>
                      <div className='flex-1'>
                        <FloatInput
                          required={true}
                          name={"price"}
                          value={price}
                          parentClass={errors?.price && 'mb-5'}
                          // onChange={handlememberInput}
                          disabled={allUinqueCurrencyList?.length == memberInput?.currency_membership_price?.length}
                          onChange={(e) => {
                            setPrice(e.target.value)
                            setErrors((prev) => ({
                              ...prev,
                              price: ""
                            }));
                          }}
                          onBlur={() => onBlurHandler({ price: price })}
                          error={errors?.price && !edit_id}
                          title={selectedLanguage?.name == "English" ? "Selling Price" : translation[269]?.value ? translation[269]?.value : "Selling Price"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Membership Price" : translation[1731]?.value ? translation[1731]?.value : "Enter Membership Price"}
                        />
                      </div>

                      <div className='flex-1 flex items-center gap-4'>
                        <Dropwdown
                          required={true}
                          title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                          placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                          name={"selectedCurrency"}
                          value={selectedCurrency}
                          onChange={(e) => setSelectedCurrency(e.target.value)}
                          options={allUinqueCurrencyList
                            ?.filter(
                              (itm) =>
                                !currency_membership_price?.find(
                                  ({ currency }) => currency === itm?.currency?.id
                                )
                            )?.map((itm) => {
                              return {
                                label: itm?.currency?.code,
                                value: itm?.currency?.id,
                              };
                            })}
                        />
                        <div className=' flex flex-col gap-2'>
                          <p className="text-sm normal-case !opacity-0">Add Price</p>
                          <LoginBtn
                            className={(selectedCurrency && price) && "shake-bottom"}
                            btnCustomHight={'btnCustomHight xl:h-[2.1875rem] h-[2.18rem]'}
                            disabled={!selectedCurrency || !price}
                            onClick={() => {
                              addNewItem();
                            }}
                          >
                            <div className='flex items-center gap-2 text-sm'>
                              <Svgs.Plus />
                              <p>
                                {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                              </p>
                            </div>
                          </LoginBtn>
                        </div>
                      </div>

                    </div>

                    {currency_membership_price?.length > 0 && (
                      <div className="flex items-center flex-wrap gap-2 mt-[2ch]">
                        {currency_membership_price &&
                          currency_membership_price?.map((item, i) => {
                            const crrncy = currencyList?.find(
                              (itm) => itm?.id === item?.currency
                            );
                            return (
                              !item?.is_deleted && (
                                <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                  <p className="text-primary text-sm font-bold">{`${item?.price} ${crrncy?.code}`}</p>
                                  <span
                                    onClick={() =>
                                      removedItem(item?.currency, "price")
                                    }
                                  >
                                    <Svgs.CirlceClose fill="#5679FF" />
                                  </span>
                                </div>
                              )
                            );
                          })}
                      </div>
                    )}

                    <div className='md:grid grid-cols-2 gap-4'>
                      <Dropwdown
                        required={true}
                        name={"valid_for"}
                        value={valid_for}
                        onChange={handlememberInput}
                        onBlur={() => onBlurHandler({ valid_for: valid_for })}
                        error={errors?.valid_for && !edit_id}
                        options={ValidityTime}
                        title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                        placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                      />
                    </div>

                    <CheckBox
                      //  className={"w-full"}
                      name="is_installment"
                      onChange={isInstallmentChangeHandler}
                      value={is_installment}
                      checked={is_installment}
                      label={"Membership available for installment"}
                    />
                    {/* <Dropwdown
                      name={"is_active"}
                      value={is_active}
                      onChange={handlememberInput}
                      error={errors?.is_active}
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                      placeholder={selectedLanguage?.name == "English" ? "Select status" : translation[483]?.value ? translation[483]?.value : "Select status"}
                      options={[
                        {
                          value: true,
                          label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                        },
                        {
                          value: false,
                          label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                        },
                      ]}
                    /> */}
                    <Textarea
                      required={true}
                      name={"term_condition"}
                      value={term_condition}
                      onChange={handlememberInput}
                      onBlur={() => onBlurHandler({ term_condition: term_condition })}
                      error={errors?.term_condition && !edit_id}
                      title={selectedLanguage?.name == "English" ? "Terms & Conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & Conditions"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Terms & Conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & Conditions"}

                    />
                  </div>
                </div>
              }
            </div>
          </div>
          {(!employee || (employee && employeePermissions?.client_sharing?.includes("create"))) &&
            <LoginBtn
              text={selectedLanguage?.name == "English" ? "Add Memberships" : translation[1165]?.value ? translation[1165]?.value : "Add Memberships"}
              // disabled={
              //   (!memberInput?.name
              //     || !memberInput?.discount
              //     || !memberInput?.term_condition
              //     || !memberInput?.description
              //     || (!memberInput?.valid_for)
              //     || (memberInput?.products?.length === 0)
              //     || (memberInput?.services?.length === 0)
              //     // || (memberInput?.currency_membership_price?.length === 0)
              //     // || (allUinqueCurrencyList?.length !== memberInput?.currency_membership_price?.length)
              //     || errors?.term_condition || errors?.description
              //     || btn_loading
              //   )
              // }
              disabled={btn_loading}
              loading={btn_loading}
              onClick={() => {
                setSubmitButtonClick(true)
                membersubmithandler()
              }}
            />
          }
        </div>

      </div>
      {
        is_delete &&
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Membership?" : translation[1176]?.value ? translation[1176]?.value : "Are you sure you want to delete this Membership?"}
          onclose={() => {
            setDelete(false)
          }}
        >
          <div>
            <p>{selectedLanguage?.name == "English" ? "Are you sure you want to delete this Membership? You won't be able to see this again." : translation[1177]?.value ? translation[1177]?.value : "Are you sure you want to delete this Membership? You won't be able to see this again."}</p>
            <div className='flex justify-end mt-4'>
              <LoginBtn
                deleteBtn
                loading={deleteLoader}
                disabled={deleteLoader}
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  onMemberDeleteHandler(is_delete)
                  setTimeout(() => {
                    setDelete(false)
                  }, 500);
                }}
              />
            </div>
          </div>
        </Popup>
      }
    </>
  )
}

export default Membership