import React from 'react'
import useAddRefundTimePeriod from './helper';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Svgs from 'Assets/svgs';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Popup from 'Components/TenantApp/Elements/Popup';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';

const AddRefundPeriod = ({ open, close, details, locationList, isEdit, setIsEdit }) => {
    const {
        selectedLanguage, translation, refundPeriod, filteredEmployee,
        onChangeHandler, onBlurHandler, onSubmit, employeeRedux,
        errors, submitLoader, setRefundPeriod
    } = useAddRefundTimePeriod({ close, isEdit, setIsEdit, details })
    return (
        open &&
        <>
            <Popup
                heading={selectedLanguage?.name == "English" ? "Configuration Refund Period" : translation[1179]?.value ? translation[1179]?.value : "Configuration Refund Period"}
                close={close} open={open}>
                <div className="flex flex-col gap-6">
                    <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                        <CircleIcon>
                            <Svgs.Percent />
                        </CircleIcon>
                        <div className="flex-1 flex flex-col gap-1 lg:text-left text-center">
                            <h2 className="text-primary font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Store refund policy" : translation[1481]?.value ? translation[1481]?.value : "Store refund policy"}
                            </h2>
                            <p className="text-[#7B7B7B] text-sm">
                                {selectedLanguage?.name == "English" ? "Set the number of days for processing refunds and designate the employee responsible for managing refund requests." : translation[1482]?.value ? translation[1482]?.value : "Set the number of days for processing refunds and designate the employee responsible for managing refund requests."}

                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5'>
                        <Dropwdown
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                            name={"location"}
                            value={refundPeriod?.location}
                            onChange={onChangeHandler}
                            disabled={isEdit}
                            error={errors?.location}
                            options={locationList?.map((loc) => ({
                                label: loc?.address_name,
                                value: loc?.id
                            }))}
                            onBlur={() => onBlurHandler({ name: refundPeriod?.location })}
                        />
                        <LoginInput
                            type={'number'}
                            title={selectedLanguage?.name == "English" ? "Number of days" : translation[1466]?.value ? translation[1466]?.value : "Number of days"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter number of days" : translation[1467]?.value ? translation[1467]?.value : "Enter number of days"}
                            required={true}
                            name={'number_of_days'}
                            value={refundPeriod?.number_of_days}
                            onlyNumericAllowed={true}
                            onChange={onChangeHandler}
                            onEnterSubmit={onSubmit}
                            error={errors?.number_of_days}
                        />
                        <Dropwdown
                            title={selectedLanguage?.name == "English" ? "Employee" : translation[4]?.value ? translation[4]?.value : "Employee"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                            name={"allowed_refund"}
                            // value={refundPeriod?.allowed_refund}
                            onChange={onChangeHandler}
                            onBlur={() => onBlurHandler({ employees: refundPeriod?.allowed_refund })}
                            error={errors.allowed_refund ? selectedLanguage?.name == "English"
                                ? "This field is required"
                                : translation[287]?.value
                                    ? translation[287]?.value
                                    : "This field is required"
                                : ""
                            }
                            required={true}
                            options={filteredEmployee?.length > 0 ? filteredEmployee : []}
                        />
                        {/* {(refundPeriod?.location && filteredEmployee?.length == 0) &&
                            <p>
                                {selectedLanguage?.name == "English" ? "Click " : translation[1296]?.value ? translation[1296]?.value : "Click "}

                                <span className='cursor-pointer underline mx-px' onClick={() => {
                                    setShowAddStaffPopup(!showAddStaffPopup);
                                    // navigate("/dashboard/employee/employees/add-employee")
                                }}>
                                    {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                                </span>
                                {selectedLanguage?.name == "English" ? " to add staff." : translation[1295]?.value ? translation[1295]?.value : " to add staff."}
                            </p> 
                        } 
                            */}
                        {refundPeriod?.allowed_refund?.length > 0 && (
                            <div className="flex items-center gap-3 flex-wrap">
                                {refundPeriod?.allowed_refund &&
                                    refundPeriod?.allowed_refund?.map((item) => {
                                        let selected_employee = employeeRedux?.find(
                                            (empl) => empl.id == item?.employee
                                        );
                                        return (
                                            <div className="px-3 py-2 rounded-full flex items-center gap-3 border-[2px] border-primary w-max text-primary cursor-pointer text-sm">
                                                {selected_employee?.full_name}
                                                <span
                                                    onClick={() => {
                                                        setRefundPeriod({
                                                            ...refundPeriod,
                                                            allowed_refund: [
                                                                ...refundPeriod?.allowed_refund?.filter(
                                                                    (emp_id) => emp_id?.employee != selected_employee?.id
                                                                ),
                                                            ],
                                                        });
                                                    }}
                                                >
                                                    <Svgs.CirlceClose fill="#5679FF" />
                                                </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                    <div>

                    </div>
                    <div className="flex items-center justify-start mt-4">
                        <LoginBtn
                            onClick={onSubmit}
                            text={isEdit ?
                                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                            disabled={submitLoader}
                            loading={submitLoader}
                        />
                    </div>
                </div>
            </Popup>


        </>
    )
}

export default AddRefundPeriod