import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useLocation } from "new-components/location/helper";


import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import LoginInput from "Components/TenantApp/Elements/LoginInput";

import Svgs from "Assets/svgs";

const GiftCardItem = ({
  setOpenCart,
  checked,
  onClick,
  data,
  onDelete,
  view_details,
  is_edit,
  onUpdate,
  index,
  onEditClick,
  currency,
  removedFromCart,
  editMemberError,
  setEditMemberError,
  ...props
}) => {
  const state = useSelector((state) => state);
  const [memberData, setmemberData] = useState({});
  const [popclose, setpopclose] = useState("false");

  const [editId, seteditId] = useState("");
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const { locationDetail } = useLocation();
  const LocationCurrency = locationDetail?.currency?.id;

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  const productRedux = state?.product?.products;
  const serviceRedux = state?.service?.services;

  const handlememberData = (e) => {
    const { name, value } = e.target;
    setmemberData({
      ...memberData,
      [name]: value,
    });

    setEditMemberError({
      ...editMemberError,
      [name]: false,
    });
  };

  const function2 = () => {
    setpopclose("false");
  };

  useEffect(() => {
    setmemberData({
      ...data,
      service: data?.service?.id,
      product: data?.product?.id,
    });
  }, [editId]);

  return (
    <>
      {editId === memberData?.id && popclose === "true" ? (
        <>
          <div className="flex flex-col gap-4 w-full">
            <LoginInput
              name={"name"}
              required={true}
              title="Membership Name"
              placeholder="Membership Name"
              value={memberData?.name}
              onEnterSubmit={() => {
                onUpdate(memberData);
              }}
              error={editMemberError?.name}
              onChange={(e) => {
                handlememberData(e);
              }}
              onBlur={() => {
                if (!memberData?.name) {
                  setEditMemberError({
                    ...editMemberError,
                    name: true,
                  });
                } else {
                  setEditMemberError({
                    ...editMemberError,
                    name: false,
                  });
                }
              }}
            />

            <Dropwdown
              required
              title="Choose Membership Type"
              placeholder="Membership Type"
              name="membership"
              value={memberData?.membership}
              error={editMemberError?.membership}
              options={[
                { label: "Service", value: "Service" },
                { label: "Product", value: "Product" },
              ]}
              onChange={(e) => {
                handlememberData(e);
              }}
              onBlur={() => {
                if (!memberData?.membership) {
                  setEditMemberError({
                    ...editMemberError,
                    membership: true,
                  });
                } else {
                  setEditMemberError({
                    ...editMemberError,
                    membership: false,
                  });
                }
              }}
            />
            <div className="grid lg:grid-cols-2 gap-4">
              {memberData?.membership == "Service" ? (
                <>
                  <Dropwdown
                    title="Services"
                    required={true}
                    placeholder="Select Service"
                    name="service"
                    onEnterSubmit={() => {
                      onUpdate(memberData);
                    }}
                    value={memberData?.service}
                    error={editMemberError?.service}
                    onChange={(e) => {
                      handlememberData(e);
                    }}
                    options={serviceRedux.map((itm) => {
                      return {
                        label: itm.name,
                        value: itm.id,
                      };
                    })}
                    onBlur={() => {
                      if (!memberData?.service) {
                        setEditMemberError({
                          ...editMemberError,
                          service: true,
                        });
                      } else {
                        setEditMemberError({
                          ...editMemberError,
                          service: false,
                        });
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <Dropwdown
                    title="Select Product"
                    required={true}
                    placeholder="Select Product"
                    name="product"
                    value={memberData?.product}
                    error={editMemberError?.product}
                    options={productRedux.map((itm) => {
                      return {
                        label: itm.name,
                        value: itm.id,
                      };
                    })}
                    onChange={(e) => {
                      handlememberData(e);
                    }}
                    onBlur={() => {
                      if (!memberData?.product) {
                        setEditMemberError({
                          ...editMemberError,
                          product: true,
                        });
                      } else {
                        setEditMemberError({
                          ...editMemberError,
                          product: false,
                        });
                      }
                    }}
                  />
                </>
              )}

              <LoginInput
                required={true}
                name="total_number"
                type="number"
                title="No. of Purchases"
                value={memberData?.total_number}
                onEnterSubmit={() => {
                  onUpdate(memberData);
                }}
                error={editMemberError?.total_number}
                placeholder={`Enter no. of Purchases`}
                onChange={(e) => {
                  handlememberData(e);
                }}
                onBlur={() => {
                  if (!memberData?.total_number) {
                    setEditMemberError({
                      ...editMemberError,
                      total_number: true,
                    });
                  } else {
                    setEditMemberError({
                      ...editMemberError,
                      total_number: false,
                    });
                  }
                }}
              />
            </div>

            <div className="grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <Dropwdown
                  placeholder="Select Day/Month"
                  title="Valid for Day/Month"
                  name="valid_for"
                  value={memberData?.valid_for}
                  error={editMemberError?.valid_for}
                  onChange={(e) => {
                    handlememberData(e);
                  }}
                  options={[
                    { label: "Days", value: "Days" },
                    { label: "Months", value: "Months" },
                  ]}
                  onBlur={() => {
                    if (!memberData?.valid_for) {
                      setEditMemberError({
                        ...editMemberError,
                        valid_for: true,
                      });
                    } else {
                      setEditMemberError({
                        ...editMemberError,
                        valid_for: false,
                      });
                    }
                  }}
                />
              </div>
              <LoginInput
                required
                title="No. of Days/Months"
                type="number"
                placeholder="No. of Days/Months"
                name="validity"
                value={memberData?.validity}
                onEnterSubmit={() => {
                  onUpdate(memberData);
                }}
                error={editMemberError?.validity}
                onChange={(e) => {
                  handlememberData(e);
                }}
                onBlur={() => {
                  if (!memberData?.validity) {
                    setEditMemberError({
                      ...editMemberError,
                      validity: true,
                    });
                  } else {
                    setEditMemberError({
                      ...editMemberError,
                      validity: false,
                    });
                  }
                }}
              />
            </div>
            <div className="grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <LoginInput
                  placeholder="Price"
                  title="Pricing "
                  name="price"
                  value={memberData?.price}
                  error={editMemberError?.price}
                  onEnterSubmit={() => {
                    onUpdate(memberData);
                  }}
                  onChange={(e) => {
                    handlememberData(e);
                  }}
                  onBlur={() => {
                    if (!memberData?.price) {
                      setEditMemberError({
                        ...editMemberError,
                        price: true,
                      });
                    } else {
                      setEditMemberError({
                        ...editMemberError,
                        price: false,
                      });
                    }
                  }}
                />
              </div>
              <LoginInput
                required
                title="Tax Rate"
                type="number"
                placeholder="Tax Rate"
                name="tax_rate"
                value={memberData?.tax_rate}
                onEnterSubmit={() => {
                  onUpdate(memberData);
                }}
                error={editMemberError?.tax_rate}
                onChange={(e) => {
                  handlememberData(e);
                }}
                onBlur={() => {
                  if (!memberData?.tax_rate) {
                    setEditMemberError({
                      ...editMemberError,
                      tax_rate: true,
                    });
                  } else {
                    setEditMemberError({
                      ...editMemberError,
                      tax_rate: false,
                    });
                  }
                }}
              />
              <LoginBtn
                text="Submit"
                disabled={
                  !memberData?.name ||
                  !memberData?.membership ||
                  !memberData?.total_number ||
                  !memberData?.valid_for ||
                  !memberData?.validity ||
                  !memberData?.price ||
                  !memberData?.tax_rate
                }
                onClick={() => {
                  onUpdate(memberData);
                  function2();
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`border justify-between relative rounded-[0.625rem]`}>
            <div className="flex items-center gap-4 p-4">

              <div className="absolute top-2 right-2">
                <div className="cursor-pointer" onClick={() => view_details()}>
                  <Svgs.INFO />
                </div>
              </div>

              <div>
                <div className="h-[70px] w-[70px] overflow-hidden flex items-center justify-center rounded-[0.625rem]">
                  <Svgs.Gift />
                </div>
              </div>

              <div className="pt-2">
                <h1 className="font-semibold whitespace-normal line-clamp-2 overflow-ellipsis">{data?.title}</h1>
                <div className="">
                  <p className="text-[#5679ff] text-xs font-semibold">
                    {data?.gift_card_details?.find((itm) => itm?.currencies === LocationCurrency)?.price
                      ? <>
                        {data?.gift_card_details?.find((itm) =>
                          itm?.currencies === LocationCurrency)?.price} {' '}
                        {data?.gift_card_details?.find((itm) => itm?.currencies === LocationCurrency)
                          ? locationDetail?.currency?.code
                          : "AED"
                        }
                      </>
                      : ""
                    }
                  </p>
                  <p className="text-[#555555] text-xs">
                    {selectedLanguage?.name == "English" ? "Including Tax" : translation[303]?.value ? translation[303]?.value : "Including Tax"} <span class="text-red-600"> *</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="border-t border-lightGray px-4 py-2.5 flex items-center justify-between">
              <div>
                <p className="text-[#555555] text-xs">
                  {selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}: {" "}
                  <span className="text-[#5679ff]">
                    {data?.valid_till} {" "}
                  </span>
                </p>
              </div>
              {(!employee || (employee && employeePermissions?.sales_apply_offer?.includes("create")) || employeePermissions?.sales_apply_offer?.includes("edit")) ? (
                <LoginBtn
                  className={'ml-auto !py-2'}
                  onClick={() => {
                    setOpenCart(true)
                    onClick()
                  }}
                  text={'Add to Cart'}
                />
              ) : (
                ""
              )}
            </div>
          </div>

        </>
      )}
    </>
  );
};

GiftCardItem.defaultProps = {
  data: {},
  index: undefined,

  onEditClick: () => { },
  onUpdate: () => { },
};

export default GiftCardItem;
