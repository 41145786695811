import Svgs from 'Assets/svgs';
import React from 'react';

const PricingFeatures = () => {
    const features = [
        {
            title: "Unlimited Appointment Bookings",
            description: "Stay neatly scheduled appointments with accessibility across mobiles, tablets, and desktops"
        },
        {
            title: "Unlimited Team Members",
            description: "Extend invitations to your team, ensuring everyone is informed with appointment notifications"
        },
        {
            title: "Unlimited Locations",
            description: "Manage multiple venues seamlessly from a single main account without restrictions"
        },
        {
            title: "Unlimited Clients and Email Notifications",
            description: "Keep track of client history, maintain notes, and stay connected through integrated email"
        },
        {
            title: "Unlimited Product Inventory",
            description: "Efficiently record and monitor stock inventory, complete with support for supplier ordering"
        },
        {
            title: "Unlimited Reporting and Much More",
            description: "Experience an all-inclusive solution designed to simplify your salon business management"
        }
    ];

    const paidFeatures = [
        {
            title: "Payment processing features",
            description: "One fee per transaction, online and in-store",
            pricing: "2.19% + $0.20"
        },
        {
            title: "Marketplace New Client Fee",
            description: "One-time fee covering business promotion and setup via the Fresha marketplace. The fee never applies to your returning clients, or any appointments made via your website, your Google Business or social pages",
            pricing: null
        },
        {
            title: "Automated notifications",
            description: "Notify your clients about their appointments",
            pricing: null
        },
        {
            title: "Blast marketing",
            description: "Send promotional texts and emails to clients",
        },
        {
            pricing: "50 free monthly texts \n $0.01 / extra texts",
            description: "Unlimited emails and in-app notifications",
        },
        {
            pricing: "$0.06 / text $0.02 / email"
        }
    ];

    return (
        <div className="flex w-full bg-white h-max font-manrope">
            <div className='overflow-auto w-full relative feature-bg' >
                <div className=" w-[95%] relative z-10 max-w-[1200px] py-24 mx-auto">
                    {/* Header Section */}
                    <div className="text-white w-full max-w-[57.5rem] mx-auto flex flex-col items-center gap-5 mb-12">
                        <h1 className="text-white text-[3.125rem] text-center font-bold mx-auto leading-tight">
                            No monthly fees, no subscription charges.
                        </h1>
                        <p className="text-2xl text-center"> The sole platform designed exclusively for salons, offered with no fees attached.</p>
                    </div>

                    {/* Features Grid */}
                    <div className="grid md:grid-cols-2 gap-y-10 gap-x-12 lg:gap-x-[4.75rem] mb-20">
                        {features.map((feature, index) => (
                            <div key={index} className="flex items-center space-x-4">
                                <div className="flex-shrink-0 w-[1.875rem] h-[1.875rem] mt-1">
                                    <Svgs.TickIconGreen />
                                </div>
                                <div>
                                    <h3 className="text-white font-semibold text-2xl">{feature.title}</h3>
                                    <p className="text-white text-base">{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Paid Features Section */}
                    <div className="bg-secondary-white rounded-[1.875rem] p-[4.375rem]">
                        <h1 className="text-black text-[3.125rem] text-center font-bold mx-auto leading-tight">
                            Optional paid features
                        </h1>
                        <p className="text-2xl text-black text-center pb-10">
                            Only pay for what you use. Cancel for free anytime without any commitment.
                        </p>
                        <div className="grid md:grid-cols-2 gap-8">
                            {paidFeatures.map((feature, index) => (
                                <div key={index} className="space-y-2 text-black bg-white px-10 py-6 rounded-[1.25rem]">
                                    {feature.title
                                        ? <h3 className="font-bold text-2xl">{feature.title}</h3>
                                        : <p className="text-3xl font-bold">{feature.pricing}</p>
                                    }
                                    {feature.description && <p className="text-base">{feature.description}</p>}
                                    {feature.title && <p className="text-3xl font-bold">{feature.pricing}</p>}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex w-full justify-center items-center mt-4">
                        <Svgs.CardShadow />
                    </div>
                </div>

                <div className="hidden absolute top-0 right-0 h-[19.25rem] w-[27.5rem] md:flex ">
                    <img src="/images/feature.png" alt=""  className='w-full h-full' />
                </div>
            </div>
        </div>
    );
};

export default PricingFeatures;