import Svgs from 'Assets/svgs'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const PricingCard = ({ data, isMiddle }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className={`bg-white flex flex-col w-full rounded-[1.125rem] shadow-2xl overflow-hidden`}>
                <div className={`bg-primary-blue py-10 px-6 text-white`}>
                    <div className="flex justify-between items-center">
                        <div>
                            <h3 className="text-[1.75rem] font-bold">{data?.title}</h3>
                            <p className="text-sm">{data?.description}</p>
                        </div>
                        <div className="flex">{data?.icon}</div>
                    </div>
                </div>

                <div className="px-8 pb-9 flex flex-col flex-1 gap-6 justify-between">
                    <div className="flex flex-col gap-5">
                        <div className="border-b pt-2 pb-3.5 w-full">
                            <span className="text-5xl font-bold">${data?.price}</span>
                        </div>

                        <div className="flex flex-col gap-5">
                            {data?.features.map((feature, index) => (
                                <div key={index} className="flex items-center gap-2.5">
                                    <Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} />
                                    <span className="text-sm">{feature}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <button
                        className={`w-64 sm:w-full py-3 rounded-full px-11 font-semibold transition-colors
                        ${isMiddle ? 'bg-primary-blue text-white' : 'bg-white text-black shadow-[0px_4px_50px_0px_rgba(0,0,0,0.15)]'}`}
                        onClick={() => { data?.url && navigate(data?.url) }}
                    >
                        {data?.buttonText}
                    </button>
                </div>
            </div >
        </>
    )
}

export default PricingCard