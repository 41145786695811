import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Svgs from '../../../../../Assets/svgs';
import { ClientCard } from './ClientCard'
import { ClientAppointmenrCard } from './ClientAppointmenrCard';
import { getClientSales } from '../../../../../Adapters/Api/apointments';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AddAppointmentRightClient from './../Elements/AddAppointmentRightClient';
import { GET_CLIENT_SALE } from './../../../../../Redux/TenantRedux/ActionTypes/Appointment/Appointment';
import LoginInput from './../../../Elements/LoginInput';
import moment from 'moment';
import NoClientFound from 'Components/Utility/NoClientFound';
import LabelAvatar from 'Components/TenantApp/Elements/LabelAvatar';
import InfiniteScroll from "react-infinite-scroll-component";
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import { getClientDropDownList, getClientDropDownListPagination } from 'Adapters/Api/clients';
import ClientSectionDetailShimmer from 'Components/Utility/Shimmers/ClientSectionDetailShimmer';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import ClientCardShimmer from 'Components/Utility/Shimmers/ClientCardShimmer';
import TotalCount from 'Components/TenantApp/Elements/TotalCount';
import AddClientPopup from '../../Clients/AddClient/AddClientPopup';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';

let timeOutId = undefined
const CheckBoxClientSection = ({ future_err, hours_err, setCreateAppErr, setSubmitButtonClick, setCombinedAppointment, setNoPromotionAvailed, setSelectedClientIdIndex, appointmentData, initialState, setAppointmentData, selectedId, requiredLength, removeBtn, setSelectedId, confirm, setConfirm, setAllSelectedClient, combinedAppointment, deletedClientID }) => {
    let past_appointment = [];
    let news_appointment = [];

    let past_quick_sales = [];
    let new_quick_sales = [];


    let past_voucher = [];
    let news_voucher = [];

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const state = useSelector(state => state);
    const client_sale = state?.appointment?.client_sale
    const clientRedux = state?.client?.clientsRedux;
    const clientPaginationData = state?.client?.clientPaginationData;
    const appointment_client = state?.appointment?.appointment_client;
    const totalSales = client_sale?.totalSales ? client_sale?.totalSales : 0
    const quickSalesCount = client_sale?.quickSalesCount ? client_sale?.quickSalesCount : 0
    const vouchersCount = client_sale?.vouchersCount ? client_sale?.vouchersCount : 0
    const totalAppointmentsCount = client_sale?.totalAppointmentsCount ? client_sale?.totalAppointmentsCount : 0
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const clientImages = client_sale?.clientImages ? client_sale?.clientImages : []
    const [SideSection, setSideSection] = useState('Appointments')
    const [SelectedClient, setSelectedClient] = useState()
    const [loader, setLoader] = useState(true)
    // const [clients, setClients] = useState([])
    const [clientLoader, setClientLoader] = useState(true)
    const [clientSecondLoader, setClientSecondLoader] = useState(false)
    const [clientSearchText, setClientSearchText] = useState("");
    const [showAddClientPopup, setShowAddClientPopup] = useState(false)
    const [clientCurrentPage, setClientCurrentPage] = useState(1)
    const [selectedClients, setSelectedClients] = useState([])
    const [selectedClientsData, setSelectedClientsData] = useState([])
    // const [confirm, setConfirm] = useState(false)
    const [clientConfirm, setClientConfirm] = useState(false)
    //filter new and past_appointment appointmen
    client_sale?.appointment?.length > 0 &&
        client_sale?.appointment.map((app, ind) => {
            if (app?.appointment_status === "Done") {
                past_appointment.push(app)
            } else {
                news_appointment.push(app)
            }
        })

    //filter past and new prodcut 
    client_sale?.product?.length > 0 &&
        client_sale?.product.map((prdct) => {

            var dat = new Date();
            dat?.setDate(dat?.getDate() - 10);
            past_quick_sales.push(prdct)
        })

    //filter past and new services 
    client_sale?.service?.length > 0 &&
        client_sale?.service.map((srv) => {

            var dat = new Date();
            dat?.setDate(dat?.getDate() - 10);
            past_quick_sales.push(srv)
        })



    client_sale?.voucher?.length > 0 &&
        client_sale?.voucher.map((vchr) => {
            let dt = vchr?.created_at?.split('T')[0];
            let dy = dt?.split("-")[2];


            var dat = new Date();
            dat?.setDate(dat?.getDate() - 10);
            let formate_create_at_dt = moment(dt).format('YYYY-MM-DD')
            let formate_from_Date = moment(dat).format('YYYY-MM-DD')
            const fromDate = Date?.parse(formate_from_Date);
            let createAt = Date?.parse(formate_create_at_dt);

            if (createAt > fromDate) {
                news_voucher.push(vchr)
            } else {
                past_voucher.push(vchr)
            }

        })

    const getClients = (clientCurrentPage, clientSearchText) => {
        dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
    }

    // get single client data
    const getSingleClientData = (id) => {
        const success = (data) => {
            let ClientSaleDetail = {
                clientImages: data?.c_images,
                appointment: data?.appointment,
                product: data?.product,
                voucher: data?.voucher,
                service: data?.service,
                vouchersCount: data?.voucher_count,
                totalAppointmentsCount: data?.appointments_count,
                quickSalesCount: data?.quick_sale_count,
                totalSales: data?.total_sales
            }
            dispatch({
                type: GET_CLIENT_SALE,
                payload: ClientSaleDetail
            })
        }
        const fail = () => {
            toast.error("invalid client id!", { toastId: "toast" })
        }

        getClientSales({ client: id, location_id: selected_location }, setLoader, success, fail)
    }
    useEffect(() => {
        if (SelectedClient?.id) {
            getSingleClientData(SelectedClient?.id)
        }
    }, [SelectedClient])

    // Filters out the deleted client ID from the selectedClients array.And also Updates the state with the filtered array.
    useEffect(() => {
        if (deletedClientID) {
            const filteredClients = selectedClients?.filter(itm => itm != deletedClientID)
            setSelectedClients(filteredClients)
        }
    }, [deletedClientID])

    // Filters clientRedux based on selectedClients and sets both selectedClientsData and AllSelectedClient accordingly.
    useEffect(() => {
        const filteredClients = state?.client?.clients?.filter(itm => selectedClients?.includes(itm?.id))
        setSelectedClientsData(filteredClients)
        setAllSelectedClient(filteredClients)
    }, [selectedClients, selectedClients?.length])

    useEffect(() => {
        dispatch(getClientDropDownList())
    }, [])

    useEffect(() => {
        if (clientSearchText === null) {
            // Initial load with empty clientSearchText
            getClients(clientCurrentPage, "")
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getClients(clientCurrentPage, clientSearchText)
            }, 500);
        }
    }, [clientSearchText, clientCurrentPage])

    // SetClientConfirm to true if confirm is true and selectedClients length equal to requiredLength.
    // Otherwise, SetClientConfirm and setConfirm to false .
    useEffect(() => {
        if (confirm && (selectedClients?.length == requiredLength)) {
            setClientConfirm(true)
        } else {
            setClientConfirm(false)
            setConfirm(false)
        }
    }, [confirm, selectedClients])

    useEffect(() => {
        setClientCurrentPage(1)
    }, [clientSearchText])

    // Handles the deletion of a client with the given ID.
    const handleClientDelete = (id) => {
        const filteredClients = selectedClients?.filter(itm => itm != id)
        setSelectedClients(filteredClients)
        const prevData = combinedAppointment?.filter(itm => itm?.client != id)
        setCombinedAppointment([...prevData])
        setConfirm(false)
    }
    // Handles checkbox changes for a client with the given ID.
    // Adds the client to selectedClients if the checkbox is checked and the requiredLength is not reached.
    // Removes the client from selectedClients if the checkbox is unchecked.
    const handleCheckboxChange = (e, id) => {
        if (e?.target?.checked) {
            if (selectedClients?.length !== +requiredLength) {
                if (selectedClients?.length < +requiredLength) {
                    setSelectedClients([...selectedClients, id])
                }
            } else {
                toast.error(`You cannot select more than ${requiredLength} members`)
            }
        } else {
            const filteredClients = selectedClients?.filter(itm => itm != id)
            setSelectedClients(filteredClients)
        }
    }

    return (
        <div className="col-span-2 flex flex-col relative gap-4 lg:pl-4 lg:border-l h-full">
            {!SelectedClient ?
                <>

                    <div className='shadow-appoint rounded-md bg-white'>
                        <div className="flex flex-col items-start gap-4 justify-start min-h-[30rem] max-h-[40rem] overflow-y-auto">
                            <div className='p-4 w-full'>
                                <div className='flex items-center justify-between gap-3' >
                                    <h1 className='font-semibold text-xl'>
                                        {selectedLanguage?.name == "English" ? "Client" : translation[5]?.value ? translation[5]?.value : "Client"}
                                    </h1>
                                    {!confirm &&
                                        <div className='flex items-center gap-2 cursor-pointer' onClick={() => {
                                            localStorage.setItem('add_client_from_sales', true)
                                            setShowAddClientPopup(!showAddClientPopup)
                                        }}>
                                            <Svgs.Plus color='#5679FF' />
                                            <p className='font-semibold text-sm text-[#5679FF]'>

                                                {selectedLanguage?.name == "English" ? "Add New Client" : translation[142]?.value ? translation[142]?.value : "Add New Client"}

                                            </p>

                                        </div>
                                    }
                                </div>
                                <div className='mt-4'>
                                    <LoginInput
                                        name="clientSearchText"
                                        value={clientSearchText}
                                        onChange={(e) => { setClientSearchText(e.target.value) }}
                                        title=''
                                        placeholder={selectedLanguage?.name == "English" ? "Search Client" : translation[143]?.value ? translation[143]?.value : "Search Client"}
                                        padding='py-1'
                                        leftIcon={<Svgs.Search />}

                                    />
                                </div>
                            </div>
                            <div className='max-h-[30rem] overflow-y-auto w-full p-4' id="scrollableDiv">
                                {!clientConfirm && !confirm ?
                                    clientLoader && !clientSecondLoader
                                        ? [0, 1, 2, 3, 4]?.map(itm =>
                                            <ClientCardShimmer />
                                        )
                                        : <InfiniteScroll
                                            dataLength={clientRedux?.length ? clientRedux?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                clientPaginationData?.currentPage < clientPaginationData?.pages ? setClientSecondLoader(true) : setClientSecondLoader(false)
                                                setClientCurrentPage(clientPaginationData?.currentPage + 1)
                                            }}
                                            hasMore={clientPaginationData && clientRedux?.length < clientPaginationData?.allCount && clientPaginationData?.currentPage > 0 && clientPaginationData?.currentPage < clientPaginationData?.pages}
                                            scrollableTarget="scrollableDiv"
                                            loader={clientSecondLoader
                                                ? <div className="h-[4rem] flex items-center justify-center">
                                                    <PreloaderSm />
                                                </div>
                                                : ""
                                            }
                                        >
                                            {clientRedux?.length > 0
                                                ? clientRedux?.map((clint, index) => {
                                                    return (
                                                        <CheckBox
                                                            mainLabelClass={"!w-full"}
                                                            label={
                                                                <AddAppointmentRightClient
                                                                    data={clint}
                                                                    index={index}
                                                                    noId
                                                                />
                                                            }
                                                            name={'client'}
                                                            //   value={clint?.id}
                                                            id={clint?.id}
                                                            onChange={(e) => handleCheckboxChange(e, clint?.id)}
                                                            checked={selectedClients?.find(itm => clint?.id == itm) ? true : false}
                                                        />
                                                    )
                                                })
                                                : <NoClientFound />
                                            }
                                        </InfiniteScroll>
                                    :
                                    <>
                                        {
                                            confirm && selectedClients?.length == requiredLength && selectedClientsData?.filter(itm => itm?.full_name?.toLowerCase()?.includes(clientSearchText?.toLowerCase()))?.length > 0 ?
                                                confirm && selectedClients?.length == requiredLength && (selectedClientsData?.filter(itm => itm?.full_name?.toLowerCase()?.includes(clientSearchText?.toLowerCase()))?.map((client, index) => {
                                                    return (
                                                        <AddAppointmentRightClient
                                                            data={client}
                                                            index={index}
                                                            selectedClient={true}
                                                            isSelected={selectedId == client?.id}
                                                            isAppointment={combinedAppointment.find((item) => {
                                                                if (item.client === client?.id) {
                                                                    if (item?.appointments?.length > 0) {
                                                                        return true
                                                                    }
                                                                }
                                                            })}
                                                            onCloseClick={() => { handleClientDelete(client?.id) }}
                                                            onEyeClick={() => {
                                                                setSelectedClient(client)
                                                            }}
                                                            onClick={() => {
                                                                setSubmitButtonClick(true)
                                                                if (appointmentData.appointments?.length == 0) {
                                                                    setCreateAppErr({ error: `${selectedLanguage?.name == "English" ? "Add atleast one appointment service" : translation[1722]?.value ? translation[1722]?.value : "Add atleast one appointment service"}`, selectedClient: selectedId })
                                                                } else {
                                                                    if (hours_err?.length == 0 && future_err.length == 0 && appointmentData.appointments?.every(
                                                                        (itm) =>
                                                                            itm.date_time &&
                                                                            itm.service &&
                                                                            itm.member &&
                                                                            itm.duration
                                                                    )) {
                                                                        const prevData = combinedAppointment?.find(itm => itm?.client == selectedId)
                                                                        if (prevData) {
                                                                            const excludedData = combinedAppointment?.filter(itm => itm?.client != selectedId)
                                                                            setCombinedAppointment([...excludedData, { ...appointmentData, client: selectedId, client_type: "IN HOUSE", }])
                                                                        } else {
                                                                            const prevData = combinedAppointment || []
                                                                            setCombinedAppointment([...prevData, { ...appointmentData, client: selectedId, client_type: "IN HOUSE", }])
                                                                        }
                                                                        setSelectedClientIdIndex(index)
                                                                        const exist = combinedAppointment?.find(itm => itm?.client == client?.id)
                                                                        if (exist) {
                                                                            setAppointmentData(exist)
                                                                        } else {
                                                                            if (selectedId != client?.id) {
                                                                                setNoPromotionAvailed && setNoPromotionAvailed(true)
                                                                                setAppointmentData({
                                                                                    ...appointmentData,
                                                                                    appointments: [],
                                                                                    avail_offer: [],
                                                                                    client_type: "IN HOUSE",
                                                                                    selected_promotion: {},
                                                                                    selected_promotion_id: "",
                                                                                    selected_promotion_type: "",
                                                                                    is_promotion_availed: false,
                                                                                    appointment_notes: ""
                                                                                });
                                                                            }
                                                                        }

                                                                        setSelectedId && setSelectedId(client?.id)
                                                                        setSubmitButtonClick(false)
                                                                        setCreateAppErr({})
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }))
                                                : <NoClientFound showAddText={false} />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        {/* {errors?.client && (
                            <span className={"text-[#eb3b3b] flex items-center gap-1 text-xs w-full justify-end"}>
                                <Svgs.I fill="#eb3b3b" />
                                {errors?.client}
                            </span>
                        )} */}
                    </div>

                    {!confirm &&
                        (<div className='flex justify-end items-end'>
                            <LoginBtn
                                onClick={() => {
                                    dispatch({
                                        type: "SELECTED_APPOINTMENT_CLIENT_GROUP",
                                        payload: selectedClientsData,
                                    })
                                    setConfirm(true)
                                }}
                                disabled={selectedClients?.length != requiredLength}
                                text={
                                    `${selectedLanguage?.name == "English" ? "Confirm" : translation[1569]?.value ? translation[1569]?.value : "Confirm"}`
                                }
                            />
                        </div>)
                    }
                </>
                :
                <div className='shadow-appoint rounded-md'>
                    {loader ?
                        <ClientSectionDetailShimmer />
                        :
                        <>
                            <div className='p-3'>
                                <div className='bg-[#ECF3FF] border-2 border-[#BAD3FC] rounded-md p-4 relative'>
                                    {!removeBtn && <div className='absolute top-3 right-3 text-[#5679FF] text-sm cursor-pointer'
                                        onClick={() => {
                                            dispatch({
                                                type: "REST_SELECTED_APPOINTMENT_CLIENT",
                                                payload: [],
                                            })
                                            setSelectedClient(undefined)
                                        }}
                                    >
                                        {selectedLanguage?.name == "English" ? "Remove" : translation[144]?.value ? translation[144]?.value : "Remove"}
                                    </div>}

                                    <div className='pt-4'>
                                        <div className='flex items-center gap-4'>
                                            <div className='h-[4rem] w-[4rem] rounded-full overflow-hidden'>
                                                {SelectedClient?.image ?
                                                    <img
                                                        src={SelectedClient?.image}
                                                        className="h-full w-full object-cover" />
                                                    :
                                                    <LabelAvatar iconLabel={SelectedClient?.full_name} index={1} />
                                                }
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <h1 className='font-semibold'>{SelectedClient?.full_name}</h1>
                                                <p className='text-[#7E8693] text-xs'>{SelectedClient?.email ? SelectedClient?.email : "----"}</p>
                                                <p className='text-[#7E8693] text-xs'>{SelectedClient?.phone_number && SelectedClient?.phone_number}</p>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-2 gap-6 pt-4'>
                                            <div className='flex flex-col gap-1 text-center pr-3 border-r border-[#E0E0F3]'>
                                                <h1 className='font-bold text-3xl'>{totalAppointmentsCount}</h1>
                                                <p className='text-[#7E8693]'>
                                                    {selectedLanguage?.name == "English" ? "Total Appointment" : translation[145]?.value ? translation[145]?.value : "Total Appointment"}
                                                </p>
                                            </div>


                                            <div className='flex flex-col gap-1 text-center'>
                                                <h1 className='font-bold text-3xl'>{parseFloat(totalSales)}</h1>
                                                <p className='text-[#7E8693]'>
                                                    {selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='border-b flex items-center justify-between gap-5 px-4 font-semibold'>
                                <div className={`${SideSection == 'Appointments' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Appointments')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                </div>
                                <div className={`${SideSection == 'Quick_Sales' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Quick_Sales')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Quick Sales" : translation[148]?.value ? translation[148]?.value : "Quick Sales"}
                                </div>
                                <div className={`${SideSection == 'Offers' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Offers')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Offers Availed" : translation[149]?.value ? translation[149]?.value : "Offers Availed"}
                                </div>
                                <div className={`${SideSection == 'Disclaimer' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Disclaimer')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Waiver / Disclaimer" : translation[1565]?.value ? translation[1565]?.value : "Waiver / Disclaimer"}
                                </div>
                            </div>

                            <div className='p-4'>
                                {
                                    SideSection == 'Appointments' && (past_appointment?.length === 0) ?
                                        <div className='rounded-md p-4 text-[#55555] h-[10rem]'>
                                            <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                <div>
                                                    <div className='w-fit mx-auto'>
                                                        <Svgs.NotFoundIcon />
                                                    </div>
                                                    <h1 className='font-semibold pt-2 text-sm'>
                                                        {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        SideSection == 'Appointments' ?
                                            (news_appointment?.length != 0) || (past_appointment?.length != 0) ?


                                                <>

                                                    {
                                                        past_appointment?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                                                <TotalCount count={totalAppointmentsCount} />
                                                            </h1>
                                                            {
                                                                past_appointment?.map((app, ind) => {
                                                                    let total = 0;
                                                                    let dt = app?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2]
                                                                    total += app?.price ? app?.price : app?.total_price ? app?.total_price : app?.gst ? app?.gst : app?.tip ? app?.tip : 0;
                                                                    return (

                                                                        (ind <= 4) &&
                                                                        <ClientAppointmenrCard
                                                                            name={app?.service?.name}
                                                                            price={total ? total : 0}
                                                                            created_at={app?.created_at}
                                                                            sale_by={app?.member}
                                                                            description={app?.description}
                                                                            dt={dt ? dt : ""}
                                                                            dy={dy}
                                                                            status={app?.appointment_service_status}
                                                                            duration={app?.duration}
                                                                            service={app?.service}
                                                                            favourite={app?.is_favourite}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                            {past_appointment?.length >= 5 &&
                                                                <div className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center' onClick={() => {
                                                                    localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                    navigate("/dashboard/sales/appointments?active_tab=appointments")
                                                                }}>{selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}</div>}
                                                        </div>}
                                                </>
                                                : ""
                                            : ""
                                }
                                {
                                    SideSection == 'Quick_Sales' && (past_quick_sales?.length === 0) ?
                                        <div className='rounded-md p-4 text-[#55555] h-[10rem]'>
                                            <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                <div>
                                                    <div className='w-fit mx-auto'>
                                                        <Svgs.NotFoundIcon />
                                                    </div>
                                                    <h1 className='font-semibold pt-2 text-sm'>
                                                        {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        SideSection == 'Quick_Sales' ?
                                            (new_quick_sales?.length != 0) || (past_quick_sales?.length != 0) ?
                                                <>

                                                    {
                                                        past_quick_sales?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "Quick Sales" : translation[148]?.value ? translation[148]?.value : "Quick Sales"}
                                                                <TotalCount count={quickSalesCount} />
                                                            </h1>
                                                            {
                                                                past_quick_sales.map((quick_sales, ind) => {
                                                                    let dt = quick_sales?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2];
                                                                    return (
                                                                        <ClientCard
                                                                            name={quick_sales?.order_type == "Product" ? quick_sales?.name : quick_sales?.service}
                                                                            price={quick_sales?.price}
                                                                            created_at={quick_sales?.created_at}
                                                                            sale_by={quick_sales?.member}
                                                                            description={quick_sales?.product_details}
                                                                            dt={dt}
                                                                            dy={dy}
                                                                            sub_price={quick_sales?.price}
                                                                            qnty={quick_sales?.quantity}
                                                                        />
                                                                    )
                                                                })

                                                            }
                                                            {past_quick_sales?.length >= 5 &&
                                                                <div className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center' onClick={() => {
                                                                    localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                    navigate("/dashboard/sales/sale-history")
                                                                }}>{selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}</div>}
                                                        </div>
                                                    }
                                                </>
                                                : ""
                                            : ""
                                }

                                {
                                    SideSection == 'Offers' && (past_voucher?.length === 0) ?
                                        <div className='rounded-md p-4 text-[#55555] h-[10rem]'>
                                            <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                <div>
                                                    <div className='w-fit mx-auto'>
                                                        <Svgs.NotFoundIcon />
                                                    </div>
                                                    <h1 className='font-semibold pt-2 text-sm'>
                                                        {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        (SideSection == 'Offers') ?
                                            ((news_voucher?.length != 0) || (past_voucher?.length != 0)) ?
                                                <>

                                                    {
                                                        past_voucher?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "Past" : translation[151]?.value ? translation[151]?.value : "Past"}
                                                                <TotalCount count={vouchersCount} />
                                                            </h1>

                                                            {

                                                                past_voucher.map((prdct, ind) => {
                                                                    let dt = prdct?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2];

                                                                    return (
                                                                        <ClientCard
                                                                            name={prdct?.voucher ? prdct?.voucher : prdct?.membership ? prdct?.membership : ""}
                                                                            price={prdct?.price}
                                                                            created_at={prdct?.created_at}
                                                                            sale_by={prdct?.member}
                                                                            // description={prdct?.product_details}
                                                                            dt={dt}
                                                                            dy={dy}
                                                                            type={prdct?.order_type}
                                                                        />
                                                                    )

                                                                })


                                                            }
                                                            {past_voucher?.length >= 5 &&
                                                                <div className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center' onClick={() => {
                                                                    localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                    navigate("/dashboard/sales/sale-history")
                                                                }}>{selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}</div>}
                                                        </div>
                                                    }

                                                </>
                                                : ""
                                            : ""
                                }

                                {
                                    SideSection == 'Disclaimer' &&
                                    <div className='p-3'>
                                        <div className='rounded-md p-4 text-[#55555] min-h-[10rem]'>
                                            {
                                                clientImages.length > 0 ? <>
                                                    <div className='flex items-center justify-center h-full'>
                                                        <div className='flex items-center justify-center gap-5 flex-wrap'>
                                                            {clientImages.map((item) => (
                                                                <img key={item?.id} src={item?.image} alt='Image' className="w-24 h-24 object-cover rounded-md" onClick={() => window.open(item?.image, '_blank')} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </> : <>
                                                    <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                        <div>
                                                            <div className='w-fit mx-auto'>
                                                                <Svgs.NotFoundIcon />
                                                            </div>
                                                            <h1 className='font-semibold pt-2 text-sm'>
                                                                {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>

            }


            {
                showAddClientPopup &&
                <AddClientPopup open={showAddClientPopup} close={setShowAddClientPopup} getClients={getClients} />
            }
        </div>
    )
}

export default CheckBoxClientSection