import moment from "moment";
import React from "react";

function Sold({ ShowDetails, setShowDetails, i, report_obj, selectedLanguage, translation }) {
  return (
    <div className="scale-in-center">
      <div className="bg-[#FAF9FF] px-6 py-3">
        <div className="inline-grid  grid-cols-[repeat(8,minmax(140px,2fr))] gap-5 border-b">
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.report_choice}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Stock before" : translation[1128]?.value ? translation[1128]?.value : "Stock before"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.before_quantity}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.location?.address_name}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Date" : translation[450]?.value ? translation[450]?.value : "Date"}
            </p>
            <p className="text-black font-semibold">
              {moment(report_obj?.created_at).format("DD-MM-YYYY")}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Sold Stock" : translation[1110]?.value ? translation[1110]?.value : "Sold Stock"}
            </p>
            <p className="text-black font-semibold"> {(report_obj?.before_quantity - report_obj?.after_quantity)}</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Updated Stock" : translation[1125]?.value ? translation[1125]?.value : "Updated Stock"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.after_quantity}
            </p>
          </div>


          {/* <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">Retail Price</p>
            <p className="text-black font-semibold">
              {report_obj?.retail_price ? report_obj?.retail_price?.retail_price : "0"}{" "}
              {report_obj?.retail_currency}
            </p>
          </div> */}

          {/* <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">Cost Price</p>
                <p className="text-black font-semibold">
                  {report_obj?.product?.cost_price}
                </p>
              </div> */}


          <div className="flex flex-col gap-1"></div>
          <div className="flex flex-col gap-1"></div>
          <div className="flex items-start justify-end"></div>
        </div>
      </div>
    </div>


    // <div
    //   className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails ? "border-l-[4px] border-[#5679FFB2]" : "border-l-[0px]"
    //     }`}
    // >
    //   <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(9,minmax(155px,1fr))]">
    //     <div
    //       className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1
    //         ? "before-left-arrow-open"
    //         : "before-left-arrow-close"
    //         }`}
    //       onClick={() => {
    //         setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
    //       }}
    //     >
    //       <div className="h-full flex items-center">
    //         <p className="text-sm">{report_obj?.product?.name} </p>
    //       </div>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm line-clamp-1 pr-3">{report_obj?.report_choice}</p>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm">
    //         {/* {report_obj?.available_stock?.current_stock}  available stock*/}
    //         {report_obj?.after_quantity}
    //       </p>
    //     </div>

    //     {/* <div className="flex items-center">
    //       <p className="text-sm">{report_obj?.product?.cost_price}</p>
    //     </div> */}

    //     <div className="flex items-center">
    //       <p className="text-sm">
    //         {report_obj?.retail_price ? report_obj?.retail_price?.retail_price : "0"}{" "}
    //         {report_obj?.retail_currency}
    //       </p>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm">{report_obj?.product?.brand}</p>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm">{report_obj?.created_at.split("T", 1)}</p>
    //     </div>
    //   </div>
    //   {ShowDetails == i + 1 ? (
    //     <div className="scale-in-center">
    //       <div className="bg-[#FAF9FF] px-6 py-3">
    //         <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(140px,2fr))] gap-y-5">
    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Product</p>
    //             <p className="text-black font-semibold">{report_obj?.product?.name}</p>
    //           </div>
    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Type</p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.report_choice}
    //             </p>
    //           </div>
    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Stock before Sale</p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.before_quantity}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Location</p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.location?.address_name}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Date</p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.created_at.split("T", 1)}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Retail Price</p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.retail_price ? report_obj?.retail_price?.retail_price : "0"}{" "}
    //               {report_obj?.retail_currency}
    //             </p>
    //           </div>

    //           {/* <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Cost Price</p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.product?.cost_price}
    //             </p>
    //           </div> */}

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Sold Stock</p>
    //             <p className="text-black font-semibold"> {(report_obj?.before_quantity - report_obj?.after_quantity)}</p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">Updated Stock</p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.after_quantity}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1"></div>
    //           <div className="flex flex-col gap-1"></div>
    //           <div className="flex items-start justify-end"></div>
    //         </div>
    //       </div>
    //     </div>
    //   ) : (
    //     ""
    //   )}
    // </div>
  );
}

export default Sold;
