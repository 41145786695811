import React from "react";

import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import useOrderStock from "../../useOrderStock";
import NotFound from "Components/Utility/NotFound";
import DeleteConfirmation from "new-components/delete-popup";

import Svgs from "Assets/svgs";
import NoPurchaseOrderDataFound from "Components/Utility/NoPurchaseOrderDataFound";
import { useState } from "react";
import { useEffect } from "react";

function Step3({
  step,
  setStep,
  orderStock,
  selectedProductsList,
  orderStockHandleChange,
  removeSelectedProduct,
  handleOrder,
  loading,
  deleting,
  setDeleting,
  isEdit,
  searchText,
  setSearchText,
  onBlurHandler,
  errors,
  openDeletePopup,
  setOpenDeletePopup,
  deleteId,
  translation,
  selectedLanguage,
  setDeleteId,
  lastStepError,
}) {
  const { quantity, selected_product_list } = orderStock;
  const [isValid, setIsValid] = useState(true);
  const [submitButtonClick, setSubmitButtonClick] = useState(false)

  useEffect(() => {
    const validateSelectedProductList = () => {
      const isListValid = selected_product_list.every(obj => {
        if (obj.quantity > obj.reorder_quantity) {
          return false;
        }
        if (obj.quantity === "") {
          return false;
        }
        if (obj.quantity === 0 && obj.quantity !== "") {
          return false;
        }
        return true;
      });
      setIsValid(isListValid);
    };

    validateSelectedProductList();
  }, [selected_product_list, selected_product_list?.length]);

  return (
    <>
      {/* <div className="mt-[1rem] border flex items-center justify-between gap-4 border-[#BAD3FC] bg-[#ECF3FF] px-4 py-3 rounded-lg">
        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
          <CircleIcon bg="#5679FF">
            <p className="text-xl text-white">JT</p>
          </CircleIcon>
          <div className="flex flex-col gap-1 lg:text-left text-center">
            <h2 className="text-primary font-semibold text-xl">
              Junaid Tahir
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              Bahadurabad Chowrangi, Bahadurabad Bahadur Yar Jang CHS.
            </p>
          </div>
        </div>
        <div>
          <LoginBtn
            className="border border-primary bg-transparent text-primary"
            text="View Details"
          />
        </div>
      </div> */}

      <div className="border rounded-lg mt-[1rem]">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
              </h2>
              <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                <p className="text-primary">
                  {selected_product_list?.filter((item) => {
                    if (
                      item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                      item?.stocks?.length
                        ?.toString()
                        .includes(searchText.toLowerCase()) ||
                      item?.location_quantities?.length
                        ?.toString()
                        ?.includes(searchText.toString()) ||
                      item?.isDeleted === true
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }).length} {" "}
                  {selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                </p>
              </div>
            </div>
            <div className="mb-00">
              <LoginInput
                title=""
                placeholder={selectedLanguage?.name == "English" ? "Search Product" : translation[1265]?.value ? translation[1265]?.value : "Search Product"}
                padding="py-1"
                leftIcon={<Svgs.Search />}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
            <LoginBtn
              padding="p-3"
              className="rounded-lg border border-primary text-sm"
              bg="bg-primary"
              
            >
              <div className="flex items-center gap-2">
                <Svgs.Filters fill="#fff" />
              </div>
            </LoginBtn>
          </div> */}
        </div>
        <div className="overflow-x-auto">
          <div className="inline-grid xl:grid gap-4 grid-cols-[repeat(5,minmax(160px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
            {/* <p>{selectedLanguage?.name == "English" ? "ID" : translation[603]?.value ? translation[603]?.value : "ID"}</p> */}
            <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
            <p>{selectedLanguage?.name == "English" ? "Available Stock" : translation[1030]?.value ? translation[1030]?.value : "Available Stock"}</p>
            <p>{selectedLanguage?.name == "English" ? "Min Quantity" : translation[1031]?.value ? translation[1031]?.value : "Min Quantity"}</p>
            <p>{selectedLanguage?.name == "English" ? "Order Quantity" : translation[1032]?.value ? translation[1032]?.value : "Order Quantity"}</p>
            {/* <p>{selectedLanguage?.name == "English" ? "Size" : translation[1035]?.value ? translation[1035]?.value : "Size"}</p> */}
            <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
          </div>
          {selected_product_list &&
            selected_product_list?.filter((item) => {
              if (
                item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                item?.stocks?.length
                  ?.toString()
                  .includes(searchText.toLowerCase()) ||
                item?.location_quantities?.length
                  ?.toString()
                  ?.includes(searchText.toString()) ||
                item?.isDeleted === true
              ) {
                return true;
              } else {
                return false;
              }
            }).length > 0 &&
            selected_product_list ? (
            selected_product_list
              ?.filter((item) => {
                if (
                  item?.name
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  item?.stocks?.length
                    ?.toString()
                    .includes(searchText.toLowerCase()) ||
                  item?.location_quantities?.length
                    ?.toString()
                    ?.includes(searchText.toString()) ||
                  item?.isDeleted === true
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              ?.map((product, index) => {
                let filteredProductError = {}
                filteredProductError = lastStepError?.length > 0 ? lastStepError?.find(itm => itm?.id == product?.product?.id) : {}
                return (
                  <div
                    key={index}
                    className={`odd-bg text-[#3A3A3A] transition-all`}
                  >
                    {product.isDeleted === false && (
                      <div className="px-12 py-3 pb-[1.5rem] inline-grid xl:grid gap-4 grid-cols-[repeat(5,minmax(160px,1fr))]">
                        {/* <div className="flex items-center gap-3">
                          <p className="text-sm font-semibold text-[#7B7B7B]">
                            {product?.id}
                          </p>
                        </div> */}
                        <div className="flex items-center gap-3">
                          <p className="text-sm">{product?.name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">{product?.current_stock} </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">{product?.low_stock} </p>
                        </div>
                        <div className="flex items-center">
                          <div className="w-[10rem] pb-4">
                            <LoginInput
                              name={`quantity`}
                              title=""
                              type={"number"}
                              placeholder={selectedLanguage?.name == "English" ? "Order Qty" : translation[1032]?.value ? translation[1032]?.value : "Order Qty"}
                              value={product?.quantity}
                              onBlur={() =>
                                onBlurHandler({ quantity: quantity })
                              }
                              onChange={(e) => {
                                orderStockHandleChange(e, index);
                              }}
                              error={product?.quantity > product.reorder_quantity ?
                                `${selectedLanguage?.name == "English" ? "Max quantity is" : translation[1198]?.value ? translation[1198]?.value : "Max quantity is"} ${product.reorder_quantity}` :
                                (product?.quantity == 0 && product?.quantity != "") ? `${selectedLanguage?.name == "English" ? "Quantity should be greater than 0." : translation[1198]?.value ? translation[1198]?.value : "Quantity should be greater than 0."}` :
                                  ((filteredProductError && !product?.quantity && filteredProductError?.quantity == '') || (submitButtonClick && product?.quantity == "")) ? selectedLanguage?.name == "English"
                                    ? "This field is required"
                                    : translation[287]?.value
                                      ? translation[287]?.value
                                      : "This field is required"
                                    : ""}
                            />
                            {/* {product?.quantity>product.reorder_quantity && `it must be less than ${product.reorder_quantity}`} */}
                          </div>
                        </div>
                        {/* <div className="flex items-center">
                          <p className="text-sm">{product?.product?.product_size ? `${product?.product?.product_size} g` : 0} </p>
                        </div> */}
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => {
                            setDeleteId(product?.id);
                            setOpenDeletePopup(true);
                          }}
                        >
                          <Svgs.Delete />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
          ) : (
            <>
              <NoPurchaseOrderDataFound />
            </>
          )}
          {openDeletePopup && (
            <DeleteConfirmation
              isLoading={deleting}
              heading={
                selectedLanguage?.name == "English"
                  ? "Delete product from purchase list?"
                  : translation[1070]?.value
                    ? translation[1070]?.value
                    : "Delete product from purchase list?"
              }

              description={
                selectedLanguage?.name == "English" ? "Are you sure you want to remove this item from your purchase order"
                  : translation[1071]?.value
                    ? translation[1071]?.value
                    : "Are you sure you want to remove this item from your purchase order"
              }

              handleClose={() => setOpenDeletePopup(false)}
              handleDelete={() => {
                setDeleting(true);
                setTimeout(() => {
                  removeSelectedProduct(deleteId);
                  setOpenDeletePopup(false);
                  setDeleting(false);
                }, 200);
              }}
            />
          )}
        </div>
      </div>
      <div>
        <div className="flex items-center justify-end">
          <LoginBtn
            loading={loading}
            onClick={() => {
              setSubmitButtonClick(true)
              if (isValid) {
                handleOrder()
              }
            }}
            disabled={loading}
          // disabled={loading ||
          //   selected_product_list.filter((obj) => obj?.quantity === "" || obj.quantity > obj.reorder_quantity)
          //     .length > 0 || selected_product_list?.some((product) => {
          //       return (product?.id && product?.quantity <= 0 && product?.quantity != "");
          //     })
          //   ? true
          //   : false
          // }
          >
            <div className="flex items-center gap-2">
              <p>
                {isEdit
                  ? selectedLanguage?.name == "English" ? "Update Now" : translation[1072]?.value ? translation[1072]?.value : "Update Now"
                  : selectedLanguage?.name == "English" ? "Order Now" : translation[1037]?.value ? translation[1037]?.value : "Order Now"
                }
              </p>
            </div>
          </LoginBtn>
        </div>
      </div>
    </>
  );
}

export default Step3;
