import Svgs from "Assets/svgs";
import React from "react";

const StarRatings = ({
    className = "",
    starSize = "",
    parentClass = "",
    totalStars = 5,
    value = 0,
    name = "rating",
    title,
    allowHalfStar = true,
    onChange,
}) => {
    const handleClick = (index, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const clickPosition = event.clientX - rect.left;
        const width = rect.width;

        // Determine the new value based on whether half-star clicks are allowed
        const isHalf = allowHalfStar && clickPosition < width / 2;
        const newValue = isHalf ? index + 0.5 : index + 1;

        if (onChange) {
            onChange({
                target: {
                    name,
                    value: newValue,
                },
            });
        }
    };

    return (
        <div className={`flex flex-col gap-2 ${parentClass}`}>
            {title && <p className="text-sm">{title}</p>}
            <div className={`flex gap-3 ${className}`}>
                {[...Array(totalStars)].map((_, index) => {
                    const isFullStar = index + 1 <= Math.floor(value);
                    const isHalfStar = !isFullStar && value > index && value < index + 1;

                    return (
                        <span
                            key={index}
                            onClick={(e) => handleClick(index, e)}
                            className="text-[5rem] cursor-pointer"
                        >
                            {isFullStar ? (
                                <Svgs.StarFillIcon width={starSize ? starSize : 24} />
                            ) : isHalfStar ? (
                                <Svgs.StarHalfIcon width={starSize ? starSize : 24} />
                            ) : (
                                <Svgs.StarEmptyIcon width={starSize ? starSize : 24} />
                            )}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};

export default StarRatings; 