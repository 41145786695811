import React from 'react'

function CheckBox({ parentClass, disabled, label_text_transform, mainLabelClass, label_Class, name, onChange, value,
    checked, error, label, colorLabel1, colorLabel2, className, id, onClickLabel1, onClickLabel2, MainClassName }) {
    var unique_id = Math.floor(Math.random() * 100) * Date.now();
    return (
        <div className={`${MainClassName} flex flex-col gap-1`}>
            <div className={`flex items-center sm:gap-4 gap-2 ${parentClass ? parentClass : ""}`}>
                <input disabled={disabled} id={`${id ? id : unique_id}`} type="checkbox" name={name} onChange={onChange} value={value} checked={checked} className={`${className ? className : "accent-[#5679FF] h-[1.3rem] w-[1.3rem]"} ${disabled && "cursor-not-allowed"} `} />
                <div className='flex space-x-1 items-center'>
                    <label htmlFor={`${id ? id : unique_id}`} className={`${mainLabelClass}`} >
                        <p className={`text-sm font-semibold cursor-pointer ${label_Class ? label_Class : ' text-[#7B7B7B]'} ${label_text_transform ? label_text_transform : 'normal-case'}`}>
                            {label}
                        </p>
                    </label>
                    <span className='text-primary cursor-pointer' onClick={(e) => {
                        e.stopPropagation()
                        onClickLabel1 && onClickLabel1()
                    }}>{colorLabel1}  </span>  {colorLabel2 && <span> <span>&</span> <span className='text-primary cursor-pointer' onClick={(e) => {
                        e.stopPropagation()
                        onClickLabel2 && onClickLabel2()
                    }}>{colorLabel2}</span></span>}
                </div>
            </div>
            {error && <span className='text-[#eb3b3b] text-xs text-right'>{error}</span>}
        </div>
    )
}

export default CheckBox
