import PrivacyPolicy from "Screens/NstyleApp/PrivacyPolicy";
import LoginScreen from "Screens/NstyleApp/Auth/LoginScreen";
import SignupScreen from "Screens/NstyleApp/Auth/SignupScreen";
import ForgetScreen from "Screens/NstyleApp/Auth/ForgetScreen";
import TermsAndConditions from "Screens/NstyleApp/TermsAndConditions";
import AccountActivation from "Screens/NstyleApp/Auth/AccountActivation";
import OnlineProfile from "Components/NstyleApp/User/Profile/OnlineProfile";
import ProfileAppointment from "Components/NstyleApp/User/Profile/ProfileAppointment";
import MyAppointments from "Components/NstyleApp/User/Appointments/MyAppointments";
import ChooseAccountType from "Components/NstyleApp/AccountTypesAndSignupTypes/ChooseAccountType";
import Help from "Screens/NstyleApp/Help";
import Outlet from "Screens/NstyleApp/Help/DetailSections/Outlet";
import LandingPage from "Components/NstyleApp/Home";
import Blogs from "Components/NstyleApp/Blogs";
import BlogDetail from "Components/NstyleApp/BlogDetail";
import ContactUs from "Components/NstyleApp/ContactUs";
import FeaturePage from "Components/NstyleApp/Features";
import PricingPage from "Components/NstyleApp/Pricing";
import AboutUs from "Components/NstyleApp/AboutUs";
import PrivacyPolicyV2 from "Components/NstyleApp/PrivacyPolicy";
import CustomerComplaintFeedBack from "Components/TenantApp/CustomerComplaintFeedBack/CustomerComplaintFeedBack";

export const publicRoutes = [

    {
        path: "auth/account-type",
        element: <ChooseAccountType />
    },
    {
        path: "auth/login",
        element: <LoginScreen />
    },
    {
        path: "auth/login/forgot",
        element: <ForgetScreen />
    },
    {
        path: "auth/login/activate",
        element: <AccountActivation />
    },
    {
        path: "auth/signup",
        element: <SignupScreen />
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicy />
    },
    {
        path: "terms-and-conditions",
        element: <TermsAndConditions />
    },
    {
        path: "auth/verify-acount/",
        element: <SignupScreen />
    }, {
        path: "/profile/:location/:address/:id",
        element: <OnlineProfile />
    },
    {
        path: "/profile-appointment",
        element: <ProfileAppointment />
    },
    {
        path: "/clients-appointments",
        element: <MyAppointments />
    },
    {
        path: "/help",
        element: <Help />
    },
    {
        path:"/home",
        element: <LandingPage />
    },
    {
        path: "/blogs",
        element: <Blogs />
    },
    {
        path: "/blog/:slug",
        element: <BlogDetail />
    },
    {
        path: "/contact",
        element: <ContactUs />
    },
    {
        path:"/privacy-policy-v2",
        element: <PrivacyPolicyV2 />
    },
    {
        path: "/feature",
        element: <FeaturePage />
    },
    {
        path: "/pricing",
        element: <PricingPage />
    },
    {
        path: "/about",
        element: <AboutUs />
    },
    {
        path: "/help-details",
        element: <Outlet />
    },
    {
        path: "/complaint-feedback/:id",
        element: <CustomerComplaintFeedBack />
    },
]