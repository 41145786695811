export const SALESREPORT = {
    GET_PRODUCT_SALES_REPORT: "GET_PRODUCT_SALES_REPORT",
    GET_SERVICE_SALES_REPORT: "GET_SERVICE_SALES_REPORT",
    GET_MEMBERSHIP_SALES_REPORT: "GET_MEMBERSHIP_SALES_REPORT",
    GET_VOUCHER_SALES_REPORT: "GET_VOUCHER_SALES_REPORT",
    GET_GIFTCARD_SALES_REPORT: "GET_GIFTCARD_SALES_REPORT",
    GET_CLIENT_ALL_SALES_REPORT: "GET_CLIENT_ALL_SALES_REPORT",
    GET_APPOINTMENT_SALES_REPORT: "GET_APPOINTMENT_SALES_REPORT",
    GET_TAX_REPORT: "GET_TAX_REPORT",
};

export const SALESUMMARYREPORT = {
    GET_APPOINTMENT_SALE_SUMMARY_REPORT: "GET_APPOINTMENT_SALE_SUMMARY_REPORT",
    GET_PRODUCT_SALE_SUMMARY_REPORT: "GET_PRODUCT_SALE_SUMMARY_REPORT",
};

export const EMPLOYEETIPREPORT ={
    GET_EMPLOYEE_TIP_REPORT: "GET_EMPLOYEE_TIP_REPORT"
}