import GroupInvoiceModal from 'Components/TenantApp/Dashboard/Sales/sale-history/GroupInvoiceModal'
import SelectedInvoiceModal from 'Components/TenantApp/Dashboard/Sales/sale-history/SelectedInvoiceModal'
import React from 'react'
import useOpenDetailInvoicehelper from './helper'

const OpenDetailInvoice = ({ getSaleHistory, setCheckoutId, checkoutId }) => {
    const { translation, selectedLanguage, detailLoading, InvoicePopup, setInvoicePopup, selectedInvice, calculate_totals,
        invoice_titles } = useOpenDetailInvoicehelper({ checkoutId })
    return (
        <>
            {selectedInvice?.checkout_type == "Group Appointment" ?
                <GroupInvoiceModal
                    open={InvoicePopup}
                    close={() => {
                        setCheckoutId()
                        setInvoicePopup(false)
                    }}
                    invoice_titles={invoice_titles}
                    selectedInvice={selectedInvice}
                    selectedLanguage={selectedLanguage}
                    translation={translation}
                    calculate_totals={calculate_totals}
                    loading={detailLoading}
                    getSaleHistory={getSaleHistory}
                    hideRefund
                /> :
                <>
                    <SelectedInvoiceModal
                        open={InvoicePopup}
                        close={() => {
                            setCheckoutId()
                            setInvoicePopup(false)
                        }}
                        invoice_titles={invoice_titles}
                        selectedInvice={selectedInvice}
                        selectedLanguage={selectedLanguage}
                        translation={translation}
                        calculate_totals={calculate_totals}
                        loading={detailLoading}
                        getSaleHistory={getSaleHistory}
                        hideRefund
                    />
                </>
            }
        </>
    )
}

export default OpenDetailInvoice
