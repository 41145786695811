import { buildQueryString } from "Constants/Functions/buildQueryString";
import { BASE_URL, get_appointment_sale_report, get_appointment_sale_summary_report, get_csv_sale_report, get_giftcard_sale_report, get_membership_sale_report, get_product_sale_report, get_product_sale_summary_report, get_service_sale_report, get_employee_tip_report_url, get_voucher_sale_report, get_tax_report_url, get_tax_report_chart_url } from "../api_variables";
import axios from "axios";
import { getAppointmentSaleReport, getAppointmentSaleSummaryReport, getClientAllSaleReport, getEmployeeTipReport, getGiftCardSaleReport, getMembershipSaleReport, getProductSaleReport, getProductSaleSummaryReport, getServiceSaleReport, getTaxReport, getVoucherSaleReport } from "Redux/TenantRedux/Actions/salesReportActions";
import { toast } from "react-toastify";
import { exportCSVFile } from "Constants/Functions/exportCSVFile";

// Get CSV Excel Data API
export const GetSaleReportCSVExcelDataApi = (access_token, payload, success, fail) => async () => {
    const query = buildQueryString(payload);
    const headers = {
        headers: {
            Authorization: access_token ? `Bearer ${access_token}` : "",
        },
        responseType: "blob", // Ensures the response is treated as a file
    };

    try {
        const response = await axios.get(`${BASE_URL}${get_csv_sale_report}${query}`, headers);

        if (response?.status === 200) {
            if (!response?.data || response?.data.size === 0) {
                toast.warn("No data found for export.", { toastId: "toast" });
                fail?.("No data found");
                return;
            }

            exportCSVFile(response?.data, payload?.exportName);
            toast.success(`${payload?.exportName} Data Exported Successfully`, { toastId: "toast" });

            success?.();
        } else {
            fail?.();
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            // Convert Blob to JSON to extract error message
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const errorResponse = JSON.parse(reader.result); // Parse JSON from Blob
                    console.log(errorResponse, "errorResponse")
                    const errorMessage = errorResponse?.error || "No data available for export.";
                    toast.error(errorMessage, { toastId: "toast" });
                    fail?.(errorMessage);
                } catch (parseError) {
                    toast.error("No data available for export.", { toastId: "toast" });
                    fail?.("No data available for export.");
                }
            };
            reader.readAsText(error.response.data); // Read Blob data as text
        }
        fail?.(error);
    }
};

// get product sale report api
export const getProductSaleReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_product_sale_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getProductSaleReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

// get service sale report api
export const getServiceSaleReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_service_sale_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getServiceSaleReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

// get membership sale report api
export const getMembershipSaleReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_membership_sale_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getMembershipSaleReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

// get voucher sale report api
export const getVoucherSaleReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_voucher_sale_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getVoucherSaleReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};


// get giftcard sale report api
export const getGiftCardSaleReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_giftcard_sale_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getGiftCardSaleReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

// get appointment sale report api
export const getAppointmentSaleReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_appointment_sale_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getAppointmentSaleReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

// get client all sale report api
export const getClientAllSaleReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}/client/client/${payload?.client_id}/sales/${query}`, headers);
        if (result?.status === 200) {
            dispatch(getClientAllSaleReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};


// SALE SUMMARY API
// get appointment sale summary report api
export const getAppointmentSaleSummaryReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_appointment_sale_summary_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getAppointmentSaleSummaryReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};


// get product sale summary report api
export const getProductSaleSummaryReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_product_sale_summary_report}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getProductSaleSummaryReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

// get Employee tip report api
export const getEmployeeTipReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_employee_tip_report_url}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getEmployeeTipReport(result?.data))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

// get tax report api
export const getTaxReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_tax_report_url}${query}`, headers);
        if (result?.status === 200) {
            dispatch(getTaxReport(result?.data?.results))
            success && success(result?.data?.response)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

export const getTaxReportChartApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_tax_report_chart_url}${query}`, headers);
        if (result?.status === 200) {
            success && success(result?.data?.response?.data)
            return
        } else {
            fail && fail(result?.data?.results)
            return
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
};
