import React from 'react'
import useCreateComplainHelper from './helper'
import Popup from 'Components/TenantApp/Elements/Popup'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Textarea from 'Components/TenantApp/Elements/Textarea'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown'

const CreateComplaintPopup = ({ open, close, onSucessCallBack }) => {
    const {
        translation, selectedLanguage, submitLoading, formData, onChangeHandler, employeeDropdown, employeePaginationData,
        employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, serviceDropdown,
        complaintCategoryDropdown, errors, onSubmit, clientRedux
    } = useCreateComplainHelper(open, close, onSucessCallBack)
    return (
        <>
            <Popup open={open} close={close} heading={open?.id ? "Edit Complaint" : "Create Complaint"}>
                <div className="flex flex-col gap-6">
                    <div className="grid grid-cols-2 gap-5">
                        {/* <LoginInput
                            name={'customer_name'}
                            value={formData?.customer_name}
                            onChange={onChangeHandler}
                            error={errors?.customer_name}
                            title={'Customer Name'}
                            placeholder={'Enter Customer Name'}
                            required={true}
                        /> */}
                        <Dropwdown
                            required={true}
                            name="client"
                            value={formData?.client}
                            onChange={onChangeHandler}
                            error={errors?.client}
                            title={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                            options={clientRedux?.map((item) => ({
                                label: item.full_name,
                                value: item.id
                            }))}
                        />

                        <Dropwdown
                            required={true}
                            name={'category'}
                            error={errors?.category}
                            title={'Select Category'}
                            value={formData?.category}
                            onChange={onChangeHandler}
                            placeholder={'Select Category'}
                            options={complaintCategoryDropdown}
                        />

                        {(formData?.category == complaintCategoryDropdown?.find(item => item?.label == 'Staff Behavior')?.value || formData?.category == complaintCategoryDropdown?.find(item => item?.label == 'Service Delay')?.value) && (
                            <div className="mb-00">
                                <PaginationDropdown
                                    title="Employee"
                                    showTitle={true}
                                    required={false}
                                    page={employeePage}
                                    className={'w-full'}
                                    name="assigned_employee"
                                    loading={employeeLoader}
                                    setPage={setEmployeePage}
                                    onChange={onChangeHandler}
                                    options={employeeDropdown}
                                    dropdownSearch={employeeSearch}
                                    error={errors?.assigned_employee}
                                    custom_min_width={"min-w-[15rem]"}
                                    value={formData?.assigned_employee}
                                    setDropdownSearch={setEmployeeSearch}
                                    lastPage={employeePaginationData?.employeePages}
                                    currentPage={employeePaginationData?.employeeCurrentPage}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                                />
                            </div>
                        )}

                        {formData?.category == complaintCategoryDropdown?.find(item => item?.label == 'Service Delay')?.value &&
                            <Dropwdown
                                required={false}
                                name={'service'}
                                title={'Service'}
                                error={errors?.service}
                                value={formData?.service}
                                options={serviceDropdown}
                                onChange={onChangeHandler}
                                placeholder={'Select Service'}
                            />
                        }

                        {(formData?.category == complaintCategoryDropdown?.find(item => item?.label == 'Product Quality')?.value || formData?.category == complaintCategoryDropdown?.find(item => item?.label == 'Order Issue')?.value || formData?.category == complaintCategoryDropdown?.find(item => item?.label == 'Payment Issue')?.value) && (
                            <LoginInput
                                required={false}
                                name={'invoice_id'}
                                title={'Invoice ID'}
                                charactersMaxLength={40}
                                onChange={onChangeHandler}
                                value={formData?.invoice_id}
                                placeholder={'Enter Invoice ID'}
                            />
                        )}

                        <div className="col-span-2">
                            <Textarea
                                name={'description'}
                                value={formData?.description}
                                onChange={onChangeHandler}
                                error={errors?.description}
                                title={'Description'}
                                placeholder={'Enter Description'}
                                required={true}
                            />
                        </div>
                    </div>
                    <LoginBtn
                        title={'Submit'}
                        text={'Submit'}
                        onClick={onSubmit}
                        loading={submitLoading}
                    />
                </div>

            </Popup>
        </>
    )
}

export default CreateComplaintPopup