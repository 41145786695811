import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Svgs from "../../../../../Assets/svgs";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";

const EmployeeCard = ({ onDelete, onEdit, animate, id, index, data, currency, hideDeleteIcon }) => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    // Shimmer
    animate ? <div className="rounded-xl shadow-appoint p-3 flex flex-col gap-3 animate-pulse">
      <div className="flex items-center justify-between">
        <div>
          <div className="h-[4.375rem] w-[4.375rem] rounded-full overflow-hidden bg-gray-200 "></div>
        </div>
      </div>
      <div className=" bg-[#F8FBFB] border-2 border-[#E1E7FF] p-2 rounded-lg">
        <p className="bg-gray-200 rounded-[4px] h-4 my-2 w-2/3 mx-1"></p>
        <p className="bg-gray-300 rounded-[4px] h-4 my-2 w-2/3 mx-1"></p>
        <p className="bg-gray-300 rounded-[4px] h-4 my-2 w-2/3 mx-1"></p>
        <p className="bg-gray-200 rounded-[4px] h-4 my-2 w-2/3 mx-1"></p>
      </div>
    </div>
      :
      <div className="rounded-xl bg-white shadow-appoint p-3 flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <div>
            <div className="h-[4.375rem] w-[4.375rem] relative rounded-full ">
              {data?.image ? (
                <img src={data?.image} className="h-full w-full object-cover rounded-full" />
              ) : (
                <LabelAvatar iconLabel={data?.full_name} index={index} className={'rounded-full '} />
                // <Svgs.AvatarClient />
              )}
              <span className={`absolute z-10 bottom-1 right-1.5 h-2.5 w-2.5 rounded-full ${data?.is_active ? 'bg-green-500' : 'bg-red-500'}  content-['']`} />
            </div>
          </div>
          <div className="flex items-center gap-3">
            {!employee ||
              (employee && employeePermissions?.employee_new?.includes("edit")) ? (
              <div
                onClick={() => onEdit(id)}
                className="h-[2.5rem] w-[2.5rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
              >
                <span>
                  <Svgs.Pen />
                </span>
              </div>
            ) : (
              ""
            )}
            {(!employee && !hideDeleteIcon) ? (
              <div
                onClick={() => onDelete(id)}
                className="h-[2.5rem] w-[2.5rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
              >
                <span>
                  <Svgs.Delete fill="#CE0000" />
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className=" bg-[#F8FBFB] border-2 border-[#E1E7FF] p-2 rounded-lg">
          <h1 className="font-semibold font-2xl line-clamp-1">
            {data?.full_name}
          </h1>
          <div className="flex items-center gap-2">
            <div className="w-[20px]">
              <Svgs.Email className={'!w-5'} />
            </div>
            <p className="truncate">{data?.email ? data?.email : "------"}</p>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[20px]">
              <Svgs.Dashboard_Payroll width="20px" height="20px" />
            </div>
            <p className="text-[#5D5D5D] text-sm">
              {currency} {data?.employee_info?.salary} /{" "}
              {data?.employee_info?.income_type == "Hourly_Rate"
                ? "hr"
                : data?.employee_info?.income_type == "Monthly_Salary"
                  ? "month"
                  : "day"}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <div className=" font-semibold">
              <p>Designation: </p>
            </div>
            <p className="text-[#5D5D5D] text-sm">{data?.employee_info?.designation}</p>
          </div>
        </div>
        {/* <div className="flex items-center gap-1 flex-wrap">
          {
                    ["service one", "service two"].map(item => {
                        return <div className='py-2 px-4 border text-xs border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer'>
                          {item}
                        </div>
                    })
                } 
        </div> */}
      </div>
  );
};

export default EmployeeCard;
