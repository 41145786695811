import React, { useState } from 'react'
import NotFound from 'Components/Utility/NotFound'
import Svgs from 'Assets/svgs'
import moment from 'moment'
import { statusClasses } from 'Constants/Data/Months'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import ClientFeedbackPopup from './ClientFeedbackPopup'

const ComplaintTable = ({ data, onEdit, onDelete, onDetail }) => {
    const [feedbackPopup, setFeedbackPopup] = useState(false)
    return (
        <>
            <div className="overflow-x-auto ">
                <div className="inline-grid xl:grid grid-cols-[repeat(11,minmax(205px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                    <p>Customer</p>
                    <p>Complaint Category</p>
                    <p className='col-span-2'>Description</p>
                    <p>Employee</p>
                    <p>Service</p>
                    <p>Invoice</p>
                    <p>Requested By</p>
                    <p>Created At</p>
                    <p>Status</p>
                    <p>Actions</p>
                </div>
                {data?.length > 0 ? (
                    data?.map((itm) => {
                        return (
                            <div className="text-[#3A3A3A] border-b transition-all px-12 py-3 odd-bg inline-grid items-center xl:grid grid-cols-[repeat(11,minmax(205px,1fr))] min-w-full w-fit">
                                <p className='line-clamp-1'>{itm?.customer_name || '----'}</p>
                                <p className='line-clamp-1'>{itm?.category_name || '----'}</p>
                                <p className='col-span-2 line-clamp-2'>{itm?.description || '----'}</p>
                                <p className='line-clamp-1'>{itm?.assigned_employee_name || '----'}</p>
                                <p className='line-clamp-1'>{itm?.service_name || '----'}</p>
                                <p className='line-clamp-1'>{itm?.invoice_id || '----'}</p>
                                <p className='line-clamp-1'>{itm?.requested_by_name || '----'}</p>
                                <p>{itm?.created_at ? moment(itm?.created_at).format('DD-MM-YYYY') : '----'}</p>
                                <p className={`px-2 py-1 rounded text-sm font-bold ${statusClasses[itm?.status] || 'bg-gray-100 text-gray-800'} w-fit text-sm`}>
                                    {itm?.status || '----'}
                                </p>
                                <div className="flex gap-2 items-center">
                                    <div
                                        onClick={() => onEdit(itm)}
                                        className="h-[2.5rem] w-[2.5rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
                                    >
                                        <span>
                                            <Svgs.Pen />
                                        </span>
                                    </div>
                                    <div
                                        onClick={() => onDelete(itm?.id)}
                                        className="h-[2.5rem] w-[2.5rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
                                    >
                                        <span>
                                            <Svgs.Delete fill="#CE0000" />
                                        </span>
                                    </div>
                                    <div
                                        onClick={() => onDetail(itm?.id)}
                                        className="h-[2.5rem] w-[2.5rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
                                    >
                                        <span>
                                            <Svgs.RequestDetailSvg size={"1.338rem"} />
                                        </span>
                                    </div>
                                    {itm?.feedback?.id &&
                                        <LoginBtn
                                            text={'Client Feedback'}
                                            onClick={() => { setFeedbackPopup(itm?.feedback) }}
                                        />
                                    }
                                </div>
                            </div>
                        )
                    })
                ) : <NotFound />}
            </div>

            {feedbackPopup?.id &&
                <ClientFeedbackPopup
                    open={feedbackPopup?.id}
                    close={() => { setFeedbackPopup() }}
                    data={feedbackPopup}
                />
            }
        </>
    )
}

export default ComplaintTable