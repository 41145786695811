import React, { useEffect, useState } from "react";
import Select from "react-select";
import Svgs from "../../../Assets/svgs";
import { error_message } from "../../../Constants/Variables";
import LabelAvatar from "./LabelAvatar";

const Dropwdown = ({
  custom_min_width,
  className,
  title,
  name,
  value,
  onBlur,
  textTransform,
  placeholder,
  options,
  onChange,
  error,
  disabled,
  required,
  noBorder,
  showTitle,
  edit,
  handleFocus,
  filterOption,
  custom_opening_hours_width,
  custom_padding,
  customHeight,
  inputClass,
  errorClass
}) => {

  const [field_err, setFieldErr] = useState(undefined)
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const parsedPlaceholder = placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      boxShadow: isFocused ? "none" : "none",
      border: noBorder
        ? 0
        : isFocused
          ? "1px solid #e5e7eb"
          : "1px solid #e5e7eb",
      color: "#101928",
      fontSize: "0.875rem",
      padding: "0.2rem 0.2rem",
      outline: "none",
      // minWidth: options.find((itm) => itm.value == value) ? `${(options.find((itm) => itm.value == value).label.length * 0.625 + 3) > 12 ? 12 : (options.find((itm) => itm.value == value).label.length * 0.625 + 3)}rem` : '12rem' // Adjust the width calculation as needed
      // minWidth: '10rem'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? "#f2f2f2"
          : isFocused
            ? "#2684ff14"
            : "#fff",
        color: "#101928",
        fontSize: "0.875rem",
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    menu: (styles) => ({
      ...styles,
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      zIndex: 111111111111,
    }),
  };

  const errorStyle = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      boxShadow: isFocused ? "none" : "none",
      border: field_err && "1px solid #eb3b3b",
      color: "#101928",
      fontSize: "0.875rem",
      padding: "0.2rem 0.2rem",
      minHeight: "0",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? "#f2f2f2"
          : isFocused
            ? "#2684ff14"
            : "#fff",
        color: "#101928",
        fontSize: "0.875rem",
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    menu: (styles) => ({
      ...styles,
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      zIndex: '11111111222222221111',
    }),
    input: (styles) => ({
      ...styles,
    }),

    // sudo certbot --nginx -d result
  };


  useEffect(() => {
    setFieldErr(error)
  }, [error])

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setTimeout(() => {
      setMenuIsOpen(false);
    }, 100);
  };

  const customFormatOptionLabel = ({ label, otherData }) => (
    <div className={`flex items-center gap-3 ${customHeight ? customHeight : 'h-[1.5625rem]'} z-[10]`}>
      {otherData?.showImage == true ?
        <div className="h-[1.850rem] w-[1.850rem] min-h-[1.850rem] min-w-[1.850rem] rounded-full overflow-hidden">
          {
            otherData?.image ? (
              <img src={otherData?.image} className="h-full w-full object-cover" />
            ) : (
              <LabelAvatar index={otherData?.index} fontSize={'text-[12px]'} iconLabel={label} />
            )}
        </div>
        : ""
      }
      <div className="flex flex-col text-sm -space-y-1">
        <div className="text-sm">{label}</div>
        <div className="overflow-hidden whitespace-nowrap text-[8px]">{otherData?.DataOne && otherData.DataOne}</div>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`flex flex-col gap-2 flex-1 whitespace-nowrap capitalize relative  ${className} ${custom_opening_hours_width || ""} min-w-[12ch] ${custom_min_width ? custom_min_width : 'lg:min-w-[16ch]'} ${disabled ? "cursor-not-allowed" : ""
          }`}
      >
        {title != "" && showTitle && (
          <p className={`text-sm flex gap-1 ${textTransform ? textTransform : 'capitalize'}`}>
            {title && title}
            <span className="text-red-600">
              {required && required ? " * " : ""}
            </span>
          </p>
        )}
        <Select
          styles={field_err ? errorStyle : colourStyles}
          menuIsOpen={menuIsOpen}
          onMenuOpen={handleMenuOpen}
          formatOptionLabel={customFormatOptionLabel}
          onMenuClose={handleMenuClose}
          name={name}
          // className="border-0"
          className={`${error && "error"} ${inputClass} `}
          // menuPlacement={'auto'}
          menuPosition="fixed"

          menuShouldBlockScroll
          menuPortalTarget={document.body}
          classNamePrefix="custom"
          // defaultValue={options.find(itm => itm.value == value).label}
          // defaultInputValue={options.find(itm => itm.value == value).label}
          // value={value}
          // onChange = {onChange}
          isSearchable={window.innerWidth >= 1024 ? true : false} // Enable search for larger screens
          // onBlur={(e) => {
          //   onBlur({ target: { name: name } });
          // }}
          value={{
            value: options
              ? options?.find((itm) => itm?.value == value)
                ? options?.find((itm) => itm?.value == value)
                : ""
              : "",
            label: options
              ? options.find((itm) => itm?.value == value)
                ? options?.find((itm) => itm?.value == value)?.label
                : placeholder
              : placeholder
          }}
          // value={
          //   options.find((itm) => itm.value == value)
          //     ? options.find((itm) => itm.value == value)
          //     : ""
          // }

          options={options && options.length > 0 ? options : []}
          onChange={(e) => {
            setFieldErr(undefined)
            if (options.length > 0) {
              onChange &&
                onChange({
                  target: {
                    value: e.value,
                    name: name,
                  },
                });
            }
          }}
          isDisabled={edit ? false : options.length == 0 ? true : disabled}
          placeholder={parsedPlaceholder}
          isOptionDisabled={(option) => option.disabled}
          onFocus={() => {
            if (handleFocus) {
              handleFocus();
            }
          }}
          filterOption={filterOption}
        />

        {/* <p className='text-sm'>{title && title}{required && required ? ' * ' : <span className='text-[#A1A1A1] text-sm'> (Optional)</span>}</p> */}
        {/* {field_err && <span className='text-[#eb3b3b] flex items-center gap-2'>{field_err}<Svgs.I /></span>} */}

        {field_err && typeof field_err == "string" ? (
          <p className={`text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end normal-case pt-1 ${errorClass} ${custom_opening_hours_width || ''}`}>
            <Svgs.I fill="#eb3b3b" />
            {field_err}
          </p>
        ) : typeof field_err == "boolean" && field_err == true ? (
          <p className={`text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end normal-case pt-1 ${custom_padding || ''}`}>
            <Svgs.I fill="#eb3b3b" />
            {error_message}
          </p>
        ) : (
          ""
        )}
        {/* {field_err && <span className='text-[#eb3b3b] flex items-center gap-2 text-xs'>{field_err}<Svgs.I /></span>} */}
      </div>
    </>
  );
};

Dropwdown.defaultProps = {
  title: 'Enter "title" prop',
  placeholder: 'Enter "placeholder" prop',
  options: [
    // { value: 'no_value', label: 'Enter "options" prop' },
  ],
  name: "no_name",
  value: "no_value",
  onBlur: () => { },
  onChange: () => { },
  error: false,
  disabled: false,
  required: false,
  noBorder: false,
  showTitle: true,
};

export default Dropwdown;