import { MAINTANENCE } from "Redux/TenantRedux/ActionTypes/Maintanence/maintanence";

const initialState = {
  maintanence: [],
  maintanence_pagination: {}
};

const maintanenceReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case MAINTANENCE.GET_MAINTANENCE:
      return {
        ...state,
        maintanence: payload?.results?.response?.data,
        maintanence_pagination: {
          current_page: payload?.results?.current_page,
          total_pages: payload?.results?.total_pages,
          count: payload?.results?.count,
        }
      };

    default:
      return state;
  }
};

export default maintanenceReducer;
