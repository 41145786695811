import Svgs from "Assets/svgs";
import { useRef, useEffect } from "react";

const TeamCard = ({ id, name, title, location, imageSrc, showSocials, toggleSocials }) => {
    const socialRef = useRef(null);
    const showSocialsPopup = showSocials === id; // Checks if this card's popup should be open

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (socialRef.current && !socialRef.current.contains(e.target)) {
                toggleSocials(null); // Close all popups if clicked outside
            }
        };

        if (showSocialsPopup) {
            document.addEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showSocialsPopup, toggleSocials]);

    return (
        <div className="flex flex-col gap-6 rounded-lg overflow-hidden">
            <div className="relative">
                <img src={imageSrc} alt={`${name} profile`} className="w-full" />

                {/* Social media button */}
                <button
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent immediate closing from handleClickOutside
                        toggleSocials(id);
                    }}
                    className="absolute bottom-0 right-0 rounded-md transition-all duration-300"
                >
                    <Svgs.MoreIcon
                        className="w-14 h-14 xs:h-16 xs:w-16 md:h-20 md:w-20"
                        fill={showSocialsPopup ? 'var(--primary-blue)' : ''}
                        stroke={showSocialsPopup ? '#fff' : ''}
                    />
                </button>

                {/* Social icons panel */}
                {showSocialsPopup && (
                    <div
                        ref={socialRef}
                        className="absolute bottom-28 right-2 bg-white rounded-[1.125rem] flex flex-col px-3 py-5 shadow-lg"
                    >
                        <a href="#" className="p-2 hover:bg-gray-100 rounded transition-colors">
                            <Svgs.NewFBIconV2 className="h-8 w-8" />
                        </a>
                        <a href="#" className="p-2 hover:bg-gray-100 rounded transition-colors">
                            <Svgs.NewXIcon className="h-8 w-8" />
                        </a>
                        <a href="#" className="p-2 hover:bg-gray-100 rounded transition-colors">
                            <Svgs.InstagramIcon />
                        </a>
                        <a href="#" className="p-2 hover:bg-gray-100 rounded transition-colors">
                            <Svgs.LinkedInFilled2 />
                        </a>
                    </div>
                )}
            </div>

            <div className="flex flex-col justify-center items-center gap-0">
                <h3 className="font-bold text-primary-black text-center text-2xl">{name}</h3>
                <p className="text-base text-primary-black text-center">{title} {location && `, ${location}`}</p>
            </div>
        </div>
    );
};

export default TeamCard;
