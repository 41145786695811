import Svgs from 'Assets/svgs';
import Footer from 'Components/Utility/Footer';
import Navbar from 'Components/Utility/Navbar';
import TeamCard from 'Components/Utility/TeamCard';
import React, { useState } from 'react';

const profiles = [
  {
    id: 1,
    name: "Tim Draper",
    title: "Lead Investor",
    location: "",
    imageSrc: "/images/team/team (1).png"
  },
  {
    id: 2,
    name: "Andy Seol",
    title: "Poultry Consultant & Advisor",
    location: "USA",
    imageSrc: "/images/team/team (2).png"
  },
  {
    id: 3,
    name: "Joseph Nikola",
    title: "Food Science Expert",
    location: "Netherlands",
    imageSrc: "/images/team/team (3).png"
  },
  {
    id: 4,
    name: "Tim Draper",
    title: "Lead Investor",
    location: "",
    imageSrc: "/images/team/team (4).png"
  },
  {
    id: 5,
    name: "Andy Seol",
    title: "Poultry Consultant & Advisor",
    location: "USA",
    imageSrc: "/images/team/team (5).png"
  },
  {
    id: 6,
    name: "Joseph Nikola",
    title: "Food Science Expert",
    location: "Netherlands",
    imageSrc: "/images/team/team (6).png"
  }
];

const AboutUs = () => {
  const [showSocials, setShowSocials] = useState(false)

  const toggleSocials = (id) => {
    setShowSocials(() => showSocials === id ? false : id);
  };

  return (
    <>
      <div className="flex flex-col w-full font-manrope landing_page">
        <Navbar />
        <div className="flex relative overflow-x-hidden flex-col"        >
          <div className="max-w-5xl w-[95%] relative mx-auto py-12 flex flex-col gap-3 items-center justify-center text-center">
            <h1 className="text-[3.5rem] font-bold">About Us</h1>
            <p className="text-base">
              Your Beauty & Wellness Business, Simplified
            </p>
          </div>

          <div className="flex w-full py-16"
            style={{
              background: 'url(/images/about.png)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            <div className="flex bg-white w-11/12 max-w-3xl mx-auto rounded-[1.875rem] p-[1.875rem]">
              <div className="flex flex-col gap-3 items-center">
                <h1 className='text-[2.5rem] font-bold text-center'>Your Beauty & Wellness Business, Simplified</h1>
                <p className='text-base text-center'>
                  At NStyle, we empower beauty and wellness professionals with cutting-edge technology to streamline operations, enhance client experiences, and grow their businesses effortlessly. Whether you're a salon owner, a beauty expert, or a wellness professional, our platform is designed to make management seamless and stress-free.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-16 py-12 mx-auto w-[95%] max-w-[1200px]">
            {/* Section 1 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex max-h-80">
                <img src="/images/contact (1).png" alt="" srcset="" className='w-full object-contain' />
              </div>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Our Mission</h1>
                <p className='text-base'>
                  We believe that beauty and wellness businesses should focus on creativity and customer service—not complicated admin work. Our goal is to provide a smart, easy-to-use, and cost-effective solution that helps professionals thrive in the industry.
                </p>
              </div>
            </div>

            {/* Section 2 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Why Choose NStyle?</h1>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-2.5">
                    <div><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                    <p className='text-sm flex-1'>Effortless Booking & Management - Simplify scheduling, appointments, and client interactions.</p>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <div><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                    <p className='text-sm flex-1'>Seamless Payments - Accept transactions securely and quickly.</p>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <div><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                    <p className='text-sm flex-1'>Smart Team & Staff Coordination - Manage your business efficiently with easy-to-use tools.</p>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <div><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                    <p className='text-sm flex-1'>Retail & Inventory Control - Keep track of products, sales, and stock with ease.</p>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <div><Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} /></div>
                    <p className='text-sm flex-1'>No Hidden Fees - Transparent pricing with flexible options.</p>
                  </div>
                </div>
              </div>
              <div className="flex order-2 sm:order-none max-h-[28rem]">
                <img src="/images/contact (2).png" alt="" srcset="" className='w-full object-contain' />
              </div>
            </div>

            {/* Section 3 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex max-h-80">
                <img src="/images/contact (3).png" alt="" srcset="" className='w-full object-contain' />
              </div>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Our Journey</h1>
                <p className='text-base'>
                  Founded with a vision to revolutionize the beauty and wellness industry, NStyle has grown into a trusted platform used by professionals worldwide. Our commitment to innovation, user-friendly design, and top-tier support makes us the go-to choice for businesses of all sizes.
                </p>
              </div>
            </div>

          </div>

          <div className="flex flex-col gap-y-16 pb-12 mx-auto w-[95%] max-w-[1200px]">
            <h1 className="text-[3.5rem] font-bold text-center">Our Team</h1>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
              {profiles.map(profile => (
                <TeamCard
                  key={profile.id}
                  id={profile.id}
                  name={profile.name}
                  title={profile.title}
                  location={profile.location}
                  imageSrc={profile.imageSrc}
                  showSocials={showSocials}
                  setShowSocials={setShowSocials}
                  toggleSocials={toggleSocials}
                />
              ))}
            </div>
          </div>

          <div className="w-[95%] max-w-[1200px] feature-cta-bg rounded-[1.25rem] mx-auto mb-32 p-8 md:p-14 relative">
            <div className="grid sm:grid-cols-3 gap-5 w-full max-w-5xl mx-auto">
              <div className="flex flex-col sm:col-span-2 gap-2.5">
                <h1 className="text-[2.125rem] font-bold">
                  Join the NStyle Community
                </h1>
                <p className="text-lg">
                  Be a part of a growing network of beauty and wellness professionals who trust NStyle to elevate their businesses. Experience the future of salon and spa management—effortless, intuitive, and designed for success.
                </p>
              </div>

              <div className="flex justify-start sm:justify-end relative gap-4 items-center">
                <div className="hidden sm:flex w-fit absolute left-0 top-32">
                  <Svgs.CurveArrowIcon />
                </div>

                <button className='flex-1 bg_gradient-blue-tr w-full max-w-max mt-2.5 rounded-full py-4 px-7 text-white text-base font-semibold'>
                  Get Started for Free
                </button>
              </div>
            </div>
          </div>

        </div>

        <Footer />
      </div >
    </>
  );
};

export default AboutUs;
