import React from 'react'
import usePaymentMethodsReport from './helper'
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb'
import LocationSelection from "new-components/location";
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer';
import moment from 'moment';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import NotFound from 'Components/Utility/NotFound';

const PaymentMethodsReport = () => {
    const {     translation, selectedLanguage, startDate, setStartDate, endDate, setEndDate, loader, paymentMethodsData,
        toggleDateRange, setToggleDateRange, loadingPermission
    } = usePaymentMethodsReport()
    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                    second={selectedLanguage?.name == "English" ? "Payment Methods Report" : translation[1690]?.value ? translation[1690]?.value : "Payment Methods Report"}
                    url={'/dashboard/pos-analytics/'}
                />
                <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
                    <div className="flex items-center sm:gap-4 gap-2">

                        <h2 className="text-primary font-bold lg:text-3xl text-xl">
                            {selectedLanguage?.name == "English" ? "Payment Methods Report" : translation[1690]?.value ? translation[1690]?.value : "Payment Methods Report"}
                        </h2>
                        <LocationSelection />
                    </div>
                </div>
                <div className="border rounded-lg bg-white">
                    <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b sm:flex-row flex-col gap-3">
                        <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
                            <div className="flex items-center sm:gap-4 gap-2 ">
                                <h2 className="font-semibold text-xl">
                                    {selectedLanguage?.name == "English" ? "Payment Methods Details" : translation[1783]?.value ? translation[1783]?.value : "Payment Methods Details"}
                                </h2>
                                {loader ?
                                    <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                        <p className="rounded-md h-5 w-12 mx-1"> </p>
                                    </div>
                                    :
                                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                        <p className="text-primary">
                                            {paymentMethodsData?.length ? paymentMethodsData?.length : 0}{" "}
                                            {paymentMethodsData?.length && paymentMethodsData?.length > 1 ?
                                                selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                                : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                            }
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        {loadingPermission ?
                            <EmployeesHeadShimmer parentClass={'sm:w-fit w-full'} classOn1={'!w-full lg:!w-44 sm:!w-40'} classOn2={'!w-full lg:!w-44 sm:!w-40'} classOn3={'!w-full lg:!w-44 sm:!w-40'} classOn4={'!w-12 lg:!16'} has4Itm />
                            :
                            <div className="flex items-center sm:justify-center justify-end gap-3 sm:w-fit w-full flex-col xs:flex-row flex-nowrap">
                                <div className="flex items-center sm:gap-4 gap-2 xs:w-fit w-full">
                                    <div className='border border-[#A1A1A1] w-full text-sm rounded-md font-semibold flex items-center select-none'>
                                        <div className='p-2 relative gap-4 flex justify-between items-center w-full'>
                                            <p onClick={() => {
                                                setToggleDateRange(!toggleDateRange)
                                            }} className='cursor-pointer whitespace-nowrap'>
                                                {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/---"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/---"}`}
                                            </p>
                                            <span className='cursor-pointer' onClick={() => {
                                                setStartDate()
                                                setEndDate()
                                            }}>Clear</span>

                                            {
                                                toggleDateRange && <>
                                                    <div className='fixed inset-0 z-1 w-full' onClick={() => { setToggleDateRange(false) }}></div>
                                                    <div className='absolute top-full -right-16 xs:right-0 z-[2]'>
                                                        <DateRangePicker
                                                            start={startDate}
                                                            end={endDate}
                                                            setStartDate={setStartDate}
                                                            setEndDate={setEndDate}
                                                            setToggleDateRange={setToggleDateRange}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {loader ?
                        <TableShimer cols={5} />
                        :
                        <div className="overflow-x-auto">
                            <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(165px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                                <p>Method Name</p>
                                <p>Total Count</p>
                                <p>Total Amount</p>
                            </div>
                            <div className={`odd-bg text-[#3A3A3A] transition-all`}>
                                {paymentMethodsData?.length > 0
                                    ? paymentMethodsData?.map((item, i) => {
                                        return (
                                            <>
                                                <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(165px,1fr))] min-w-full w-fit even:bg-[#FBFCFF]">
                                                    <div className="h-full flex items-center">
                                                        <p className="text-sm">{item?.method_type}</p>
                                                    </div>
                                                    <div className="h-full flex items-center">
                                                        <p className="text-sm">{item?.total_count}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-1 pr-3">
                                                            {item?.total_amount?.toFixed(2)} {item?.currency}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    : <NotFound />
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default PaymentMethodsReport