import { getAllAppointments, getAllAppointmentsWithNoPagination } from "Adapters/Api/apointments";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


let timeOutId = undefined
function useAppointmentsHelper() {
    const dispatch = useDispatch();

    const state = useSelector((state) => state);
    const { all_appointments, all_appointments_no_pagination } = state.appointment;
    const count = state?.appointment?.count
    const allCount = state?.appointment?.allCount
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const isPermissionUpdated = state?.user?.isPermissionUpdated;

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const client_fullName = localStorage.getItem("client_search")
    const selectedLocationCurrency = getDataFromLocalStorage("selectedLocationCurrency")

    const [Search, setSearch] = useState("");
    const [noPaginationLoader, setNoPaginationLoader] = useState(true)
    const [exportLoader, setExportLoader] = useState(false);
    const [employee, setEmployee] = useState();
    const [currentPage, setCurrentPage] = useState(1)
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [loader, setLoader] = useState(true)
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [ShowDetails, setShowDetails] = useState(-1);
    const [dropdownStatus, setDropdownStatus] = useState('')
    const [loadingPermission, setLoadingPermission] = useState(true)
    const [cancelReasonPopup, setCancelReasonPopup] = useState(false)
    const [cancelReason, setCancelReason] = useState({})

    const PageSize = 10

    let StatusOptions = [
        { label: selectedLanguage?.name == "English" ? 'All Appointments' : translation[1342]?.value ? translation[1342]?.value : "All Appointments", value: "" },
        { label: selectedLanguage?.name == "English" ? 'Booked' : translation[1429]?.value ? translation[1429]?.value : "Booked", value: "Booked" },
        { label: selectedLanguage?.name == "English" ? 'Started' : translation[469]?.value ? translation[469]?.value : "Started", value: "Started" },
        { label: selectedLanguage?.name == "English" ? 'Finished' : translation[1430]?.value ? translation[1430]?.value : "Finished", value: "Finished" },
        { label: selectedLanguage?.name == "English" ? 'Done' : translation[1431]?.value ? translation[1431]?.value : "Done", value: "Done" },
        { label: selectedLanguage?.name == "English" ? 'Cancelled' : translation[172]?.value ? translation[172]?.value : "Cancelled", value: "Cancelled" },
        // { label: selectedLanguage?.name == "English" ? 'Void' : translation[1339]?.value ? translation[1339]?.value : "Void", value: "Void" }
    ];

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);


    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        if (client_fullName) {
            setSearch(client_fullName)
            const timeout = setTimeout(() => {
                localStorage.removeItem("client_search");
                return () => clearTimeout(timeout);
            }, 500);
        }
    }, [client_fullName])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [dropdownStatus, selected_location])

    useEffect(() => {
        if (startDate && endDate) {
            setCurrentPage(1)
        }
    }, [startDate, endDate])

    useEffect(() => {
        setCurrentPage(1)
    }, [Search])

    useEffect(() => {
        let data = {}
        if (startDate && endDate) {
            data = { page: currentPage, location: selected_location, start_date: startDate, end_date: endDate, appointment_status: dropdownStatus }
        } else {
            data = { page: currentPage, location: selected_location, appointment_status: dropdownStatus }
        }
        if (selected_location) {
            if (Search === null) {
                setLoader(true)
                // Initial load with empty search_text
                getDataFromApi(data)
            } else {
                data = { ...data, search_text: Search }
                if (timeOutId) {
                    clearTimeout(timeOutId)
                }
                timeOutId = setTimeout(() => {
                    setLoader(true)
                    getDataFromApi(data)
                }, 500);
            }
        }
    }, [Search, selected_location, currentPage, startDate, endDate, dropdownStatus])

    const getDataFromApi = (data) => {
        dispatch(getAllAppointments(data, setLoader));
        dispatch(getAllAppointmentsWithNoPagination(data, setNoPaginationLoader));
    }

    let headers = [
        // { label: "Booked_by", key: "booked_by" },
        // { label: "Service", key: "service" },
        { label: "Appointment ID", key: "booking_id" },
        { label: "Client", key: "client" },
        { label: "Client type", key: "appointment_type" },
        { label: "Status", key: "booking_status" },
        // { label: "Member", key: "member" },
    ];

    const csvReport = {
        filename: "Appointment History Report.csv",
        headers: headers,
        data: all_appointments_no_pagination?.map((itm) => {
            return {
                ...itm,
                booked_by: itm?.booked_by ? itm?.booked_by : "--------",
                booking_id: itm?.id ? itm?.id?.split('-')[0] : "--------",
                client: itm?.client?.full_name ? itm?.client?.full_name : "--------",
                booking_status: itm?.status ? itm?.status : "--------",
                appointment_type: itm?.client_type ? itm?.client_type : "--------",
                member: itm?.member ? itm?.member : "--------",
                service: itm?.service ? itm?.service : "--------",
            };
        }),
    };

    const handleExportClick = () => {
        if (!isToastVisible) {
            if (all_appointments_no_pagination?.length > 0) {
                // setExportLoader(true)
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                    setExportLoader(false)
                }, 300);
            } else {
                toast.error("No data to export", {
                    toastId: "toast",
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };

    return {
        selectedLanguage, translation, loader, allCount, loadingPermission, toggleDateRange,
        setToggleDateRange, startDate, setStartDate, endDate, setEndDate, setSearch, Search,
        exportLoader, employee, all_appointments_no_pagination, csvReport, handleExportClick,
        all_appointments, selectedLocationCurrency, currentPage, count, PageSize, setCurrentPage,
        ShowDetails, setShowDetails, StatusOptions, dropdownStatus, setDropdownStatus,
        cancelReasonPopup, setCancelReasonPopup, cancelReason, setCancelReason
    };
}

export default useAppointmentsHelper