import React from 'react'

const ToggleWithText = ({ title, title2, checked, className, name, value, onChange, disabled, textClass, label }) => {
    return (
        <div className={`${className} flex items-center justify-between`}>
            {title && <p className={`text-sm font-semibold cursor-pointer w-9/12 ${textClass}`}>{title}</p>}
            <div onClick={() => !disabled && onChange && onChange({ target: { name, value: !checked } })} className={`relative inline-block w-10 mr-0 align-middle select-none transition duration-200 ease-in ${disabled ? 'opacity-40 cursor-not-allowed' : ''}`}>
                <input disabled={disabled} type="checkbox" name={name} value={value} onChange={onChange} checked={checked} id={name} className="outline-none toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-2 disabled:cursor-not-allowed border-[#7B7B7B] appearance-none cursor-pointer" />
                <label for={name} className={`toggle-label block overflow-hidden h-6 rounded-full bg-[#7B7B7B] ${disabled ? 'cursor-not-allowed ' : 'cursor-pointer'}`}>
                    {label && label}
                </label>
            </div>
            {title2 && <p className={`text-sm font-semibold cursor-pointer pl-2 ${textClass}`}>{ title2}</p>}
        </div>
    )
}

export default ToggleWithText