import { EMPLOYEETIPREPORT, SALESREPORT, SALESUMMARYREPORT } from "Redux/TenantRedux/ActionTypes/salesReportTypes"

export const getProductSaleReport = (data) => {
    return {
        type: SALESREPORT.GET_PRODUCT_SALES_REPORT,
        payload: data
    }
}

export const getServiceSaleReport = (data) => {
    return {
        type: SALESREPORT.GET_SERVICE_SALES_REPORT,
        payload: data
    }
}

export const getMembershipSaleReport = (data) => {
    return {
        type: SALESREPORT.GET_MEMBERSHIP_SALES_REPORT,
        payload: data
    }
}

export const getVoucherSaleReport = (data) => {
    return {
        type: SALESREPORT.GET_VOUCHER_SALES_REPORT,
        payload: data
    }
}

export const getGiftCardSaleReport = (data) => {
    return {
        type: SALESREPORT.GET_GIFTCARD_SALES_REPORT,
        payload: data
    }
}

export const getAppointmentSaleReport = (data) => {
    return {
        type: SALESREPORT.GET_APPOINTMENT_SALES_REPORT,
        payload: data
    }
}

export const getClientAllSaleReport = (data) => {
    return {
        type: SALESREPORT.GET_CLIENT_ALL_SALES_REPORT,
        payload: data
    }
}

export const getAppointmentSaleSummaryReport = (data) => {
    return {
        type: SALESUMMARYREPORT.GET_APPOINTMENT_SALE_SUMMARY_REPORT,
        payload: data
    }
}

export const getProductSaleSummaryReport = (data) => {
    return {
        type: SALESUMMARYREPORT.GET_PRODUCT_SALE_SUMMARY_REPORT,
        payload: data
    }
}

export const getEmployeeTipReport = (data) => {
    return {
        type: EMPLOYEETIPREPORT.GET_EMPLOYEE_TIP_REPORT,
        payload: data
    }
}

export const getTaxReport = (data) => {
    return {
        type: SALESREPORT.GET_TAX_REPORT,
        payload: data
    }
}
