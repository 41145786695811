import AutoPlaceInput from 'Components/TenantApp/Elements/AutoPlaceInput';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import React from 'react'

const LocationStep = ({ handleChange, formData, currencyList, errors }) => {
    console.log(formData)
    return (
        <div className='my-[2rem] flex flex-col gap-4'>
            <h1 className='font-semibold text-xl'>Add Location</h1>
            <div>
                <LoginInput
                    required={true}
                    value={formData?.location?.name}
                    name={'name'}
                    onChange={(e) => { handleChange(e, 'location') }}
                    title={'Location Name'}
                    // charactersMaxLength={25}
                    placeholder={'Enter Location Name'}
                    // onBlur={() => onBlurHandler({ name: formData?.location?.name })}
                    error={(!formData?.location?.name && errors?.name) && errors?.name}
                />
            </div>
            <div>
                <AutoPlaceInput
                    onChange={(e) => { handleChange(e, 'location') }}
                    name={"business_address"}
                    value={formData?.location?.business_address}
                    required={true}
                    error={(!formData?.location?.business_address && errors?.address_name) && errors?.address_name}
                    title="Address"
                    placeholder="Enter Business Address"
                />
            </div>
            <div>
                <Dropwdown
                    required={true}
                    error={(errors?.currency && !formData?.location?.currency) && errors?.currency}
                    options={currencyList?.map((itm) => {
                        return {
                            label: itm?.code,
                            value: itm?.id,
                        };
                    })}
                    name={"currency"}
                    value={formData?.location?.currency}
                    onChange={(e) => { handleChange(e, 'location') }}
                    title={'Currency'}
                    placeholder={'Select Currency'} />
            </div>
            {/* <div>
                <LoginInput
                    required={true}
                    value={formData?.location?.email}
                    name={'email'}
                    onChange={(e) => { handleChange(e, 'location') }}
                    title={'Email Address'}
                    placeholder={'Enter Email Address'}
                    disabled={formData?.location?.email ? true : false}
                />
            </div> */}
        </div>
    )
}

export default LocationStep