import React, { useEffect, useMemo, useState } from 'react'

const LabelAvatar = ({ iconLabel, className, fontSize, index, height, width }) => {
    const staticColors = [
        '#CED1FF', '#5679FE', '#054CD5', '#FFC0CB', '#FF5733', '#FFD700', '#32CD32', '#FF1493', '#800080', '#00FFFF',
        '#00FF00', '#FFFF00', '#FF4500', '#8B4513', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF',
        '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57',
        '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500',
        '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22',
        '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A',
        '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2',
        '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50',
        '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB',
        '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0',
        '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC',
        '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1'
    ];

    const [currentColorIndex, setCurrentColorIndex] = useState(0);

    // Check if the provided index matches the staticColors index, otherwise use the currentColorIndex
    useEffect(() => {
        if (index) {
            setCurrentColorIndex(index + 1)
        } else {
            setCurrentColorIndex((prevIndex) => (prevIndex + 1) % staticColors.length);
        }
    }, [index]);

    const currentColor = useMemo(() => {
        return staticColors[currentColorIndex];
    }, [currentColorIndex]);

    // const currentColor = useMemo(() => {
    //     const randomIndex = Math.floor(Math.random() * staticColors.length);
    //     const color = staticColors[randomIndex];
    //     return color;
    // }, []);

    return (
        <>
            <div className={`${className} flex items-center justify-center h-full w-full`}
                style={{
                    backgroundColor: currentColor,
                }}>
                {iconLabel && <h1 className={`${fontSize ? fontSize : 'text-sm'} text-white font-bold`}>{iconLabel?.split('')[0]}</h1>}
            </div>
        </>
    )
}

export default LabelAvatar