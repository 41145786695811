import React from "react";
import Svgs from "Assets/svgs";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import useHoliday from "./helper";
import AddHoliday from "./addHoliday";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import DetailHoliday from "./DetailHoliday";
import DeleteConfirmation from "new-components/delete-popup";
import NotFound from "Components/Utility/NotFound";
import Pagination from "new-components/Pagination";
import moment from "moment";


const Holiday = ({ CreateHoliday, setCreateHoliday }) => {

  const {
    holidays,
    loading,
    ViewDetails, setViewDetails,
    FilterPopup, setFilterPopup,
    searchHoliday, setSearchHoliday, successCallBack,
    selectedLanguage, translation, deleteLoading, deleteHoliday,
    deletePopup, setDeletePopup, getHolidayDetail, currentPage, setCurrentPage,
    holidayDetails, isEdit, setIsEdit, holidayPagination, selectedLocation
  } = useHoliday({ setCreateHoliday })

  return (
    <>
      <div className="">
        <div className="border rounded-lg bg-white">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
              <div className="flex items-center lg:gap-4 gap-3">
                <div className="flex items-center sm:gap-4 gap-2 ">
                  <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Holiday" : translation[1473]?.value ? translation[1473]?.value : "Holiday"}</h2>
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    {loading
                      ? <div className="h-4 w-12" />
                      : <>
                        <p className="text-primary">
                          {holidayPagination?.allCount ? holidayPagination?.allCount : 0} {' '}
                          {selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"}
                        </p>
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className="mb-0">
                <LoginInput
                  title=""
                  name={"search_text"}
                  value={searchHoliday}
                  onChange={(e) => setSearchHoliday(e.target.value)}
                  placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                />
              </div>
            </div>
            <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
              {/* <LoginBtn
                  borderBtn
                  onClick={() => {
                    setFilterPopup(!FilterPopup);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Filters />
                    <p>{selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}</p>
                  </div>
                </LoginBtn> */}
              {/* <IconButton>
                  <Svgs.Import />
                </IconButton>
                <IconButton filled>
                  <Svgs.Export />
                </IconButton> */}
            </div>
          </div>

          {loading ?
            <TableShimer cols={5} colsHeight={'9'} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] px-12 py-3 gap-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Holiday Name" : translation[1475]?.value ? translation[1475]?.value : "Holiday Name"}</p>
                <p>{selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date"}</p>
                <p>{selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date"}</p>
                <p>{selectedLanguage?.name == "English" ? "Note" : translation[536]?.value ? translation[536]?.value : "Note"}</p>
                <p>{selectedLanguage?.name == "English" ? "Action" : translation[1366]?.value ? translation[1366]?.value : "Action"}</p>
              </div>
              <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                {holidays?.length > 0
                  ? holidays?.map((itm, i) => {
                    return (
                      <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] gap-3 min-w-full w-fit">
                        <div
                          onClick={() => getHolidayDetail(itm, 'detail')}
                          className="cursor-pointer h-full flex items-center gap-3">
                          <p className="text-sm text-primary">{itm?.name}</p>
                        </div>

                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.start_date ? moment(itm?.start_date).format('YYYY-MM-DD') : '------'}
                          </p>
                        </div>

                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.end_date ? moment(itm?.end_date).format('YYYY-MM-DD') : '------'}
                          </p>
                        </div>

                        <div className="flex items-center">
                          <p className="text-sm capitalize line-clamp-2">
                            {itm?.note}
                          </p>
                        </div>

                        <div className="flex items-center gap-3">
                          <div
                            className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center"
                            onClick={() => getHolidayDetail(itm, 'edit')}
                          >
                            <Svgs.Edit fill="#7D7D7D" />
                          </div>
                          <div
                            className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center"
                            onClick={() => getHolidayDetail(itm, 'delete')}
                          >
                            <Svgs.Delete fill="#7D7D7D" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : <NotFound />
                }
                {/* -------------- */}
              </div>
            </div>
          }
        </div>

        {(holidays?.length > 0 && !loading) &&
          <Pagination
            currentPage={currentPage}
            totalCount={holidayPagination?.pages}
            pageSize={10}
            onPageChange={page => setCurrentPage(page)}
          />
        }
      </div>

      {CreateHoliday && (
        <AddHoliday
          CreateHoliday={CreateHoliday}
          setCreateHoliday={setCreateHoliday}
          isEdit={isEdit}
          selectedLocation={selectedLocation}
          setIsEdit={setIsEdit}
          data={holidayDetails}
          successCallBack={successCallBack}
        />
      )
      }

      {ViewDetails &&
        <DetailHoliday
          data={holidayDetails}
          ViewDetails={ViewDetails}
          setViewDetails={setViewDetails}
          selectedLanguage
          translation
        />
      }

      {deletePopup &&
        <DeleteConfirmation
          isLoading={deleteLoading}
          handleClose={() => {
            setDeletePopup(false);
          }}
          handleDelete={() => deleteHoliday(holidayDetails?.id)}
          description={selectedLanguage?.name == "English" ? "Are you sure you want to delete the following holiday?" : translation[1553]?.value ? translation[1553]?.value : "Are you sure you want to delete the following holiday?"}
          heading={selectedLanguage?.name == "English" ? "Delete Holiday?" : translation[1552]?.value ? translation[1552]?.value : "Delete Holiday?"}

        />}
    </>
  );
};

export default Holiday;
