import { getTaxReportApi, getTaxReportChartApi } from "Adapters/Api/salesReport";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const useTaxReports = () => {
    const dispatch = useDispatch();
    const topRef = useRef(null);
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const selected_location = getDataFromLocalStorage("selected_location");

    const state = useSelector((state) => state);
    const location_currency = state?.utility?.currency?.location_currency;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const taxReportData = state?.saleReport?.taxReportData
    const taxReportTableFields = state?.saleReport?.taxReportTableFields

    const [loading, setLoading] = useState(true)
    const [chartLoading, setChartLoading] = useState(true)

    const [SelectedDate, setSelectedDate] = useState(new Date().toLocaleDateString("en-CA"));
    const [ToggleCalendarRange, setToggleCalendarRange] = useState(false)
    const [selectedDateRange, setSelectedDateRange] = useState([
        {
            startDate: getStartOfMonth(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const [taxReportChartData, setTaxReportChartData] = useState([])

    const formattedEndDate = formatDate(selectedDateRange[0]?.endDate);
    const formattedStartDate = formatDate(selectedDateRange[0]?.startDate);
    const EndDate = formatDateForFrontend(formattedEndDate);
    const StartDate = formatDateForFrontend(formattedStartDate);

    function getStartOfMonth() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
    }

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatDateForFrontend(inputDate) {
        const dateParts = inputDate.split('-');
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}-${month}-${year}`;
    }

    const handleDateChange = (ranges) => {
        setSelectedDateRange([ranges.selection]);
    };

    const getTaxReportChartData = () => {
        const payload = {
            location_id: selected_location,
            start_date: moment(selectedDateRange[0]?.startDate).format('YYYY-MM-DD'),
            end_date: moment(selectedDateRange[0]?.endDate).format('YYYY-MM-DD')
        }
        const success = (res) => {
            setChartLoading(false)
            setTaxReportChartData(res)
        }
        const fail = (err) => {
            setChartLoading(false)
        }
        setChartLoading(true)
        dispatch(getTaxReportChartApi(access_token, payload, success, fail))
    }

    const getTaxReportData = () => {
        const payload = {
            location_id: selected_location,
            start_date: moment(selectedDateRange[0]?.startDate).format('YYYY-MM-DD'),
            end_date: moment(selectedDateRange[0]?.endDate).format('YYYY-MM-DD')
        }
        const success = (res) => {
            setLoading(false)
        }
        const fail = (err) => {
            setLoading(false)
        }
        setLoading(true)
        dispatch(getTaxReportApi(access_token, payload, success, fail))
    }

    const onSubmit = () => {
        getTaxReportData()
        getTaxReportChartData()
    }

    useEffect(() => {
        onSubmit()
    }, [selected_location])

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selected_location]);

    return {
        loading, SelectedDate, selectedDateRange, selectedLanguage, translation, ToggleCalendarRange, setToggleCalendarRange, handleDateChange, EndDate, StartDate,
        loading, storeDataInLocalStorage, taxReportData, taxReportTableFields, taxReportChartData, chartLoading, getTaxReportChartData, location_currency, onSubmit, topRef
    }
}

export default useTaxReports
