import { useSelector } from "react-redux"
import Svgs from "../../Assets/svgs"
import { useNavigate } from "react-router-dom"

const ExpenseAnalyticsNotFound = ({ className }) => {
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div className={`flex items-center justify-center my-5 ${className}`}>
                <div className="flex flex-col gap-1 justify-center text-center">
                    <div className="w-fit mx-auto">
                        <Svgs.InventoryInsightNotFound />
                    </div>
                    <h1 className="text-primary font-semibold text-lg normal-case">
                        No Expenses Available
                    </h1>
                    <p className="text-sm text-[#555555]">
                        There are currently no expenses available. Please check back later or add your expenses to keep track of your spending.
                    </p>
                </div>
            </div>
        </>
    )
}

ExpenseAnalyticsNotFound.defaultProps = {
    className: ''
}

export default ExpenseAnalyticsNotFound