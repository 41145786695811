import React from "react";
import LocationSelection from "new-components/location";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import useDonationsReportHelper from "./helper";
import ReactDateRangePicker from "Components/TenantApp/Elements/ReactDateRangePicker";
import DonationCard from "./Elements/DonationCard";
import DonationBarChart from "./Elements/DonationBarChart";

const DonationReports = () => {
    const { translation, selectedLanguage, storeDataInLocalStorage, startDate, setStartDate, endDate, setEndDate, data, loading,
        location_currency, handleResetDate
    } = useDonationsReportHelper();
    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative bg-primary-background">
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                url={'/dashboard/reports'}
                second={"Sales Report"}
            />

            <div className="flex items-center justify-between sm:gap-4 gap-2 my-[1rem]">
                <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                    Donations Report
                </h2>

                <div className="flex gap-2 items-center">
                    <ReactDateRangePicker
                        className="!max-w-none"
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onClear={handleResetDate}
                    />
                    <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
                </div>
            </div>
            <div className="border-t py-5 md:py-10 flex flex-col gap-5 md:gap-10">
                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {loading
                        ? [1, 2, 3]?.map(itm => { return <DonationCard animate /> })
                        : <>
                            <DonationCard
                                value={(data?.total_donations)?.toFixed(2)}
                                currency={location_currency}
                                text={"Total Donations"}
                            />
                            <DonationCard
                                value={(data?.walk_in_donations)?.toFixed(2)}
                                currency={location_currency}
                                text={"Total Donation By Walk-in Client"}
                            />
                            <DonationCard
                                value={data?.client_donations?.toFixed(2)}
                                currency={location_currency}
                                text={"Total Donation By Registered Client"}
                            />
                        </>
                    }
                </div>
                <div className="h-fit xl:h-[30rem] p-3 border border-[#3F3F441A] rounded-lg !pt-5 bg-white">
                    <DonationBarChart
                        data={data?.donation_summary}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
};

export default DonationReports;
