import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom";

const useSalesReportHelper = (clientId) => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const active_tab = searchParams.get("active_tab");

    const { storeDataInLocalStorage } = useSetDataInLocalStorage();

    // redux states
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    // states
    const [backButtonClick, setBackButtonClick] = useState(false)

    let tabList = [
        {
            value: "products",
            label: selectedLanguage?.name == "English" ? "products" : translation[233]?.value ? translation[233]?.value : "products"
        },
        {
            value: "services",
            label: selectedLanguage?.name == "English" ? "services" : translation[180]?.value ? translation[180]?.value : "services"
        },
        {
            value: "vouchers",
            label: selectedLanguage?.name == "English" ? "vouchers" : translation[196]?.value ? translation[196]?.value : "vouchers"
        },
        {
            value: "memberships",
            label: selectedLanguage?.name == "English" ? "memberships" : translation[195]?.value ? translation[195]?.value : "memberships"
        },
        {
            value: "gift_card",
            label: selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"
        },
        {
            value: "appointment",
            label: selectedLanguage?.name == "English" ? "Appointment" : translation[158]?.value ? translation[158]?.value : "Appointment"
        },
    ];

    if (clientId) {
        tabList = [
            {
                value: "all",
                label: "All"
            },
            ...tabList, // Spread existing tabs
        ]
    }

    useEffect(() => {

    }, [clientId])

    useEffect(() => {
        // custom browser back button functionality implemented
        const handlePopstate = () => {
            setBackButtonClick(true)
            navigate('/dashboard/reports');
        };
        window.addEventListener('popstate', handlePopstate);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    useEffect(() => {
        if (!backButtonClick && !(active_tab && !tabList?.includes(active_tab))) {
            setSearchParams({ ...searchParams, active_tab: tabList[0]?.value });
        }
    }, [active_tab, backButtonClick, tabList]);


    return {
        navigate, setSearchParams, searchParams, tabList, active_tab, translation, selectedLanguage, storeDataInLocalStorage
    }
}

export default useSalesReportHelper
