import EmployeeInsightNotFound from "Components/Utility/EmployeeInsightNotFound";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import DashboardChart from "../../DashboardChart";
import { graphScheleton } from "Constants/Data/Calendar";
import NoReportDataFound from "Components/Utility/NoReportDataFound";

const TaxChart = ({ data, loading }) => {
    const [isEmptyData, setIsEmptyData] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0)
    const [showData, setShowData] = useState(false);

    const staticColors = [
        '#CED1FF', '#5679FE', '#054CD5', '#FFC0CB', '#FF5733', '#FFD700', '#32CD32', '#FF1493', '#800080', '#00FFFF',
        '#00FF00', '#FFFF00', '#FF4500', '#8B4513', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF',
        '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57',
        '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500',
        '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22',
        '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A',
        '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2',
        '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50',
        '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB',
        '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0',
        '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC',
        '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1'
    ];

    let colorIndex = 0; // Initialize the color index

    const transformedData = useMemo(() => {
        const labels = [];
        const datasetsMap = new Map(); // Map to store dataset objects by tax name

        data.forEach((dateData) => {
            const date = Object.keys(dateData)[0]; // Get the date
            labels.push(date);

            dateData[date].forEach((tax) => {
                if (!datasetsMap.has(tax.name)) {
                    datasetsMap.set(tax.name, {
                        label: tax.name,
                        backgroundColor: staticColors[colorIndex], // Assign color
                        data: Array(labels.length - 1).fill(0), // Fill previous dates with 0
                        borderRadius: 8,
                        barThickness: 25,
                    });
                    colorIndex = (colorIndex + 1) % staticColors.length;
                }

                // Add value for this specific date
                datasetsMap.get(tax.name).data.push(tax.value);
            });

            // Ensure all other datasets also get a zero for this date
            datasetsMap.forEach((dataset) => {
                if (dataset.data.length < labels.length) {
                    dataset.data.push(0);
                }
            });
        });

        return {
            labels,
            datasets: Array.from(datasetsMap.values()),
        };
    }, [data]);

    useEffect(() => {
        for (const item of transformedData.datasets) {
            // Check if all values in the 'data' array are zero
            if (item.data.some((value) => value !== 0)) {
                setIsEmptyData(false);
                setShowData(true);
                break; // No need to continue checking if we found a non-zero value
            } else {
                setIsEmptyData(true);
                setShowData(false);
            }
        }
        if (data?.length == 0) {
            setIsEmptyData(true)
            setShowData(false)
        }
    }, [data, transformedData]);


    useEffect(() => {
        // Delay showing data for 1000 milliseconds (1 second)
        const delay = setTimeout(() => {
            setShowData(true);
        }, 300);

        return () => clearTimeout(delay); // Clear the timeout if the component unmounts

    }, [showData]);

    useEffect(() => {
        // var totalWidth = transformedData.labels.length * 30; // Calculate total width
        const totalItems = transformedData?.labels?.length; // Assuming labels represent items
        const containerWidth = totalItems * (transformedData?.datasets?.length > 0 ? transformedData?.datasets?.length : 1) / 3.8 * 100; // Adjust width according to employes length. Use "3.8" to set containerWidth according to Employee length
        setContainerWidth(containerWidth)
    }, [transformedData, transformedData?.labels?.length, transformedData?.datasets?.length])
    console.log(transformedData, "transformedData")
    const options = {
        layout: {
            margin: {
                top: 20, // Adjust the bottom padding as needed
            },
        },
        maintainAspectRatio: false, // Set to false to enable full width
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
            },
        },
        plugins: {
            legend: {
                display: true,
                align: "start",
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    padding: 10,
                },
            },
        },
    };

    return (
        <div className="overflow-auto">
            {!loading ?
                !isEmptyData ? (
                    showData ?
                        <>
                            {/* <div style={{ width: "200%", overflowX: "auto" }}>
                        <Bar height={420} data={transformedData} options={options} />
                    </div> */}

                            <div className="min-w-full" style={{ width: `${containerWidth ? containerWidth : 1000}px`, overflowX: 'auto', paddingLeft: '10px' }}>
                                <Bar height={420} data={transformedData} options={options} />
                            </div>
                        </>
                        :
                        <Shimmer>
                            <DashboardChart GraphData={graphScheleton} animate />
                        </Shimmer>
                ) : (
                    <NoReportDataFound />
                )
                :
                <Shimmer>
                    <div className="h-fit xl:h-[30rem] p-3 border border-[#3F3F441A] rounded-lg !pt-5">
                        <DashboardChart GraphData={graphScheleton} animate />
                    </div>
                </Shimmer>

            }
        </div>
    );
};

export default TaxChart;

