import { getSingleClient } from 'Adapters/Api/clients';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useClientSaleHistoryHelper = () => {
    const navigate = useNavigate()
    const { clientId } = useParams();

    // states
    const [loading, setLoading] = useState(true)
    const [clientData, setClientData] = useState()

    useEffect(() => {
        if (clientId) {
            getClientDetails(clientId)
        } else {
            navigate(-1)
        }
    }, [clientId])

    //get a single client 
    const getClientDetails = (id) => {
        setLoading(true)
        getSingleClient(id)
            .then((result) => {
                if (result?.status === 200) {
                    const client = result?.response?.client;
                    setClientData(client)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)

            })
    }

    return {
        clientId, clientData, loading
    }
}

export default useClientSaleHistoryHelper
