import { COMPLAINT } from "Redux/TenantRedux/ActionTypes/Complaint/complaint";

const initialState = {
  complaint: [],
  complaint_pagination: {},
  complaint_category: []
};

const complaintReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case COMPLAINT.GET_COMPLAINT:
      return {
        ...state,
        complaint: payload?.response?.data,
        complaint_pagination: {
          current_page: payload?.current_page || 1,
          total_pages: payload?.total_pages || 1,
          count: payload?.count || 0,
        }
      };

    case COMPLAINT.GET_COMPLAINT_CATEGORY:
      return {
        ...state,
        complaint_category: payload?.response?.data,
      };

    default:
      return state;
  }
};

export default complaintReducer;
