import AdditionalInformation from "Screens/TenantApp/Dashboard/AdditionalInformation";

import DashboardMain from "Components/TenantApp/Dashboard/DashboardMain";
import DashboardSetup from "Components/TenantApp/Dashboard/DashboardSetup";
import DashboardAccount from "Components/TenantApp/Dashboard/DashboardAccount";
import ServiceManagement from "Components/TenantApp/Dashboard/ServiceManagement.jsx";

import ThemeCustomization from "Components/TenantApp/Dashboard/MyAccount/AccountSetup/ThemeCustomization";
import FinancialSettings from "Components/TenantApp/Dashboard/MyAccount/AccountSetup/FinancialSettings";
import DashboardInventory from "Components/TenantApp/Dashboard/MyAccount/Inventory/DashboardInventory";
import BusinessSettings from "Components/TenantApp/Dashboard/MyAccount/AccountSetup/BusinessSettings";
import BookingSettings from "Components/TenantApp/Dashboard/MyAccount/AccountSetup/BookingSettings";
import MultiLanguage from "Components/TenantApp/Dashboard/MyAccount/AccountSetup/MultiLanguage";
import Notification from "Components/TenantApp/Dashboard/MyAccount/AccountSetup/Notification";
import Products from "Components/TenantApp/Dashboard/MyAccount/Inventory/Products";
import Vendors from "Components/TenantApp/Dashboard/MyAccount/Inventory/Vendors";
import Brands from "Components/TenantApp/Dashboard/MyAccount/Inventory/Brands";
import Reports from "Components/TenantApp/Dashboard/MyAccount/Inventory/reports";
import Purchase from "Components/TenantApp/Dashboard/MyAccount/Inventory/Purchase";
import Stocks from "Components/TenantApp/Dashboard/MyAccount/Inventory/stock";
import Category from "Components/TenantApp/Dashboard/MyAccount/Inventory/Category/index";
import OrderStock from "Components/TenantApp/Dashboard/MyAccount/Inventory/ManagePurchases/OrderStock";
import GoodReceipt from "Components/TenantApp/Dashboard/MyAccount/Inventory/GoodReceipt.jsx";
import Consumptions from "Components/TenantApp/Dashboard/MyAccount/Inventory/Consumptions";
import PurchaseOrders from "Components/TenantApp/Dashboard/MyAccount/Inventory/ManagePurchases";
import StocksTransfer from "Components/TenantApp/Dashboard/MyAccount/Inventory/stocks-transfer";
import DashboardEmployee from "Components/TenantApp/Dashboard/Employee";
import AttendanceLeoDay from "Components/TenantApp/Dashboard/Employee/AttendanceLeoDay/index";
import Reports_e from "Components/TenantApp/Dashboard/Employee/Reports/index";
import Payroll from "Components/TenantApp/Dashboard/Employee/Payroll/index";
import AddEmployee from "Components/TenantApp/Dashboard/Employee/AddEmployee";
import HistoryLog from "Components/TenantApp/Dashboard/Employee/history-log/index.jsx";
import StaffGroups from "Components/TenantApp/Dashboard/Employee/StaffGroups/index";
import Employees from "Components/TenantApp/Dashboard/Employee/AddEmployee/Employees";
import Working_Schedule from "Components/TenantApp/Dashboard/Employee/working-schedule/index.jsx";
import Commission_Scheme from "Components/TenantApp/Dashboard/Employee/commission/index.jsx";

import DashboardClients from "Components/TenantApp/Dashboard/Clients/DashboardClients";
import ClientProfiles from "Components/TenantApp/Dashboard/Clients/ClientProfile";
import AddClient from "Components/TenantApp/Dashboard/Clients/AddClient";
import Promotions from "Components/TenantApp/Dashboard/Clients/Promotions.jsx";
import Client_Reports from "Components/TenantApp/Dashboard/Clients/Reports.jsx";
import ClientGroups from "Components/TenantApp/Dashboard/Clients/ClientGroups/index";
import SharingSetings from "Components/TenantApp/Dashboard/Clients/SharingSettings/SharingSetings";
import DiscountsPromotions from "Components/TenantApp/Dashboard/Clients/Discounts";

import AddAppointment from "Components/TenantApp/Dashboard/Calendar/AddAppointment/index";
import AddGroupAppointment from "Components/TenantApp/Dashboard/Calendar/AddGroupAppointment/index";
import CalendarMain from "Components/TenantApp/Dashboard/Calendar/CalendarMain.jsx";
import CheckoutFinish from "Components/TenantApp/Dashboard/Calendar/CheckoutFinish";
import Checkout from "Components/TenantApp/Dashboard/Calendar/Checkout.jsx";
import Discounts from "Components/TenantApp/Dashboard/Calendar/Discounts.jsx";
import Appointment from "Components/TenantApp/Dashboard/Calendar/Appointment";
import AppointmentEdit from "Components/TenantApp/Dashboard/Calendar/AppointmentEdit";

// sale module routes.
import DashboardSales from "Components/TenantApp/Dashboard/Sales";
import QuickSales from "Components/TenantApp/Dashboard/Sales/quick-sales";
import SaleOrders from "Components/TenantApp/Dashboard/Sales/sale-orders";
import SaleHistory from "Components/TenantApp/Dashboard/Sales/sale-history";
import Appointments from "Components/TenantApp/Dashboard/Sales/appointment";
import SalesCheckout from "Components/TenantApp/Dashboard/Sales/Checkout.jsx";

import DashboardReports from "Components/TenantApp/Dashboard/Reports";
import DashboardPOSAnalytics from "Components/TenantApp/Dashboard/POSAnalytics";
import LoyaltyPoints from "Components/TenantApp/Dashboard/Clients/LoyaltyPoints";
import Vacation from "../../Components/TenantApp/Dashboard/Employee/Vacation";
import UpsellDownsell from "../../Components/TenantApp/Dashboard/POSAnalytics/Upsell&DownSell";
import TargetDashboard from "../../Components/TenantApp/Dashboard/MyAccount/Target/TargetDashboard";
import StaffTarget from "Components/TenantApp/Dashboard/MyAccount/Target/StaffTarget";
import StoreTarget from "Components/TenantApp/Dashboard/MyAccount/Target/StoreTarget";
import ServiceTarget from "Components/TenantApp/Dashboard/MyAccount/Target/ServiceTarget";
import RetailTarget from "Components/TenantApp/Dashboard/MyAccount/Target/RetailTarget";
import AdminBooking from "Components/TenantApp/Dashboard/AdminBooking";
import MarketPlace from "Components/TenantApp/Dashboard/MarketPlace";
import Inprogress from "Components/TenantApp/Dashboard/Inprogress";
import CreateSpecificDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateSpecificDiscount/AddDiscount";
import CreateFreeItemDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateFreeItemDiscount/AddDiscount";
import CreateFixedDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateFixedDiscount/AddDiscount";
import CreateItemAmountDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateItemAmountDiscount/AddDiscount";
import AddDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateDirectFlatDiscount/AddDiscount";
import CreateBundleDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateBundleDiscount/AddDiscount";
import CreateRetailServicesDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateRetailServicesDiscount/AddDiscount";
import CreateRestrictedDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateRestrictedDiscount/AddDiscount";
import CreateComplimentaryVouchers from "Components/TenantApp/Dashboard/Clients/Discounts/CreateComplimentaryVouchers/AddDiscount";
import CreatePackagesDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreatePackagesDiscount/AddDiscount";
import CreateCouponsDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateCouponsDiscount/AddDiscount";
import CreatePurchaseDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreatePurchaseDiscount/AddDiscount";
import CreateSpendDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateSpendDiscount/AddDiscount";
import CreateSpecificBrandDiscount from "Components/TenantApp/Dashboard/Clients/Discounts/CreateSpecificBrandDiscount/AddDiscount";
import CommissionReports from "Components/TenantApp/Dashboard/Reports/CommissionReports";
import StaffTargetReports from "Components/TenantApp/Dashboard/Reports/StaffTargetReports";
import StoreTargetReports from "Components/TenantApp/Dashboard/Reports/StoreTargetReports";
import ServiceTargetReports from "Components/TenantApp/Dashboard/Reports/ServiceTargetReports";
import RetailTargetReports from "Components/TenantApp/Dashboard/Reports/RetailTargetReports";
import LoyaltyLogs from "Components/TenantApp/Dashboard/Clients/LoyaltyLogs";
import CashBack from "Components/TenantApp/Dashboard/Clients/CashBack";
import DiscountsPromotionsReports from "Components/TenantApp/Dashboard/Reports/DiscountsPromotionsReports";
// import POSAnalytics from "Components/TenantApp/Dashboard/POSAnalytics";
import SalesAnalytics from "Components/TenantApp/Dashboard/POSAnalytics/SalesAnalytics";
import FinanceAnalytics from "Components/TenantApp/Dashboard/POSAnalytics/FinanceAnalytics";
import CustomerAnalytics from "Components/TenantApp/Dashboard/POSAnalytics/CustomerAnalytics";
import InventoryInsights from "Components/TenantApp/Dashboard/InventoryInsights/InventoryInsights";
import InvoiceTranslation from "Components/TenantApp/Dashboard/MyAccount/AccountSetup/InvoiceTranslation/InvoiceTranslation";
import SaleOvertime from "Components/TenantApp/Dashboard/SaleOvertime/SaleOvertime";
import CRM from "Components/TenantApp/Dashboard/CRM/CustomersSegmentation/index.jsx";
import DashboardCRM from "Components/TenantApp/Dashboard/DashboardCRM";
import DashboardHRM from "Components/TenantApp/Dashboard/DashboardHRM";
import CustomersSegmentation from "Components/TenantApp/Dashboard/CRM/CustomersSegmentation/index.jsx";
import Campaigns from "Components/TenantApp/Dashboard/CRM/Campaigns";
import ConversionTracker from "Components/TenantApp/Dashboard/CRM/ConversionTracker";
import Lead_Generation from "Components/TenantApp/Dashboard/CRM/LeadGeneration";
import Unsubscribe from "Components/TenantApp/Dashboard/CRM/Unsubscribe";
import Deals from "Components/TenantApp/Dashboard/CRM/Deals";
import CreateDeals from "Components/TenantApp/Dashboard/CRM/Deals/CreateDeals";
import AnalyticsCalendar from "Components/TenantApp/Dashboard/Calendar/AnalyticsCalendar";
import LeaveManagement from "Components/TenantApp/Dashboard/HRM/LeaveManagement";
import WeekendManagement from "Components/TenantApp/Dashboard/HRM/WeekendManagement";
import SalesAppointments from "Components/TenantApp/Dashboard/Sales/appointment";
import GroupAppointment from "Components/TenantApp/Dashboard/Calendar/GroupAppointmentDetail/GroupAppointment";
import MarketingAnalytics from "Components/TenantApp/Dashboard/POSAnalytics/MarketingAnalytics";
import GroupCheckoutFinish from "Components/TenantApp/Dashboard/Calendar/GroupAppointmentDetail/GroupCheckoutFinish";
import RefundReports from "Components/TenantApp/Dashboard/MyAccount/Inventory/RefundReports";
import EmployeeComments from "Components/TenantApp/Dashboard/Employee/EmployeeComments";
import EmployeeTrainingRequests from "Components/TenantApp/Dashboard/Employee/TrainingRequests";
import Expenses from "Components/TenantApp/Dashboard/MyAccount/Expenses";
import ExpenseAnalytics from "Components/TenantApp/Dashboard/POSAnalytics/ExpenseAnalytics/ExpenseAnalytics";
import AppointmentReports from "Components/TenantApp/Dashboard/Reports/AppointmentReports";
import PaymentMethodsReport from "Components/TenantApp/Dashboard/POSAnalytics/PaymentMethodsReport";
import SalesReports from "Components/TenantApp/Dashboard/Reports/SalesReports";
import ClientSaleHistory from "Components/TenantApp/Dashboard/Clients/ClientSaleHistory";
import SalesSummaryReports from "Components/TenantApp/Dashboard/Reports/SalesHistoryReport";
import MembershipSold from "Components/TenantApp/Dashboard/Clients/MembershipSold";
import TipReports from "Components/TenantApp/Dashboard/Reports/TipReports";
import Complaints from "Components/TenantApp/Dashboard/Clients/Complaints";
import Maintenance from "Components/TenantApp/Dashboard/MyAccount/Maintenance";
import TaxReports from "Components/TenantApp/Dashboard/Reports/TaxReports";
import DonationReports from "Components/TenantApp/Dashboard/Reports/DonationsReports";



export const tenantRoutes = [
  {
    path: "/dashboard",
    element: <DashboardMain />,
  },
  {
    path: "/dashboard/account",
    element: <DashboardAccount />,
  },
  {
    path: "/dashboard/account/setup",
    element: <DashboardSetup />,
  },
  {
    path: "/dashboard/account/setup/business-settings",
    element: <BusinessSettings />,
  },
  {
    path: "/dashboard/account/setup/business-settings/additional-information/",
    element: <AdditionalInformation />,
  },
  {
    path: "/dashboard/account/setup/financial-settings",
    element: <FinancialSettings />,
  },
  {
    path: "/dashboard/account/setup/booking-settings",
    element: <BookingSettings />,
  },
  {
    path: "/dashboard/account/setup/notification",
    element: <Notification />,
  },
  {
    path: "/dashboard/account/setup/invoice-translation",
    element: <InvoiceTranslation />,
  },
  {
    path: "/dashboard/account/setup/theme-customization",
    element: <ThemeCustomization />,
  },
  {
    path: "/dashboard/account/setup/multi-language",
    element: <MultiLanguage />,
  },
  {
    path: "/dashboard/account/service-management",
    element: <ServiceManagement />,
  },
  {
    path: "/dashboard/account/inventory-operations/",
    element: <DashboardInventory />,
  },
  {
    path: "/dashboard/account/inventory-operations/products",
    element: <Products />,
  },
  {
    path: "/dashboard/account/inventory-operations/vendors",
    element: <Vendors />,
  },
  {
    path: "/dashboard/account/inventory-operations/brands",
    element: <Brands />,
  },
  {
    path: "/dashboard/account/inventory-operations/Category",
    element: <Category />,
  },
  {
    path: "/dashboard/account/target-control/staff-target",
    element: <StaffTarget />,
  },
  {
    path: "/dashboard/account/target-control/store-target",
    element: <StoreTarget />,
  },
  {
    path: "/dashboard/account/target-control/service-target",
    element: <ServiceTarget />,
  },
  {
    path: "/dashboard/account/target-control/retail-target",
    element: <RetailTarget />,
  },
  {
    path: "/dashboard/account/target-control/service-target",
    element: <Category />,
  },
  {
    path: "/dashboard/account/target-control/retail-target",
    element: <Category />,
  },
  {
    path: "/dashboard/account/inventory-operations/stocks",
    element: <Stocks />,
  },
  {
    path: "/dashboard/account/inventory-operations/purchase",
    element: <Purchase />,
  },
  {
    path: "/dashboard/account/inventory-operations/reports",
    element: <Reports />,
  },
  {
    path: "/dashboard/account/maintenance",
    element: <Maintenance />,
  },
  {
    path: "/dashboard/employee",
    element: <DashboardEmployee />,
  },
  {
    path: "/dashboard/employee/employees",
    element: <Employees />,
  },
  {
    path: "/dashboard/employee/employees/add-employee",
    element: <AddEmployee />,
  },
  {
    path: "/dashboard/employee/payroll",
    element: <Payroll />,
  },
  {
    path: "/dashboard/employee/attendance_leoday",
    element: <AttendanceLeoDay />,
  },
  {
    path: "/dashboard/employee/staff-groups",
    element: <StaffGroups />,
  },
  {
    path: "/dashboard/employee/reports",
    element: <Reports_e />,
  },
  {
    path: "/dashboard/employee/commission-scheme",
    element: <Commission_Scheme />,
  },
  {
    path: "/dashboard/employee/employee-comments",
    element: <EmployeeComments />,
  },
  {
    path: "/dashboard/employee/working-schedule",
    element: <Working_Schedule />,
  },
  {
    path: "/dashboard/employee/history-log",
    element: <HistoryLog />,
  }, {
    path: "/dashboard/employee/vacation&absence",
    element: <Vacation />,
  },
  // {
  //   path: "/dashboard/employee/upsell&downsell-report",
  //   element: <UpsellDownsell />,
  // },
  {
    path: "/dashboard/pos-analytics/upsell&downsell-report",
    element: <UpsellDownsell />,
  },
  {
    path: "/dashboard/employee/employee-training-requests",
    element: <EmployeeTrainingRequests />,
  },
  {
    path: "/dashboard/clients",
    element: <DashboardClients />,
  },
  {
    path: "/dashboard/clients/client-profiles",
    element: <ClientProfiles />,
  },
  {
    path: "/dashboard/clients/client-profiles/add-client",
    element: <AddClient />,
  },
  {
    path: "/dashboard/clients/client-group",
    element: <ClientGroups />,
  },
  {
    path: "/dashboard/clients/discounts-promotions",
    element: <DiscountsPromotions />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-flat-discount",
    element: <AddDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-flat-discount/:flat_discount_id",
    element: <AddDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-specific-discount",
    element: <CreateSpecificDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-specific-discount/:servicegroup_discount_id",
    element: <CreateSpecificDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-free-item-discount",
    element: <CreateFreeItemDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-free-item-discount/:id",
    element: <CreateFreeItemDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-item-fixed-discount",
    element: <CreateFixedDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-item-fixed-discount/:id",
    element: <CreateFixedDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-item-amount-discount",
    element: <CreateItemAmountDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-item-amount-discount/:id",
    element: <CreateItemAmountDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-bundle-discount",
    element: <CreateBundleDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-bundle-discount/:id",
    element: <CreateBundleDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-retail-services-discount",
    element: <CreateRetailServicesDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-retail-services-discount/:id",
    element: <CreateRetailServicesDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-restricted-discounts",
    element: <CreateRestrictedDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-restricted-discounts/:id",
    element: <CreateRestrictedDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-complimentary-vouchers",
    element: <CreateComplimentaryVouchers />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-complimentary-vouchers/:id",
    element: <CreateComplimentaryVouchers />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-packages-discount",
    element: <CreatePackagesDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-packages-discount/:id",
    element: <CreatePackagesDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-coupons-discount",
    element: <CreateCouponsDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-coupons-discount/:id",
    element: <CreateCouponsDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-purchase-discount",
    element: <CreatePurchaseDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-purchase-discount/:purchase_discount_id",
    element: <CreatePurchaseDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-spend-discount",
    element: <CreateSpendDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-spend-discount/:id",
    element: <CreateSpendDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/create-specific-brand-discount",
    element: <CreateSpecificBrandDiscount />,
  },
  {
    path: "/dashboard/clients/discounts-promotions/edit-specific-brand-discount/:id",
    element: <CreateSpecificBrandDiscount />,
  },
  {
    path: "/dashboard/clients/rewards-promotions",
    element: <Promotions />,
  },
  {
    path: "/dashboard/clients/reports",
    element: <Client_Reports />,
  },
  {
    path: "/dashboard/clients/loyalty-points",
    element: <LoyaltyPoints />,
  },
  {
    path: "/dashboard/clients/sharing-settings/:activeTab",
    element: <SharingSetings />,
  },
  {
    path: "/dashboard/clients/loyalty-logs",
    element: <LoyaltyLogs />,
  },
  {
    path: "/dashboard/clients/cash-back",
    element: <CashBack />,
  },
  {
    path: "/dashboard/clients/membership",
    element: <MembershipSold />,
  },
  {
    path: "/dashboard/clients/complains",
    element: <Complaints />,
  },
  {
    path: "/dashboard/clients/sale-history/:clientId",
    element: <ClientSaleHistory />,
  },
  {
    path: "/dashboard/calendar/",
    element: <CalendarMain />,
  },
  {
    path: "/dashboard/calendar/add-appointment",
    element: <AddAppointment />,
  },
  {
    path: "/dashboard/calendar/add-appointment/add-group-appointment",
    element: <AddGroupAppointment />,
  },
  {
    path: "/dashboard/calendar/add-appointment/checkout",
    element: <Checkout />,
  },
  {
    path: "/dashboard/calendar/add-appointment/checkout/discounts",
    element: <Discounts />,
  },
  {
    path: "/dashboard/calendar/appointment/:appointment_service_id/checkout/finish",
    element: <CheckoutFinish />,
  },
  {
    path: "/dashboard/calendar/appointment/:appointment_service_id/group-checkout/finish",
    element: <GroupCheckoutFinish />,
  },
  {
    path: "/dashboard/sales",
    element: <DashboardSales />,
  },
  {
    path: "/dashboard/sales/quick-sales",
    element: <QuickSales />,
  },
  {
    path: "/dashboard/sales/quick-sales/checkout",
    element: <SalesCheckout />,
  },
  {
    path: "/dashboard/sales/sale-orders",
    element: <SaleOrders />,
  },
  {
    path: "/dashboard/sales/appointments",
    element: <SalesAppointments />,
  },
  {
    path: "/dashboard/sales/sale-history",
    element: <SaleHistory />,
  },
  {
    path: "/dashboard/calendar/appointment-detail/:appointment_service_id",
    element: <Appointment />,
  },
  {
    path: "/dashboard/calendar/appointment-detail/:appointment_service_id/reschedule",
    element: <AppointmentEdit />,
  },
  {
    path: "/dashboard/calendar/appointment-group-detail/:appointment_service_id",
    element: <GroupAppointment />,
  },
  {
    path: "/dashboard/reports",
    element: <DashboardReports />,
  },
  {
    path: "/dashboard/pos-analytics",
    element: <DashboardPOSAnalytics />,
  },
  {
    path: "/dashboard/pos-analytics/sales-analytics",
    element: <SalesAnalytics />,
  },
  {
    path: "/dashboard/pos-analytics/marketing-analytics",
    element: <MarketingAnalytics />,
  },
  {
    path: "/dashboard/pos-analytics/client-analytics",
    element: <CustomerAnalytics />,
  },
  {
    path: "/dashboard/pos-analytics/finance-analytics",
    element: <FinanceAnalytics />,
  },
  {
    path: "/dashboard/reports/commission-reports",
    element: <CommissionReports />,
  },
  {
    path: "/dashboard/reports/staff-target",
    element: <StaffTargetReports />,
  },
  {
    path: "/dashboard/reports/store-target",
    element: <StoreTargetReports />,
  },
  {
    path: "/dashboard/reports/service-target",
    element: <ServiceTargetReports />,
  },
  {
    path: "/dashboard/reports/retail-target",
    element: <RetailTargetReports />,
  },
  {
    path: "/dashboard/reports/discounts-promotions",
    element: <DiscountsPromotionsReports />,
  },
  {
    path: "/dashboard/reports/appointment-reports",
    element: <AppointmentReports />,
  },
  {
    path: "/dashboard/reports/sales-reports",
    element: <SalesReports />,
  },
  {
    path: "/dashboard/reports/sales-summary-reports",
    element: <SalesSummaryReports />,
  },
  {
    path: "/dashboard/reports/tip-reports",
    element: <TipReports />,
  },
  {
    path: "/dashboard/reports/tax-reports",
    element: <TaxReports />,
  },
  {
    path: "/dashboard/reports/donations-report",
    element: <DonationReports />,
  },
  {
    path: "/dashboard/account/target-control",
    element: <TargetDashboard />,
  },
  {
    path: "/dashboard/account/expenses",
    element: <Expenses />,
  },
  {
    path: "/dashboard/account/inventory-operations/purchases-orders",
    element: <PurchaseOrders />,
  },
  {
    path: "/dashboard/account/inventory-operations/consumptions",
    element: <Consumptions />,
  },
  {
    path: "/dashboard/account/inventory-operations/stocks-transfer",
    element: <StocksTransfer />,
  },
  {
    path: "/dashboard/account/inventory-operations/order-stock",
    element: <OrderStock />,
  },
  {
    path: "/dashboard/account/inventory-operations/order-stock/:order_stock_id",
    element: <OrderStock />,
  },
  {
    path: "/dashboard/account/inventory-operations/good-receipt",
    element: <GoodReceipt />,
  },
  {
    path: "/dashboard/account/inventory-operations/refund-reports",
    element: <RefundReports />,
  },
  {
    path: "/dashboard/marketplace-profile/admin-booking/",
    element: <AdminBooking />,
  },
  {
    path: "/dashboard/marketplace-profile",
    element: <MarketPlace />,
  },
  {
    path: "/dashboard/hrm",
    element: <DashboardHRM />,
  },
  {
    path: "/dashboard/crm",
    element: <DashboardCRM />,
  },
  {
    path: "/dashboard/expenses",
    element: <Inprogress />,
  },
  {
    path: "/dashboard/products",
    element: <Inprogress />,
  },
  {
    path: "/dashboard/automation",
    element: <Inprogress />,
  },
  {
    path: "/dashboard/pos-analytics/inventory-insights",
    element: <InventoryInsights />,
  },
  {
    path: "/dashboard/overtime",
    element: <SaleOvertime />,
  },
  {
    path: "/dashboard/hrm/leave-management",
    element: <LeaveManagement />,
  },
  {
    path: "/dashboard/hrm/weekend-management",
    element: <WeekendManagement />,
  },
  {
    path: "/dashboard/hrm/performance-evaluation",
    element: <CustomersSegmentation />,
  },
  {
    path: "/dashboard/hrm/staff-training",
    element: <CustomersSegmentation />,
  },

  {
    path: "/dashboard/crm/customers-segmentation",
    element: <CustomersSegmentation />,
  },
  {
    path: "/dashboard/crm/campaigns",
    element: <Campaigns />,
  },
  {
    path: "/dashboard/crm/conversion-tracker",
    element: <ConversionTracker />,
  },
  {
    path: "/dashboard/crm/lead-generation",
    element: <Lead_Generation />,
  },
  {
    path: "/dashboard/crm/unsubscribe",
    element: <Unsubscribe />,
  },
  {
    path: "/dashboard/crm/deals",
    element: <Deals />,
  },
  {
    path: "/dashboard/crm/deals/CreateDeals",
    element: <CreateDeals />,
  },
  {
    path: "/dashboard/pos-analytics/analytics",
    element: <AnalyticsCalendar />,
  },
  {
    path: "/dashboard/pos-analytics/expense",
    element: <ExpenseAnalytics />,
  },
  {
    path: "/dashboard/pos-analytics/payment-methods-report",
    element: <PaymentMethodsReport />,
  },
];