import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "Components/TenantApp/Elements/Popup";
import VacationItem from "./VacationItem";
import AddVacation from "./AddVacation";
import Svgs from "Assets/svgs";
import { useVacation } from "./helper";
import { useAbsence } from "./AddAbsence/helper";
import AddAbsence from "./AddAbsence";
import AbsenceItem from "./AbsenceItem";
import Pagination from "new-components/Pagination/index";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoVacationRecordFound from "../Elements/NoVacationRecordFound";
import NoAbsenceRecordFound from "../Elements/NoAbsenceRecordFound";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import LocationSelection from "new-components/location"
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import NotFound from "Components/Utility/NotFound";
// import TableShimer from "Components/Utility/Shimmers/TableShimer";

const Vacation = () => {
  const navigate = useNavigate();
  const Userstate = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [Toggle, setToggle] = useState("Vacation");
  const [exportLoader, setExportLoader] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [deleteAbsencePopup, setDeleteAbsencePopup] = useState(false)
  const [deleteAbsenceID, setDeleteAbsenceID] = useState(null);
  const [deleteVacationPopup, setDeleteVacationPopup] = useState(false)
  const [deleteVacationID, setDeleteVacationID] = useState(null);

  useEffect(() => {
    setEmployee(Userstate?.user?.user?.employee);
    setEmployeePermissions(Userstate?.user?.permissions);
  }, [Userstate?.user, employee, employeePermissions]);

  const {
    // employeeDropdownList,
    openVacation, vacationList, filterStaff, searchText, state, employeeListRedux, setSearchText, setOpenVacation,
    vacationHandleChange, currentPage, setCurrentPage, pages, allVacationList, allCount, loader, vacationDeleteHandler,
    employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, employeePaginationData,
    deleteLoading
  } = useVacation();

  const {
    // employeeDropdownList,
    openAbsence, absenceList, filterAbsenceStaff, searchAbsenceText, stateAbsence, deleteAbsenceLoading,
    setSearchAbsenceText, setOpenAbsence, absenceHandleChange, absencePages, absenceDeleteHandler,
    currentAbsencePage, setCurrentAbsencePage, absenceAllCount, allAbsenceList, absenceLoader
  } = useAbsence();


  const vacation_updated = state.vacation.vacation_updated;
  const absence_updated = stateAbsence.absence.absence_updated;

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])
  // filter handler
  const vacation_flter = () => {
    // const vacation_flter_data = vacationList?.filter((item, index) => {
    //   if (searchText) {
    //     let return_value = false;

    //     //check search text
    //     if (searchText) {
    //       if (
    //         item?.employee?.full_name
    //           ?.toLowerCase()
    //           .includes(searchText.toLowerCase()) ||
    //         item?.full_name?.toLowerCase().includes(searchText.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     // check dropdown text
    //     // if (filterStaff) {
    //     //   if (
    //     //     item?.employee?.full_name
    //     //       ?.toLowerCase()
    //     //       ?.includes(filterStaff?.toString().toLowerCase())
    //     //   ) {
    //     //     return_value = true;
    //     //   } else {
    //     //     return false;
    //     //   }
    //     // }
    //     return return_value;
    //   }
    //   return true;
    // });
    const vacation_flter_data = vacationList
    return vacation_flter_data;
  };

  const absence_flter = () => {
    // const absence_flter_data = absenceList?.filter((item, index) => {
    //   if (searchAbsenceText) {
    //     let return_value = false;

    //     //check search text
    //     if (searchAbsenceText) {
    //       if (
    //         item?.employee?.full_name
    //           ?.toLowerCase()
    //           .includes(searchAbsenceText.toLowerCase()) ||
    //         item?.full_name?.toLowerCase().includes(searchAbsenceText.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     // check dropdown text
    //     // if (filterAbsenceStaff) {
    //     //   if (
    //     //     item?.employee?.full_name
    //     //       ?.toLowerCase()
    //     //       ?.includes(filterAbsenceStaff?.toString().toLowerCase())
    //     //   ) {
    //     //     return_value = true;
    //     //   } else {
    //     //     return false;
    //     //   }
    //     // }
    //     return return_value;
    //   }
    //   return true;
    // });
    const absence_flter_data = absenceList
    return absence_flter_data;
  };

  //----------------------Exoprt code--------------------

  let headers = [
    {
      label: selectedLanguage?.name == "English" ? "ID" : translation[603]?.value ? translation[603]?.value : "ID",
      key: "id"
    },
    {
      label: selectedLanguage?.name == "English" ? "Full Name" : translation[472]?.value ? translation[472]?.value : "Full Name",
      key: "fullName"
    },
    {
      label: selectedLanguage?.name == "English" ? "Designation" : translation[488]?.value ? translation[488]?.value : "Designation",
      key: "designation"
    },
    {
      label: selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date",
      key: "fromDate"
    },
    {
      label: selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date",
      key: "toDate"
    },
    {
      label: selectedLanguage?.name == "English" ? "Note" : translation[609]?.value ? translation[609]?.value : "Note",
      key: "note"
    },
  ];


  const csvReport = {
    filename: "Employees Vacation Report.csv",
    headers: headers,
    data: allVacationList?.map(itm => {
      return {
        ...itm,
        id: itm?.id ? itm?.id : "--------",
        fullName: itm?.employee ? itm.employee.full_name : "--------",
        designation: itm?.employee ? itm.employee.designation : "--------",
        fromDate: itm?.from_date ? itm?.from_date : "--------",
        toDate: itm?.to_date ? itm?.to_date : "--------",
        note: itm?.note ? itm?.note : "--------",
      };
    }),
  };

  const handleVacationExportClick = () => {
    if (!isToastVisible) {
      if (allVacationList?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const csvAbsenceReport = {
    filename: "Employees Absence Report.csv",
    headers: headers,
    data: allAbsenceList?.map(itm => {
      return {
        ...itm,
        id: itm?.id ? itm?.id : "--------",
        fullName: itm?.employee ? itm.employee.full_name : "--------",
        designation: itm?.employee ? itm.employee.designation : "--------",
        fromDate: itm?.from_date ? itm?.from_date : "--------",
        toDate: itm?.to_date ? itm?.to_date : "--------",
        note: itm?.note ? itm?.note : "--------",
      };
    }),
  };

  const handleAbsenceExportClick = () => {
    if (!isToastVisible) {
      if (allAbsenceList?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const PageSize = 10

  // useEffect(() => {
  //   if (vacationList?.length === 0) {
  //     handleGetVacationList();
  //   } else {
  //     const val = vacationList?.map((element) => {
  //       const data = element?.employee?.location?.filter(
  //         ({ id }) => id === selected_location
  //       );

  //       if (data.length > 0 && data !== undefined) return { ...element };
  //     });
  //     const productArr = val.filter((element) => element !== undefined);
  //     setDataArr(productArr);
  //   }
  // }, [selected_location, vacationList]);

  //   (Toggle == "Vacation" ? (!vacation_updated || loader) : (!absence_updated || absenceLoader)) ? (
  //    <PreloaderFull />
  //  ) :
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Vacation & Absence" : translation[414]?.value ? translation[414]?.value : "Vacation & Absence"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <div className="flex items-center gap-4">
              <h2 className="text-primary font-bold lg:text-3xl text-xl">
                {selectedLanguage?.name == "English" ? "Vacation & Absence" : translation[414]?.value ? translation[414]?.value : "Vacation & Absence"}
              </h2>
              <LocationSelection />
            </div>
          </div>
          {Toggle == "Vacation" && (loadingPermission ?
            <Shimmer className={'w-40 lg:w-44'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            : !employee || (employee && employeePermissions?.employee_vacation?.includes("create")) ? (
              <div className="flex items-center sm:gap-4 gap-2">
                <LoginBtn
                  onClick={() => setOpenVacation(!openVacation)}
                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Add Vacation" : translation[628]?.value ? translation[628]?.value : "Add Vacation"}
                    </p>
                  </div>
                </LoginBtn>
              </div>
            ) : ""
          )}

          {Toggle == "Absence" && (loadingPermission ?
            <Shimmer className={'w-40 lg:w-44'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            :
            !employee || (employee && employeePermissions?.employee_absence?.includes("create")) ? (
              <div className="flex items-center sm:gap-4 gap-2">
                <LoginBtn
                  onClick={() => setOpenAbsence(!openAbsence)}
                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Add Absence" : translation[629]?.value ? translation[629]?.value : "Add Absence"}
                    </p>
                  </div>
                </LoginBtn>
              </div>
            ) : ""
          )}
        </div>

        <div className="mb-[1.5rem]">
          <div className="border-b flex items-end gap-6 text-sm font-semibold">
            <p
              onClick={() => {
                setToggle("Vacation");
              }}
              className={`border-b-4 ${Toggle == "Vacation"
                ? "border-primary"
                : "border-transparent"
                } pb-4 w-fit cursor-pointer`}
            >
              {selectedLanguage?.name == "English" ? "Vacation" : translation[441]?.value ? translation[441]?.value : "Vacation"}
            </p>
            <p
              onClick={() => {
                setToggle("Absence");
              }}
              className={`border-b-4 ${Toggle == "Absence"
                ? "border-primary"
                : "border-transparent"
                } pb-4 w-fit cursor-pointer`}
            >
              {selectedLanguage?.name == "English" ? "Absence" : translation[442]?.value ? translation[442]?.value : "Absence"}
            </p>
          </div>
        </div>

        <div className="border rounded-lg bg-white">
          {Toggle == "Vacation" &&
            <div>
              <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">
                      {selectedLanguage?.name == "English" ? "Vacation" : translation[441]?.value ? translation[441]?.value : "Vacation"}
                    </h2>

                    {(!vacation_updated || loader) ?
                      <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                        <p className="rounded-md h-5 w-12 mx-1"> </p>
                      </div>
                      :
                      <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                        <p className="text-primary">
                          {allCount ? allCount : 0}{" "}
                          {allCount && allCount > 1 ?
                            selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                            : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                          }
                        </p>
                      </div>
                    }
                  </div>
                  <div className="mb-00">
                    {loadingPermission ?
                      <Shimmer className={'w-40 lg:w-44'}>
                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                      </Shimmer>
                      :
                      <LoginInput
                        title=""
                        placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        padding="py-1"
                        leftIcon={<Svgs.Search />}
                      />
                    }
                  </div>
                </div>
                {loadingPermission ?
                  <EmployeesHeadShimmer classOn2={'!w-12 lg:!16'} />
                  :
                  <div className="flex items-center lg:justify-center justify-end gap-3 w-full sm:w-fit ">
                    <PaginationDropdown
                      placeholder="All team Member"
                      title=""
                      name="filterStaff"
                      value={filterStaff}
                      onChange={(e) => {
                        vacationHandleChange(e);
                      }}
                      custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                      options={[
                        { label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: "" },
                        ...employeeListRedux?.map((empl, i) => {
                          return {
                            label: empl.full_name,
                            value: empl.id,
                            otherData: {
                              image: empl?.image,
                              DataOne: empl.email,
                              showImage: true,
                              index: i
                            },
                            email: empl.email,
                            mobile_number: empl.mobile_number
                          };
                        }),
                      ]}
                      setPage={setEmployeePage}
                      page={employeePage}
                      setDropdownSearch={setEmployeeSearch}
                      dropdownSearch={employeeSearch}
                      lastPage={employeePaginationData?.employeePages}
                      currentPage={employeePaginationData?.employeeCurrentPage}
                      loading={employeeLoader}
                    />


                    {exportLoader ? (
                      <div className="h-[4rem] flex items-center justify-center">
                        <PreloaderSm />
                      </div>
                    ) :
                      !employee ? (
                        allVacationList?.length > 0 ?
                          <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                            {/* <input
                        type="file"
                        accept=".csv"
                        id="csvFileforproduct"
                        hidden
                      /> */}
                            <CSVLink
                              {...csvReport}
                              target="_blank"
                              onClick={handleVacationExportClick}
                            >
                              <IconButton filled>
                                <Svgs.Export />
                              </IconButton>
                            </CSVLink>
                          </div> :
                          <LoginBtn
                            className="rounded-lg border-2 border-primary"
                            bg="bg-white"
                            animation={false}
                            onClick={handleVacationExportClick}
                          >
                            <div className="flex items-center gap-2 text-[#FFFFFF]">
                              <Svgs.Export bg="#FFFFFF" />
                            </div>
                          </LoginBtn>
                      ) : (
                        ""
                      )}
                  </div>
                }
              </div>
              <div>
                {(!vacation_updated || loader) ?
                  <TableShimmer_v2 cols={3} rowHeight={'h-14'} />
                  :
                  <div className="overflow-x-auto">
                    <div className="inline-grid xl:grid gap-3 grid-cols-[repeat(6,minmax(145px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                      {/* <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p> */}
                      <p>{selectedLanguage?.name == "English" ? "Employee" : translation[4]?.value ? translation[4]?.value : "Employee"}</p>
                      <p>{selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date"}</p>
                      <p>{selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date"}</p>
                      <p>{selectedLanguage?.name == "English" ? "Vacation Type" : translation[1494]?.value ? translation[1494]?.value : "Vacation Type"}</p>
                      <p>{selectedLanguage?.name == "English" ? "Number of Leaves" : translation[1539]?.value ? translation[1539]?.value : "Number of Leaves"}</p>
                      {/* <p>{selectedLanguage?.name == "English" ? "Designations" : translation[630]?.value ? translation[630]?.value : "Designations"}</p> */}
                      <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                      {/* {!employee ||
                    (employee &&
                      employeePermissions?.employee_vacation?.includes("edit")) ? (
                    <p>Actions</p>
                  ) : (
                    ""
                  )} */}
                    </div>

                    {(vacationList?.length > 0 && vacationList && vacation_flter()?.length > 0) ? (
                      vacation_flter().map((item, index) => {
                        return <VacationItem
                          item={item}
                          key={index}
                          i={index}
                          setDeleteVacationID={setDeleteVacationID}
                          setDeleteVacationPopup={setDeleteVacationPopup}
                        />;
                      })
                    ) : (
                      <NoVacationRecordFound className={"my-[3rem]"} />
                    )}
                    {/* Pagination */}
                  </div>
                }
                {(vacation_flter()?.length > 0 && !loader) &&
                  <Pagination
                    currentPage={currentPage}
                    totalCount={Math.ceil(pages)}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
              </div>
            </div>
          }
          {Toggle == "Absence" &&
            <>
              <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">
                      {selectedLanguage?.name == "English" ? "Absence" : translation[442]?.value ? translation[442]?.value : "Absence"}
                    </h2>
                    {(!absence_updated || absenceLoader) ?

                      <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                        <p className="rounded-md h-5 w-12 mx-1"> </p>
                      </div>
                      :
                      <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                        <p className="text-primary">
                          {absenceAllCount ? absenceAllCount : 0}{" "}
                          {absenceAllCount && absenceAllCount > 1 ?
                            selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                            : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                          }
                        </p>
                      </div>
                    }
                  </div>
                  <div className="mb-00">
                    {loadingPermission ?
                      <Shimmer className={'w-40 lg:w-44'}>
                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                      </Shimmer>
                      :
                      <LoginInput
                        title=""
                        placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                        value={searchAbsenceText}
                        onChange={(e) => {
                          setSearchAbsenceText(e.target.value);
                        }}
                        padding="py-1"
                        leftIcon={<Svgs.Search />}
                      />
                    }
                  </div>
                </div>
                {loadingPermission ?
                  <EmployeesHeadShimmer classOn2={'!w-12 lg:!16'} />
                  :
                  <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                    <PaginationDropdown
                      placeholder="All team Member"
                      title=""
                      name="filterAbsenceStaff"
                      value={filterAbsenceStaff}
                      onChange={(e) => {
                        absenceHandleChange(e);
                      }}
                      options={[
                        { label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: "" },
                        ...employeeListRedux?.map((empl, i) => {
                          return {
                            label: empl.full_name,
                            value: empl.id,
                            otherData: {
                              image: empl?.image,
                              DataOne: empl.email,
                              showImage: true,
                              index: i
                            },
                            email: empl.email,
                            mobile_number: empl.mobile_number
                          };
                        }),
                      ]}
                      setPage={setEmployeePage}
                      page={employeePage}
                      setDropdownSearch={setEmployeeSearch}
                      dropdownSearch={employeeSearch}
                      lastPage={employeePaginationData?.employeePages}
                      currentPage={employeePaginationData?.employeeCurrentPage}
                      loading={employeeLoader}
                    />
                    {exportLoader ? (
                      <div className="h-[4rem] flex items-center justify-center">
                        <PreloaderSm />
                      </div>
                    ) :
                      !employee ? (
                        absenceList?.length > 0 ?
                          <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                            {/* <input
                        type="file"
                        accept=".csv"
                        id="csvFileforproduct"
                        hidden
                      /> */}
                            <div className="flex items-center gap-2 text-primary">
                              <CSVLink
                                {...csvAbsenceReport}
                                target="_blank"
                                onClick={handleAbsenceExportClick}
                              >
                                <IconButton filled>
                                  <Svgs.Export />
                                </IconButton>
                              </CSVLink>
                            </div>
                          </div>
                          :
                          <LoginBtn
                            className="rounded-lg border-2 border-primary"
                            bg="bg-white"
                            animation={false}
                            onClick={handleAbsenceExportClick}
                          >
                            <div className="flex items-center gap-2 text-[#FFFFFF]">
                              <Svgs.Export bg="#FFFFFF" />
                            </div>
                          </LoginBtn>
                      ) : (
                        ""
                      )}
                  </div>
                }
              </div>
              {(!absence_updated || absenceLoader) ?
                <TableShimmer_v2 cols={3} rowHeight={'h-14'} />
                :
                <div className="overflow-x-auto">
                <div className="inline-grid xl:grid gap-3 grid-cols-[repeat(3,minmax(145px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                    {/* <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p> */}
                    <p>{selectedLanguage?.name == "English" ? "Employee" : translation[4]?.value ? translation[4]?.value : "Employee"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Designations" : translation[630]?.value ? translation[630]?.value : "Designations"}</p>
                    {/* {!employee ||
                    (employee &&
                      employeePermissions?.employee_absence?.includes("edit")) ? (
                    <p>Actions</p>
                  ) : (
                    ""
                  )} */}
                  </div>
                  {absenceList?.length > 0 && absenceList && absence_flter()?.length > 0 ? (
                    absence_flter()?.map((item, index) => {
                      return <AbsenceItem
                        item={item}
                        key={index}
                        i={index}
                        setDeleteAbsenceID={setDeleteAbsenceID}
                        setDeleteAbsencePopup={setDeleteAbsencePopup}
                      />;
                    })
                  ) : (
                    <NoAbsenceRecordFound className={"my-[3rem]"} />
                  )}
                </div>
              }
              {(absence_flter()?.length > 0 && absenceLoader) &&
                <Pagination
                  currentPage={currentAbsencePage}
                  totalCount={Math.ceil(absencePages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentAbsencePage(page)}
                />
              }
            </>
          }
        </div>
      </div>

      {openVacation && (
        <AddVacation
          handleClose={() => {
            setOpenVacation(false);
          }}
        />
      )
      }

      {
        openAbsence && (
          <AddAbsence
            handleClose={() => {
              setOpenAbsence(false);
            }}
          />
        )
      }

      {deleteAbsencePopup && (
        <Popup
          heading={
            selectedLanguage?.name == "English"
              ? "Are you sure you want to delete this Absence?" :
              translation[633]?.value ? translation[633]?.value
                : "Are you sure you want to delete this Absence?"
          }
          onclose={() => { setDeleteAbsencePopup(false) }}
        >
          <p>
            {
              selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this Absence? You won't be able to see this again." :
                translation[634]?.value ? translation[634]?.value
                  : "Are you sure you want to delete this Absence? You won't be able to see this again."
            }
          </p>
          <div className="flex justify-end mt-4">
            <LoginBtn
              loading={deleteAbsenceLoading}
              text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
              deleteBtn
              onClick={() => absenceDeleteHandler(deleteAbsenceID, () => { setDeleteAbsencePopup(false) })}
            />
          </div>
        </Popup>
      )}

      {deleteVacationPopup && (
        <Popup
          heading={
            selectedLanguage?.name == "English"
              ? "Are you sure you want to delete this Vacation?" :
              translation[631]?.value ? translation[631]?.value
                : "Are you sure you want to delete this Vacation?"
          }
          onclose={() => { setDeleteVacationPopup(false) }}
        >
          <p>
            {
              selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this Vacation? You won't be able to see this again." :
                translation[632]?.value ? translation[632]?.value
                  : "Are you sure you want to delete this Vacation? You won't be able to see this again."
            }
          </p>
          <div className="flex justify-end mt-4">
            <LoginBtn
              loading={deleteLoading}
              text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
              deleteBtn
              onClick={() => vacationDeleteHandler(deleteVacationID, () => { setDeleteVacationPopup(false) })}
            />
          </div>
        </Popup>
      )}
    </>
  );
};

export default Vacation;

export const DeleteVacation = ({ heading, description, btnText, setDeletePopup, deleteId }) => {
  const { vacationDeleteHandler, deleteLoading } = useVacation();
  const handleClose = () => {
    setDeletePopup(false);
  };

  return (
    <Popup
      heading={heading}
      onclose={handleClose}
    >
      <p>
        {description}
      </p>
      <div className="flex justify-end mt-4">
        <LoginBtn
          loading={deleteLoading}
          text={btnText}
          deleteBtn
          onClick={() => vacationDeleteHandler(deleteId, handleClose)}
        />
      </div>
    </Popup>
  );
};

export const DeleteAbsence = ({ heading, description, btnText, setDeletePopup, deleteId }) => {
  const { absenceDeleteHandler, deleteLoading } = useAbsence();
  const handleClose = () => {
    setDeletePopup(false);
  };
  return (
    <Popup
      heading={heading}
      onclose={handleClose}
    >
      <p>{description}</p>
      <div className="flex justify-end mt-4">
        <LoginBtn
          loading={deleteLoading}
          text={btnText}
          deleteBtn
          onClick={() => absenceDeleteHandler(deleteId, handleClose)}
        />
      </div>
    </Popup>
  );
};
