import { useState, useMemo } from "react";
import { useSelector } from "react-redux";

const useAppointmentByTeamMemberHelper = () => {
    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation;
    const selectedLanguage = state?.languages?.selected_language;
    const location_currency = state?.utility?.currency?.location_currency;
    const productSummaryData = state?.saleReport?.productSummaryData;
    const employeesData = productSummaryData?.teamMember;

    // states
    const [searchText, setSearchText] = useState("");

    // Debounced search filtering
    const filteredEmployees = useMemo(() => {
        const allData = employeesData || []
        if (!searchText) return allData;
        return allData?.filter(emp =>
            emp.employee_name?.toLowerCase().includes(searchText?.toLowerCase())
        );
    }, [searchText, employeesData]);

    return {
        translation, selectedLanguage, location_currency, searchText, setSearchText, filteredEmployees
    };
};

export default useAppointmentByTeamMemberHelper;
