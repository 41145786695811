import React from 'react'
import Shimmer from 'Components/Utility/Shimmers/Shimmer'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Svgs from 'Assets/svgs'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import TableShimer from 'Components/Utility/Shimmers/TableShimer'
import AppointmentByTeamMemberTable from '../../../../Elements/Tables/AppointmentByTeamMemberTable'
import useAppointmentByTeamMemberHelper from './helper'

const AppointmentByTeamMember = (props) => {
    const { loading, loadingPermission, employee, csvLoading, handleExportCSVData } = props
    const { translation, selectedLanguage, location_currency, searchText, setSearchText, filteredEmployees } = useAppointmentByTeamMemberHelper()
    return (
        <div className="rounded-[10px] bg-white cardShadow">
            <div className="px-4 lg:px-6 py-[21px] flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                <div className="flex md:flex-row flex-col md:items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                    <div className="flex items-center sm:gap-4 gap-2 ">
                        <h2 className="font-semibold capitalize text-xl">
                            Appointments By Team Members
                        </h2>

                        {loading ?
                            <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                <p className="rounded-md h-3 w-12 mx-1"> </p>
                            </div>
                            :
                            <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                <p className="text-primary">
                                    {filteredEmployees?.length ? filteredEmployees?.length : 0} {" "}
                                    {filteredEmployees?.length && filteredEmployees?.length > 1 ?
                                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                    }
                                </p>
                            </div>
                        }
                    </div>
                    <div className="flex gap-2 items-center flex-wrap justify-end">
                        {loadingPermission ?
                            <Shimmer className={'w-40 lg:w-44'}>
                                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                            </Shimmer>
                            :
                            <LoginInput
                                title=""
                                placeholder={"Search"}
                                leftIcon={<Svgs.Search />}
                                padding="py-1"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        }
                        {!employee ? (
                            <LoginBtn
                                className="rounded-lg !py-[7px]"
                                bg="bg-white"
                                animation={false}
                                disabled={csvLoading}
                                onClick={() => { handleExportCSVData("appointment-by-team-member") }}
                                customThemeBtn={'theme-btn2'}
                            >
                                <div className="flex items-center gap-2 text-[#FFFFFF]">
                                    {csvLoading == 'appointment-by-team-member' ? (
                                        <div className="w-5 h-5 border-2 border-black border-t-transparent rounded-full animate-spin"></div>
                                    ) : (
                                        <Svgs.ExportSvg />
                                    )}
                                </div>
                            </LoginBtn>

                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            {loading ?
                <TableShimer cols={3} rows={3} />
                :
                <AppointmentByTeamMemberTable
                    data={filteredEmployees}
                    location_currency={location_currency}
                />
            }
        </div>
    )
}

export default AppointmentByTeamMember
