import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Svgs from "Assets/svgs";
import LocationSelection from "new-components/location";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Popup from "Components/TenantApp/Elements/Popup";
import useServiceTarget from "./helper";
import Selection from "new-components/dropdown";
import Input from "new-components/input";

import { MONTHS } from "./../../../../../../Constants/Data/Months";
import NotFound from "Components/Utility/NotFound";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import CopyServiceTarget from "./CopyServiceTarget";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import moment from "moment";

const ServiceTarget = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [YearValue, setYearValue] = useState(true);
  const [ServiceGroupValue, setServiceGroupValue] = useState(undefined);
  const [AddressValue, setAddressValue] = useState(undefined);
  const [AddMonthValue, setMonthValue] = useState(undefined);
  const [serviceTargetValue, setServiceTargetValue] = useState(undefined);


  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const navigate = useNavigate();
  const { handleSubmit, register, reset, onSubmitTarget, onDeleteHandler, onEditHandler, onUpdateHandler, setAddService_Target,
    setDelete, setDeleteId, setCopyService_Target, setUpdate, setSearchText, setMonthFilter, service_target_fiter, locationList,
    serviceGroups, searchYear, setSearchYear, serviceGroup, setServiceGroup, control, errors, isValid, AddService_Target,
    is_delete, deleteId, is_Update, searchText, monthFilter, CopyService_Target, serviceTargetData, currency, dataList,
    monthDropdown, yearDropdown, currencyOfSelectedLocation, locationDetail, add_serv_target_loading, setServ_targ_Loading,
    pages, allCount, setCurrentPage, currentPage, loader, allServiceTargetList, handleExportClick, exportLoader,
    serviceGroupSearchText, setServiceGroupSearchText, serviceGroupCurrentPage, setServiceGroupCurrentPage, serviceGroupLoader, serviceGroupPaginationData,
    successCall
  } = useServiceTarget({ setServiceTargetValue });

  const [availed_values, setAvailedValues] = useState({})

  // let Month_value = []
  // let Year_value = []
  // let Service_group_value = []
  // let Address_value = []
  // let this_filter_group = []

  // let final_data = {}
  // let this_year = {}
  // let this_month = {}

  // service_target_fiter()?.forEach((str) => {
  //   Month_value = [
  //     ...Month_value,
  //     str?.month?.toLowerCase()
  //   ]
  // if (!final_data[str?.service_group?.id]) {
  //   final_data[str?.service_group?.id] = {
  //     [str?.year?.slice(0, 4)]: [Month_value, ...Month_value]
  //   }
  // }

  // if (!final_data[str?.service_group?.id]) {
  //   final_data[str?.service_group?.id] = !this_year[str?.year?.slice(0, 4)] ?
  //     this_year[str?.year?.slice(0, 4)] = {
  //       [str?.year?.slice(0, 4)]: [...Month_value]
  //     } : ''
  // }

  // if (!final_data[str?.service_group?.id]) {
  //   final_data[str?.service_group?.id] = !this_year[str?.year?.slice(0, 4)] ?
  //     this_year[str?.year?.slice(0, 4)] = {
  //       [str?.year?.slice(0, 4)]: !this_month[str?.month?.toLowerCase()] ?
  //         this_month[str?.month?.toLowerCase()] = [str?.month?.toLowerCase()] : ''
  //     } : ''
  // }

  // Year_value = [
  //   ...Year_value,
  //   str?.year?.slice(0, 4)
  // ]
  // Service_group_value = [
  //   ...Service_group_value,
  //   str?.service_group?.id
  // ]
  // Address_value = [
  //   ...Address_value,
  //   str?.location?.id
  // ]
  // this_filter_group = [
  //   {
  //     service_groupid: str?.service_group?.id,
  //     service_locationid: str?.location?.id,
  //     service_year: str?.year?.slice(0, 4),
  //     service_month: str?.month?.toLowerCase()
  //   },
  //   ...this_filter_group
  // ]
  // })

  useEffect(() => {
    let values = {}
    dataList.forEach(target => {
      let loc_data = values[target?.location?.id]
      if (!loc_data) {
        loc_data = {}
      }
      values[target?.location?.id] = { ...loc_data }

      let service_group_id = target?.service_group?.id
      let this_service_group = values[target?.location?.id][service_group_id]
      if (!this_service_group) {
        this_service_group = {}
      }
      values[target?.location?.id][service_group_id] = {
        ...this_service_group
      }

      let year = target.year?.slice(0, 4)
      let this_year = values[target?.location?.id][service_group_id][year]
      if (!this_year) {
        this_year = []
      }

      let month = target.month
      values[target?.location?.id][service_group_id][year] = [
        ...this_year,
        month
      ]
    })

    setAvailedValues(values)
  }, [dataList])


  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const PageSize = 10
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          second={selectedLanguage?.name == "English" ? "Target Control" : translation[569]?.value ? translation[569]?.value : "Target Control"}
          third={selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target"}
          url={'/dashboard/account/'}
          secondurl={'/dashboard/account/target-control'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-3 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <div className="flex items-center gap-4">
              <h2 className="text-primary font-bold lg:text-3xl text-xl">
                {selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target"}
              </h2>
              <LocationSelection />
            </div>
          </div>
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-32 !lg:w-44 !sm:w-40'} />
            :
            <div className="flex items-center sm:gap-4 gap-2">
              {!employee ? (
                <LoginBtn
                  onClick={() => {
                    setCopyService_Target(!CopyService_Target);
                  }}

                >
                  <div className="flex items-center gap-2">
                    <Svgs.Copy />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Copy Service Target" : translation[848]?.value ? translation[848]?.value : "Copy Service Target"}
                    </p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )}

              {!employee ||
                (employee &&
                  employeePermissions?.target_control_service?.includes(
                    "create"
                  )) ? (
                <LoginBtn
                  onClick={() => {
                    setAddService_Target(!AddService_Target);
                    setUpdate(false);
                    setAddressValue(state.locations.selected_location)
                    reset({
                      location: state.locations.selected_location
                    })
                  }}

                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Add Service Target" : translation[849]?.value ? translation[849]?.value : "Add Service Target"}
                    </p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )}
            </div>
          }
        </div>

        <div className="border rounded-lg bg-white">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 lg:gap-1 gap-2">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target"}
                </h2>

                {loader ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-[#101928]">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                      {/* {selectedLanguage?.name == "English" ? "Store" : translation[822]?.value ? translation[822]?.value : "Store"} */}
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-44 lg:w-48'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  :
                  <LoginInput
                    name="serviceGroup"
                    value={serviceGroup}
                    title=""
                    onChange={(e) => {
                      setServiceGroup(e.target.value);
                    }}
                    placeholder={selectedLanguage?.name == "English" ? "Search service group" : translation[1277]?.value ? translation[1277]?.value : "Search service group"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-32 !lg:w-44 !sm:w-40'} classOn3={'!w-12 !lg:16'} has3Itm />
              :
              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">

                <Dropwdown
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                  showTitle={false}
                  value={searchYear}
                  onChange={(e) => {
                    setSearchYear(e.target.value);
                  }}
                  options={
                    yearDropdown && [
                      { label: selectedLanguage?.name == "English" ? "All Year" : translation[823]?.value ? translation[823]?.value : "All Year", value: "" },
                      ...yearDropdown,
                    ]
                  }
                />

                <Dropwdown
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                  showTitle={false}
                  value={monthFilter}
                  onChange={(e) => {
                    setMonthFilter(e.target.value);
                  }}
                  options={[
                    { label: selectedLanguage?.name == "English" ? "All Month" : translation[821]?.value ? translation[821]?.value : "All Month", value: "" },
                    ...MONTHS.map((mnth, i) => {
                      return {
                        label: mnth,
                        value: mnth,
                      };
                    }),
                  ]}
                />
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) : !employee ? (
                  allServiceTargetList?.length > 0 ?
                    <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                      <CSVLink
                        {...serviceTargetData}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <LoginBtn
                          className="rounded-lg !py-[7px]"
                          bg="bg-white"
                          animation={false}
                          customThemeBtn={'theme-btn2'}
                        >
                          <div className="flex items-center gap-2 text-[#FFFFFF]">
                            <Svgs.ExportSvg />
                          </div>
                        </LoginBtn>
                      </CSVLink>

                      {/* <LoginBtn borderBtn animation={false}>
                                <div className='flex items-center gap-2 text-[#101928]'>
                                    <Svgs.Import />
                                </div>
                            </LoginBtn> */}
                    </div> :
                    <LoginBtn
                      className="rounded-lg !py-[7px]"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                      customThemeBtn={'theme-btn2'}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.ExportSvg />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}
              </div>
            }
          </div>
          {loader ?
            <TableShimer cols={5} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid gap-3 grid-cols-[repeat(5,minmax(145px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                {/* <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p> */}
                <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
                <p>{selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}</p>
                <p>{selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target"}</p>
                {/* <p>Service group target</p> */}
                <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
                <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
              </div>
              {service_target_fiter()?.length > 0 ? (
                service_target_fiter()?.map((itm) => {
                  const currentMonth = moment().month(); // 0-based index (0 = January, 1 = February, etc.)
                  const currentYear = moment().year(); // Example: 2024

                  const itemMonth = moment().month(itm?.month).month(); // Convert month name to index
                  const itemYear = parseInt(itm?.year?.slice(0, 4), 10); // Convert year to number

                  // Condition: Show buttons only if itemMonth & itemYear are in the current month/year or future
                  const isCurrentOrFuture = itemYear > currentYear || (itemYear === currentYear && itemMonth >= currentMonth);
                  return (
                    <>
                      <div className={`odd-bg text-[#3A3A3A] transition-all px-12 py-3 inline-grid xl:grid gap-3 grid-cols-[repeat(5,minmax(145px,1fr))] min-w-full w-fit`}>
                        {/* <div className="flex items-center cursor-pointer">
                          <p className="text-sm">
                            {itm?.id.toString().split("-")[0]}
                          </p>
                        </div> */}
                        <div className="flex items-center">
                          <p className="text-sm">{itm?.location?.address_name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">{itm?.service_group?.name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.service_target} {currencyOfSelectedLocation}
                          </p>
                        </div>
                        {/* <div className='flex items-center'>
                                                <p className='text-sm'>$10</p>
                                            </div> */}
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.month}{" "}
                            {itm?.year && `, ${itm?.year?.slice(0, 4)}`}
                          </p>
                        </div>
                        {isCurrentOrFuture ?
                          <div className="flex items-center gap-2">
                            {!employee ||
                              (employee &&
                                employeePermissions?.target_control_service?.includes(
                                  "edit"
                                )) ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                onClick={() => {
                                  onEditHandler(itm);
                                  setAddressValue(itm?.location?.id)
                                  setServiceTargetValue(itm?.service_target)
                                  setServiceGroupValue(itm?.service_group?.id)
                                  setYearValue(itm?.year?.slice(0, 4))
                                  setMonthValue(itm?.month)
                                }}
                              >
                                <Svgs.Pen />
                              </div>
                            ) : (
                              ""
                            )}
                            {!employee ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center"
                                onClick={() => {
                                  setDelete(true);
                                  setDeleteId(itm?.id);
                                }}
                              >
                                <Svgs.Delete />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          : "-----"}
                      </div>
                    </>
                  );
                })
              ) : (
                <NoReportDataFound />
              )}

              {(service_target_fiter()?.length > 0 && !loader) &&
                <Pagination
                  currentPage={currentPage}
                  totalCount={Math.ceil(pages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              }
            </div>
          }
        </div>
      </div>

      {AddService_Target && (
        <Popup
          heading={`${is_Update ?
            selectedLanguage?.name == "English" ? "Update Service Target" : translation[850]?.value ? translation[850]?.value : "Update Service Target" :
            selectedLanguage?.name == "English" ? "Add Service Target" : translation[849]?.value ? translation[849]?.value : "Add Service Target"
            }`}
          close={() => {
            setAddService_Target(false);
            reset();
            setServiceTargetValue("")
          }}
          open={AddService_Target}
        >
          <form
            onSubmit={
              handleSubmit(
                is_Update ? onUpdateHandler : onSubmitTarget
              )
            }
          >
            <div className="flex flex-col gap-4">
              <div className="grid md:grid-cols-2 gap-4">
                <Selection
                  isDisabled={true}
                  label={<>
                    {selectedLanguage?.name == "English" ? "Store" : translation[822]?.value ? translation[822]?.value : "Store"}{" "}
                    <span className="text-red-600">*</span>
                  </>}
                  placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[380]?.value ? translation[380]?.value : "Select Stores"}
                  name={"location"}
                  control={control}
                  setVALUEEEE={setAddressValue}
                  VALUEEEE={AddressValue}
                  errorMessage={errors?.location?.message}
                  options={locationList?.map((itm) => {
                    return {
                      label: itm?.address_name,
                      value: itm?.id,
                    };
                  })}
                />
                <div>
                  <PaginationDropdown
                    required={true}
                    name={"service_group"}
                    value={ServiceGroupValue}
                    onChange={(e) => setServiceGroupValue(e.target.value)}
                    control={control}
                    title={selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Service Group" : translation[263]?.value ? translation[263]?.value : "Select Service Group"}
                    // onBlur={() =>
                    //   onBlurHandler({ staffgroup_id: staffgroup_id })
                    // }
                    error={errors?.service_group?.message}
                    options={serviceGroups?.filter((grp) => grp?.is_active === true)?.map((itm) => {
                      return {
                        label: itm?.name,
                        value: itm?.id,
                      };
                    })}
                    setPage={setServiceGroupCurrentPage}
                    setDropdownSearch={setServiceGroupSearchText}
                    page={serviceGroupCurrentPage}
                    dropdownSearch={serviceGroupSearchText}
                    lastPage={serviceGroupPaginationData?.total_pages}
                    currentPage={serviceGroupPaginationData?.currentPage}
                    loading={serviceGroupLoader}
                  />
                  {/* <Selection
                    control={control}
                    setVALUEEEE={}
                    VALUEEEE={}
                    isDisabled={serviceGroups?.filter((grp) => grp?.is_active === true)?.length == 0}
                    options={serviceGroups
                      ?.filter((grp) => grp?.is_active === true)?.map((itm) => {
                        return {
                          label: itm?.name,
                          value: itm?.id,
                        };
                      })}
                  /> */}
                  {serviceGroups
                    ?.filter((grp) => grp?.is_active === true)
                    ?.map((itm) => {
                      return {
                        label: itm?.name,
                        value: itm?.id,
                      };
                    }).length == 0 && (
                      <div
                        className="flex items-center gap-2 cursor-pointer pt-2"
                        onClick={() => {
                          navigate('/dashboard/account/service-management')
                        }}
                      >
                        <p className="text-sm">
                          {selectedLanguage?.name == "English" ? "No service group found. Click " : translation[808]?.value ? translation[808]?.value : "No service group found. Click "}
                          <span className="underline mx-px text-[#5679FF]">
                            {selectedLanguage?.name == "English" ? "here " : translation[502]?.value ? translation[502]?.value : "here "}
                          </span>
                          {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                        </p>
                      </div>
                    )}
                </div>
                <Selection
                  label={<>
                    {selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}{" "}
                    <span className="text-red-600">*</span>
                  </>}
                  placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                  name={"year"}
                  control={control}
                  setVALUEEEE={setYearValue}
                  VALUEEEE={YearValue}
                  errorMessage={errors?.year?.message}
                  options={yearDropdown}
                />
                <Selection
                  label={<>
                    {selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}{" "}
                    <span className="text-red-600">*</span>
                  </>}
                  placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                  name={"month"}
                  control={control}
                  errorMessage={errors?.month?.message}
                  // options={monthDropdown.filter(dt => (availed_values[AddressValue] ? availed_values[AddressValue][ServiceGroupValue] ? availed_values[AddressValue][ServiceGroupValue][YearValue] ? (is_Update && AddMonthValue == dt.value) ? true : !availed_values[AddressValue][ServiceGroupValue][YearValue].includes(dt.value) : true : true : true))}
                  options={monthDropdown.filter(dt => (availed_values[AddressValue] ? availed_values[AddressValue][ServiceGroupValue] ? availed_values[AddressValue][ServiceGroupValue][YearValue] ? (is_Update && AddMonthValue == dt.value) ? true : !availed_values[AddressValue][ServiceGroupValue][YearValue].includes(dt.value) : true : true : true))?.filter(monthOption => {
                    const monthLabel = monthOption.label.toLowerCase();
                    const yearAvailable = availed_values[AddressValue]?.[ServiceGroupValue]?.[YearValue];

                    if (yearAvailable) {
                      if (is_Update && AddMonthValue === monthLabel) {
                        return true; // Allow the selected month if it's an update
                      }
                      return !yearAvailable.includes(monthLabel);
                    }

                    return true; // Allow all months if no year available
                  })}
                  VALUEEEE={AddMonthValue}
                  setVALUEEEE={setMonthValue}
                // options={monthDropdown.filter(dt => (this_filter_group.includes(AddressValue) ? Service_group_value.includes(ServiceGroupValue) ? Year_value.includes(YearValue) ? !Month_value.includes(dt?.label?.toLowerCase()) : true : true : true))}
                />
                <Input
                  required={true}
                  type={"number"}
                  className="mt-2"
                  title={`${selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target"}`}
                  placeholder={selectedLanguage?.name == "English" ? "Enter Service Target" : translation[842]?.value ? translation[842]?.value : "Enter Service Target"}
                  rightIcon={AddressValue ? locationList?.filter((itm) => itm?.id === AddressValue)?.map((itm) => itm?.currency?.code) : ""}
                  name={"service_target"}
                  register={register}
                  value={serviceTargetValue}
                  setValue={setServiceTargetValue}
                  errorMessage={errors?.service_target?.message}
                />
              </div>

              <LoginBtn
                text={`${is_Update ?
                  selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                  : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                  }`}
                loading={add_serv_target_loading}
                disabled={add_serv_target_loading}
              />
              {/* <LoginBtn
                                text={`Save`}
                                className='!px-6' /> */}
            </div>
          </form>
        </Popup>
      )}

      {CopyService_Target && (
        <CopyServiceTarget
          translation={translation}
          selectedLanguage={selectedLanguage}
          onClose={successCall} />
      )}

      {is_delete && (
        <Popup
          heading={
            selectedLanguage?.name == "English" ? "Are you sure you want to delete this Service targets?"
              : translation[851]?.value ? translation[851]?.value : "Are you sure you want to delete this Service targets?"
          }
          onclose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this Service targets? You won't be able to see this again."
                : translation[852]?.value
                  ? translation[852]?.value
                  : "Are you sure you want to delete this Service targets? You won't be able to see this again."
              }

            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  onDeleteHandler();
                  setTimeout(() => {
                    setDelete(false);
                  }, 500);
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ServiceTarget;