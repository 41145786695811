/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CircleIcon from "../../../Elements/CircleIcon";
import DateInput from "../../../Elements/DateInput";
import Dropwdown from "../../../Elements/Dropwdown";
import DurationBtn from "../../../Elements/DurationBtn";
import LoginBtn from "../../../Elements/LoginBtn";
import Vacations from "../Elements/Vacations";
import Popup from "../../../Elements/Popup";

import Location from "new-components/location";
import UploadWorkingSchedule from "./upload-working-schedule";
import DeleteConfirmation from "new-components/delete-popup";
import AddEditEmployeeSlot from "./add-employee-slot";

import useWrokingSchedule from "./helper";

import Svgs from "Assets/svgs";
import Absences from "../Elements/Absences";
import Weekend from "../Elements/Weekend";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import NoVacationRecordFound from "../Elements/NoVacationRecordFound";
import WorkingScheduleShimmer from "Components/Utility/Shimmers/WorkingScheduleShimmer";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";
import Holiday from "../Elements/Holiday";
import AddBreakTime from "Components/TenantApp/Elements/AddBreakTime";
import AddBreakTimeSlot from "./add-breaktime-slot";
// import { CSVLink } from "react-csv";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import NotFound from "../../../../Utility/NotFound";
// import PreloaderFull from "../../../Elements/PreloaderFull";
// import NotAvailable from "../Elements/NotAvailable";
// import Input from "new-components/input";
// import TableShimer from "Components/Utility/Shimmers/TableShimer";
// import Shimmer from "Components/Utility/Shimmers/Shimmer";

const Working_Schedule = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const isPermissionUpdated = state?.user?.isPermissionUpdated;

  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  const { setImportWorkingSchedule, working_schedule_filter, setselectdEmployee, setDateRangePopup,
    applyDateFilter, HandleSearch, handleEditSlot, setIsUpdate, setFrom, setDeleteSlot, setBreakTimeDeleteSlot,
    setEmployeeData, handleDeleteSlot, setEmployeeSlotPopup, handleWeeklyDate, isLeoDay, setIsLeoDay,
    working_schedule_updated, employeeSlotPopup, selectdEmployee, employeeData, isUpdate, DateRange,
    deleteSlot, currentWeek, search_text, selectedFrom, updateSlotData, DateRangePopup, handleBreakTimeDeleteSlot,
    importWorkingSchedule, employeeOfSelectedLocation, loader, successCallBack, breaktimeSlotPopup, setBreaktimeSlotPopup, breakTimeDeleteSlot,
    handleEditBreakSlot, breakTimeEdit, setBreakTimeEdit
  } = useWrokingSchedule();

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Working Schedule" : translation[412]?.value ? translation[412]?.value : "Working Schedule"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between flex-wrap gap-3">
          <div className="flex items-center sm:gap-4 gap-2 w-fit ">

            <div className="flex items-center gap-3">
              <h2 className="text-primary font-extrabold text-2xl whitespace-nowrap">
                {selectedLanguage?.name == "English" ? "Working Hours" : translation[608]?.value ? translation[608]?.value : "Working Hours"}

              </h2>
              <Location />
            </div>
          </div>
          <div className="flex items-center sm:gap-4 gap-2">
            {/* <input
              type="file"
              accept=".csv"
              id="csvFileforproduct"
              onChange={(e) => {
                onImportHandler(e.target.files[0]);
                setImportWorkingSchedule(false);
              }}
              hidden
              value={""}
            />

            
            <CSVLink
              {...exportWorkingSchedule}
              target="_blank"
              onClick={() => {
                setTimeout(() => {
                  toast.success("File downloaded successfully", { toastId: "toast",
onClose: () => setIsToastVisible(false) });
                }, 300);
              }}
            >
            
            </CSVLink> */}
            {/* {loadingPermission ?
              <Shimmer className={'w-40 lg:w-44'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              :
              !employee ||
                (employee &&
                  employeePermissions?.employee_new?.includes(
                    "create"
                  )) ? (
                <LoginBtn
                  onClick={() =>
                    navigate("/dashboard/employee/employees/add-employee")
                  }
                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p>{selectedLanguage?.name == "English" ? "New Employee" : translation[423]?.value ? translation[423]?.value : "New Employee"}</p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )} */}
          </div>
        </div>
        <div className="border rounded-lg bg-white">
          {!working_schedule_updated || loader ?
            <WorkingScheduleShimmer />
            :
            <>
              <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                <div className="flex items-center justify-between flex-1 w-full gap-2 flex-wrap">
                  <div className="flex items-center sm:justify-center justify-center gap-3 sm:w-fit w-full flex-wrap">
                    <div className="flex items-center sm:gap-4 gap-2">
                      <div
                        className="bg-[#F1F6FA] h-[2.3rem] w-[2.3rem] rounded-lg flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          handleWeeklyDate("prev");
                        }}
                      >
                        <Svgs.Chevron_next />
                      </div>
                      <div
                        className="border border-[#A1A1A1] rounded-lg text-primary text-sm flex items-center cursor-pointer"
                      // onClick={() => {
                      //   setDateRangePopup(!DateRange);
                      // }}
                      >
                        <div className="border-r border-[#A1A1A1] py-[0.5rem] px-2">
                          {selectedLanguage?.name == "English" ? "This Week" : translation[616]?.value ? translation[616]?.value : "This Week"}
                        </div>
                        <div className="py-[0.5rem] px-2">
                          {`${currentWeek[0]} - ${currentWeek[currentWeek?.length - 1]
                            }`}
                        </div>
                      </div>
                      <div
                        className="bg-[#F1F6FA] h-[2.3rem] w-[2.3rem] rounded-lg flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          handleWeeklyDate("next");
                        }}
                      >
                        <Svgs.Chevron_prev />
                      </div>
                    </div>
                  </div>
                  <div className="mb-00 sm:w-fit w-full justify-end flex items-center gap-3">
                    <LoginInput
                      name={"search_text"}
                      value={search_text}
                      leftIcon={<Svgs.Search />}
                      onChange={HandleSearch}
                      title=""
                      parentClass='flex-1'
                      placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                    />
                    <Dropwdown
                      custom_min_width="sm:min-w-[15rem] min-w-[17rem]"
                      showTitle={false}
                      placeholder="All team Member"
                      value={selectdEmployee}
                      onChange={(e) => {
                        setselectdEmployee(e.target.value);
                      }}
                      options={
                        employeeOfSelectedLocation()?.length > 0
                          ? [
                            { label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: "" },
                            ...employeeOfSelectedLocation()?.map((itm, i) => {
                              return {
                                label: itm.full_name,
                                value: itm.id,
                                otherData: {
                                  image: itm?.image,
                                  DataOne: itm.email,
                                  showImage: true,
                                  index: i
                                },
                                email: itm.email,
                                mobile_number: itm.mobile_number
                              };
                            }),
                          ]
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="overflow-auto h-[60vh]">
                <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(195px,1fr))] text-sm font-semibold sticky top-0 z-30 bg-white">
                  <div className="schedule-grid-item px-3 sticky left-0 bg-white z-20">
                    <div className="text-center">
                      <p>{currentWeek[0]}</p>
                      <p>to</p>
                      <p>{currentWeek[currentWeek?.length - 1]}</p>
                    </div>
                  </div>
                  {currentWeek.map((day, index) => (
                    <div className="schedule-grid-item bg-white" key={index}>
                      {day}
                    </div>
                  ))}
                </div>
                {working_schedule_filter()?.length > 0 ? (
                  working_schedule_filter()?.map(
                    ({ schedule, image, full_name, id }, index, arr) => {
                      return (
                        <div
                          className="inline-grid xl:grid grid-cols-[repeat(8,minmax(195px,1fr))] text-sm font-semibold"
                          key={id}
                        >
                          <div
                            className="schedule-grid-item px-3 sticky left-0 bg-white z-20"
                            style={{ justifyContent: "start" }}
                          >
                            <div className="flex items-center gap-2">
                              <div className="h-[3.125rem] w-[3.125rem] overflow-hidden rounded-full">
                                {image ?
                                  <img
                                    src={image}
                                    className="h-full w-full object-cover"
                                  />
                                  :
                                  <LabelAvatar iconLabel={full_name} index={index} className={'!rounded-full'} />
                                }
                              </div>
                              <h2 className="flex-1 line-clamp-1">{full_name}</h2>
                            </div>
                          </div>
                          {currentWeek.map((day, i = 1) =>
                            day ===
                              schedule?.find(
                                (obj) =>
                                  obj.date !== null &&
                                  obj.date === day &&
                                  obj.is_vacation === false &&
                                  obj.is_leave === false &&
                                  obj.is_weekend === false &&
                                  obj.is_holiday === false
                              )?.date
                              ? schedule
                                ?.filter(
                                  ({ date, is_vacation, is_leave, is_weekend, is_holiday }) =>
                                    date !== null &&
                                    date === day &&
                                    is_vacation === false &&
                                    is_leave === false &&
                                    is_weekend === false &&
                                    is_holiday === false
                                )
                                ?.map((d) => (
                                  <div className={`schedule-grid-item p-1  ${d?.is_leo_day == true ? "bg-[#FEF2D5]" : ""}`} key={i}>

                                    <div className={`flex flex-col w-full items-center justify-center ${d?.is_leo_day == true ? 'gap-0' : 'gap-2'}`}>
                                      {d?.is_leo_day == true && (<div className="leo_day flex w-full justify-end">
                                        <p className="rounded-md px-3 bg-[#FBC02B] text-white uppercase text-[0.625rem]">Lieu Day</p>
                                      </div>)}
                                      <div className={`flex flex-col gap-1 hover:z-[2]  ${d?.is_leo_day == true ? 'lg:scale-75 scale-y-75' : ''}`}>
                                        <DurationBtn
                                          index={index}
                                          bottom={
                                            arr.length - 1 == index ||
                                            arr.length - 2 == index
                                          }
                                          handleDeleteSlot={() =>
                                            setDeleteSlot((prev) => ({
                                              ...prev,
                                              show: true,
                                              isSubmit: false,
                                              id: d.id,
                                              is_leo_day: d?.is_leo_day,
                                            }))
                                          }
                                          handleEditSlot={() => {
                                            let otherData = {}
                                            if (d?.is_leo_day) {
                                              setIsLeoDay(true)
                                            } else {
                                              otherData = { is_working_schedule: true }
                                              setIsLeoDay(false)
                                            }
                                            handleEditSlot(d.id);
                                            setEmployeeData({
                                              ...otherData,
                                              full_name,
                                              id,
                                              day,
                                              week: currentWeek[i],
                                            });
                                          }}
                                          end={d.end_time_shift || d.end_time}
                                          start={d.start_time}
                                          key={id}
                                          week={currentWeek[i]}
                                          employee={employee}
                                          employeePermissions={employeePermissions}
                                        />
                                        <AddBreakTime
                                          plusBtnClick={() => {
                                            setBreakTimeEdit({})
                                            setIsUpdate(false)
                                            setEmployeeData({
                                              full_name,
                                              id,
                                              day,
                                              schedule,
                                              week: currentWeek[i],
                                            })
                                          }
                                          }
                                          setBreaktimeSlotPopup={setBreaktimeSlotPopup}
                                          handleDeleteSlot={() => {
                                            setBreakTimeDeleteSlot((prev) => ({
                                              ...prev,
                                              show: true,
                                              isSubmit: false,
                                              id: d?.break_time?.length > 0 && d?.break_time[0]?.id,
                                            }))
                                          }}
                                          handleEditSlot={() => {
                                            let otherData = {}
                                            if (d?.is_leo_day) {
                                              setIsLeoDay(true)
                                            } else {
                                              otherData = { is_working_schedule: true }
                                              setIsLeoDay(false)
                                            }
                                            //  d?.break_time[0]?.end_time_shift || d?.break_time[0]?.end_time
                                            handleEditBreakSlot(d?.break_time[0].id);
                                            setEmployeeData({
                                              full_name,
                                              id,
                                              day,
                                              schedule,
                                              week: currentWeek[i],
                                            })
                                          }}
                                          end={d?.break_time[0]?.end_time_shift || d?.break_time[0]?.end_time}
                                          start={d?.break_time[0]?.start_time}
                                          key={id}
                                          week={currentWeek[i]}
                                          employee={employee}
                                          employeePermissions={employeePermissions}

                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : day === schedule?.find(({ from_date, is_vacation, is_leave, is_weekend, is_holiday }) => from_date === day && (is_vacation === true || is_leave === true || is_weekend === true || is_holiday === true))?.from_date
                                ? schedule?.filter(({ from_date }) => from_date === day)
                                  ?.map((d) => (
                                    <div div key={i} >
                                      {
                                        d?.is_leave === true
                                          ? <Absences />
                                          : d?.is_vacation === true
                                            ? <Vacations onClick={() => {
                                              setIsLeoDay(true)
                                              setEmployeeSlotPopup(true);
                                              setEmployeeData({
                                                id_to_maintain: d?.id,
                                                is_created: "true",
                                                type: 'vacation',
                                                type_of_vacation: d?.vacation?.vacation_type,
                                                full_name,
                                                id,
                                                day,
                                                week: currentWeek[i],
                                              });
                                            }} />
                                            : d?.is_weekend == true
                                              ? <>
                                                <Weekend onClick={() => {
                                                  setIsLeoDay(true)
                                                  setEmployeeSlotPopup(true);
                                                  setEmployeeData({
                                                    id_to_maintain: d?.id,
                                                    type: 'weekend',
                                                    full_name,
                                                    id,
                                                    day,
                                                    week: currentWeek[i],
                                                  });
                                                }} />
                                              </>
                                              : d?.is_holiday === true
                                                ? <Holiday />
                                                : null
                                      }
                                    </div>
                                  ))
                                : day === currentWeek[i] && (
                                  <div
                                    className="schedule-grid-item flex justify-center items-center hover-parent"
                                    key={i}
                                  >
                                    {!employee ||
                                      (employee &&
                                        employeePermissions?.employee_work_schedule?.includes(
                                          "create"
                                        )) ? (
                                      <div
                                        onClick={() => {
                                          setIsLeoDay(false)
                                          setEmployeeSlotPopup(true);
                                          setEmployeeData({
                                            full_name,
                                            id,
                                            day,
                                            week: currentWeek[i],
                                            is_working_schedule: true
                                          });
                                        }}
                                        className="h-[2rem] w-[2rem] border-2 border-[#5679FF] rounded-full items-center justify-center cursor-pointer hover-child hidden"
                                      >
                                        <Svgs.Plus color="#5679FF" />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )
                          )}
                        </div>
                      );
                    }
                  )
                ) : (
                  <NoVacationRecordFound className={"my-[3rem]"} />
                )}
              </div>
            </>
          }
        </div>
        {/* day === schedule?.find(({ from_date, is_vacation }) => from_date === day && is_vacation === true)?.from_date
                            ? schedule?.filter(({ from_date, is_vacation }) => from_date === day && is_vacation === true)
                              ?.map((d) => (
                                <div key={i}>
                                  <Vacations />
                                </div>
                              )) */}
        <div className="mt-4">
          <div className="flex items-center gap-3 bg-[#E4EAF0] p-3 rounded-md">
            <div>
              <Svgs.Bulb />
            </div>
            <div className="flex flex-col gap-1">
              <h1 className="font-semibold">
                {selectedLanguage?.name == "English" ? "Note" : translation[609]?.value ? translation[609]?.value : "Note"}:
              </h1>
              <p className="text-[#696969] text-sm">
                {selectedLanguage?.name == "English"
                  ? "The team roster shows your availability for bookings and is linked to your business opening hours."
                  : translation[610]?.value
                    ? translation[610]?.value
                    : "The team roster shows your availability for bookings and is linked to your business opening hours."}
                {/* To set your opening
                hours,
                <Link to="/dashboard/employee/employees" className="underline">
                  click here
                </Link>
                . */}
              </p>
            </div>
          </div>
        </div>
      </div>
      {DateRangePopup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Date Range" : translation[617]?.value ? translation[617]?.value : "Date Range"}
          open={DateRangePopup}
          close={setDateRangePopup}
        >
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.Date_Range />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Date Range" : translation[617]?.value ? translation[617]?.value : "Date Range"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Select a date range to see working schedule of your" : translation[618]?.value ? translation[618]?.value : "Select a date range to see working schedule of your"}
                  {" "}
                  <br className="hidden md:block" />
                  {selectedLanguage?.name == "English" ? "employees" : translation[399]?.value ? translation[399]?.value : "employees"} .
                </p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <div className="flex-1">
                <DateInput
                  title={selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}
                  name="from"
                  value={selectedFrom}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </div>
              {/* <div className="flex-1">
                <DateInput
                  title="To"
                  name="to"
                  // value={selectedTo}
                  // onChange={(e) => setTo(e.target.value)}
                />
              </div>
             */}
            </div>
            <div className="flex items-center justify-end">
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Apply Filter" : translation[595]?.value ? translation[595]?.value : "Apply Filter"}
                onClick={applyDateFilter}
              />
            </div>
          </div>
        </Popup>
      )
      }
      {
        importWorkingSchedule && (
          <UploadWorkingSchedule
            handleClose={() => setImportWorkingSchedule(false)}
          />
        )
      }
      {employeeSlotPopup && (
        <AddEditEmployeeSlot
          setEmployeeSlotPopup={setEmployeeSlotPopup}
          employeeData={employeeData}
          updateSlotData={updateSlotData}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          isLeoDay={isLeoDay}
          successCallBack={successCallBack}
        />
      )}
      {breaktimeSlotPopup && (
        <AddBreakTimeSlot
          breaktimeSlotPopup={breaktimeSlotPopup}
          setBreaktimeSlotPopup={setBreaktimeSlotPopup}
          setEmployeeSlotPopup={setEmployeeSlotPopup}
          employeeData={employeeData}
          updateSlotData={breakTimeEdit}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          isLeoDay={isLeoDay}
          successCallBack={successCallBack}
        />
      )}

      {deleteSlot.show &&
        <DeleteConfirmation
          heading={selectedLanguage?.name == "English" ? "Delete break time" : translation[1696]?.value ? translation[1696]?.value : "Delete break time"}
          description={selectedLanguage?.name == "English"
            ? "Are you sure you want to delete the working schedule of this employee? This day will consider as off-day for this employee."
            : translation[627]?.value ? translation[627]?.value
              : "Are you sure you want to delete the working schedule of this employee? This day will consider as off-day for this employee."}
          handleClose={() =>
            setDeleteSlot((prev) => ({
              ...prev,
              show: false,
            }))
          }
          handleDelete={handleDeleteSlot}
          isLoading={deleteSlot.isSubmit}
        />}

      {breakTimeDeleteSlot?.show &&
        <DeleteConfirmation
          heading={selectedLanguage?.name == "English" ? "Delete break time" : translation[1696]?.value ? translation[1696]?.value : "Delete Working Schedule"}
          description={selectedLanguage?.name == "English"
            ? "Are you sure you want to delete the break time of this employee?"
            : translation[1697]?.value ? translation[1697]?.value
              : "Are you sure you want to delete the break time of this employee?"}
          handleClose={() =>
            setBreakTimeDeleteSlot((prev) => ({
              ...prev,
              show: false,
            }))
          }
          handleDelete={handleBreakTimeDeleteSlot}
          isLoading={breakTimeDeleteSlot?.isSubmit}
        />}
    </>
  );
};

export default Working_Schedule;

export const week = [
  {
    dayName: "Monday",
  },
  {
    dayName: "Tuesday",
  },
  {
    dayName: "Wednesday",
  },
  {
    dayName: "Thursday",
  },
  {
    dayName: "Friday",
  },
  {
    dayName: "Saturday",
  },
  {
    dayName: "Sunday",
  },
];