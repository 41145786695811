import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const HeroSliders = () => {
    const dashboards = [
        {
            id: 1,
            image: "/images/slider_img.png",
            alt: "Dashboard analytics view"
        },
        {
            id: 2,
            image: "/images/slider_img.png",
            alt: "Dashboard messaging view"
        },
        {
            id: 3,
            image: "/images/slider_img.png",
            alt: "Dashboard reports view"
        }
    ];

    const swiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div
            className="flex flex-col relative items-center justify-center py-[5rem]"
            style={{
                backgroundImage: "url(../images/noise-bg.png), var(--gradient-dark)",
            }}
        >

            {/* Heading */}
            <div className="text-center mb-7 relative z-10">
                <h1 className="text-white w-[95%] text-[3.125rem] font-bold max-w-[57rem] mx-auto leading-tight">
                    Effortless Business Management at Your Fingertips
                </h1>
            </div>

            {/* Dashboard Carousel */}
            <div className="w-[95%] mx-auto max-w-[110rem]">
                <Swiper
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={true}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                        el: '.swiper-pagination',
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1.7, // On screens ≥ 640px, show 1.7 slides
                        },
                    }}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                    modules={[EffectCoverflow, Autoplay, Pagination]}
                    className="w-full py-2"
                >
                    {dashboards.map((dashboard, index) => {
                        return (
                            <SwiperSlide key={dashboard.id} className="w-10/12 md:w-7/12 flex items-center justify-center">
                                <div className={`${activeIndex === index ? 'opacity-100' : 'opacity-40'} relative w-full rounded-3xl overflow-hidden shadow-2xl transition-transform duration-300 hover:scale-[1.02]`}>
                                    <img
                                        src={dashboard.image}
                                        alt={dashboard.alt}
                                        className="w-full h-auto object-cover"
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

                {/* Custom pagination */}
                <div className="flex justify-center gap-2 mt-8">
                    {dashboards.map((_, index) => (
                        <button
                            key={index}
                            className={`w-8 h-1.5 rounded-full transition-all duration-300 ${activeIndex === index ? "bg-primary-blue" : "bg-white/40"
                                }`}
                            onClick={() => {
                                swiperRef.current?.slideToLoop(index);
                                setActiveIndex(index);
                            }}
                        />
                    ))}
                </div>
            </div>
            <div
                className="absolute  top-0 bottom-0 right-0 w-1/2 max-w-96 transition-opacity z-40 duration-300 ease-in-out"
                style={{
                    background: 'linear-gradient(to right, rgba(255,255,255,0.01), rgba(0,0,0,0.5))'
                }}
            ></div>

            <div
                className="absolute top-0 bottom-0 left-0 w-1/2 max-w-96 transition-opacity z-40 duration-300 ease-in-out"
                style={{
                    background: 'linear-gradient(to right, rgba(0,0,0,0.5),rgba(255,255,255,0.01))'
                }}
            ></div>
        </div>
    );
};

export default HeroSliders;