import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NotFound from "Components/Utility/NotFound";
import React, { useState, useEffect } from "react";
import { MONTHS } from "Constants/Data/Months";
import { useSelector } from "react-redux";
import LocationSelection from "new-components/location";

import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import Svgs from "../../../../../Assets/svgs";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import { useReports } from "./helper";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Pagination from "new-components/Pagination";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";

const ServiceTargetReports = () => {
  const { reportStatus, filterMonth, filterYear, filterServiceGroup, yearDropdown, monthDropdown, csvReport, loading,
    serviceGroupDropdown, getTotalService, reportsFilter, handleChange, translation, selectedLanguage,
    currentPage, setCurrentPage, PageSize, allCount, pages, allReports, handleExportClick, exportLoader, currencyOfSelectedLocation,
    serviceGroupSearchText, setServiceGroupSearchText, serviceGroupCurrentPage, setServiceGroupCurrentPage, serviceGroupLoader, serviceGroupPaginationData,
  } = useReports();

  const state = useSelector((state) => state);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
          url={'/dashboard/reports/'}
          second={selectedLanguage?.name == "English" ? 'Service Target Reports' : translation[390]?.value ? translation[390]?.value : 'Service Target Reports'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
            </h2>
            <LocationSelection />
          </div>
        </div>

        <div className="border rounded-lg bg-white">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 gap-4">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl whitespace-nowrap">
                  {selectedLanguage?.name == "English" ? 'Service Target Reports' : translation[390]?.value ? translation[390]?.value : 'Service Target Reports'}
                </h2>

                {(!reportStatus || loading) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }

              </div>
              {loadingPermission ?
                <Shimmer className={'w-44 lg:w-48'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer> :
                <div className="mb-00">
                  <PaginationDropdown
                    name="filterServiceGroup"
                    title=""
                    showTitle={false}
                    placeholder={selectedLanguage?.name == "English" ? "Select Service Groups" : translation[391]?.value ? translation[391]?.value : "Select Service Groups"}
                    options={serviceGroupDropdown}
                    value={filterServiceGroup}
                    onChange={handleChange}
                    // onBlur={() =>
                    //   onBlurHandler({ staffgroup_id: staffgroup_id })
                    // }
                    setPage={setServiceGroupCurrentPage}
                    setDropdownSearch={setServiceGroupSearchText}
                    page={serviceGroupCurrentPage}
                    dropdownSearch={serviceGroupSearchText}
                    lastPage={serviceGroupPaginationData?.total_pages}
                    currentPage={serviceGroupPaginationData?.currentPage}
                    loading={serviceGroupLoader}
                  />
                  {/* <Dropwdown/> */}
                </div>
              }
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} has3Itm />
              :
              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                <Dropwdown
                  name="filterYear"
                  title=""
                  showTitle={false}
                  placeholder="2023"
                  options={yearDropdown}
                  value={filterYear}
                  onChange={handleChange}
                />
                <Dropwdown
                  name="filterMonth"
                  title=""
                  showTitle={false}
                  placeholder={selectedLanguage?.name == "English" ? "January" : translation[370]?.value ? translation[370]?.value : "January"}
                  options={monthDropdown}
                  value={filterMonth}
                  onChange={handleChange}
                />
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) :
                  !employee ? (
                    allReports?.length > 0 ?
                      <CSVLink
                        {...csvReport}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <LoginBtn
                          className="rounded-lg !py-[7px]"
                          bg="bg-white"
                          animation={false}
                          customThemeBtn={'theme-btn2'}
                        >
                          <div className="flex items-center gap-2 text-[#FFFFFF]">
                            <Svgs.ExportSvg />
                          </div>
                        </LoginBtn>
                      </CSVLink> :
                      <LoginBtn
                        className="rounded-lg !py-[7px]"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                        customThemeBtn={'theme-btn2'}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.ExportSvg />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}
              </div>
            }
          </div>
          {(!reportStatus || loading) ?
            <TableShimer cols={5} colsHeight={7} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(200px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Service Target" : translation[371]?.value ? translation[371]?.value : "Total Service Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Service Sales" : translation[372]?.value ? translation[372]?.value : "Total Service Sales"}</p>
                <p>{selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}</p>
                <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
              </div>
              <div className={`odd-bg text-[#3A3A3A] transition-all`}>
                {reportsFilter()?.length > 0 ? (
                  reportsFilter()?.map(
                    ({ name, service, service_target, total_service_sales }, index) => (
                      <>
                        <div
                          key={index}
                          className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(200px,1fr))] min-w-full w-fit"
                        >
                          <div className="flex items-center">
                            <p className="text-sm">{name}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{service_target || 0} {currencyOfSelectedLocation}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {parseFloat(total_service_sales?.toFixed(2))} {currencyOfSelectedLocation}
                              {/* {getTotalService(service) || "0"} {' '}  */}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{filterYear}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{MONTHS[filterMonth]}</p>
                          </div>
                        </div>
                      </>
                    )
                  )
                ) : (
                  <>
                    <NoReportDataFound />
                  </>
                )}

                {(reportsFilter()?.length > 0 && !loading) &&
                  <Pagination
                    currentPage={currentPage}
                    totalCount={Math.ceil(pages)}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ServiceTargetReports;
