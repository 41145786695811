import Svgs from 'Assets/svgs';
import { LogoSvg } from 'Assets/svgs/Logo/LogoSvg';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const menuItems = [
    { title: "Why We're Different", link: '/feature', items: [], active: 'feature' },
    {
        title: "Features",
        active: '',
        items: [
            "Online Booking", "Retail & Inventory", "Packages", "Mobile Apps",
            "Marketing & Automation", "Payments & Point of Sale", "Express Booking",
            "Staff management", "Integrated forms", "Reporting", "Payroll Processing",
            "Client Management", "Two way Texting", "Virtual Waiting Room", "Gift Cards",
            "Multi Location", "Payroll Processing"
        ]
    },
    { title: "Pricing", link: '/pricing', items: [], active: 'pricing' },
    { title: "Blogs", link: '/blogs', items: [], active: 'blog' }
];

const Navbar = ({ active }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [expandedSection, setExpandedSection] = useState(null);
    const [isFeaturesPopupOpen, setIsFeaturesPopupOpen] = useState(false);
    const navigate = useNavigate()

    const toggleSection = useCallback((itm) => {
        itm?.link
            ? navigate(itm?.link)
            : setExpandedSection(prev => (prev === itm?.title ? null : itm?.title));
    }, []);


    const onMenuClick = (itm, index) => {
        itm?.title === "Features"
            ? setIsFeaturesPopupOpen(!isFeaturesPopupOpen)
            : itm?.link && navigate(itm?.link)
    }

    useEffect(() => {
        const handleResize = () => {
            setIsFeaturesPopupOpen(window.innerWidth > 640 ? isFeaturesPopupOpen : false);
            setIsMenuOpen(false);
        };

        const debounceResize = () => requestAnimationFrame(handleResize);
        window.addEventListener("resize", debounceResize);
        return () => window.removeEventListener("resize", debounceResize);
    }, [isFeaturesPopupOpen]);

    return (
        <div className="flex w-full bg-secondary-white relative z-20">
            <div className="flex py-5 w-[95%] max-w-[1280px] mx-auto">
                <div className="flex items-center w-full justify-between">
                    <div className="flex items-center gap-8">
                        <div className='cursor-pointer' onClick={() => navigate('/home')}>
                            <LogoSvg height="3.75rem" width="3.75rem" />
                        </div>
                        <div className="hidden sm:flex gap-6 md:gap-12 items-center">
                            {menuItems.map((itm, index) => {
                                const isActive = active == itm?.active || (isFeaturesPopupOpen && itm?.title === "Features")
                                return (
                                    <div
                                        key={index}
                                        className={`relative flex gap-1 items-center cursor-pointer ${isActive ? 'text-primary-blue' : 'text-black'}`}
                                        onClick={() => onMenuClick(itm, index)}
                                    >
                                        <p className="text-[1rem] font-manrope">{itm?.title}</p>
                                        <Svgs.Chevron_down stroke={isActive ? "var(--primary-blue)" : "#6A6B74"} size="1rem" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="flex items-center gap-4 md:gap-8">
                        <div
                            className="hidden sm:flex cursor-pointer font-manrope text-primary-black font-semibold"
                            onClick={() => navigate('/auth/signup/?account_type=business')}
                        >
                            Signup
                        </div>
                        <button className="rounded-full flex items-center gap-2 bg-primary-black text-white px-9 py-3.5"
                            onClick={() => navigate('/auth/login/?account_type=business')}
                        >
                            <Svgs.LoginIcon />
                            Login
                        </button>
                        <button className="flex items-center rounded-full sm:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <Svgs.HamburgerIcon />
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <>
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setIsMenuOpen(false)} />
                    <div className="fixed inset-y-0 right-0 w-full bg-white transform transition-transform duration-300 ease-in-out z-50">
                        <div className="flex flex-col h-full">
                            <div className="flex items-center justify-between p-4 border-b">
                                <div className='cursor-pointer' onClick={() => navigate('/home')}>
                                    <LogoSvg height="3.75rem" width="3.75rem" />
                                </div>
                                <button className="p-2" onClick={() => setIsMenuOpen(false)}>
                                    <Svgs.Close fill="#000" />
                                </button>
                            </div>

                            <div className="flex-1 overflow-y-auto px-4">
                                {menuItems.map((itm, index) => (
                                    <div key={index} className="py-2">
                                        <button
                                            className={`flex items-center px-5 py-3 justify-between rounded-full text-black w-full ${expandedSection === itm?.title ? "bg-secondary-white text-primary-blue" : ""
                                                }`}
                                            onClick={() => toggleSection(itm)}
                                        >
                                            <span className="text-[20px] font-medium">{itm?.title}</span>
                                            {(itm?.items?.length > 0 || itm?.title === "Resources") && (
                                                <Svgs.Chevron_down
                                                    stroke={expandedSection === itm?.title ? 'var(--primary-blue)' : '#6A6B74'}
                                                    size="1rem"
                                                    className={`w-5 h-5 transition-transform duration-200 ${expandedSection === itm?.title ? "rotate-180" : ""}`}
                                                />
                                            )}
                                        </button>
                                        {expandedSection === itm?.title && itm?.items.length > 0 && (
                                            <div className="flex flex-col pl-4 py-2 gap-y-3.5">
                                                {itm?.items.map((item, i) => (
                                                    <div key={i} className="py-1">
                                                        <span className="text-black text-[14px] hover:text-blue-600 cursor-pointer">
                                                            {item}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className="p-4 space-y-3 border-t">
                                <button className="w-full text-[14px] bg_gradient-blue-tr text-white rounded-full py-3.5 px-6 font-semibold"
                                    onClick={() => navigate('/auth/signup/?account_type=business')}
                                >
                                    Signup
                                </button>
                                <button className="w-full text-[14px] border border-primary-blue text-primary-blue rounded-full py-3.5 px-6 font-semibold flex items-center justify-center gap-2"
                                    onClick={() => navigate('/contact')}
                                >
                                    <Svgs.PhoneIcon />
                                    Contact
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Features Popup */}
            {isFeaturesPopupOpen && (
                <div
                    onClick={() => setIsFeaturesPopupOpen(false)}
                    className="absolute top-[6.4rem] h-[calc(100vh-6.4rem)] w-full left-1/2 transform -translate-x-1/2 z-50"
                >
                    <FeaturesPopup />
                </div>
            )}
        </div>
    );
};

export default Navbar;

const FeaturesPopup = () => {
    const features = useMemo(() => [
        ['Calendar & Scheduling', 'Online Booking', 'Retail & Inventory', 'Packages', 'Mobile Apps', 'Marketing & Automation'],
        ['Payments & Point of Sale', 'Express Booking', 'Staff management', 'Integrated forms', 'Reporting', 'Payroll Processing'],
        ['Client Management', 'Tow way Texting', 'Virtual Waiting Room', 'Gift Cards', 'Multi Location', 'Integrations']
    ], []);

    return (
        <div className="bg-white font-manrope rounded-3xl shadow-xl p-7 max-w-[75rem] mx-auto grid md:grid-cols-4 gap-6">
            <div className="col-span-3  grid grid-cols-3 divide-x gap-6">
                {features.map((column, colIndex) => (
                    <div key={colIndex} className={`flex flex-col ${colIndex !== 0 && "pl-6"} gap-1`}>
                        {column.map((feature, index) => (
                            <div key={index} className="flex items-center justify-between p-3 hover:bg-secondary-white rounded-full cursor-pointer">
                                <span className="text-black text-sm">{feature}</span>
                                <Svgs.Chevron_down className="-rotate-90" stroke="#6A6B74" size="1rem" />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="flex rounded-[1.125rem] p-5 bg_gradient-blue-tb relative">
                <div className="absolute right-6 top-6">
                    <Svgs.Solution />
                </div>
                <div className="flex flex-col h-full justify-end items-end gap-6">

                    <div className="flex flex-col gap-2.5 text-white">
                        <h1 className='text-[1.625rem] leading-tight font-semibold'>All-in-One Solution for Your Business</h1>
                        <p className='text-sm font-medium'>Manage bookings, payments, staff, and more—all in one place!</p>
                    </div>

                    <LoginBtn
                        customThemeBtn={'w-full bg-white font-semibold text-xs rounded-full py-2.5'}
                        text={'Get Started'}
                    />
                </div>
            </div>
        </div>
    );
};
