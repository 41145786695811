import React from 'react'
import { useNavigate } from 'react-router-dom'

const AppointmentTableRow = (props) => {
    const navigate = useNavigate()
    return (
        props?.animate ? <tr className="bg-white animate-pulse border-b">
            <td className="text-sm text-gray-900 font-light px-2 py-[10px]">
                <div className="flex items-center gap-3 px-3">
                    <div>
                        <div className="rounded-xl h-[55px] w-[55px] bg-gray-200">
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 w-full'>
                        <div className="h-5 w-full rounded-sm bg-gray-200"></div>
                        <div className="h-5 w-full rounded-sm bg-gray-200"></div>
                    </div>
                </div>
            </td>
            <td className=" px-2 py-2text-center">
                <div className="mx-auto h-5 w-1/2 rounded-sm bg-gray-200"></div>

            </td>
            <td className=" px-2 py-2">
                <div className="mx-auto h-5 w-1/2 rounded-sm bg-gray-200"></div>
            </td>
            <td className=" px-2 py-2">
                <div className="mx-auto h-5 w-1/2 rounded-sm bg-gray-200"></div>
            </td>
        </tr>
            : <tr className="bg-white border-b cursor-pointer"
                // onClick={}
                onClick={() => {
                    navigate(`/dashboard/calendar/appointment-detail/${props?.data?.id}/?parent_id=${props?.data?.appointment_id}`)
                }}
            >
                <td className="text-sm text-gray-900 font-light px-2 py-[10px]">
                    <div className="flex items-center gap-3 px-3">
                        <div>
                            <div className="rounded-xl h-[55px] w-[55px] flex overflow-hidden justify-center items-center bg-[#EFF2F9]">
                                {props.img
                                    ? <img src={props?.img} className='h-full' />
                                    : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.57999 19.01L2.55999 19.03C2.28999 18.44 2.11999 17.77 2.04999 17.03C2.11999 17.76 2.30999 18.42 2.57999 19.01Z" fill="black" />
                                        <path d="M9 10.3801C10.3144 10.3801 11.38 9.31456 11.38 8.00012C11.38 6.68568 10.3144 5.62012 9 5.62012C7.68556 5.62012 6.62 6.68568 6.62 8.00012C6.62 9.31456 7.68556 10.3801 9 10.3801Z" fill="black" />
                                        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z" fill="black" />
                                    </svg>
                                }
                            </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <p className="font-semibold text-[#3F3F44] line-clamp-2 overflow-ellipsis capitalize">{props.service}</p>
                            {
                                props.totalServices &&
                                <p className='text-[#3F3F44] text-xs'>{props.totalServices} Services</p>
                            }
                        </div>
                    </div>
                </td>
                <td className="text-sm text-gray-900 font-light px-2 py-2 break-words text-center capitalize">
                    {props.employee}
                </td>
                <td className="text-sm text-gray-900 font-light px-2 py-2 text-center">
                    {props.sale ? props.sale : '---'}
                </td>
                <td className="text-sm text-gray-900 font-light px-2 py-2 text-center">
                    <div className='bg-[#E0E7FF] rounded-[4px] px-2.5 py-1.5 text-[#5679FF] w-fit mx-auto font-semibold text-xs'>{props.earnings}</div>
                </td>
            </tr>
    )
}

export default AppointmentTableRow