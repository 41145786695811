import { COMPLAINT } from "Redux/TenantRedux/ActionTypes/Complaint/complaint"

export const get_complaint_action = (data) => {
    return {
        type: COMPLAINT.GET_COMPLAINT,
        payload: data
    }
}

export const get_complaint_category_action = (data) => {
    return {
        type: COMPLAINT.GET_COMPLAINT_CATEGORY,
        payload: data
    }
}