import { useState, useMemo } from "react";
import { useSelector } from "react-redux";

const useAppointmentByServiceHelper = () => {
    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation;
    const selectedLanguage = state?.languages?.selected_language;
    const location_currency = state?.utility?.currency?.location_currency;
    const productSummaryData = state?.saleReport?.productSummaryData;
    const Category = productSummaryData?.category;
    console.log(Category, "Category")
    // states
    const [searchText, setSearchText] = useState("");

    // Debounced search filtering
    const filteredCategories = useMemo(() => {
        const allData = Category || []
        if (!searchText) return allData;
        return allData?.filter(cat =>
            cat?.category_name?.toLowerCase().includes(searchText?.toLowerCase())
        );
    }, [searchText, Category]);

    return {
        translation, selectedLanguage, location_currency, searchText, setSearchText, filteredCategories
    };
};

export default useAppointmentByServiceHelper;
