import { getClienComplimentaryVoucher, getClient } from "Adapters/Api/clients"
import Svgs from "Assets/svgs"
import moment from "moment"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

let COMPLIMENTARY_DURATIONS = {
    'Next 1 visit': 1,
    'Next 2 visit': 2,
    'Next 3 visit': 3,
    'Next 4 visit': 4,
    'Next 5 visit': 5,
    'Next 6 visit': 6,
    'Next 7 visit': 7,
    'Next 8 visit': 8,
    'Next 9 visit': 9,
    'Next 10 visit': 10,
}

export const ComplimentaryVoucherCard = ({ selected, data, onSelect, onRemoveOffer }) => {
    const state = useSelector(state => state)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div
                className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                    }  border rounded-md p-2 mt-1`}
            >
                {onRemoveOffer && selected &&
                    <div className="flex items-center justify-end mb-2 ">
                        <button
                            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                            onClick={onRemoveOffer}
                        >
                            <Svgs.Delete fill="#767A87" />
                        </button>
                    </div>
                }

                <div className="px-3  text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>
                <div className="mb-3 p-3 flex justify-between gap-1">
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "Complimentary Voucher" : translation[1211]?.value ? translation[1211]?.value : "Complimentary Voucher"}
                    </p>
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "Visits" : translation[1210]?.value ? translation[1210]?.value : "Visits"}
                    </p>
                </div>
                <div className="">
                    {
                        data?.services?.map((service, index) => {
                            return (
                                <div key={index} className={`${selected ? "" : "border"} rounded-md cursor-pointer border-[#B8C6F5] p-2 my-3 flex items-center justify-between`}
                                    onClick={() => {
                                        onSelect &&
                                            onSelect(
                                                {
                                                    ...data,
                                                    services: [service]
                                                }
                                            )
                                    }}>
                                    <p className="text-[#2a2a2a]">{service?.service?.name ? service?.service?.name : service?.service?.srv_name}
                                        <span className="text-gray-800 font-medium">
                                            ({service?.discount_percentage}%
                                            {selectedLanguage?.name == "English" ? "OFF" : translation[1209]?.value ? translation[1209]?.value : "OFF"}
                                            )
                                        </span>
                                    </p>
                                    <p className="text-[#2a2a2a]">{service?.discount_duration}</p>
                                </div>
                            )
                        })
                    }

                </div>

                {/* Date duration */}
                <div className="flex items-center justify-between w-full gap-2">
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

ComplimentaryVoucherCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const ComplimentaryVoucher = ({ complimentaryVoucher, MODULE_TYPE, servicesRedux, onSelect, setSelServGroups, cartData, setCartData,
    appointmentServices, setAppointmentService }) => {
    const dispatch = useDispatch()

    const state = useSelector(state => state)
    const [voucher_data, setVoucherData] = useState([])


    const handle_select_appointment = (data) => {
        let selected_client = cartData?.client
        if (!selected_client) {
            toast.error('No client found to apply promotion.', { toastId: "toast" })
            return
        }

        getClienComplimentaryVoucher(
            { client: selected_client, complimentary: data?.id },
            (result) => {
                let new_apps = []
                let service_taken = false
                let total_allowed = 0
                data?.services?.forEach(service => {
                    let total_taken = COMPLIMENTARY_DURATIONS[service.discount_duration]
                    total_allowed = total_allowed + total_taken
                    let this_price = service?.service?.price
                    let appointment = {
                        ...service?.service,
                        discount_percentage: service?.discount_percentage,
                        voucher_duration: service?.discount_duration,
                        discount_price: this_price || this_price == 0 ? ((this_price * (100 - service?.discount_percentage)) / 100) : undefined,
                    }
                    new_apps.push(appointment)
                })

                if (result.count >= total_allowed) {
                    service_taken = true
                }

                if (service_taken) {
                    toast.info(`You have completely availed this offer.`, { toastId: "toast" })
                    onSelect(
                        [],
                        false,
                    )
                    return
                }
                else {
                    const updatedAppointmentServices = appointmentServices?.map(service => {
                        const filteredService = new_apps?.find(itm => itm?.service == service?.service)
                        if (filteredService?.service === service?.service) {
                            return {
                                ...service,
                                discount_price: filteredService?.discount_price,
                                discount_price_minus: filteredService?.price - filteredService?.discount_price,
                                discount_percentage: filteredService?.discount_percentage,
                                is_promotion: true,
                                is_promotion_availed: true
                            };
                        }
                        return service;
                    })

                    const promotionalServices = updatedAppointmentServices?.filter(itm => itm?.is_promotion)
                    const total_discount_price = promotionalServices?.reduce((sum, item) => sum + (item?.discount_price_minus || 0), 0);
                    setAppointmentService(updatedAppointmentServices);
                    setCartData({
                        ...cartData,
                        promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
                        is_promotion: true,
                        is_promotion_availed: true,
                        selected_promotion_type: data?.type || null,
                        selected_promotion_id: data?.id || null,
                    })
                    onSelect?.()
                }
            },
            (error) => {
                toast.info('Something went wrong', { toastId: "toast" })
                onSelect(
                    [],
                    false,
                )
                return
            }
        )

    }

    const handle_select_services = (data) => {
        let new_items = []

        let selected_currency_id = state.locations.locations.find(loc => loc.id == state.locations?.selected_location)?.currency?.id

        data?.services?.forEach((service) => {
            let this_price = service?.service?.priceservice?.find(itm => itm.currency == selected_currency_id)
            new_items.push({
                ...service?.service,
                service: service?.service?.id,
                discount_price: this_price ? (this_price.price * (100 - service?.discount_percentage) / 100) : undefined,
                discount: service?.discount_percentage,
                voucher_duration: service?.discount_duration,
                quantity: 1,
            })
        })


        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': new_items,
                'client_type': "In_Saloon",
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });

        toast.success('Items added to cart', { toastId: "toast" })
        onSelect(
            [],
            false,
        )
    }

    const handle_select = (data) => {
        let funcs = {
            'APPOINTMENT': () => handle_select_appointment(data),
            'SERVICE': () => handle_select_services(data),
        }
        funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
    }

    useEffect(() => {
        if (!state.client.clients_updated) {
            dispatch(
                getClient()
            )
        }
    }, [])

    useEffect(() => {
        let voucher_data_ = [
            ...complimentaryVoucher
        ]

        voucher_data_.forEach(vouchr => {
            if (!vouchr.services) {
                vouchr.services = vouchr.freeservice
            }
            vouchr?.services?.forEach(srv => {
                let this_service = appointmentServices?.find(itm => itm?.service == srv?.service)
                if (this_service) {
                    srv.service = this_service
                }
            })
        })

        setVoucherData(voucher_data_)

    }, [servicesRedux])

    return (
        <>
            {
                voucher_data?.map((retail, index) => {
                    return (
                        <>
                            <ComplimentaryVoucherCard
                                data={retail}
                                key={index}
                                onSelect={handle_select}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

ComplimentaryVoucher.defaultProps = {
    spend_some_amount: []
}


export default ComplimentaryVoucher

