import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const ReactDateRangePicker = ({ startDate, endDate, setStartDate, setEndDate, maxDate, minDate, className, customPadding, onClear,
    hideClear
}) => {
    // states
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const [dateSelected, setDateSelected] = useState(false)
    const [clickCount, setClickCount] = useState(0);
    const [selectionRange, setSelectionRange] = useState({
        startDate: startDate ? new Date(startDate) : new Date(),
        endDate: endDate ? new Date(endDate) : new Date(),
        key: 'selection',
    });

    // on clear date range
    const onClearSelection = () => {
        if (onClear) {
            onClear()
        } else {
            setStartDate()
            setEndDate()
            setSelectionRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            })
        }
        setDateSelected(false);
        setClickCount(0);
    }

    // handle select date range
    const handleSelect = (ranges) => {
        let countValue = clickCount == 2 ? 0 : clickCount
        setClickCount(countValue + 1);
        if (ranges.selection.startDate && ranges.selection.endDate) {
            setSelectionRange(ranges.selection);
            setDateSelected(true)
        } else if (ranges.selection.startDate) {
            // User has only selected the start date, not the end date
            setSelectionRange({
                startDate: ranges.selection.startDate,
                endDate: null,
                key: 'selection',
            });

        } else {
            // User has cleared both start and end dates
            setSelectionRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            });
        }
    };

    useEffect(() => {
        if (clickCount == 2) {
            if (selectionRange.startDate && selectionRange.endDate && dateSelected && selectionRange?.endDate != "Invalid Date") {
                const from = selectionRange.startDate ? moment(selectionRange.startDate).format("YYYY-MM-DD") : '';
                const to = selectionRange.endDate ? moment(selectionRange.endDate).format("YYYY-MM-DD") : '';
                setStartDate(from);
                setEndDate(to);
                setToggleDateRange(false)
            }
        }
    }, [selectionRange, clickCount]);

    return (
        <div className={`${customPadding ? customPadding : "py-2.5 px-2"} bg-white relative gap-2 border border-lightGray text-xs rounded-md font-semibold flex items-center select-none`}>
            <p onClick={() => {
                setToggleDateRange(!toggleDateRange)
            }} className='cursor-pointer'>
                {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
            </p>
            {hideClear ? "" :
                <span className='cursor-pointer' onClick={onClearSelection}>Clear</span>
            }

            {
                toggleDateRange && <>
                    <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                    <div className='absolute top-full -right-5 xs:right-0 z-[9999]'>
                        <div className={`${className} bg-darkGrey border-lightGrey date-range-picker-container shadow-sm overflow-hidden border rounded-lg mb-2 scale-90 xs:scale-100 mt-0 xs:mt-2`}>
                            <DateRange
                                editableDateInputs={true}
                                onChange={handleSelect}
                                moveRangeOnFirstSelection={false}
                                ranges={[selectionRange]}
                                rangeColors={['var(--secondary-color)']}
                                maxDate={maxDate && maxDate}
                                minDate={minDate && minDate}
                            />
                        </div>
                    </div>
                </>
            }
        </div>

    );
}

export default ReactDateRangePicker;
