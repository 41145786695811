import { useState, useMemo } from "react";
import { useSelector } from "react-redux";

const useAppointmentByServiceHelper = () => {
    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation;
    const selectedLanguage = state?.languages?.selected_language;
    const location_currency = state?.utility?.currency?.location_currency;
    const appointmentSummaryData = state?.saleReport?.appointmentSummaryData;
    const Services = appointmentSummaryData?.services;

    // states
    const [searchText, setSearchText] = useState("");

    // Debounced search filtering
    const filteredServices = useMemo(() => {
        const allData = Services || []
        if (!searchText) return allData;
        return allData?.filter(srv =>
            srv?.service_name?.toLowerCase().includes(searchText?.toLowerCase())
        );
    }, [searchText, Services]);

    return {
        translation, selectedLanguage, location_currency, searchText, setSearchText, filteredServices
    };
};

export default useAppointmentByServiceHelper;
