import { useNavigate } from "react-router-dom";

// import React from "react";
export const logoutfunction = () => {
    localStorage.removeItem('loginData')
    localStorage.clear()
    const origin = window.location.origin;
    const extracted = origin.replace(/:\/\/[^.]+\./, '://');
    window.location.href = `${extracted}/home`

    // window.location.href = `${process.env.REACT_APP_MY_VARIABLE}/auth/account-type/?logout=1`

    // let access_token = localStorage.removeItem('access_token');
    // let user_id = localStorage.removeItem('user_id');

    // window.location.href = process.env.REACT_APP_MY_VARIABLE + '/auth/account-type';
    // console.log(process.env.REACT_APP_MY_VARIABLE + '/auth/account-type');
    // localStorage.clear();
}