import Svgs from 'Assets/svgs';
import React from 'react';

const SelectBusinessCard = ({ title, icon: Icon }) => (
    <div className="bg-white select-business-card p-8 border border-[#BDC7D4] rounded-3xl flex flex-col gap-5 items-center justify-center shadow-xl transition-shadow relative">
        {/* Icon */}
        <div className="text-primary-blue">
            {Icon}
        </div>

        {/* Content */}
        <h3 className="text-2xl mb-1.5 text-black text-center">{title}</h3>
    </div>
);

const SelectBusinessType = () => {
    const services = [
        { name: "Hair Saloon", icon: <Svgs.HairSaloon /> },
        { name: "Nail Saloon", icon: <Svgs.NailSaloon /> },
        { name: "Beauty Saloon", icon: <Svgs.BeautySaloon /> },
        { name: "Barbershop", icon: <Svgs.BarbarShop /> },
        { name: "Eyebrows & Lashes", icon: <Svgs.EyeBrowLashes /> },
        { name: "Massage", icon: <Svgs.Massage /> },
        { name: "Spa", icon: <Svgs.Spa /> },
        { name: "Gym & Fitness", icon: <Svgs.GymFitness /> },
    ];

    return (
        <>
            <div className="bg-secondary-white py-20 px-4 font-manrope">
                <div className="max-w-[1200px] mx-auto">
                    {/* Heading */}
                    <div className="text-center mb-12">
                        <div className="text-black w-full max-w-[57.5rem] mx-auto flex flex-col items-center gap-5 mb-12">
                            <h1 className="text-[3.125rem] text-center font-bold mx-auto leading-tight">
                                Begin for Free by Selecting Your Business Type
                            </h1>
                            <p className="text-2xl text-center"> The sole platform designed exclusively for salons, offered with no fees attached.</p>
                        </div>
                    </div>

                    {/* Features Grid */}
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
                        {services.map((feature, index) => (
                            <SelectBusinessCard
                                key={index}
                                number={index + 1}
                                title={feature.name}
                                icon={feature.icon}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-white py-20 px-4 font-manrope">
                <div className="grid sm:grid-cols-2 gap-4 items-center max-w-[1200px] mx-auto">
                    <div className="flex justify-center max-w-xl mx-auto items-center">
                        <img src="/images/mobile-app.png" alt="" srcset="" />
                    </div>

                    <div className="flex flex-col gap-6">
                        <div className="text-black w-full max-w-[57.5rem] mx-auto flex flex-col items-start gap-5">
                            <h1 className="text-[3.125rem] text-start font-bold leading-tight">Get NStyle Moblie App</h1>
                            <p className="text-2xl text-start">
                                Seamless experience at your fingertips! Book, track, and explore with ease—download now for a smarter way to stay connected.
                            </p>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-2.5">
                                <div className="flex-shrink-0 w-6 h-6 mt-1">
                                    <Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} />
                                </div>
                                <p className='text-sm '>Easy & Fast Booking</p>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <div className="flex-shrink-0 w-6 h-6 mt-1">
                                    <Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} />
                                </div>
                                <p className='text-sm '>Real-Time Notifications</p>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <div className="flex-shrink-0 w-6 h-6 mt-1">
                                    <Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} />
                                </div>
                                <p className='text-sm '>Secure Payments</p>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center">
                            <div className='flex w-[12.5rem]'>
                                <img src="/images/App-Store.png" alt="" srcset="" className='w-full' />
                            </div>
                            <div className='flex w-[12.5rem]'>
                                <img src="/images/Play-Store.png" alt="" srcset="" className='w-full' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default SelectBusinessType;