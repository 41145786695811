import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "Components/TenantApp/Elements/Popup";
import Textarea from "Components/TenantApp/Elements/Textarea";
import LoginInput from "Components/TenantApp/Elements/LoginInput";


import {
  clientCanBookOptionList,
  controllTimeSlotOptionList,
} from "Constants/Data/BookingSettingData";
import { useAddServices } from "./helper";

import Svgs from "Assets/svgs";
import I_Info_Icon from "Components/TenantApp/Elements/I_Info_Icon";
import { APPOINTMENT_TIME } from "Constants/Data/OpeningHours";
import { useService } from "../helper";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import AddEmployeePopup from "Components/TenantApp/Dashboard/Employee/AddEmployee/AddEmployeePopup";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import { getServiceGroup } from "Adapters/Api/saleServices";
import ProductFormShimmer from "Components/Utility/Shimmers/ProductFormShimmer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import UploadFile from "Components/TenantApp/Elements/UploadFile";

const AddService = ({
  setServiceGroup,
  setServiceType,
  setAddService,
  serviceModal,
  ServiceType,
  serviceData,
  setService,
  setIsEdit,
  setErrors,
  errors,
  isEdit,
  loading,
  onBlurHandler,
  onSubmitService,
  durationOptions,
  serviceHandleChange,
  removedSelectedEmployee,
  setSelectServices,
  formRef,
  icon,
  isLoading,
  showPlusIcon,
  allowGroupsApiCall,
  successAddServiceFromServiceManagmnet,
  onSuccessAddServiceFromCalendar
}) => {

  const {
    name,
    employee,
    location,
    is_package,
    invoices,
    description,
    image,
    priceservice,
    service_availible,
    slot_availible_for_online,
    initial_deposit,
    client_can_book,
    staffgroup_id,
    // enable_team_comissions,
    // controls_time_slot,
  } = serviceData;

  const {
    reduxgroup,
    selectePrice,
    employeeRedux,
    selecteDuration,
    reduxlocationsList,
    openAddServicePopup,
    addDurationPrice,
    addMultilanguageInvoices,
    setDuration,
    setPrice,
    invoiceLanguages, setInvoiceLanguages,
    service_name, setService_name,
    selectedCurrency,
    setSelectedCurrency,
    serviceGroupSearchText, setServiceGroupSearchText, serviceGroupCurrentPage, setServiceGroupCurrentPage, serviceGroupLoader, serviceGroupPaginationData,
    // employeeOnSelectedLocation,
    // allUinqueCurrencyList,
    // formRef
  } = useAddServices({
    setIsEdit,
    setService,
    setAddService,
    setServiceType,
    serviceData,
  });
  const { locationList } = useService()
  const dispatch = useDispatch();

  const [isEmployee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [filteredInvoicesOptions, setFilteredInvoicesOptions] = useState([])
  const [showAddStaffPopup, setShowAddStaffPopup] = useState(false)
  const [loadingData, setLoadingData] = useState(true)

  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const invoiceLanguage = state?.multilanguageInvoices?.invoices

  useEffect(() => {
    setLoadingData(isLoading)
  }, [isLoading])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user, isEmployee, employeePermissions]);

  useEffect(() => {
    dispatch(getServiceGroup());
  }, [])


  useEffect(() => {
    if (location?.length > 0) {
      const filteredLocations = reduxlocationsList.filter(locationObj =>
        location?.includes(locationObj.id)
      );
      const filteredLanguages = invoiceLanguage?.filter(language => {
        // Check if language name exists in primary_translation or secondary_translation of any location
        const foundInPrimaryTranslation = filteredLocations?.some(loc => loc.primary_translation == language.id);
        const foundInSecondaryTranslation = filteredLocations?.some(loc => loc.secondary_translation == language.id);
        // If language name is found in either primary_translation or secondary_translation, keep it in the filteredLanguages array
        return (foundInPrimaryTranslation || foundInSecondaryTranslation) &&
          language.language_data.code !== "eng";
      });
      setFilteredInvoicesOptions(filteredLanguages)
    } else {
      setFilteredInvoicesOptions([])
    }
  }, [location, reduxlocationsList, invoiceLanguage])

  const removeSelectedPrice = (currency, duration, price, type) => {
    if (type === "priceservice") {
      setService((prev) => ({
        ...prev,
        priceservice: [
          ...prev?.priceservice?.filter(
            (itm) =>
              itm?.currency !== currency ||
              itm?.duration !== duration ||
              itm?.price !== price
          ),
        ],
      }));
    } else {
      setService((prev) => ({
        ...prev,
        priceservice: [
          ...prev?.priceservice?.map((itm) => {
            if (
              itm?.currency === currency &&
              itm?.duration === duration &&
              itm?.price === price
            ) {
              return {
                ...itm,
                is_deleted: true,
              };
            }
            return itm;
          }),
        ],
      }));
    }
  };
  const removeSelectedLanguage = (invoiceLanguage, service_name, type) => {
    if (type === "invoices") {
      setService((prev) => ({
        ...prev,
        invoices: [
          ...prev?.invoices?.filter(
            (itm) =>
              itm?.invoiceLanguage !== invoiceLanguage ||
              itm?.service_name !== service_name),
        ],
      }));
    } else {
      setService((prev) => ({
        ...prev,
        invoices: [
          ...prev?.invoices?.map((itm) => {
            if (
              itm?.invoiceLanguage === invoiceLanguage &&
              itm?.service_name === service_name
            ) {
              return {
                ...itm,
                is_deleted: true,
              };
            }
            return itm;
          }),
        ],
      }));
    }
  };

  // Filter allUniqueCurrencyList based on locationIds
  const filteredCurrencyList = locationList?.filter((cur) => location?.includes(cur?.id));

  // Generate options from the filtered list
  let options = filteredCurrencyList.map((itm) => ({
    label: itm.currency.code,
    value: itm.currency.id,
  }));
  // Remove duplicates based on the 'value' property
  const uniqueCurrencyListData = options?.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.value === item.value
    ))
  );

  return (
    <>
      {!isEmployee ? (
        <LoginBtn
          onClick={openAddServicePopup}
          text={<>

            {icon ? icon : <></>}
            <div className="flex items-center gap-2">
              {!showPlusIcon &&
                <Svgs.Plus size='0.8rem' />
              }
              <p className="">
                {selectedLanguage?.name == "English" ? "Create Service" : translation[252]?.value ? translation[252]?.value : "Create Service"}
              </p>
            </div>
          </>
          }
        />
      ) : (
        ""
      )}

      {serviceModal && (
        <Popup
          open={serviceModal}
          close={() => {
            setAddService(!serviceModal);
            setErrors({});
            setService({
              business: "",
              name: "",
              service_type: "",
              description: "",
              price: "",
              duration: "",
              priceservice: [],
              is_package: false,
              // employee: [],
              location: [],
              service: [],
              invoices: [],
              // controls_time_slot: "",
              initial_deposit: null,
              slot_availible_for_online: "",
              client_can_book: "",
              enable_team_comissions: false,
              enable_vouchers: false,
            });
          }}
          heading={`${ServiceType == "Package"
            ? isEdit
              ? selectedLanguage?.name == "English" ? "Update Package" : translation[253]?.value ? translation[253]?.value : "Update Package"
              : selectedLanguage?.name == "English" ? "Add Package" : translation[254]?.value ? translation[254]?.value : "Add Package"

            : isEdit
              ? selectedLanguage?.name == "English" ? "Update Service" : translation[255]?.value ? translation[255]?.value : "Update Service"
              : selectedLanguage?.name == "English" ? "Add Service" : translation[256]?.value ? translation[256]?.value : "Add Service"

            }`}
        >
          {loadingData
            ? <ProductFormShimmer hideStep />
            : <div className="flex flex-col gap-6 text-left" ref={formRef}>
              <div className="flex items-center gap-4">
                <CircleIcon>
                  <Svgs.NewService />
                </CircleIcon>
                <div className="flex flex-col gap-1">
                  <h1 className="text-primary text-lg font-bold normal-case">
                    {is_package
                      ? isEdit
                        ? selectedLanguage?.name == "English" ? "Update Package" : translation[253]?.value ? translation[253]?.value : "Update Package"
                        : selectedLanguage?.name == "English" ? "Create New Package" : translation[257]?.value ? translation[257]?.value : "Create New Package"

                      : isEdit
                        ? selectedLanguage?.name == "English" ? "Update Service" : translation[255]?.value ? translation[255]?.value : "Update Service"
                        : selectedLanguage?.name == "English" ? "Introduce a new service" : translation[1725]?.value ? translation[1725]?.value : "Introduce a new service"
                    }
                  </h1>
                  <p className="text-sm text-[#7B7B7B]">
                    {selectedLanguage?.name == "English" ? "Fill in the following information to add a service" : translation[259]?.value ? translation[259]?.value : "Fill in the following information to add a service"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <>
                  <div className="grid md:grid-cols-2 gap-4">
                    <LoginInput
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Service Name" : translation[260]?.value ? translation[260]?.value : "Service Name"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter service name" : translation[261]?.value ? translation[261]?.value : "Enter service name"}
                      name="name"
                      value={name}
                      // charactersMaxLength={25}
                      onChange={serviceHandleChange}
                      onBlur={() => onBlurHandler({ name: name })}
                      error={errors?.name}
                    />
                    <div>
                      <PaginationDropdown
                        required={true}
                        name="staffgroup_id"
                        value={staffgroup_id}
                        onChange={serviceHandleChange}
                        title={selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
                        placeholder={selectedLanguage?.name == "English" ? "Select service group" : translation[263]?.value ? translation[263]?.value : "Select service group"}
                        // onBlur={() =>
                        //   onBlurHandler({ staffgroup_id: staffgroup_id })
                        // }
                        error={errors?.staffgroup_id}
                        options={reduxgroup
                          // ?.filter((grp) => (grp?.is_active === true ? true : false))
                          ?.map((grp) => {
                            return {
                              label: grp.name,
                              value: grp.id,
                            };
                          })}

                        setPage={setServiceGroupCurrentPage}
                        setDropdownSearch={setServiceGroupSearchText}
                        page={serviceGroupCurrentPage}
                        dropdownSearch={serviceGroupSearchText}
                        lastPage={serviceGroupPaginationData?.total_pages}
                        currentPage={serviceGroupPaginationData?.currentPage}
                        loading={serviceGroupLoader}
                      />
                      {/* <Dropwdown/> */}
                      {reduxgroup?.filter((grp) => (grp?.is_active === true ? true : false))?.length == 0 && (
                        <div
                          className="flex items-center gap-2 cursor-pointer pt-2"
                          onClick={() => {
                            setServiceGroup(true);
                          }}
                        >
                          <p className="text-sm">
                            {selectedLanguage?.name == "English" ? "No service group found." : translation[1720]?.value ? translation[1720]?.value : "No service group found."}
                            <span className="underline mx-px text-[#5679FF]">
                              {selectedLanguage?.name == "English" ? "Click here" : translation[14]?.value ? translation[14]?.value : "Click here"}
                            </span>
                            {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </>

                <div className="grid md:grid-cols-2 gap-4">
                  <div className="flex flex-col gap-2 md:order-1 order-2">
                    <Dropwdown
                      required={true}
                      custom_padding='pt-[2px]'
                      title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                      name="location"
                      value={location}
                      onChange={serviceHandleChange}
                      onBlur={() => onBlurHandler({ location: location })}
                      error={(location?.length == 0 && errors?.location) ? true : false}
                      options={reduxlocationsList?.filter((lctin) => !location.includes(lctin?.id)).map((item) => {
                        return { label: item?.address_name, value: item?.id };
                      })}
                    />
                  </div>
                  <Dropwdown
                    className={"md:order-2 order-1"}
                    required={true}
                    name="service_availible"
                    value={service_availible}
                    onChange={serviceHandleChange}
                    title={selectedLanguage?.name == "English" ? "Services Available For" : translation[84]?.value ? translation[84]?.value : "Services Available For"}
                    placeholder={selectedLanguage?.name == "English" ? "Select availability for" : translation[89]?.value ? translation[89]?.value : "Select availability for"}
                    error={errors?.service_availible}
                    options={[
                      { label: selectedLanguage?.name == "English" ? "Everyone" : translation[85]?.value ? translation[85]?.value : "Everyone", value: 'Everyone' },
                      { label: selectedLanguage?.name == "English" ? "Male" : translation[86]?.value ? translation[86]?.value : "Male", value: 'Male' },
                      { label: selectedLanguage?.name == "English" ? "Female" : translation[87]?.value ? translation[87]?.value : "Female", value: 'Female' }
                    ]}
                  />
                </div>

                {location?.length > 0 ? (
                  <div className="flex items-center flex-wrap gap-2">
                    {location &&
                      location?.map((item) => {
                        let selected_location = reduxlocationsList?.find(
                          (lction) => lction?.id == item
                        );
                        return (
                          selected_location?.address_name && (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <Svgs.MAP_PIN />
                              <p className="text-primary text-sm font-bold">
                                {selected_location?.address_name}
                              </p>
                              <span
                                onClick={() =>
                                  removedSelectedEmployee(item, "location")
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          )
                        );
                      })}
                  </div>
                ) : (
                  <div className="flex items-center gap-1">
                    <Svgs.I />
                    <p className="text-xs text-[#555555] normal-case pt-1">
                      {selectedLanguage?.name == "English" ? "You can select multiple locations" : translation[264]?.value ? translation[264]?.value : "You can select multiple locations"}
                    </p>
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  <Dropwdown
                    required={true}
                    title={selectedLanguage?.name == "English" ? "Assign Staff" : translation[265]?.value ? translation[265]?.value : "Assign Staff"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Staff Member" : translation[266]?.value ? translation[266]?.value : "Select Staff Member"}
                    name={"employee"}
                    value={employee}
                    onChange={serviceHandleChange}
                    error={(employee?.length == 0 && errors?.employee) ? true : false}
                    options={employeeRedux?.filter(emp => emp?.is_active == true)
                      ?.filter((emp) =>
                        emp?.location?.length > 0 ? location?.includes(emp?.location[0]?.id) : true
                      )
                      ?.filter((emp) => !employee?.includes(emp?.id))
                      ?.map((item) => {
                        return {
                          label: item.full_name,
                          value: item.id,
                          otherData: {
                            image: item?.image,
                            DataOne: item.email,
                            showImage: true,
                          },
                          email: item.email,
                          mobile_number: item.mobile_number
                        };
                      })}
                  />
                  {employeeRedux?.filter(emp => emp?.is_active == true)
                    ?.filter((emp) =>
                      emp?.location?.length > 0 ? location?.includes(emp?.location[0]?.id) : true
                    )?.length > 0 ?
                    employee?.length > 0 ? (
                      <div className="flex items-center flex-wrap gap-2">
                        {employee &&
                          employee?.map((item) => {
                            let selected_employee = employeeRedux?.find(
                              (empl) => empl?.id == item
                            );
                            return (
                              <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                <div>
                                  <img
                                    src={`${selected_employee?.image
                                      ? selected_employee?.image
                                      : `${process.env.PUBLIC_URL}/images/profile_img.svg`
                                      }`}
                                    className="h-[1.4rem] w-[1.4rem] object-cover rounded-md"
                                  />
                                </div>
                                <p className="text-primary text-sm font-bold">
                                  {selected_employee?.full_name}
                                </p>
                                <span
                                  onClick={() =>
                                    removedSelectedEmployee(item, "employee")
                                  }
                                >
                                  <Svgs.CirlceClose fill="#5679FF" />
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div className="flex items-center gap-1">
                        <Svgs.I />
                        <p className="text-xs text-[#555555] normal-case pt-1">
                          {selectedLanguage?.name == "English" ? "You can select multiple staff members" : translation[267]?.value ? translation[267]?.value : "You can select multiple staff members"}
                        </p>
                      </div>
                    ) : (
                      <div
                        className="flex items-center gap-2 cursor-pointer pt-2"
                        onClick={() => {
                          // localStorage.setItem('emp_popup_from_add_service', true)
                          setShowAddStaffPopup(!showAddStaffPopup);
                        }}
                      >
                        <p className="text-sm">
                          {selectedLanguage?.name == "English" ? "No staff found." : translation[1719]?.value ? translation[1719]?.value : "No staff found."}
                          <span className="underline mx-px text-[#5679FF]">
                            {selectedLanguage?.name == "English" ? "Click here" : translation[14]?.value ? translation[14]?.value : "Click here"}
                          </span>
                          {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                        </p>
                      </div>
                    )}
                </div>

                <div className="text-sm">
                  {selectedLanguage?.name == "English" ? "Add pricing with multiple durations and currencies" : translation[268]?.value ? translation[268]?.value : "Add pricing with multiple durations and currencies"}
                </div>
                <div className="flex  items-end gap-4 justify-end flex-wrap">

                  <Dropwdown
                    className={'flex-1 min-w-[15rem]'}
                    required={true}
                    title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                    name={"currency"}
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                    error={errors?.currency}
                    options={uniqueCurrencyListData}
                    leftText={
                      <p className="font-semibold text-sm text-primary">Minutes</p>
                    }
                  />
                  <Dropwdown
                    className={'flex-1 min-w-[15rem]'}
                    title={selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Duration" : translation[115]?.value ? translation[115]?.value : "Select Duration"}
                    type={"number"}
                    leftText={
                      <p className="font-semibold text-sm text-primary">
                        Minutes
                      </p>
                    }
                    required={true}
                    name={"duration"}
                    value={selecteDuration}
                    disabled={location?.length == 0}
                    onChange={(e, extraValues) => {
                      const { name, value } = e.target;
                      setDuration(value);
                      setErrors((prev) => ({
                        ...prev,
                        [name]: "",
                      }));
                    }}
                    error={errors?.duration}
                    options={APPOINTMENT_TIME}
                  />
                  <FloatInput
                    title={selectedLanguage?.name == "English" ? "Pricing" : translation[269]?.value ? translation[269]?.value : "Pricing"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter pricing" : translation[270]?.value ? translation[270]?.value : "Enter pricing"}
                    required={true}
                    parentClass={"flex-1"}
                    name={"price"}
                    value={selectePrice}
                    disabled={location?.length == 0}
                    onChange={(e, extraValues) => {
                      const { name, value } = e.target;
                      setPrice(value);
                      setErrors((prev) => ({
                        ...prev,
                        [name]: "",
                      }));
                    }}
                    error={errors?.price}
                  />
                  <div className="">
                    <LoginBtn
                      disabled={
                        !selecteDuration || !selectePrice || !selectedCurrency
                      }
                      className={(selecteDuration && selectePrice && selectedCurrency) && "shake-bottom"}
                      onClick={() => {
                        if (priceservice?.find(itm => itm?.price == selectePrice && itm?.duration == selecteDuration && itm?.currency == selectedCurrency)) {
                          setErrors((prev) => ({
                            ...prev,
                            price: "Value already exists",
                          }));
                        } else {
                          addDurationPrice({
                            currency: selectedCurrency || (filteredCurrencyList[0]?.currency?.id),
                            duration: selecteDuration,
                            price: selectePrice,
                          });
                          setDuration("");
                          setPrice("");
                          setSelectedCurrency("");
                        }
                      }}
                    >
                      <div className="flex items-center gap-1">
                        <Svgs.Plus />
                        <p>
                          {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                        </p>
                      </div>
                    </LoginBtn>
                  </div>
                </div>

                {priceservice?.length > 0 ? (
                  <div className="flex items-center flex-wrap gap-2">
                    {priceservice &&
                      priceservice?.map((item, i) => {
                        const selected_currency = filteredCurrencyList?.find(
                          (itm) => itm?.currency?.id === item?.currency
                        );
                        return (
                          !item?.is_deleted && (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">{`${item?.duration.split("_")[0]
                                } - ${item?.price} ${selected_currency?.currency?.code
                                }`}</p>
                              <span
                                onClick={() =>
                                  removeSelectedPrice(item?.currency, item?.duration, item?.price, "priceservice")
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          )
                        );
                      })}
                  </div>
                ) : (
                  <></>
                )}

                {/* Multilanguages */}
                {filteredInvoicesOptions?.length > 0 &&
                  <div className="flex  items-end gap-4 flex-wrap">
                    <Dropwdown
                      required={false}
                      title={selectedLanguage?.name == "English" ? "Invoice Language" : translation[271]?.value ? translation[271]?.value : "Invoice Language"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Invoice Language" : translation[272]?.value ? translation[272]?.value : "Enter Invoice Language"}
                      name={"invoice_language"}
                      value={invoiceLanguages}
                      onChange={(e) => setInvoiceLanguages(e.target.value)}
                      error={errors?.invoice_language}
                      options={
                        filteredInvoicesOptions
                          ?.filter(current => !serviceData?.invoices?.find(itm => itm?.invoiceLanguage === current?.language))
                          ?.map((itm) => {
                            return { label: itm?.language_data?.name, value: itm?.language_data?.id };
                          })}

                    />
                    <LoginInput
                      type={"text"}
                      title={selectedLanguage?.name == "English" ? "Service Name" : translation[260]?.value ? translation[260]?.value : "Service Name"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter service name" : translation[261]?.value ? translation[261]?.value : "Enter service name"}
                      required={false}
                      name={"service_name"}
                      value={service_name}
                      disabled={filteredInvoicesOptions?.length == 0 || !invoiceLanguages}
                      onChange={(e, extraValues) => {
                        const { name, value } = e.target;
                        setService_name(value);
                        setErrors((prev) => ({
                          ...prev,
                          [name]: "",
                        }));
                      }}
                      error={errors?.service_name}
                    />

                    <div className="">
                      <LoginBtn
                        disabled={!invoiceLanguages || !service_name}
                        onClick={() => {
                          addMultilanguageInvoices({
                            invoiceLanguage: invoiceLanguages || (filteredInvoicesOptions[0]?.language),
                            service_name: service_name
                          });
                          setService_name("");
                          setInvoiceLanguages("");
                        }}
                        className={(invoiceLanguages && service_name) && "shake-bottom"}
                      >
                        <div className="flex items-center gap-1">
                          <Svgs.Plus />
                          <p>
                            {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                          </p>
                        </div>
                      </LoginBtn>
                    </div>
                  </div>
                }

                {invoices?.length > 0 ? (
                  <div className="flex items-center flex-wrap gap-2">
                    {invoices &&
                      invoices?.map((item, i) => {
                        const selected_language = filteredInvoicesOptions?.find((itm) => itm?.language_data?.id === item?.invoiceLanguage);
                        return (
                          !item?.is_deleted && (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">
                                {`${item?.service_name} ${selected_language?.language_data?.name}`}
                              </p>
                              <span
                                onClick={() =>
                                  removeSelectedLanguage(item?.invoiceLanguage, item?.service_name, "invoices")
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          )
                        );
                      })}
                  </div>
                ) : (
                  <></>
                )}

                <div className="flex flex-col gap-2">
                </div>
                <div className="flex flex-col gap-2">
                  <FloatInput
                    title={
                      <I_Info_Icon
                        text={selectedLanguage?.name == "English" ? "Initial Deposit" : translation[273]?.value ? translation[273]?.value : "Initial Deposit"}
                        info={selectedLanguage?.name == "English" ? "Customers need to submit some amount in advance for this service." : translation[286]?.value ? translation[286]?.value : "Customers need to submit some amount in advance for this service."}
                      />
                    }
                    placeholder={selectedLanguage?.name == "English" ? "Enter initial deposit" : translation[274]?.value ? translation[274]?.value : "Enter initial deposit"}
                    required={false}
                    name={"initial_deposit"}
                    value={initial_deposit === 0 ? "0" : initial_deposit}
                    onChange={serviceHandleChange}
                  />
                </div>
                <div className="grid lg:grid-cols-2 gap-4">
                  <Dropwdown
                    required={false}
                    type={"number"}
                    title={selectedLanguage?.name == "English" ? "Client Can Book Appointment" : translation[275]?.value ? translation[275]?.value : "Client Can Book Appointment"}
                    placeholder={selectedLanguage?.name == "English" ? "Client can book appointment" : translation[275]?.value ? translation[275]?.value : "Client can book appointment"}
                    name={"client_can_book"}
                    value={client_can_book}
                    onChange={serviceHandleChange}
                    options={clientCanBookOptionList(selectedLanguage, translation)}
                  />
                  <div className="flex flex-col gap-2 mt-5">
                    <Dropwdown
                      required={false}
                      title={" "}
                      placeholder={selectedLanguage?.name == "English" ? "No more than 5 months in the future" : translation[277]?.value ? translation[277]?.value : "No more than 5 months in the future"}
                      name={"slot_availible_for_online"}
                      value={slot_availible_for_online}
                      onChange={serviceHandleChange}
                      options={controllTimeSlotOptionList(selectedLanguage, translation)}
                    />
                    <div className="flex items-center gap-1">
                      <Svgs.I />
                      <p className="text-xs text-[#555555]">

                        {selectedLanguage?.name == "English" ? "Controls the time slots available for online booking" : translation[276]?.value ? translation[276]?.value : "Controls the time slots available for online booking"}
                      </p>
                    </div>
                  </div>
                </div>

                <Textarea
                  required={true}
                  title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter description" : translation[93]?.value ? translation[93]?.value : "Enter description"}
                  name="description"
                  value={description}
                  onChange={serviceHandleChange}
                  onBlur={() => onBlurHandler({ description: description })}
                  error={errors?.description}
                />

                <UploadFile
                  accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                  onChange={serviceHandleChange}
                  name="image"
                  id={'service_image'}
                  mb_file_size={10}
                  required={false}
                  edit={isEdit ? true : false}
                  value={image}
                  title={selectedLanguage?.name == "English" ? "Service Image" : translation[1399]?.value ? translation[1399]?.value : "Service Image"}
                  error={errors?.image}
                />

                {/* <ToggleWithText
                title="Alow clients to select team members"
                value={{ enable_team_comissions }}
                onChange={() =>
                  setService((prev) => ({
                    ...prev,
                    enable_team_comissions: !enable_team_comissions,
                  }))
                }
                checked={enable_team_comissions}
              /> */}

                <div className="flex justify-end">
                  <LoginBtn
                    className={"!px-6"}
                    loading={loading}
                    disabled={loading}
                    onClick={async () => {
                      const selectedLocations = reduxlocationsList?.filter((lctin) => location.includes(lctin?.id))
                      const selectedLocationIds = selectedLocations?.map(location => location.currency.id);
                      const priceServiceCurrenciesIds = priceservice?.map(item => item.currency);

                      if (!selectedLocationIds?.every(id => priceServiceCurrenciesIds?.includes(id))) {
                        setErrors((prev) => ({
                          ...prev,
                          price: selectedLanguage?.name == "English" ? "Add all currencies and their price" : translation[1655]?.value ? translation[1655]?.value : "Add all currencies and their price"
                        }));
                        formRef.current.scrollIntoView({ behavior: "smooth" });
                      } else {
                        setErrors((prev) => ({
                          ...prev,
                          price: "",
                        }));
                        await onSubmitService();
                      }
                    }}
                    text={`${is_package
                      ? isEdit
                        ? selectedLanguage?.name == "English" ? "Update Package" : translation[253]?.value ? translation[253]?.value : "Update Package"
                        : selectedLanguage?.name == "English" ? "Add Package" : translation[254]?.value ? translation[254]?.value : "Add Package"

                      : isEdit
                        ? selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                        : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                      }`}
                  />

                </div>
              </div>
            </div>
          }
        </Popup>
      )}

      {showAddStaffPopup && (
        <AddEmployeePopup
          open={showAddStaffPopup}
          close={setShowAddStaffPopup}
          success={() => {
            successAddServiceFromServiceManagmnet && successAddServiceFromServiceManagmnet()
            onSuccessAddServiceFromCalendar && onSuccessAddServiceFromCalendar()
          }} />
      )}
    </>
  );
};

export default AddService;