import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import LocationSelection from "new-components/location";

import Attendance from "./Attendance/Attendance";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LeoDay from "./LeoDay/LeoDay";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Svgs from "Assets/svgs";
import AddLieuDay from "./LeoDay/AddLieuDay";



const AttendanceLeoDay = () => {
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [isAddLieuDay, setIsAddLieuDay] = useState(false)
  const [active_tab, setActiveTab] = useState("attendance")
  const [apiCallState, setApiCallState] = useState(false)


  const tabList = [
    {
      value: "attendance",
      label: selectedLanguage?.name == "English" ? "Attendance" : translation[404]?.value ? translation[404]?.value : "Attendance"
    },
    {
      value: "leo_day",
      label: selectedLanguage?.name == "English" ? "LIEU Day" : translation[1495]?.value ? translation[1495]?.value : "LIEU Day"
    },

  ];
  const tabsList = {
    attendance: <Attendance />,
    leo_day: <LeoDay apiCallState={apiCallState} setApiCallState={setApiCallState} />,
  };

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Attendance & LIEU Day" : translation[1494]?.value ? translation[1494]?.value : "Attendance & LIEU Day"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Attendance & LIEU Day" : translation[1494]?.value ? translation[1494]?.value : "Attendance & LIEU Day"}
            </h2>
            <LocationSelection />
          </div>
          {/* {!employee ||
            (employee &&
              employeePermissions?.employee_attendance?.includes("create")) ? ( */}
          {/* <div className="flex items-center sm:gap-4 gap-2">
            {active_tab == 'leo_day'
              ? <LoginBtn onClick={() => setIsAddLieuDay(!isAddLieuDay)}>
                <div className="flex items-center gap-2">
                  <Svgs.Plus />
                  <p className="font-semibold">
                    {selectedLanguage?.name == "English" ? "Add Lieu day" : translation[1558]?.value ? translation[1558]?.value : "Add Lieu day"}
                  </p>
                </div>
              </LoginBtn>
              : ""
            }
          </div> */}
          {/* ) : (
            ""
          )} */}
        </div>

        <div className={"border-b mt-[1rem]"}>
          <div className="flex items-center gap-6">
            {tabList?.length > 0 &&
              tabList.map((tab, index) => {
                return (
                  <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${active_tab == tab.value && "font-semibold border-primary"}`}
                    onClick={() => {
                      setActiveTab(tab.value)
                    }}
                    key={index}
                  >
                    <h2 className="text-primary capitalize">{tab.label}</h2>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="mt-[3rem] mb-[3rem]">{active_tab && tabsList[active_tab]}</div>
      </div>

      {isAddLieuDay &&
        <AddLieuDay
          open={isAddLieuDay}
          close={setIsAddLieuDay}
          successCallback={() => {
            setApiCallState(true)
          }}
        />
      }
    </>
  );
};

export default AttendanceLeoDay;
