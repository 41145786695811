import React from 'react'
import { CSVLink } from "react-csv";
import useUpsellDownsell from '../useUpsellDownsell';
import Popup from 'new-components/popup';
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';
import moment from 'moment';
import IconButton from 'Components/TenantApp/Elements/IconButton';
import Svgs from 'Assets/svgs';

import TableShimer from "Components/Utility/Shimmers/TableShimer";
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import NoPayrollFound from '../../Elements/NoPayrollFound';
import Pagination from 'new-components/Pagination';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';

const EmployeeUpsellDownSell = () => {
    const {
        loader,
        up_down_sell,
        currentPage, loadingPermission,
        pages,
        translation,
        selectedLanguage,
        employeePaginationData,
        Currency,
        employeeLoader, employeeRedux,
        setToggleDateRange, toggleDateRange,
        startDate, endDate,
        setStartDate, setEndDate,
        tabList,
        active_tab,
        setSearchParams,
        searchParams,
        setEmployeeSearch, employeeSearch,
        selectedEmployee, setSelectedEmployee, employee, payrollData,
        exportLoader, handleExportClick, setCurrentPage,
        setEmployeePage, employeePage, setImportPayroll, ImportPayroll, PageSize
    } = useUpsellDownsell()
    return (
        <>
            <div className="">

                <div className="border rounded-lg bg-white">
                    <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b sm:flex-row flex-col gap-3">
                        <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
                            <div className="flex items-center sm:gap-4 gap-2 ">
                                <h2 className="font-semibold text-xl">
                                    {selectedLanguage?.name == "English" ? "Employee Upsell and Downsell Details" : translation[1782]?.value ? translation[1782]?.value : "Employee Upsell and Downsell Details"}
                                </h2>
                                {loader ?
                                    <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                        <p className="rounded-md h-5 w-12 mx-1"> </p>
                                    </div>
                                    :
                                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                        <p className="text-primary">
                                            {up_down_sell?.length ? up_down_sell?.length : 0}{" "}
                                            {up_down_sell?.length && up_down_sell?.length > 1 ?
                                                selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                                : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                            }
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        {loadingPermission ?
                            <EmployeesHeadShimmer classOn1={'!w-full lg:!w-44 sm:!w-40'} classOn2={'!w-full lg:!w-44 sm:!w-40'} classOn3={'!w-full lg:!w-44 sm:!w-40'} classOn4={'!w-12 lg:!16'} has4Itm />
                            :
                            <div className="flex items-center sm:justify-center justify-end gap-3 sm:w-fit w-full flex-nowrap">
                                <div className="grid grid-cols-2 gap-3 items-center sm:flex w-full">
                                    <PaginationDropdown
                                        title=""
                                        setPage={setEmployeePage}
                                        page={employeePage}
                                        setDropdownSearch={setEmployeeSearch}
                                        dropdownSearch={employeeSearch}
                                        lastPage={employeePaginationData?.employeePages}
                                        currentPage={employeePaginationData?.employeeCurrentPage}
                                        showTitle={false}
                                        placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                                        name={"selectedEmployee"}
                                        value={selectedEmployee}
                                        onChange={(e) => setSelectedEmployee(e.target.value)}
                                        loading={employeeLoader}
                                        // customHeight={'h-[1.25rem]'}
                                        custom_min_width={'sm:min-w-[15rem]'}
                                        options={[
                                            {
                                                label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: ""
                                            },
                                            ...employeeRedux?.map((empl, i) => {
                                                return {
                                                    label: empl.full_name,
                                                    value: empl.id,
                                                    otherData: {
                                                        image: empl?.image,
                                                        DataOne: empl.email,
                                                        showImage: true,
                                                        index: i
                                                    },
                                                    email: empl.email,
                                                    mobile_number: empl.mobile_number
                                                };
                                            })
                                        ]}
                                    />


                                    <div className='border border-[#A1A1A1] w-full text-sm rounded-md font-semibold flex items-center select-none'>
                                        <div className='p-2 relative gap-4 flex justify-between items-center w-full'>
                                            <p onClick={() => {
                                                setToggleDateRange(!toggleDateRange)
                                            }} className='cursor-pointer'>
                                                {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/---"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/---"}`}
                                            </p>
                                            <span className='cursor-pointer' onClick={() => {
                                                setStartDate()
                                                setEndDate()
                                            }}>Clear</span>

                                            {
                                                toggleDateRange && <>
                                                    <div className='fixed inset-0 z-1 w-full' onClick={() => { setToggleDateRange(false) }}></div>
                                                    <div className='absolute top-full -right-16 xs:right-0 z-[2]'>
                                                        <DateRangePicker
                                                            start={startDate}
                                                            end={endDate}
                                                            setStartDate={setStartDate}
                                                            setEndDate={setEndDate}
                                                            setToggleDateRange={setToggleDateRange}

                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {exportLoader ? (
                                    <div className="h-[4rem] flex items-center justify-center">
                                        <PreloaderSm />
                                    </div>
                                ) :
                                    !employee ? (
                                        up_down_sell?.length > 0 ?
                                            <div className="flex items-center sm:gap-4 gap-2">
                                                <CSVLink
                                                    {...payrollData}
                                                    target="_blank"
                                                    onClick={handleExportClick}
                                                >
                                                    <LoginBtn
                                                        className="rounded-lg !py-[7px]"
                                                        bg="bg-white"
                                                        animation={false}
                                                        customThemeBtn={'theme-btn2'}
                                                    >
                                                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                            <Svgs.ExportSvg />
                                                        </div>
                                                    </LoginBtn>
                                                </CSVLink>
                                            </div> :
                                            <LoginBtn
                                                className="rounded-lg !py-[7px]"
                                                bg="bg-white"
                                                animation={false}
                                                onClick={handleExportClick}
                                                customThemeBtn={'theme-btn2'}
                                            >
                                                <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                    <Svgs.ExportSvg />
                                                </div>
                                            </LoginBtn>
                                    ) : (
                                        ""
                                    )}

                            </div>
                        }
                    </div>
                    {loader ?
                        <TableShimer cols={5} />
                        :
                        <div className="overflow-x-auto">
                            <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(165px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                                <p>{selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Service Name" : translation[260]?.value ? translation[260]?.value : "Service Name"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Before Service" : translation[1692]?.value ? translation[1692]?.value : "Before Service"}</p>
                                <p>{selectedLanguage?.name == "English" ? "After Service" : translation[1693]?.value ? translation[1693]?.value : "After Service"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Price Difference" : translation[1694]?.value ? translation[1694]?.valu : "Price Difference"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Staus"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                            </div>
                            <div className={`odd-bg text-[#3A3A3A] transition-all`}>
                                {up_down_sell?.length > 0
                                    ? up_down_sell?.map((item, i) => {
                                        return (
                                            <>
                                                <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(165px,1fr))] min-w-full w-fit even:bg-[#FBFCFF]">
                                                    <div className="h-full flex items-center">
                                                        <p className="text-sm">{item?.employee_name}</p>
                                                    </div>
                                                    <div className="h-full flex items-center">
                                                        <p className="text-sm">{item?.service_name}</p>
                                                    </div>


                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-1 pr-3">
                                                            {item?.old_price?.toFixed(2)} {Currency}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-1 pr-3">
                                                            {item?.new_price?.toFixed(2)} {Currency}
                                                        </p>
                                                    </div>

                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-1 pr-3">
                                                            {(+item?.price_difference)?.toFixed(2)} {Currency}
                                                        </p>
                                                    </div>
                                                    <div className="">
                                                        {item?.status
                                                            ? <LoginBtn className={`${item?.status == 'Up-Sell' ? "newSuccessBtnFocus" : 'newDeleteBtnFocus'} !shadow-none`} text={item?.status} />
                                                            : '------'
                                                        }
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm">
                                                            {moment(item?.created_at).format('YYYY-MM-DD')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    : <NoPayrollFound />
                                }
                            </div>
                        </div>
                    }
                    {/* Pagination */}
                    {(!loader && up_down_sell?.length > 0) &&
                        <Pagination
                            currentPage={currentPage}
                            totalCount={Math.ceil(pages)}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    }
                </div>
            </div>
            {ImportPayroll &&
                <Popup
                    heading={`Import Payrolls`}
                    close={setImportPayroll}
                    open={ImportPayroll}
                >
                    <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
                        <div className="h-[15rem]">
                            <Svgs.UploadList />
                        </div>
                        <p className="text-[#595959]">Drop your .CSV file to add Payrolls</p>
                        <p className="text-[#595959]">Or</p>
                        <LoginBtn size="sm" className="mx-auto">
                            <label htmlFor="csvFileforproduct" className="cursor-pointer">
                                <div className="flex items-center gap-2 text-[#ffffff]">
                                    Upload New List
                                </div>
                            </label>
                        </LoginBtn>
                    </div>
                </Popup>}
        </>
    )
}

export default EmployeeUpsellDownSell