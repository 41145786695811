import axios from "axios";
import { BASE_URL, create_maintenance_url, get_maintenance_url, update_maintenance_url, } from "../api_variables";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { get_maintanence_action } from "Redux/TenantRedux/Actions/maintanenceActions";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";

export const createMaintenanceAPI = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.post(`${BASE_URL}${create_maintenance_url}`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 201) {
            toast.success(result?.data?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.message, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const updateMaintenance = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.put(`${BASE_URL}${update_maintenance_url}${payload?.id}/?location_id=${payload?.location}`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 201) {
            toast.success(result?.data?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.message, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const getAllMaintenanceAPI = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${get_maintenance_url}${query}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.results?.status_code == 200) {
            dispatch(get_maintanence_action(result?.data))
            success && success(result)
            return;
        } else {
            dispatch(get_maintanence_action({}))
            fail && fail(result)
        }
    } catch (error) {
        dispatch(get_maintanence_action({}))
        fail && fail(error)
        return error;
    }
};

export const getSingleMaintenanceAPI = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.get(`${BASE_URL}${get_maintenance_url}/${payload?.id}/logs/?location_id=${payload?.location}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.results?.status_code == 200) {
            success && success(result?.data?.results)
            return;

        } else {
            fail && fail(result)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

