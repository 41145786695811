import { deleteExpense, deleteExpenseCategory, getExpense, getExpenseCategory, getExpenseCategoryNoPagination, getExpenseNoPagination } from "Adapters/Api/expense"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

const useExpense = () => {
    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const selected_location = getDataFromLocalStorage("selected_location");

    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const allExpenseCategory = state?.expense?.allExpenseCategory

    const [activeTab, setActiveTab] = useState("Expense");
    const [deletePopup, setDeletePopup] = useState()
    const [editData, setEditData] = useState()
    const [isEdit, setIsEdit] = useState(false)

    // ==================================== Expense Table Data ==================================== //
    const expenses = state?.expense?.expenses
    const expensePagination = state?.expense?.expensePagination
    const noPaginationExpense = state?.expense?.expensesNoPagination
    const Userstate = useSelector((state) => state);

    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [expenseFilter, setExpenseFilter] = useState({
        category: '',
        status: ''
    })
    const [toggleDateRange, setToggleDateRange] = useState();
    const [FilterPopup, setFilterPopup] = useState(false);
    const [addExpensePopup, setAddExpensePopup] = useState(false)
    const [loader, setLoader] = useState(true)
    const [noPaginationLoader, setNoPaginationLoader] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [exportLoader, setExportLoader] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);

    const getAllExpenses = (reset, page) => {
        setLoader(true)
        const payload = {
            page: page ? page : currentPage,
            start_date: startDate,
            end_date: endDate,
            location_id: selected_location,
            category: reset ? '' : expenseFilter?.category,
            status: reset ? '' : expenseFilter?.status
        }
        const success = () => { setLoader(false) }
        const fail = () => { setLoader(false) }
        dispatch(getExpense(access_token, payload, success, fail))
    }

    const getAllExpensesNoPagination = (reset, page) => {
        setNoPaginationLoader(true)
        const payload = {
            no_pagination: true,
            start_date: startDate,
            end_date: endDate,
            location_id: selected_location,
            category: reset ? '' : expenseFilter?.category,
            status: reset ? '' : expenseFilter?.status,
        }
        const success = () => { setNoPaginationLoader(false) }
        const fail = () => { setNoPaginationLoader(false) }
        dispatch(getExpenseNoPagination(access_token, payload, success, fail))
    }

    const onExpenseDelete = () => {
        setDeleteLoading(true)
        const success = () => {
            setDeleteLoading(false)
            setDeletePopup()
            getAllExpenses()
            getAllExpensesNoPagination()
        }
        const fail = () => { setDeleteLoading(false) }
        dispatch(deleteExpense(access_token, deletePopup, success, fail))
    }

    useEffect(() => {
        if (activeTab == 'Expense') {
            getAllExpenses()
            getAllExpensesNoPagination()
        }
    }, [startDate, currentPage, selected_location, endDate, activeTab])

    useEffect(() => {
        setEmployee(Userstate?.user?.user?.employee);
        setEmployeePermissions(Userstate?.user?.permissions);
    }, [Userstate?.user, employee, employeePermissions]);


    let headers = [
        {
            label: "ID",
            key: "id"
        },
        {
            label: "Date",
            key: "date"
        },
        {
            label: "Category",
            key: "category"
        },
        {
            label: "Total Amount",
            key: "total_amount"
        },
        {
            label: "Due Amount",
            key: "due_amount"
        },
        {
            label: "Status",
            key: "status"
        },
        {
            label: "Added By",
            key: "added_by"
        },
    ];


    const csvReport = {
        filename: "Expense Report.csv",
        headers: headers,
        data: noPaginationExpense?.map(itm => {
            return {
                ...itm,
                id: itm?.id ? itm?.id : "--------",
                date: itm?.date ? itm.date : "--------",
                category: itm?.expense_category_name ? itm.expense_category_name : "--------",
                total_amount: itm?.total_amount ? itm?.total_amount : "0",
                due_amount: itm?.due_amount ? itm?.due_amount : "0",
                status: itm?.status ? itm?.status == 'Paid' ? 'Active' : 'Inactive' : "--------",
                added_by: itm?.user_name ? itm?.user_name : "--------",
            };
        }),
    };

    const handleExpenseExportClick = () => {
        if (!isToastVisible) {
            if (noPaginationExpense?.length > 0) {
                // setExportLoader(true)
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                    setExportLoader(false)
                }, 300);
            } else {
                toast.error("No data to export", {
                    toastId: "toast",
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };
    // ==================================== Expense Table Data ==================================== //



    // ==================================== Expense Category Table Data ==================================== //
    const [addCategoryPopup, setAddCategoryPopup] = useState(false)
    const [expenseCategoryLoader, setExpenseCategoryLoader] = useState(true)
    const [expenseCategoryCurrentPage, setExpenseCategoryCurrentPage] = useState(1)
    const [expenseCategoryDeleteLoading, setExpenseCategoryDeleteLoading] = useState(false)

    const expenseCategory = state?.expense?.expenseCategory
    const expenseCategoryPagination = state?.expense?.expenseCategoryPagination

    const getExpensesCategories = (page) => {
        setExpenseCategoryLoader(true)
        const payload = {
            page: page ? page : expenseCategoryCurrentPage,
        }
        const success = () => { setExpenseCategoryLoader(false) }
        const fail = () => { setExpenseCategoryLoader(false) }
        dispatch(getExpenseCategory(access_token, payload, success, fail))
    }

    const onExpenseCategoryDelete = () => {
        setExpenseCategoryDeleteLoading(true)
        const success = () => {
            setExpenseCategoryDeleteLoading(false)
            setDeletePopup()
            getExpensesCategories()
            getAllExpensesCategories()
        }
        const fail = () => { setExpenseCategoryDeleteLoading(false) }
        dispatch(deleteExpenseCategory(access_token, deletePopup, success, fail))
    }

    useEffect(() => {
        if (activeTab == 'Expense Category') {
            getExpensesCategories()
        }
    }, [activeTab, expenseCategoryCurrentPage])

    useEffect(() => {
        setExpenseCategoryLoader(true)
    }, [expenseCategoryCurrentPage])
    // ==================================== Expense Category Table Data ==================================== //

    const getAllExpensesCategories = () => {
        setExpenseCategoryLoader(true)
        const payload = {
            no_pagination: true
        }
        const success = () => { setExpenseCategoryLoader(false) }
        const fail = () => { setExpenseCategoryLoader(false) }
        dispatch(getExpenseCategoryNoPagination(access_token, payload, success, fail))
    }

    useEffect(() => {
        setLoader(true)
        setExpenseCategoryLoader(true)
        setCurrentPage(1)
        setExpenseCategoryCurrentPage(1)
    }, [activeTab])

    useEffect(() => {
        getAllExpensesCategories()
    }, [])

    return {
        startDate, setStartDate, endDate, setEndDate, toggleDateRange, setToggleDateRange, FilterPopup, setFilterPopup, addCategoryPopup, setAddCategoryPopup, addExpensePopup,
        setAddExpensePopup, translation, selectedLanguage, expenses, loader, getAllExpenses, onExpenseDelete, deletePopup, setDeletePopup, deleteLoading, isEdit, setIsEdit,
        editData, setEditData, expensePagination, currentPage, setCurrentPage, expenseFilter, expenseCategory, setExpenseFilter, activeTab, setActiveTab, expenseCategoryLoader,
        setExpenseCategoryCurrentPage, expenseCategoryCurrentPage, expenseCategoryPagination, getExpensesCategories, expenseCategoryDeleteLoading, onExpenseCategoryDelete,
        setExpenseCategoryLoader, getAllExpensesCategories, allExpenseCategory, getAllExpensesNoPagination, exportLoader, handleExpenseExportClick, csvReport, noPaginationExpense,
        employee
    }

}

export default useExpense