import React, { useState, useEffect, useRef } from "react";

import Svgs from "Assets/svgs";
import { error_message } from "Constants/Variables";

const LoginInput = ({
  onEnterSubmit,
  rightIcon,
  leftIcon,
  title,
  required,
  type,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  parentClass,
  min,
  max,
  specialCharacterNotAllowed,
  rightIconClassName,
  onlyNumericAllowed,
  noBorder,
  charactersMaxLength,
  inputPadding,
  readOnly,
  isCapitalized,
  capatalizeInput,
  errorClass
}) => {
  const [sanitizedValue, setSanitizedValue] = useState('');
  const inputRef = useRef(null);
  const parsedPlaceholder = isCapitalized ? placeholder : placeholder && placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

  useEffect(() => {
    // Sanitize the input value based on the specified type
    if (value) {
      if (type === "number") {
        setSanitizedValue(typeof value == 'string' ? Math.abs(value?.replace(/[^0-9]/g, "")) : value);
      } else {
        setSanitizedValue(value);
      }
    } else {
      setSanitizedValue("");
    }
  }, [value, type]);

  useEffect(() => {
    try {
      // Preserve cursor position in the input field
      if (sanitizedValue != '') {
        if (inputRef.current && inputRef.current.selectionStart !== null && inputRef.current.selectionEnd !== null) {
          const startPosition = inputRef.current.selectionStart;
          const endPosition = inputRef.current.selectionEnd;

          inputRef.current.value = sanitizedValue;

          if (startPosition !== null && endPosition !== null) {
            inputRef.current.setSelectionRange(startPosition, endPosition);
          }
        }
      }
    } catch (error) {
      // Handle the error
      // console.warn("An error occurred:", error);
    }
  }, [sanitizedValue, value]);

  const handleInputChange = (e) => {
    let inputValue = '';
    if (charactersMaxLength) {
      inputValue = e.target.value?.slice(0, charactersMaxLength)
      e.target.value = inputValue
    }

    // Convert small alphabet to capital
    if (capatalizeInput) {
      inputValue = e.target.value?.replace(/[a-z]/g, (match) => match?.toUpperCase());
      e.target.value = inputValue
    }

    // regex for only numbers allowed
    if (onlyNumericAllowed) {
      inputValue = e.target.value?.replace(/\D/g, '')
      e.target.value = inputValue
    }

    // regex to remove special characters
    if (specialCharacterNotAllowed) {
      inputValue = e.target.value?.replace(/[^a-zA-Z0-9\s]/g, '');
      e.target.value = inputValue
    }

    let currentPosition = 0;
    try {
      // Check if inputRef, inputRef.current, and selectionStart are supported
      if (inputRef?.current && 'selectionStart' in inputRef.current) {
        currentPosition = inputRef.current.selectionStart || 0;
      }
    } catch (error) {
      // Handle any potential error if accessing selectionStart fails
      console.error("Error accessing selectionStart:", error);
      // Set currentPosition to a default value, for example 0
      currentPosition = 0;
    }

    // Update the sanitizedValue state based on the input value
    if (e.target.value !== "") {
      if (type === "number") {
        setSanitizedValue(typeof e.target.value == 'string' ? Math.abs(e.target.value.replace(/[^0-9]/g, "")) : e.target.value);
      } else {
        setSanitizedValue(e.target.value);
      }
    } else {
      setSanitizedValue(e.target.value);
    }

    // Call the external onChange handler (if provided)
    if (typeof onChange === "function") {
      onChange(e);
    }

    // Restore the cursor position
    try {
      if (
        inputRef?.current &&
        'selectionStart' in inputRef.current &&
        'setSelectionRange' in inputRef.current
      ) {
        currentPosition = inputRef.current.selectionStart || 0;
        const maxLength = inputRef.current.value.length;

        // Ensure the currentPosition is within the bounds of the input text length
        currentPosition = Math.min(currentPosition, maxLength);

        // Set the selection range if the input has focus
        if (inputRef.current === document.activeElement) {
          inputRef.current.setSelectionRange(currentPosition, currentPosition);
        }
      }
    } catch (error) {
      // Handle any potential error if accessing or setting selection properties fails
    }
  };

  // old onChange function before date issue on safari
  // const handleInputChange = (e) => {
  //   const currentPosition = inputRef.current.selectionStart;

  //   // Update the sanitizedValue state based on the input value
  //   if (type === "float") {
  //     const sanitized = e.target.value?.replace(/[^\d.]/g, "")?.replace(/(\..*)\./g, '$1')?.replace(/^(\d+\.\d*)\..*/, '$1');
  //     setSanitizedValue(Math.abs(sanitized));
  //   } else if (type === "number") {
  //     setSanitizedValue(typeof value == 'string' ? Math.abs(value.replace(/[^0-9]/g, "")) : value);
  //   } else {
  //     setSanitizedValue(e.target.value);
  //   }

  //   // Call the external onChange handler (if provided)
  //   if (typeof onChange === "function") {
  //     onChange(e);
  //   }

  //   // Restore the cursor position
  //   if (currentPosition !== null) {
  //     inputRef.current.setSelectionRange(currentPosition, currentPosition);
  //   }
  // };



  const HandleKeyPress = (e) => {
    // regular expression pattern for special characters
    const specialCharsPattern = /[0123456789=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥""¢''/`"'\[\]:|;<>,.?~\\-]/g;
    // Check your condition here to disallow special characters
    if (specialCharacterNotAllowed && specialCharsPattern.test(e.key)) {
      e.preventDefault(); // Prevent typing of special characters
    }

    // regular expression pattern for only numeric characters
    const onlyNumericCharsAllowed = /[a-zA-Z=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥""¢''/`"'\[\]:|;<>,.?~\\-]/g;
    // Check your condition here to disallow special characters
    if (onlyNumericAllowed && onlyNumericCharsAllowed.test(e.key) && e.key != "Backspace") {
      // If input doesn't match, prevent the default behavior (e.g., typing the invalid character)
      e.preventDefault();
    }

    if (type === 'number' && e.key === '-') {
      e.preventDefault(); // Prevent typing of '-' symbol
    }

    if (e.key.toLowerCase() == "enter" && !disabled) {
      onEnterSubmit();
    }
  };

  useEffect(() => {
    // Use setTimeout to ensure that the blur occurs after the initial render
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }, 0);

    window.scrollTo({ top: 0 });
    return () => clearTimeout(timeoutId);

  }, []);

  return (
    <div className={`${parentClass ? parentClass : ""} flex flex-col gap-2 relative `}>
      {title != "" && (
        <div className="text-sm flex items-center gap-1">
          <div>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
        </div>
      )}

      {rightIcon ? (
        <div className="w-full relative">
          <input
            ref={inputRef}
            onClick={(e) => {
              if (type === 'date' && typeof e.target.showPicker === 'function') {
                e.target.showPicker();
              }
            }}
            onKeyDown={HandleKeyPress}
            disabled={disabled}
            type={type ? type : "text"}
            placeholder={type == 'date' ? "yyyy-mm-dd" : parsedPlaceholder}
            name={name}
            value={sanitizedValue}
            min={min}
            max={max}
            maxLength={charactersMaxLength}
            onChange={handleInputChange}
            // onBlur={onBlur}
            className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
              } input w-full lg:pr-8 pr-7 ${inputPadding && inputPadding}`}
          />
          <div className={`${rightIconClassName} absolute top-1/2 -translate-y-1/2 right-3 text-sm input-right-icon`}>
            {rightIcon}
          </div>
        </div>
      ) : leftIcon ? (
        <div className="w-full relative">
          <div className="absolute top-1/2 -translate-y-1/2 left-3">
            {leftIcon}
          </div>
          <input
            onClick={(e) => {
              if (type === 'date' && typeof e.target.showPicker === 'function') {
                e.target.showPicker();
              }
            }}
            ref={inputRef}
            onKeyDown={HandleKeyPress}
            disabled={disabled}
            type={type ? type : "text"}
            placeholder={type == 'date' ? "yyyy-mm-dd" : parsedPlaceholder}
            name={name}
            value={sanitizedValue}
            min={min}
            max={max}
            maxLength={charactersMaxLength}
            onChange={handleInputChange}
            // onBlur={onBlur}
            className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
              } input w-full lg:pl-8 !pl-7 ${inputPadding && inputPadding}`}
          />
        </div>
      ) : (
        <input
          ref={inputRef}
          onClick={(e) => {
            if (type === 'date' && typeof e.target.showPicker === 'function') {
              e.target.showPicker();
            }
          }}
          onKeyDown={HandleKeyPress}
          min={min}
          max={max}
          readOnly={readOnly}
          maxLength={charactersMaxLength}
          disabled={disabled}
          type={type ? type : "text"}
          placeholder={type == 'date' ? "yyyy-mm-dd" : parsedPlaceholder}
          name={name}
          value={sanitizedValue}
          onChange={handleInputChange}
          // onBlur={onBlur}
          className={`${noBorder && '!border-0'} ${error && "!border-l-[0.375rem] !border-[#eb3b3b]"} input ${inputPadding && inputPadding}`}
        />
      )}
      {error && typeof error == "string" ? (
        <p className={`text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
          <Svgs.I fill="#eb3b3b" />
          {error}
        </p>
      ) : typeof error == "boolean" && error == true ? (
        <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
          <Svgs.I fill="#eb3b3b" /> {error_message}
        </p>
      ) : (
        ""
      )}
      {/* {error && <span className='text-[#eb3b3b] flex items-center gap-2 text-xs'>{error}<Svgs.I /></span>} */}
    </div>
  );
};

LoginInput.defaultProps = {
  title: 'Enter "title" prop',
  placeholder: 'Enter "placeholder" prop',
  type: "text",
  required: true,
  value: "",
  onEnterSubmit: () => { },
  onBlur: () => { }
};

export default LoginInput;
