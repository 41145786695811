import LoginBtn from "Components/TenantApp/Elements/LoginBtn"
import Popup from "Components/TenantApp/Elements/Popup"
import { useEffect, useState } from "react"
import { useSearchParams, useLocation, useNavigate } from "react-router-dom"

import { getUserDefaultData, UpdateUserBusinessDefaultData } from '../../../Adapters/Api/businessLocationInNstyleApp'
import LoginInput from "Components/TenantApp/Elements/LoginInput"
import { toast } from "react-toastify"
import AccountSetupPopup from "Components/TenantApp/Dashboard/AccountSetupPopup/AccountSetupPopup"

const AskToUpdateDataPopup = ({ closePopup }) => {
    const [formData, setFormData] = useState({})
    const [btn_loading, setBtnLoading] = useState(false)

    const onClosePopup = () => {
        closePopup && closePopup()
    }

    const handleChange = (e, module_name) => {
        let { name, value } = e.target

        setFormData({
            ...formData,
            [module_name]: {
                ...formData[module_name],
                [name]: value
            }
        })
    }

    const onUpdateChanges = () => {
        setBtnLoading(true)
        UpdateUserBusinessDefaultData(
            {
                location: formData.location?.name,
                client: formData.client?.name,
                employee: formData.employee?.name,
                service: formData.service,
            },
            () => {
                toast.success('Data updated successfully', { toastId: "toast" })
                onClosePopup()
                setBtnLoading(false)
            },
            () => {
                setBtnLoading(false)
            }
        )
    }

    const getBusinessDefaultData = () => {
        let business_data = localStorage.getItem('business_data')
        business_data = JSON.parse(business_data)
        getUserDefaultData(
            {
                business_id: business_data?.id
            },
            (result) => {
                if (result) {
                    setFormData(result)
                }
            }
        )
    }


    useEffect(() => {
        getBusinessDefaultData()
    }, [])

    return (
        <>
            <Popup
                heading={"Verify your account information"}
                size={"md"}
                open={true}
                close={onClosePopup}
            >
                <div className="flex flex-col gap-4">
                    <div>
                        <p>We have created some essential data to complete your account, if you want to change any parameter you can update.</p>
                    </div>

                    <div className="flex flex-col gap-3">
                        <div className="flex justify-between gap-3">
                            <div className="flex-1">
                                <LoginInput
                                    placeholder={'Location Name'}
                                    title={'Location Name'}
                                    value={formData?.location?.name}
                                    name={'name'}
                                    onChange={(e) => { handleChange(e, 'location') }}
                                ></LoginInput>
                            </div>
                            <div className="flex-1">
                                <LoginInput
                                    placeholder={'Employee Name'}
                                    title={'Employee Name'}
                                    name={'name'}
                                    value={formData?.employee?.name}
                                    onChange={(e) => { handleChange(e, 'employee') }}

                                ></LoginInput>
                            </div>
                        </div>

                        <div className="flex justify-between gap-3">
                            {
                                formData?.service?.map((service_obj, index) => {
                                    return (
                                        <div className="flex-1">
                                            <LoginInput
                                                placeholder={'Service Name'}
                                                title={'Service Name'}
                                                name={'name'}
                                                value={service_obj?.name}
                                                onChange={(e) => {
                                                    let prev = {
                                                        ...formData
                                                    }
                                                    console.log(prev?.service)
                                                    let this_instance = prev?.service?.find(itm => itm.id == service_obj.id)
                                                    if (this_instance) {
                                                        this_instance.name = e.target.value
                                                        setFormData(prev)
                                                    }
                                                }}
                                            ></LoginInput>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="flex justify-between gap-3">
                            <div className="flex-1">
                                <LoginInput
                                    placeholder={'Client Name'}
                                    title={'Client Name'}
                                    name={'name'}
                                    value={formData?.client?.name}
                                    onChange={(e) => { handleChange(e, 'client') }}
                                ></LoginInput>
                            </div>
                        </div>


                    </div>
                    <div className="flex justify-end">
                        <LoginBtn
                            loading={btn_loading}
                            onClick={onUpdateChanges}
                            disabled={(
                                !formData?.location?.name ||
                                formData?.service?.find(itm => !itm.name) ||
                                !formData?.client?.name ||
                                !formData?.employee?.name
                            ) ? true : false}
                        >
                            Update
                        </LoginBtn>

                    </div>
                </div>
            </Popup>
        </>
    )
}

const AskToUpdateData = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const [showPopup, setShowPopup] = useState(false)

    useEffect(() => {
        let ask_for_change_default_data = searchParams.get('ask_for_change_default_data')
        if (ask_for_change_default_data) {
            let params = '?'
            searchParams.forEach((value, key) => {
                if (key != 'ask_for_change_default_data') {
                    params += `${key}=${value}`
                }
            })
            setShowPopup(true)
            localStorage.setItem("callGetDefaultData", true)
            navigate(`${location.pathname}${params}`)
        } else {
            if (ask_for_change_default_data) {
                localStorage.setItem("callGetDefaultData", true)
                setShowPopup(true)
            }
        }
    }, [])

    return (
        <>
            {
                showPopup &&
                <AccountSetupPopup
                    closePopup={() => {
                        setShowPopup(false)
                    }}
                />
            }
        </>
    )
}

export default AskToUpdateData