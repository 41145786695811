import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import CheckBox from "../../../../../Elements/Checkbox/CheckBox";
import Dropwdown from "../../../../../Elements/Dropwdown";
import LoginBtn from "../../../../../Elements/LoginBtn";
import LoginInput from "../../../../../Elements/LoginInput";
import Popup from "../../../../../Elements/Popup";

import { get_business_brands } from "Adapters/Api/Brand";
import { getAllCategories } from "Adapters/Api/categories";
import { getVendorsList } from "Adapters/Api/vendor";

import Svgs from "Assets/svgs";
import IconButton from "Components/TenantApp/Elements/IconButton";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import { useLocation } from "new-components/location/helper";

const FilterPopup = ({ onClose, filter_data, setFilterData, ApplyFilter }) => {
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const [data, setData] = useState({});


  const handle_change = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    if (!state.brand.brands_updated) {
      dispatch(get_business_brands());
    }
    if (!state?.vendors?.vendors_updated) {
      dispatch(getVendorsList());
    }
    if (!state.category.categories_updated) {
      dispatch(getAllCategories());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(filter_data).length > 0) {
      setData(filter_data);
    }
  }, []);



  return (
    <Popup heading="Filters" close={onClose} open={true}>
      <div className="flex flex-col gap-6">
        <Dropwdown
          value={data.vendor}
          title="Vendors"
          placeholder="Select Vendors"
          name={"vendor"}
          options={[
            ...state.vendors.vendors.map((vendor) => {
              return {
                value: vendor.id,
                label: vendor.vendor_name,
              };
            }),
          ]}
          onChange={handle_change}
        />
        <Dropwdown
          title="Brand"
          value={data.brand}
          placeholder="Select Brand"
          options={[
            ...state.brand.brands.map((brand) => {
              return {
                value: brand.id,
                label: brand.name,
              };
            }),
          ]}
          name={"brand"}
          onChange={handle_change}
        />
        <Dropwdown
          title="Category"
          placeholder="Select Category"
          options={[
            ...state.category.categories.map((category) => {
              return {
                value: category.id,
                label: category.name,
              };
            }),
          ]}
          name={"category"}
          value={data.category}
          onChange={handle_change}
        />
        <LoginInput
          required={false}
          title="Vendors Name"
          placeholder="Enter Vendors Name"
          name={"vendor_name"}
          value={data.vendor_name}
          onChange={handle_change}
        />

        <div className="flex flex-col gap-3">
          <h2 className="font-semibold">Stock</h2>
          <CheckBox
            label="Low Stock"
            checked={data.low_stock}
            onChange={(e) => {
              setData({ ...data, low_stock: e.target.checked });
            }}
          />
          <CheckBox
            label="High Stock"
            checked={data.high_stock}
            onChange={(e) => {
              setData({ ...data, high_stock: e.target.checked });
            }}
          />
        </div>

        <div className="flex flex-col gap-3">
          <h2 className="font-semibold">Top Selling Items</h2>
          <CheckBox
            label="Top Sellable Items"
            checked={data.top_sellable_items}
            onChange={(e) => {
              setData({ ...data, top_sellable_items: e.target.checked });
            }}
          />
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="font-semibold">Date Range</h2>
          <div className="flex items-center gap-5">
            <div className="flex-1">
              <div className="border rounded-lg p-3">
                <input
                  type="date"
                  placeholder="asudhas"
                  className="outline-none w-full text-[#7B7B7B]"
                  name="start_date"
                  onChange={handle_change}
                  value={data.start_date}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="border rounded-lg p-3">
                <input
                  type="date"
                  placeholder="asudhas"
                  className="outline-none w-full text-[#7B7B7B]"
                  name="end_date"
                  onChange={handle_change}
                  value={data.end_date}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-start mt-[2rem]">
          <LoginBtn
            text="Apply Filters"
            disabled={Object.keys(data).length == 0}
            onClick={() => {
              ApplyFilter(data);
            }}
          />
        </div>
      </div>
    </Popup>
  );
};

const StockFilter = ({ allStockData, filter_data, setFilterData, selected_location, setSearchText }) => {
  const state = useSelector((state) => state);
  const locations = state?.locations?.locations;

  const { locationDetail } = useLocation();

  const [Filters, setFilters] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  const ApplyFilter = (data) => {
    setFilterData({
      ...filter_data,
      ...data,
    });
    setFilters(false);
  };
  let headers = [
    { label: selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name", key: "name" },
    { label: selectedLanguage?.name == "English" ? "Current Stock" : translation[998]?.value ? translation[998]?.value : "Current Stock", key: "current_stock" },
    { label: selectedLanguage?.name == "English" ? "Sold Stock" : translation[1110]?.value ? translation[1110]?.value : "Sold Stock", key: "sold_stock" },
    { label: selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price", key: "price" },
    { label: selectedLanguage?.name == "English" ? "Consumed" : translation[1000]?.value ? translation[1000]?.value : "Consumed", key: "consumed" },
    { label: selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status", key: "status_text" },
    { label: selectedLanguage?.name == "English" ? "Transfer" : translation[1111]?.value ? translation[1111]?.value : "Transfer", key: "transfer" },
    { label: selectedLanguage?.name == "English" ? "Sale Status" : translation[1113]?.value ? translation[1113]?.value : "Sale Status", key: "sale_status" },
    { label: selectedLanguage?.name == "English" ? "Turnover" : translation[1114]?.value ? translation[1114]?.value : "Turnover", key: "turnover" }
  ];


  const csvReport = {
    filename: "Stock Records.csv",
    headers: headers,
    data: allStockData?.map((itm) => {
      let price = itm?.currency_retail_price.find((obj) => obj?.currency == locationDetail?.currency?.id)
      return {
        ...itm,
        name: itm?.name ? itm.name : "--------",
        current_stock: itm?.stock[0]?.available_quantity || "0",
        sold_stock: itm?.stock[0]?.sold_quantity || "0",
        price: `${price?.retail_price || "0"} ${locationDetail?.currency?.code}`,
        consumed: itm?.total_consumption,
        status_text: itm?.stock[0]?.status ? "active" : "inActive",
        transfer: itm?.total_transfer,
        sale_status: itm?.stock[0]?.status_text,
        turnover: itm?.stock[0]?.turnover,
      };
    }),
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allStockData?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return (
    <>
      <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
        <div className="mb-00">
          {loadingPermission ?
            <Shimmer className={'w-32 lg:w-36'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            :
            <LoginInput
              title=""
              placeholder={selectedLanguage?.name == "English" ? "Search product" : translation[1265]?.value ? translation[1265]?.value : "Search product"}
              padding="py-1"
              value={filter_data.text}
              leftIcon={<Svgs.Search />}
              onChange={(e) => {
                setSearchText(e.target.value)
                // if (e.target.value == "") {
                //   let new_data = {
                //     ...filter_data,
                //   };
                //   delete new_data["text"];
                //   setFilterData(new_data);
                // } else {
                //   setFilterData({ ...filter_data, text: e.target.value });
                // }
              }}
            />
          }
        </div>
        {/* <LoginBtn
          borderBtn
          
          onClick={() => {
            setFilters(!Filters);
          }}
        >
          <div className="flex items-center gap-2 text-primary">
            <Svgs.Filters />
            <p>Filters</p>
          </div>
        </LoginBtn> */}
        {loadingPermission ?
          <Shimmer className={'w-12 lg:w-16'}>
            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
          </Shimmer>
          : exportLoader ? (
            <div className="h-[4rem] flex items-center justify-center">
              <PreloaderSm />
            </div>
          ) : state?.product?.stocks?.length > 0 ?
            <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
              <LoginBtn
                className="rounded-lg !py-[7px]"
                bg="bg-white"
                animation={false}
                customThemeBtn={'theme-btn2'}
              >
                <div className="flex items-center gap-2 text-[#FFFFFF]">
                  <Svgs.ExportSvg />
                </div>
              </LoginBtn>
            </CSVLink>
            :
            <LoginBtn
              className="rounded-lg !py-[7px]"
              bg="bg-white"
              animation={false}
              onClick={handleExportClick}
              customThemeBtn={'theme-btn2'}
            >
              <div className="flex items-center gap-2 text-[#FFFFFF]">
                <Svgs.ExportSvg />
              </div>
            </LoginBtn>
        }
      </div>
      {Filters && (
        <FilterPopup
          ApplyFilter={ApplyFilter}
          onClose={() => {
            setFilters(false);
          }}
          setFilterData={setFilterData}
          filter_data={filter_data}
        />
      )}
    </>
  );
};

export default StockFilter;
