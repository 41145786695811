import useObjectKeys from 'Hooks/useObjectKeys'
import useValidations from 'Hooks/useValidations'
import { useState, useEffect } from 'react'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { createComplaintCategoryAPI, updateComplaintCategoryAPI } from 'Adapters/Api/complaints'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'

const initialFormData = {
    name: '',
    status: 'active'
}

const useCreateComplaintCategoryPopup = (close, open, onSucessCallBack) => {
    const dispatch = useDispatch()
    const { errors, setErrors, validation } = useValidations()
    const { objectKeyConvertToArray } = useObjectKeys()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const [submitLoading, setSubmitLoading] = useState(false)
    const [formData, setFormData] = useState(initialFormData)

    const onChangeHandler = (e) => {
        const { name, value } = e.target
        setErrors({ ...errors, [name]: '' })
        setFormData({ ...formData, [name]: value })
    }

    const onSubmit = () => {
        const validationResult = validation(formData)
        setErrors(validationResult)

        if (objectKeyConvertToArray(validationResult).length == 0) {
            const success = () => {
                setFormData(initialFormData)
                setSubmitLoading(false)
                onSucessCallBack()
                close(false)
            }
            const fail = () => {
                setSubmitLoading(false)
            }
            setSubmitLoading(true)
            open?.id
                ? dispatch(updateComplaintCategoryAPI(formData, access_token, success, fail))
                : dispatch(createComplaintCategoryAPI(formData, access_token, success, fail))
        }
    }

    const statusOptions = useMemo(() => [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
    ], [])

    useEffect(() => {
        if (open?.id) {
            setFormData({ ...open })
        } else {
            setErrors({})
            setFormData(initialFormData)
        }
        setSubmitLoading(false)
    }, [open])

    return {
        submitLoading, formData, onChangeHandler, onSubmit, statusOptions, errors
    }
}

export default useCreateComplaintCategoryPopup