import React from 'react'
import SalesReports from '../../Reports/SalesReports'
import useClientSaleHistoryHelper from './helper';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import Shimmer from 'Components/Utility/Shimmers/Shimmer';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';

const ClientSaleHistory = () => {
    const { clientId, clientData, loading } = useClientSaleHistoryHelper()
    console.log(clientData, "clientData")
    return (
        loading ?
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative bg-primary-background">
                <div className='animate-pulse'>
                    <Breadcrumb
                        loading={true}
                    />
                    <div className="flex items-center justify-between sm:gap-4 gap-2 my-[1rem]">
                        <div className='bg-gray-300 rounded-md w-40 h-8'></div>
                        <div className='bg-gray-300 rounded-md w-28 h-10'></div>
                    </div>
                    <div className={"border-b my-[2rem]"}>
                        <div className="flex items-center gap-6">
                            {[...Array(5)].map((_, index) => (
                                <div key={index} className="w-24 h-6 bg-gray-300 rounded animate-pulse"></div>
                            ))}
                        </div>
                    </div>
                    <div className="rounded-[10px] bg-white cardShadow">
                        <div className="px-4 lg:px-6 py-[21px] flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                            <div className="flex md:flex-row flex-col md:items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                <div className="flex items-center sm:gap-4 gap-2 ">
                                    <div className='bg-gray-300 rounded-md w-40 h-8'></div>
                                    <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                        <p className="rounded-md h-3 w-12 mx-1"> </p>
                                    </div>
                                </div>
                                <div className="flex gap-2 items-center flex-wrap justify-end">
                                    <Shimmer className={'w-40 lg:w-44'}>
                                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                                    </Shimmer>
                                    <Shimmer className={'w-40 lg:w-44'}>
                                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                                    </Shimmer>
                                    <Shimmer className={'w-40 lg:w-44'}>
                                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                                    </Shimmer>
                                </div>
                            </div>
                        </div>
                        <TableShimer cols={7} rows={10} />
                    </div>
                </div>
            </div>
            :
            <SalesReports clientId={clientId} clientData={clientData} />
    )
}

export default ClientSaleHistory
