import React, { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import LoginInput from "./LoginInput";

const AutoPlaceInput = ({ onChange, name, value, error, placeholder, title, onEnterSubmit }) => {
    const [address, setAddress] = useState("");
    const [inputFocus, setInputFocus] = useState(false); // Track focus state

    useEffect(() => {
        if (value) {
            setAddress(value);
        }
    }, [value]);

    const handleAddressSuggestion = async (value) => {
        const result = await geocodeByAddress(value);
        setAddress(value);

        const event = {
            target: {
                name: name ? name : "address",
                value: value,
            },
        };
        if (onChange) {
            onChange(event);
        }
    };

    const handleBlur = () => {
        setInputFocus(false); // Mark input as blurred
        // Clear address if no suggestion was selected
        if (!address.trim()) {
            setAddress("");
            const event = {
                target: {
                    name: name ? name : "address",
                    value: '',
                },
            };
            if (onChange) {
                onChange(event);
            }
        }
    };

    return (
        <PlacesAutocomplete
            value={address}
            onChange={(value) => {
                setAddress(value);
                setInputFocus(true); // Mark input as active on change
                if (value?.length == 0) {
                    const event = {
                        target: {
                            name: name ? name : "address",
                            value: '',
                        },
                    };
                    if (onChange) {
                        onChange(event);
                    }
                }
            }}
            onSelect={(value) => {
                handleAddressSuggestion(value);
                setInputFocus(false); // Mark input as completed
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <LoginInput
                        {...getInputProps({
                            onBlur: handleBlur, // Detect blur to handle the logic
                            onFocus: () => setInputFocus(true),
                        })}
                        required
                        className="login-input"
                        placeholder={placeholder}
                        error={error}
                        title={title}
                        onEnterSubmit={onEnterSubmit}
                    />

                    {inputFocus && suggestions.length > 0 && (
                        <div className="autocomplete-dropdown-container border rounded-md mt-2">
                            {loading && (
                                <div className="rounded-md p-2 text-xs text-[#555555]">
                                    Loading...
                                </div>
                            )}
                            {suggestions.map((suggestion) => {
                                const className =
                                    "rounded-md p-2 text-xs hover:!bg-gray-100 text-[#555555]";
                                const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" };

                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default AutoPlaceInput;
