import axios from "axios";
import { BaseUrlWithoutV1, get_all_blogs_url, get_blogs_categories_url, get_single_blogs_url } from "../api_variables";
import { buildQueryString } from "Constants/Functions/buildQueryString";

export const getBlogsCategories = async (success, fail) => {
    try {
        const result = await axios.get(`${BaseUrlWithoutV1}${get_blogs_categories_url}`);
        success?.(result?.data?.data || [])
        return result;
    } catch (error) {
        fail?.()
        return error;
    }
};

export const getAllBlogs = async (payload, success, fail) => {
    try {
        const query = buildQueryString(payload)
        const response = await axios.get(`${BaseUrlWithoutV1}${get_all_blogs_url}${query}`);
        success?.(response?.data)
        return response;
    } catch (error) {
        fail?.()
        return error;
    }
};

export const getSingleBlog = async (payload, success, fail) => {
    try {
        const response = await axios.get(`${BaseUrlWithoutV1}${get_single_blogs_url}${payload?.slug}`);
        success?.(response?.data)
        return response;
    } catch (error) {
        fail?.()
        return error;
    }
};