import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'

const ExpenseFilterPopup = ({ open, close, setCurrentPage, selectedLanguage, getAllExpenses, getAllExpensesNoPagination, expenseCategory, setExpenseFilter, data, translation }) => {
    return (
        <>
            <Popup
                heading={selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                open={open}
                close={close}
            >
                <div className="flex flex-col gap-5">
                    <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                        <CircleIcon>
                            <Svgs.FilterPopup />
                        </CircleIcon>
                        <div className="flex flex-col gap-1 lg:text-left text-center">
                            <h2 className="text-primary font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Search By Filters" : translation[343]?.value ? translation[343]?.value : "Search By Filters"}
                            </h2>
                            <p className="text-[#7B7B7B] text-sm">
                                {selectedLanguage?.name == "English" ? "Explore tailored results by applying various filters to enhance your sales search." : translation[344]?.value ? translation[344]?.value : "Explore tailored results by applying various filters to enhance your sales search."}
                            </p>
                        </div>
                    </div>
                    <div className="grid xs:grid-cols-2 gap-5">
                        <div>
                            <Dropwdown
                                title={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Category" : translation[889]?.value ? translation[889]?.value : "Select Category"}
                                name={'category'}
                                onChange={(e) => setExpenseFilter((prev) => ({ ...prev, [e.target.name]: [e.target.value] }))}
                                value={data?.category}
                                options={expenseCategory?.map(itm => {
                                    return {
                                        label: itm?.name,
                                        value: itm?.id
                                    }
                                })}
                            />
                        </div>
                        <div>
                            <Dropwdown
                                title={selectedLanguage?.name == "English" ? "Payment Status" : translation[1748]?.value ? translation[1748]?.value : "Payment Status"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Payment Status" : translation[1749]?.value ? translation[1749]?.value : "Select Payment Status"}
                                name={'status'}
                                value={data?.status}
                                onChange={(e) => setExpenseFilter((prev) => ({ ...prev, [e.target.name]: [e.target.value] }))}
                                options={[
                                    { value: 'Due', label: 'Due' },
                                    { value: 'Paid', label: 'Paid' }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <LoginBtn
                            text={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                            onClick={() => {
                                getAllExpenses(false, 1)
                                getAllExpensesNoPagination(false, 1)
                                setCurrentPage(1)
                                close && close()
                            }}
                        />
                        <LoginBtn
                            text={selectedLanguage?.name == "English" ? "Clear filter" : translation[346]?.value ? translation[346]?.value : "Clear filter"}
                            onClick={() => {
                                setExpenseFilter({
                                    category: '',
                                    status: '',
                                })
                                setCurrentPage(1)
                                getAllExpenses('reset')
                                getAllExpensesNoPagination('reset')
                                close && close()
                            }}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default ExpenseFilterPopup