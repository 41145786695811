import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deletePurchaseOrder,
  getPurchaseOrder,
  getPurchaseOrderWithNoPagination,
} from "Adapters/Api/purchasesOrder";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_orderStock,
  getOrderStock,
  getPurchasesPages,
} from "Redux/TenantRedux/Actions/purchaseOrderActions";
import { toast } from "react-toastify";

let timeOutId = undefined
export const usePurchaseOrder = () => {
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");

  const [deleteId, setDeleteId] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [searchText, setSearchText] = useState("");
  const [ShowDetails, setShowDetails] = useState(0);
  const [OrderStatus, setOrderStatus] = useState(false);
  const [deleteClose, setDeleteClose] = useState(false);
  const [dataList, setDataList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const purchasesOrderList = state?.purchases?.orderStock;
  const allPurchases = state?.purchases?.allPurchases;
  const allCount = state?.purchases?.allCount
  const purchasePages = state?.purchases?.pages
  const { selected_location } = state?.locations;

  const getPurchaseOrderList = async (selected_location, currentPage, searchText) => {
    const result = await getPurchaseOrder(selected_location, currentPage, searchText, setLoader, access_token);
    dispatch(getOrderStock(result?.data?.response?.stocks));
    dispatch(getPurchasesPages(result?.data))
  };

  const handleDeleteOrder = async () => {
    setIsDelete(true);
    const response = await deletePurchaseOrder(deleteId, access_token);
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      if (!isToastVisible) {
        toast.success(successMessage, {
          onClose: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
      setIsDelete(false);
      setDeleteClose(false);
      setCurrentPage(1)
      dispatch(getPurchaseOrderList(selected_location, 1, searchText))
      dispatch(getPurchaseOrderWithNoPagination(selected_location, searchText, setNoPaginationLoader))
      // dispatch(delete_orderStock(deleteId));
    }
  };

  useEffect(() => {
    setDataList(purchasesOrderList)
  }, [purchasesOrderList?.length, purchasesOrderList, selected_location])

  // useEffect(() => {
  //   if (purchasesOrderList?.length === 0) {
  //     if (searchText === null) {
  //       // Initial load with empty searchText
  //       dispatch(getPurchaseOrderList(currentPage, ""))
  //     } else {
  //       if (timeOutId) {
  //         clearTimeout(timeOutId)
  //       }
  //       timeOutId = setTimeout(() => {
  //         dispatch(getPurchaseOrderList(currentPage, searchText))
  //       }, 500);
  //     }
  //   } else {
  //     const data = purchasesOrderList?.filter(
  //       (ele) => ele?.to_location === selected_location
  //     );

  //     setDataList(data)
  //   }
  // }, [selected_location, purchasesOrderList]);

  useEffect(() => {
    if (searchText === null) {
      // Initial load with empty searchText
      dispatch(getPurchaseOrderWithNoPagination(selected_location, "", setNoPaginationLoader))
      dispatch(getPurchaseOrderList(selected_location, currentPage, ""))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getPurchaseOrderWithNoPagination(selected_location, searchText, setNoPaginationLoader))
        dispatch(getPurchaseOrderList(selected_location, currentPage, searchText))
      }, 500);
    }
  }, [currentPage, searchText, selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchText, selected_location])

  const update_status = (productId, orderStockId) => {
    setUpdateData({ productId: productId, orderStockId: orderStockId, locationId: selected_location });
    setUpdateStatus(!updateStatus)
  }

  return {
    purchasesOrderList, ShowDetails, OrderStatus, deleteClose, searchText, isDelete, dataList, updateData,
    updateStatus, update_status, setUpdateStatus, navigate, setUpdateData, setDeleteId, setSearchText, setDeleteClose,
    setShowDetails, setOrderStatus, handleDeleteOrder, currentPage, setCurrentPage, purchasePages, allCount, allPurchases,
    loader, exportLoader, setExportLoader
  };
};
