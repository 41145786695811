import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addVendors, checkVendorExistance, deleteVendor, getVendorsList, getVendorsListWithNoPagination, updateVendor } from '../../../../../../Adapters/Api/vendor';
import useAddress from '../../../../../../Hooks/useAddress';
import useCommonHooks from '../../../../../../Hooks/useCommonHooks';
import useObjectKeys from '../../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from '../../../../../../Hooks/useSetDataInLocalStorage';
import useValidations from '../../../../../../Hooks/useValidations';
import { addVendor, removeVendor, editVendor } from '../../../../../../Redux/TenantRedux/Actions/vendorActions';
import { useEffect } from 'react';
import { useRef } from 'react';

function useVendors() {
    const formRefVendor = useRef(null);
    const stateOptions = [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
    ]
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const { setErrors, validation, errors, onBlurHandler, validateWebsiteUrl, validateEmail } = useValidations();
    const { checkMobileNumber } = useCommonHooks();

    const { objectKeyConvertToArray } = useObjectKeys();
    const dispatch = useDispatch();
    const vendorsDataRedux = useSelector((state) => state?.vendors);
    const vendorsRedux = vendorsDataRedux?.vendors;
    const pages = vendorsDataRedux?.pages
    const allCount = vendorsDataRedux?.allCount
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const verdor_id = getDataFromLocalStorage("verdor_id");

    const [isEdit, setIsEdit] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [countryId, setCountryId] = useState()
    // const [stateId, setStateId] = useState()
    const [loading, setLoading] = useState(false);
    const [vendorActive, setVendorActive] = useState();
    const [vendorSubmitLoading, setVendorSubmitLoading] = useState(false);
    const [vendorErrors, setVendorErrors] = useState(errors)
    const [search_text, setSearchText] = useState("");
    const [loader, setLoader] = useState(true)
    const [noPaginationLoader, setNoPaginationLoader] = useState(true)
    const [isToastVisible, setIsToastVisible] = useState(false);


    const [AddVendor, setAddVendor] = useState(false)
    const [vendorsData, setVedors] = useState({
        vendor_name: "", address: "",
        mobile_number: "", email: "",
        country: "", state: "",
        city: "", gstin: "",
        website: "", is_active: true
    });

    const { countriesList, stateList, setStateList } = useAddress(countryId);

    useEffect(() => {
        setVendorSubmitLoading(loading)
    }, [loading])

    useEffect(() => {
        setVendorActive(vendorsData?.is_active)
    }, [vendorsData?.is_active])


    // **************************** vendor modal toggler  *********************************
    const vendorModalToggler = () => {
        setAddVendor(!AddVendor);
        setVedors({
            vendor_name: "", address: "",
            mobile_number: "", email: "",
            country: "", state: "",
            city: "", gstin: "",
            website: "", is_active: true
        })
        setErrors()
        setCountryId()
        // setStateId()
    }

    // **************************** vendor on change handler *********************************
    const vendorHandleChange = (e, fieldName = "") => {
        if (e?.target) {
            const { name, value } = e.target;
            setVedors((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }

        if (fieldName) {
            setVedors((prev) => ({ ...prev, [fieldName]: e.target.value }));
            setErrors((prev) => ({ ...prev, [fieldName]: "" }))

            // setVedors((prev) => ({ ...prev, [fieldName]:  e }));

            if (fieldName && fieldName === "country") {
                setCountryId(e?.target?.value);
                // setCountryId(e.value);

            }

            else if (fieldName && fieldName === "state") {
                // setStateId(e.target.value)
                // setStateId(e.value)
            }
        }

    }

    useEffect(() => {
        setVendorErrors(errors)
    }, [errors])


    // **************************** vendor on submit handler *********************************
    const onVendorsSubmit = async () => {
        const {
            vendor_name, address, email, website, mobile_number
        } = vendorsData;

        let validationFields = { vendor_name, address }

        if (mobile_number) {
            const isPhoneNumber = checkMobileNumber(mobile_number);
            if (isPhoneNumber) {
                delete vendorsData.mobile_number;
            } else {
                validationFields = { ...validationFields, mobile_number }
            }
        }



        setErrors(validation(validationFields));
        let result = validation(validationFields);
        const validWebsite = website && validateWebsiteUrl(website);
        if ((objectKeyConvertToArray(result)?.length === 0) && ((website && validWebsite) || !website)) {
            let formatDataToBackend;
            if (isEdit) {
                setLoading(true)
                formatDataToBackend = {
                    ...vendorsData,
                    business: businessData.id,
                    vendor: verdor_id,
                }

                let form_data = new FormData()
                for (let key in formatDataToBackend) {
                    if (formatDataToBackend[key] || typeof formatDataToBackend[key] == "boolean") {
                        form_data.append(key, formatDataToBackend[key])
                    }

                }

                const response = await updateVendor(form_data, access_token);
                // const response = await updateVendor(formatDataToBackend, access_token);
                if (response.status === 200) {
                    setCurrentPage(1)
                    dispatch(getVendorsList(1, search_text, setLoader))
                    dispatch(getVendorsListWithNoPagination(search_text, setNoPaginationLoader))
                    let updatedVendors = response?.data?.response?.vendor;
                    let successMessage = response?.data?.response?.message;
                    // dispatch(editVendor(updatedVendors));
                    setLoading(false)
                    toast.success(successMessage, { toastId: "toast" });
                    setStateList();
                    // setCityList();
                    vendorModalToggler();
                }

            } else {
                setLoading(true)
                formatDataToBackend = {
                    ...vendorsData,
                    business: businessData.id,
                }
                let form_data = new FormData()
                for (let key in formatDataToBackend) {
                    if (formatDataToBackend[key] || typeof formatDataToBackend[key] == "boolean") {
                        form_data.append(key, formatDataToBackend[key])
                    }

                }
                const response = await addVendors(form_data, access_token);
                // const response = await addVendors(formatDataToBackend, access_token);
                if (response.status === 201) {
                    setCurrentPage(1)
                    dispatch(getVendorsList(1, search_text, setLoader))
                    dispatch(getVendorsListWithNoPagination(search_text, setNoPaginationLoader))
                    let vendor = response?.data?.response?.vendor;
                    let successMessage = response?.data?.response?.message;
                    // dispatch(addVendor(vendor));
                    setStateList();
                    // setCityList();
                    setCountryId()
                    // setStateId()
                    vendorModalToggler();
                    setLoading(false)
                    toast.success(successMessage, { toastId: "toast" });

                } else {
                    setLoading(false);
                    toast.error("Error in create vendor", { toastId: "toast" });
                }

            }
        } else {
            setLoading(false)
            if (website && !validWebsite) {
                toast.error("Invalid URL", { toastId: "toast" })
                setErrors(((prev) => ({
                    ...prev,
                    website: "invalid URL"
                })));

            }
        }

    }


    const checkVendorExistenceBeforeSubmit = () => {
        const { email, mobile_number } = vendorsData;
        let payload = {}
        if (isEdit) {
            const instance_id = JSON.parse(localStorage.getItem('verdor_id'))
            payload = {
                email: email,
                mobile_number: mobile_number,
                instance_id: instance_id
            }
        } else {
            payload = {
                email: email,
                mobile_number: mobile_number
            }
        }
        let checkValidationPayload = {
            vendor_name: vendorsData?.vendor_name,
            address: vendorsData?.address
        }
        setErrors(validation(checkValidationPayload));
        let result = validation(checkValidationPayload);
        if (objectKeyConvertToArray(result)?.length === 0) {
            let isEmailValid = true
            if (email) {
                isEmailValid = email && validateEmail(email)
            } else {
                isEmailValid = true
            }
            if (isEmailValid) {
                setLoading(true)
                dispatch(
                    checkVendorExistance(
                        payload,
                        (response) => {
                            if (response?.data?.status_code == 200) {
                                const data = response?.data?.response?.fields
                                if (data?.length > 0) {
                                    setLoading(false)
                                    if (data?.includes("EMAIL")) {
                                        toast.error("Email already registered", { toastId: "toast" })
                                    } else {
                                        if (data?.includes("MOBILE_NUMBER")) {
                                            toast.error("Phone number already registered", { toastId: "toast" })
                                        }
                                    }
                                } else {
                                    onVendorsSubmit()
                                }
                            }
                        },
                        (result) => {
                            // if (result?.data?.status_code == 404) {

                            // }
                            setLoading(false)
                        }
                    )
                )
            } else {
                setErrors((prev) => ({
                    ...prev,
                    email: "Email is invalid.",
                }));
                formRefVendor.current?.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            formRefVendor.current?.scrollIntoView({ behavior: "smooth" });
        }
    }



    // **************************** Eidt vendor *********************************
    const vendorEditHandler = async (id) => {
        storeDataInLocalStorage("verdor_id", id)
        setIsEdit(true);
        vendorModalToggler();
        const selectedVendor = vendorsRedux.find((item) => item.id === id);
        // selectedVendor?.state && setStateId(selectedVendor?.state)
        selectedVendor?.country && setCountryId(selectedVendor?.country?.unique_id)

        if (selectedVendor) {
            setVedors({
                vendor_name: selectedVendor?.vendor_name,
                address: selectedVendor?.address,
                mobile_number: selectedVendor?.mobile_number,
                email: selectedVendor?.email,
                gstin: selectedVendor?.gstin,
                website: selectedVendor?.website,
                is_active: selectedVendor?.is_active,
                country: selectedVendor?.country?.unique_id,
                state: selectedVendor?.state?.unique_id,
                city: selectedVendor?.city?.name,

                //proper work
                // country: { label: selectedVendor.country, value: selectedVendor.country } , 
                // state:  { label: selectedVendor.state, value: selectedVendor.state },
                // city:  { label: selectedVendor.city, value: selectedVendor.city }, 
                // is_active: { label: selectedVendor.is_active ? "Active" : "Inactive", value: selectedVendor.is_active ? true : false } 
            })
        }
    }


    // **************************** delete vendor *********************************
    const vendorDeleteHandler = async (id) => {
        let response = await deleteVendor(id, access_token);
        if (response.status_code === 200) {
            let successMessage = response?.response?.message;
            setCurrentPage(1)
            dispatch(getVendorsList(1, search_text, setLoader))
            dispatch(getVendorsListWithNoPagination(search_text, setNoPaginationLoader))
            if (!isToastVisible) {
                toast.success(successMessage, {
                    toastId: "toast",
                    onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
            }
            // dispatch(removeVendor(id));
        }
    }

    return {
        AddVendor, setAddVendor, vendorsData, vendorModalToggler, vendorHandleChange, onVendorsSubmit, setIsEdit, isEdit,
        vendorDeleteHandler, vendorEditHandler, countriesList, stateList, stateOptions, onBlurHandler, errors, loading,
        checkVendorExistenceBeforeSubmit, setErrors, vendorErrors, setVendorErrors, vendorSubmitLoading, vendorActive,
        currentPage, setCurrentPage, vendorsDataRedux, pages, allCount, vendorsRedux, search_text, setSearchText, setLoader,
        loader, isToastVisible, setIsToastVisible, formRefVendor, setNoPaginationLoader, noPaginationLoader
    }
}


export default useVendors
