import Popup from 'Components/TenantApp/Elements/Popup'
import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import moment from 'moment';
import React from 'react'

const DetailPopup = ({ open, heading, location_currency, setCheckoutId, close }) => {
    return (
        <>
            <Popup
                open={open}
                size={'lg'}
                z={'z-[3333]'}
                close={close}
                heading={heading || "Consumption History"}
            >
                <div className="overflow-x-auto">
                    <div className={`inline-grid xl:grid grid-cols-[repeat(4,minmax(10rem,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-[41rem] w-full`}>
                        <p>Invoice ID</p>
                        <p>Consumption Date</p>
                        <p>Discount</p>
                        <p>Total Price</p>
                    </div>
                    <div
                        className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                    >
                        {open?.length > 0 ? (
                            open?.map((item, index) => {
                                return (
                                    <div key={index} className={`px-12 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(10rem,1fr))] min-w-[41rem] w-full`}>
                                        <div className={`before-left-arrow cursor-pointer`}>
                                            <div className="h-full flex items-center">
                                                <p
                                                    className="text-xs text-[#5679FF] font-black line-clamp-1"
                                                    onClick={() => {
                                                        setCheckoutId(item?.checkout_id)
                                                    }}
                                                >
                                                    #{item?.invoice_id}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-xs">
                                            {item?.consumption_date ? moment(item?.consumption_date).format("DD-MM-YYYY") : "----"}
                                        </p>
                                        <p className="text-xs">
                                            {item?.price || item?.discounted_amount} {location_currency}
                                        </p>
                                        <p className="text-xs">
                                            {item?.total_bill} {location_currency}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <NoReportDataFound />
                        )}
                    </div>
                </div>

            </Popup>
        </>
    )
}

export default DetailPopup