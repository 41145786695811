import Svgs from "Assets/svgs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";


export const FreeItemForOtherPurchaseCard = ({ onSelect, data, isDelete, selected, onRemoveOffer, cartItems, MODULE_TYPE }) => {
  const [purchase_data, setPurchaseData] = useState({})
  const [isValidFreeService, setIsValidFreeService] = useState(false);

  const state = useSelector(state => state)
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    let free_services = [...data.free_service];
    let this_service
    let isValid = true; // Assume it's valid initially
    if (MODULE_TYPE == 'quickSales') {
      free_services?.forEach((serv) => {
        let matchingService = cartItems?.find((service) => service?.id === serv.service);

        if (matchingService) {
          serv.name = matchingService.name;
          serv.client_can_book = matchingService.client_can_book;
          serv.slot_availible_for_online = matchingService.slot_availible_for_online;

          // Check if quantities match
          if (matchingService?.quantity !== serv?.quantity) {
            isValid = false;
          }
        } else {
          // If a free service is missing from the cart, set check to false
          isValid = false;
        }
      });

      this_service = cartItems?.find((service) => service?.id === data.service);

      // If this_service is missing, set isValid to false
      if (!this_service) {
        isValid = false;
      }
    } else {
      free_services?.forEach((serv) => {
        let matchingServices = cartItems?.filter((service) => service?.service === serv?.service) || [];

        if (matchingServices?.length > 0) {
          // Assign values from the first matching service
          serv.name = matchingServices[0].srv_name ? matchingServices[0].srv_name : matchingServices[0].name;
          serv.client_can_book = matchingServices[0].client_can_book;
          serv.slot_availible_for_online = matchingServices[0].slot_availible_for_online;
          // Count occurrences of the service in cartItems
          const serviceCountInCart = matchingServices?.length;

          // Check if any of the matching services have a different quantity
          if (serviceCountInCart != serv?.quantity) {
            isValid = false;
          }
        } else {
          // If a free service is missing from the cart, set check to false
          isValid = false;
        }
      });

      // Check for this_service
      this_service = cartItems?.find((service) => service?.service === data?.service);
      if (!this_service) {
        isValid = false;
      }
    }

    setPurchaseData({
      ...data,
      free_service: free_services,
      freeServiceForDiscount: this_service,
      service_name: this_service ? this_service?.name : ''
    });

    setIsValidFreeService(isValid); // Update state
  }, [cartItems, data, MODULE_TYPE]); // Dependencies added

  return isValidFreeService ? (
    <div
      className={`bg-[#F2F5FF] relative ${selected?.id === purchase_data?.id ? "border-black" : " border-[#B8C6F5]"
        }  border   rounded-md p-4 mt-1 cursor-pointer`}
      onClick={() => {
        onSelect && onSelect({ ...purchase_data })
      }}
    >
      <div
        className="absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2"
      >
        {isDelete && <Svgs.CirlceClose size="20" fill="red" />}
      </div>
      <div className="flex justify-between items-center mb-2">

        <div className="text-[#A1A1A1] font-bold">
          {selectedLanguage?.name == "English" ? "Get a Free Item For other purchase" : translation[1215]?.value ? translation[1215]?.value : "Get a Free Item For other purchase"}
        </div>

        {onRemoveOffer && selected &&
          <button
            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
            onClick={onRemoveOffer}
          >
            <Svgs.Delete fill="#767A87" />
          </button>
        }
      </div>
      <div className="text-black font-semibold mb-2 w-full">{data?.promotion_name}</div>
      <div className="flex items-center  gap-6">

        <div className="flex flex-col gap-2 flex-1">
          <p className=" text-bold">
            {selectedLanguage?.name == "English" ? "Get Free" : translation[1216]?.value ? translation[1216]?.value : "Get Free"}
            {purchase_data?.service_name ? <span className="font-bold">"{purchase_data?.service_name}"</span> : ''}
            {selectedLanguage?.name == "English" ? "on buying" : translation[1217]?.value ? translation[1217]?.value : "on buying"}
          </p>
          <div className="flex items-center justify-between gap-2">
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
              </p>
            </div>
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity"}
              </p>
            </div>
          </div>
          {purchase_data?.free_service?.map((obj, index) => {
            return (
              <div className="flex items-center justify-between gap-2" key={index} >
                <div>
                  <p className="text-[#101928] font-semibold">{obj?.name ? obj?.name : obj?.srv_name}</p>
                </div>
                <div>
                  <p className="text-[#101928] font-semibold text-left">
                    {obj.quantity}
                  </p>
                </div>
              </div>
            );
          })}
          {/* Date duration */}
          <div className="flex items-center justify-between w-full gap-2">
            <div className="flex items-center gap-2">
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
              </p>
              <p className="text-[#101928] font-semibold">
                {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
              </p>
              <p className="text-[#101928] font-semibold">
                {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}


export const FreeItemForOtherPurchase = ({ MODULE_TYPE, onSelect, get_free_item_for_other_purchase, cartData, setCartData,
  appointmentServices, setAppointmentService }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)

  const handle_selected_data_appointment = (data) => {
    const updatedAppointmentServices = appointmentServices?.map(service => {
      if (data?.freeServiceForDiscount?.service === service?.service) {
        return {
          ...service,
          discount_price: 0,
          discount_percentage: 100,
          is_promotion: true,
          is_promotion_availed: true
        };
      }
      return service;
    })
    const promotionalServices = updatedAppointmentServices?.filter(itm => itm?.is_promotion)
    const total_discount_price = promotionalServices?.reduce((sum, item) => sum + (item?.price || 0), 0);
    setAppointmentService(updatedAppointmentServices);
    setCartData({
      ...cartData,
      promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
      is_promotion: true,
      is_promotion_availed: true,
      selected_promotion_type: data?.type || null,
      selected_promotion_id: data?.id || null,
    })
    onSelect?.()
  }

  const handle_select_service = (data) => {
    let total_discount_price = 0;
    setCartData(prev => {
      let updatedCart = prev && typeof prev === "object" ? { ...prev } : { ids: [] };
      updatedCart.ids = updatedCart.ids || [];

      // Update items in the cart
      updatedCart.ids = updatedCart.ids.map(item => {
        if (item?.selection_type === 'SERVICE' && data?.freeServiceForDiscount?.id === item?.id) {
          return {
            ...item,
            discount_price: 0,
            discount_percentage: 100,
            is_promotion: true,
            is_promotion_availed: true
          };
        }
        return item;
      });
      const services = updatedCart.ids?.filter(itm => itm?.is_promotion)
      // Calculate total discount price
      total_discount_price = services?.reduce((sum, item) => {
        return sum + (item?.price || 0);
      }, 0);

      return {
        ...updatedCart,
        selected_promotion_type: data?.type || null,
        selected_promotion_id: data?.id || null,
        is_promotion: true,
        is_promotion_availed: true,
        promotion_discount_sum: total_discount_price
      };
    });


    dispatch({
      type: "UPDATE_CART_DISCOUNTS",
      payload: {
        cart_items: state?.cartItem?.cart_items?.map(item => {
          if (item?.selection_type == 'SERVICE' && data?.freeServiceForDiscount?.id === item?.id) {
            return {
              ...item,
              discount_price: 0,
              discount_percentage: 100,
              is_promotion: true,
              is_promotion_availed: true
            };
          }
          return item;
        }),
        is_promotion: true,
        selected_promotion_type: data?.type || null,
        selected_promotion_id: data?.id || null,
        is_promotion_availed: true,
        promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
      }
    });

    onSelect?.()
  }

  const handle_selected_data = (data) => {
    if (MODULE_TYPE === 'quickSales') {
      handle_select_service(data);
    } else {
      let funcs = {
        'APPOINTMENT': () => handle_selected_data_appointment(data)
      }
      funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
    }
  }

  return (
    <>
      {
        get_free_item_for_other_purchase?.map((itm, index) => {
          return (
            <FreeItemForOtherPurchaseCard
              key={index}
              data={itm}
              isDelete={false}
              selected={false}
              onSelect={handle_selected_data}
              cartItems={MODULE_TYPE == 'quickSales' ? cartData?.ids : appointmentServices}
              MODULE_TYPE={MODULE_TYPE}
            />
          )
        })
      }
    </>
  );
};