import Svgs from 'Assets/svgs'
import NotFound from 'Components/TenantApp/Dashboard/Sales/Elements/NotFound'
import React from 'react'
import { useState } from 'react'
import ViewPaymentPopup from '../Popups/ViewPayment'
import CreatePaymentPopup from '../Popups/CreatePayment'
import DeleteConfirmation from 'new-components/delete-popup'
import Pagination from 'new-components/Pagination'
import StatusBtn from 'Components/TenantApp/Elements/StatusBtn'
import Popup from 'Components/TenantApp/Elements/Popup'

const ExpenseTable = ({ data, selectedLanguage, deletePopup, loader, setDeletePopup, translation, setIsEdit, setEditData, setAddExpensePopup, deleteLoading, onDeleteClick, expensePagination, currentPage, setCurrentPage, getAllExpenses, getAllExpensesNoPagination }) => {
    const [showMenuPopup, setShowMenuPopup] = useState(false)
    const [viewPaymentPopup, setViewPaymentPopup] = useState(false)
    const [createPaymentPopup, setCreatePaymentPopup] = useState(false)
    const [expenseData, setExpenseData] = useState()
    const [showNotes, setShowNotes] = useState(false)
    const [getNote, setGetNote] = useState('')

    return (
        <>
            <div className="overflow-x-auto rounded-lg min-h-[21.875rem]">
                <div className="inline-grid xl:grid border-b grid-cols-[repeat(9,minmax(140px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                    <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Total Amount" : translation[183]?.value ? translation[183]?.value : "Total Amount"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Due Amount" : translation[1745]?.value ? translation[1745]?.value : "Due Amount"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Expense Note" : translation[1746]?.value ? translation[1746]?.value : "Expense note"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Added By" : translation[1747]?.value ? translation[1747]?.value : "Added By"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                </div>
                {data?.length == 0
                    ? <NotFound />
                    : <div className={`odd-bg transition-all border-l-[0px]`}>
                        {data?.map((itm, index) => {
                            return <div className="odd-bg inline-grid items-center xl:grid grid-cols-[repeat(9,minmax(140px,1fr))] px-12 py-3 text-sm min-w-full w-fit">
                                <p>{itm?.id?.split("-")[0]}</p>
                                <p>{itm?.date}</p>
                                <p>{itm?.expense_category_name}</p>
                                <p>{itm?.total_amount}</p>
                                <p>{itm?.due_amount}</p>
                                <p><StatusBtn text={itm?.status} status={itm?.status == 'Paid' ? 'active' : 'inactive'} /></p>
                                <p>
                                    {itm?.expense_note &&
                                        <span className='cursor-pointer' onClick={() => {
                                            setShowNotes(true)
                                            setGetNote(itm?.expense_note)
                                        }}>
                                            <Svgs.NoteBook />
                                        </span>
                                    }
                                    {/* {itm?.expense_note} */}
                                </p>
                                <p>{itm?.user_name}</p>
                                <p className='relative'>
                                    <div className="flex cursor-pointer" onClick={() => setShowMenuPopup(itm?.id)}>
                                        <Svgs.ThreeDots />
                                    </div>
                                    {(showMenuPopup == itm?.id) && <>
                                        <div className={`absolute w-full z-10 rounded-md bg-white shadow-popup-card ${(index == 0 || index == 1 || index == 2) ? 'top-3' : 'bottom-10'}`}>
                                            <div className="flex group items-center gap-2 p-2 cursor-pointer rounded-md hover:!bg-[#5679ff] hover:text-white"
                                                onClick={() => {
                                                    setIsEdit && setIsEdit(true)
                                                    setEditData && setEditData(itm)
                                                    setAddExpensePopup && setAddExpensePopup(true)
                                                    setShowMenuPopup()
                                                }}
                                            >
                                                <Svgs.Edit fill="#7D7D7D" className={'group-hover:!fill-white w-5'} />
                                                <p className="text-sm">{selectedLanguage?.name == "English" ? "Edit" : translation[39]?.value ? translation[39]?.value : "Edit"}</p>
                                            </div>
                                            <div className="flex items-center group gap-2 p-2 cursor-pointer rounded-md hover:!bg-[#5679ff]  hover:text-white"
                                                onClick={() => {
                                                    setDeletePopup(itm?.id)
                                                    setShowMenuPopup()
                                                }}
                                            >
                                                <Svgs.Delete className={'group-hover:fill-white w-5'} />
                                                <p className="text-sm">
                                                    {selectedLanguage?.name == "English" ? "Delete" : translation[120]?.value ? translation[120]?.value : "Delete"}
                                                </p>
                                            </div>
                                            <hr />
                                            {itm?.status == 'Due' &&
                                                <div className="flex items-center group gap-2 p-2 cursor-pointer rounded-md hover:!bg-[#5679ff]  hover:text-white"
                                                    onClick={() => {
                                                        setCreatePaymentPopup(!createPaymentPopup)
                                                        setExpenseData(itm)
                                                        setShowMenuPopup()
                                                    }}
                                                >
                                                    <Svgs.DollarSign fill="#7D7D7D" className={'group-hover:fill-white w-5'} />
                                                    <p className="text-sm">
                                                        {selectedLanguage?.name == "English" ? "Add Payment" : translation[1772]?.value ? translation[1772]?.value : "Add Payment"}
                                                    </p>
                                                </div>
                                            }
                                            <div className="flex items-center group gap-2 p-2 cursor-pointer rounded-md hover:!bg-[#5679ff]  hover:text-white"
                                                onClick={() => {
                                                    setViewPaymentPopup(!viewPaymentPopup)
                                                    setExpenseData(itm)
                                                    setShowMenuPopup()
                                                }}
                                            >
                                                <Svgs.MasterCardSM fill="#7D7D7D" className={'group-hover:fill-white w-5'} />
                                                <p className="text-sm whitespace-nowrap">
                                                    {selectedLanguage?.name == "English" ? "View Payment" : translation[1771]?.value ? translation[1771]?.value : "View Payment"}
                                                </p>
                                            </div>

                                        </div>
                                        <div
                                            className="fixed inset-0 z-[1] top-0 bottom-0 left-0 right-0"
                                            onClick={() => {
                                                setShowMenuPopup()
                                            }}
                                        ></div>
                                    </>}
                                </p>
                            </div>
                        })}
                    </div>
                }
                {(data?.length > 0 && !loader) &&
                    <Pagination
                        currentPage={currentPage}
                        totalCount={Math.ceil(expensePagination?.total_pages)}
                        pageSize={10}
                        onPageChange={page => setCurrentPage(page)}
                    />
                }
            </div>


            <ViewPaymentPopup
                open={viewPaymentPopup}
                close={setViewPaymentPopup}
                data={expenseData}
                translation={translation}
                selectedLanguage={selectedLanguage}
                onSuccessAPICall={() => {
                    getAllExpensesNoPagination()
                    getAllExpenses()
                }}
            />
            <CreatePaymentPopup
                data={expenseData}
                open={createPaymentPopup}
                close={setCreatePaymentPopup}
                onSuccessAPICall={() => {
                    getAllExpensesNoPagination()
                    getAllExpenses()
                }}
            />

            {deletePopup &&
                <DeleteConfirmation
                    handleClose={() => setDeletePopup()}
                    heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this expense?" : translation[1778]?.value ? translation[1778]?.value : "Are you sure you want to delete this expense?"}
                    description={`
                        ${selectedLanguage?.name == "English" ? "Are you sure you want to delete this expense?" : translation[1778]?.value ? translation[1778]?.value : "Are you sure you want to delete this expense?"}
                        ${selectedLanguage?.name == "English" ? "You won't be able to see this again." : translation[745]?.value ? translation[745]?.value : "You won't be able to see this again."}`}
                    isLoading={deleteLoading}
                    handleDelete={onDeleteClick}
                />
            }

            {
                showNotes && (
                    <Popup customHeight={'min-h-[20vh]'}
                        heading={selectedLanguage?.name == "English" ? "Expense Note" : translation[1746]?.value ? translation[1746]?.value : "Expense Note"}
                        onclose={() => {
                            setShowNotes(!showNotes);
                            setGetNote("")
                        }}
                    >
                        <div className="p-5">
                            <div className="border p-5 rounded-2xl min-h-[20vh]">
                                <p className="text-sm ">
                                    {getNote ? getNote : "There is no Expense Note"}
                                </p>
                            </div>
                        </div>
                    </Popup>
                )
            }
        </>
    )
}

export default ExpenseTable