
import React from 'react'
import LoginBtn from './LoginBtn'
import Popup from './Popup'

const VerificationPopup = ({ title, open, loading, close, onDelete, customSize }) => {
    return (
        <>
            <Popup
                open={open}
                close={close}
                removeClose={true}
                header={false}
                size={customSize ? "" : 'xs'}
                customSizeStyle={customSize ? customSize : ""}
            >
                <div className="flex flex-col gap-5">
                    <h1 className='text-xl font-semibold normal-case'>{title}</h1>
                    <div className="flex justify-between gap-4 items-center">
                        <LoginBtn className={'!w-full !text-center flex items-center justify-center btn-outlined'} onClick={() => close(false)} >No</LoginBtn>
                        <LoginBtn className={'!w-full !text-center flex items-center justify-center'} loading={loading} onClick={() => onDelete()}>Yes</LoginBtn>
                    </div>
                </div>

            </Popup>
        </>
    )
}

export default VerificationPopup