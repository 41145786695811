import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getvouchersFromQuickSales,
  updateVoucher,
} from "../../../../../../Adapters/Api/vouchers";
import Svgs from "../../../../../../Assets/svgs";
import { PRODUCT } from "../../../../../../Redux/TenantRedux/ActionTypes/productTypes";
import LoginBtn from "../../../../Elements/LoginBtn";
import LoginInput from "../../../../Elements/LoginInput";
// import NotFound from "../../Elements/NotFound";
import VoucherCard from "../../Elements/VoucherCard.jsx";
import { toast } from "react-toastify";
import AddToCart from "../../Elements/AddToCart";
// import PreloaderFull from "./../../../../Elements/PreloaderFull";
import { useService } from "../Services/helper";
// import CircleIcon from "./../../../../Elements/CircleIcon";
import DetailPopupDetails from "./../../Elements/DetailPopupDetails";
import Popup from "./../../../../../../new-components/popup/index";
import { apiErrorMessage } from "Constants/Data/Errors";
import { useLocation } from "new-components/location/helper";
import Pagination from "new-components/Pagination";
// import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NoVoucherFound from "../../Elements/NoVoucherFound";
import ServiceCard from "../../Elements/ServiceCard";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import QuickSalesTableHead from "Components/Utility/Shimmers/QuickSalesTableHead";
import Shimmer_array from "Constants/Data/Shimmer_array";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

let timeOutId = undefined
const Voucher = ({ isLoading }) => {
  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const cartFromLocalStorage = getDataFromLocalStorage('cart_items')

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { locations } = useSelector(
    (state) => state
  );
  const { selected_location } = locations;
  const voucher_updated = state?.voucher?.voucher_updated;
  const location_currency = state?.utility?.currency?.location_currency;
  // const discountPromotions = state?.clientDiscountPromotion?.discountPromotions;

  let pages = state?.voucher?.page_count

  const cartItems = state.cartItem.cart_items;
  const [selected_ids, setSelectedIds] = useState([]);
  const [search_text, setSearchText] = useState("");
  const [edit_id, setEditId] = useState(undefined);
  const [voucherData, setvoucherData] = useState({});
  const [ProductDetailPopup, setProductDetailPopup] = useState(false);
  const { removedFromCart } = useService();
  const [voucher_details, setVoucherDetails] = useState({});
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [OpenCart, setOpenCart] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)
  const [lastPage, setLastPage] = useState()

  const { locationDetail } = useLocation();
  const LocationCurrency = locationDetail?.currency?.id;

  const PageSize = 10

  const [currentPage, setCurrentPage] = useState(1);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [loadingPermission, setLoadingPermission] = useState(true)
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const onVoucherUpdateHnadler = (edited_data) => {
    dispatch(
      updateVoucher(
        edited_data,
        () => {
          toast.success("Promotion Updated successfully", { toastId: "toast" });
          setEditId(false);
          dispatch(getvouchersFromQuickSales());
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  const onVoucherEditClickHnadler = (id) => {
    setEditId(id);
  };

  const handlevoucherData = (e) => {
    const { name, value } = e.target;
    setvoucherData({
      ...voucherData,
      [name]: value,
    });
  };

  const handleSelection = (vouchers_already_checkout, sale, id, price) => {
    const addingVoucherAlreadyExistsInCart = cartItems?.find(itm => itm?.id == id)
    if (addingVoucherAlreadyExistsInCart) {
      if (addingVoucherAlreadyExistsInCart?.quantity + addingVoucherAlreadyExistsInCart?.voucher_count < addingVoucherAlreadyExistsInCart?.sales) {
        if (selected_ids.includes(id)) {
          selected_ids.splice(selected_ids.indexOf(id), 1);
        } else {
          selected_ids.push(id);
        }
        const result = state.voucher?.voucher?.find((itm) => itm.id === id);
        dispatch({
          type: "ADD_TO_CART",
          payload: { ...result, quantity: 1, price, selection_type: "VOUCHER" },
        });
        let updatedCartForLocal = []
        if (cartFromLocalStorage?.length > 0 && cartFromLocalStorage != undefined) {
          updatedCartForLocal = [...cartFromLocalStorage]
        }
        updatedCartForLocal = [...updatedCartForLocal, { ...result, quantity: 1, price, selection_type: "VOUCHER" }]
        storeDataInLocalStorage('cart_items', updatedCartForLocal)
        // toast.success("item added to cart",{ toastId: "toast" });
      }
      else {
        toast.error("The number of sales are completed for this voucher", { toastId: "toast" });
      }
    }
    else {
      if (vouchers_already_checkout <= sale) {
        if (selected_ids.includes(id)) {
          selected_ids.splice(selected_ids.indexOf(id), 1);
        } else {
          selected_ids.push(id);
        }
        const result = state.voucher?.voucher?.find((itm) => itm.id === id);
        dispatch({
          type: "ADD_TO_CART",
          payload: { ...result, quantity: 1, price, selection_type: "VOUCHER" },
        });
        let updatedCartForLocal = []
        if (cartFromLocalStorage?.length > 0 && cartFromLocalStorage != undefined) {
          updatedCartForLocal = [...cartFromLocalStorage]
        }
        updatedCartForLocal = [...updatedCartForLocal, { ...result, quantity: 1, price, selection_type: "VOUCHER" }]
        storeDataInLocalStorage('cart_items', updatedCartForLocal)
        // toast.success("item added to cart",{ toastId: "toast" });
      }
      else {
        toast.error("The number of sales are completed for this voucher");
      }
    }

  };

  const filterVoucher = () => {
    // return state.voucher?.voucher.filter(itm => itm?.sale > state?.saleOrder?.saleOrderVouchers.filter(s_itm => s_itm.id == itm.id).length).filter(itm => itm.name.toLowerCase().includes(search_text.toLowerCase()))
    // return state.voucher?.voucher.filter((itm) => itm.name.toLowerCase().includes(search_text.toLowerCase()));
    return state.voucher?.voucher
  };

  const onBuyVoucher = () => {
    let ids = selected_ids;
    dispatch({
      type: PRODUCT.STORE_SELECTED_IDS_INTO_REDUX,
      payload: {
        ids: ids,
        selection_type: "VOUCHER",
      },
    });
    navigate("/dashboard/sales/quick-sales/checkout");
  };

  // useEffect(() => {
  //   dispatch(getvouchersFromQuickSales());
  // }, []);

  // useEffect(() => {
  //   dispatch(getvouchersFromQuickSales(currentPage));
  // }, [currentPage]);

  useEffect(() => {
    const quickSales = true
    if (search_text === null) {
      // Initial load with empty search_text 
      dispatch(getvouchersFromQuickSales(selected_location, currentPage, "", setPageLoading, quickSales))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getvouchersFromQuickSales(selected_location, currentPage, search_text, setPageLoading, quickSales))
      }, 500);
    }
  }, [currentPage, search_text, selected_location])

  useEffect(() => {
    // setPageLoading(true)
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    // if (state.saleService?.selection?.ids.length > 0) {
    //     setSelectedIds(state.saleService?.selection?.ids)
    // }

    const result = cartItems?.map((itm) => itm.id);
    setSelectedIds(result);
  }, [cartItems.length != selected_ids.length]);

  const voucher_details_settings = (id) => {
    setProductDetailPopup(true);
    const selected_voucher = filterVoucher()?.find((itm) => itm?.id === id);
    setVoucherDetails(selected_voucher);
  };

  const voucherCount = state?.voucher?.allCount
  return (
    <>

      <div className="rounded-[10px] bg-white cardShadow">
        <div className="px-4 lg:px-6 py-[21px] flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
              </h2>
              {!voucher_updated || pageLoading ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {voucherCount ? voucherCount : 0} {" "}
                    {voucherCount && voucherCount > 1 ?
                      selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers"
                      : selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"
                    }
                  </p>
                </div>
              }
            </div>
            <div className="mb-00">
              {loadingPermission ?
                <Shimmer className={'w-40 lg:w-44'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Voucher" : translation[1267]?.value ? translation[1267]?.value : "Search Voucher"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                  value={search_text}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              }
            </div>
          </div>
          {loadingPermission ?
            <QuickSalesTableHead />
            :
            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
              {!employee ||
                (employee &&
                  employeePermissions?.sales_apply_offer?.includes("create")) ||
                employeePermissions?.sales_apply_offer?.includes("edit") ? (
                <AddToCart OpenCart={OpenCart} setOpenCart={setOpenCart} />
              ) : (
                ""
              )}
              {!employee ? (
                <LoginBtn
                  text={selectedLanguage?.name == "English" ? "Add Voucher" : translation[290]?.value ? translation[290]?.value : "Add Voucher"}
                  onClick={() => {
                    navigate("/dashboard/clients/sharing-settings/voucher");
                  }}
                />
              ) : (
                ""
              )}
            </div>
          }
        </div>
        <div className="px-6 py-3">
          {!voucher_updated || pageLoading ?
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
              {Shimmer_array()?.map(itm => <ServiceCard animate />)}
            </div>
            :
            filterVoucher()?.length > 0 ? (
              <>
                <div className="flex items-center justify-between">
                  <h1 className="text-[#7B7B7B] text-sm font-semibold normal-case">
                    {selectedLanguage?.name == "English" ? "You can select multiple vouchers" : translation[289]?.value ? translation[289]?.value : "You can select multiple vouchers"} <span className='text-red-500'>*</span>
                  </h1>
                  {voucherCount > 10 &&
                    <div className="flex items-center justify-between gap-4">
                      <LoginBtn
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                        text="Back" />
                      <LoginBtn
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                        disabled={currentPage === lastPage}
                        text="Next" />
                    </div>
                  }
                </div>
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
                  {/* ?.filter(voucher => voucher?.currency_voucher?.some(item => item.currency == LocationCurrency)) */}
                  {filterVoucher()?.map((voucher, index) => {
                    return (
                      <>
                        <VoucherCard
                          setOpenCart={setOpenCart}
                          data={{
                            ...voucher,
                            currency: location_currency,
                          }}
                          checked={
                            selected_ids.includes(voucher.id) ? true : false
                          }
                          key={index}
                          index={index}
                          is_edit={edit_id == voucher.id ? true : false}
                          onUpdate={(edited_data) => {
                            onVoucherUpdateHnadler(edited_data);
                          }}
                          onEditClick={(id) => {
                            onVoucherEditClickHnadler(id);
                          }}
                          veiw_details={() =>
                            voucher_details_settings(voucher?.id)
                          }
                          onClick={() =>
                            handleSelection(voucher?.voucher_count, voucher?.sales, voucher.id, voucher?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency)?.price)
                          }
                          removedFromCart={removedFromCart}
                        />
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center justify-center">
                  <NoVoucherFound />
                </div>
              </>
            )}
        </div>

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalCount={Math.ceil(pages)}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
          setLastPage={setLastPage}
        />
      </div>

      {ProductDetailPopup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Voucher Details" : translation[308]?.value ? translation[308]?.value : "Voucher Details"}
          handleClose={() => setProductDetailPopup(false)}
        >
          <div className="grid md:grid-cols-5 gap-4">
            {/* <div className="flex justify-center">
              <CircleIcon className="!h-[6rem] !w-[6rem]">
                <Svgs.NewService />
              </CircleIcon>
            </div> */}
            <div>
              <div className="h-[6rem] w-[6rem] overflow-hidden flex items-center justify-center">
                <Svgs.Gift />
              </div>
            </div>
            <div className="flex flex-col gap-6 md:col-span-4">
              <div className="grid md:grid-cols-3 gap-2">
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Voucher Name" : translation[304]?.value ? translation[304]?.value : "Voucher Name"}
                  desc={voucher_details?.name}
                />
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Promotion Type" : translation[305]?.value ? translation[305]?.value : "Promotion Type"}
                  desc={voucher_details?.voucher_type}
                />
                <div></div> {/* Don't Remove This Div */}
                {voucher_details?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency)?.price &&
                  <DetailPopupDetails
                    title={selectedLanguage?.name == "English" ? "Voucher Price" : translation[306]?.value ? translation[306]?.value : "Voucher Price"}
                    // desc={voucher_details?.price}
                    desc={`${voucher_details?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency)?.price} ${voucher_details?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency) ? locationDetail?.currency?.code : "AED"}`}
                  />
                }
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Validity" : translation[307]?.value ? translation[307]?.value : "Validity"}
                  desc={voucher_details?.validity}
                />
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}
                  desc={`${voucher_details?.discount_percentage} %`}
                />
                {/* <DetailPopupDetails
                  title={"Voucher Value"}
                  desc={voucher_details?.value}
                /> */}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Voucher;
