/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { deleteAllWorkingSchedules, getEmployeeDropDownList, getWorkingShedule } from "Adapters/Api/employee";
import { handleDeleteLIEUDay, handleDeleteShift, handleDeleteBreakTime } from "Adapters/Api/employee/working-schedule";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";

const useWrokingSchedule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const working_schedule_updated = state?.employee?.working_schedule_updated;
  const locationRedux = state?.locations;
  const employeeReduxx = state?.employee?.employees;

  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");

  const [updateSlotData, setUpdateSlotData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [editSlotId, setEditSlotId] = useState("");
  const [allemployee, setallemployee] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [currentWeek, setCurrentWeek] = useState([]);
  const [employeeData, setEmployeeData] = useState({});
  const [selectedTo, setTo] = useState("");
  var currentDate = moment();
  const [selectedFrom, setFrom] = useState(currentDate);
  // const [selectedFrom, setFrom] = useState(moment('Wednesday, Jan 4, 2023'));
  const [DateRange, setDateRange] = useState("");
  const [search_text, setSearchText] = useState("");
  const [DateRangePopup, setDateRangePopup] = useState(false);
  const [employeeSlotPopup, setEmployeeSlotPopup] = useState(false);
  const [breaktimeSlotPopup, setBreaktimeSlotPopup] = useState(false);
  const [breakTimeEdit, setBreakTimeEdit] = useState({})
  const [deleteSlot, setDeleteSlot] = useState({
    show: false,
    id: "",
    isSubmit: false,
  });
  const [breakTimeDeleteSlot, setBreakTimeDeleteSlot] = useState({
    show: false,
    id: "",
    isSubmit: false,
  });
  const [selectdEmployee, setselectdEmployee] = useState("");
  const [dateRangeValue, setDateRangeValue] = useState({
    from: "",
    to: "",
  });
  const [reduxData, setreduxData] = useState([]);
  const [WorkingSchedule, setWorkingSchedule] = useState([]);
  const [importWorkingSchedule, setImportWorkingSchedule] = useState(false);
  const [range, setRange] = useState(0);
  const [loader, setLoader] = useState(true)
  const [isLeoDay, setIsLeoDay] = useState(false)
  const { from, to } = dateRangeValue;

  //set option list of select employee dropdwon
  let optionList;
  if (state?.employee?.working_schedule?.length > 0) {
    optionList = state?.employee?.working_schedule?.map((item) => {
      return {
        label: item?.full_name,
        value: item?.full_name,
      };
    });
  }
  useEffect(() => {
    if (locationRedux?.selected_location && currentWeek?.length > 0) {
      const startDate = moment(currentWeek[0]).format('YYYY-MM-DD')
      const endDate = moment(currentWeek[currentWeek?.length - 1]).format('YYYY-MM-DD')
      let payload = {
        location_id: locationRedux?.selected_location,
        start_date: startDate,
        end_date: endDate
      }
      dispatch(getWorkingShedule(payload, setLoader));
    }
  }, [locationRedux?.selected_location, currentWeek])

  // useEffect(() => {
  //   //only uncomment this when want to delete all schedules
  //   dispatch(deleteAllWorkingSchedules());
  // }, [])

  useEffect(() => {
    setreduxData(state?.employee?.working_schedule);
    getCurrentWeek();
  }, []);

  useEffect(() => {
    setWorkingSchedule(reduxData);
  }, [reduxData]);

  useEffect(() => {
    let arr = [
      {
        value: "All",
        label: "All Team Member",
      },
    ];
    if (state?.employee?.working_schedule.length > 0) {
      state?.employee?.working_schedule.map((sch) => {
        arr.push({
          value: sch.id,
          label: sch.full_name,
        });
      });
    }
    setallemployee(arr);
  }, []);

  const HandleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // working schedule exports
  let header = [
    { label: "Employee Name", key: "name" },
    { label: "Start Time", key: "start_time" },
    { label: "End Time", key: "end_time" },
    { label: "Monday", key: "monday" },
    { label: "Tuesday", key: "tuesday" },
    { label: "wednesday", key: "wednesday" },
    { label: "Thursday", key: "thursday" },
    { label: "Friday", key: "friday" },
    { label: "Saturday", key: "saturday" },
    { label: "Sunday", key: "sunday" },
  ];

  const successCallBack = () => {
    const startDate = moment(currentWeek[0]).format('YYYY-MM-DD')
    const endDate = moment(currentWeek[currentWeek?.length - 1]).format('YYYY-MM-DD')
    let payload = {
      location_id: locationRedux?.selected_location,
      start_date: startDate,
      end_date: endDate
    }
    dispatch(getWorkingShedule(payload, setLoader));
  }

  const exportWorkingSchedule = {
    filename: "workingSechedule.csv",
    headers: header,
    data:
      state?.employee?.working_schedule?.length > 0
        ? state?.employee?.working_schedule?.map((item) => {
          return {
            name: item?.full_name ? item?.full_name : "Not Available",
            start_time: item?.start_time ? item?.start_time : "Not Available",
            end_time: item?.end_time ? item?.end_time : "Not Available",
            monday: item?.monday
              ? `${item?.start_time} - ${item?.end_time}`
              : "Not Available",
            tuesday: item?.tuesday
              ? `${item?.start_time} - ${item?.end_time}`
              : "Not Available",
            wednesday: item?.wednesday
              ? `${item?.start_time} - ${item?.end_time}`
              : "Not Available",
            thursday: item?.thursday
              ? `${item?.start_time} - ${item?.end_time}`
              : "Not Available",
            friday: item?.friday
              ? `${item?.start_time} - ${item?.end_time}`
              : "Not Available",
            saturday: item?.saturday
              ? `${item?.start_time} - ${item?.end_time}`
              : "Not Available",
            sunday: item?.sunday
              ? `${item?.start_time} - ${item?.end_time}`
              : "Not Available",
          };
        })
        : [],
  };

  //import working scheduler submit here
  const onImportHandler = (file) => {
    let form_data = new FormData();
    form_data.append("file", file);
  };

  //handle all filter here like search, dropdown ..etc
  const working_schedule_filter = (from_date, to_date) => {
    const fromDate = Date.parse(from);
    const toDate = Date.parse(to);
    const working_schedule_data = dataList?.filter((item) => {
      let createAt = Date.parse(item?.created_at.split("T")[0]);

      if (search_text || selectdEmployee || (fromDate && toDate)) {
        let return_value = false;

        //check search text
        if (search_text) {
          if (
            item.full_name.toLowerCase().includes(search_text.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check dropdown text
        if (selectdEmployee) {
          if (item.id?.includes(selectdEmployee)) {
            return_value = true;
          } else {
            return false;
          }
        }

        if (fromDate && toDate) {
          if (createAt >= fromDate && createAt <= toDate) {
            return_value = true;
          } else {
            return false;
          }
        }

        return return_value;
      }
      return true;
    });

    return working_schedule_data;
  };

  const applyDateFilter = () => {
    setDateRangeValue((prev) => ({
      from: selectedFrom,
      to: selectedTo,
    }));
    setDateRangePopup(false);
    getCurrentWeek()
  };

  const getCurrentWeek = () => {
    let selected = moment(selectedFrom)
    const weekDays = [];
    var weekStart = selected.clone().startOf("isoWeek");

    for (var i = 0; i < 7; i++) {
      weekDays.push(
        moment(weekStart).add(i, "days").format("dddd, MMM D, yyyy")
      );
    }
    setCurrentWeek(weekDays);
  }

  useEffect(() => {
    getCurrentWeek();
  }, [selectedFrom])

  const handleWeeklyDate = (filter) => {
    if (filter === 'next') {
      setRange(range + 7);
      var nextWeek = moment().add(range + 7, 'd').startOf("isoWeek").format("dddd, MMM D, yyyy");
      setFrom(nextWeek);
    }
    if (filter === 'prev') {
      setRange(range - 7);
      var prevWeek = moment().add(range - 7, 'd').startOf("isoWeek").format("dddd, MMM D, yyyy");
      setFrom(prevWeek);
    }
  }

  // Delete Working Schedule
  const handleDeleteSlot = async () => {
    setDeleteSlot((prev) => ({
      ...prev,
      isSubmit: true,
    }));
    const data = new FormData();
    data.append("id", deleteSlot.id);

    const res = deleteSlot?.is_leo_day
      ? await handleDeleteLIEUDay(deleteSlot?.id, setDeleteSlot, access_token)
      : await handleDeleteShift(data, setDeleteSlot, access_token)

    res.status === 200 && successCallBack()
  };

  // Delete Break Time
  const handleBreakTimeDeleteSlot = async (id) => {
    setBreakTimeDeleteSlot((prev) => ({
      ...prev,
      isSubmit: true,
    }));

    const res = await handleDeleteBreakTime(breakTimeDeleteSlot?.id, setBreakTimeDeleteSlot, access_token)

    if (res.status === 200) {
      toast.success(res?.data?.response?.message, { toastId: 'success Break time' })
      successCallBack()
    } else {
      toast.error(apiErrorMessage, { toastId: 'breaktime' })
      setBreakTimeDeleteSlot((prev) => ({
        ...prev,
        show: false,
        isSubmit: false,
      }));
    }
  };

  // Edit Working Schedule
  const handleEditSlot = (id) => {
    dataList.find(({ schedule }) => {
      const data = schedule.find((x) => x.id === id);
      if (data) {
        setUpdateSlotData(data);
        setIsUpdate(true);
        setEmployeeSlotPopup(true);
        return true;
      } else {
        return false;
      }
    });
  };

  // Edit Break Time
  const handleEditBreakSlot = (id) => {
    dataList.find(({ schedule }) => {
      const data = schedule.find((x) => {
        return x?.break_time?.find(bt => {
          if (bt?.id === id) {
            return bt
          }
        })
      });
      if (data) {
        setBreakTimeEdit(data);
        setIsUpdate(true);
        setBreaktimeSlotPopup(true);
        return true;
      } else {
        return false;
      }
    });
  };

  useEffect(() => {
    // if (state?.employee?.working_schedule?.length === 0) {
    //   dispatch(getWorkingShedule());
    // } else {
    // const val = state?.employee?.working_schedule?.map((element) => {
    //   const data = element?.location?.filter(
    //     ({ id }) => id === locationRedux?.selected_location
    //   );
    //   if (data.length > 0 && data !== undefined) return { ...element };
    // });
    // const productArr = val.filter((element) => element !== undefined);

    var workingSechedule = state?.employee?.working_schedule?.map(obj => {
      return {
        ...obj,
        schedule: obj.schedule.map((s) => {
          return {
            ...s,
            date: s.date === undefined ? undefined : moment(s.date)?.format("dddd, MMM D, yyyy"),
            from_date: s.from_date === undefined ? undefined : moment(s.from_date)?.format("dddd, MMM D, yyyy"),
            to_date: s.to_date === undefined ? undefined : moment(s.to_date)?.format("dddd, MMM D, yyyy")
          }
        })

      }
    })
    if (workingSechedule) {
      setDataList(workingSechedule);
    }
    // }
  }, [locationRedux?.selected_location, state?.employee?.working_schedule]);

  //get employee list

  useEffect(() => {
    if (locationRedux?.selected_location) {
      dispatch(getEmployeeDropDownList(locationRedux?.selected_location))
    }
  }, [locationRedux?.selected_location])


  //filter dropdown employee on the basis of selected location
  const employeeOfSelectedLocation = () => {
    // const result  = employeeReduxx?.filter((emp) => {
    //   let get_loc = emp?.location?.find((loc) => loc?.id === locationRedux?.selected_location);
    //   if (get_loc) {
    //     return emp;
    //   }
    //   return false;
    // })
    const result = employeeReduxx
    return result
  };


  return {
    setImportWorkingSchedule, working_schedule_filter, setselectdEmployee, setDateRangePopup,
    onImportHandler, applyDateFilter, HandleSearch, handleEditSlot, navigate, setFrom, setTo,
    setIsUpdate, setDeleteSlot, setEditSlotId, setEmployeeData, handleDeleteSlot, isLeoDay,
    setEmployeeSlotPopup, setRange, handleWeeklyDate, range, working_schedule_updated, setIsLeoDay,
    exportWorkingSchedule, employeeSlotPopup, selectdEmployee, updateSlotData, employeeData,
    isUpdate, moment, DateRange, deleteSlot, optionList, selectedTo, currentWeek, search_text,
    selectedFrom, DateRangePopup, importWorkingSchedule, loader, employeeOfSelectedLocation, breakTimeDeleteSlot,
    successCallBack, breaktimeSlotPopup, setBreaktimeSlotPopup, handleBreakTimeDeleteSlot, setBreakTimeDeleteSlot,
    handleEditBreakSlot, breakTimeEdit, setBreakTimeEdit
  };
};

export default useWrokingSchedule;