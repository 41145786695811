import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import Svgs from "Assets/svgs";
import DashboardChart from "Components/TenantApp/Dashboard/DashboardChart";
import { graphScheleton } from "Constants/Data/Calendar";

const DonationBarChart = ({ data, loading }) => {
    const [isEmptyData, setIsEmptyData] = useState(false);

    // Transform data for Chart.js
    const transformedData = {
        labels: data?.map(item => item?.date), // Dates on X-axis
        datasets: [
            {
                label: "Total Amount",
                backgroundColor: data?.map(item => item?.total_amount > 0 ? "#5679FE" : "#CED1FF"), // Different colors if amount > 0
                data: data?.map(item => item.total_amount),
                borderRadius: 8,
                barThickness: 25,
            }
        ]
    };

    useEffect(() => {
        // Check if data contains non-zero values
        setIsEmptyData(data?.every(item => item?.total_amount === 0));
    }, [data]);

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: { stacked: false },
            y: { stacked: false, beginAtZero: true },
        },
        plugins: {
            legend: { display: false },
        },
    };

    return (
        <div className="overflow-auto">
            {loading ?
                <Shimmer>
                    <DashboardChart GraphData={graphScheleton} animate />
                </Shimmer> :
                isEmptyData ?
                    <div className="flex items-center justify-center pt-14">
                        <div className="flex flex-col gap-3 justify-center text-center">
                            <div className="w-fit mx-auto">
                                <Svgs.EmployeeInsightNotFound />
                            </div>
                            <h1 className="text-primary font-semibold text-lg normal-case">
                                {"No donation data found!"}
                            </h1>
                        </div>
                    </div>
                    :
                    <div className="min-w-full" style={{ width: `${data.length * 50}px`, overflowX: "auto" }}>
                        <Bar height={420} data={transformedData} options={options} />
                    </div>
            }
        </div>
    );
};

export default DonationBarChart;
