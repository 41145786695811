import axios from "axios";
import { BASE_URL, get_donation_report_url } from "../api_variables";
import { buildQueryString } from "Constants/Functions/buildQueryString";

// get donation report api
export const getDonationReportApi = (access_token, payload, success, fail) => async dispatch => {
    const query = buildQueryString(payload)
    let headers = {
        headers: { Authorization: `Token ${access_token}` }
    }
    try {
        const result = await axios.get(`${BASE_URL}${get_donation_report_url}${query}`, headers);

        if (result?.status === 200) {
            success && success(result?.data?.data)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};