import Footer from 'Components/Utility/Footer';
import Navbar from 'Components/Utility/Navbar';
import React from 'react';
import useBlogsHelper from './helper';
import NotFound from 'Components/TenantApp/Dashboard/Sales/Elements/NotFound';
import moment from 'moment';
import Pagination from 'new-components/Pagination';

const Blogs = () => {
  const {
    loading, blogs, currentPage, categories, selectedCategory, pagination, catLoading, setCurrentPage, setSelectedCategory,
    navigation, createMarkup, toggleCategorySelection
  } = useBlogsHelper()

  return (
    <>
      <div className="flex flex-col w-full font-manrope landing_page">
        <Navbar active={'blog'} />
        <div className="flex bg-secondary-white relative flex-col">
          <div className="flex justify-center mt-3">
            <span className="px-8 py-2.5 rounded-full text-primary-blue border border-primary-blue text-xl font-semibold">
              Blog
            </span>
          </div>

          <h1 className="text-6xl font-bold text-center mb-5">
            Beauty Insights & Inspirations
          </h1>

          {/* Categories */}
          <div className="w-full overflow-x-auto pb-2.5 mb-12">
            <div className="flex w-fit mx-auto px-5 gap-3">
              {catLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="flex w-24 h-10 animate-pulse rounded-full bg-gray-200" />
                ))
                : categories?.length > 0 &&
                categories?.map((category) => (
                  <button
                    key={category?.id}
                    onClick={() => toggleCategorySelection(category?.id)}
                    className={`flex items-center w-max gap-2 px-6 py-3 text-sm font-medium rounded-full transition-all
                    ${selectedCategory == category?.id ? 'bg-primary-blue text-white' : 'bg-white hover:bg-gray-100'}`}
                  >
                    <span className="w-max">{category.name}</span>
                  </button>
                ))
              }
            </div>
          </div>

        </div>
        <div className="max-w-7xl w-[95%] mx-auto px-4 py-8">
          {/* Blogs Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {
              loading
                ? Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="flex w-full h-96 animate-pulse rounded-[1.25rem] bg-gray-200" />
                ))
                : blogs?.length > 0
                  ? blogs.map((blog) => (
                    <div key={blog.id}
                      onClick={() => {
                        navigation(`/blog/${blog.slug}`)
                      }}
                      className="bg-white rounded-[1.25rem] cursor-pointer flex flex-col p-5 gap-5 h-full shadow-lg overflow-hidden transition-shadow">
                      <div className="flex h-60">
                        <img
                          src={blog?.image}
                          alt={blog?.subject}
                          className="w-full rounded-[1.25rem]  object-cover"
                        />
                      </div>
                      <div className="flex flex-col gap-2.5">
                        <div className="flex items-center justify-between mb-4">
                          <span className="px-3 py-1 bg-primary-blue text-white rounded-full text-sm">
                            {blog?.category_title}
                          </span>
                          <span className="text-sm text-primary-black">{moment(blog?.created_at).format('MMMM DD, YYYY')}</span>
                        </div>
                        <h3 className="text-2xl font-bold ">{blog?.subject}</h3>
                        <p
                          className="text-black text-sm line-clamp-3"
                        >
                          {createMarkup(blog?.message)}
                        </p>
                      </div>
                    </div>
                  ))
                  : <div className="flex justify-center items-center sm:col-span-2 lg:col-span-3">
                    <NotFound />
                  </div>
            }
          </div>
        </div>

        {!loading && blogs?.length > 0 &&
          <Pagination
            currentPage={currentPage}
            totalCount={pagination?.total_pages}
            pageSize={10}
            onPageChange={page => { setCurrentPage(page) }}
          />
        }
        <Footer />
      </div>
    </>
  );
};

export default Blogs;