import { getAllMaintenanceAPI, getSingleMaintenanceAPI, updateMaintenance } from "Adapters/Api/maintenance";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


const useMaintenance = () => {
    const dispatch = useDispatch();
    const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");

    // redux states
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const { selected_location } = state?.locations;
    const maintanence = state?.maintanence?.maintanence
    const maintanence_pagination = state?.maintanence?.maintanence_pagination
    const PageSize = 10

    // states
    const [openCreatePopup, setOpenCreatePopup] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openUpdateStatus, setOpenUpdateStatus] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [viewDetailId, setViewDetailId] = useState(false)
    const [detailLoading, setDetailLoading] = useState(false)
    const [maintananceDetail, setMaintananceDetail] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const getMaintenanceRequests = (page) => {
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        setLoading(true)
        if (page)
        setCurrentPage(page)
        const payload = {
            location_id: selected_location,
            page: page || currentPage,
        }
        dispatch(getAllMaintenanceAPI(payload, access_token, success, fail))
    }

    const onUpdateStatus = () => {
        const success = () => {
            setSubmitLoading(false)
            setOpenUpdateStatus(false)
            getMaintenanceRequests()
        }
        const fail = () => {
            setSubmitLoading(false)
            setOpenUpdateStatus(false)
            getMaintenanceRequests()
        }
        setSubmitLoading(true)
        const payload = {
            ...openUpdateStatus,
        }
        dispatch(updateMaintenance(payload, access_token, success, fail))
    }

    const getSingleMaintenance = () => {
        const success = (data) => {
            setDetailLoading(false)
            setMaintananceDetail(data?.response?.data)
        }
        const fail = () => { setDetailLoading(false) }
        const payload = {
            id: viewDetailId?.id,
            location: selected_location
        }
        setDetailLoading(true)
        dispatch(getSingleMaintenanceAPI(payload, access_token, success, fail))
    }

    const onSuccessCallBack = () => {
        setLoading(true)
        setSubmitLoading(false)
        setOpenCreatePopup(false)
        setOpenUpdateStatus(false)
        getMaintenanceRequests(1)
    }

    useEffect(() => {
        if (selected_location)
            getMaintenanceRequests()
    }, [selected_location, currentPage])

    useEffect(() => {
        if (viewDetailId)
            getSingleMaintenance()
        else
            setMaintananceDetail([])
    }, [viewDetailId])

    return {
        translation, selectedLanguage, storeDataInLocalStorage, openCreatePopup, setOpenCreatePopup, maintanence, loading, openUpdateStatus, setOpenUpdateStatus, PageSize,
        submitLoading, onUpdateStatus, detailLoading, setViewDetailId, viewDetailId, maintananceDetail, onSuccessCallBack, currentPage, setCurrentPage, maintanence_pagination
    }
}

export default useMaintenance