import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocations } from "../../../../Adapters/Api"
import { getBusinessTax } from "../../../../Adapters/Api/financialSettings"
import LoginBtn from "../../Elements/LoginBtn"
import { useLocation } from "new-components/location/helper";
import Svgs from "Assets/svgs"


const BookingServiceCard = ({ index, totalAmountPaid, couponDiscountedIndex, is_coupon_redeemed, couponsIds, couponType, setShowDiscount, showDiscount, cart_Item, cartItems, service, voucher_redeem, voucher_type, is_voucher_redeemed, membership_service, membership_type, membership_product, OrderData, currency }) => {
    const [discount, setDiscount] = useState(null)
    const [updatedPrice, setUpdatedPrice] = useState(null)

    const { locationDetail } = useLocation();
    const active_currency_id = locationDetail?.currency?.id


    let item_price = 0
    if (cart_Item?.selected_promotion_type == "Purchase Discount") {
        if (service?.discount_price == false) {
            if (service?.price || service?.full_price || service?.currency_membership || service?.gift_card_details || service?.installment_price) {
                item_price += service?.installment_price ? service?.installment_price
                    : service?.price
                        ? service?.price
                        : service?.full_price
                            ? service?.full_price
                            : service?.currency_membership
                                ? service?.currency_membership?.find(itm => itm?.currency === active_currency_id)?.price
                                : service?.gift_card_details
                                    ? service?.gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price
                                    : 0
            }
        } else {
            if (service?.discount_price || service?.discount_price == 0) {
                item_price += service?.discount_price
            } else if (service?.installment_price) {
                item_price += service?.installment_price
            }
            else if (service?.price || service?.full_price || service?.currency_membership || service?.gift_card_details) {
                item_price += service?.price ? service?.price : service?.full_price ? service?.full_price : service?.currency_membership ? service?.currency_membership?.find(itm => itm?.currency === active_currency_id)?.price : service?.gift_card_details ? service?.gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price : 0;
            }
        }
    } else {
        cartItems?.filter(itm => itm?.id == service?.id)?.filter(({ price, full_price, currency_membership, gift_card_details, quantity, discount_price, installment_price }) => {
            if (discount_price || discount_price == 0) {
                item_price += discount_price
            } else if (installment_price) {
                item_price += installment_price
            } else if (price || full_price || currency_membership || gift_card_details) {
                item_price += price ? price : full_price ? full_price : currency_membership ? currency_membership?.find(itm => itm?.currency === active_currency_id)?.price : service?.gift_card_details ? service?.gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price : 0;
            }
        });
    }

    useEffect(() => {
        let price = item_price?.toString(); // Convert price to string
        price = (+price)?.toFixed(2)
        setUpdatedPrice(price)
    }, [item_price, OrderData, service])

    useEffect(() => {
        setDiscount()
        if (is_voucher_redeemed == true && voucher_redeem && OrderData?.ids?.find(itm => (itm?.selection_type.toLowerCase() == voucher_type.toLowerCase() && itm?.id == service?.id))) {
            setUpdatedPrice(item_price - (voucher_redeem * item_price) / 100)
            setDiscount({ id: service?.id, percentage: voucher_redeem })
            setShowDiscount(true)
        }


        if (membership_service?.length > 0 && membership_type === "Free") {
            const filteredServices = OrderData?.ids?.filter(itm => itm?.selection_type === "SERVICE");
            let matchingService = {}
            if (cart_Item?.selected_promotion_type == "Purchase Discount" && service?.discount_price == false) {
                const servicePrice = service?.price;
                setUpdatedPrice((+item_price - +servicePrice)?.toFixed(2));
                setDiscount({ id: service?.id, percentage: 100 })
                setShowDiscount(true)
            } else {
                matchingService = filteredServices?.find(itm => itm?.id == service?.id);
                if (matchingService && matchingService?.id == service?.id && membership_service.some(item => item?.service === matchingService?.id)) {
                    const servicePrice = matchingService?.discount_price || matchingService?.discount_price == 0 ? matchingService?.discount_price : matchingService?.price;
                    setUpdatedPrice((+item_price - +servicePrice)?.toFixed(2));
                    setDiscount({ id: service?.id, percentage: 100 })
                    setShowDiscount(true)
                }
            }
        }

        if (membership_product?.length > 0 && membership_type == "Free") {
            const productPercantage = membership_product?.find(itm => itm?.product == service?.id)?.percentage
            if (productPercantage) {
                setDiscount({ id: service?.id, 'percentage': productPercantage })
                setShowDiscount(true)
                setUpdatedPrice((item_price - (productPercantage * item_price) / 100)?.toFixed(2))
            }
        }

        if (membership_service?.length > 0 && membership_type == "Limited") {
            const serviceLimitedPercantage = membership_service?.find(itm => itm?.service == service?.id)?.percentage
            if (serviceLimitedPercantage) {
                setDiscount({ id: service?.id, percentage: serviceLimitedPercantage })
                setShowDiscount(true)
                setUpdatedPrice((item_price - (serviceLimitedPercantage * item_price) / 100)?.toFixed(2))
            }
        }

        if (membership_product?.length > 0 && membership_type == "Limited") {
            const productLimitedPercantage = membership_product?.find(itm => itm?.product == service?.id)?.percentage
            if (productLimitedPercantage) {
                setDiscount({ id: service?.id, percentage: productLimitedPercantage })
                setShowDiscount(true)
                setUpdatedPrice((item_price - (productLimitedPercantage * item_price) / 100)?.toFixed(2))
            }
        }

        if (OrderData?.is_promotion && service?.discount_percentage) {
            setDiscount({ id: service?.id, percentage: service?.discount_percentage })
            setShowDiscount(true)
        }

        if (is_coupon_redeemed === true) {
            if (couponType == 1 || couponType == 2) {
                let matchingId = couponsIds?.length > 0 ? couponsIds?.find(itm => itm?.id == service?.id) : ''
                if (matchingId && !matchingId?.is_coupon_redeemed) {
                    setDiscount({ id: service?.id, percentage: matchingId?.discount_percentage })
                    setShowDiscount(true)
                    setUpdatedPrice(+item_price - +matchingId?.discount_price)
                }
            }
        } else {
            setShowDiscount(false)
        }
    }, [service, OrderData, cart_Item?.selected_promotion_type, is_voucher_redeemed, voucher_type, membership_service, membership_type, item_price, membership_service?.length, membership_product?.length, membership_product, voucher_redeem, couponType, couponsIds?.length, couponsIds, is_coupon_redeemed])

    return (
        <>
            <div className='grid grid-cols-3 items-center justify-between gap-2 text-sm '>
                <p className='text-[#7B7B7B] col-span-2'><span className="line-clamp-2 overflow-ellipsis">{service?.name}</span> <span className="font-semibold">{(discount != undefined && (discount?.id == service?.id) && !service?.is_coupon_redeemed) ? `(Discount ${discount?.percentage}%)` : ''}</span></p>
                <p className='text-[#7B7B7B] flex gap-2 col-span-1 items-end justify-end'>
                    <span className='text-primary font-semibold'>{service?.quantity} x</span>
                    <span>
                        {(discount?.id && showDiscount)
                            && discount?.id == service?.id
                            && (!service?.is_coupon_redeemed && discount?.percentage == 100)
                            ? 0
                            : updatedPrice
                                ? parseFloat(parseFloat(updatedPrice)?.toFixed(2))
                                : 0
                        } {' '}
                        {currency}
                    </span>
                    {/* {service?.discount_price !== undefined ?
                        <span>  {service?.discount_price ? service?.discount_price : service?.price ? service?.price : 0} {currency} </span> :
                        <span>  {service?.currency_membership ? service?.currency_membership?.find(itm => itm?.currency === active_currency_id)?.price : 0} {currency} </span>} */}
                </p>
            </div>
        </>
    )
}


const SaleBookingDetailCard = ({ loyaltyDiscountAmount, subTotal, setCouponDiscountValue, is_gift_card_redeemed,
    setSelectedPaymentMethods, selectedPaymentMethods, taxApplied, setLocationTax, couponDiscountValue, setTotalPriceWithoutTax,
    is_coupon_redeemed, couponsIds, setTax, singleTaxName, discountValue, setDiscountValue, totalAmountPaid, cart_Item,
    selected_location, is_redeemed, setIs_redeemed, setRedeemed_points, setLoyaltyDiscountAmount, setRedeemed_id, setOrderData,
    payNowBtnLoading, membership_product, membership_service, membership_type, is_voucher_redeemed, OrderData, payBtnDisabled,
    voucher_redeem, voucher_type, onTipDelete, employee_tips, previous_price, checkout_price, total_amount, payNowBtnDisabled,
    data, tax, location, onPayClick, payNowBtn, type, donation, setDonation }) => {
    const dispatch = useDispatch()
    const { locationDetail } = useLocation();

    const state = useSelector(state => state)
    const { cartItem } = useSelector((state) => state);
    const cartItems = cartItem.cart_items;
    const location_currency = state?.utility?.currency?.location_currency;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const active_currency_id = locationDetail?.currency?.id

    const taxTypeForGroup = localStorage.getItem('taxTypeForGroup')

    const [total_price, setTotalPrice] = useState(0)
    const [taxFreeAmount, setTaxFreeAmount] = useState(0)
    const [showDiscount, setShowDiscount] = useState(true)
    const [taxType, setTaxType] = useState()
    const [singleTax, setSingleTax] = useState(null)
    const [taxForGroup, setTaxForGroup] = useState(null)
    const [disableResetBtn, setDisableResetBtn] = useState(true)

    const onDeleteVoucherMembership = () => {
        setSelectedPaymentMethods([])
        if (OrderData?.is_voucher_redeemed && OrderData?.is_voucher_redeemed === true) {

            const updatedOrderData = { ...OrderData };
            delete updatedOrderData.is_voucher_redeemed;
            delete updatedOrderData.redeemed_voucher_id;
            delete updatedOrderData.voucher_redeem;
            delete updatedOrderData.voucher_type

            const filteredItemsforOrderIds = updatedOrderData?.ids?.map((itm) => {
                if (itm?.is_voucher_redeemed) {
                    delete itm.is_voucher_redeemed
                    delete itm.redeemed_price
                    delete itm.discount_percentage
                }
                return itm
            })

            setOrderData({ ...updatedOrderData, ids: filteredItemsforOrderIds });
            setDiscountValue(0)
            setShowDiscount(false)
        }
        else if (OrderData?.is_membership_redeemed && OrderData?.is_membership_redeemed === true) {
            const updatedOrderData = { ...OrderData };
            delete updatedOrderData.is_membership_redeemed;
            delete updatedOrderData.redeemed_membership_id;
            delete updatedOrderData.membership_product;
            delete updatedOrderData.membership_service;
            delete updatedOrderData.membership_type
            const filteredItemsforOrderIds = updatedOrderData?.ids?.map((itm) => {
                if (itm?.is_membership_redeemed) {
                    delete itm.is_membership_redeemed
                    delete itm.redeemed_price
                    delete itm.discount_percentage
                }
                return itm
            })

            setOrderData({ ...updatedOrderData, ids: filteredItemsforOrderIds });
            setDiscountValue(0)
            setShowDiscount(false)
        }
    }

    const onDeletePromotion = () => {
        setSelectedPaymentMethods([])
        const updatedOrderData = { ...OrderData };
        delete updatedOrderData.is_promotion;
        delete updatedOrderData.is_promotion_availed;
        delete updatedOrderData.selected_promotion_id;
        delete updatedOrderData.selected_promotion_type;
        delete updatedOrderData.promotion_discount_sum
        const filteredItemsforOrderIds = updatedOrderData?.ids?.map((itm) => {
            if (itm?.is_promotion) {
                delete itm.is_promotion
                delete itm.discount_percentage
                delete itm.discount_price
            }
            return itm
        })
        setOrderData({ ...updatedOrderData, ids: filteredItemsforOrderIds });
        dispatch({
            type: "REMOVE_PROMOTION",
        });
    }

    const onDeleteLoyalityPoints = () => {
        setSelectedPaymentMethods([])
        const updatedOrderData = { ...OrderData };
        delete updatedOrderData.is_redeemed;
        delete updatedOrderData.redeemed_id;
        delete updatedOrderData.redeemed_points;
        setOrderData(updatedOrderData);
        setIs_redeemed(false)
        setRedeemed_points('')
        setLoyaltyDiscountAmount('')
        setRedeemed_id("")
    }

    const onDeleteCoupon = () => {
        const updatedOrderData = { ...OrderData };

        const filteredItemsforOrderIds = updatedOrderData?.ids?.filter((item) => !item.newAddedCouponItm)?.map(({ discount_percentage, name, id, discount_price, gift_card_details, currency_membership, price, quantity, selection_type, employee, employee_id, brand, avaliableservicegroup }) => {
            setSelectedPaymentMethods([])
            let this_price = currency_membership?.find(itm => itm?.currency === active_currency_id)?.price
            let gc_price = gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price

            let data = {
                selection_type, name, id, discount_price, avaliableservicegroup, brand, quantity, employee, employee_id, discount_percentage,
                price: price !== undefined ? price : this_price ? this_price : gc_price
            }

            delete data.is_coupon_redeemed
            delete data.redeemed_price
            delete data.discount_percentage
            delete data.coupon_discounted_price
            delete data?.refund_coupon
            delete data?.cashback_coupon
            return data
        })

        dispatch({
            type: 'REMOVE_FROM_CART_FROM_COUPON',
            payload: filteredItemsforOrderIds
        })

        delete updatedOrderData.other_method;
        delete updatedOrderData.coupon_name;
        delete updatedOrderData.coupon_type;
        delete updatedOrderData.coupon_type_value;
        delete updatedOrderData.couponsIds;
        delete updatedOrderData.couponDiscountPercentage;
        delete updatedOrderData.couponSpendAmount;
        delete updatedOrderData.is_coupon_redeemed;
        delete updatedOrderData?.redeemed_coupon_id
        delete updatedOrderData?.refund_coupon
        delete updatedOrderData?.cashback_coupon
        setSelectedPaymentMethods([])
        setOrderData({ ...updatedOrderData, ids: filteredItemsforOrderIds });
    }


    const onDeleteGiftCard = () => {
        if (OrderData?.is_gift_card_redeemed) {
            const updatedOrderData = { ...OrderData }
            delete updatedOrderData?.is_gift_card_redeemed
            setSelectedPaymentMethods([])
            setOrderData({
                ...updatedOrderData,
                appliedGiftCard: {
                    purchased_gift_card_id: '',
                    partial_price: 0
                }
            });
        }
    }

    useEffect(() => {
        let total_items_amount = 0
        let discountedPrice = 0
        let total_price = previous_price ? (+previous_price)?.toFixed(2) : 0

        if (cart_Item?.selected_promotion_type == "Bundle_Fixed_Service") {
            const previousPrice = previous_price ? (+previous_price)?.toFixed(2) : 0
            total_price -= previousPrice
        }

        if (cart_Item?.selected_promotion_type == "Fixed_Price_Service") {
            const previousPrice = previous_price ? (+previous_price)?.toFixed(2) : 0
            total_price -= previousPrice
        }

        cartItems?.filter(({ price, full_price, currency_membership, quantity, discount_price, gift_card_details, installment_price }) => {
            if (discount_price || discount_price == 0) {
                total_price += discount_price * quantity
            } else if (installment_price) {
                total_price += installment_price * quantity
            }
            else if (price || full_price || currency_membership || gift_card_details) {
                total_price += price ? +price * quantity : full_price ? +full_price * quantity : currency_membership ? +currency_membership?.find(itm => itm?.currency === active_currency_id)?.price * quantity : gift_card_details ? +gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price * quantity : 0;
            }
        });
        total_items_amount = total_price;

        // ===================================== Membership Calculations ===================================== //
        if (membership_service?.length > 0 && membership_type === "Free") {
            const servicesFiltered = OrderData?.ids?.filter(itm => itm?.selection_type === "SERVICE");
            const filteredServices = servicesFiltered?.filter(itm =>
                membership_service?.some(mmbr_serv => mmbr_serv?.service === itm?.id)
            );
            const sumOfFreeServicesPrices = filteredServices?.map(srv => (srv?.discount_price * srv?.quantity || srv?.price * srv?.quantity))?.reduce((total, percentage) => total + percentage, 0);
            if (sumOfFreeServicesPrices) {
                total_price -= sumOfFreeServicesPrices;
                discountedPrice = discountedPrice + sumOfFreeServicesPrices
            }
        }

        if (membership_product?.length > 0 && membership_type == "Free") {
            const filteredFreeProductsPrices = membership_product?.map((prod) => {
                const matchingProd = OrderData?.ids?.find(itm => itm?.id == prod?.product)
                const matchingProdPrice = matchingProd?.discount_price || matchingProd?.discount_price == 0 ? matchingProd?.discount_price * matchingProd?.quantity : matchingProd?.price * matchingProd?.quantity
                return (
                    matchingProdPrice * prod?.percentage / 100
                )
            })

            const sumOfFreeProductPrices = filteredFreeProductsPrices.reduce((total, percentage) => total + percentage, 0);
            if (sumOfFreeProductPrices) {
                total_price -= sumOfFreeProductPrices
                discountedPrice = discountedPrice + sumOfFreeProductPrices
            }
        }

        if (membership_service?.length > 0 && membership_type == "Limited") {
            const filteredLimitedServicesPrices = membership_service?.map((srv) => {
                const matchingServ = OrderData?.ids?.find(itm => itm?.id == srv?.service)
                const matchingServPrice = matchingServ?.discount_price || matchingServ?.discount_price == 0 ? matchingServ?.discount_price * matchingServ?.quantity : matchingServ?.price * matchingServ?.quantity
                return (
                    matchingServPrice * srv?.percentage / 100
                )
            })
            const sumOfLimitedServicesPrices = filteredLimitedServicesPrices.reduce((total, percentage) => total + percentage, 0);
            if (sumOfLimitedServicesPrices) {
                total_price -= sumOfLimitedServicesPrices
                discountedPrice = discountedPrice + sumOfLimitedServicesPrices
            }
        }

        if (membership_product?.length > 0 && membership_type == "Limited") {
            const filteredLimitedProductsPrices = membership_product?.map((prod) => {
                const matchingProd = OrderData?.ids?.find(itm => itm?.id == prod?.product)
                const matchingProdPrice = matchingProd?.discount_price || matchingProd?.discount_price == 0 ? matchingProd?.discount_price * matchingProd?.quantity : matchingProd?.price * matchingProd?.quantity
                return (
                    matchingProdPrice * prod?.percentage / 100
                )
            })
            const sumOfLimitedProductPrices = filteredLimitedProductsPrices.reduce((total, percentage) => total + percentage, 0);
            if (sumOfLimitedProductPrices) {
                total_price -= sumOfLimitedProductPrices
                discountedPrice = discountedPrice + sumOfLimitedProductPrices
            }
        }
        // ===================================== Membership Calculations ===================================== //


        // ===================================== Voucher Calculations ===================================== //
        if (voucher_redeem && OrderData?.ids?.find(itm => (itm?.selection_type.toLowerCase() == voucher_type.toLowerCase()))) {
            const sumOfFreeServicesPrices = OrderData?.ids
                ?.filter(itm => itm?.selection_type?.toLowerCase() === voucher_type.toLowerCase())
                ?.reduce((total, srv) => total + (srv?.discount_price * srv?.quantity || srv?.price * srv?.quantity), 0) || 0;

            if (sumOfFreeServicesPrices) {
                const discountAmount = (voucher_redeem * sumOfFreeServicesPrices) / 100;
                total_price -= discountAmount;
                discountedPrice += discountAmount;
            }
        }
        // ===================================== Voucher Calculations ===================================== //


        // ===================================== Coupon Calculations ===================================== //
        if (OrderData?.is_coupon_redeemed) {
            if (OrderData?.coupon_type == 'refund') {
                const discount = OrderData?.refund_coupon?.coupon_discounted_price
                setCouponDiscountValue(discount)
                total_price -= discount
            } else if (OrderData?.coupon_type == 'Cashback') {
                const discount = OrderData?.cashback_coupon?.coupon_discounted_price
                if (total_price >= discount) {
                    total_price -= discount
                    setCouponDiscountValue(discount)
                } else {
                    setCouponDiscountValue(total_price)
                    total_price = 0
                }
            } else {
                if (OrderData?.coupon_type_value == 1 || OrderData?.coupon_type_value == 2) {
                    if (couponsIds?.length > 0) {
                        const totalDiscountPrice = couponsIds?.reduce((total, item) => total + (item.discount_price * item.quantity || 0), 0);
                        setCouponDiscountValue(totalDiscountPrice)
                        total_price -= totalDiscountPrice
                    }
                } else {
                    if (total_price >= OrderData?.couponSpendAmount) {
                        setCouponDiscountValue(+(total_price * OrderData?.couponDiscountPercentage / 100))
                        total_price = (total_price - +(total_price * OrderData?.couponDiscountPercentage / 100))
                    }
                }
            }
        }
        // ===================================== Coupon Calculations ===================================== //


        // ===================================== Gift Card Calculations ===================================== //
        if (OrderData?.is_gift_card_redeemed) {
            // setTotalAmountPaid(0)
            // if (total_price >= +appliedGiftCard?.partial_price) {
            total_items_amount = (total_items_amount - +(OrderData?.appliedGiftCard?.partial_price))
            // }
        }
        // ===================================== Gift Card Calculations ===================================== //


        // ===================================== Loyalty Calculations ===================================== //
        if (is_redeemed) {
            total_price = total_price - +loyaltyDiscountAmount
        }
        // ===================================== Loyalty Calculations ===================================== //


        if (employee_tips?.length > 0) {
            const total_tip_amount = employee_tips?.reduce(function (total, obj) {
                return total + (+obj?.tip);
            }, 0)
            let total_tips = +total_tip_amount
            total_price = total_price + total_tips
        }
        // if (tax) {
        //     total_price = total_price + (tax * total_items_amount) / 100
        // }
        // total_amount(total_items_amount.toFixed(2))
        total_price = total_price?.toString()
        setTotalPriceWithoutTax((+total_price)?.toFixed(2))
        setTotalPrice((+total_price)?.toFixed(2))
        setTaxFreeAmount((+total_items_amount).toFixed(2))
        total_amount((+total_price)?.toFixed(2))
        checkout_price((+total_price)?.toFixed(2))
        setDiscountValue((+discountedPrice)?.toFixed(2))
    }, [state.financialSettings.businessTaxs, location, employee_tips, data, OrderData, loyaltyDiscountAmount])


    useEffect(() => {
        if ((state.locations.locations.length == 0) && state.business.business?.id) {
            dispatch(getLocations(state.business.business?.id))
        }

        if ((state.financialSettings.businessTaxs.length == 0) && state.business.business?.id) {
            dispatch(
                getBusinessTax(state.business.business?.id)
            )
        }

    }, [state.business.business])

    useEffect(() => {
        let total_tips = 0
        let tax_amount = 0
        if (employee_tips?.length > 0) {
            const total_tip_amount = employee_tips?.reduce(function (total, obj) {
                return total + (+obj?.tip);
            }, 0)
            total_tips = +total_tip_amount
        }

        let location_taxes = []
        let allTaxes = []
        if (state.financialSettings.businessTaxs.length > 0) {
            location_taxes = state.financialSettings.businessTaxs.filter(itm => itm.tax_type == 'Location' && itm?.location?.id == selected_location)
            setTaxType(location_taxes[0]?.parent_tax[0]?.tax_type)
        }
        if (location_taxes?.length > 0) {
            allTaxes = location_taxes[0]?.parent_tax[0]?.parent_tax
            setTaxForGroup(location_taxes[0]?.parent_tax[0]?.parent_tax)
        }
        if (taxType) {
            if (taxType == 'Group') {
                let tax1Price = 0
                let tax2Price = 0
                let totalPriceWithTax1 = 0
                if (taxTypeForGroup == 'Seperately') {
                    tax1Price = (allTaxes[0]?.tax_rate * (total_price - total_tips)) / 100
                    totalPriceWithTax1 = +tax1Price + (+total_price - +total_tips)
                    tax2Price = allTaxes[1]?.tax_rate * totalPriceWithTax1 / 100
                    tax_amount = +tax1Price + +tax2Price
                    allTaxes?.forEach((tx, i) => {
                        if (i == 0) {
                            tx.value = tax1Price
                        } else {
                            tx.value = tax2Price
                        }
                    })
                } else {
                    const totalCombinedTax = allTaxes?.reduce(function (total, obj) {
                        return total + +(obj?.tax_rate);
                    }, 0)
                    tax1Price = (allTaxes[0]?.tax_rate * (total_price - total_tips)) / 100
                    tax2Price = allTaxes[1]?.tax_rate * (total_price - total_tips) / 100
                    allTaxes?.forEach((tx, i) => {
                        if (i == 0) {
                            tx.value = tax1Price
                        } else {
                            tx.value = tax2Price
                        }
                    })
                    tax_amount = (+totalCombinedTax * (total_price - total_tips)) / 100
                }
            }
            else {
                let tax_perc = location_taxes[0]?.parent_tax[0]?.tax_rate
                tax_amount = (tax_perc * (total_price - total_tips)) / 100
                setSingleTax([{ ...location_taxes[0]?.parent_tax[0], value: tax_amount }])
                setTax(tax_perc)
            }
        } else {
            tax_amount = 0
        }
        function roundToNearest(value) {
            return Math.round((value + Number.EPSILON) * 100) / 100;
        }

        setLocationTax(roundToNearest(tax_amount))
        // setCalculatedTax(+tax_amount)
    }, [tax, total_price, employee_tips, state.financialSettings.businessTaxs, state.financialSettings.businessTaxs.length, taxTypeForGroup, selected_location, taxType])

    useEffect(() => {
        if (OrderData?.is_voucher_redeemed || OrderData?.is_membership_redeemed || OrderData?.is_redeemed || OrderData?.is_gift_card_redeemed || OrderData?.is_coupon_redeemed || selectedPaymentMethods?.length > 0)
            setDisableResetBtn(false)
        else
            setDisableResetBtn(true)
    }, [OrderData, selectedPaymentMethods])


    const onResetClick = () => {
        setSelectedPaymentMethods([])
        if (OrderData?.is_voucher_redeemed && OrderData?.is_voucher_redeemed === true) {
            onDeleteVoucherMembership()
        }
        if (OrderData?.is_membership_redeemed && OrderData?.is_membership_redeemed === true) {
            onDeleteVoucherMembership()
        }
        if (OrderData.is_redeemed) {
            onDeleteLoyalityPoints()
        }
        if (OrderData?.is_gift_card_redeemed) {
            onDeleteGiftCard()
        }
        if (OrderData?.is_coupon_redeemed) {
            onDeleteCoupon()
        }
    }

    let remaining_price = +(+total_price + +taxApplied?.toFixed(2) + (donation ? +donation : 0) - (+totalAmountPaid).toFixed(2)).toFixed(2)
    let appliedGiftCard_amount = OrderData?.is_gift_card_redeemed ? +OrderData?.appliedGiftCard?.partial_price : 0

    return (
        <>
            <div className='rounded-md appointment-shadow bg-white'>
                <div className='p-3 px-6 border-b'>
                    <h2 className='font-semibold text-[#101928] text-2xl'>
                        {selectedLanguage?.name == "English" ? "Cart Details" : translation[1551]?.value ? translation[1551]?.value : "Cart Details"}
                    </h2>
                </div>
                <div className='p-3 flex flex-col gap-2'>
                    <p className='text-[#000000]  text-xl font-medium px-4'>
                        {selectedLanguage?.name == "English" ? "Total cart items" : translation[392]?.value ? translation[392]?.value : "Total cart items"}
                    </p>
                    {data && data?.length > 0 ?
                        <div className='flex flex-col gap-2 px-4'>
                            {
                                data?.map((service, index) => {
                                    return (
                                        <BookingServiceCard
                                            service={service}
                                            index={index}
                                            totalAmountPaid={totalAmountPaid}
                                            couponDiscountedIndex={service?.is_coupon_redeemed ? index : undefined}
                                            couponsIds={OrderData?.couponsIds}
                                            couponType={OrderData?.coupon_type_value}
                                            is_coupon_redeemed={OrderData?.is_coupon_redeemed}
                                            cartItems={cartItems}
                                            OrderData={OrderData}
                                            voucher_redeem={voucher_redeem}
                                            voucher_type={voucher_type}
                                            membership_product={membership_product}
                                            membership_service={membership_service}
                                            membership_type={membership_type}
                                            key={index}
                                            currency={location_currency}
                                            is_voucher_redeemed={is_voucher_redeemed}
                                            cart_Item={cart_Item}
                                            showDiscount={showDiscount}
                                            setShowDiscount={setShowDiscount}
                                        />
                                    )
                                })
                            }
                        </div>
                        :
                        <>
                            No {type?.toLowerCase()} Selected
                        </>
                    }
                    <hr />
                    {/* {(is_redeemed || is_coupon_redeemed || OrderData?.is_membership_redeemed || OrderData?.is_voucher_redeemed) && <hr />} */}
                    {OrderData?.is_voucher_redeemed &&
                        <div className='flex items-center justify-between gap-2 text-sm px-4'>
                            <LoginBtn
                                onClick={onDeleteVoucherMembership}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <div className="flex justify-between w-11/12 flex-1">
                                <p className='font-semibold'>
                                    {selectedLanguage?.name == "English" ? "Paid from Voucher" : translation[218]?.value ? translation[218]?.value : "Paid from Voucher"}{" "}
                                    {voucher_redeem}%
                                </p>
                                <p className='text-[#A1A1A1] flex items-center gap-2'>{(+discountValue)?.toFixed(2)} {location_currency}</p>
                            </div>
                        </div>
                    }
                    {OrderData?.is_promotion &&
                        <div className='flex items-center justify-between gap-2 text-sm px-4'>
                            <LoginBtn
                                onClick={onDeletePromotion}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <div className="flex justify-between w-11/12 flex-1">
                                <p className='font-semibold'>
                                    Paid from Promotion{" "}
                                </p>
                                <p className='text-[#A1A1A1] flex items-center gap-2'>{(+OrderData?.promotion_discount_sum)?.toFixed(2)} {location_currency}</p>
                            </div>
                        </div>
                    }
                    {OrderData?.is_membership_redeemed &&
                        <div className='flex items-center justify-between gap-2 text-sm px-4'>
                            <LoginBtn
                                onClick={onDeleteVoucherMembership}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <div className="flex justify-between w-11/12 flex-1">
                                <p className='font-semibold'>
                                    {selectedLanguage?.name == "English" ? "Membership Redeemed" : translation[219]?.value ? translation[219]?.value : "Membership Redeemed"}
                                </p>
                                <p className='text-[#A1A1A1] flex items-center gap-2'>{(+discountValue)?.toFixed(2)} {location_currency}</p>
                            </div>
                        </div>
                    }
                    {is_redeemed &&
                        <div className='flex items-center justify-between gap-2 text-sm px-4'>
                            <LoginBtn
                                onClick={onDeleteLoyalityPoints}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <div className="flex justify-between w-11/12 flex-1">
                                <p className='font-semibold'>
                                    {selectedLanguage?.name == "English" ? "Redeemed Loyality Points " : translation[220]?.value ? translation[220]?.value : "Redeemed Loyality Points "}</p>
                                <p className='text-[#A1A1A1] flex items-center gap-2'>
                                    <span>{loyaltyDiscountAmount} {location_currency}</span>
                                </p>
                            </div>
                        </div>
                    }
                    {is_coupon_redeemed &&
                        <div className='flex items-center justify-between gap-2 text-sm px-4'>
                            <LoginBtn
                                onClick={onDeleteCoupon}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <div className="flex justify-between w-11/12 flex-1">
                                <p className='font-semibold'>
                                    {selectedLanguage?.name == "English" ? "Coupon Redeem " : translation[1480]?.value ? translation[1480]?.value : "Coupon Redeem "}
                                </p>
                                {(couponDiscountValue && couponDiscountValue !== '0') &&
                                    <div className="flex items-center justify-between gap-2 text-sm ">
                                        {/* <p>   {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}:</p> */}
                                        <p className='text-[#A1A1A1] flex items-center gap-2'>
                                            <span>{(+couponDiscountValue)?.toFixed(2)} {location_currency}</span>
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className='flex items-center justify-between gap-2 text-sm px-4'>
                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Subtotal" : translation[1712]?.value ? translation[1712]?.value : "Subtotal"}</p>
                        <p className='text-[#A1A1A1] flex items-center gap-2'>
                            {subTotal} {location_currency}
                        </p>
                    </div>


                    {taxType == 'Group' ?
                        taxForGroup?.map((itm, index) => {
                            return (
                                <div key={index} className='flex items-center justify-between gap-4 text-sm px-4'>
                                    <p className='font-semibold text-[#7B7B7B]'>{itm?.name} ( {itm?.tax_rate}% )</p>
                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                        <span>{itm?.value?.toFixed(2)} {location_currency}</span>
                                    </p>
                                </div>
                            )
                        })
                        : tax > 0 &&
                        <div className='flex items-center justify-between gap-4 text-sm px-4'>
                            {/* <p className='font-semibold'>{tax_name} ( {tax_percentage}% )</p> */}
                            <p className='font-semibold text-[#7B7B7B]'>{singleTaxName?.parent_tax[0]?.name} ({tax}%)</p>
                            <p className='text-[#A1A1A1] flex items-center gap-2'>

                                <span>
                                    {/* {(tax * taxFreeAmount) / 100} {location_currency} */}
                                    {taxApplied?.toFixed(2)} {location_currency}
                                </span>
                                {/* <span>{location_currency}. {loc_tax}</span> */}
                            </p>
                        </div>
                    }
                    {
                        employee_tips?.map(tp => <>
                            <div className='flex items-center justify-between gap-2 text-sm px-4'>
                                <LoginBtn
                                    onClick={() => {
                                        onTipDelete(tp)
                                    }}
                                >
                                    <Svgs.Delete fill="#fff" />
                                </LoginBtn>
                                <div className="flex justify-between w-11/12 flex-1">

                                    <p className='font-semibold'>
                                        {selectedLanguage?.name == "English" ? "Tip for" : translation[188]?.value ? translation[188]?.value : "Tip for"} {" "}
                                        {tp.employee}</p>

                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                        <span>{tp.tip} {location_currency}</span>
                                        {/* <span>{location_currency}  {tip}</span> */}
                                        {/* <span>{location_currency}  {Math.round((tip * services[0].price) / 100)}</span> */}
                                    </p>
                                </div>
                            </div>
                        </>)
                    }
                    {donation &&
                        <div className='flex items-center justify-between gap-2 text-sm px-4'>
                            <LoginBtn
                                onClick={() => { setSelectedPaymentMethods([]); setDonation("") }}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <div className="flex justify-between w-11/12 flex-1">
                                <p className='font-semibold'>
                                    Donation
                                </p>
                                <p className='text-[#A1A1A1] flex items-center gap-2'>
                                    <span>{donation} {location_currency}</span>
                                </p>
                            </div>
                        </div>
                    }
                    {is_gift_card_redeemed &&
                        <div className='flex items-center justify-between gap-2 text-sm px-4'>
                            <LoginBtn
                                onClick={onDeleteGiftCard}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <div className="flex justify-between w-11/12 flex-1">
                                <p className='font-semibold'>
                                    {selectedLanguage?.name == "English" ? "Gift Card Redeem" : translation[1574]?.value ? translation[1574]?.value : "Gift Card Redeem"}
                                </p>
                                <div className="flex items-center justify-between gap-2 text-sm ">
                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                        <span>{(+appliedGiftCard_amount)?.toFixed(2)} {location_currency}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    }
                    <hr />
                    <div className='flex items-center justify-between gap-2 text-sm px-4'>
                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Payable" : translation[1642]?.value ? translation[1642]?.value : "Payable"}</p>
                        <p className='text-[#A1A1A1] flex items-center gap-2'>
                            {(totalAmountPaid - appliedGiftCard_amount)?.toFixed(2)} {location_currency}
                        </p>
                    </div>
                    <div className='flex items-center justify-between gap-2 text-sm px-4'>
                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Remaining" : translation[1562]?.value ? translation[1562]?.value : "Remaining"}</p>
                        <p className='text-[#A1A1A1] flex items-center gap-2'>
                            {/* {((Math.floor(((+total_price) + (+taxApplied)) * 100) / 100) - totalAmountPaid).toFixed(2)} */}
                            {remaining_price < 0
                                ? 0.00
                                : remaining_price?.toFixed(2)}
                            {' '}
                            {location_currency}
                        </p>
                    </div>
                    <div className='flex items-center justify-between gap-2 text-sm px-4'>
                        <p className='font-semibold'>Change</p>
                        <p className='text-[#A1A1A1] flex items-center gap-2'>
                            {/* {((Math.floor(((+total_price) + (+taxApplied)) * 100) / 100) - totalAmountPaid).toFixed(2)} */}
                            {remaining_price < 0
                                ? Math.abs(remaining_price)?.toFixed(2)
                                : 0
                            }
                            {' '}
                            {location_currency}
                        </p>
                    </div>


                    <hr />

                    <div className='flex items-center justify-between gap-4 text-sm '>

                        {payNowBtn &&
                            <div className="flex flex-start gap-3">
                                <LoginBtn
                                    className={`${(+remaining_price > 0 || payNowBtnDisabled || payNowBtnLoading || payBtnDisabled) ? '!bg-[#E8E8E8] !text-[#B4B4B4] ' : "!bg-[#E0EBFD] !text-[#5679FF] "} !border-none !px-7`}
                                    disabled={+remaining_price > 0 || payNowBtnDisabled || payNowBtnLoading || payBtnDisabled}
                                    text={selectedLanguage?.name == "English" ? "Checkout" : translation[182]?.value ? translation[182]?.value : "Checkout"}
                                    onClick={() => {
                                        onPayClick({
                                            // taxes_applied: loc_tax_group,
                                            tax_type: taxType,
                                            taxes_applied_group: taxForGroup,
                                            single_tax_applied: singleTax,
                                            taxes_applied_single_value: taxApplied,
                                            taxes_applied_single: tax,
                                        })
                                    }}
                                    // onClick={onPayClick}
                                    loading={payNowBtnLoading}
                                />
                                <LoginBtn
                                    customThemeBtn={'custom-theme-btn-checkout'}
                                    disabled={disableResetBtn}
                                    className={`${!disableResetBtn ? "bg-[#E0EBFD] text-[#5679FF] " : 'bg-[#E8E8E8] !text-[#B4B4B4] '}  !px-7`}
                                    borderBtn
                                    text={selectedLanguage?.name == "English" ? "Reset" : translation[1141]?.value ? translation[1141]?.value : "Reset"}
                                    onClick={onResetClick}
                                />
                            </div>
                        }


                        <div className={`flex gap-1 text-center ${!payNowBtn ? 'flex-1 justify-between' : 'flex-col'}`}>
                            <p className='text-[#A1A1A1] flex items-center gap-2'>
                                {selectedLanguage?.name == "English" ? "Total Amount" : translation[183]?.value ? translation[183]?.value : "Total Amount"}{" "}
                            </p>
                            <p className='font-semibold'>{(((+total_price) + (+taxApplied) + (donation ? +donation : 0)) - appliedGiftCard_amount)?.toFixed(2)} {location_currency}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


SaleBookingDetailCard.defaultProps = {
    payNowBtn: true,
    onPayClick: () => { },
    payBtnText: 'Pay Now',
    status: 'Pending',
    payNowBtnLoading: false,
    payNowBtnDisabled: true,
    data: [],
    location: {},
    tip: undefined,
    type: 'SERVICE'
}

export default SaleBookingDetailCard