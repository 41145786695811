import Svgs from "Assets/svgs";
import useContactUsHelper from "Components/NstyleApp/ContactUs/helper";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import { useState } from "react";

const faqs = [
    {
        question: "How can I book an appointment?",
        answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
        question: "Do you offer walk-in services?",
        answer: "Yes, we offer walk-in services during business hours.",
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept credit cards, debit cards, and online payments.",
    },
    {
        question: "What are the workshops included with the course?",
        answer: "The course includes various workshops on practical skills and knowledge.",
    },
];

const FAQ = () => {
    const { onNewsChangeHandler, newsLetterSubmission, newsLetterLoading, newsLetterEmail, openIndex, toggleFAQ } = useContactUsHelper()

    return (
        <>
            <div className="flex bg-secondary-white">
                <div className="w-[95%] max-w-[1200px] mx-auto p-6 rounded-lg font-manrope">
                    <h1 className="text-[3.125rem] text-center font-bold leading-tight">Frequently Asked Questions</h1>
                    <div className="flex flex-col gap-5 my-9 w-full">
                        {faqs.map((faq, index) => (
                            <div key={index} className="border-b pb-4">
                                <button
                                    className="flex justify-between items-center w-full font-semibold py-3 focus:outline-none"
                                    onClick={() => toggleFAQ(index)}
                                >
                                    <p className="text-xl font-bold ">
                                        {faq.question}
                                    </p>
                                    <div>
                                        {openIndex === index
                                            ? <Svgs.MinusIcon />
                                            : <Svgs.PlusIcon />
                                        }
                                    </div>
                                </button>
                                {openIndex === index && (
                                    <div className="rounded-[1.25rem] px-7 py-10 bg-white p-4 shadow-xl my-3">
                                        <p className="text-gray-600">{faq.answer}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg_gradient-blue-tr w-full py-7">
                <div className="flex w-[95%] flex-col sm:flex-row gap-5 max-w-[1200px] mx-auto justify-between sm:items-center">
                    <h1 className="text-3xl font-bold sm:max-w-sm text-white">
                        Subscribe to Our Newsletter for Updates!
                    </h1>

                    <div className="flex gap-2.5 w-full sm:max-w-xl bg-white rounded-full p-1.5 relative items-center justify-between">
                        <div className="flex flex-1 ml-5 gap-2.5 items-center">
                            <Svgs.EmailIcon />
                            <input value={newsLetterEmail} onChange={onNewsChangeHandler} type="email" placeholder="Enter your email" className="outline-none flex-1 text-black placeholder:text-black" />
                        </div>
                        <LoginBtn
                            customThemeBtn="bg_gradient-blue-tr min-h-14 rounded-full text-white text-base py-4 px-8 font-semibold"
                            onClick={newsLetterSubmission}
                            loading={newsLetterLoading}
                            text={'Subscribe'}
                        />
                    </div>


                </div>
            </div>
        </>
    );
};

export default FAQ;
