import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import I_Info_Icon from "./../../../Elements/I_Info_Icon";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import Svgs from "Assets/svgs";
import AddLoyaltyCard from "./AddLoyaltyCard";
import useLoyaltyPoint from "./helper";
import Popup from "./../../../../../new-components/popup/index";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LoyalityShimmer from "Components/Utility/Shimmers/LoyalityShimmer";
import FloatInput from "Components/TenantApp/Elements/FloatInput";

function LoyaltyPoints() {
  const {
    all_loyalty,
    errors,
    onBlurHandler,
    loyaltyPoints,
    setLoyaltyPoints,
    loyaltyHandleChange,
    onSubmitLoyalty,
    onUpdateLoyalty,
    is_delete,
    setDelete,
    setDeleteId,
    loadLoyality,
    onDeleteLoyalty,
    currency,
    addLoyalityLoader,
    updateLoyalityLoader,
    translation, selectedLanguage, editErrors, setEditErrors,
    editId, seteditId
  } = useLoyaltyPoint();

  const {
    name,
    // loyaltytype,
    status,
    amount_spend,
    number_points,
    earn_points,
    total_earn_from_points,
    location
  } = loyaltyPoints;

  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const reduxlocationsList = state?.locations?.locations;

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  // const [loyalityLocation, setLoyalityLocation] = useState("");

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">
        <div className="lg:w-[82%] slide-in-bottom">

          <Breadcrumb
            first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
            url={'/dashboard/clients/'}
            second={selectedLanguage?.name == "English" ? " Loyalty Points" : translation[447]?.value ? translation[447]?.value : " Loyalty Points"}
          />
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Loyalty Points" : translation[447]?.value ? translation[447]?.value : "Loyalty Points"}
            </h2>
          </div>

          <div className="flex flex-col gap-5 ">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
              <CircleIcon>
                <Svgs.Promotions />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Add Loyalty" : translation[1088]?.value ? translation[1088]?.value : "Add Loyalty"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Set loyalty criterias for your client on product/service/both" : translation[1089]?.value ? translation[1089]?.value : "Set loyalty criterias for your client on product/service/both"}
                </p>
              </div>
            </div>
            <div className="lg:w-[82%] w-full col-span-1">
              <div className="">
                <>
                  {loadLoyality ?
                    <LoyalityShimmer />
                    :
                    all_loyalty?.length > 0 &&
                    all_loyalty?.map((loyal, index) => {
                      return (
                        <AddLoyaltyCard
                          index={index}
                          data={loyal}
                          loyaltyPoints={loyaltyPoints}
                          errors={editErrors}
                          setErrors={setEditErrors}
                          onBlurHandler={onBlurHandler}
                          loyaltyHandleChange={loyaltyHandleChange}
                          updateLoyalty={(data) => onUpdateLoyalty(data)}
                          onDelete={(id) => {
                            setDelete(true);
                            setDeleteId(id);
                          }}
                          currency={currency}
                          updateLoyalityLoader={updateLoyalityLoader}
                          translation={translation}
                          selectedLanguage={selectedLanguage}
                          editId={editId}
                          seteditId={seteditId}
                        />
                      );
                    })}
                </>
                {!loadingPermission && !employee || (employee && employeePermissions?.client_loyality?.includes("create")) ? (
                  <div className="flex gap-6">
                    <div className="relative">
                      <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                        <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                      </div>
                      {all_loyalty?.length > 0 && <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>}
                    </div>
                    <div className="mt-[2rem] border px-4 py-3 rounded-md w-xl flex flex-col gap-4 flex-1 bg-white">
                      <LoginInput
                        required={true}
                        name={"name"}
                        // charactersMaxLength={25}
                        value={name?.slice(0, 99)}
                        onChange={loyaltyHandleChange}
                        onBlur={() => onBlurHandler({ name: name })}
                        error={errors?.name}
                        title={selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Name" : translation[1090]?.value ? translation[1090]?.value : "Enter Name"}
                      />

                      {/* <Dropwdown
                        // required={true}
                        name="loyaltytype"
                        value={loyaltytype}
                        onChange={loyaltyHandleChange}
                        onBlur={() =>
                          onBlurHandler({ loyaltytype: loyaltytype })
                        }
                        error={errors?.loyaltytype}
                        placeholder="Choose Loyalty Type"
                        options={[
                          { label: "Service", value: "Service" },
                          { label: "Retail", value: "Retail" },
                          { label: "Both", value: "Both" },
                        ]}
                        title={
                          <I_Info_Icon
                            text="Choose Loyalty Type *"
                            info="Choose where you want to implement the loyalty points"
                          />
                        }
                      /> */}

                      <Dropwdown
                        required={true}
                        name="location"
                        value={location}
                        onChange={loyaltyHandleChange}
                        onBlur={() =>
                          onBlurHandler({ location: location })
                        }
                        error={errors?.location}
                        options={reduxlocationsList?.filter((adrs) => {
                          if (!(all_loyalty?.find(item => item?.location?.id === adrs?.id)?.id)) {
                            return adrs
                          }
                        }).map((adrs) => {
                          return {
                            label: adrs?.address_name,
                            value: adrs?.id,
                          };
                        })}
                        title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                      />
                      <Dropwdown
                        required={true}
                        name={"status"}
                        value={status}
                        onChange={loyaltyHandleChange}
                        onBlur={() => onBlurHandler({})}
                        error={errors?.status}
                        title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
                        options={[
                          {
                            value: true,
                            label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                          },
                          {
                            value: false,
                            label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                          },
                        ]}
                      />
                      <div className="grid lg:grid-cols-2 gap-4">
                        <FloatInput
                          required={true}
                          name="amount_spend"
                          value={amount_spend}
                          onChange={loyaltyHandleChange}
                          onBlur={() =>
                            onBlurHandler({ amount_spend: amount_spend })
                          }
                          error={errors?.amount_spend}
                          title={
                            <I_Info_Icon
                              text={selectedLanguage?.name == "English" ? "Amount To Spend" : translation[1091]?.value ? translation[1091]?.value : "Amount To Spend"}
                              info={selectedLanguage?.name == "English" ? "Total amount that a client will spend on purchases to avail this offer " : translation[1092]?.value ? translation[1092]?.value : "Total amount that a client will spend on purchases to avail this offer "}
                            />
                          }
                          placeholder={selectedLanguage?.name == "English" ? "Enter Spend Amount" : translation[1095]?.value ? translation[1095]?.value : "Enter Spend Amount"}
                        />

                        <FloatInput
                          required={true}
                          name="number_points"
                          value={number_points}
                          onChange={loyaltyHandleChange}
                          onBlur={() =>
                            onBlurHandler({ number_points: number_points })
                          }
                          error={errors?.number_points}
                          title={
                            <I_Info_Icon
                              text={selectedLanguage?.name == "English" ? "Total Number Of Points" : translation[1093]?.value ? translation[1093]?.value : "Total Number Of Points"}
                              info={selectedLanguage?.name == "English" ? "Number of point the client will get on spending the mentioned amount" : translation[1094]?.value ? translation[1094]?.value : "Number of point the client will get on spending the mentioned amount"}
                            />
                          }
                          placeholder={selectedLanguage?.name == "English" ? "Enter No Of Points" : translation[1097]?.value ? translation[1097]?.value : "Enter No Of Points"}
                        />
                      </div>
                      {/* <LoginInput
                                            required={true}
                                            name='earn_points'
                                            value={earn_points}
                                            onChange={loyaltyHandleChange}
                                            onBlur={() => onBlurHandler({ earn_points: earn_points })}
                                            error={errors?.earn_points}
                                            type={"number"}
                                            placeholder='Enter Total Point To Earn'
                                            title={
                                                <I_Info_Icon text='Total points to earn' info='Total number of points that a customer need to earn to avail discount' />
                                            }
                                        />
                                        <LoginInput
                                            required={true}
                                            name='total_earn_from_points'
                                            value={total_earn_from_points}
                                            onChange={loyaltyHandleChange}
                                            onBlur={() => onBlurHandler({ total_earn_from_points: total_earn_from_points })}
                                            error={errors?.total_earn_from_points}
                                            title={
                                                <I_Info_Icon text='Amount earn from the points' info='After earning total points the discount that a customer can avail on servicer/retail/both' />
                                            }
                                            type={"number"}
                                            placeholder='Enter Total Earn From Points'
                                        /> */}
                      <div className="text-sm flex items-center md:gap-3 gap-6 flex-wrap my-[1rem]">
                        <span>{selectedLanguage?.name == "English" ? "For every" : translation[1096]?.value ? translation[1096]?.value : "For every"}</span>
                        <div>
                          <FloatInput
                            parentClass="w-[10rem]"
                            required={true}
                            name="earn_points"
                            value={earn_points}
                            onChange={loyaltyHandleChange}
                            onBlur={() =>
                              onBlurHandler({ earn_points: earn_points })
                            }
                            error={errors?.earn_points}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Total Points" : translation[1098]?.value ? translation[1098]?.value : "Enter Total Points"}
                            title=""
                          />
                        </div>
                        <span>{selectedLanguage?.name == "English" ? "points, a client will get" : translation[1099]?.value ? translation[1099]?.value : "points, a client will get"}</span>
                        <div>
                          <FloatInput
                            parentClass="w-[10rem]"
                            required={true}
                            name="total_earn_from_points"
                            value={total_earn_from_points}
                            onChange={loyaltyHandleChange}
                            onBlur={() =>
                              onBlurHandler({
                                total_earn_from_points: total_earn_from_points,
                              })
                            }
                            error={errors?.total_earn_from_points}
                            title=""
                            placeholder={selectedLanguage?.name == "English" ? "Enter Total Amount" : translation[1100]?.value ? translation[1100]?.value : "Enter Total Amount"}
                          />
                        </div>
                        <span>{selectedLanguage?.name == "English" ? "amount" : translation[793]?.value ? translation[793]?.value : "amount"}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {!loadingPermission && !employee || (employee && employeePermissions?.client_loyality?.includes("create")) ? (
              <div className="flex justify-end items-center lg:w-[82%]">
                <LoginBtn
                  // disabled={
                  //   !name ||
                  //   !amount_spend ||
                  //   !number_points ||
                  //   !earn_points ||
                  //   !location ||
                  //   // !loyaltytype ||
                  //   !total_earn_from_points
                  //   || addLoyalityLoader
                  // }
                  disabled={addLoyalityLoader}
                  className="ml-6"
                  text={selectedLanguage?.name == "English" ? "Add Loyalty" : translation[1088]?.value ? translation[1088]?.value : "Add Loyalty"}
                  loading={addLoyalityLoader}
                  onClick={() => onSubmitLoyalty()}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {is_delete && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Loyalty?" : translation[1112]?.value ? translation[1112]?.value : "Are you sure you want to delete this Loyalty?"}
          handleClose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English" ? "Are you sure you want to delete this Loyalty? You won't be able to see this again." : translation[1115]?.value ? translation[1115]?.value : "Are you sure you want to delete this Loyalty? You won't be able to see this again."}
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  onDeleteLoyalty();
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
}

export default LoyaltyPoints;
