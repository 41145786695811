import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import React from 'react'

const TipTable = ({ employeeTip, location_currency }) => {
    return (
        <div className="overflow-x-auto">
            <div className={`inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                <p className='col-span-2'>Employee Name</p>
                <p className='text-center'>Total Tips Received</p>
            </div>
            <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px] overflow-y-auto`}
            >
                {employeeTip?.length > 0 ? (
                    employeeTip?.map((item, index) => {
                        return (
                            <div key={index} className={`text-xs px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))]  min-w-full w-fit`}>
                                <p className='col-span-2'>
                                    {item?.employee_name}
                                </p>
                                <p className="text-center">
                                    {item?.total_tips} {location_currency}
                                </p>
                            </div>
                        );
                    })
                ) : (
                    <NoReportDataFound />
                )}
            </div>
        </div>
    )
}

export default TipTable
