import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb'
import React from 'react'
import useComplaints from './helper'
import LocationSelection from "new-components/location";
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import CreateComplaintPopup from './CreateComplaintPopup';
import ComplainTable from './ELements/ComplaintTable';
import CreateComplaintCategoryPopup from './CreateComplaintCategoryPopup';
import DeleteConfirmation from 'new-components/delete-popup';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import Pagination from 'new-components/Pagination';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import { complaintStatus } from 'Constants/Data/Months';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import ComplaintDetailPopup from './ELements/ComplaintDetailPopup';
import LogsDetailPopup from './ELements/LogsDetailPopup';

const Complaints = () => {
    const {
        translation, selectedLanguage, storeDataInLocalStorage, openCreatePopup, setOpenCreatePopup, openCreateCategoryPopup, setOpenCreateCategoryPopup,
        onSucessCallBack, complaintLoading, deleteCategoryPopup, setDeleteCategoryPopup, deleteCategoryLoading, deleteComplaintCategory,
        deleteComplaintPopup, setDeleteComplaintPopup, deleteComplaintLoading, deleteComplaint, paginationData, complaint, currentPage, setCurrentPage,
        searchText, setSearchText, status, setStatus,
        complaintDetail, setComplaintDetail, complaintPopup, setComplaintPopup, complaintDetailLoading, updateComplaintLoading, updateComplaintStatus, viewLogs, setViewLogs
    } = useComplaints()

    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative bg-primary-background">
                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                    url={"/dashboard/clients/"}
                    second={"Complaints"}
                />

                <div className="flex items-center flex-wrap justify-between sm:gap-4 gap-2 my-[1rem]">
                    <div className="flex gap-4 items-center ">
                        <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                            Complaints
                        </h2>

                        <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
                    </div>

                    <div className="flex gap-5 flex-1 justify-end">
                        {/* <LoginBtn title={"Create Complaint Category"} text={"Create Complaint Category"} onClick={() => setOpenCreateCategoryPopup(true)} /> */}
                        <LoginBtn title={"Create Complaint"} text={"Create Complaint"} onClick={() => setOpenCreatePopup(true)} />
                    </div>
                </div>
                <div className="flex justify-end gap-5">
                </div>

                <div className="border rounded-lg bg-white w-full flex flex-col gap-3">
                    <div className="flex w-full px-5 justify-between gap-4 items-center">
                        {complaintLoading ?
                            <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                <p className="rounded-md h-5 w-12 mx-1"> </p>
                            </div>
                            :
                            <div className="p-2 bg-[#E4EAF0] px-4 h-fit py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                <p className="text-primary">
                                    {paginationData?.count ? paginationData?.count : 0}{" "}
                                    {paginationData?.count && paginationData?.count > 1 ?
                                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                    }
                                </p>
                            </div>
                        }
                        <div className="flex justify-end gap-2 w-full mx-auto pt-4 pb-3">
                            <LoginInput
                                title={""}
                                value={searchText}
                                placeholder={"Search client or employee"}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <div className=''>
                                <Dropwdown
                                    title={""}
                                    value={status}
                                    className={"cursor-pointer"}
                                    options={[
                                        { label: "All", value: "" },
                                        ...complaintStatus,
                                    ]}
                                    placeholder={"Select Status"}
                                    onChange={(e) => setStatus(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {complaintLoading
                        ? <TableShimer cols={4} rows={10} />
                        :
                        <>
                            <ComplainTable
                                data={complaint}
                                onEdit={setOpenCreatePopup}
                                onDelete={setDeleteComplaintPopup}
                                onDetail={setComplaintPopup}
                            />

                            {complaint?.length > 0 &&
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={Math.ceil(paginationData?.total_pages)}
                                    pageSize={10}
                                    onPageChange={page => setCurrentPage(page)}
                                />

                            }
                        </>
                    }
                </div>


            </div>

            <CreateComplaintPopup
                open={openCreatePopup}
                close={setOpenCreatePopup}
                onSucessCallBack={onSucessCallBack}
            />

            <CreateComplaintCategoryPopup
                open={openCreateCategoryPopup}
                close={setOpenCreateCategoryPopup}
                onSucessCallBack={onSucessCallBack}
            />

            {deleteCategoryPopup &&
                <DeleteConfirmation
                    open={deleteCategoryPopup}
                    isLoading={deleteCategoryLoading}
                    heading={"Delete Complaint Category"}
                    handleDelete={deleteComplaintCategory}
                    handleClose={() => setDeleteCategoryPopup(false)}
                    description={"Are you sure you want to delete this complaint category?"}
                />

            }
            {deleteComplaintPopup &&
                <DeleteConfirmation
                    open={deleteComplaintPopup}
                    isLoading={deleteComplaintLoading}
                    heading={"Delete Complaint"}
                    handleDelete={deleteComplaint}
                    handleClose={() => setDeleteComplaintPopup(false)}
                    description={"Are you sure you want to delete this complaint?"}
                />
            }

            <ComplaintDetailPopup
                open={complaintPopup}
                close={setComplaintPopup}
                complaintDetail={complaintDetail}
                setComplaintDetail={setComplaintDetail}
                updateComplaintStatus={updateComplaintStatus}
                updateComplaintLoading={updateComplaintLoading}
                complaintDetailLoading={complaintDetailLoading}
                viewLogs={viewLogs}
                setViewLogs={setViewLogs}
            />
            {/* <LogsDetailPopup
                open={viewLogs}
                close={setViewLogs}
                data={complaintDetail?.status_logs}
            /> */}

        </>
    )
}

export default Complaints