import Popup from 'Components/TenantApp/Elements/Popup';
import Pagination from 'new-components/Pagination';
import React, { useState } from 'react'
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Svgs from 'Assets/svgs';
import moment from 'moment';
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import NotFound from 'Components/Utility/NotFound';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import { useSelector } from 'react-redux';
import useMissedOpportunity from './helper';
import AddMissedOpportunity from './AddMissedOpportunity';
import { CALENDAR_OPENING_TIME } from 'Constants/Data/OpeningHours';
import PaidUnpaidShimmer from 'Components/Utility/Shimmers/PaidUnpaidShimmer';
// import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
export const MissedOpportunityPopup = ({ onClose, open }) => {
    const { searchText, setSearchText, toggleDateRange, setToggleDateRange, currentPage, setCurrentPage, loader, PageSize, data, setData, getNote, setGetNote,
        translation, selectedLanguage, startDate, setStartDate, endDate, setEndDate, pages, ShowDetails, setShowDetails, deleteOpportunity,
        count, addPopup, setAddPopup, delPopup, setDelPopup, deleteLoader, setDeleteId, getAPi, loadingPermission, showNotes, setShowNotes
    } = useMissedOpportunity()
    return (
        <>
            <Popup
                heading={selectedLanguage?.name == "English" ? "Missed Opportunities" : translation[1378]?.value ? translation[1378]?.value : "Missed Opportunities"}
                size="lg"
                close={() => {
                    onClose()
                    setData([])
                }}
                open={open}
            // customHeight={'min-h-[80vh]'}
            >
                <div className="">


                    <div className="border rounded-lg">
                        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-1 lg:gap-1 gap-2">
                                <div className="flex items-center lg:gap-4 gap-3">
                                    <div className="flex items-center sm:gap-4 gap-2 ">
                                        {/* <h2 className="font-semibold text-xl sm:whitespace-nowrap">
                                            {selectedLanguage?.name == "English" ? "Missed Opportunities" : translation[147]?.value ? translation[147]?.value : "Missed Opportunities"}
                                        </h2> */}
                                        {loader ?
                                            <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                                <p className="rounded-md h-5 w-12 mx-1"> </p>
                                            </div>
                                            : <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                                <p className="text-primary">
                                                    {count ? count : 0}{" "}
                                                    {count && count > 1 ?
                                                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                                    }
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {loadingPermission ?
                                    <PaidUnpaidShimmer has2Itm classOn1={'!w-32 lg:!w-44 sm:!w-40'} />
                                    :
                                    <div className="mb-00 flex items-center gap-2">
                                        {/* <div className="mb-00">
                                            <Dropwdown
                                                title=""
                                                value={dropdownStatus}
                                                onChange={(e) => setDropdownStatus(e.target.value)}
                                                showTitle={false}
                                                placeholder={selectedLanguage?.name == "English" ? "Select Type" : translation[334]?.value ? translation[334]?.value : "Select Type"}
                                                options={[
                                                    { label: selectedLanguage?.name == "English" ? "All Appointments" : translation[1342]?.value ? translation[1342]?.value : "All Appointments", value: "" },
                                                    { label: selectedLanguage?.name == "English" ? "Paid" : translation[1340]?.value ? translation[1340]?.value : "Paid", value: 'paid' },
                                                    { label: selectedLanguage?.name == "English" ? "Un-Paid" : translation[1341]?.value ? translation[1341]?.value : "Un-Paid", value: 'unpaid' }
                                                ]}
                                            />
                                        </div> */}
                                        <LoginBtn
                                            onClick={() => {
                                                setAddPopup(!addPopup);
                                            }}
                                            borderBtn
                                        >
                                            <div className="flex items-center gap-2">
                                                <Svgs.Plus color='rgb(86, 121, 255)' />
                                                <p className="">
                                                    {selectedLanguage?.name == "English" ? "Add Missed Opportunity" : translation[1379]?.value ? translation[1379]?.value : "Add Missed Opportunity"}
                                                </p>
                                            </div>
                                        </LoginBtn>

                                        <LoginInput
                                            title=""
                                            name={"search_text"}
                                            placeholder={selectedLanguage?.name == "English" ? "Search Client" : translation[143]?.value ? translation[143]?.value : "Search Client"}
                                            value={searchText}
                                            onChange={(e) => {
                                                setSearchText(e.target.value)
                                            }}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                        />
                                    </div>
                                }
                            </div>
                            {loadingPermission ?
                                <PaidUnpaidShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} has2Itm classOn2={'!w-12 lg:!16'} />
                                :
                                <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                                    <div className='flex items-center gap-3'>
                                        <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                            <div className='p-2 relative gap-4 flex items-center'>
                                                <p onClick={() => {
                                                    setToggleDateRange(!toggleDateRange)
                                                }} className='cursor-pointer'>
                                                    {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                                </p>
                                                <span className='cursor-pointer' onClick={() => {
                                                    setStartDate()
                                                    setEndDate()
                                                }}>Clear</span>

                                                {
                                                    toggleDateRange && <>
                                                        <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                                        <div className='absolute top-full right-0 z-[2]'>
                                                            <DateRangePicker
                                                                start={startDate}
                                                                end={endDate}
                                                                setStartDate={setStartDate}
                                                                setEndDate={setEndDate}
                                                                setToggleDateRange={setToggleDateRange}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* {exportLoader ? (
                                        <div className="h-[4rem] flex items-center justify-center">
                                            <PreloaderSm />
                                        </div>
                                    ) :
                                        !employee ? (
                                            commissionAndReportsWithNoPagination?.length > 0 ?
                                                <CSVLink
                                                    {...exportPromotionDiscountReport}
                                                    target="_blank"
                                                    onClick={handleExportClick}
                                                >
                                                         <LoginBtn
                              className="rounded-lg !py-[7px]"
                              bg="bg-white"
                              animation={false}
                              customThemeBtn={'theme-btn2'}
                            >
                              <div className="flex items-center gap-2 text-[#FFFFFF]">
                                <Svgs.ExportSvg />
                              </div>
                            </LoginBtn>
                                                </CSVLink> :
                                                <LoginBtn
                                                    className="rounded-lg border-2 border-primary"
                                                    bg="bg-white"
                                                    animation={false}
                                                    onClick={handleExportClick}
                                                >
                                                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                        <Svgs.Export bg="#FFFFFF" />
                                                    </div>
                                                </LoginBtn>
                                        ) : (
                                            ""
                                        )} */}
                                </div>

                            }
                        </div>
                        {
                            loader ?
                                <TableShimer rows={10} cols={6} />
                                :
                                <div className="overflow-x-auto">
                                    <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] px-10 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                        <p>{selectedLanguage?.name == "English" ? "Client Name" : translation[474]?.value ? translation[474]?.value : "Client Name"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Client Type" : translation[1344]?.value ? translation[1344]?.value : "Client Type"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Opportunity Date" : translation[1345]?.value ? translation[1345]?.value : "Opportunity Date"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Dependency" : translation[793]?.value ? translation[793]?.value : "Dependency"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Actions" : translation[793]?.value ? translation[793]?.value : "Actions"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Notes" : translation[793]?.value ? translation[793]?.value : "Notes"}</p>
                                    </div>
                                    <div
                                        className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px] min-h-[60vh] relative`}
                                    >
                                        {data?.length > 0 ? (
                                            data?.map((item, index) => {
                                                return (
                                                    <div key={index} className="py-3 ">
                                                        <div className='px-10 inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] items-center min-w-full w-fit'>
                                                            <div className={`before-left-arrow cursor-pointer ${ShowDetails == item.id
                                                                ? "before-left-arrow-open"
                                                                : "before-left-arrow-close"
                                                                }`}
                                                                onClick={() => {
                                                                    setShowDetails(ShowDetails != item.id ? item.id : undefined);
                                                                }}>
                                                                <p className="text-sm line-clamp-1 pr-3 pt-2">
                                                                    {item?.client_name ? item?.client_name : "----"}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className="h-full flex items-center">
                                                                    <p className="text-sm text-primary line-clamp-1">
                                                                        {item?.client_type == 'Walk_in' ? "Walk In" : item?.client_type == 'In_Saloon' ? "In Saloon" : ''}

                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="flex items-center">
                                                                <p className="text-sm line-clamp-2">
                                                                    {item?.date_time ? moment(item?.date_time).format('DD-MM-YYYY') : "----"}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm">
                                                                    {item?.dependency ? item?.dependency : "----"}
                                                                </p>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    setDeleteId(item?.id)
                                                                    setDelPopup(!delPopup);
                                                                }}
                                                                className="h-[2.5rem] w-[2.5rem] cursor-pointer flex items-center justify-center"
                                                            >
                                                                <span>
                                                                    <Svgs.Bucket />
                                                                </span>
                                                            </div>
                                                            <div className="flex items-center line-clamp-2">
                                                                <p className="text-sm ">
                                                                    {item?.note ? item?.note : "----"}
                                                                </p>
                                                                {/* {item?.note &&
                                                                <span className='cursor-pointer' onClick={() => {
                                                                    setShowNotes(true)
                                                                    setGetNote(item?.note)
                                                                }}>
                                                                    <Svgs.NoteBook />
                                                                </span>
                                                            } */}
                                                            </div>
                                                        </div>

                                                        {ShowDetails == item.id ? (
                                                            <div className="scale-in-center">
                                                                <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid min-w-fit grid-cols-[repeat(4,minmax(211px,1fr))]">
                                                                    <div className="grid grid-cols-3 col-span-3 gap-y-5 gap-x-3">
                                                                        {item?.services?.map(srv => {
                                                                            return (
                                                                                <>
                                                                                    <div className="flex flex-col gap-1">
                                                                                        <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Service Name" : translation[934]?.value ? translation[934]?.value : "Service Name"}</p>
                                                                                        <p className="text-black font-semibold">
                                                                                            {srv?.service_name ? srv?.service_name : "-------"}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="flex flex-col gap-1">
                                                                                        <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? 'Employee Name' : translation[428]?.value ? translation[428]?.value : "Employee Name"}</p>
                                                                                        <p className="text-black font-semibold">
                                                                                            {srv?.employee_name ? srv?.employee_name : "-------"}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="flex items-end justify-between">
                                                                                        <div className="flex flex-col gap-1">
                                                                                            <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Duration" : translation[962]?.value ? translation[962]?.value : "Duration"}</p>
                                                                                            <p className="text-black font-semibold">
                                                                                                {srv?.duration
                                                                                                    ? srv?.duration
                                                                                                    : "-------"}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="flex flex-col gap-1">
                                                                                            <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Time" : translation[999]?.value ? translation[999]?.value : "Time"}</p>
                                                                                            <p className="text-black font-semibold">
                                                                                                {srv?.time ?
                                                                                                    CALENDAR_OPENING_TIME.find(
                                                                                                        (itm) =>
                                                                                                            itm.value == srv?.time
                                                                                                    )?.label
                                                                                                    : "----"}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <NotFound MissedOpportunitiesNotFound className={'absolute h-4/5 w-full'} />
                                        )}
                                    </div>

                                </div>
                        }
                        {/* Pagination */}
                        {(data?.length > 0 && !loader) &&
                            <Pagination
                                currentPage={currentPage}
                                totalCount={Math.ceil(pages)}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        }

                    </div>
                </div>
            </Popup>
            {
                showNotes && (
                    <Popup customHeight={'min-h-[20vh]'}
                        heading={selectedLanguage?.name == "English" ? "Client Note" : translation[536]?.value ? translation[536]?.value : "Client Note"}
                        onclose={() => {
                            setShowNotes(!showNotes);
                            setGetNote("")
                        }}
                    >
                        <div className="p-5">
                            <div className="border p-5 rounded-2xl min-h-[20vh]">
                                <p className="text-sm ">
                                    {getNote ? getNote : "There is no any Client Note"}
                                </p>
                            </div>
                        </div>
                    </Popup>
                )
            }

            {/* {
                addPopup && ( */}
            <AddMissedOpportunity
                onClose={() => {
                    setAddPopup(false);
                }}
                update={false}
                open={addPopup}
                addSuccess={() => {
                    getAPi()
                }}
            />
            {/* )
            } */}

            {delPopup && (
                <Popup
                    heading={selectedLanguage?.name == "English" ? "Delete missed opportunity" : translation[1435]?.value ? translation[1435]?.value : "Delete missed opportunity"}
                    onclose={() => {
                        setDeleteId('')
                        setDelPopup(false);
                    }}
                >
                    <div className="">
                        <p>
                            {selectedLanguage?.name == "English"
                                ? "Confirm deletion of this overlooked chance. This action cannot be undone. Are you certain you wish to proceed?"
                                : translation[1436]?.value
                                    ? translation[1436]?.value
                                    : "Confirm deletion of this overlooked chance. This action cannot be undone. Are you certain you wish to proceed?"}

                        </p>
                        <div className="flex items-center justify-end mt-[2rem]">
                            <LoginBtn
                                loading={deleteLoader}
                                disabled={deleteLoader}
                                deleteBtn
                                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                                onClick={() => {
                                    deleteOpportunity()
                                }}
                            />
                        </div>
                    </div>
                </Popup>
            )}
        </>
    )
}

const MissedOpprtunity = ({ missedOpprtunityPopup, setMissedOpprtunityPopup }) => {
    const state = useSelector((state) => state);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            {missedOpprtunityPopup && (
                <MissedOpportunityPopup
                    onClose={() => {
                        setMissedOpprtunityPopup(false);
                    }}
                    open={missedOpprtunityPopup}
                />
            )}
        </>
    );
};

export default MissedOpprtunity