import Svgs from "Assets/svgs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


export const SpecificBrandServiceGroupCard = ({ onSelect, data, isDelete, selected, onRemoveOffer }) => {
  const state = useSelector(state => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  return (
    <div
      className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
        }  border   rounded-md p-4 mt-1 cursor-pointer`}
      onClick={() => {
        onSelect && onSelect(data)
      }}
    >
      <div
        className="absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2"
      >
        {isDelete && <Svgs.CirlceClose size="20" fill="red" />}
      </div>
      <div className="flex justify-between items-center mb-2">

        <div className="text-[#A1A1A1] font-bold">
          {selectedLanguage?.name == "English" ? "Specific Group of Category" : translation[1207]?.value ? translation[1207]?.value : "Specific Group of Category"}
        </div>
        {onRemoveOffer && selected &&
          <button
            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
            onClick={onRemoveOffer}
          >
            <Svgs.Delete fill="#767A87" />
          </button>
        }
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

        <div className="flex flex-col gap-2 flex-1 w-full">
          <div className="flex items-center justify-between gap-2">
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
              </p>
            </div>
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}
              </p>
            </div>
          </div>
          {
            data?.servicegroup_discount?.map((srv_grp, index) => {
              return (
                <div key={index} className="flex items-center justify-between gap-2">
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.servicegroup?.name ? srv_grp?.servicegroup?.name : srv_grp?.service_group_name}</p>
                  </div>
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.discount} %</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="flex flex-col gap-2 flex-1 w-full">
          <div className="flex items-center justify-between gap-2">
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Brand" : translation[292]?.value ? translation[292]?.value : "Brand"}
              </p>
            </div>
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}
              </p>
            </div>
          </div>
          {
            data?.servicegroup_discount?.map((srv_grp, index) => {
              return (
                <div key={index} className="flex items-center justify-between gap-2">
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.brand?.name}</p>
                  </div>
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.brand_discount} %</p>
                  </div>
                </div>
              )
            })
          }
        </div>

        {/* Date duration */}
        <div className="flex items-center justify-between w-full gap-2">
          <div className="flex items-center gap-2">
            <p className="text-[#A1A1A1] text-sm">
              {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
            </p>
            <p className="text-[#101928] font-semibold">
              {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-[#A1A1A1] text-sm">
              {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
            </p>
            <p className="text-[#101928] font-semibold">
              {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}



export const SpecificGroup = ({ MODULE_TYPE, onSelect, specific_group, setCartData, cartData, appointmentServices,
  setAppointmentService }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const service_groups = useSelector(state => state.saleService.servicesgroup)

  const [Specific_group_data, setSpecificGroupData] = useState([])

  // handle appointment promotion scenarios
  const handle_select_appointment = (data) => {
    let excludedItems = data?.excluded_services?.map(itm => itm.id) || [];

    const updatedAppointmentServices = appointmentServices?.map(service => {
      let matchingGroup = data?.servicegroup_discount?.find(grp =>
        service?.avaliable_service_group?.some(srv => srv?.id === grp?.servicegroup)
      );
      if (matchingGroup?.discount && !excludedItems.includes(service?.service)) {
        return {
          ...service,
          discount_price: (service?.price * (100 - matchingGroup?.discount)) / 100,
          discount_price_minus: service?.price - ((service?.price * (100 - matchingGroup?.discount)) / 100),
          discount_percentage: matchingGroup?.discount,
          is_promotion: true,
          is_promotion_availed: true,
        };
      }
      return service;
    })
    const isPromotionApplied = updatedAppointmentServices?.find(itm => itm?.is_promotion)
    if (isPromotionApplied) {
      const total_discount_price = updatedAppointmentServices?.reduce((sum, item) => sum + (item?.discount_price_minus || 0), 0);
      setAppointmentService(updatedAppointmentServices);
      setCartData({
        ...cartData,
        promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
        is_promotion: true,
        is_promotion_availed: true,
        selected_promotion_type: data?.type || null,
        selected_promotion_id: data?.id || null,
      })
      onSelect?.()
    } else {
      toast.error("No services found to apply promotion", { toastId: "toast" })
    }
  }

  // handle product and services promotion scenarios
  const handleSelectMultipleItems = (data, types) => {
    let total_discount_price = 0;

    const processCartItems = () => {
      let updatedCart = cartData && typeof cartData === "object" ? { ...cartData } : { ids: [] };
      updatedCart.ids = updatedCart.ids || [];

      let total_discount_price = 0;

      types?.forEach(type => {
        let excludedItems = type === "SERVICE"
          ? data?.excluded_services?.map(itm => itm.id) || []
          : data?.excluded_products?.map(itm => itm.id) || [];

        let filteredItems = cartData?.ids?.filter(item => item?.selection_type === type) || [];
        let remainingItems = filteredItems?.filter(item => !excludedItems.includes(item.id));

        if (type === "SERVICE") {
          let groups = data?.servicegroup_discount?.map(group => ({
            ...group.servicegroup,
            discount_percentage: group.discount
          }));
          remainingItems = remainingItems?.map(service => {
            let matchingGroup = groups?.find(grp => grp.services?.some(srv => srv.id === service.id));
            if (matchingGroup?.discount_percentage) {
              return {
                ...service,
                discount_price: (service.price * (100 - matchingGroup?.discount_percentage)) / 100,
                discount_price_minus: service.price - ((service.price * (100 - matchingGroup?.discount_percentage)) / 100),
                discount_percentage: matchingGroup?.discount_percentage,
                is_promotion: true,
                is_promotion_availed: true,
              };
            }
            return service;
          });
        } else {
          remainingItems = remainingItems?.map(product => {
            let filteredDiscount = data?.servicegroup_discount?.find(itm => itm?.brand?.id === product?.brand?.id);
            if (filteredDiscount?.brand_discount) {
              return {
                ...product,
                discount_percentage: filteredDiscount?.brand_discount,
                discount_price: (product.price * (100 - filteredDiscount?.brand_discount)) / 100,
                discount_price_minus: product.price - (product.price * (100 - filteredDiscount?.brand_discount)) / 100,
                is_promotion: true,
                is_promotion_availed: true,
              };
            }
            return product;
          });
        }

        updatedCart.ids = [...updatedCart.ids.filter(itm => itm.selection_type !== type), ...remainingItems];
      });

      total_discount_price = updatedCart?.ids?.reduce((sum, item) => sum + (item?.discount_price_minus || 0), 0);

      return {
        ...updatedCart,
        selected_promotion_type: data?.type || null,
        selected_promotion_id: data?.id || null,
        is_promotion: true,
        is_promotion_availed: true,
        promotion_discount_sum: total_discount_price
      };
    };
    const updatedCartData = processCartItems();
    if (updatedCartData?.ids?.find(itm => itm?.is_promotion)) {
      setCartData(updatedCartData);

      dispatch({
        type: "UPDATE_CART_DISCOUNTS",
        payload: {
          cart_items: state?.cartItem?.cart_items?.map(item => {
            if (types.includes(item?.selection_type)) {
              let groups = data?.servicegroup_discount?.map(group => ({
                ...group.servicegroup,
                discount_percentage: group.discount
              }));
              let discount
              if (item?.selection_type == 'PRODUCT') {
                discount = data?.servicegroup_discount?.find(itm => itm?.brand?.id == item?.brand?.id)?.brand_discount
              } else {
                let matchingGroup = groups?.find(grp => grp.services?.some(srv => srv.id === item.id));
                discount = matchingGroup?.discount_percentage
              }
              if (discount) {
                return {
                  ...item,
                  discount_percentage: discount,
                  discount_price: (item?.price * (100 - discount)) / 100,
                  is_promotion: true
                };
              }
            }
            return item;
          }),
          is_promotion: true,
          selected_promotion_type: data?.type || null,
          selected_promotion_id: data?.id || null,
          is_promotion_availed: true,
          promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
        }
      });

      onSelect?.()
    } else {
      toast.error("No services/products found to apply promotion", { toastId: "toast" })
    }
  };

  const handle_select = (data) => {
    if (MODULE_TYPE === 'quickSales') {
      handleSelectMultipleItems(data, ["SERVICE", "PRODUCT"]);
    } else {
      let funcs = {
        'APPOINTMENT': () => handle_select_appointment(data),
      };

      funcs[MODULE_TYPE] && funcs[MODULE_TYPE]();
    }
  };

  useEffect(() => {
    let new_data = [
      ...specific_group
    ]

    new_data.forEach((spc_grp) => {
      spc_grp.servicegroup_discount?.forEach(grp => {
        let this_group = service_groups.find(itm => itm.id == grp?.servicegroup)
        if (this_group) {
          grp.servicegroup = this_group
        }
      })
    })

    setSpecificGroupData(new_data)
  }, [])
  return (
    <>
      {
        Specific_group_data && Specific_group_data?.map((itm, index) => {
          return (
            <SpecificBrandServiceGroupCard
              key={index}
              data={itm}
              isDelete={false}
              selected={false}
              onSelect={(data) => handle_select(data, index)}
            />
          )
        })
      }
    </>
  );
};