import Svgs from "Assets/svgs";
import { useState, useRef, useEffect } from "react";


const ThreeDotMenu = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className="relative inline-block" ref={menuRef}>
            <button
                onClick={() => setIsOpen((prev) => !prev)}
                className="p-2 rounded-md hover:bg-gray-100 focus:outline-none"
            >
                <Svgs.Threedots />
            </button>

            {isOpen && (
                <div className="absolute right-5 top-2 rounded-md shadow-lg p-1 z-[1000]">
                    {children}
                </div>
            )}
        </div>
    );
};

export default ThreeDotMenu;
