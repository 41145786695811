import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import React from 'react'

const ProductByCategoryTable = ({ data, location_currency }) => {
    return (
        <div className="overflow-x-auto">
            <div className={`inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                <p>Category Name</p>
                <p className='text-center'>Sold</p>
                <p className="text-center">Price</p>
            </div>
            <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px] max-h-[400px] overflow-y-auto`}
            >
                {data?.length > 0 ? (
                    data?.map((item, index) => {
                        return (
                            <div key={index} className={`text-xs px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))]  min-w-full w-fit`}>
                                <p>
                                    {item?.category_name}
                                </p>
                                <p className="text-center">
                                    {item?.total_sold_count}
                                </p>
                                <p className="text-center">
                                    {item?.total_sale_value} {location_currency}
                                </p>
                            </div>
                        );
                    })
                ) : (
                    <NoReportDataFound />
                )}
            </div>
        </div>
    )
}

export default ProductByCategoryTable
