import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Svgs from '../../../../../Assets/svgs';
import { ClientCard } from './ClientCard'
import { ClientAppointmenrCard } from './ClientAppointmenrCard';
import { getClientSales } from '../../../../../Adapters/Api/apointments';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AddAppointmentRightClient from './../Elements/AddAppointmentRightClient';
import { GET_CLIENT_SALE } from './../../../../../Redux/TenantRedux/ActionTypes/Appointment/Appointment';
import LoginInput from './../../../Elements/LoginInput';
import TotalCount from '../../../Elements/TotalCount';
import moment from 'moment';
import NoClientFound from 'Components/Utility/NoClientFound';
import AddClientPopup from '../../Clients/AddClient/AddClientPopup';
import LabelAvatar from 'Components/TenantApp/Elements/LabelAvatar';
import InfiniteScroll from "react-infinite-scroll-component";
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import { getClientDropDownListPagination } from 'Adapters/Api/clients';
import ClientSectionShimmer from 'Components/Utility/Shimmers/ClientSectionShimmer';
import ClientSectionDetailShimmer from 'Components/Utility/Shimmers/ClientSectionDetailShimmer';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';

// import NotFound from './../../Sales/Elements/NotFound';
// import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
let timeOutId = undefined
function ClientSection({ id, setSelectedId, client_id, reset, removeBtn }) {
    let past_appointment = [];
    let news_appointment = [];

    let past_quick_sales = [];
    let new_quick_sales = [];


    let past_voucher = [];
    let news_voucher = [];

    // let total_sales = 0;
    // let total_appoint_sales = 0;
    // let total_product_sales = 0;
    // let total_voucher_sales = 0;
    // let total_service_sales = 0
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const state = useSelector(state => state);
    const client_sale = state?.appointment?.client_sale
    const clientRedux = state?.client?.clientsRedux;
    const clientPaginationData = state?.client?.clientPaginationData;
    const appointment_client = state?.appointment?.appointment_client;
    const totalSales = client_sale?.totalSales ? client_sale?.totalSales : 0
    const quickSalesCount = client_sale?.quickSalesCount ? client_sale?.quickSalesCount : 0
    const vouchersCount = client_sale?.vouchersCount ? client_sale?.vouchersCount : 0
    const totalAppointmentsCount = client_sale?.totalAppointmentsCount ? client_sale?.totalAppointmentsCount : 0
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const [SideSection, setSideSection] = useState('Appointments')
    const [SelectedClient, setSelectedClient] = useState()
    const [loader, setLoader] = useState(true)
    // const [clients, setClients] = useState([])
    const [clientLoader, setClientLoader] = useState(true)
    const [clientSecondLoader, setClientSecondLoader] = useState(false)
    const [clientSearchText, setClientSearchText] = useState("");
    const [showAddClientPopup, setShowAddClientPopup] = useState(false)
    const [clientCurrentPage, setClientCurrentPage] = useState(1)


    //filter new and past_appointment appointmen
    client_sale?.appointment?.length > 0 &&
        client_sale?.appointment.map((app, ind) => {
            if (app?.appointment_status === "Done") {
                past_appointment.push(app)
            } else {
                news_appointment.push(app)
            }
        })



    //filter past and new prodcut 
    client_sale?.product?.length > 0 &&
        client_sale?.product.map((prdct) => {
            // let dt = prdct?.created_at?.split('T')[0];
            // let dy = dt?.split("-")[2];

            var dat = new Date();
            dat?.setDate(dat?.getDate() - 10);
            // let from_Date = dat?.toLocaleString()?.split(",")[0]

            // const fromDate = Date?.parse(from_Date);
            // let createAt = Date?.parse(dt);
            // if (createAt > fromDate) {
            //     new_quick_sales.push(prdct)
            // } else {
            //     past_quick_sales.push(prdct)
            // }
            past_quick_sales.push(prdct)
        })

    //filter past and new services 
    client_sale?.service?.length > 0 &&
        client_sale?.service.map((srv) => {
            // let dt = srv?.created_at?.split('T')[0];
            // let dy = dt?.split("-")[2];

            var dat = new Date();
            dat?.setDate(dat?.getDate() - 10);
            // let from_Date = dat?.toLocaleString()?.split(",")[0]

            // const fromDate = Date?.parse(from_Date);
            // let createAt = Date?.parse(dt);

            // if (createAt > fromDate) {
            //     new_quick_sales.push(srv)
            // } else {
            //     past_quick_sales.push(srv)
            // }
            past_quick_sales.push(srv)
        })



    client_sale?.voucher?.length > 0 &&
        client_sale?.voucher.map((vchr) => {
            let dt = vchr?.created_at?.split('T')[0];
            let dy = dt?.split("-")[2];


            var dat = new Date();
            dat?.setDate(dat?.getDate() - 10);
            let formate_create_at_dt = moment(dt).format('YYYY-MM-DD')
            let formate_from_Date = moment(dat).format('YYYY-MM-DD')
            const fromDate = Date?.parse(formate_from_Date);
            let createAt = Date?.parse(formate_create_at_dt);

            if (createAt > fromDate) {
                news_voucher.push(vchr)
            } else {
                past_voucher.push(vchr)
            }

        })

    //filter clients list
    // const clients_filter_list = () => {
    //     // let clients_data = state.client?.checkout_client?.clients
    //     // if (!clients_data || clients_data?.length == 0) {
    //     //     clients_data = clientRedux
    //     // }
    //     // const filter_clients = state.client?.clients?.filter(itm => {
    //     //     if (itm?.is_active) {
    //     //         if (clientSearchText) {
    //     //             let return_value = false;

    //     //             if (itm?.full_name?.toLowerCase()?.includes(clientSearchText?.toLowerCase()) ||
    //     //                 itm?.client_id?.toLowerCase()?.includes(clientSearchText?.toLowerCase()) ||
    //     //                 itm?.email?.toLowerCase()?.includes(clientSearchText?.toLowerCase()) ||
    //     //                 itm?.mobile_number?.toLowerCase()?.includes(clientSearchText)) {
    //     //                 return_value = true
    //     //             } else {
    //     //                 return false
    //     //             }
    //     //             return return_value
    //     //         }
    //     //         return true
    //     //     }

    //     // })

    //     // setClients(filter_clients)
    //     setClients(clientRedux)
    // }

    // useEffect(() => {
    //     clients_filter_list()
    // }, [clientSearchText, clientRedux?.length, clientRedux, state.client?.checkout_client])

    const getClients = (clientCurrentPage, clientSearchText) => {
        dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
    }

    useEffect(() => {
        if (clientSearchText === null) {
            // Initial load with empty clientSearchText
            getClients(clientCurrentPage, "")
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getClients(clientCurrentPage, clientSearchText)
            }, 500);
        }
    }, [clientSearchText, clientCurrentPage])

    useEffect(() => {
        setClientCurrentPage(1)
    }, [clientSearchText])

    // get single client data
    const getSingleClientData = (id) => {
        const success = (data) => {
            let ClientSaleDetail = {
                appointment: data?.appointment,
                product: data?.product,
                voucher: data?.voucher,
                service: data?.service,
                totalAppointmentsCount: data?.appointments_count,
                quickSalesCount: data?.quick_sale_count,
                totalSales: (+data?.total_sales)?.toFixed(2),
                vouchersCount: data?.voucher_count
            }
            dispatch({
                type: GET_CLIENT_SALE,
                payload: ClientSaleDetail
            })
        }
        const fail = () => {
            toast.error("invalid client id!", { toastId: "toast" })
        }

        getClientSales({ client: id, location_id: selected_location }, setLoader, success, fail)
    }
    useEffect(() => {
        if (SelectedClient?.id) {
            getSingleClientData(SelectedClient?.id)
        }
    }, [SelectedClient])


    useEffect(() => {
        if (clientRedux?.length > 0) {
            if (id) {
                const res = clientRedux?.find((itm) => itm?.id === id)
                setSelectedClient(res)
            } else if (client_id) {
                const res = clientRedux?.find((itm) => itm?.id === client_id)
                setSelectedClient(res)
            } else {
                //reset client details when user select client type walk in
                if (reset) {
                    setSelectedClient(undefined)
                }
            }
        }

    }, [id, clientRedux, client_id])

    useEffect(() => {
        if (Object.keys(appointment_client)?.length > 0) {
            setSelectedClient(appointment_client)
        }
    }, [appointment_client])

    //calculate total sales of selected client
    // const total_price = () => {
    //     let price;
    //     client_sale?.appointment?.length > 0 &&
    //         client_sale?.appointment?.filter(itm => {
    //             if (itm?.price || itm?.total_price || itm?.gst || itm?.tip) {
    //                 total_appoint_sales += itm?.price ? +itm?.price : itm?.total_price ? +itm?.total_price : itm?.gst ? +itm?.gst : itm?.tip ? +itm?.tip : 0
    //             }
    //         })

    //     client_sale?.product?.length > 0 &&
    //         client_sale?.product?.filter(itm => {
    //             if (itm?.price || itm?.total_price || itm?.gst || itm?.tip) {
    //                 total_product_sales += itm?.price ? +itm?.price : itm?.total_price ? +itm?.total_price : itm?.gst ? +itm?.gst : itm?.tip ? +itm?.tip : 0
    //             }
    //         })


    //     client_sale?.voucher?.length > 0 &&
    //         client_sale?.voucher?.filter(itm => {
    //             if (itm?.price || itm?.total_price || itm?.gst || itm?.tip) {
    //                 total_voucher_sales += itm?.price ? +itm?.price : itm?.total_price ? +itm?.total_price : itm?.gst ? +itm?.gst : itm?.tip ? +itm?.tip : 0
    //             }
    //         })

    //     client_sale?.service?.length > 0 &&
    //         client_sale?.service?.filter(itm => {
    //             if (itm?.price || itm?.total_price || itm?.gst || itm?.tip) {
    //                 total_service_sales += itm?.price ? +itm?.price : itm?.total_price ? +itm?.total_price : itm?.gst ? +itm?.gst : itm?.tip ? +itm?.tip : 0
    //             }
    //         })

    //     price = (+total_appoint_sales) + (+total_product_sales) + (+total_voucher_sales) + (+total_service_sales)

    //     return parseFloat(price?.toFixed())
    // }


    return (
        <div className='lg:col-span-2 flex flex-col gap-6 lg:mt-0 mt-4'>
            {!client_id && SelectedClient == undefined ?
                clientLoader && !clientSecondLoader ?
                    <ClientSectionShimmer />
                    :
                    <div className='shadow-appoint rounded-md bg-white'>
                        <div className='p-4'>
                            <div className='flex items-center justify-between gap-3' >
                                <h1 className='font-semibold text-xl'>
                                    {selectedLanguage?.name == "English" ? "Client Info" : translation[141]?.value ? translation[141]?.value : "Client Info"}
                                </h1>
                                <div className='flex items-center gap-2 cursor-pointer' onClick={() => {
                                    localStorage.setItem('add_client_from_sales', true)
                                    // navigate('/dashboard/clients/client-profiles/add-client/?from=appointment')
                                    setShowAddClientPopup(!showAddClientPopup)
                                }}>
                                    <Svgs.Plus color='#5679FF' />
                                    <p className='font-semibold text-sm text-[#5679FF]'>

                                        {selectedLanguage?.name == "English" ? "Add New Client" : translation[142]?.value ? translation[142]?.value : "Add New Client"}

                                    </p>

                                </div>
                            </div>
                            <div className='mt-4'>
                                <LoginInput
                                    name="clientSearchText"
                                    value={clientSearchText}
                                    onChange={(e) => { setClientSearchText(e.target.value) }}
                                    title=''
                                    placeholder={selectedLanguage?.name == "English" ? "Search Client" : translation[143]?.value ? translation[143]?.value : "Search Client"}
                                    padding='py-1'
                                    leftIcon={<Svgs.Search />}

                                />
                            </div>
                        </div>
                        <div className='max-h-[30rem] overflow-y-auto' id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={clientRedux?.length ? clientRedux?.length : 10} //This is important field to render the next data
                                next={() => {
                                    clientPaginationData?.currentPage < clientPaginationData?.pages ? setClientSecondLoader(true) : setClientSecondLoader(false)
                                    setClientCurrentPage(clientPaginationData?.currentPage + 1)
                                }}
                                hasMore={clientPaginationData && clientPaginationData?.currentPage < clientPaginationData?.pages}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    clientSecondLoader ?
                                        <div className="h-[4rem] flex items-center justify-center">
                                            <PreloaderSm />
                                        </div>
                                        : ""
                                }
                            >
                                <div>
                                    {clientRedux?.length > 0 ?
                                        clientRedux?.map((client, index) => {
                                            return (
                                                <AddAppointmentRightClient
                                                    data={client}
                                                    index={index}
                                                    onClick={() => {
                                                        setLoader(true)
                                                        setSelectedClient(client)
                                                        dispatch({
                                                            type: "SELECTED_APPOINTMENT_CLIENT",
                                                            payload: client,
                                                        })
                                                        setSelectedId && setSelectedId(client?.id)
                                                    }}

                                                />
                                            )
                                        }) : <NoClientFound onClick={() => {
                                            setShowAddClientPopup(!showAddClientPopup)
                                        }} />
                                    }
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                :
                <div className='shadow-appoint rounded-md bg-white'>
                    {loader ?
                        <ClientSectionDetailShimmer />
                        :
                        <>
                            <div className='p-3'>
                                <div className='bg-[#ECF3FF] border-2 border-[#BAD3FC] rounded-md p-4 relative'>
                                    {/* <div className='absolute top-3 left-3'
                                onClick={() => {
                                    dispatch({
                                        type: "REST_SELECTED_APPOINTMENT_CLIENT",
                                        payload: [],
                                    })
                                    // setSelectedId()
                                    setSelectedClient(undefined)

                                }}
                            >
                                <Svgs.Back className='zoom-1' />
                            </div> */}

                                    {!removeBtn && <div className='absolute top-3 right-3 text-[#5679FF] text-sm cursor-pointer'
                                        onClick={() => {
                                            dispatch({
                                                type: "REST_SELECTED_APPOINTMENT_CLIENT",
                                                payload: [],
                                            })
                                            // setSelectedId()
                                            setSelectedClient(undefined)
                                        }}
                                    >
                                        {selectedLanguage?.name == "English" ? "Remove" : translation[144]?.value ? translation[144]?.value : "Remove"}
                                    </div>}

                                    <div className='pt-4'>
                                        <div className='flex items-center gap-4'>
                                            <div className='h-[4rem] w-[4rem] rounded-full overflow-hidden'>
                                                {SelectedClient?.image ?
                                                    <img
                                                        src={SelectedClient?.image}
                                                        className="h-full w-full object-cover" />
                                                    :
                                                    <LabelAvatar iconLabel={SelectedClient?.full_name} index={1} />
                                                }
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <h1 className='font-semibold'>{SelectedClient?.full_name}</h1>
                                                <p className='text-[#7E8693] text-xs'>{SelectedClient?.email ? SelectedClient?.email : "----"}</p>
                                                <p className='text-[#7E8693] text-xs'>{SelectedClient?.phone_number && SelectedClient?.phone_number}</p>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-2 gap-6 pt-4'>
                                            <div className='flex flex-col gap-1 text-center pr-3 border-r border-[#E0E0F3]'>
                                                <h1 className='font-bold text-3xl'>{totalAppointmentsCount}</h1>
                                                <p className='text-[#7E8693]'>
                                                    {selectedLanguage?.name == "English" ? "Total Appointment" : translation[145]?.value ? translation[145]?.value : "Total Appointment"}
                                                </p>
                                            </div>

                                            {/* <div className='h-[3.5rem] bg-[#E0E0F3] w-[1px]'></div> */}

                                            <div className='flex flex-col gap-1 text-center'>
                                                <h1 className='font-bold text-3xl'>{parseFloat(totalSales)}</h1>
                                                <p className='text-[#7E8693]'>
                                                    {selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='border-b flex items-center justify-between gap-5 px-4 font-semibold'>
                                <div className={`${SideSection == 'Appointments' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Appointments')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                </div>
                                <div className={`${SideSection == 'Quick_Sales' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Quick_Sales')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Quick Sales" : translation[148]?.value ? translation[148]?.value : "Quick Sales"}
                                </div>
                                <div className={`${SideSection == 'Offers' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Offers')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Offers Availed" : translation[149]?.value ? translation[149]?.value : "Offers Availed"}
                                </div>
                                <div className={`${SideSection == 'Customers' ? 'border-primary text-[#101928]' : 'border-transparent text-[#A1A1A1]'} pb-3 border-b-2 text-sm cursor-pointer`} onClick={() => {
                                    setSideSection('Customers')
                                }}>
                                    {selectedLanguage?.name == "English" ? "Waiver/Disclaimer" : translation[1565]?.value ? translation[1565]?.value : "Waiver/Disclaimer"}
                                </div>
                            </div>

                            <div className='p-4'>
                                {
                                    SideSection == 'Appointments' && (past_appointment?.length === 0) ?
                                        <div className='rounded-md p-4 text-[#55555] h-[10rem]'>
                                            <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                <div>
                                                    <div className='w-fit mx-auto'>
                                                        <Svgs.NotFoundIcon />
                                                    </div>
                                                    <h1 className='font-semibold pt-2 text-sm'>
                                                        {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        SideSection == 'Appointments' ?
                                            (news_appointment?.length != 0) || (past_appointment?.length != 0) ?


                                                <>
                                                    {/* {
                                                        news_appointment?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "New!" : translation[152]?.value ? translation[152]?.value : "New!"}
                                                                <TotalCount count={news_appointment?.length} />
                                                            </h1>
                                                            {
                                                                news_appointment.map((app, ind) => {
                                                                    let total = 0;
                                                                    let dt = app?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2]
                                                                    total += app?.price ? app?.price : app?.total_price ? app?.total_price : app?.gst ? app?.gst : app?.tip ? app?.tip : 0;
                                                                    return (
                                                                        <ClientAppointmenrCard
                                                                            name={app?.service?.name}
                                                                            price={total ? total : 0}
                                                                            created_at={app?.created_at}
                                                                            sale_by={app?.member}
                                                                            description={app?.description}
                                                                            dt={dt ? dt : ""}
                                                                            dy={dy}
                                                                            status={app?.appointment_service_status}
                                                                            // duration={app?.appointment_service_duration}
                                                                            duration={app?.duration}
                                                                            service={app?.service}
                                                                            favourite={app?.is_favourite}

                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                    } */}

                                                    {
                                                        past_appointment?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                                                <TotalCount count={totalAppointmentsCount} />
                                                            </h1>
                                                            {
                                                                past_appointment?.map((app, ind) => {
                                                                    let total = 0;
                                                                    let dt = app?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2]
                                                                    total += app?.price ? app?.price : app?.total_price ? app?.total_price : app?.gst ? app?.gst : app?.tip ? app?.tip : 0;
                                                                    return (

                                                                        (ind <= 4) &&
                                                                        <ClientAppointmenrCard
                                                                            name={app?.service?.name}
                                                                            price={total ? total : 0}
                                                                            created_at={app?.created_at}
                                                                            sale_by={app?.member}
                                                                            description={app?.description}
                                                                            dt={dt ? dt : ""}
                                                                            dy={dy}
                                                                            status={app?.appointment_service_status}
                                                                            // duration={app?.appointment_service_duration}
                                                                            duration={app?.duration}
                                                                            service={app?.service}
                                                                            favourite={app?.is_favourite}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                            {past_appointment?.length >= 5 &&
                                                                <div className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center' onClick={() => {
                                                                    localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                    navigate("/dashboard/sales/appointments?active_tab=appointments")
                                                                }}>{selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}</div>}
                                                        </div>}
                                                </>
                                                : ""
                                            : ""
                                }
                                {
                                    SideSection == 'Quick_Sales' && (past_quick_sales?.length === 0) ?
                                        <div className='rounded-md p-4 text-[#55555] h-[10rem]'>
                                            <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                <div>
                                                    <div className='w-fit mx-auto'>
                                                        <Svgs.NotFoundIcon />
                                                    </div>
                                                    <h1 className='font-semibold pt-2 text-sm'>
                                                        {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        SideSection == 'Quick_Sales' ?
                                            (new_quick_sales?.length != 0) || (past_quick_sales?.length != 0) ?
                                                <>
                                                    {/* {
                                                        new_quick_sales?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "New" : translation[152]?.value ? translation[152]?.value : "New"}
                                                                <TotalCount count={new_quick_sales?.length} />
                                                            </h1>
                                                            {


                                                                new_quick_sales.map((quick_sales, ind) => {
                                                                    let dt = quick_sales?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2];
                                                                    return (
                                                                        <ClientCard
                                                                            name={quick_sales?.order_type == "Product" ? quick_sales?.product_name : quick_sales?.service?.name}
                                                                            price={(+quick_sales?.total_price)?.toFixed(2)}
                                                                            created_at={quick_sales?.created_at}
                                                                            sale_by={quick_sales?.member?.full_name}
                                                                            description={((quick_sales?.product_details) && (quick_sales?.product_details != null)) ? quick_sales?.product_details != "null" ? quick_sales?.product_details : "" : ""}
                                                                            dt={dt}
                                                                            dy={dy}
                                                                            sub_price={quick_sales?.order_type == "Product" ? quick_sales?.product_price : quick_sales?.price}
                                                                            qnty={quick_sales?.quantity}
                                                                        />
                                                                    )

                                                                })


                                                            }

                                                        </div>
                                                    } */}

                                                    {
                                                        past_quick_sales?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "Quick Sales" : translation[148]?.value ? translation[148]?.value : "Quick Sales"}
                                                                <TotalCount count={quickSalesCount} />
                                                            </h1>
                                                            {
                                                                past_quick_sales.map((quick_sales, ind) => {
                                                                    let dt = quick_sales?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2];
                                                                    return (
                                                                        <ClientCard
                                                                            name={quick_sales?.order_type == "Product" ? quick_sales?.name : quick_sales?.service}
                                                                            price={quick_sales?.price}
                                                                            created_at={quick_sales?.created_at}
                                                                            sale_by={quick_sales?.member}
                                                                            description={quick_sales?.product_details}
                                                                            dt={dt}
                                                                            dy={dy}
                                                                            sub_price={quick_sales?.price}
                                                                            qnty={quick_sales?.quantity}
                                                                        />
                                                                    )
                                                                })

                                                            }
                                                            {past_quick_sales?.length >= 5 &&
                                                                <div className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center' onClick={() => {
                                                                    localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                    navigate("/dashboard/sales/sale-history")
                                                                }}>{selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}</div>}
                                                        </div>
                                                    }
                                                </>
                                                : ""
                                            : ""
                                }

                                {
                                    SideSection == 'Offers' && (past_voucher?.length === 0) ?
                                        <div className='rounded-md p-4 text-[#55555] h-[10rem]'>
                                            <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                <div>
                                                    <div className='w-fit mx-auto'>
                                                        <Svgs.NotFoundIcon />
                                                    </div>
                                                    <h1 className='font-semibold pt-2 text-sm'>
                                                        {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        (SideSection == 'Offers') ?
                                            ((news_voucher?.length != 0) || (past_voucher?.length != 0)) ?
                                                <>
                                                    {/* {
                                                        news_voucher?.length > 0 && <div>
                                                            <h1 className='text-xl font-semibold flex items-center gap-1'>
                                                                {selectedLanguage?.name == "English" ? "New" : translation[152]?.value ? translation[152]?.value : "New"}
                                                                <TotalCount count={news_voucher?.length} />
                                                            </h1>
                                                            {
                                                                news_voucher.map((prdct, ind) => {
                                                                    let dt = prdct?.created_at?.split('T')[0];
                                                                    let dy = dt?.split("-")[2];

                                                                    return (
                                                                        <ClientCard
                                                                            name={prdct?.voucher ? prdct?.voucher : prdct?.membership ? prdct?.membership : ""}
                                                                            price={prdct?.total_price}
                                                                            created_at={prdct?.created_at}
                                                                            sale_by={prdct?.member?.full_name}
                                                                            description={prdct?.product_details}
                                                                            dt={dt}
                                                                            dy={dy}
                                                                            type={prdct?.order_type}
                                                                        />
                                                                    )

                                                                })
                                                            }

                                                        </div>
                                                    } */}

                                                    {client_sale?.membership.length > 0 && <div>
                                                        <h1 className='text-xl font-semibold flex items-center gap-2 pt-3'>
                                                            {selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"}
                                                            <TotalCount count={client_sale?.membership?.length} />
                                                        </h1>
                                                        {client_sale?.membership?.map((prdct, ind) => {
                                                            let dt = prdct?.created_at?.split('T')[0];
                                                            let dy = dt?.split("-")[2];
                                                            return (
                                                                <ClientCard
                                                                    name={prdct?.membership}
                                                                    price={prdct?.price}
                                                                    created_at={prdct?.created_at}
                                                                    sale_by={prdct?.member}
                                                                    status={prdct?.status}
                                                                    expiry={prdct?.expiry}
                                                                    // description={prdct?.product_details}
                                                                    dt={dt}
                                                                    dy={dy}
                                                                />
                                                            )
                                                        })}
                                                        {client_sale?.membership?.length >= 5 &&
                                                            <div
                                                                className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center'
                                                                onClick={() => {
                                                                    localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                    navigate("/dashboard/sales/sale-history")
                                                                }}
                                                            >
                                                                {selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}
                                                            </div>}
                                                    </div>}
                                                    {past_voucher?.length > 0 && <div>
                                                        <h1 className='text-xl font-semibold flex items-center gap-2'>
                                                            {selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
                                                            <TotalCount count={vouchersCount} />
                                                        </h1>

                                                        {past_voucher.map((prdct, ind) => {
                                                            let dt = prdct?.created_at?.split('T')[0];
                                                            let dy = dt?.split("-")[2];

                                                            return (
                                                                <ClientCard
                                                                    name={prdct?.voucher ? prdct?.voucher : prdct?.membership ? prdct?.membership : ""}
                                                                    price={prdct?.price}
                                                                    created_at={prdct?.created_at}
                                                                    sale_by={prdct?.member}
                                                                    // description={prdct?.product_details}
                                                                    dt={dt}
                                                                    dy={dy}
                                                                />
                                                            )

                                                        })


                                                        }
                                                        {past_voucher?.length >= 5 &&
                                                            <div className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center' onClick={() => {
                                                                localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                navigate("/dashboard/sales/sale-history")
                                                            }}>{selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}</div>}
                                                    </div>}
                                                    {client_sale?.gift_cards.length > 0 && <div>
                                                        <h1 className='text-xl font-semibold flex items-center gap-2 pt-3'>
                                                            {selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"}
                                                            <TotalCount count={client_sale?.gift_cards?.length} />
                                                        </h1>
                                                        {client_sale?.gift_cards?.map((prdct, ind) => {
                                                            let dt = prdct?.created_at?.split('T')[0];
                                                            let dy = dt?.split("-")[2];
                                                            return (
                                                                <ClientCard
                                                                    name={prdct?.gift_card_detail?.title}
                                                                    price={prdct?.price}
                                                                    created_at={prdct?.created_at}
                                                                    sale_by={prdct?.member}
                                                                    // description={prdct?.product_details}
                                                                    dt={dt}
                                                                    dy={dy}
                                                                />
                                                            )
                                                        })}
                                                        {client_sale?.gift_cards?.length >= 5 &&
                                                            <div
                                                                className='w-fit mx-auto pt-2 cursor-pointer text-xs underline text-[#555555] text-center'
                                                                onClick={() => {
                                                                    localStorage.setItem('client_search', SelectedClient?.full_name)
                                                                    navigate("/dashboard/sales/sale-history")
                                                                }}
                                                            >
                                                                {selectedLanguage?.name == "English" ? "See more" : translation[154]?.value ? translation[154]?.value : "See more"}
                                                            </div>}
                                                    </div>}

                                                </>
                                                : ""
                                            : ""
                                }

                                {
                                    SideSection == 'Customers' &&
                                    <div className='p-3'>
                                        <div className='rounded-md p-4 text-[#55555] h-[10rem]'>
                                            {
                                                SelectedClient?.customer_note ? SelectedClient?.customer_note : <>
                                                    <div className='mx-auto w-fit h-full grid place-items-center text-center'>
                                                        <div>
                                                            <div className='w-fit mx-auto'>
                                                                <Svgs.NotFoundIcon />
                                                            </div>
                                                            <h1 className='font-semibold pt-2 text-sm'>
                                                                {selectedLanguage?.name == "English" ? "No Record Found!" : translation[27]?.value ? translation[27]?.value : "No Record Found!"}
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>

            }
            {
                showAddClientPopup &&
                <AddClientPopup open={showAddClientPopup} close={setShowAddClientPopup} getClients={getClients} />
            }
        </div>
    )
}

export default ClientSection