import VerificationMethod from "./VerificationMethod";
import VerificationForm from "./VerificationForm";
import { useEffect, useState } from "react";
import MobileVerify from "../Signup/MobileVerify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useClientUrlFromCreatedLink from "Hooks/useClientUrlFromCreatedLink";
import { CLIENT_SIGNUP } from "Redux/NstyleRedux/ActionTypes/signupTypes";
import useClientLandingScreenUrl from "Hooks/useClientLandingScreenUrl";

const UserVerificationOTP = ({ onVerify, ignore_activity, ...props }) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const { storeDataInLocalStorage } = useSetDataInLocalStorage();
  const { nexts, hash, token, domain } = useClientUrlFromCreatedLink();
  const { urls } = useClientLandingScreenUrl();
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [is_selected, setSelected] = useState(true);

  const [method_type, setMethodType] = useState("Email");
  const [account_type, setAccountType] = useState()
  const [sent, setSent] = useState(false);

  useEffect(() => {
    let is_otp_sent = searchParams.get('otp_sent')
    let user_query = searchParams.get('user_query')
    let query_type = searchParams.get('query_type')
    let account_type = searchParams.get('account_type')

    let otp_qureies = [is_otp_sent, user_query, query_type, account_type]

    if (otp_qureies.every(itm => itm)) {
      setSent(user_query)
      setMethodType(query_type)
      setAccountType(account_type)
    }
  }, [])

  const getServerData = async (domain) => {
    const result = await axios.get(
      `http://${process.env.REACT_APP_SERVER_APPLICATION_DOMAIN}/ssl-certificate-nstyle-sub-domain/${domain}`
    );
  };
  console.log(location.pathname.includes('forgot'), "location.pathname.includes('forgot')")

  return (
    <div
      className={`flex flex-1 flex-col gap-5 justify-center lg:w-[75%] w-full py-[3rem] slide-in-bottom`}
    >
      {sent ? (
        <>
          <MobileVerify
            onVerifyOTP={location.pathname.includes('forgot') || location.pathname.endsWith('forgot') ? (
              () => {
                onVerify({ sent_to: sent, method_type: method_type });
              }
            ) : (
              (response) => {
                toast.success("Authenticated successfully", { toastId: "toast" });
                if (account_type?.toLowerCase() === "business") {
                  let userData = response?.data?.response?.data;
                  let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
                  if (typeof window !== 'undefined') {
                    hostName = window.location.host;
                  }
                  const hostArr = hostName.split(".");
                  hostArr.unshift(`${userData?.domain}`);
                  const result = hostArr.join(".");
                  try {
                    getServerData(userData?.domain);
                  } catch { }
                  let is_asked_for_data_update = userData.is_asked_for_data_update
                  // navigate("/auth/login/?account_type=business")

                  let redirect_params = ''
                  if (!is_asked_for_data_update && !userData?.employee) {
                    redirect_params += `is_asked_for_data_update=${is_asked_for_data_update}&`
                  }
                  window.location = `http://${result}/authentication/login/auto_login/?user_login_id=${userData.id}&access_token=${userData.access_token}&next_path=/dashboard/account/setup/business-settings/additional-information&redirection_source=nstyle_app&redirection_purpose=account_and_mobile_otp_verification&${redirect_params}`;
                  storeDataInLocalStorage("otp", "verified");
                  storeDataInLocalStorage("currentStep", 3);
                } else {
                  if (account_type?.toLowerCase() == "everyone") {
                    dispatch({
                      type: CLIENT_SIGNUP.CLIENT_INFO,
                      payload: response?.data?.response?.data,
                    });
                    storeDataInLocalStorage("client_token", response?.data?.response?.data?.access_token);
                    storeDataInLocalStorage("client_id", response?.data?.response?.data?.client_id);
                    storeDataInLocalStorage("client_email", response?.data?.response?.data?.email);
                    if (nexts) {
                      navigate(
                        `${nexts}&hash=${hash}&token=${token}&domain=${domain}`
                      );
                    } else {
                      navigate(`${urls}`);
                    }
                    return;
                  }
                }
              }
            )}
            // onVerifyOTP={() => {
            //   onVerify({ sent_to: sent, method_type: method_type });
            // }}
            topHeading={"Verification"}
            secondHeading={"Please verify your email by entering the 4-digit code sent to"}
            code_for={method_type}
            sent_to={sent}
          />
        </>
      ) : is_selected ? (
        <>
          <VerificationForm
            ignore_activity={ignore_activity}
            onSendCode={({ sent_to }) => {
              setSent(sent_to);
            }}
            onCancel={() => {
              setSelected(false);
              setMethodType(undefined);
            }}
            method_type={method_type}
          />
        </>

      ) : (
        <VerificationMethod
          onChange={(data) => {
            if (data.type) {
              setSelected(true);
              setMethodType(data.type);
            }
          }}
        />
      )}
    </div>
  );
};

UserVerificationOTP.defaultProps = {
  onVerify: () => { },
  ignore_activity: false,
};

export default UserVerificationOTP;
