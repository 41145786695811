import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import React from 'react'

const TaxReportTable = ({ data, tableFields, location_currency }) => {
    const totalCols = tableFields?.length + 1
    return (
        <div className="overflow-x-auto">
            <div className={`inline-grid gap-2 xl:grid grid-cols-[repeat(${totalCols},minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                <p className=''>Category</p>
                {tableFields?.map((item, index) => {
                    return (
                        <p key={index} className=''>{item?.tax_type}</p>
                    )
                })}
            </div>
            <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px] overflow-y-auto`}
            >
                {data?.length > 0 ? (
                    data?.map((item, index) => {
                        return (
                            <div key={index} className={`text-xs px-12 py-3 inline-grid gap-2 xl:grid grid-cols-[repeat(${totalCols},minmax(115px,1fr))]  min-w-full w-fit`}>
                                <p className=''>
                                    {item?.category}
                                </p>
                                {tableFields?.map((itm, index) => {
                                    return (
                                        <p key={index}>
                                            {item?.tax_breakdown?.length > 0
                                                ? (item?.tax_breakdown?.find(tax => tax?.tax_name === itm?.tax_type)?.tax_value || "0.00")
                                                : "0.00"
                                            } {location_currency}
                                        </p>
                                    );
                                })}
                            </div>
                        );
                    })
                ) : (
                    <NoReportDataFound />
                )}
            </div>
        </div>
    )
}

export default TaxReportTable
