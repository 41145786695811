import { getDonationReportApi } from "Adapters/Api/donationReport";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const getStartOfMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString("en-CA");
};

const getTodayDate = () => {
    return new Date().toLocaleDateString("en-CA");
};

const useDonationsReportHelper = () => {
    const dispatch = useDispatch()

    const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");

    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const location_currency = state?.utility?.currency?.location_currency;
    const { locations } = useSelector((state) => state);
    const { selected_location } = locations;

    // states
    const [loading, setLoading] = useState(true)
    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [loadingPermission, setLoadingPermission] = useState(true)
    const [data, setData] = useState()
    const [startDate, setStartDate] = useState(getStartOfMonth());
    const [endDate, setEndDate] = useState(getTodayDate());

    useEffect(() => {
        setStartDate(getStartOfMonth());
        setEndDate(getTodayDate());
    }, []);

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    useEffect(() => {
        if (selected_location) {
            getDonationData()
        }
    }, [startDate, endDate, selected_location])

    // handle reset date
    const handleResetDate = () => {
        setStartDate(getStartOfMonth());
        setEndDate(getTodayDate());
    }

    // get donation report data
    const getDonationData = async () => {
        setLoading(true)
        const success = (res) => {
            setLoading(false)
            setData(res)
        }
        const fail = () => {
            setLoading(false)
        }
        let payload = {
            location_id: selected_location,
            start_date: startDate,
            end_date: endDate,
        }
        dispatch(getDonationReportApi(access_token, payload, success, fail))
    }


    return {
        translation, selectedLanguage, storeDataInLocalStorage, startDate, setStartDate, endDate, setEndDate, employee,
        employeePermissions, loadingPermission, location_currency, data, loading, handleResetDate
    }
}

export default useDonationsReportHelper
