import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import InputWithIcon from "Components/TenantApp/Elements/InputWithIcon";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import UploadFile from "Components/TenantApp/Elements/UploadFile";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import CardTile from "../../CardTile";
import Popup from "Components/TenantApp/Elements/Popup";
import NotFound from "Components/TenantApp/../Utility/NotFound";
import FormSteps from "Components/TenantApp/Elements/FormSteps";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import PhoneInput from "Components/TenantApp/Elements/PhoneInput";
import ToggleWithText from "Components/TenantApp/Elements/ToggleWithText";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import useAddress from "Hooks/useAddress";
import { WEEKDAYS } from "Constants/Data/Months";
import { TimeZones } from "Constants/Data/Timezones";
import { OPENING_TIME } from "Constants/Data/OpeningHours";
import {
  createBusinessLocation,
  deleteBusinessLocation,
  getAllLanguages,
  getBusinessById,
  getBusinessTypes,
  getLocations,
  updateBusiness,
  updateLocations,
} from "Adapters/Api";
import {
  createLocations,
  deleteLocations,
  editBusiness,
  fetchBusiness,
  updateLocation,
} from "Redux/TenantRedux/Actions/locationActions";

import Svgs from "Assets/svgs";
import CheckboxCard from "Components/TenantApp/Elements/CheckboxCard";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
import { apiErrorMessage } from "Constants/Data/Errors";
import LocationCardTile from "../../LocationCardTitle";
import { useRef } from "react";
import { getLanguages } from "Redux/TenantRedux/Actions/multiLanguageInvoicesActions";
import { getMultiLanguageInvoice } from "Adapters/Api/multiLanguageInvoice";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import Textarea from "Components/TenantApp/Elements/Textarea";
import AutoPlaceInput from "Components/TenantApp/Elements/AutoPlaceInput";

const BusinessSettings = () => {
  const businessFormRef = useRef()
  const formRefLocation = useRef()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let urls = window?.location?.host;
  const [location_del_popup, setLocDelPopup] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [location2, setLocation2] = useState({});
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [step, setstep] = useState(1);

  const locationList = useSelector((state) => state.locations.locations);
  const reduxBusiness = useSelector((state) => state?.locations?.business);
  const stateData = useSelector((state) => state);

  const { setErrors, validation, errors, onBlurHandler, validateWebsiteUrl } =
    useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();

  const { storeDataInLocalStorage, getDataFromLocalStorage } =
    useSetDataInLocalStorage();

  // get business_data and access_token from localStorage
  const businessData = getDataFromLocalStorage("business_data");
  const access_token = getDataFromLocalStorage("access_token");
  const user_id = getDataFromLocalStorage("user_id");
  const sub_domain = getDataFromLocalStorage("domain-name");
  const hash = getDataFromLocalStorage("hash");

  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const { countriesList, stateList, setStateList } =
    useAddress(countryId);
  const [Location, setLocation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const reduxstate = useSelector((state) => state);
  const currency_list = reduxstate?.utility?.currency?.currency_list;
  const [addres, setAddress] = useState("");

  // ******** create link state start*************
  const [ShareLink, setShareLink] = useState(false);
  const [CreateLink, setCreateLink] = useState(false);
  const [selected_location, setSelectedLocation] = useState();
  const [copy, setCopy] = useState(false);
  const [searchText, setSearchText] = useState();
  // ******** create link state end*************

  const translation = reduxstate?.languages?.language_translation
  const selectedLanguage = reduxstate?.languages?.selected_language
  const reduxInvoices = reduxstate?.multilanguageInvoices?.invoices;
  const selectedLocationId = localStorage.getItem('selected_location')

  const isPermissionUpdated = reduxstate?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  //location form state
  const [locationData, setLocationData] = useState({
    address_name: "",
    address: "",
    postal_code: "",
    country: "",
    state: "",
    city: "",
    email: "",
    mobile_number: "",
    banking: "Enable",
    start_time: "",
    close_time: "",
    currency: "",
    primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.code == "eng")?.id : "",
    secondary_translation_id: "",
    finance_email: "",
    bank_name: "",
    branch_name: "",
    account_title: "",
    iban_no: "",
    account_no: "",
    trn_no: ""
  });

  const {
    address_name,
    address,
    country,
    state,
    city,
    postal_code,
    email,
    banking,
    mobile_number,
    currency,
    primary_translation_id,
    secondary_translation_id,
    privacy_policy
  } = locationData;

  const [businessLocations, setBusinessLocations] = useState({
    country_name: "",
    state_name: "",
    city_name: "",
  });
  const { country_name, state_name, city_name } = businessLocations;
  // business form state
  const [businessSetting, setBusinessSetting] = useState({});
  const [buttonClick, setButtonClick] = useState(false)
  const {
    business_name,
    file,
    timezone,
    time_format,
    week_start,
    website,
    facebook,
    instagram,
    logo,
  } = businessSetting;

  // business form handler
  const handleBusinessDetailChange = (e, fieldName = "") => {
    const business_data = getDataFromLocalStorage("business_data");

    if (e?.target) {
      const { name, value } = e.target;
      if (name == "website" || name == "facebook" || name == "instagram") {
        setBusinessSetting((prev) => ({ ...prev, [name]: value.trim() }));
      } else {
        setBusinessSetting((prev) => ({ ...prev, [name]: value }));
      }
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }

    if (e?.target?.files) {
      setBusinessSetting((prev) => ({ ...prev, file: e.target.files[0] }));
      setErrors((prev) => ({ ...prev, file: "" }));
    }
  };

  const getLanguageList = async () => {
    const response = await getAllLanguages();
    if (response.status === 200) {
      let languageList = response?.data?.response?.languages;
      dispatch(getLanguages(languageList))
      // let laguageOptionList = setOptionForDropdown(languageList);
      // setAllLanguages(laguageOptionList);
    }
  };

  useEffect(() => {
    getLanguageList()
  }, [])

  useEffect(() => {
    if (!secondary_translation_id && primary_translation_id) {
      const filteredValues = reduxInvoices?.filter(itm => itm?.status == "active")?.filter(itm => itm?.id != primary_translation_id)
      setLocationData((prev) => ({
        ...prev,
        secondary_translation_id: filteredValues[0]?.id,
      }));
    }
  }, [secondary_translation_id, primary_translation_id, reduxInvoices, Location])

  // location form handler
  const handleLocationChange = (e, fieldName = "") => {
    if (e?.target) {
      const { name, value } = e?.target;
      let trimmedValue = value

      if (name === "country") {
        setCountryId(trimmedValue)
        setLocationData((prev) => ({
          ...prev,
          city: '',
        }));
        // setCityList();
      }
      if (name === "privacy_policy") {
        e.target.value = e.target.value?.replace(/\r\n|\r/g, '\n')
        trimmedValue = e.target.value?.slice(0, 500); // Trim the value to a maximum of 150 characters

      }
      setLocationData((prev) => ({
        ...prev,
        [name]: trimmedValue,
      }));

      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
      name === "state" && setStateId(trimmedValue);
    }
  };

  // handle location financial change
  const handleFinancialChange = (e) => {
    const { name, value } = e?.target;

    // Clear the error for the current field
    setErrors((prev) => ({ ...prev, [name]: '' }));

    if (name === 'finance_email') {
      // Regular expression for validating email addresses
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Trim spaces, split by comma, and remove empty strings
      const emails = value
        ?.split(',')
        ?.map((email) => email.trim())
        ?.filter((email) => email !== '');

      // Validate emails if input is not empty
      if (emails?.length > 0) {
        const allValid = emails?.every((email) => emailRegex?.test(email)) && !value?.endsWith(',');

        if (!allValid) {
          setErrors((prev) => ({ ...prev, [name]: 'Enter Valid Financial Email' }));
        }
      }
      setLocationData((prev) => ({ ...prev, [name]: value }));
    } else {
      // Update location data for non-email fields
      setLocationData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onfirststepsubmit = () => {
    const requiredFields = {
      currency,
      address_name,
      address,
      email,
      mobile_number,
      country,
      primary_translation_id
    };
    setErrors(validation(requiredFields));
    const result = validation(requiredFields);
    if (objectKeyConvertToArray(result)?.length === 0) {
      setstep(2);
    } else {
      formRefLocation.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onLocationSubmit = async () => {
    if (!isButtonDisabled) {
      if (step == 2) {
        setstep(3);
      } else {
        const requiredFields = {
          currency,
          address_name,
          address,
          email,
          mobile_number,
          country,
          primary_translation_id
        };
        setErrors(validation(requiredFields));
        const result = validation(requiredFields);

        if (objectKeyConvertToArray(result)?.length === 0) {
          const location_id = getDataFromLocalStorage("location_id");
          if (isEdit) {
            setLoading(true);
            let form_data = new FormData();
            for (let key in locationData) {
              if (locationData[key]) {
                form_data.append(key, locationData[key]);
              }
            }

            form_data.append("open_day", JSON.stringify(location2));
            form_data.append("business", businessData.id);

            const response = await updateLocations(form_data, access_token);
            if (response?.status === 200) {
              let location = response?.data?.response?.location;
              let successMessage = response?.data?.response?.message;
              setLocationData({
                address_name: "",
                address: "",
                postal_code: "",
                country: "",
                state: "",
                city: "",
                email: "",
                mobile_number: "",
                start_time: "",
                close_time: "",
                banking: "Enable",
                primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.name == "English")?.id : "",
                secondary_translation_id: "",
                finance_email: "",
                bank_name: "",
                branch_name: "",
                account_title: "",
                iban_no: "",
                account_no: "",
                trn_no: ""
              });
              setLocation2({});
              setStateList();
              // setCityList();
              setCountryId();
              setStateId();
              dispatch(updateLocation(location));
              setLocation(!Location);
              toast.success(successMessage, { toastId: "toast" });
              setLoading(false);
              setstep(1);
              getBusinessLocation(businessData?.id);
            } else {
              setLoading(false);
              toast.error(apiErrorMessage, { toastId: "toast" });
            }
          } else {
            setLoading(true);

            let form_data = new FormData();
            for (let key in locationData) {
              if (locationData[key]) {
                form_data.append(key, locationData[key]);
              }
            }
            form_data.append("open_day", JSON.stringify(location2));
            form_data.append("business", businessData.id);

            // const response = await createBusinessLocation({ ...locationData, business: businessData?.id }, access_token);
            const response = await createBusinessLocation(form_data, access_token);
            if (response?.status === 201) {
              let locations = response?.data?.response?.locations;
              let successMessage = response?.data?.response?.message;
              toast.success(successMessage, { toastId: "toast" });
              dispatch(createLocations(locations));
              if (locationList?.length === 0) {
                storeDataInLocalStorage("selected_location", locations?.id);
                const selected_location = getDataFromLocalStorage('selected_location')
                if (selected_location != locations?.id) {
                  storeDataInLocalStorage('locationChange', true)
                }
              }
              setLoading(false);

              setLocationData({
                address_name: "",
                address: "",
                postal_code: "",
                country: "",
                state: "",
                city: "",
                email: "",
                mobile_number: "",
                start_time: "",
                close_time: "",
                banking: "Enable",
                finance_email: "",
                bank_name: "",
                branch_name: "",
                account_title: "",
                iban_no: "",
                account_no: "",
                trn_no: ""
              });
              setLocation2({});
              setStateList();
              // setCityList();
              setCountryId();
              setStateId();
              setLocation(!Location);
              // getBusinessLocation(businessData?.id);
              setstep(1);
            } else {
              setLoading(false);

              toast.error(apiErrorMessage, { toastId: "toast" });
            }
          }
        }
      }
    } else {
      formRefLocation.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  // submit update business form
  const onBusinessDetailSubmit = async () => {
    setErrors(validation({ business_name }));
    let result = validation({ business_name });
    if (objectKeyConvertToArray(result)?.length === 0) {
      const result = website && validateWebsiteUrl(website);
      const result1 = facebook && validateWebsiteUrl(facebook);
      const result2 = instagram && validateWebsiteUrl(instagram);

      if (
        ((website && result) || !website) &&
        ((facebook &&
          result1 &&
          facebook?.toLowerCase().includes("facebook.com")) ||
          !facebook) &&
        ((instagram &&
          result2 &&
          instagram.toLowerCase().includes("instagram.com")) ||
          !instagram)
      ) {
        let form_data = new FormData();
        let new_business_setting = { ...businessSetting };
        for (let dt in new_business_setting) {
          if (dt == "logo" && typeof new_business_setting[dt] == "string") {
          } else {
            if (new_business_setting[dt]) {
              form_data.append(dt, new_business_setting[dt]);
            }
          }
        }
        form_data.append("business", businessData.id);
        setSaving(true);
        const response = await updateBusiness(form_data, access_token);
        setSaving(false);

        if (response.status === 200) {
          let business = response?.data?.response?.business;
          let successMessage = response?.data?.response?.message;
          dispatch(editBusiness(business));
          toast.success(successMessage, { toastId: "toast" });
          navigate("/dashboard/account/setup");
        } else {
          toast.error(response.response.data.response.message);
        }
      } else {
        toast.error(selectedLanguage?.name == "English" ? 'Your social link url is Invalid!' : translation[1242]?.value ? translation[1242]?.value : 'Your social link url is Invalid!', { toastId: "toast" });

        setErrors((prev) => ({
          ...prev,
          website: website && !result
            ? selectedLanguage?.name == "English" ? 'This is invalid url!' : translation[1241]?.value ? translation[1241]?.value : 'This is invalid url!'
            : "",
          facebook:
            facebook &&
              (!result1 || !facebook?.toLowerCase().includes("facebook.com"))
              ? selectedLanguage?.name == "English" ? 'This is invalid url!' : translation[1241]?.value ? translation[1241]?.value : 'This is invalid url!'
              : "",
          instagram:
            instagram &&
              (!result2 || !instagram?.toLowerCase().includes("instagram.com"))
              ? selectedLanguage?.name == "English" ? 'This is invalid url!' : translation[1241]?.value ? translation[1241]?.value : 'This is invalid url!'
              : "",
        }));
      }
    } else {
      businessFormRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  //get business location and stored it in redux
  const getBusinessLocation = (business_id) => {
    dispatch(getLocations(business_id));
  };

  // delete business locations
  const locationDeleteHandler = async (location_id) => {
    setDeleting(true);
    const response = await deleteBusinessLocation(location_id, access_token);
    setLocDelPopup(false);
    setDeleting(false);
    if (response.status === 200) {
      const successMessage = response?.data?.response?.message;

      dispatch(deleteLocations(location_id));
      toast.success(successMessage, { toastId: "toast" });
    } else {
      toast.error("error in deleting locations", { toastId: "toast" });
    }
  };

  // click on edit button to store specific location in form field
  const locationEidtHandler = (location_id) => {
    setIsEdit(true);
    storeDataInLocalStorage("location_id", location_id);
    const selectedLocation = locationList.find(
      (item) => item.id === location_id
    );

    selectedLocation?.country?.unique_id &&
      setCountryId(selectedLocation?.country?.unique_id);
    // selectedLocation?.state?.id && setStateId(selectedLocation?.state?.id);

    const result = selectedLocation?.opening_hours?.map((item) => {
      if (!item?.is_closed) {
        return {
          [item.day]: {
            start_time: item?.start_time,
            end_time: item?.close_time,
            is_closed: item?.is_closed,
          },
        };
      }
    });
    setLocation2({
      ...result[0],
      ...result[1],
      ...result[2],
      ...result[3],
      ...result[4],
      ...result[5],
      ...result[6],
    });
    setLocationData((prev) => ({
      ...prev,
      location: location_id,
      address_name: selectedLocation.address_name,
      address: selectedLocation.address,
      postal_code: selectedLocation.postal_code,
      email: selectedLocation?.email,
      mobile_number: selectedLocation?.mobile_number,
      start_time: selectedLocation?.start_time,
      close_time: selectedLocation?.close_time,
      country: selectedLocation?.country?.unique_id,
      state: selectedLocation?.state?.unique_id,
      city: selectedLocation?.city?.name,
      banking: selectedLocation?.banking,
      currency: selectedLocation?.currency?.id,
      primary_translation_id: selectedLocation?.primary_translation,
      secondary_translation_id: selectedLocation?.secondary_translation,
      privacy_policy: selectedLocation?.privacy_policy,
      finance_email: selectedLocation?.finance_email,
      bank_name: selectedLocation?.bank_name,
      branch_name: selectedLocation?.branch_name,
      account_title: selectedLocation?.account_title,
      iban_no: selectedLocation?.iban_no,
      account_no: selectedLocation?.account_no,
      trn_no: selectedLocation?.trn_no
    }));
    setLocation(!Location);
  };

  useEffect(() => {
    if (businessData?.id) {
      if (locationList?.length === 0) {
        getBusinessLocation(businessData?.id);
      }
    }
  }, []);

  const getBusiness = async (user_id) => {
    const response = await getBusinessById(user_id, false, access_token);

    if (response.status === 200) {
      let business = response?.data?.response?.business;
      dispatch(fetchBusiness(business));
      business?.id && dispatch(getLocations(business?.id));
      setBusinessSetting(business);
      setErrors((prev) => ({
        ...prev,
        business_name: business.business_name && "",
      }));
    }
  };

  useEffect(() => {
    if (user_id) {
      if (Object.keys(reduxBusiness).length === 0) {
        getBusiness(user_id);
      } else {
        setBusinessSetting(reduxBusiness);
      }
    }
    dispatch(getBusinessTypes());
  }, []);

  const handleAddressSuggetion = async (value) => {
    const result = await geocodeByAddress(value);
    setAddress(value);
    setLocationData((prev) => ({ ...prev, address: value }));
    setErrors((prev) => ({ ...prev, address: "" }));
  };

  useEffect(() => {
    setEmployee(stateData?.user?.user?.employee);
    setEmployeePermissions(stateData?.user?.permissions);
  }, [stateData?.user, employee, employeePermissions]);

  useEffect(() => {
    let isDisabled = false;
    if (step == 2 && (Object.keys(location2)?.length == 0
      || Object.keys(location2)?.find(key => (!location2[key]?.end_time || !location2[key]?.start_time)))) {
      isDisabled = true;
    }
    setIsButtonDisabled(isDisabled);
  }, [location2, step]);

  useEffect(() => {
    if (!locationData?.primary_translation_id && reduxInvoices?.length > 0) {
      setLocationData((prev) => ({ ...prev, primary_translation_id: reduxInvoices?.find(itm => itm?.language_data?.code == "eng")?.id ? reduxInvoices?.find(itm => itm?.language_data?.code == "eng")?.id : reduxInvoices[0]?.id }));
    }
  }, [reduxInvoices, reduxInvoices?.length, locationData?.primary_translation_id])

  // const getInovices = () => {
  //   setLoading(true)
  //   const success = () => { setLoading(false) }
  //   const fail = () => { setLoading(false) }
  //   dispatch(getMultiLanguageInvoice(access_token, success, fail))
  // }

  // useEffect(() => {
  //   getInovices()
  // }, [])

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/setup/");
            }}
          >
            {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
          </span>{" "}
          &gt;{" "}
          <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Business Settings" : translation[654]?.value ? translation[654]?.value : "Business Settings"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Business Settings" : translation[654]?.value ? translation[654]?.value : "Business Settings"}
            </h2>
          </div>
        </div>
        <div className="flex flex-col gap-6" ref={businessFormRef}>
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
            <CircleIcon>
              <Svgs.Business_info />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Business Info" : translation[656]?.value ? translation[656]?.value : "Business Info"}

              </h2>
              <p className="text-[#7B7B7B] text-sm">
                {selectedLanguage?.name == "English" ? "Your business information will be visible to all users on Nstyle." : translation[670]?.value ? translation[670]?.value : "Your business information will be visible to all users on Nstyle."}
              </p>
            </div>
          </div>

          <div className="lg:w-[36%] w-full">
            {(employee && employeePermissions?.account_business_setting?.includes("edit")) ||
              !employee ? (
              <LoginInput
                required={true}
                name="business_name"
                value={business_name ? business_name : ""}
                error={errors?.business_name}
                // charactersMaxLength={25}
                onChange={(e) => handleBusinessDetailChange(e)}
                // error={errors.business_name}
                // onBlur={() => onBlurHandler({ business_name: business_name })}
                title={selectedLanguage?.name == "English" ? "Business Name" : translation[671]?.value ? translation[671]?.value : "Business Name"}
                placeholder={selectedLanguage?.name == "English" ? "Enter your business name" : translation[684]?.value ? translation[684]?.value : "Enter your business name"}
              />
            ) : (employee && (employeePermissions?.pos_root_access?.includes("view") || employeePermissions?.account_root_access?.includes("view"))) ? (
              <LoginInput
                required={true}
                name="business_name"
                disabled={true}
                value={business_name ? business_name : ""}
                onChange={(e) => handleBusinessDetailChange(e)}
                // charactersMaxLength={25}
                // error={errors.business_name}
                // onBlur={() => onBlurHandler({ business_name: business_name })}
                title={selectedLanguage?.name == "English" ? "Business Name" : translation[671]?.value ? translation[671]?.value : "Business Name"}
                placeholder={selectedLanguage?.name == "English" ? "Enter your business name" : translation[684]?.value ? translation[684]?.value : "Enter your business name"}
              />
            ) : (
              ""
            )}

            {/* <LoginInput
              required={false}
              name="business_name"
              value={business_name ? business_name : ""}
              onChange={(e) => handleBusinessDetailChange(e)}
              // error={errors.business_name}
              // onBlur={() => onBlurHandler({ business_name: business_name })}
              title="Business Name"
              placeholder="Enter your business name"
            /> */}
          </div>

          {loadingPermission ?
            <UploadFile animate />
            :
            (employee &&
              employeePermissions?.account_business_setting?.includes("edit")) ||
              !employee ? (
              <UploadFile
                title={selectedLanguage?.name == "English" ? "Business Logo" : translation[672]?.value ? translation[672]?.value : "Business Logo"}
                multiple={false}
                onChange={(e) => {
                  setBusinessSetting({
                    ...businessSetting,
                    logo: e.target.files[0],
                  });
                }}
                name="logo"
                id="business_logo"
                value={logo ? logo : null}
                edit={logo ? true : false}
                // error={errors && errors?.file ? true : false}
                accept=".png,.PNG,.JPG,.jpg,jpeg,.JPEG"
                mb_file_size={10}
              />
            ) : (employee && employeePermissions?.pos_root_access?.includes("view")) ? (
              <UploadFile title={"Business Logo"} hideButton disabled={true} name="logo" value={businessSetting?.logo ? businessSetting?.logo : null} />
            ) : ("")}
        </div>

        <hr className="bg-[#3F3F441A] my-[1.5rem]" />

        {/* <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
          <CircleIcon>
            <Svgs.Calendar_2 />
          </CircleIcon>
          <div className="flex flex-col gap-1 lg:text-left text-center">
            <h2 className="text-primary font-semibold text-xl">
              Time and calendar settings
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              Choose the time zone and format on which you want to accomplish
              business operations.{" "}
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-[2rem]">
          <Dropwdown
            name="timezone"
            value={timezone}
            onChange={handleBusinessDetailChange}
            options={TimeZones.map((tz) => {
              return {
                label: `${tz.timezone} ${tz.name}`,
                value: `${tz.timezone} ${tz.name}`,
              };
            })}
            title="Timezone"
            placeholder="Select Timezone"
          />
          <Dropwdown
            name="time_format"
            value={time_format}
            onChange={(e) => handleBusinessDetailChange(e, "time_format")}
            options={[
              {
                value: "12 hours (e.g. 9:00pm)",
                label: "12 hours (e.g. 9:00pm)",
              },
              {
                value: "24 hours (e.g. 13:00)",
                label: "24 hours (e.g. 13:00)",
              },
            ]}
            // error={errors.time_format}
            // onBlur={() => onBlurHandler({ time_format: time_format })}
            title="Time Format"
            placeholder="Select Time Format"
          />
          <Dropwdown
            name="week_start"
            value={week_start}
            onChange={(e) => handleBusinessDetailChange(e, "week_start")}
            options={[
              { value: "Monday", label: "Monday" },
              { value: "Tuesday", label: "Tuesday" },
              { value: "Wednesday", label: "Wednesday" },
              { value: "Thursday", label: "Thursday" },
              { value: "Friday", label: "Friday" },
              { value: "Saturday", label: "Saturday" },
              { value: "Sunday", label: "Sunday" },
            ]}
            // error={errors.week_start}
            // onBlur={() => onBlurHandler({ week_start: week_start })}
            title="Week Start"
            placeholder="Select Day"
          />
        </div>

        <hr className="bg-[#3F3F441A] my-[1.5rem]" /> */}

        <div className="flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
            <CircleIcon>
              <Svgs.Location />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</h2>
              <p className="text-[#7B7B7B] text-sm">
                {selectedLanguage?.name == "English" ? "Add all the existing locations of your business." : translation[674]?.value ? translation[674]?.value : "Add all the existing locations of your business."}
              </p>
            </div>
          </div>
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} />
            :
            <div className="flex items-center gap-2">
              {!employee ? (
                <>
                  <LoginBtn
                    onClick={() => {
                      setShareLink(!ShareLink);
                    }}
                    borderBtn

                  >
                    {selectedLanguage?.name == "English" ? "Create Share Link" : translation[675]?.value ? translation[675]?.value : "Create Share Link"}
                  </LoginBtn>
                  <LoginBtn
                    onClick={() => {
                      setStateList();
                      // setCityList();
                      setCountryId();
                      setStateId();
                      setIsEdit(false);
                      setLocation(!Location);
                    }}
                  >
                    {selectedLanguage?.name == "English" ? "Add Location" : translation[44]?.value ? translation[44]?.value : "Add Location"}
                  </LoginBtn>
                </>
              ) : employee &&
                employeePermissions?.account_business_setting?.includes(
                  "edit"
                ) ? (
                ""
              ) : (
                ""
              )}
            </div>
          }
        </div>

        {locationList?.length > 0 ? (
          <>
            <div className="flex flex-col gap-3 mt-[2rem]">
              {locationList &&
                locationList?.map((location) => {
                  return (
                    !employee ? (
                      <LocationCardTile
                        selectedLocationId={selectedLocationId}
                        id={location.id}
                        lefticon_class={location?.images && '!p-0'}
                        leftImage={location?.images && <img src={location?.images} className="h-full w-full object-cover" />}
                        leftIcon={!(location?.images) && <Svgs.Home size={'1.5rem'} />}
                        title={location?.address_name}
                        desc={location?.address}
                        onDelete={() => setLocDelPopup(location.id)}
                        onEdit={() => locationEidtHandler(location.id)}
                      />
                    ) : employee && employeePermissions?.account_business_setting?.includes("edit") ? (
                      <LocationCardTile
                        selectedLocationId={selectedLocationId}
                        id={location.id}
                        lefticon_class={location?.images && '!p-0'}
                        leftImage={location?.images && <img src={location?.images} className="h-full w-full object-cover" />}
                        leftIcon={!(location?.images) && <Svgs.Home size={'1.5rem'} />}
                        title={location?.address_name}
                        desc={location?.address}
                        onEdit={() => locationEidtHandler(location.id)}
                      />
                    ) : employee && (employeePermissions?.pos_root_access?.includes("view") || employeePermissions?.account_root_access?.includes("view")) ?
                      (
                        <LocationCardTile
                          selectedLocationId={selectedLocationId}
                          id={location.id}
                          lefticon_class={location?.images && '!p-0'}
                          leftImage={location?.images && <img src={location?.images} className="h-full w-full object-cover" />}
                          leftIcon={!(location?.images) && <Svgs.Home size={'1.5rem'} />}
                          title={location?.address_name}
                          desc={location?.address}
                        />
                      ) : ("")
                  )
                })}
            </div>
          </>
        ) : (
          <NotFound className="" />
        )}

        <hr className="bg-[#3F3F441A] my-[1.5rem]" />
        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
          <CircleIcon>
            <Svgs.Link />
          </CircleIcon>
          <div className="flex flex-col gap-1 lg:text-left text-center">
            <h2 className="text-primary font-semibold text-xl">
              {selectedLanguage?.name == "English" ? "Link" : translation[676]?.value ? translation[676]?.value : "Link"}
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              {selectedLanguage?.name == "English" ? "Add your website and social media links to share with clients." : translation[677]?.value ? translation[677]?.value : "Add your website and social media links to share with clients."}
            </p>
          </div>
        </div>

        {!employee ? (
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-[2rem]">
            <InputWithIcon
              name="website"
              value={website ? website : ""}
              onChange={handleBusinessDetailChange}
              error={errors?.website}
              className="w-full"
              title={selectedLanguage?.name == "English" ? "Website Link" : translation[690]?.value ? translation[690]?.value : "Website Link"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Website Link" : translation[681]?.value ? translation[681]?.value : "Enter Website Link"}
              icon={<Svgs.Website />}
            />

            <InputWithIcon
              name="facebook"
              value={facebook ? facebook : ""}
              onChange={handleBusinessDetailChange}
              error={errors?.facebook}
              className="w-full"
              title={selectedLanguage?.name == "English" ? "Facebook Page" : translation[680]?.value ? translation[680]?.value : "Facebook Page"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Facebook Page Link" : translation[682]?.value ? translation[682]?.value : "Enter Facebook Page Link"}
              icon={<Svgs.Facebook />}
            />

            <InputWithIcon
              name="instagram"
              value={instagram ? instagram : ""}
              onChange={handleBusinessDetailChange}
              error={errors?.instagram}
              className="w-full"
              title={selectedLanguage?.name == "English" ? "Instagram Link" : translation[679]?.value ? translation[679]?.value : "Instagram Link"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Instagram Page Link" : translation[682]?.value ? translation[682]?.value : "Enter Instagram Page Link"}
              icon={<Svgs.Instagram />}
            />
          </div>
        ) : employee && employeePermissions?.account_business_setting?.includes("edit") ? (
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-[2rem]">
            <InputWithIcon
              name="website"
              value={website ? website : ""}
              onChange={handleBusinessDetailChange}
              error={errors?.website}
              className="w-full"
              title={selectedLanguage?.name == "English" ? "Website Link" : translation[690]?.value ? translation[690]?.value : "Website Link"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Website Link" : translation[681]?.value ? translation[681]?.value : "Enter Website Link"}
              icon={<Svgs.Website />}
            />

            <InputWithIcon
              name="facebook"
              value={facebook ? facebook : ""}
              onChange={handleBusinessDetailChange}
              error={errors?.facebook}
              className="w-full"
              title={selectedLanguage?.name == "English" ? "Facebook Page" : translation[680]?.value ? translation[680]?.value : "Facebook Page"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Facebook Page Link" : translation[682]?.value ? translation[682]?.value : "Enter Facebook Page Link"}
              icon={<Svgs.Facebook />}
            />

            <InputWithIcon
              name="instagram"
              value={instagram ? instagram : ""}
              onChange={handleBusinessDetailChange}
              error={errors?.instagram}
              className="w-full"
              title={selectedLanguage?.name == "English" ? "Instagram Link" : translation[679]?.value ? translation[679]?.value : "Instagram Link"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Instagram Page Link" : translation[682]?.value ? translation[682]?.value : "Enter Instagram Page Link"}
              icon={<Svgs.Instagram />}
            />
          </div>
        ) : (employee && (employeePermissions?.pos_root_access?.includes("view") || employeePermissions?.account_root_access?.includes("view"))) ?
          (
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-[2rem]">
              <InputWithIcon
                name="website"
                disabled={true}
                value={website ? website : ""}
                onChange={handleBusinessDetailChange}
                error={errors?.website}
                className="w-full"
                title={selectedLanguage?.name == "English" ? "Website Link" : translation[690]?.value ? translation[690]?.value : "Website Link"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Website Link" : translation[681]?.value ? translation[681]?.value : "Enter Website Link"}
                icon={<Svgs.Website />}
              />

              <InputWithIcon
                name="facebook"
                disabled={true}
                value={facebook ? facebook : ""}
                onChange={handleBusinessDetailChange}
                error={errors?.facebook}
                className="w-full"
                title={selectedLanguage?.name == "English" ? "Facebook Page" : translation[680]?.value ? translation[680]?.value : "Facebook Page"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Facebook Page Link" : translation[682]?.value ? translation[682]?.value : "Enter Facebook Page Link"}
                icon={<Svgs.Facebook />}
              />

              <InputWithIcon
                name="instagram"
                disabled={true}
                value={instagram ? instagram : ""}
                onChange={handleBusinessDetailChange}
                error={errors?.instagram}
                className="w-full"
                title={selectedLanguage?.name == "English" ? "Instagram Link" : translation[679]?.value ? translation[679]?.value : "Instagram Link"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Instagram Page Link" : translation[682]?.value ? translation[682]?.value : "Enter Instagram Page Link"}
                icon={<Svgs.Instagram />}
              />
            </div>
          ) : ("")}


        {loadingPermission ?
          <Shimmer className={'flex items-center justify-start mt-[2rem] w-44 lg:w-48'}>
            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
          </Shimmer> :
          <div className="flex items-center justify-start mt-[2rem]">
            {!employee ? (
              <LoginBtn
                padding=""
                onClick={onBusinessDetailSubmit}
                // disabled={(!business_name?.trim()) && isDisabled}
                // disabled={!business_name?.trim()}
                loading={saving}
              >
                {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}

              </LoginBtn>
            ) : employee && employeePermissions?.account_business_setting?.includes("edit") ? (
              <LoginBtn
                padding=""
                onClick={onBusinessDetailSubmit}
                // disabled={(!business_name?.trim()) && isDisabled}
                disabled={!business_name?.trim()}
                loading={saving}
              >
                {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}

              </LoginBtn>
            ) : (employee && (employeePermissions?.pos_root_access?.includes("view") || employeePermissions?.account_root_access?.includes("view"))) ?
              (
                <LoginBtn
                  // disabled={(!business_name?.trim()) && isDisabled}
                  disabled={true}
                >
                  {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                </LoginBtn>
              ) : ("")}
          </div>
        }
      </div>

      {/*  Delete Location popup*/}
      {location_del_popup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Delete Location" : translation[695]?.value ? translation[695]?.value : "Delete Location"}
          close={() => {
            setLocDelPopup(!location_del_popup);
          }}
          loading={true}
        >
          <div>
            <p className="mb-4">
              {selectedLanguage?.name == "English" ? "Are you sure you want to delete this location?" : translation[696]?.value ? translation[696]?.value : "Are you sure you want to delete this location?"}
            </p>
            <div className="flex justify-end">
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Yes" : translation[697]?.value ? translation[697]?.value : "Yes"}
                deleteBtn
                loading={false}
                onClick={() => {
                  locationDeleteHandler(location_del_popup);
                }}
              />
            </div>
          </div>
        </Popup>
      )}

      {/* Select Location for share Popup */}
      <Popup
        size="sm"
        heading={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
        close={() => {
          setShareLink(false);
          setCreateLink(false);
          setSelectedLocation();
        }}
        open={ShareLink}
      >
        {!CreateLink ? (
          <div className="flex flex-col gap-4">
            <LoginInput
              name="searchText"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              title=""
              placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
              padding="py-1"
              leftIcon={<Svgs.Search />}
            />
            {(locationList.length > 0 && locationList?.filter((itm) => itm?.is_publish === true)).length > 0 ?
              <>
                <h1 className="font-semibold">
                  {selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations"}
                </h1>
                {locationList
                  ?.filter((itm) => itm?.is_publish === true)
                  ?.filter((flt) => {
                    if (searchText) {
                      let returnValue = false;
                      if (
                        flt?.address_name
                          ?.toLowerCase()
                          ?.includes(searchText?.toLowerCase())
                      ) {
                        returnValue = true;
                      } else {
                        returnValue = false;
                      }
                      return returnValue;
                    }
                    return true;
                  })
                  ?.map((lctn) => {
                    return (
                      <div className="flex flex-col gap-3">
                        <div className="flex items-center gap-3">
                          <CheckBox
                            value={selected_location}
                            checked={selected_location?.id === lctn?.id}
                            onChange={() => {
                              setSelectedLocation(lctn);
                            }}
                          />
                          <div>
                            <img
                              src={`${lctn?.images
                                ? lctn?.images
                                : `${process.env.PUBLIC_URL}/images/location_img.png`
                                }`}
                              className="h-[5rem] w-[7rem] border object-cover rounded-lg"
                            />
                          </div>
                          <div className="flex flex-col gap-2 flex-1">
                            <h1 className="font-semibold">{lctn?.address_name}</h1>
                            <p className="text-[#555555]">{lctn?.address}</p>
                          </div>
                          {/* <div>
                            <div className="h-[2.5rem] w-[2.5rem] rounded-full bg-[#EDEDED] flex items-center justify-center">
                              <Svgs.NextArrow size="1rem" fill="#000000" />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
              </>
              :
              selectedLanguage?.name == "English" ?
                "You don't have any online location, go to online profile to list your location for online booking"
                : translation[688]?.value ? translation[688]?.value
                  : "You don't have any online location, go to online profile to list your location for online booking"
            }


            <LoginBtn
              disabled={!selected_location}
              text={selectedLanguage?.name == "English" ? "Create Link" : translation[689]?.value ? translation[689]?.value : "Create Link"}

              onClick={() => {
                setCreateLink(true);
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4 items-center text-center">
            <div className="h-[6rem] w-[6rem] rounded-full flex items-center justify-center bg-green-gradient">
              <Svgs.Check size="2rem" />
            </div>
            <h1 className="font-semibold">
              {selectedLanguage?.name == "English" ? "Your Link is Ready!" : translation[692]?.value ? translation[692]?.value : "Your Link is Ready!"}
            </h1>
            <p className="text-[#555555]">
              {selectedLanguage?.name == "English" ? "You have created a link to your Sale listed on NStyle" : translation[693]?.value ? translation[693]?.value : "You have created a link to your Sale listed on NStyle"}
            </p>
            <CopyToClipboard
              // text={`http://${window?.location?.host
              //   .split(".")
              //   .slice(1)
              //   .join(".")}/profile/${selected_location?.address_name
              //     ?.replaceAll(",", "")
              //     .replaceAll(" ", "-")}/${selected_location?.address
              //       ?.replaceAll(",", "")
              //       .replaceAll(" ", "-")}/${selected_location?.id}?business=${businessData.id
              //   }&domain=${sub_domain}&hash=${hash}&token=${access_token}`}
              text={`http://${window?.location?.host
                .split(".")
                .slice(1)
                .join(".")}/profile/${selected_location?.address_name
                  ?.replaceAll(",", "")
                  .replaceAll(" ", "-")}/${selected_location?.address
                    ?.replaceAll(",", "")
                    .replaceAll(" ", "-")}/${selected_location?.id}?business=${businessData.id
                }&domain=${sub_domain}&token=${access_token}`}
              onCopy={() => {
                setCopy(true);
                toast.success("copied to clipboard!", { toastId: "toast" });
              }}
            >
              <div className="flex flex-col gap-2  w-full relative capitalize">
                <InputWithIcon
                  disabled={true}
                  fill="#555555"
                  title=""
                  value={`http://${window?.location?.host
                    .split(".")
                    .slice(1)
                    .join(".")}/profile/${selected_location?.address_name
                      ?.replaceAll(",", "")
                      .replaceAll(" ", "-")}/${selected_location?.address
                        ?.replaceAll(",", "")
                        .replaceAll(" ", "-")}/${selected_location?.id}?business=${businessData.id
                    }`}
                  icon={<Svgs.Copy />}
                />
              </div>
            </CopyToClipboard>
            <div className="flex items-center justify-center gap-3">
              <LoginBtn
                borderBtn
                text={selectedLanguage?.name == "English" ? "See Your Profile" : translation[694]?.value ? translation[694]?.value : "See Your Profile"}
                onClick={() => {
                  window.open(
                    `http://${window?.location?.host
                      .split(".")
                      .slice(1)
                      .join(".")}/profile/${selected_location?.address_name
                        ?.replaceAll(",", "")
                        .replaceAll(" ", "-")}/${selected_location?.address
                          ?.replaceAll(",", "")
                          .replaceAll(" ", "-")}/${selected_location?.id
                    }?business=${businessData.id
                    }&domain=${sub_domain}token=${access_token}`,
                    "_blank",
                    "noreferrer"
                  );
                }}
              // onClick={() => {
              //   window.open(
              //     `http://${window?.location?.host
              //       .split(".")
              //       .slice(1)
              //       .join(".")}/profile/${selected_location?.address_name
              //         ?.replaceAll(",", "")
              //         .replaceAll(" ", "-")}/${selected_location?.address
              //           ?.replaceAll(",", "")
              //           .replaceAll(" ", "-")}/${selected_location?.id
              //     }?business=${businessData.id
              //     }&domain=${sub_domain}&hash=${hash}&token=${access_token}`,
              //     "_blank",
              //     "noreferrer"
              //   );
              // }}
              />
              <LoginBtn
                onClick={() => {
                  setShareLink(false);
                  setCreateLink(false);
                  setSelectedLocation();
                }}
                text={selectedLanguage?.name == "English" ? "Done!" : translation[101]?.value ? translation[101]?.value : "Done!"}
              />
            </div>
          </div>
        )}
      </Popup>

      {/* Add / Edit Lcation Popup */}
      <Popup
        size="lg"
        heading={`${isEdit ?
          selectedLanguage?.name == "English" ? "Edit Location" : translation[88]?.value ? translation[88]?.value : "Edit Location"
          :
          selectedLanguage?.name == "English" ? "Add Location" : translation[44]?.value ? translation[44]?.value : "Add Location"
          } `}
        close={() => {
          setLocation(!Location);
          setBusinessLocations({
            country_name: "",
            state_name: "",
            city_name: "",
          });
          setstep(1);
          setLocation2({});
          setLocationData({
            address_name: "",
            address: "",
            postal_code: "",
            country: "",
            state: "",
            city: "",
            email: "",
            mobile_number: "",
            banking: "Enable",
          });
          setErrors({});
        }}
        open={Location}
      >
        <div className="pb-[1.5rem]">
          <FormSteps
            currentStep={step}
            setStep={setstep}
            active={step}
            steps={[
              selectedLanguage?.name == "English" ? "Location Details" : translation[46]?.value ? translation[46]?.value : "Location Details",
              selectedLanguage?.name == "English" ? "Time Schedule" : translation[47]?.value ? translation[47]?.value : "Time Schedule",
              selectedLanguage?.name == "English" ? "Financial Setting" : translation[47]?.value ? translation[47]?.value : "Financial Setting"
            ]}
            stepsLength="3"
          />
        </div>

        {step === 1 && (
          <div className="flex flex-col gap-6" ref={formRefLocation}>
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.Location />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Fill in all the details to add your business location" : translation[691]?.value ? translation[691]?.value : "Fill in all the details to add your business location"}
                </p>
              </div>
            </div>
            <LoginInput
              required={true}
              name="address_name"
              value={address_name}
              // charactersMaxLength={25}
              onChange={handleLocationChange}
              error={errors.address_name}
              onBlur={() => onBlurHandler({ address_name: address_name })}
              title={selectedLanguage?.name == "English" ? "Location Name" : translation[48]?.value ? translation[48]?.value : "Location Name"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Location Name" : translation[50]?.value ? translation[50]?.value : "Enter Location Name"}
            />
            {/* <LoginInput
              required={true}
              onChange={handleLocationChange}
              name="address"
              value={address}
              error={errors.address}
              onBlur={() => onBlurHandler({ address: address })}
              title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Business Address" : translation[51]?.value ? translation[51]?.value : "Enter Business Address"}
            /> */}
            <AutoPlaceInput
              onChange={handleLocationChange}
              name={"address"}
              value={address}
              required={true}
              title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Business Address" : translation[51]?.value ? translation[51]?.value : "Enter Business Address"}
              error={errors?.address}
            />
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
                placeholder={selectedLanguage?.name == "English" ? "Select Country" : translation[53]?.value ? translation[53]?.value : "Select Country"}
                name="country"
                value={country}
                onChange={(e) => handleLocationChange(e, "country")}
                onBlur={() => onBlurHandler({ country: country })}
                error={errors.country}
                options={countriesList}
                edit={isEdit ? true : false}
              />
              <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "State/Province" : translation[54]?.value ? translation[54]?.value : "State/Province"}
                placeholder={selectedLanguage?.name == "English" ? "Select State" : translation[55]?.value ? translation[55]?.value : "Select State"}
                name="state"
                value={state}
                onChange={(e) => handleLocationChange(e, "state")}
                options={stateList}
                edit={isEdit ? true : false}
              />
              {/* <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Postal Code" : translation[57]?.value ? translation[57]?.value : "Select City"}
                name="city"
                value={city}
                onChange={handleLocationChange}
                // onBlur={() => onBlurHandler({ city: city })}
                // error={errors.city}
                options={cityList}
                edit={isEdit ? true : false}
              /> */}

              <LoginInput
                required={false}
                name={"city"}
                value={city}
                disabled={!state || !country}
                onChange={handleLocationChange}
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Enter City Name" : translation[57]?.value ? translation[57]?.value : "Enter City Name"}
              />

              <LoginInput
                required={false}
                title={selectedLanguage?.name == "English" ? "Postal Code" : translation[58]?.value ? translation[58]?.value : "Postal Code"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Postal Code" : translation[59]?.value ? translation[59]?.value : "Enter Postal Code"}
                name="postal_code"
                // type="number"
                type="text"
                value={postal_code}
                onChange={handleLocationChange}
              />

              <LoginInput
                required={true}
                title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Email Address" : translation[61]?.value ? translation[61]?.value : "Enter Email Address"}
                name="email"
                value={email}
                onChange={handleLocationChange}
                error={errors?.email}
                onBlur={() => onBlurHandler({ email: email })}
                disabled={isEdit ? true : false}
              />

              <PhoneInput
                required={true}
                name="mobile_number"
                title={selectedLanguage?.name == "English" ? "Phone Number" : translation[62]?.value ? translation[62]?.value : "Phone Number"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Phone Number" : translation[63]?.value ? translation[63]?.value : "Enter Phone Number"}
                value={mobile_number}
                onChange={handleLocationChange}
                error={errors.mobile_number}
                onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
                edit={isEdit ? true : false}
              />

              <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "Booking" : translation[64]?.value ? translation[64]?.value : "Booking"}
                placeholder={selectedLanguage?.name == "English" ? "Select Booking" : translation[65]?.value ? translation[65]?.value : "Select Booking"}
                name="banking"
                value={banking}
                onChange={handleLocationChange}
                options={[
                  { value: "Enable", label: selectedLanguage?.name == "English" ? "Enable" : translation[65]?.value ? translation[65]?.value : "Enable" },
                  { value: "Disable", label: selectedLanguage?.name == "English" ? "Disable" : translation[66]?.value ? translation[66]?.value : "Disable" },
                ]}
              />
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                name={"currency"}
                value={currency}
                disabled={isEdit ? true : false}
                onChange={handleLocationChange}
                onBlur={() => onBlurHandler({ currency: currency })}
                error={errors.currency}
                options={currency_list?.map((crncy) => {
                  return {
                    label: crncy?.code,
                    value: crncy?.id,
                  };
                })}
              />

              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Primary Language" : translation[1290]?.value ? translation[1290]?.value : "Primary Language"}
                placeholder={selectedLanguage?.name == "English" ? "Select Primary Language" : translation[1291]?.value ? translation[1291]?.value : "Select Primary Language"}
                name="primary_translation_id"
                value={primary_translation_id}
                onChange={handleLocationChange}
                error={errors.primary_translation_id}
                onBlur={() => onBlurHandler({ primary_translation_id: primary_translation_id })}
                options={reduxInvoices?.filter(itm => itm?.status == "active")
                  ?.filter(itm => itm?.id != secondary_translation_id)?.map((lang) => {
                    return {
                      label: lang?.language_data?.name,
                      value: lang?.id,
                    };
                  })}
              />

              <Dropwdown
                title={selectedLanguage?.name == "English" ? "Secondary Language" : translation[1289]?.value ? translation[1289]?.value : "Secondary Language"}
                placeholder={selectedLanguage?.name == "English" ? "Select Secondary Language" : translation[1288]?.value ? translation[1288]?.value : "Select Secondary Language"}
                name="secondary_translation_id"
                value={secondary_translation_id}
                onChange={handleLocationChange}
                error={errors.secondary_translation_id}
                onBlur={() => onBlurHandler({ secondary_translation_id: secondary_translation_id })}
                options={[
                  {
                    value: "",
                    label: selectedLanguage?.name == "English" ? "Select Secondary Language" : translation[1288]?.value ? translation[1288]?.value : "Select Secondary Language"
                  },
                  ...reduxInvoices?.filter(itm => itm?.status == "active")?.filter(itm => itm?.id != primary_translation_id)?.map((lang) => {
                    return { value: lang?.id, label: lang?.language_data?.name };
                  }),
                ]}
              />
            </div>
            <div>
              <Textarea
                error={errors.privacy_policy}
                title={selectedLanguage?.name == "English" ? "Terms & Conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & Conditions"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Terms & Conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & Conditions"}
                rows='6'
                limit='500'
                name='privacy_policy'
                onChange={handleLocationChange}
                disabled={loading}
                value={privacy_policy}
                required={false}
              />
            </div>
            <div className="flex items-center justify-end mt-[2rem]">
              <LoginBtn
                // disabled={
                //   !currency ||
                //   !address_name ||
                //   !address ||
                //   !email ||
                //   !mobile_number ||
                //   !country
                // }
                onClick={() => {
                  // setstep(2)
                  onfirststepsubmit();
                  // onLocationSubmit
                }}

              >
                <div className="flex items-center gap-2">
                  <div className="">
                    {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                  </div>
                  <Svgs.NextArrow />
                </div>
              </LoginBtn>
            </div>
          </div>
        )}
        {step == 2 && (
          <div className="flex flex-col gap-3" ref={formRefLocation}>
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[3rem]">
              <CircleIcon>
                <Svgs.Location />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Opening Hours" : translation[70]?.value ? translation[70]?.value : "Opening Hours"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Set the opening and closing hours of your business" : translation[71]?.value ? translation[71]?.value : "Set the opening and closing hours of your business"}
                </p>
              </div>
            </div>
            {WEEKDAYS.map((days, index) => {
              days = days.toLowerCase();
              return (
                <>
                  <div className="md:grid flex grid-cols-5 gap-4 lg:flex-row flex-col pb-[1rem]">
                    <div className="col-span-1 flex items-center">
                      <div className="w-full">
                        <ToggleWithText
                          checked={
                            location2[days]?.start_time &&
                            location2[days]?.end_time
                          }
                          title={<p className="capitalize">{days}</p>}
                          value={location2[days]}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setLocation2({
                                ...location2,
                                [days]: {
                                  is_closed: false,
                                  start_time:
                                    days === "monday"
                                      ? ""
                                      : location2["monday"]?.start_time,
                                  end_time:
                                    days === "monday"
                                      ? ""
                                      : location2["monday"]?.end_time,
                                },
                              });
                            } else {
                              delete location2[days];
                              setLocation2({
                                ...location2,
                                // [days]: null
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {location2[days] && (
                      <div className="col-span-4 flex items-center w-full sm:gap-4 gap-2 lg:flex-row flex-col">
                        <Dropwdown
                          className="w-full"
                          title={false}
                          showTitle={false}
                          placeholder={selectedLanguage?.name == "English" ? "Start Time" : translation[73]?.value ? translation[73]?.value : "Start Time"}
                          // options={OPENING_TIME.filter((itm, itm_index) => {
                          //   if (!location2[days]?.end_time) {
                          //     return true;
                          //   } else {
                          //     let index_start = OPENING_TIME.find(
                          //       (op_itm) =>
                          //         op_itm.value == location2[days]?.end_time
                          //     );
                          //     index_start = OPENING_TIME.indexOf(index_start);
                          //     // console.log("index_start",index_start);

                          //     if (index_start >= 0 && itm_index < index_start) {
                          //       return true;
                          //     }

                          //     return false;
                          //   }
                          // })}
                          options={OPENING_TIME}
                          value={location2[days]?.start_time}
                          onChange={(e) => {
                            setLocation2({
                              ...location2,
                              [days]: {
                                ...location2[days],
                                start_time: e.target.value,
                              },
                            });
                          }}
                        // value={location2.sunday.start_time}
                        />
                        <Dropwdown
                          className="w-full"
                          title={false}
                          showTitle={false}
                          placeholder={selectedLanguage?.name == "English" ? "End time" : translation[74]?.value ? translation[74]?.value : "End time"}
                          options={OPENING_TIME.filter((itm, itm_index) => {
                            if (!location2[days]?.start_time) {
                              return true;
                            } else {
                              let index_start = OPENING_TIME.find(
                                (op_itm) =>
                                  op_itm.value == location2[days]?.start_time
                              );
                              index_start = OPENING_TIME.indexOf(index_start);
                              if (index_start >= 0 && itm_index > index_start) {
                                return true;
                              }
                              return false;
                            }
                          })}
                          value={location2[days]?.end_time}
                          onChange={(e) => {
                            setLocation2({
                              ...location2,
                              [days]: {
                                ...location2[days],
                                end_time: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            {(isButtonDisabled && buttonClick) &&
              <div className="text-red-500 text-sm">
                {Object.keys(location2)?.length == 0 ?
                  "Please select one business day and provide timings for all business days." :
                  "Please provide timing for all the active day"}
              </div>
            }
            <div className="flex items-center justify-end mt-[2rem]">
              <LoginBtn
                onClick={() => {
                  setButtonClick(true)
                  onLocationSubmit();
                }}
              >
                <div className="flex items-center gap-2">
                  <div className="">
                    {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                  </div>
                  <Svgs.NextArrow />
                </div>
              </LoginBtn>
            </div>
          </div>
        )}
        {step == 3 && (
          <div className="flex flex-col gap-3" ref={formRefLocation}>
            <h2 className="font-semibold">Financial Settings</h2>
            <LoginInput
              required={false}
              name={"finance_email"}
              error={errors?.finance_email}
              value={locationData?.finance_email}
              onChange={handleFinancialChange}
              title={"Financial Email"}
              placeholder={"Enter Financial Email"}
            />
            <h2 className="font-semibold">Bank Details</h2>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
              <LoginInput
                required={false}
                name={"bank_name"}
                error={errors?.bank_name}
                value={locationData?.bank_name}
                onChange={handleFinancialChange}
                title={"Bank Name"}
                placeholder={"Enter Bank Name"}
              />
              <LoginInput
                required={false}
                name={"branch_name"}
                error={errors?.branch_name}
                value={locationData?.branch_name}
                onChange={handleFinancialChange}
                title={"Branch Name"}
                placeholder={"Enter Branch Name"}
              />
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
              <LoginInput
                required={false}
                name={"iban_no"}
                error={errors?.iban_no}
                value={locationData?.iban_no}
                onChange={handleFinancialChange}
                title={"IBAN"}
                placeholder={"Enter IBAN"}
              />
              <LoginInput
                required={false}
                name={"account_no"}
                error={errors?.account_no}
                value={locationData?.account_no}
                onChange={handleFinancialChange}
                title={"Account Number"}
                placeholder={"Enter Account Number"}
              />
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
              <LoginInput
                required={false}
                name={"account_title"}
                error={errors?.account_title}
                value={locationData?.account_title}
                onChange={handleFinancialChange}
                title={"Account Title"}
                placeholder={"Enter Account Title"}
              />
              <LoginInput
                required={false}
                name={"trn_no"}
                error={errors?.trn_no}
                value={locationData?.trn_no}
                onChange={handleFinancialChange}
                title={"Tax Registration Number"}
                placeholder={"Enter Tax Registration Number"}
              />
            </div>
            <LoginBtn
              disabled={loading}
              loading={loading}
              text={`${isEdit ?
                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                }`}
              onClick={() => {
                setButtonClick(true)
                onLocationSubmit();
              }}
            />
          </div>
        )}
      </Popup>
    </>
  );
};

export default BusinessSettings;
