import React, { useState } from "react";
import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import useUnsubcribe from "./helper";
import AddGroup from "./addGroup";
import AddFilter from "./addFilter";



const Unsubscribe = () => {


  const {
    loading,
    tabList,
    active_tab,
    UnsubscribeData,
    CreateGroup, setCreateGroup,
    ViewDetails, setViewDetails,
    FilterPopup, setFilterPopup,
    translation, selectedLanguage,
  } = useUnsubcribe()




  // Date Range
  // const [indexValue, setIndexValue] = useState()
  // const [startDate, setStartDate] = useState()
  // const [endDate, setEndDate] = useState()
  // const [toggleDateRange, setToggleDateRange] = useState(false)
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb first={selectedLanguage?.name == "English" ? "CRM" : translation[1354]?.value ? translation[1354]?.value : "CRM"} url={"/dashboard/crm/"} second={selectedLanguage?.name == "English" ? "Unsubscribe" : translation[1362]?.value ? translation[1362]?.value : "Unsubscribe"} />
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Unsubscribe" : translation[1362]?.value ? translation[1362]?.value : "Unsubscribe"}
            </h2>
          </div>
          <LoginBtn
            onClick={() => {
              setCreateGroup(!CreateGroup);
            }}
            text={
              <div className="flex items-center gap-3">
                <div>
                  <Svgs.Plus />
                </div>
                <div>
                  <p>{selectedLanguage?.name == "English" ? "Create Group" : translation[579]?.value ? translation[579]?.value : "Create Group"}</p>
                </div>
              </div>
            }
          />
        </div>
        <div className={"border-b my-[2rem]"}>
          <div className="flex items-center gap-6">
            {tabList?.length > 0 &&
              tabList.map((tab, index) => {
                return (
                  <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${active_tab == tab.value && "font-semibold border-primary"}`}
                    key={index}
                  >
                    <h2 className="text-primary capitalize">{tab.label}</h2>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "All Unsubscribed Users" : translation[1388]?.value ? translation[1388]?.value : "All Unsubscribed Users"}</h2>
                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                      <p className="text-primary">4 {selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"}</p>
                    </div>
                  </div>
                </div>
                <div className="mb-00">
                  <LoginInput
                    title=""
                    name={"search_text"}
                    placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                </div>
              </div>
              <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                <LoginBtn
                  borderBtn
                  onClick={() => {
                    setFilterPopup(!FilterPopup);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Filters />
                    <p>{selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}</p>
                  </div>
                </LoginBtn>

              </div>
            </div>

            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}</p>
                <p>{selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"} </p>
                <p className="flex justify-center md:justify-start">{selectedLanguage?.name == "English" ? "Assign Sement" : translation[1365]?.value ? translation[1365]?.value : "Assign Sement"}</p>
                <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                <p>{selectedLanguage?.name == "English" ? "Action" : translation[1366]?.value ? translation[1366]?.value : "Action"}</p>
              </div>
              <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
              >
                {/* --------- Unsubscribe ---------- */}
                {UnsubscribeData?.map((v, i) => {

                  return (
                    <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] min-w-full w-fit">
                      <div className="cursor-pointer h-full flex items-center gap-3">
                        {v.name}
                      </div>
                      <div className="flex items-center text-sm ">
                        {v.email}
                      </div>

                      <div className="flex items-center text-sm justify-center md:justify-start">
                        {v.type}
                      </div>
                      <div className="flex items-center">
                        <StatusBtn
                          status={v.is_active ? "Active" : "Inactive"}
                          text={v.is_active ? "Email" : "SMS"}
                        />
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                          <Svgs.Edit fill="#7D7D7D" />
                        </div>
                        <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                          <Svgs.Delete fill="#7D7D7D" />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* -------------- */}
              </div>
            </div>
          </div>
        </div>
        {CreateGroup && (
          <AddGroup CreateGroup={CreateGroup} setCreateGroup={setCreateGroup} UnsubscribeData={UnsubscribeData} />
        )
        }
        {FilterPopup && (
          <AddFilter setFilterPopup={setFilterPopup} FilterPopup={FilterPopup} />
        )
        }
      </div>
    </>
  );
};

export default Unsubscribe;
