import { getSingleBlog } from "Adapters/Api/blogs"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DOMPurify from 'dompurify';

const useBlogDetailHelper = () => {
    const navigation = useNavigate()
    const [loading, setLoading] = useState(true)
    const [blogDetail, setblogDetail] = useState()
    const params = useParams();

    function createMarkup(html) {
        return { __html: DOMPurify.sanitize(html) };
    }

    const getBlogDetail = () => {
        const success = (response) => {
            setLoading(false)
            setblogDetail(response?.data)
        };

        const fail = () => {
            setLoading(false)
        };

        const payload = {
            slug: params?.slug,
        }
        getSingleBlog(payload, success, fail);

    }

    useEffect(() => {
        setLoading(true)
        getBlogDetail()
    }, [params])


    return {
        loading, blogDetail, navigation, createMarkup
    }
}

export default useBlogDetailHelper