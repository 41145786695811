import { useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeDropDownListPagination } from 'Adapters/Api/employee';
import { getserviceDropdownList } from 'Adapters/Api/services';
import { createComplaintAPI, getAllComplaintCategoryAPI, updateComplaintAPI } from 'Adapters/Api/complaints';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import useObjectKeys from 'Hooks/useObjectKeys';
import { getClientDropDownList } from 'Adapters/Api/clients';

let employeeTimeOutId = undefined

const initialFormData = {
    category: "",
    // customer_name: "",
    client: "",
    service: "",
    description: ""
}

const useCreateComplaintHelper = (open, close, onSucessCallBack) => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const { errors, setErrors, validation } = useValidations()
    const { objectKeyConvertToArray } = useObjectKeys()

    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    const servicesRedux = state?.service?.services;
    const employeeRedux = state?.employee?.employeesDropdown;
    const employeePaginationData = state?.employee?.employeeDropdownPaginationData
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const complaintCategory = state?.complaint?.complaint_category
    const clientRedux = useSelector((state) => state?.client?.clients);
    const { selected_location } = state?.locations;


    const [employeeSearch, setEmployeeSearch] = useState("");
    const [employeePage, setEmployeePage] = useState(1)
    const [employeeLoader, setEmployeeLoader] = useState(false)


    const [submitLoading, setSubmitLoading] = useState(false)
    const [formData, setFormData] = useState(initialFormData)

    const onChangeHandler = (e) => {
        const { name, value } = e.target
        setErrors({ ...errors, [name]: '' })
        setFormData({ ...formData, [name]: value })
    }

    const onSubmit = () => {
        const { category, client, description } = formData

        const validationResult = validation({ category, client, description })
        setErrors(validationResult)

        if (objectKeyConvertToArray(validationResult).length == 0) {
            const success = () => {
                close && close(false)
                setSubmitLoading(false)
                setFormData(initialFormData)
                onSucessCallBack && onSucessCallBack()
            }
            const fail = () => {
                setSubmitLoading(false)
            }
            setSubmitLoading(true)
            open?.id
                ? dispatch(updateComplaintAPI(formData, access_token, success, fail))
                : dispatch(createComplaintAPI(formData, access_token, success, fail))
        }
    }

    useEffect(() => {
        if (selected_location || open) {
            if (employeeSearch === null) {
                // Initial load with empty search_text
                dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
            } else {
                if (employeeTimeOutId) {
                    clearTimeout(employeeTimeOutId)
                }
                employeeTimeOutId = setTimeout(() => {
                    dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
                }, 500);
            }
        }
    }, [employeeSearch, selected_location, employeePage, open])

    useEffect(() => {
        dispatch(getserviceDropdownList());
        dispatch(getAllComplaintCategoryAPI({}, access_token))
        dispatch(getClientDropDownList())
    }, [])

    const serviceDropdown = useMemo(() => {
        return [
            { label: selectedLanguage?.name == "English" ? "Select Service" : translation[1063]?.value ? translation[1063]?.value : "Select Service", value: "" },
            ...servicesRedux?.map((obj) => {
                if (obj) {
                    return {
                        label: obj?.name,
                        value: obj?.id
                    }
                }
            })]
    }, [servicesRedux])

    const complaintCategoryDropdown = useMemo(() => {
        return [
            { label: "Select Category", value: "" },
            ...(complaintCategory?.map((obj) => {
                if (obj) {
                    return {
                        label: obj?.name,
                        value: obj?.id
                    }
                }
            }) || [])
        ]
    }, [complaintCategory])


    const employeeDropdown = useMemo(() => {
        return [
            { label: selectedLanguage?.name == "English" ? 'Select Employee' : translation[241]?.value ? translation[241]?.value : "Select Employee", value: "" },
            ...employeeRedux.map((empl, i) => {
                return {
                    label: empl.full_name,
                    value: empl.id,
                    otherData: {
                        image: empl?.image,
                        DataOne: empl.email,
                        showImage: true,
                        index: i
                    },
                    email: empl.email,
                    mobile_number: empl.mobile_number
                }
            })]
    }, [])

    useEffect(() => {
        if (open?.id) {
            setFormData({ ...open })
        } else {
            setErrors({})
            setFormData(initialFormData)
        }
        setSubmitLoading(false)
    }, [open])

    return {
        submitLoading, formData, onChangeHandler, employeeDropdown, employeePaginationData, employeeSearch, setEmployeeSearch,
        employeePage, setEmployeePage, employeeLoader, translation, selectedLanguage, serviceDropdown, complaintCategoryDropdown,
        errors, onSubmit, clientRedux
    }
}

export default useCreateComplaintHelper