import { createMaintenanceAPI } from "Adapters/Api/maintenance";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const initialFormData = {
    title: '',
    description: '',
    priority_level: '',
}

const useCreateMaintenancePopup = (open, onSuccessCallBack) => {
    const state = useSelector((state) => state);
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");

    const dispatch = useDispatch();
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const { selected_location } = state?.locations;

    const { errors, setErrors, validation } = useValidations()
    const { objectKeyConvertToArray } = useObjectKeys()

    const [submitLoading, setSubmitLoading] = useState(false)
    const [formData, setFormData] = useState(initialFormData)

    const onChangeHandler = (e) => {
        const { name, value } = e.target
        setErrors((prev) => ({
            ...prev,
            [name]: ""
        }));
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    }


    const onSubmit = () => {
        const error = validation(formData)
        setErrors(error)

        if (objectKeyConvertToArray(error)?.length == 0) {
            const success = () => {
                setFormData(initialFormData)
                setSubmitLoading(false);
                onSuccessCallBack()
            }
            const fail = () => { setSubmitLoading(false) }
            setSubmitLoading(true)
            const payload = {
                ...formData,
                location: selected_location,
            }
            dispatch(createMaintenanceAPI(payload, access_token, success, fail))
        }
    }

    useEffect(() => {
        setErrors()
        if (open?.id)
            setFormData(open)
        else
            setFormData(initialFormData)
    }, [open])

    return {
        submitLoading, formData, onChangeHandler, translation, selectedLanguage, errors, onSubmit
    }
}

export default useCreateMaintenancePopup