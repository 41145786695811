import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { getLocations } from 'Adapters/Api';
import { getServiceGroup, getServiceGroupPaginationDropdown } from 'Adapters/Api/saleServices';
import { useLocation } from "new-components/location/helper";
import {
    createServiceTarget, getServiceTarget, updateServiceTarget, deleteServiceTarget, getServiceTargetWithNoPagination
} from 'Adapters/Api/serviceTarget';
import { toast } from 'react-toastify';
import { MONTHS } from 'Constants/Data/Months';
import { apiErrorMessage } from 'Constants/Data/Errors';

let timeOutId = undefined;
let serviceGroupTimeOutId = undefined;

function useServiceTarget() {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const [add_serv_target_loading, setServ_targ_Loading] = useState(false)

    const dispatch = useDispatch()
    const { locationDetail } = useLocation()
    const state = useSelector((state) => state)
    const locationList = state?.locations?.locations;
    const serviceTargetList = state?.serviceTarget?.service_targets;
    const allServiceTargetList = state?.serviceTarget?.all_service_targets;
    const pages = state?.serviceTarget?.pages;
    const allCount = state?.serviceTarget?.allCount;
    const service_target_updated = state?.serviceTarget?.service_target_updated;
    const currency = state?.business?.business?.currency?.code;
    const selected_location = state.locations.selected_location;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    // const serviceGroups = state?.saleService?.servicesgroup;
    const serviceGroups = state?.saleService?.servicesGroupDropdown;


    const [AddService_Target, setAddService_Target] = useState(false);
    const [CopyService_Target, setCopyService_Target] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({
        current_year: '',
        current_month: '',
        current_date: '',
        selected_month: '',
    })
    const [dataList, setDataList] = useState([]);
    const [is_delete, setDelete] = useState(false);
    const [is_Update, setUpdate] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [searchText, setSearchText] = useState();
    const [monthFilter, setMonthFilter] = useState("");
    const [searchYear, setSearchYear] = useState("");
    const [serviceGroup, setServiceGroup] = useState("");
    const [exportLoader, setExportLoader] = useState(false);
    const locations = state?.locations?.locations;
    const location = locations?.find((obj) => obj?.id == selected_location);
    const currencyOfSelectedLocation = location?.currency?.code;

    const [serviceGroupSearchText, setServiceGroupSearchText] = useState("");
    const [serviceGroupCurrentPage, setServiceGroupCurrentPage] = useState(1)
    const [serviceGroupLoader, setServiceGroupLoader] = useState(false)
    const serviceGroupPaginationData = state?.saleService?.servicesGroupDropdownPagination;
    // const reduxgroup = state?.saleService.servicesGroupDropdown;

    useEffect(() => {
        if (serviceGroupSearchText === null) {
            // dispatch(getServiceGroup());
            dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, "", setServiceGroupLoader))
        } else {
            if (serviceGroupTimeOutId) {
                clearTimeout(serviceGroupTimeOutId)
            }
            serviceGroupTimeOutId = setTimeout(() => {
                dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, serviceGroupSearchText, setServiceGroupLoader))
            }, 500);
        }
    }, [serviceGroupSearchText, serviceGroupCurrentPage])


    const {
        handleSubmit,
        register,
        reset,
        control,
        watch,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(vaildationSchema),
        mode: "onBlur"
    });

    useEffect(() => {
        let date = new Date();
        let today_year = date.getFullYear();
        let today_month = date.getMonth();
        let today_date = date.getDate();
        let today_day = date.getDay();

        setData({
            ...data,
            current_year: today_year,
            current_month: today_month,
            current_date: today_date,
            selected_month: MONTHS[today_month],
        });
    }, [])

    //on submit  service target 
    const onSubmitTarget = (data) => {
        setServ_targ_Loading(true)
        const body = {
            business: businessData?.id,
            location: data?.location,
            service_group: data?.service_group,
            month: data?.month,
            year: data?.year,
            service_target: data?.service_target,
        }
        dispatch(
            createServiceTarget(
                body,
                access_token,
                (message) => {
                    setServ_targ_Loading(false)
                    toast.success(message, { toastId: "toast" });
                    setAddService_Target(false)
                    setCurrentPage(1)
                    dispatch(getServiceTargetWithNoPagination(selected_location))
                    dispatch(getServiceTarget(selected_location, 1, searchText, monthFilter, searchYear, serviceGroup, setLoader))
                    reset()
                    // setServiceTargetValue("")
                },
                () => {
                    setServ_targ_Loading(false)
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            )
        )


    }

    const successCall = () => {
        setCopyService_Target(false)
        setCurrentPage(1)
        dispatch(getServiceTargetWithNoPagination(selected_location))
        dispatch(getServiceTarget(selected_location, 1, searchText, monthFilter, searchYear, serviceGroup, setLoader))
        reset()
    }
    const yearDropdown = useMemo(() => {
        if (data.current_year) {
            let years = [];
            for (let i = data.current_year; i <= 2040; i++) {
                years.push({ label: i.toString(), value: i.toString() })
            }
            return years;
        }
    }, [data.current_year])

    const monthDropdown = useMemo(() => {
        let selected_year = watch('year');
        if (selected_year === undefined || selected_year === null) {
            let months = MONTHS?.map((month, i) => {
                return {
                    label: month,
                    value: month,
                    disabled: true
                };
            })
            return months;
        }
        if (selected_year !== null || selected_year !== undefined || selected_year !== '') {
            let months = MONTHS?.map((month, i) => {
                return {
                    label: month,
                    value: month,
                    disabled:
                        parseInt(selected_year) < parseInt(data.current_year) ? true
                            :
                            parseInt(selected_year) === parseInt(data.current_year) ?
                                data.current_month > i ? true
                                    : false
                                : false
                };
            })
            return months;
        }

    }, [watch('year')])

    //delete handler
    const onDeleteHandler = () => {
        dispatch(
            deleteServiceTarget(
                deleteId,
                access_token,
                (message) => {
                    if (!isToastVisible) {
                        toast.success(message, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }

                    setDelete(false)
                    setCurrentPage(1)
                    dispatch(getServiceTargetWithNoPagination(selected_location))
                    dispatch(getServiceTarget(selected_location, 1, searchText, monthFilter, searchYear, serviceGroup, setLoader))
                },
                () => {
                    if (!isToastVisible) {
                        toast.error(apiErrorMessage, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                }
            )
        )
    }

    // set state of edit record
    const onEditHandler = (data) => {
        setAddService_Target(true)
        setUpdate(true)
        reset({
            id: data?.id,
            business: businessData?.id,
            location: data?.location?.id,
            service_group: data?.service_group?.id,
            month: data?.month,
            year: data?.year?.slice(0, 4),
            service_target: data?.service_target,
        })
    }

    //update record handle here 
    const onUpdateHandler = (data) => {
        setServ_targ_Loading(true)
        const body = {
            id: data?.id,
            business: businessData?.id,
            location: data?.location,
            service_group: data?.service_group,
            month: data?.month,
            year: data?.year,
            service_target: data?.service_target,
        }
        dispatch(
            updateServiceTarget(
                body,
                access_token,
                (message) => {
                    setServ_targ_Loading(false)
                    toast.success(message, { toastId: "toast" });
                    setAddService_Target(false)
                    setUpdate(false)
                    setCurrentPage(1)
                    dispatch(getServiceTargetWithNoPagination(selected_location))
                    dispatch(getServiceTarget(selected_location, 1, searchText, monthFilter, searchYear, serviceGroup, setLoader))
                    reset()
                    // setServiceTargetValue("")
                },
                () => {
                    setServ_targ_Loading(false)
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            )
        )

    }


    //get business location and stored it in redux
    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id));
    };

    useEffect(() => {
        if (locationList?.length === 0 && businessData?.id) {
            getBusinessLocation(businessData?.id);
        }
    }, [businessData?.id])


    useEffect(() => {
        // if (serviceTargetList?.length === 0) {
        //     dispatch(getServiceTarget())
        // }
        if (serviceTargetList) {
            // const val = serviceTargetList?.map((element) => {
            //     if (element?.location?.id === selected_location) {
            //         return { ...element }
            //     }
            // });
            // const serviceTargetArr = val?.filter((element) => element !== undefined);
            // setDataList(serviceTargetArr);
            setDataList(serviceTargetList)
        }
    }, [selected_location, serviceTargetList]);

    useEffect(() => {
        dispatch(getServiceGroup());
    }, [])

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText
            dispatch(getServiceTarget(selected_location, currentPage, "", monthFilter, searchYear, serviceGroup, setLoader))
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                dispatch(getServiceTarget(selected_location, currentPage, searchText, monthFilter, searchYear, serviceGroup, setLoader))
            }, 500);
        }
    }, [currentPage, searchText, monthFilter, searchYear, serviceGroup, selected_location])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText, monthFilter, searchYear, serviceGroup, selected_location])

    useEffect(() => {
        dispatch(getServiceTargetWithNoPagination(selected_location))
    }, [selected_location])

    // search and month filter 
    const service_target_fiter = () => {
        // const filter_data = dataList?.filter((itm) => {
        //     if (monthFilter || searchText || searchYear || serviceGroup) {
        //         let return_value = false;
        //         if (monthFilter) {
        //             if (itm?.month?.toLowerCase() === monthFilter?.toLocaleLowerCase()) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         if (searchText) {
        //             if (itm?.location?.address_name?.toLowerCase()?.includes(searchText?.toLocaleLowerCase())) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         if (serviceGroup) {
        //             if (itm?.service_group?.name?.toLowerCase()?.includes(serviceGroup?.toLocaleLowerCase())) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         if (searchYear) {
        //             if (itm?.year?.toLowerCase()?.includes(searchYear?.toLocaleLowerCase())) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         return return_value;
        //     }
        //     return true;
        // })
        const filter_data = dataList
        return filter_data;
    }
    let serviceTarget = [
        { label: selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id", key: "id" },
        { label: selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location", key: "location" },
        { label: selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group", key: "service_group" },
        { label: selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target", key: "service_target" },
        { label: selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month", key: "month" },
    ];

    const serviceTargetData = {
        filename: "Service Target Report.csv",
        headers: serviceTarget,
        data:
            allServiceTargetList?.length > 0
                ? allServiceTargetList?.map((item) => {
                    let selected_currency = state.locations.locations.find(loc => loc.id == item?.location?.id)?.currency
                    return {
                        id: item?.id ? item?.id.toString().split("-")[0] : "---",
                        location: item?.location?.address_name ? item?.location?.address_name : "----",
                        service_group: item?.service_group?.name ? item?.service_group?.name : "----",
                        service_target: item?.service_target ? `${item?.service_target} ${selected_currency?.code ? selected_currency?.code : ""}` : "----",
                        month: (item?.month && item?.year) ? `${item?.month}, ${item?.year?.slice(0, 4)}` : "----",
                    };
                })
                : [],
    };

    const handleExportClick = () => {
        if (!isToastVisible) {
            if (allServiceTargetList?.length > 0) {
                // setExportLoader(true)
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                    setExportLoader(false)
                }, 300);
            } else {
                toast.error("No data to export", {
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };


    return {
        handleSubmit, register, reset, onDeleteHandler,
        onEditHandler, onUpdateHandler, setAddService_Target,
        onSubmitTarget, setDelete, setDeleteId, setCopyService_Target, setUpdate,
        setSearchText, setMonthFilter, service_target_fiter, setSearchYear,
        locationList, serviceGroups, serviceTargetList, service_target_updated,
        control, errors, isValid, AddService_Target, is_delete, searchYear, serviceGroup,
        setServiceGroup, deleteId, is_Update, searchText, monthFilter, CopyService_Target, locationDetail,
        serviceTargetData, currency, dataList, monthDropdown, yearDropdown, currencyOfSelectedLocation,
        add_serv_target_loading, setServ_targ_Loading, pages, allCount, setCurrentPage, currentPage,
        serviceGroupSearchText, setServiceGroupSearchText, serviceGroupCurrentPage, setServiceGroupCurrentPage, serviceGroupLoader, serviceGroupPaginationData,
        loader, setLoader, selected_location, allServiceTargetList, handleExportClick, exportLoader, successCall
    }
}

export default useServiceTarget

export const vaildationSchema = yup.object().shape({
    location: yup.string().required("This field is required").nullable(),
    month: yup.string().required("This field is required").nullable(),
    year: yup.string().required("This field is required").nullable(),
    service_group: yup.string().required("This field is required").nullable(),
    service_target: yup.string().required("This field is required"),
});
