import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import StatusBtn from 'Components/TenantApp/Elements/StatusBtn';
import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import moment from 'moment';
import React from 'react'

const GiftCardTable = ({ data, setCheckoutId, location_currency, setGiftCardDetail, isFromClient }) => {
    return (
        <div className="overflow-x-auto">
            <div className={`inline-grid xl:grid grid-cols-[repeat(9,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                <p>Invoice ID</p>
                {!isFromClient ?
                    <p>Client</p> : ""
                }
                <p>Sold Date</p>
                <p>Sold By</p>
                <p>Expiry Date</p>
                <p className='col-span-2'>Gift Card Name</p>
                <p>Price</p>
                {isFromClient ?
                    <p>Expiry</p> : ""}
                <p>Action</p>
            </div>
            <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
            >
                {data?.length > 0 ? (
                    data?.map((item, index) => {
                        let expiryStatus
                        const now = moment();
                        if (item?.expiry) {
                            const expiry = moment(item?.expiry)
                            expiryStatus = expiry?.isValid() ? (expiry.isBefore(now) ? "Expired" : "Active") : null;
                        }
                        return (
                            <div key={index} className={`px-12 py-3 inline-grid xl:grid grid-cols-[repeat(9,minmax(115px,1fr))]`}>
                                <div className={`before-left-arrow cursor-pointer`}>
                                    <div className="h-full flex items-center">
                                        <p
                                            className="text-xs text-[#5679FF] font-black line-clamp-1"
                                            onClick={() => {
                                                setCheckoutId(item?.checkout_id)
                                            }}
                                        >
                                            #{item?.invoice_id}
                                        </p>
                                    </div>
                                </div>
                                {!isFromClient ?
                                    <p className="text-xs line-clamp-1 pr-3 capitalize">
                                        {item?.client_type == "In_Saloon"
                                            ? item?.client
                                            : "Walk In"}
                                    </p>
                                    : ""}
                                <p className="text-xs">
                                    {item?.sold_date ? moment(item?.sold_date).format("DD-MM-YYYY") : "----"}
                                </p>
                                <p className="text-xs line-clamp-2">
                                    {item?.sold_by}
                                </p>
                                <p className="text-xs">
                                    {item?.expiry ? moment(item?.expiry).format("DD-MM-YYYY") : "----"}
                                </p>
                                <p className="text-xs line-clamp-2 col-span-2">
                                    {item?.giftcard_name}
                                </p>
                                <p className="text-xs">
                                    {item?.price} {location_currency}
                                </p>
                                {isFromClient ?
                                    <p className="text-xs">
                                        {expiryStatus ?
                                            <StatusBtn
                                                noBorder
                                                rounded={"rounded-[5px]"}
                                                status={expiryStatus}
                                            /> : "-----"
                                        }
                                    </p> : ""}
                                <p>
                                    {item?.checkout_data && item?.checkout_data?.length > 0
                                        ? <LoginBtn
                                            title={'View Detail'}
                                            text={"View Detail"}
                                            customThemeBtn={'theme-btn3 !py-1.5'}
                                            onClick={() => {
                                                setGiftCardDetail(item?.checkout_data)
                                            }}
                                        />
                                        : "------"
                                    }
                                </p>
                            </div>
                        );
                    })
                ) : (
                    <NoReportDataFound />
                )}
            </div>
        </div>
    )
}

export default GiftCardTable
