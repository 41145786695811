import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb'
import React from 'react'
import LocationSelection from "new-components/location";
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import useMaintenance from './helper';
import CreateMaintenancePopup from './CreateMaintenancePopup';
import MaintenanceTable from './Elements/MaintenanceTable';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import UpdateStatusPopup from './Elements/UpdateStatusPopup';
import ViewDetailPopup from './Elements/ViewDetailPopup';
import Pagination from 'new-components/Pagination';

const Maintenance = () => {
    const {
        translation, selectedLanguage, storeDataInLocalStorage, openCreatePopup, setOpenCreatePopup, maintanence, loading, openUpdateStatus, setOpenUpdateStatus, PageSize,
        submitLoading, onUpdateStatus, detailLoading, setViewDetailId, viewDetailId, maintananceDetail, onSuccessCallBack, currentPage, setCurrentPage, maintanence_pagination
    } = useMaintenance()

    console.log('Detail', detailLoading)
    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative bg-primary-background">
                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "Account" : translation[5]?.value ? translation[5]?.value : "Account"}
                    url={"/dashboard/account/"}
                    second={"Maintenance"}
                />

                <div className="flex items-center justify-between sm:gap-4 gap-2 my-[1rem]">
                    <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                        Maintenance
                    </h2>

                    <div className="flex gap-5 ">
                        <LoginBtn title={"Create Maintenance Issue"} text={"Create Maintenance Issue"} onClick={() => setOpenCreatePopup(true)} />
                    </div>
                </div>

                <div className='flex flex-col bg-white rounded-xl gap-6 py-4 border border-[#3F3F441A] '>
                    <div className="flex justify-between items-center gap-4 w-[95%] mx-auto">
                        {loading ?
                            <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                <p className="rounded-md h-5 w-12 mx-1"> </p>
                            </div>
                            :
                            <div className="p-2 bg-[#E4EAF0] px-4 h-fit py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                <p className="text-primary">
                                    {maintanence_pagination?.count ? maintanence_pagination?.count : 0}{" "}
                                    {maintanence_pagination?.count && maintanence_pagination?.count > 1 ?
                                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                    }
                                </p>
                            </div>
                        }
                        <div className="flex justify-end gap-5">
                            <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
                        </div>
                    </div>
                    {loading ?
                        <TableShimer cols={8} rows={10} />
                        :
                        <MaintenanceTable
                            data={maintanence}
                            setViewDetailId={setViewDetailId}
                            setOpenUpdateStatus={setOpenUpdateStatus}
                        />
                    }
                    <Pagination
                        pageSize={PageSize}
                        currentPage={currentPage}
                        onPageChange={page => setCurrentPage(page)}
                        totalCount={maintanence_pagination?.total_pages}
                    />
                </div>
            </div>

            <CreateMaintenancePopup
                open={openCreatePopup}
                close={setOpenCreatePopup}
                onSuccessCallBack={onSuccessCallBack}
            />
            <UpdateStatusPopup
                open={openUpdateStatus}
                close={setOpenUpdateStatus}
                submitLoading={submitLoading}
                onSubmit={onUpdateStatus}
            />
            <ViewDetailPopup
                open={viewDetailId}
                close={setViewDetailId}
                data={maintananceDetail}
                loading={detailLoading}
            />
        </>
    )
}

export default Maintenance