import { getPaymentMethods } from 'Adapters/Api/financialSettings'
import NotFound from 'Components/Utility/NotFound'
import { apiErrorMessage } from 'Constants/Data/Errors'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPaymentMethodList } from 'Redux/TenantRedux/Actions/financialSettingsActions'
import { getClientCheckoutVouchers, getClientCheckoutMemberships, getClientLoyaltyPoints, getSingleCouponOnCheckout, getClientCheckoutGiftCard } from '../../../../../Adapters/Api/apointments'
import Svgs from '../../../../../Assets/svgs'
import useSetDataInLocalStorage from '../../../../../Hooks/useSetDataInLocalStorage'
import LoginBtn from '../../../Elements/LoginBtn'
import LoginInput from '../../../Elements/LoginInput'
import Popup from '../../../Elements/Popup'
import SupportCard from '../../Clients/Elements/SupportCard'
import moment from "moment";
import { getEmployeeList } from 'Adapters/Api/employee'
import { CLIENT } from 'Redux/TenantRedux/ActionTypes/clientActionTypes'
import CheckBoxCardShimmer from 'Components/Utility/Shimmers/CheckBoxCardShimmer'
import PaymentCheckboxCard from 'Components/TenantApp/Elements/PaymentCheckboxCard'
import { OTHER_PAYMENT_METHODS } from 'Constants/Data/Payment'
import GroupBookingDetailCard from './GroupBookingDetailsCard'
import useGroupAppointmentServiceDetails from './useGroupAppointmentServiceDetail'
import GroupSelectedClients from './Elements/GroupSelectedClients'
import { CreateNewCheckout } from 'Adapters/Api/saleServices'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import DiscountPopup from '../../Sales/DiscountPromotion'

const GroupCheckoutFinish = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const state = useSelector(state => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const reduxPaymentMethodList = state?.financialSettings?.paymentMethod;
    const [searchParams, setSearchParams] = useSearchParams();
    const app_group_id = searchParams?.get("app_group_id");

    const { setAppointmentService, payNowBtnDisabled, filteredClientsAppointments, loader, totalCartPrice, setTotalCartPrice,
        cartTotal, setCartTotal, cartRemainingPrice, setCartRemainingPrice, cartChange, setCartChange, appointmentServices
    } = useGroupAppointmentServiceDetails(app_group_id);

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const businessData = getDataFromLocalStorage("business_data");
    const cur_currency = getDataFromLocalStorage("selectedLocationCurrency");

    const [tip_for_emply, setTip_for_emply] = useState([])
    const [employee_tips, setEmployee_tips] = useState([])
    const [employeeTips, setEmployeeTips] = useState([])
    const [currentEmployeeTip, setCurrentEmployeeTip] = useState([])
    const [tip_sum, setTip_sum] = useState(0)
    const [TipToStaff, setTipToStaff] = useState(false)
    const [employeeTipSum, setEmployeeTipSum] = useState(0)
    const [selectedClient, setSelectedClient] = useState("")
    const [appointmentData, setAppointmentData] = useState({ membership_data: [], voucher_data: [], coupon_data: [], loyalty_data: [], gift_card_data: [], payment_method: reduxPaymentMethodList?.length > 0 ? reduxPaymentMethodList[0]?.method_type : '' })
    const [loyalityPoints, setLoyalityPoints] = useState({})
    const [existingLoyalityPoints, setExistingLoyalityPoints] = useState({})
    const [checkoutVouchers, setCheckoutVouchers] = useState([])
    const [checkoutMemberships, setCheckoutMembership] = useState([])
    const [notFound, setNotFound] = useState(false)
    const [voucherNotFound, setVoucherNotFound] = useState(false)
    const [membershipNotFound, setMembershipNotFound] = useState(false)
    const [checkoutLoader, setCheckoutLoader] = useState(false)
    const [giftCardDetail, setGiftCardDetail] = useState(undefined)
    const [giftCardDetailPopup, setGiftCardDetailPopup] = useState(false)
    const [loyalityPointsDetail, setLoyalityPointsDetail] = useState(undefined)
    const [ConfirmRedeem, setConfirmRedeem] = useState(false)
    const [t_price, setT_price] = useState(0)
    const [payBtnDisabled, setPayBtnDisabled] = useState(false)
    const [checkoutGiftCard, setCheckoutGiftCard] = useState([])
    const [giftCardCode, setGiftCardCode] = useState("")
    const [giftCardLoader, setGiftCardLoader] = useState(false)
    const [giftCard_amount, setGiftCard_amount] = useState(0)
    const [giftCardError, setGiftCardError] = useState()
    const [membershipAvailable, setMembershipAvailable] = useState(false)
    const [voucherAvailable, setVoucherAvailable] = useState(false)
    const [loyalityAvailable, setLoyalityAvailable] = useState(false)
    const [Employees, setEmployees] = useState([])
    const [couponCode, setCouponCode] = useState("")
    const [getCouponLoader, setGetCouponLoader] = useState(false)
    const [couponPopup, setCouponPopup] = useState(false);
    const [giftCardPopup, setGiftCardPopup] = useState(false);
    const [voucherPopup, setVoucherPopup] = useState(false);
    const [loyalityPointsPopup, setLoyalityPointsPopup] = useState(false);
    const [totalAmountPaid, setTotalAmountPaid] = useState(0)
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
    const [paymentError, setPaymentError] = useState({ id: '', message: '' })
    const [payment_price, setPaymentPrice] = useState(0)
    const [taxApplied, setLocationTax] = useState(0)
    const [membershipPopup, setMembershipPopup] = useState(false);
    const [loyaltyLoader, setLoyaltyLoader] = useState(true)
    const [membershipLoader, setMembershipLoader] = useState(true)
    const [voucherLoader, setVoucherLoader] = useState(true)
    const [getGiftCardLoader, setGetGiftCardLoader] = useState(true)
    const [totalTips, setTotalTips] = useState(0)
    const [availOfferPopup, setAvailOfferPopup] = useState(false);
    const [donation, setDonation] = useState('')

    const otherOptions = useMemo(() =>
        [
            {
                method_type: "Coupon",
                label: selectedLanguage?.name == "English" ? "Coupon" : translation[1566]?.value ? translation[1566]?.value : "Coupon"
            },
            {
                method_type: "LoyalityPoints",
                label: selectedLanguage?.name == "English" ? "LoyalityPoints" : translation[197]?.value ? translation[197]?.value : "LoyalityPoints"
            },
            {
                method_type: "Voucher",
                label: selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"
            },
            {
                method_type: "Membership",
                label: selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"
            },
            {
                method_type: "GiftCard",
                label: selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"
            },
            {
                method_type: "Promotion",
                label: "Promotion"
            },
        ], [selectedLanguage])

    useEffect(() => {
        // calculating total cart price
        const totalPrice = filteredClientsAppointments?.reduce((accumulator, appointment) => accumulator + appointment?.total_price, 0);
        setTotalCartPrice(totalPrice)
    }, [filteredClientsAppointments])

    useEffect(() => {
        const tips_sum = employeeTips?.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);
        setTotalTips(tips_sum)
    }, [employeeTips])

    useEffect(() => {
        if (businessData.id) {
            fetchPaymentMethods(businessData.id);
        }
    }, [businessData.id])

    useEffect(() => {
        if (filteredClientsAppointments?.length > 0) {
            setAppointmentData((prev) => ({
                ...prev,
                filteredClientsAppointments: filteredClientsAppointments
            }))
        }
    }, [filteredClientsAppointments])

    useEffect(() => {
        if (selectedClient) {
            getLoyaltyPoints(selected_location, selectedClient)
            getCheckoutVouchers(selected_location, selectedClient)
            getCheckoutMembership(selected_location, selectedClient)
            getCheckoutGiftCard()
        }
    }, [selectedClient])

    useEffect(() => {
        dispatch(getEmployeeList())
    }, [])

    useEffect(() => {
        let new_empls = state?.employee?.employees?.filter((emp) => emp?.is_active === true)
        new_empls = new_empls.filter(empl => {
            let active_locs = empl.location.filter(loc => loc.id == state.locations.selected_location)
            if (active_locs.length > 0) {
                return true
            }
        })
        setEmployees(new_empls)
    }, [state.locations.selected_location, state.employee.employees])

    useEffect(() => {
        if (appointmentData?.payment_method == '' && reduxPaymentMethodList?.length) {
            setAppointmentData({
                ...appointmentData,
                payment_method: reduxPaymentMethodList?.length > 0 ? reduxPaymentMethodList[0]?.method_type : ''
            })
        }
    }, [reduxPaymentMethodList?.length, appointmentData, reduxPaymentMethodList, appointmentData?.payment_method])

    useEffect(() => {
        const totalAmount = selectedPaymentMethods?.reduce((sum, item) => sum + item.amount, 0)
        setPaymentPrice(+(cartTotal - (+totalAmount?.toFixed(2))))
        let giftCardPrice = 0
        if (appointmentData?.gift_card_data?.length > 0) {
            giftCardPrice = appointmentData?.gift_card_data[0]?.partial_price
        }
        setTotalAmountPaid((+totalAmount?.toFixed(2) + +(giftCardPrice)?.toFixed(2)))
    }, [selectedPaymentMethods, appointmentData?.gift_card_data, cartTotal])

    //get payment methods
    const fetchPaymentMethods = async (business_id) => {
        const response = await getPaymentMethods(business_id);
        if (response.status === 200) {
            let paymentMethods = response?.data?.response?.payment_methods;
            dispatch(getPaymentMethodList(paymentMethods));
        }
    };

    // get client loyalty points
    const getLoyaltyPoints = (location_id, client_id) => {
        setLoyaltyLoader(true)
        setNotFound(false)
        setLoyalityAvailable(false);
        if (location_id && client_id) {
            dispatch(getClientLoyaltyPoints(
                { location_id: location_id, client_id: client_id },
                (result) => {
                    setLoyaltyLoader(false)
                    setLoyalityPoints(result?.client_loyalty_points)
                    setExistingLoyalityPoints(result?.client_loyalty_points)
                    if (result?.client_loyalty_points) {
                        setLoyalityAvailable(true);
                    } else {
                        setLoyalityAvailable(false);
                    }
                },
                (err) => {
                    setLoyaltyLoader(false)
                    setNotFound(true)
                }
            ))
        }
    }

    // get client vouchers
    const getCheckoutVouchers = (location_id, selectedClient) => {
        setVoucherLoader(true)
        setVoucherAvailable(false);
        if (location_id && selectedClient) {
            dispatch(getClientCheckoutVouchers(
                { location_id: location_id, client_id: selectedClient },
                (result) => {
                    setVoucherLoader(false)
                    const filteredVouchers = result?.client_vouchers?.filter(vchr => vchr?.voucher?.voucher_type == "Service")
                    setCheckoutVouchers(filteredVouchers)
                    if (filteredVouchers?.length > 0) {
                        setVoucherAvailable(true);
                    } else {
                        setVoucherAvailable(false);
                    }
                },
                (err) => {
                    setVoucherLoader(false)
                    setVoucherNotFound(true)
                }
            ))
        }
    }

    // get client memberships
    const getCheckoutMembership = (location_id, selectedClient) => {
        setMembershipLoader(true)
        setMembershipAvailable(false);
        if (location_id && selectedClient) {
            dispatch(getClientCheckoutMemberships(
                { location_id: location_id, client_id: selectedClient },
                (result) => {
                    setMembershipLoader(false)
                    setCheckoutMembership(result?.client_memberships)
                    if (result?.client_memberships?.length > 0) {
                        setMembershipAvailable(true);
                    } else {
                        setMembershipAvailable(false);
                    }
                },
                (err) => {
                    setMembershipLoader(false)
                    setMembershipNotFound(true)
                }
            ))
        }
    }

    // get and redeem coupon detail function
    const checkCouponCode = () => {
        // remove discount with existing data in services
        const filteredClientCoupon = appointmentData?.coupon_data?.length > 0 ? appointmentData?.coupon_data?.find(itm => itm?.client == selectedClient) : undefined
        let filteredAppointmentServices = appointmentData?.filteredClientsAppointments

        if (filteredClientCoupon) {
            filteredAppointmentServices = filteredAppointmentServices?.map(appointment => {
                if (selectedClient == appointment?.client) {
                    let filteredServices = appointment?.appointmnet_service?.filter(service => !service?.is_coupon_redeemed);
                    if (filteredServices?.length > 0) {
                        filteredServices = filteredServices?.map(srv => {
                            const filteredService = filteredClientCoupon?.couponIds?.find(itm => itm?.service == srv?.service)
                            const data = { ...srv }
                            if (filteredService) {
                                delete data?.discount_percentage
                                delete data?.is_coupon_redeemed
                                delete data?.discount_price
                            }
                            return data
                        })
                        return { ...appointment, appointmnet_service: filteredServices }
                    }
                } else {
                    return appointment
                }
            });
            setAppointmentData({ ...appointmentData, filteredClientsAppointments: filteredAppointmentServices });
        }

        const filteredClientData = filteredAppointmentServices?.find(itm => itm?.client == selectedClient)
        setGetCouponLoader(true)
        setCouponPopup(!couponPopup)
        const clientType = 'In_Saloon'
        dispatch(getSingleCouponOnCheckout(
            { coupon_code: couponCode, client_type: clientType, client_id: selectedClient, totalPriceWithoutTax: +filteredClientData?.total_price, location_id: selected_location },
            (result) => {
                setCouponPopup(false)
                const couponDetail = result?.coupon
                setSelectedPaymentMethods([])
                toast.success(result.message)
                setCouponCode('')
                setGetCouponLoader(false)

                // setting data of coupon in appointmentData
                let dataToUpdate = {}
                let finaldata = []
                let updatedclientData = []
                let filteredAppointmentServices = []
                if (couponDetail?.coupon_type == 'refund') {
                    dataToUpdate = { client: selectedClient, discount: +couponDetail?.amount, is_coupon_redeemed: true, coupon: couponDetail?.id, coupon_name: "Refund Coupon", coupon_type: couponDetail?.coupon_type }
                } else if (couponDetail?.coupon_type == 'Cashback') {
                    let couponDiscount
                    if (+filteredClientData?.total_price >= +couponDetail?.dicounted_amount) {
                        couponDiscount = +couponDetail?.dicounted_amount
                    } else {
                        couponDiscount = +filteredClientData?.total_price
                    }
                    dataToUpdate = { client: selectedClient, discount: couponDiscount, is_coupon_redeemed: true, coupon: couponDetail?.id, coupon_name: "Cashback Coupon", coupon_type: couponDetail?.coupon_type }
                } else {
                    // apply coupon according to type 1
                    if (couponDetail?.coupon_type_value == 1) {
                        // queries to apply coupon on services
                        const filteredServices = filteredClientData?.appointmnet_service?.filter(itm => itm?.status != "Void")
                        const servicesWithUpdatedPrices = filteredServices?.map((srv) => {
                            let data = { ...srv }
                            delete data?.discount_percentage
                            if (srv?.avaliable_service_group && srv?.avaliable_service_group?.length > 0) {
                                const isServiceExcluded = couponDetail?.excluded_services?.includes(srv?.service);
                                const filteredServiceGroupDiscount = couponDetail?.servicegroup_discount?.find((itm) =>
                                    srv?.avaliable_service_group[0]?.id == itm?.service_group[0]?.service_group && !isServiceExcluded)?.service_group[0]?.service_group_discount
                                let updatedDiscountPrice = srv?.discount ? srv?.discount : srv?.price
                                if (filteredServiceGroupDiscount) {
                                    updatedDiscountPrice = (+updatedDiscountPrice * +filteredServiceGroupDiscount / 100)
                                    let discount_price = srv?.discount ? srv?.discount : srv?.price - updatedDiscountPrice

                                    data = { ...data, discount: updatedDiscountPrice, discount_price: discount_price, discount_percentage: filteredServiceGroupDiscount }
                                }
                            }
                            return data
                        })
                        finaldata = [...servicesWithUpdatedPrices]
                        finaldata = finaldata?.filter(itm => itm?.discount !== undefined && itm?.discount)
                        finaldata = finaldata?.map((itm) => {
                            const data = { ...itm }
                            delete data?.avaliable_service_group
                            return data
                        })
                        const totalDiscountPrice = finaldata?.reduce((total, service) => total + service?.discount, 0)
                        dataToUpdate = { client: selectedClient, couponIds: finaldata, discount: totalDiscountPrice, is_coupon_redeemed: true, coupon: couponDetail?.id, coupon_name: couponDetail?.name, coupon_type: couponDetail?.coupon_type, coupon_type_value: couponDetail?.coupon_type_value }
                        filteredAppointmentServices = appointmentData?.filteredClientsAppointments?.map(appointment => {
                            if (selectedClient == appointment?.client) {
                                const filteredServicesPrices = appointment?.appointmnet_service?.filter(itm => !itm?.is_coupon_redeemed)?.map(srv => {
                                    const appointmentService = finaldata?.find(itm => srv?.service === itm?.service);
                                    if (appointmentService) {
                                        return {
                                            ...srv,
                                            discount_price: appointmentService?.discount_price,
                                            discount_percentage: appointmentService?.discount_percentage
                                        }
                                    }
                                    return srv;
                                })
                                return { ...appointment, appointmnet_service: filteredServicesPrices }
                            } else {
                                return appointment
                            }
                        })

                        updatedclientData = filteredAppointmentServices
                    } else if (couponDetail?.coupon_type_value == 2) {
                        let addToCartAllow = false
                        let filteredItm = {}
                        // apply coupon according to type 2
                        if (couponDetail?.buy_one_type == 'Service') {
                            // queries to apply coupon on services
                            const filteredServices = filteredClientData?.appointmnet_service?.filter(itm => itm?.status != "Void")
                            const servicesWithUpdatedPrices = filteredServices?.map((srv) => {
                                let data = { ...srv }
                                const isServiceIncluded = couponDetail?.buy_one_get_one_service?.includes(srv?.service);
                                if (isServiceIncluded) {
                                    delete data?.discount_percentage
                                    delete data?.discount_price
                                    addToCartAllow = true
                                    let updatedDiscountPrice = srv?.discount ? srv?.discount : srv?.price
                                    filteredItm = { ...srv, is_coupon_redeemed: true, discount_price: 0, discount_percentage: 100 }
                                    data = { ...data, discount: updatedDiscountPrice, discount_percentage: 100 }
                                }
                                return data
                            })
                            finaldata = [...servicesWithUpdatedPrices]
                        }
                        finaldata = finaldata?.filter(itm => itm?.discount !== undefined && itm?.discount)
                        finaldata = finaldata?.map((itm) => {
                            const data = { ...itm }
                            delete data?.avaliable_service_group
                            return data
                        })

                        const totalDiscountPrice = finaldata?.reduce((total, service) => total + service?.discount, 0)
                        dataToUpdate = { client: selectedClient, couponIds: finaldata, discount: totalDiscountPrice, is_coupon_redeemed: true, coupon: couponDetail?.id, coupon_name: couponDetail?.name, coupon_type: couponDetail?.coupon_type, coupon_type_value: couponDetail?.coupon_type_value }
                        if (addToCartAllow) {
                            const filteredClients = appointmentData?.filteredClientsAppointments?.filter(itm => itm?.client != selectedClient) || []
                            const updatedData = { ...filteredClientData, appointmnet_service: [...filteredClientData?.appointmnet_service, filteredItm] }
                            updatedclientData = [...filteredClients, updatedData]
                        }
                    } else {
                        if (filteredClientData?.total_price >= couponDetail?.amount_spent) {
                            const totalDiscountPrice = +filteredClientData?.total_price * +couponDetail?.discounted_percentage / 100
                            dataToUpdate = { client: selectedClient, discount: totalDiscountPrice, is_coupon_redeemed: true, coupon: couponDetail?.id, coupon_name: couponDetail?.name, coupon_type: couponDetail?.coupon_type, coupon_type_value: couponDetail?.coupon_type_value, couponSpendAmount: couponDetail?.amount_spent, couponDiscountPercentage: couponDetail?.discounted_percentage }
                        }
                    }
                }

                setAppointmentData((prev) => {
                    // remaining membership data
                    const filteredMembershipData = appointmentData?.membership_data?.filter(itm => itm?.client != selectedClient)
                    // remaining voucher data
                    const filteredVoucherData = appointmentData?.voucher_data?.filter(itm => itm?.client != selectedClient)
                    // remaining loyalty data
                    const filteredLoyaltyData = appointmentData?.loyalty_data?.filter(itm => itm?.client != selectedClient)
                    // coupon scenarios
                    const filteredClientData = filteredClientsAppointments?.find(itm => itm?.client == selectedClient)
                    const exist = appointmentData?.coupon_data?.find(itm => itm?.client == filteredClientData?.client)
                    let prevData = []
                    let couponData = []
                    if (exist != {}) {
                        prevData = appointmentData?.coupon_data?.filter(itm => itm?.client != filteredClientData?.client)
                    } else {
                        prevData = appointmentData?.coupon_data || []
                    }
                    if (prevData?.length > 0) {
                        couponData = [...prevData, dataToUpdate]
                    } else {
                        couponData = [dataToUpdate]
                    }
                    return {
                        ...prev,
                        filteredClientsAppointments: updatedclientData?.length > 0 ? updatedclientData : appointmentData?.filteredClientsAppointments,
                        voucher_data: filteredVoucherData,
                        coupon_data: couponData,
                        gift_card_data: [],
                        loyalty_data: filteredLoyaltyData,
                        membership_data: filteredMembershipData
                    }
                })
                if (updatedclientData?.length > 0) {
                    setAppointmentService(updatedclientData)
                }
            },
            (err) => {
                toast.error(err.response.data.response.message)
                setGetCouponLoader(false)
            }
        ))
    }

    // get Gift Cards WRT client
    const getCheckoutGiftCard = (code) => {
        setGetGiftCardLoader(true)
        setGiftCardLoader(true)
        let payload = {
            location_id: selected_location,
            client_id: code ? "" : selectedClient,
            code: code ? code : "",
        }

        dispatch(getClientCheckoutGiftCard(
            payload,
            (result) => {
                setGiftCardLoader(false)
                setGetGiftCardLoader(false)
                if (code) {
                    setGiftCardLoader(false)
                    if (result?.status == 404) {
                        toast.error(result?.message)
                    } else {
                        setGiftCardPopup(false)
                        setGiftCardDetailPopup(true)
                        toast.success(result?.message)
                        setGiftCardDetail(result?.client_gift_cards[0])
                    }
                } else {
                    setCheckoutGiftCard(result?.client_gift_cards)
                }
            },
            (err) => {
                setGetGiftCardLoader(false)
                setGiftCardLoader(false)
            }
        ))
    }

    // check gift card code
    const checkGiftCardCode = () => {
        setGiftCardLoader(true)
        setSelectedPaymentMethods([])
        // api to get gift card data
        getCheckoutGiftCard(giftCardCode)
    }

    // handle selected tip delete
    const handle_tip_delete = (data) => {
        let is_tip = tip_for_emply?.filter(itm => itm.employee !== data.emp_id)
        let is_employee = employee_tips?.filter(itm => itm.emp_id !== data.emp_id)
        const sum = is_tip.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);

        setTip_sum(sum)
        setEmployeeTipSum(sum)
        setTip_for_emply([...is_tip])
        setEmployee_tips([...is_employee])
        setEmployeeTips([...is_employee])

        // Remove the employee's tip from currentEmployeeTip
        const employeeId = data.emp_id;
        const updatedEmployeeTip = { ...currentEmployeeTip };
        delete updatedEmployeeTip[employeeId];
        setCurrentEmployeeTip(updatedEmployeeTip);
    }

    // handle tip change
    const handleTipChange = (emp_id, emp_name, newTip) => {
        let prev_tips = [...tip_for_emply]
        let prev_employees = [...employee_tips]
        let is_tip = prev_tips?.find(itm => itm?.employee === emp_id)
        let is_employee = prev_employees?.find(itm => itm?.emp_id === emp_id)

        // if (newTip != 0) {
        if (is_employee) {
            // is_employee.tip = newTip
            prev_employees = prev_employees?.map(itm => {
                if (itm?.emp_id === emp_id) {
                    return { ...itm, tip: newTip };
                } else {
                    return itm
                }
            });
        } else {
            prev_employees?.push({ emp_id: emp_id, employee: emp_name, tip: newTip })
        }

        if (is_tip) {
            is_tip.tip = newTip
        } else {
            prev_tips?.push({ employee: emp_id, tip: newTip })
        }
        // }

        const updatedPreviousTips = prev_tips?.filter(itm => itm?.tip != 0)
        const updatedPreviousEmployees = prev_employees?.filter(itm => itm?.tip != 0)

        setTip_for_emply(updatedPreviousTips)
        setEmployee_tips(updatedPreviousEmployees)

        const sum = updatedPreviousTips.reduce((accumulator, object) => {
            return accumulator + object?.tip;
        }, 0);

        setTip_sum(sum)
    }

    // Split Payment
    const handlePaymentMethodChange = (e, type) => {
        const { name, value } = e?.target
        let totalCartPrice = (+t_price + +taxApplied)?.toFixed(2)
        if (appointmentData?.gift_card_data?.length > 0) {
            totalCartPrice = totalCartPrice - (+appointmentData?.gift_card_data[0]?.partial_price)?.toFixed(2)
        }
        if (name === 'payment_method') {
            const updatedPaymentMethods = () => {
                if (value) {
                    setPayBtnDisabled(false)
                    if (selectedPaymentMethods?.some(itm => itm?.payment_method === value)) {
                        setPaymentError({ id: '', message: '' })
                        return selectedPaymentMethods?.filter(itm => itm?.payment_method !== value)
                    } else {
                        if (payment_price > 0) {
                            setPaymentError({ id: '', message: '' })
                            setPayBtnDisabled(false)
                            return ([...selectedPaymentMethods || [], {
                                payment_method: value, amount: +payment_price?.toFixed(2)
                            }])
                        } else {
                            setPayBtnDisabled(false)
                            setPaymentError((prev) => ({
                                ...prev,
                                id: value,
                                message: `payment already paid`
                            }));
                            return selectedPaymentMethods
                        }
                    }
                } else {
                    setPaymentError({ id: '', message: '' })
                    return selectedPaymentMethods
                }
            }
            let updatedValue = updatedPaymentMethods()
            // const totalAddAmount = updatedValue?.reduce((sum, item) => sum + item.amount, 0);
            // if (totalCartPrice >= totalAddAmount) {
            //     setRemainingPrice((totalCartPrice - totalAddAmount)?.toFixed(2))
            // } else {
            //     setRemainingPrice(0)
            // }
            setSelectedPaymentMethods(updatedValue);
        }
        if (name === 'amount') {
            if (selectedPaymentMethods?.find(itm => itm?.payment_method == type)) {
                const updatedPaymentMethods = selectedPaymentMethods.map(itm => {
                    if (itm?.payment_method === type) {
                        const updatedArray = selectedPaymentMethods?.map((itm) => {
                            if (type === itm?.payment_method) {
                                return ({
                                    ...itm,
                                    amount: +value
                                })
                            } else {
                                return itm
                            }
                        });
                        const totalRemaining = updatedArray?.reduce((sum, item) => sum + item.amount, 0);
                        let remaing = +(totalCartPrice - totalRemaining)?.toFixed(2)

                        if (itm?.payment_method === 'Cash' && selectedPaymentMethods?.length <= 1) {
                            return { ...itm, amount: +value };
                        } else {
                            if (+value <= remaing) {
                                setPayBtnDisabled(false)
                                setPaymentError((prev) => ({ ...prev, id: itm?.payment_method, message: `` }))
                                return { ...itm, amount: +value };
                            } else {
                                if (totalRemaining <= totalCartPrice) {
                                    setPayBtnDisabled(false)
                                    setPaymentError((prev) => ({ ...prev, id: itm?.payment_method, message: `` }))
                                    return { ...itm, amount: +value };
                                } else {
                                    setPayBtnDisabled(true)
                                    setPaymentError((prev) => ({
                                        ...prev,
                                        id: itm?.payment_method,
                                        message: `Payment amount can't be more than the its actual price`
                                    }));
                                    return { ...itm, amount: +value };
                                }
                            }
                        }
                    } else {
                        return itm;
                    }
                });
                // const totalAddAmount = updatedPaymentMethods?.reduce((sum, item) => sum + item.amount, 0);
                // if (totalCartPrice >= totalAddAmount) {
                //     setRemainingPrice((totalCartPrice - totalAddAmount)?.toFixed(2))
                // } else {
                //     setRemainingPrice(0)
                // }
                setSelectedPaymentMethods(updatedPaymentMethods);
            }
        }
    };

    // remove previous discount
    const removePreviousDiscount = () => {
        return appointmentData?.filteredClientsAppointments?.map(appointment => {
            if (selectedClient === appointment?.client) {
                let updatedData = { ...appointment };

                // List of properties to delete
                const keysToDelete = [
                    "selected_promotion_id",
                    "selected_promotion_type",
                    "is_promotion_availed",
                    "is_promotion",
                    "promotion_discount_sum",
                ];

                // Delete keys if they exist
                keysToDelete.forEach((key) => {
                    if (key in updatedData) delete updatedData[key];
                });

                const filteredServicesPrices = appointment?.appointmnet_service?.map((srv) => {
                    let updatedSrv = { ...srv };

                    ["discount_percentage", "discount_price", "is_promotion"].forEach((key) => {
                        if (key in updatedSrv) delete updatedSrv[key];
                    });

                    return updatedSrv;
                });

                return { ...updatedData, appointmnet_service: filteredServicesPrices };
            } else {
                return appointment;
            }
        })
    }

    // redeem voucher function 
    const redeemVoucherFunction = (vchr) => {
        // redeem voucher functionality
        setSelectedPaymentMethods([])
        setVoucherPopup(!voucherPopup)
        // first remove all previous discount on selected client services
        const updatedClientAppointmentServices = removePreviousDiscount()
        // add discount with existing data in services
        const filteredAppointmentServices = updatedClientAppointmentServices?.map(appointment => {
            if (selectedClient == appointment?.client) {
                const filteredServicesPrices = appointment?.appointmnet_service?.map(srv => {
                    let discount_price = +srv?.price - (srv?.price * vchr?.discount_percentage) / 100
                    let discount_percentage = vchr?.discount_percentage
                    return {
                        ...srv,
                        discount_price: discount_price,
                        is_voucher_redeemed: true,
                        discount_percentage: discount_percentage
                    }
                })
                return { ...appointment, appointmnet_service: filteredServicesPrices }
            } else {
                return appointment
            }
        })
        setAppointmentData((prev) => {
            // remaining membership data
            const filteredMembershipData = appointmentData?.membership_data?.filter(itm => itm?.client != selectedClient)
            // remaining coupon data
            const filteredCouponData = appointmentData?.coupon_data?.filter(itm => itm?.client != selectedClient)
            // remaining loyalty data
            const filteredLoyaltyData = appointmentData?.loyalty_data?.filter(itm => itm?.client != selectedClient)
            // voucher scenarios
            const filteredClientData = filteredClientsAppointments?.find(itm => itm?.client == selectedClient)
            const exist = appointmentData?.voucher_data?.find(itm => itm?.client == filteredClientData?.client)
            let prevData = []
            let voucherData = []
            if (exist != {}) {
                prevData = appointmentData?.voucher_data?.filter(itm => itm?.client != filteredClientData?.client)
            } else {
                prevData = appointmentData?.voucher_data || []
            }
            // calculate seperate discount
            let sumOfServicesPrices = 0
            if (filteredClientData?.appointmnet_service?.length > 0) {
                const filteredServicesPrices = filteredClientData?.appointmnet_service?.map(srv => (srv?.price * vchr?.discount_percentage) / 100)
                sumOfServicesPrices = filteredServicesPrices?.reduce((total, price) => total + price, 0);
            }
            voucherData = [...prevData, { client: selectedClient, voucher_type: vchr?.voucher?.voucher_type, is_voucher_redeemed: true, redeemed_voucher_id: vchr?.id, discount_percentage: vchr?.discount_percentage, discount: sumOfServicesPrices }]

            return {
                ...prev,
                filteredClientsAppointments: filteredAppointmentServices,
                voucher_data: voucherData,
                coupon_data: filteredCouponData,
                gift_card_data: [],
                loyalty_data: filteredLoyaltyData,
                membership_data: filteredMembershipData
            }
        })
    }

    // redeem membership function
    const redeemMembershipFunction = (membership) => {
        // first remove all previous discount on selected client services
        const updatedClientAppointmentServices = removePreviousDiscount()

        setSelectedPaymentMethods([])
        setAppointmentData((prev) => {
            // remaining voucher data
            const filteredVoucherData = appointmentData?.voucher_data?.filter(itm => itm?.client != selectedClient)
            // remaining coupon data
            const filteredCouponData = appointmentData?.coupon_data?.filter(itm => itm?.client != selectedClient)
            // remaining loyalty data
            const filteredLoyaltyData = appointmentData?.loyalty_data?.filter(itm => itm?.client != selectedClient)
            // membership scenarios
            const filteredClientData = updatedClientAppointmentServices?.find(itm => itm?.client == selectedClient)
            const exist = appointmentData?.membership_data?.find(itm => itm?.client == filteredClientData?.client)
            let prevData = []
            let membershipData = []
            if (exist != {}) {
                prevData = appointmentData?.membership_data?.filter(itm => itm?.client != filteredClientData?.client)
            } else {
                prevData = appointmentData?.membership_data || []
            }

            let filteredServices = membership?.services?.filter(srvce => filteredClientData?.appointmnet_service?.find(itm => srvce?.service == itm?.service))

            filteredServices = filteredServices?.map(srvce => {
                const appointmentService = filteredClientData?.appointmnet_service?.find(itm => srvce?.service === itm?.service);
                if (appointmentService) {
                    return { ...srvce, price: appointmentService?.price }; // Include srvce with price in filteredServices
                }
                return null; // Exclude srvce from filteredServices
            });

            // redeem membership functionality
            const filteredAppointmentServices = updatedClientAppointmentServices?.map(appointment => {
                if (selectedClient == appointment?.client) {
                    const filteredServicesPrices = appointment?.appointmnet_service?.map(srv => {
                        if (filteredServices?.length > 0) {
                            const appointmentService = filteredServices?.find(itm => srv?.service === itm?.service);
                            if (appointmentService) {
                                let discount_price
                                let discount_percentage
                                if (membership?.discount_type === 'Limited') {
                                    discount_price = (srv?.price * appointmentService?.percentage) / 100
                                    discount_percentage = appointmentService?.percentage
                                }
                                if (membership?.discount_type === 'Free') {
                                    discount_price = srv?.price
                                    discount_percentage = 100
                                }
                                return {
                                    ...srv,
                                    discount_price: discount_price,
                                    discount_percentage: discount_percentage
                                }
                            }
                        }
                        return srv;
                    })
                    return { ...appointment, appointmnet_service: filteredServicesPrices }
                } else {
                    return appointment
                }
            })

            // calculate seperate discount
            let membershipTotalPrice = 0
            let updatedClientServices = filteredAppointmentServices?.find(itm => itm?.client == selectedClient)

            if (membership?.discount_type === 'Limited') {
                // const filteredLimitedServicesPrices = filteredServices?.map(srv => (srv?.price * srv?.percentage) / 100)
                let sumOfLimitedServicesPrices = updatedClientServices?.appointmnet_service?.reduce((total, service) => {
                    // Check if the service object has the 'discount_price' property
                    if (service && typeof service.discount_price === 'number') {
                        // If it does, add its value to the total
                        return total + service.discount_price;
                    } else {
                        // If not, just return the total without any change
                        return total;
                    }
                }, 0);
                if (sumOfLimitedServicesPrices) {
                    membershipTotalPrice = sumOfLimitedServicesPrices
                }

            }

            if (membership?.discount_type === 'Free') {
                // const sumOfFreeServicesPrices = updatedClientServices?.appointmnet_service?.reduce((total, service) => total + service?.price, 0);
                let sumOfFreeServicesPrices = updatedClientServices?.appointmnet_service?.reduce((total, service) => {
                    // Check if the service object has the 'discount_price' property
                    if (membership?.services?.find(itm => itm?.service == service?.service)) {
                        // If it does, add its value to the total
                        return total + service?.price;
                    } else {
                        // If not, just return the total without any change
                        return total;
                    }
                }, 0);
                if (sumOfFreeServicesPrices) {
                    membershipTotalPrice = sumOfFreeServicesPrices
                }
            }

            if (prevData?.length > 0) {
                membershipData = [...prevData, { client: selectedClient, membership_type: membership?.discount_type, is_membership_redeemed: true, redeemed_membership_id: membership?.id, membership_service: filteredServices, discount: membershipTotalPrice }]
            } else {
                membershipData = [{ client: selectedClient, membership_type: membership?.discount_type, is_membership_redeemed: true, redeemed_membership_id: membership?.id, membership_service: filteredServices, discount: membershipTotalPrice }]
            }
            membershipData = membershipData?.filter(itm => itm?.membership_service?.length > 0)
            if (filteredServices?.length > 0) {
                toast.success(selectedLanguage?.name == "English" ? "Membership redeem successfully" : translation[1653]?.value ? translation[1653]?.value : "Membership redeem successfully", { toastId: "toast" })
            } else {
                toast.error(selectedLanguage?.name == "English" ? "Membership cannot be redeemed" : translation[1653]?.value ? translation[1653]?.value : "Membership cannot be redeemed", { toastId: "toast" })
            }

            return {
                ...prev,
                filteredClientsAppointments: filteredAppointmentServices,
                voucher_data: filteredVoucherData,
                coupon_data: filteredCouponData,
                gift_card_data: [],
                loyalty_data: filteredLoyaltyData,
                membership_data: membershipData
            }
        })
    }

    // set data of gift card
    const setGiftCardData = (data) => {
        setGiftCardDetail(data)
        let totalCartValue = +t_price + +taxApplied
        setGiftCard_amount((+totalCartValue)?.toFixed(2))
        setAppointmentData({ ...appointmentData, other_method: '', gift_card_data: [] })
        setGiftCardPopup(false);
        setGiftCardDetailPopup(true)
    }

    // redeem gift card function
    const redeemGiftCardFunction = () => {
        removePreviousDiscount()
        setAppointmentData({
            ...appointmentData,
            gift_card_data: [{
                purchased_gift_card_id: giftCardDetail?.id,
                partial_price: +giftCard_amount,
            }]
        })
        setGiftCardPopup(false)
        setGiftCardDetailPopup(false)
    }

    // redeem LoyaltyPoints Availability Check Function
    const redeemLoyaltyPointsAvailabilityCheckFunction = () => {
        const filteredClientDataPrice = filteredClientsAppointments?.find(itm => itm?.client == selectedClient)?.total_price
        if (+filteredClientDataPrice <= loyalityPoints?.total_amount) {
            setConfirmRedeem(true)
            const points_for_every_amount = (loyalityPoints?.for_every_points / loyalityPoints?.customer_will_get_amount)

            const points_to_redeem = points_for_every_amount * +filteredClientDataPrice

            const amount_for_points =
                +filteredClientDataPrice < loyalityPoints?.total_amount
                    ? (points_for_every_amount * +filteredClientDataPrice) / points_for_every_amount
                    : loyalityPoints?.total_available_points / points_for_every_amount < loyalityPoints?.total_amount
                        ? loyalityPoints?.total_available_points / points_for_every_amount
                        : loyalityPoints?.total_available_points / points_for_every_amount

            setLoyalityPointsDetail({ points_to_redeem: (+points_to_redeem)?.toFixed(2), amount_for_points: (+amount_for_points)?.toFixed(2), id: loyalityPoints?.id })
        } else {
            toast.error(selectedLanguage?.name == "English" ? "You don't have enough loyalty points to complete this sale" : translation[212]?.value ? translation[212]?.value : "You don't have enough loyalty points to complete this sale", { toastId: "toast" })
        }
    }

    // redeem loyalty points function
    const redeemLoyaltyPointsFunction = () => {
        removePreviousDiscount()
        setSelectedPaymentMethods([])
        toast.success(selectedLanguage?.name == "English" ? "Loyality points redeem successfully" : translation[1652]?.value ? translation[1652]?.value : "Loyality points redeem successfully", { toastId: "toast" })
        // remaining voucher data
        const filteredVoucherData = appointmentData?.voucher_data?.filter(itm => itm?.client != selectedClient)
        // remaining coupon data
        const filteredCouponData = appointmentData?.coupon_data?.filter(itm => itm?.client != selectedClient)
        // remaining membership data
        const filteredMembershipData = appointmentData?.membership_data?.filter(itm => itm?.client != selectedClient)
        // loyalty points scenarios
        const filteredClientData = filteredClientsAppointments?.find(itm => itm?.client == selectedClient)
        const exist = appointmentData?.loyalty_data?.find(itm => itm?.client == filteredClientData?.client)

        let prevData = []
        let loyaltyData = []
        if (exist != {}) {
            prevData = appointmentData?.loyalty_data?.filter(itm => itm?.client != filteredClientData?.client)
        } else {
            prevData = appointmentData?.loyalty_data || []
        }
        if (prevData?.length > 0) {
            loyaltyData = [...prevData, { client: selectedClient, is_redeemed: true, client_loyalty_point: loyalityPointsDetail?.id, redeemed_points: loyalityPointsDetail?.points_to_redeem, redeemed_amount: loyalityPointsDetail?.amount_for_points }]

        } else {
            loyaltyData = [{ is_redeemed: true, client: selectedClient, client_loyalty_point: loyalityPointsDetail?.id, redeemed_points: loyalityPointsDetail?.points_to_redeem, redeemed_amount: loyalityPointsDetail?.amount_for_points }]

        }

        setAppointmentData({
            ...appointmentData,
            voucher_data: filteredVoucherData?.length > 0 ? filteredVoucherData : [],
            coupon_data: filteredCouponData?.length > 0 ? filteredCouponData : [],
            membership_data: filteredMembershipData?.length > 0 ? filteredMembershipData : [],
            loyalty_data: loyaltyData
        })
        setConfirmRedeem(false)

        let remaining_available_points;
        let single_amount_for_every_point = loyalityPoints?.customer_will_get_amount / loyalityPoints?.for_every_points
        let remaining_value;
        remaining_available_points = loyalityPoints?.total_available_points - loyalityPointsDetail?.points_to_redeem
        remaining_value = single_amount_for_every_point * remaining_available_points
        setLoyalityPoints({
            ...loyalityPoints,
            total_available_points: remaining_available_points,
            total_amount: remaining_value
        })
    }

    // handle submit function
    const handle_submit = (data) => {
        const change = +(+t_price + +taxApplied?.toFixed(2) + (donation ? +donation : 0) - (totalAmountPaid ? +totalAmountPaid : 0)?.toFixed(2))?.toFixed(2)
        const applied_tax = data?.taxes_applied_group?.length > 0 ? data?.taxes_applied_group : []
        // promotion data
        let applied_promotions_records = appointmentData?.filteredClientsAppointments?.map(appointment => {
            if (appointment?.selected_promotion_id) {
                return { promotion: appointment?.selected_promotion_id, promotion_type: appointment?.selected_promotion_type, appointment: appointment?.id, client: appointment?.client, price: appointment?.promotion_discount_sum };
            }
        });
        applied_promotions_records = applied_promotions_records?.length > 0 ? applied_promotions_records?.filter(record => record !== undefined) : []

        // membership data
        const appliedMembershipsData = appointmentData?.membership_data?.length > 0 ?
            appointmentData?.membership_data?.map((itm) => {
                return {
                    membership: itm?.redeemed_membership_id,
                    client: itm?.client,
                    price: +itm?.discount?.toFixed(2)
                }
            }) : []

        // voucher data
        const appliedVouchersData = appointmentData?.voucher_data?.length > 0 ?
            appointmentData?.voucher_data?.map((itm) => {
                return {
                    voucher: itm?.redeemed_voucher_id,
                    client: itm?.client,
                    price: +itm?.discount?.toFixed(2)
                }
            }) : []

        // loyalty data
        const appliedLoyaltyData = appointmentData?.loyalty_data?.length > 0 ?
            appointmentData?.loyalty_data?.map((itm) => {
                return {
                    client_loyalty_point: itm?.client_loyalty_point,
                    redeemed_amount: itm?.redeemed_amount,
                    client: itm?.client,
                    redeemed_points: +itm?.redeemed_points
                }
            }) : []

        // gift card data
        const appliedGiftCardData = appointmentData?.gift_card_data?.length > 0 ?
            appointmentData?.gift_card_data?.map((itm) => {
                return {
                    ...itm,
                    partial_price: +itm?.partial_price?.toFixed(2)
                }
            }) : []

        // coupon data
        let appliedCouponData = appointmentData?.coupon_data?.length > 0 ?
            appointmentData?.coupon_data?.map((itm) => {
                return {
                    coupon_type: itm?.coupon_type,
                    refund_coupon: itm?.coupon_type == 'refund' ? itm?.coupon : null,
                    cash_back_coupons: itm?.coupon_type == 'Cashback' ? itm?.coupon : null,
                    coupon_name: itm?.coupon_name,
                    coupon: (itm?.coupon_type == 'refund' || itm?.coupon_type == 'Cashback') ? null : itm?.coupon,
                    client: itm?.client,
                    coupon_discounted_price: (+itm?.discount)?.toFixed(2)
                }
            }) : []
        // Loop through each object and delete 'coupon' key if its value is null
        appliedCouponData?.forEach(obj => {
            if (obj?.coupon === null) {
                delete obj.coupon;
            }
            if (obj?.refund_coupon === null) {
                delete obj.refund_coupon;
            }
            if (obj?.cash_back_coupons === null) {
                delete obj.cash_back_coupons;
            }
        });

        // applied donation
        const applied_donation = donation
            ? [{
                amount: donation,
            }]
            : []

        // tips sum
        const tips_sum = employeeTips?.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);

        // appointment services data
        // Initialize an empty array to store merged services
        const updatedAppointmentServices = [];
        // Iterate through each appointment
        appointmentData?.filteredClientsAppointments?.forEach(appointment => {
            // Iterate through appointmentServices and store required variables
            appointment?.appointmnet_service?.forEach(srv => {
                // Create a new object for each service and add required variables
                const mergedService = {
                    client: appointment?.client,
                    appointment: appointment?.id,
                    service: srv?.service,
                    employee: srv?.employee,
                    price: srv?.discount_price != 0 ? srv?.discount_price ? srv?.discount_price : srv?.price : 0,
                    service_start_time: srv?.service_start_time,
                    service_end_time: srv?.service_end_time,
                    group: app_group_id,
                    discount_percentage: srv?.discount_percentage
                }
                // Push the merged service into the updatedAppointmentServices array
                updatedAppointmentServices?.push(mergedService);
            });
        });

        let format_data = {
            checkout_type: "Group Appointment",
            location: selected_location,
            tax_records: applied_tax,
            payment_methods_records: selectedPaymentMethods,
            total_tip: tips_sum,
            tip_records: employeeTips?.map(tip_item => ({ tip: tip_item?.tip, employee: tip_item.emp_id })),
            change: change < 0 ? Math.abs(change)?.toFixed(2) : 0.00,
            total_price: +cartTotal,
            total_tax: data?.taxes_applied_single_value ? +data?.taxes_applied_single_value?.toFixed(2) : 0,
            sub_total: +(+t_price - +tips_sum)?.toFixed(2),
            products_records: [],
            services_records: [],
            membership_records: [],
            vouchers_records: [],
            gift_cards_records: [],
            installment_memberships: [],
            applied_promotions_records: applied_promotions_records,
            applied_loyalty_points_records: appliedLoyaltyData,
            applied_gift_cards_records: appliedGiftCardData,
            applied_vouchers_records: appliedVouchersData,
            applied_memberships_records: appliedMembershipsData,
            applied_coupons_records: appliedCouponData,
            appointment_services: updatedAppointmentServices,
            group: app_group_id,
            client: appointmentData?.filteredClientsAppointments[0]?.client,
            client_type: appointmentData?.filteredClientsAppointments[0]?.client_type == 'IN HOUSE' ? "In_Saloon" : "Walk_in",
            status: 'paid',
            donations_records: applied_donation
        }
        const success = (data) => {
            storeDataInLocalStorage('checkoutId', data?.id)
            toast.success('Payment successfully completed', { toastId: "toast" })
            navigate('/dashboard/sales/sale-history')
            dispatch({
                type: CLIENT.SET_SPECIFIC_CLIENTS_FOR_CHECKOUT,
                payload: {}
            });
            setCheckoutLoader(false)
        }
        const fail = () => {
            toast.error(apiErrorMessage, { toastId: "toast" })
            setCheckoutLoader(false)
        }
        // console.log(format_data, "data")
        setCheckoutLoader(true)
        dispatch(CreateNewCheckout(format_data, success, fail))
    }

    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                <div className='flex flex-col gap-4'>
                    <div className="flex items-center gap-3">
                        <Svgs.Back link={`/dashboard/calendar/appointment-group-detail/${params?.appointment_service_id}/?parent_id=${app_group_id}`} />
                        <h2 className="text-primary font-bold lg:text-3xl text-xl">
                            {selectedLanguage?.name == "English" ? "Checkout" : translation[182]?.value ? translation[182]?.value : "Checkout"}
                        </h2>
                    </div>

                    <div className='grid lg:grid-cols-5 mt-[3rem] gap-[3rem]'>
                        <div className='flex flex-col gap-5 lg:col-span-3'>
                            <div className="border-solid border rounded-xl bg-white">
                                {filteredClientsAppointments?.length > 0 ?
                                    filteredClientsAppointments?.map((client, index) => {
                                        return (
                                            <GroupSelectedClients
                                                data={client}
                                                index={index}
                                                selectedClient={selectedClient}
                                                onClick={() => {
                                                    // setLoader(true)
                                                    setSelectedClient(client?.client)
                                                    setAppointmentData({
                                                        ...appointmentData,
                                                        other_method: ""
                                                    })
                                                    // setMembershipPopup(true)
                                                    // setVoucherPopup(true)
                                                    // setLoyalityPointsPopup(true)
                                                    // setGiftCardPopup(true)
                                                    // setCouponPopup(true)
                                                }}

                                            />
                                        )
                                    })
                                    :
                                    [0, 1]?.map(() => {
                                        return (
                                            <GroupSelectedClients animate={true} />
                                        )
                                    })

                                }
                            </div>
                            <h2 className="text-primary font-bold text-xl">
                                {selectedLanguage?.name == "English" ? "Add Tip" : translation[187]?.value ? translation[187]?.value : "Add Tip"}
                            </h2>
                            <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-4 cursor-pointer text-sm'>
                                <PaymentCheckboxCard
                                    checked={employeeTips?.length > 0}
                                    onClick={() => {
                                        setTipToStaff(true)
                                        setTip_for_emply([])
                                        setTip_sum(0)
                                    }}
                                    padding={'gap-4'}
                                    inputClass={'!opacity-100 !font-medium'}
                                    currency={cur_currency}
                                    inputValue={totalTips && totalTips?.toFixed(2)}
                                    inputField
                                    inputDisabled
                                    title={selectedLanguage?.name == "English" ? "Add Tip" : translation[187]?.value ? translation[187]?.value : "Add Tip"}
                                />
                            </div>
                            <h2 className="text-primary font-bold text-xl">
                                Add Donation
                            </h2>
                            <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-4 cursor-pointer text-sm'>
                                {/* DONATION */}
                                <PaymentCheckboxCard
                                    checked={donation}
                                    padding={'gap-4'}
                                    inputClass={'!opacity-100 !font-medium'}
                                    currency={cur_currency}
                                    inputValue={donation}
                                    inputField
                                    firstLetterGreaterThanZero
                                    title={"Add Donation"}
                                    maxCharactersInput={5}
                                    customDisable={false}
                                    id={'donation'}
                                    isCustomDisable
                                    onInputChange={(e) => {
                                        setDonation(e?.target?.value)
                                    }}
                                />
                            </div>
                            <h2 className="text-primary font-bold text-xl">
                                {selectedLanguage?.name == "English" ? "Payment Methods" : translation[190]?.value ? translation[190]?.value : "Payment Methods"} <span className="text-red-600">*</span></h2>
                            <div className='grid lg:grid-cols-1 gap-3'>
                                {reduxPaymentMethodList && reduxPaymentMethodList?.length > 0 ? (
                                    <div className='grid md:grid-cols-2 gap-4'>
                                        {reduxPaymentMethodList?.filter(method => method.is_active)?.map((item, index) => {
                                            return (
                                                <>
                                                    <PaymentCheckboxCard
                                                        errorClass={"!static !pt-1"}
                                                        key={index}
                                                        checked={selectedPaymentMethods?.find(itm => itm?.payment_method === item.method_type)}
                                                        onChange={handlePaymentMethodChange}
                                                        inputField
                                                        onInputChange={(e) => {
                                                            handlePaymentMethodChange(e, item?.method_type)
                                                        }}
                                                        currency={cur_currency}
                                                        restrictedAmount={item?.method_type != 'Cash' ? payment_price : false}
                                                        inputValue={selectedPaymentMethods?.find(itm => itm?.payment_method === item.method_type)?.amount}
                                                        error={(paymentError?.id === item.method_type) && paymentError?.message}
                                                        totalPaymentMethods={payment_price}
                                                        title={
                                                            <div className='flex items-center gap-2'>
                                                                <p className='font-semibold text-sm'>{item.method_type}</p> {" "}
                                                            </div>
                                                        }

                                                        id={item.method_type}
                                                    />
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                )
                                    :
                                    <div className='grid md:grid-cols-2 gap-4'>
                                        {[0, 1, 2]?.map(itm => {
                                            return (
                                                <CheckBoxCardShimmer />
                                            )
                                        })}
                                    </div>
                                }
                            </div>

                            {selectedClient != '' &&
                                <>
                                    <hr className='my-2' />
                                    <div className='grid lg:grid-cols-1 gap-3'>
                                        <h2 className="text-primary font-bold text-xl">
                                            {selectedLanguage?.name == "English" ? "Other Methods " : translation[192]?.value ? translation[192]?.value : "Other Methods "}
                                        </h2>
                                        <div className='grid md:grid-cols-2 gap-4'>
                                            {otherOptions?.length > 0 ? (
                                                otherOptions?.map((item, index) => {
                                                    let lft_icon = OTHER_PAYMENT_METHODS?.find(
                                                        (itm) => itm.name == item.method_type
                                                    );
                                                    return (
                                                        ((item.method_type == "Membership" && membershipAvailable) ||
                                                            (item.method_type == "Voucher" && voucherAvailable) ||
                                                            (item.method_type == "LoyalityPoints" && loyalityAvailable)
                                                            || (item.method_type == "GiftCard")
                                                            || (item.method_type == "Coupon")
                                                            || (item.method_type == "Promotion")) &&
                                                        <>
                                                            <PaymentCheckboxCard
                                                                key={index}
                                                                leftIcon={lft_icon?.icon ? lft_icon?.icon : <img className="h-[1.25rem] w-[1.25rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} />}
                                                                checked={appointmentData.other_method === item.method_type}
                                                                onClick={() => {
                                                                    setAppointmentData({ ...appointmentData, other_method: item.method_type });
                                                                    if (item.method_type == 'Coupon') {
                                                                        setCouponPopup(true);
                                                                    }
                                                                    if (item.method_type == 'Membership') {
                                                                        setMembershipPopup(true);
                                                                    }
                                                                    if (item.method_type == 'GiftCard') {
                                                                        setGiftCardPopup(true);
                                                                    }
                                                                    if (item.method_type == 'LoyalityPoints') {
                                                                        setLoyalityPointsPopup(true);
                                                                    }
                                                                    if (item.method_type == 'Voucher') {
                                                                        setVoucherPopup(true);
                                                                    }
                                                                    if (item.method_type == 'Promotion') {
                                                                        setAvailOfferPopup(true);
                                                                    }
                                                                }}
                                                                id={item.method_type}
                                                                title={
                                                                    <div className='flex items-center gap-2'>
                                                                        <p className='font-semibold text-sm'>{item.label}</p>
                                                                    </div>
                                                                }
                                                            />
                                                        </>
                                                    )
                                                })
                                            )
                                                :
                                                ""
                                            }
                                            {(membershipLoader || voucherLoader || loyaltyLoader || getGiftCardLoader) && [0, 1, 2]?.map(itm => {
                                                return (
                                                    <CheckBoxCardShimmer />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }



                            {/* Gift Cards For Client */}

                            {checkoutGiftCard?.length > 0 &&
                                <div className='mt-2'>
                                    <h2 className="text-primary font-bold text-xl mb-3">
                                        {selectedLanguage?.name == "English" ? "Gift Cards" : translation[1540]?.value ? translation[1540]?.value : "Gift Cards"}
                                    </h2>
                                    <div className='grid grid-cols-2 gap-4'>
                                        {checkoutGiftCard?.map((itm, index) => {
                                            return <div
                                                key={index}
                                                onClick={() => {
                                                    setGiftCardData(itm)
                                                }}
                                                className='flex cursor-pointer items-center rounded-md gap-2 bg-[#E6E9FF] p-2'>
                                                <div className="flex bg-white p-1 rounded-md">
                                                    <Svgs.Gift width={'2rem'} height={'2rem'} />
                                                </div>
                                                <div className="">
                                                    <p className="font-semibold text-sm">{cur_currency + " " + (+itm?.spend_amount?.toFixed(2))} Gift Card</p>
                                                    <p className=" text-xs text-[#000000]/40 ">{itm?.sale_code}</p>
                                                </div>
                                            </div>
                                        }
                                        )}
                                    </div>
                                </div>
                            }
                        </div>

                        {/* Booking Detail Card */}
                        <div className='lg:col-span-2 flex flex-col gap-6'>
                            <GroupBookingDetailCard
                                tips={employeeTips}
                                tip_sum={employeeTipSum}
                                payNowBtnDisabled={payNowBtnDisabled}
                                services={appointmentData?.filteredClientsAppointments}
                                appointmentData={appointmentData}
                                setAppointmentData={setAppointmentData}
                                payBtnText={'Checkout'}
                                isFromCheckout
                                selectedPaymentMethods={selectedPaymentMethods}
                                setSelectedPaymentMethods={setSelectedPaymentMethods}
                                onPayClick={(data) => { handle_submit(data) }}
                                onTipDelete={(data) => { handle_tip_delete(data) }}
                                price={totalCartPrice}
                                setAppointmentService={setAppointmentService}
                                cartTotal={cartTotal}
                                setCartTotal={setCartTotal}
                                existingLoyalityPoints={existingLoyalityPoints}
                                setLoyalityPoints={setLoyalityPoints}
                                loading={loader}
                                location={selected_location}
                                totalAmountPaid={totalAmountPaid}
                                setAppliedTax={setLocationTax}
                                taxApplied={taxApplied}
                                setT_price={setT_price}
                                checkoutLoader={checkoutLoader}
                                allowCheckout={true}
                                payBtnDisabled={payBtnDisabled}
                                setCartRemainingPrice={setCartRemainingPrice}
                                cartRemainingPrice={cartRemainingPrice}
                                cartChange={cartChange}
                                setCartChange={setCartChange}
                                donation={donation}
                                setDonation={setDonation}
                            />
                            <SupportCard />
                        </div>
                    </div>
                </div>


                {/* ==================== Show Add a tip to staff PopUp ==================== */}

                {TipToStaff &&
                    <Popup size='md'
                        heading={selectedLanguage?.name == "English" ? "Add a tip to the staff" : translation[186]?.value ? translation[186]?.value : "Add a tip to the staff"}
                        close={setTipToStaff}
                        open={TipToStaff}
                    >
                        <div className='flex gap-4 max-h-[25rem] overflow-y-auto'>
                            <div className="flex flex-col w-full ">
                                {Employees?.sort(itm => itm.level == "Above_Averge" ? -1 : 1)?.map(empl => {
                                    return (
                                        <div className="flex items-center gap-3 ">
                                            <div className='w-1/2 p-2'>
                                                {empl?.full_name}
                                            </div>
                                            <div className='flex w-1/2 items-center gap-2 border rounded-md flex-1 '>
                                                <div className='p-2 rounded-l-md text-xs text-[#A1A1A1] bg-slate-50 h-full flex items-center justify-center w-10 border-r-[1px]'>{cur_currency}</div>
                                                <input
                                                    type="number"
                                                    className='placeholder:text-[0.875rem] flex-1 outline-none border-none w-[3rem] text-sm'
                                                    placeholder={selectedLanguage?.name == "English" ? "Add tip" : translation[187]?.value ? translation[187]?.value : "Add tip"}
                                                    max={100}
                                                    maxLength={4}
                                                    onKeyDown={(e) => {
                                                        if (e.key === '-') {
                                                            e.preventDefault(); // Prevent typing of '-' symbol
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        const value = Math.abs(+e.target.value?.replace(/[^0-9.]/g, '')?.slice(0, 4))
                                                        const updatedTipValues = {
                                                            ...currentEmployeeTip,
                                                            [empl?.id]: value
                                                        };
                                                        setCurrentEmployeeTip(updatedTipValues);
                                                        handleTipChange(empl?.id, empl?.full_name, value)
                                                    }}
                                                    value={Math.abs(currentEmployeeTip[empl?.id]) || ''}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='flex items-center gap-5 justify-end mt-[2rem]'>
                            <LoginBtn
                                // disabled={amount_for_points < t_price}
                                className={"!px-6"}
                                // disabled={employee_tips?.length == 0}
                                onClick={() => {
                                    setTipToStaff(false)
                                    setEmployeeTips(employee_tips)
                                    setEmployeeTipSum(tip_sum)
                                }}
                            >
                                {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                            </LoginBtn>
                        </div>
                    </Popup>
                }

                {/* ==================== Show Redeemable Value and LoyalityPoints PopUp ==================== */}


                {(selectedClient && appointmentData?.other_method === "LoyalityPoints" && loyalityPointsPopup) &&
                    <Popup size='md' heading={selectedLanguage?.name == "English" ? "Loyalty Points" : translation[197]?.value ? translation[197]?.value : "Loyalty Points"} close={() => { setLoyalityPointsPopup(!loyalityPointsPopup) }} open={loyalityPointsPopup}>
                        <div className='space-y-10 pt-5'>
                            {
                                notFound ? <NotFound /> :
                                    <>
                                        <div className='w-full'>
                                            <div className="flex items-center justify-center gap-3 divide-x whitespace-nowrap">
                                                <div className="text-center flex-1 space-y-2">
                                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Total Points" : translation[210]?.value ? translation[210]?.value : "Total Points"}</p>
                                                    <h3 className="font-bold text-3xl text-[#101928]">{loyalityPoints?.total_available_points?.toFixed(2)}</h3>
                                                </div>
                                                <div className="text-center flex-1 space-y-2">
                                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Redeemable Value" : translation[211]?.value ? translation[211]?.value : "Redeemable Value"}</p>
                                                    <h3 className="font-bold text-3xl text-[#101928]">{cur_currency + " " + loyalityPoints?.total_amount?.toFixed(2)}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end w-full gap-3">
                                            <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                                onClick={() => { setLoyalityPointsPopup(!loyalityPointsPopup) }}
                                                text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                            />
                                            {loyalityPoints && <LoginBtn
                                                disabled={notFound || appointmentData?.loyalty_data?.find(itm => itm?.client == selectedClient)?.redeemed_points}
                                                onClick={() => {
                                                    redeemLoyaltyPointsAvailabilityCheckFunction()
                                                    setLoyalityPointsPopup(false)
                                                }}>
                                                {selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}
                                            </LoginBtn>
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </Popup>
                }

                {/* ==================== Show Confirm Redemption PopUp ==================== */}

                <Popup size='md'
                    heading={selectedLanguage?.name == "English" ? "Confirm Redemption" : translation[213]?.value ? translation[213]?.value : "Confirm Redemption"}
                    close={setConfirmRedeem} open={ConfirmRedeem}>
                    <div className='flex flex-col gap-5 p-5'>
                        <div className='flex flex-col gap-5'>
                            <h3 className="text-center font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Confirm Redemption" : translation[213]?.value ? translation[213]?.value : "Confirm Redemption"}
                            </h3>
                            {notFound ?
                                <div className='text-[#A1A1A1] flex items-center gap-1.5'>
                                    {selectedLanguage?.name == "English" ? "Loyalty Points Not Found" : translation[214]?.value ? translation[214]?.value : "Loyalty Points Not Found"}
                                </div>
                                :
                                <div className='grid grid-cols-2 items-center text-center py-5'>
                                    <div className='border-r border-[#C7C7C7]'>
                                        <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Total redeem points" : translation[1650]?.value ? translation[1650]?.value : "Total redeem points"}</h5>
                                        <p className='font-bold text-3xl'>{loyalityPointsDetail?.points_to_redeem}</p>
                                    </div>
                                    <div>
                                        <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Total redeemable value" : translation[1651]?.value ? translation[1651]?.value : "Total redeemable value"}</h5>
                                        <p className='font-bold text-3xl'>{loyalityPointsDetail?.amount_for_points} {cur_currency}</p>
                                    </div>
                                </div>}
                            <div className="flex items-center justify-end gap-3">
                                <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                    text={
                                        <div className='flex items-center gap-2'>
                                            <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>
                                        </div>
                                    }
                                    onClick={() => setConfirmRedeem(false)}
                                />
                                {loyalityPoints &&
                                    <LoginBtn
                                        disabled={notFound}
                                        onClick={() => {
                                            redeemLoyaltyPointsFunction()
                                        }}>
                                        {selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                    </LoginBtn>
                                }
                            </div>
                        </div>
                    </div>
                </Popup>


                {/* ====================  Gift Card Detail PopUp ==================== */}

                <Popup size='md'
                    heading={selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"}
                    close={() => {
                        setGiftCardDetail(undefined)
                        setGiftCardPopup(false);
                        setGiftCardDetailPopup(false)
                        setGiftCardError('')
                    }}
                    open={giftCardDetailPopup}>
                    <div className=' p-3'>
                        <>
                            <div className='space-y-5'>
                                <div className='flex flex-col justify-between gap-10 p-5 px-7 rounded-[20px] text-white md:h-[255px]' style={{ backgroundImage: 'linear-gradient(269.5deg, #5679FF -5.22%, #325AF1 87.33%)' }}>
                                    <div>
                                        <div className='flex items-end gap-5'>
                                            <p className='text-[2.5rem] font-bold'>{cur_currency} {' '}
                                                {+(giftCardDetail?.spend_amount)?.toFixed(2)}</p>
                                            <p className='text-[1.3rem]'>/ {cur_currency} {' '} {+(giftCardDetail?.price)?.toFixed(2)}</p>
                                        </div>
                                        <p className='text-[1.25rem]'>{giftCardDetail?.gift_card_detail?.title}</p>
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex flex-col'>
                                            <p className='font-light'>{selectedLanguage?.name == "English" ? "Code" : translation[1576]?.value ? translation[1576]?.value : "Code"}</p>
                                            <p className='text-[1.25rem] font-semibold'>{giftCardDetail?.sale_code}</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='font-light'> {selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                            <p className='text-[1.25rem] font-semibold'>{moment(giftCardDetail?.expiry).format('DD MMMM YYYY')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between items-end'>
                                    <p className='font-semibold text-xl'>{selectedLanguage?.name == "English" ? "Amount to Pay" : translation[1540]?.value ? translation[1540]?.value : "Amount to Pay"}</p>
                                    <p className='font-semibold text-base'>{cur_currency} {' '} {(+t_price + +taxApplied)?.toFixed(2)}</p>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <div>
                                        <FloatInput
                                            title={selectedLanguage?.name == "English" ? "Redeem Amount" : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"}
                                            placeholder={selectedLanguage?.name == "English" ? "Redeem Amount" : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"}
                                            name="giftCardCode"
                                            value={giftCard_amount}
                                            required={false}
                                            onChange={(e) => {
                                                let amountToPay = (+t_price + +taxApplied)?.toFixed(2)
                                                if (+e.target.value !== 0) {
                                                    if (+amountToPay < +e.target.value || +(giftCardDetail?.spend_amount) < +e.target.value) {
                                                        // setGiftCard_amount(null)
                                                        setGiftCardError(`${selectedLanguage?.name == "English" ? "Amount to redeem must be lower or equal to " : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"} ${+(giftCardDetail?.spend_amount) < +e.target.value ? +(giftCardDetail?.spend_amount) : +amountToPay} `)

                                                    } else {
                                                        setGiftCardError('')
                                                        setGiftCard_amount(e.target.value)
                                                    }
                                                } else {
                                                    setGiftCard_amount(null)
                                                    setGiftCardError(`${selectedLanguage?.name == "English" ? "Amount cannot be zero" : translation[1664]?.value ? translation[1664]?.value : "Amount cannot be zero"} `)
                                                }
                                            }}
                                            // onChange={(e) => {
                                            //     setGiftCardError('')
                                            //     setGiftCard_amount(e.target.value)
                                            // }}
                                            error={giftCardError}
                                            parentClass={"mb-2"}
                                        />
                                    </div>
                                    <div className="flex items-center justify-end gap-3">
                                        <LoginBtn
                                            borderBtn
                                            text={
                                                <div className='flex items-center gap-2'>
                                                    <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>
                                                </div>
                                            }
                                            onClick={() => {
                                                setGiftCardPopup(false)
                                                setGiftCardDetail(undefined)
                                            }}
                                        />
                                        <LoginBtn
                                            onClick={() => {
                                                if (cartTotal >= +giftCard_amount) {
                                                    if (cartTotal >= +giftCard_amount && +(giftCardDetail?.spend_amount) >= +giftCard_amount) {
                                                        redeemGiftCardFunction()
                                                    } else {
                                                        setGiftCardError(`${selectedLanguage?.name == "English"
                                                            ? "Amount to redeem must be lower or equal to "
                                                            : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"} ${+(giftCardDetail?.spend_amount)?.toFixed(2)} `)
                                                    }
                                                } else {
                                                    setGiftCardError(`${selectedLanguage?.name == "English"
                                                        ? "Amount to redeem must be lower or equal to "
                                                        : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"} ${cartTotal} `)
                                                }
                                            }}
                                            disabled={!giftCard_amount}
                                            className={'h-fit'}
                                            text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                        />

                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </Popup>


                {/* ==================== Show avaliable Vouchers and REDEEM PopUp ==================== */}

                {(selectedClient && appointmentData?.other_method === "Voucher" && voucherAvailable && voucherPopup) &&
                    <Popup size='md' heading={selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers"} close={() => { setVoucherPopup(!voucherPopup) }} open={voucherPopup}>
                        <div className=''>
                            {
                                voucherNotFound ? <NotFound /> :
                                    checkoutVouchers?.length > 0 && checkoutVouchers?.map((vchr, index) =>
                                        <>
                                            <div className='border-2 border-[#e5e7eb] border-solid px-5 py-3 rounded-xl my-4' key={index}>
                                                <div className=''>
                                                    <div className="pb-3 flex items-center gap-5 justify-between">
                                                        <div className="space-y-1">
                                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}</p>
                                                            <h3 className="font-bold">{vchr?.voucher?.name}</h3>
                                                        </div>
                                                        <div className="space-y-1">
                                                            <h3 className=" font-bold text-right">{cur_currency + " " + vchr?.voucher_price}</h3>
                                                            <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF] text-xs">{vchr?.discount_percentage} %</p></div>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-4 py-3">
                                                        <div className="flex items-center justify-between sm:flex-row flex-col border-y border-solid border-[#DEDFE5] sm:divide-x divide-y sm:divide-y-0">
                                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>
                                                                <h4 className="font-bold whitespace-nowrap">{vchr?.voucher?.voucher_type}</h4>
                                                            </div>
                                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Purchase at" : translation[1647]?.value ? translation[1647]?.value : "Purchase at"}</p>
                                                                <h4 className="font-bold whitespace-nowrap">{moment(vchr?.created_at).format('DD MMMM YYYY')}</h4>
                                                            </div>
                                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                                                <h4 className="font-bold whitespace-nowrap">{moment(vchr?.voucher?.end_date).format('DD MMMM YYYY')}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-full flex items-center justify-end gap-3">
                                                    <LoginBtn onClick={() => {
                                                        redeemVoucherFunction(vchr)
                                                        toast.success(selectedLanguage?.name == "English" ? "Voucher redeem successfully" : translation[1654]?.value ? translation[1654]?.value : "Voucher redeem successfully", { toastId: "toast" })
                                                    }}
                                                        text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                                    />
                                                </div>
                                            </div>
                                        </>)
                            }
                        </div>
                    </Popup>
                }


                {/* ==================== Enter Coupon Code PopUp ==================== */}

                {(selectedClient && appointmentData?.other_method === "Coupon" && couponPopup) &&
                    <Popup size='md' heading={`Enter Coupon Code `} close={() => { setCouponPopup(!couponPopup) }} open={couponPopup}>
                        <div className='space-y-6'>
                            <div className='w-full min-w-[18.75rem]'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Enter Coupon Code" : translation[901]?.value ? translation[901]?.value : "Enter Coupon Code"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Coupon Code" : translation[901]?.value ? translation[901]?.value : "Enter Coupon Code"}
                                    name="couponCode"
                                    value={couponCode}
                                    type="text"
                                    // charactersMaxLength={6}
                                    onChange={(e) => {
                                        setCouponCode(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="flex items-center justify-end w-full gap-3">
                                <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                    onClick={() => { setCouponPopup(!couponPopup) }}
                                    text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                />
                                <LoginBtn
                                    disabled={!couponCode || getCouponLoader}
                                    loading={getCouponLoader}
                                    text={
                                        <div className='flex items-center gap-2'>
                                            <p>{selectedLanguage?.name == "English" ? "Redeem Coupon" : translation[97]?.value ? translation[97]?.value : "Redeem Coupon"}</p>
                                        </div>
                                    }
                                    onClick={checkCouponCode}
                                />
                            </div>
                        </div>
                    </Popup>
                }


                {/* ==================== Gift Card Input PopUp ==================== */}

                {(selectedClient && appointmentData?.other_method === "GiftCard" && giftCardPopup) &&
                    <Popup size='md' heading={selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"} close={() => { setGiftCardPopup(!giftCardPopup) }} open={giftCardPopup}>
                        <div className='space-y-6'>
                            <div className='w-full min-w-[18.75rem]'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Gift card number" : translation[1649]?.value ? translation[1649]?.value : "Gift card number"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter gift card number" : translation[1648]?.value ? translation[1648]?.value : "Enter gift card number"}
                                    name="giftCardCode"
                                    value={giftCardCode}
                                    type="text"
                                    charactersMaxLength={6}
                                    onChange={(e) => {
                                        setGiftCardCode(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="flex items-center justify-end w-full gap-3">
                                <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                    onClick={() => { setGiftCardPopup(false) }}
                                    text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                />
                                <LoginBtn
                                    disabled={!giftCardCode || giftCardLoader}
                                    loading={giftCardLoader}
                                    text={
                                        <div className='flex items-center gap-2'>
                                            <p>{selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}</p>
                                        </div>
                                    }
                                    onClick={checkGiftCardCode}
                                />
                            </div>
                        </div>
                    </Popup>
                }
            </div>


            {/* ==================== Show avaliable Memberships and REDEEM PopUp ==================== */}

            {(selectedClient && appointmentData?.other_method === "Membership" && membershipAvailable && membershipPopup) &&
                <Popup size='md' heading={selectedLanguage?.name == "English" ? "Memberships" : translation[195]?.value ? translation[195]?.value : "Memberships"} close={() => { setMembershipPopup(!membershipPopup) }} open={membershipPopup}>
                    {membershipNotFound ? <NotFound /> :
                        checkoutMemberships?.length > 0 && checkoutMemberships?.map((membership, index) =>
                            <div className='border-2 border-[#e5e7eb] border-solid px-5 py-3 rounded-xl my-4' key={index}>
                                <div className='divide-y'>
                                    <div className="pb-3 flex items-center gap-5">
                                        <div className="space-y-1">
                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Name" : translation[205]?.value ? translation[205]?.value : "Membership Name"}</p>
                                            <h3 className="font-bold">{membership?.name}</h3>
                                        </div>
                                        <div className="space-y-1">
                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}</p>
                                            <h3 className=" font-bold">{cur_currency + " " + membership?.membership_price}</h3>
                                        </div>
                                    </div>
                                    <div className="space-y-1 py-3">
                                        <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Services" : translation[206]?.value ? translation[206]?.value : "Membership Services"}</p>
                                        <div className="flex items-center gap-3 flex-wrap">
                                            {membership?.services?.map(service => (
                                                <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF]">{service?.service_name}</p></div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="space-y-4 py-3">
                                        <div className="space-y-1">
                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Products" : translation[1236]?.value ? translation[1236]?.value : "Membership Products"}</p>
                                            <div className="flex items-center gap-3 flex-wrap">
                                                {membership?.products?.map(product => (
                                                    <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF] text-sm">{product?.product_name}</p></div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between sm:flex-row flex-col border-y border-solid border-[#DEDFE5] sm:divide-x divide-y sm:divide-y-0">
                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>
                                                <h4 className="font-bold whitespace-nowrap">{membership?.discount_type}</h4>
                                            </div>
                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Purchase at" : translation[1647]?.value ? translation[1647]?.value : "Purchase at"}</p>
                                                <h4 className="font-bold whitespace-nowrap">{moment(membership?.created_at).format('DD MMMM YYYY')}</h4>
                                            </div>
                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                                <h4 className="font-bold whitespace-nowrap">{moment(membership?.end_date).format('DD MMMM YYYY')}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex items-center justify-end gap-3">
                                    <LoginBtn
                                        onClick={() => {
                                            redeemMembershipFunction(membership)
                                            setMembershipPopup(!membershipPopup)
                                        }}
                                        text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                    />
                                </div>
                            </div>
                        )
                    }
                </Popup>
            }

            {availOfferPopup && (
                <DiscountPopup
                    availOfferPopup={availOfferPopup}
                    setAvailOfferPopup={setAvailOfferPopup}
                    MODULE_TYPE={"APPOINTMENT"}
                    cartData={appointmentData?.filteredClientsAppointments?.find(itm => itm?.client == selectedClient)}
                    setCartData={(data) => {
                        setAppointmentData((prev) => ({
                            ...prev,
                            filteredClientsAppointments: prev?.filteredClientsAppointments?.map(itm =>
                                itm?.client === selectedClient
                                    ? {
                                        ...itm,
                                        is_promotion: data?.is_promotion,
                                        is_promotion_availed: data?.is_promotion_availed,
                                        promotion_discount_sum: data?.promotion_discount_sum,
                                        selected_promotion_id: data?.selected_promotion_id,
                                        selected_promotion_type: data?.selected_promotion_type
                                    }
                                    : itm
                            )
                        }));
                    }}
                    appointmentServices={appointmentData?.filteredClientsAppointments?.find(itm => itm?.client == selectedClient)?.appointmnet_service}
                    setAppointmentService={(appointmentServices) => {
                        setAppointmentData((prev) => ({
                            ...prev,
                            filteredClientsAppointments: prev?.filteredClientsAppointments?.map(itm =>
                                itm?.client === selectedClient
                                    ? { ...itm, appointmnet_service: appointmentServices }
                                    : itm
                            )
                        }));
                    }}

                />
            )}
        </>
    )
}

export default GroupCheckoutFinish