import React, { useEffect, useState } from 'react'
import { getChartProductsInsights, getFilteredInsightProductsUrl } from 'Adapters/Api/Products'
import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import RadioBtn from 'Components/TenantApp/Elements/RadioBtn'
import NotFound from 'Components/Utility/NotFound'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import DashboardChart from '../DashboardChart'
import IconButton from 'Components/TenantApp/Elements/IconButton'
import LocationSelection from "new-components/location";
import InventoryInsightNotFound from 'Components/Utility/InventoryInsightNotFound'
import NoProductRecordFound from 'Components/Utility/NoProductRecordFound'
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm'
import { toast } from 'react-toastify'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker'
import Shimmer from 'Components/Utility/Shimmers/Shimmer'
import TableShimmer_v2 from 'Components/Utility/Shimmers/TableShimmer_v2'
import TableShimer from 'Components/Utility/Shimmers/TableShimer'
import { graphScheleton } from 'Constants/Data/Calendar'
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb'
import { YearsDropdown } from 'Constants/Data/Months'
// import { BarChart } from 'reaviz'



// top_sold
// most_consumed
// most_ordered
// most_transferred
// low_stock_products

let is_page_loaded = false

const InventoryInsights = () => {
    const dispatch = useDispatch()
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [indexValue, setIndexValue] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [employee, setEmployee] = useState();
    const [exportLoader, setExportLoader] = useState(false);
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams()
    const selected_location = localStorage.getItem('selected_location')
    const [ListProducts, setListProduct] = useState([])
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const [filterQueries, setFilterQueries] = useState({
        is_date_top_sold: false,
        top_sold: "TOP_SOLD_PRODUCTS"
    })

    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const graph_data = state.product.insights.chart_products.data
    const [loading, setLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(true)

    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const [loadingPermission, setLoadingPermission] = useState(true)
    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        let queries = {}
        searchParams.forEach((value, key) => {
            if (value && value != 'false') {
                queries[key] = value
            }
        })
        if (!is_page_loaded) {
            setFilterQueries({
                ...filterQueries,
                ...queries
            })
        }

        let location_id = localStorage.getItem('selected_location')
        location_id = location_id.replaceAll('"', '')
        // dispatch(
        //     getFilteredInsightProductsUrl(
        //         { ...queries, location: location_id },
        //     )
        // )
        const success = () => {
            setTableLoading(false)
        }
        const fail = () => {
            setTableLoading(false)
        }
        setTableLoading(true)

        dispatch(
            getFilteredInsightProductsUrl(
                { ...filterQueries, location: location_id },
                success,
                fail
            )
        )
        is_page_loaded = true
    }, [selected_location])
    // searchParams commented array of dependency

    useEffect(() => {
        setListProduct(state.product.insights.products.data)
    }, [state.product.insights.products])

    useEffect(() => {
        let location_id = localStorage.getItem('selected_location')
        location_id = location_id.replaceAll('"', '')
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        setLoading(true)
        dispatch(
            getChartProductsInsights(
                { location: location_id, selected_year: filterQueries.selected_year },
                success,
                fail
            )
        )

    }, [filterQueries.selected_year, selected_location])

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    useEffect(() => {
        setFilterQueries({
            is_date_top_sold: false,
            top_sold: "TOP_SOLD_PRODUCTS"
        })

        // Update the URL with the default query parameters
        setSearchParams({
            is_date_top_sold: false,
            top_sold: 'TOP_SOLD_PRODUCTS',
        });
    }, [])

    useEffect(() => {
        if (startDate && endDate) {
            setFilterQueries({
                ...filterQueries,
                startDate: startDate,
                endDate: endDate
            })
        }
    }, [startDate, endDate])

    const data = [
        {
            "title": selectedLanguage?.name == "English" ? "Top Sold" : translation[1131]?.value ? translation[1131]?.value : "Top Sold",
            "options": [
                {
                    label: selectedLanguage?.name == "English" ? "Top Sold Products" : translation[1132]?.value ? translation[1132]?.value : "Top Sold Products",
                    value: 'TOP_SOLD_PRODUCTS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 7 days" : translation[1133]?.value ? translation[1133]?.value : "Last 7 days",
                    value: 'LAST_7_DAYS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 30 days" : translation[1134]?.value ? translation[1134]?.value : "Last 30 days",
                    value: 'LAST_30_DAYS'
                }
            ],
            'name': 'top_sold'
        },
        {
            "title": selectedLanguage?.name == "English" ? "Most Consumed" : translation[1135]?.value ? translation[1135]?.value : "Most Consumed",
            "options": [
                {
                    label: selectedLanguage?.name == "English" ? "Most Consumed" : translation[1135]?.value ? translation[1135]?.value : "Most Consumed",
                    value: 'MOST_COMSUMED_PRODUCTS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 7 days" : translation[1133]?.value ? translation[1133]?.value : "Last 7 days",
                    value: 'LAST_7_DAYS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 30 days" : translation[1134]?.value ? translation[1134]?.value : "Last 30 days",
                    value: 'LAST_30_DAYS'
                }
            ],
            'name': 'most_consumed'
        },
        {
            "title": selectedLanguage?.name == "English" ? "Top Ordered" : translation[1136]?.value ? translation[1136]?.value : "Top Ordered",
            "options": [
                {
                    label: selectedLanguage?.name == "English" ? "Top Ordered Products" : translation[1137]?.value ? translation[1137]?.value : "Top Ordered Products",
                    value: 'MOST_ORDERED_PRODUCTS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 7 days" : translation[1133]?.value ? translation[1133]?.value : "Last 7 days",
                    value: 'LAST_7_DAYS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 30 days" : translation[1134]?.value ? translation[1134]?.value : "Last 30 days",
                    value: 'LAST_30_DAYS'
                }
            ],
            'name': 'most_ordered'
        },
        {
            "title": selectedLanguage?.name == "English" ? "Most Transferred" : translation[1138]?.value ? translation[1138]?.value : "Most Transferred",
            "options": [
                {
                    label: selectedLanguage?.name == "English" ? "Most Transferred" : translation[1138]?.value ? translation[1138]?.value : "Most Transferred",
                    value: 'MOST_TRANSFERRED_PRODUCTS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 7 days" : translation[1133]?.value ? translation[1133]?.value : "Last 7 days",
                    value: 'LAST_7_DAYS'
                },
                {
                    label: selectedLanguage?.name == "English" ? "Last 30 days" : translation[1134]?.value ? translation[1134]?.value : "Last 30 days",
                    value: 'LAST_30_DAYS'
                }
            ],
            'name': 'most_transferred'
        }
    ]

    const handleExportClick = () => {
        if (!isToastVisible) {
            if (ListProducts?.length > 0) {
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                }, 300);
            } else {
                toast.error("No data to export", {
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };

    // export start 
    let commissionReportByStaffExport = [
        { label: selectedLanguage?.name == "English" ? 'Id' : translation[603]?.value ? translation[603]?.value : 'Id', key: 'id' },
        { label: selectedLanguage?.name == "English" ? 'Product' : translation[360]?.value ? translation[360]?.value : 'Product', key: 'product' },
        { label: selectedLanguage?.name == "English" ? 'Category' : translation[293]?.value ? translation[293]?.value : 'Category', key: 'category' },
        { label: selectedLanguage?.name == "English" ? 'Quantity' : translation[365]?.value ? translation[365]?.value : 'Quantity', key: 'quantity' },
        { label: selectedLanguage?.name == "English" ? 'Brand' : translation[836]?.value ? translation[836]?.value : 'Brand', key: 'brand' }
    ]


    const inventryInsightExportData = {
        filename: "Staff Commissions Report.csv",
        headers: commissionReportByStaffExport,
        data: ListProducts?.length > 0 ?
            ListProducts?.map((product) => {
                return {
                    id: product.id ? product.id : "----",
                    product: product.name ? product.name : "----",
                    category: product.category_name ? product.category_name : "----",
                    quantity: product.quantity ? product.quantity : 0,
                    brand: product.brand_name ? product.brand_name : "----"
                }
            }) : []
    }
    // export end 

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
            <div className="flex flex-col items-start gap-2 md:gap-4 mb-6">
                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                    second={selectedLanguage?.name == "English" ? "Inventory Insights" : translation[9]?.value ? translation[9]?.value : "Inventory Insights"}
                    url={'/dashboard/pos-analytics'}
                />
                <div className="flex items-center sm:gap-4 gap-2">
                    <h2 className="font-semibold text-2xl">
                        {selectedLanguage?.name == "English" ? "Inventory Insights" : translation[9]?.value ? translation[9]?.value : "Inventory Insights"}
                    </h2>
                    <LocationSelection />
                </div>
            </div>
            <div className='flex flex-col gap-[2rem]'>
                <div className='border rounded-2xl p-4 flex flex-col gap-3.5 bg-white'>
                    <div className='flex items-center justify-between gap-4'>
                        <h1 className='font-semibold text-xl'>
                            {selectedLanguage?.name == "English" ? "Top 10 Sold Products" : translation[1142]?.value ? translation[1142]?.value : "Top 10 Sold Products"}
                        </h1>
                        <div className='flex items-center gap-4'>
                            <Dropwdown
                                title={''}
                                placeholder={'Yearly 2022'}
                                value={filterQueries.selected_year ? filterQueries.selected_year : '2025'}
                                options={YearsDropdown}
                                onChange={(e) => {
                                    setFilterQueries({
                                        ...filterQueries,
                                        selected_year: e.target.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className='py-2 overflow-x-auto'>
                        <div className={`${graph_data?.length > 0 ? 'min-w-[1000px]' : ''}`}>

                            {loading ?
                                <Shimmer>
                                    <DashboardChart GraphData={graphScheleton} animate />
                                </Shimmer>
                                : graph_data?.length > 0 ?
                                    <DashboardChart GraphData={graph_data ? graph_data : []} />
                                    :
                                    <InventoryInsightNotFound />
                            }
                            {/* <BarChart
                            colorScheme={'#418AD7'}
                            data={state.product.insights.chart_products.data ? state.product.insights.chart_products.data : []}
                        ></BarChart> */}
                        </div>
                    </div>
                </div>



                <div className='border rounded-2xl p-4 flex flex-col gap-3.5 bg-white'>
                    <h1 className='font-semibold text-xl'>
                        {selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                    </h1>
                    <div className='grid xl:grid-cols-4 sm:grid-cols-2 gap-5'>
                        {
                            data?.map((ele, index, full_arr) => {
                                return <div className='flex flex-col gap-3' key={index}>
                                    <h1 className='font-semibold'>{ele.title}</h1>
                                    {
                                        ele?.options?.map((item, i) => {
                                            return <>
                                                <RadioBtn
                                                    className={'min-w-5'}
                                                    key={i}
                                                    gap={"gap-1"}
                                                    name={ele.name}
                                                    id={`insight_filters-${index}-${i}`}
                                                    text={item.label}
                                                    value={item.value}
                                                    checked={item.value == filterQueries[ele.name] ? true : false}
                                                    onClick={(e) => {
                                                        let date_name = `is_date_${ele.name}`
                                                        let { name, value } = e.target
                                                        setStartDate()
                                                        setEndDate()
                                                        setFilterQueries({
                                                            // ...filterQueries,
                                                            [date_name]: false,
                                                            [name]: value,
                                                        })
                                                    }}
                                                />
                                            </>
                                        })
                                    }
                                    <div className='flex items-center gap-3'>
                                        <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                            <div className='p-2 relative gap-4 flex items-center whitespace-nowrap'>
                                                <p onClick={() => {
                                                    // setStartDate()
                                                    // setEndDate()
                                                    setIndexValue(index)
                                                    setToggleDateRange(!toggleDateRange)
                                                }} className='cursor-pointer xl:text-xs'>
                                                    {indexValue == index ?
                                                        `${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}` :
                                                        '--/--/---- - --/--/----'}
                                                </p>
                                                <span className='cursor-pointer' onClick={() => {
                                                    setStartDate()
                                                    setEndDate()
                                                    setFilterQueries({
                                                        is_date_top_sold: false,
                                                        top_sold: "TOP_SOLD_PRODUCTS"
                                                    })
                                                }}>Clear</span>

                                                {
                                                    (toggleDateRange && indexValue == index) && <>
                                                        <div className='fixed inset-0 z-1 left-0 w-[95%]' onClick={() => { setToggleDateRange(false) }}></div>
                                                        <div className={`absolute top-full ${index == full_arr.length - 1 ? 'right-0' : 'left-0'} z-[99999999]`}>
                                                            <DateRangePicker
                                                                start={startDate}
                                                                end={endDate}
                                                                setStartDate={setStartDate}
                                                                setEndDate={setEndDate}
                                                                setToggleDateRange={setToggleDateRange}
                                                                addCustomDataOnSelection={() => {
                                                                    let date_name = `is_date_${ele.name}`
                                                                    setFilterQueries({
                                                                        [date_name]: true,
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='w-fit'>
                                        <LoginInput
                                            title={''}
                                            type={'date'}
                                            value={filterQueries[`is_date_${ele.name}`] ? filterQueries[ele.name] : ''}
                                            name={ele.name}
                                            onChange={(e) => {
                                                let date_name = `is_date_${ele.name}`
                                                let { name, value } = e.target
                                                setFilterQueries({
                                                    // ...filterQueries,
                                                    [date_name]: true,
                                                    [name]: value,
                                                })
                                            }}
                                        />
                                    </div> */}
                                </div>
                            })
                        }
                    </div>
                    <div className='flex items-center justify-end gap-4 flex-wrap'>
                        {/* <div className='flex items-center gap-4'>
                            <CheckBox
                                label={selectedLanguage?.name == "English" ? "Low stock products" : translation[1139]?.value ? translation[1139]?.value : "Low stock products"}

                                checked={filterQueries.low_stock_products}
                                onChange={(e) => {
                                    setFilterQueries({
                                        ...filterQueries,
                                        low_stock_products: !filterQueries.low_stock_products
                                    })
                                }}
                            />
                            <CheckBox
                                label={selectedLanguage?.name == "English" ? "Out of stock products" : translation[1140]?.value ? translation[1140]?.value : "Out of stock products"}
                                checked={filterQueries.out_of_stock_products}
                                onChange={(e) => {
                                    setFilterQueries({
                                        ...filterQueries,
                                        out_of_stock_products: !filterQueries.out_of_stock_products
                                    })
                                }}
                            />
                        </div> */}
                        <div className='flex items-center gap-4 '>
                            <LoginBtn
                                text={selectedLanguage?.name == "English" ? "Reset" : translation[1141]?.value ? translation[1141]?.value : "Reset"}
                                borderBtn
                                onClick={() => {
                                    is_page_loaded = false
                                    // setSearchParams({})
                                    // setFilterQueries({})
                                    setSearchParams({
                                        is_date_top_sold: false,
                                        top_sold: 'TOP_SOLD_PRODUCTS',
                                    });
                                    setFilterQueries({
                                        is_date_top_sold: false,
                                        top_sold: "TOP_SOLD_PRODUCTS"
                                    })
                                    let location_id = localStorage.getItem('selected_location')
                                    location_id = location_id.replaceAll('"', '')
                                    const success = () => {
                                        setTableLoading(false)
                                    }
                                    const fail = () => {
                                        setTableLoading(false)
                                    }
                                    setTableLoading(true)
                                    dispatch(
                                        getFilteredInsightProductsUrl(
                                            {
                                                is_date_top_sold: false,
                                                top_sold: 'TOP_SOLD_PRODUCTS',
                                                location: location_id,
                                            },
                                            success,
                                            fail
                                        )
                                    )
                                }}
                            />
                            <LoginBtn
                                text={selectedLanguage?.name == "English" ? "Apply Filter" : translation[595]?.value ? translation[595]?.value : "Apply Filter"}

                                disabled={Object.keys(filterQueries)?.length === 0}
                                onClick={() => {
                                    // console.log(filterQueries, endDate, "filter")
                                    let queries = {}
                                    searchParams.forEach((value, key) => {
                                        if (value && value != 'false') {
                                            queries[key] = value
                                        }
                                    })
                                    let location_id = localStorage.getItem('selected_location')
                                    location_id = location_id.replaceAll('"', '')
                                    // old code 
                                    // dispatch(
                                    //     getFilteredInsightProductsUrl(
                                    //         { ...queries, location: location_id },
                                    //     )
                                    // )
                                    // new function
                                    let payload = { ...filterQueries }
                                    if (startDate && endDate) {
                                        if (!payload?.startDate || !payload?.endDate) {
                                            payload = { ...filterQueries, startDate: startDate, endDate: endDate }
                                        }
                                    }
                                    const success = () => {
                                        setTableLoading(false)
                                    }
                                    const fail = () => {
                                        setTableLoading(false)
                                    }
                                    setTableLoading(true)
                                    dispatch(
                                        getFilteredInsightProductsUrl(
                                            { ...payload, location: location_id },
                                            success,
                                            fail
                                        )
                                    )
                                    setSearchParams({
                                        ...payload
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>


                <div className='flex flex-col gap-3.5'>
                    <div className='flex items-center justify-between gap-4'>
                        <h1 className='font-semibold text-2xl'>
                            {selectedLanguage?.name == "English" ? "Search results" : translation[1143]?.value ? translation[1143]?.value : "Search results"}

                        </h1>
                        {/* <IconButton filled>
                            <Svgs.Export />
                        </IconButton> */}
                        {loadingPermission ?
                            <Shimmer className={'w-12 lg:w-16'}>
                                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                            </Shimmer> :
                            exportLoader ? (
                                <div className="h-[4rem] flex items-center justify-center">
                                    <PreloaderSm />
                                </div>
                            ) :
                                !employee ? (
                                    ListProducts?.length > 0 ?
                                        <CSVLink
                                            {...inventryInsightExportData}
                                            target="_blank"
                                            onClick={() => {
                                                // // setExportLoader(true)
                                                handleExportClick()
                                            }}
                                        >
                                            <LoginBtn
                                                className="rounded-lg !py-[7px]"
                                                bg="bg-white"
                                                animation={false}
                                                customThemeBtn={'theme-btn2'}
                                            >
                                                <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                    <Svgs.ExportSvg />
                                                </div>
                                            </LoginBtn>
                                        </CSVLink> :
                                        <LoginBtn
                                            className="rounded-lg border-2 border-primary"
                                            bg="bg-white"
                                            animation={false}
                                            onClick={handleExportClick}
                                        >
                                            <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                <Svgs.Export bg="#FFFFFF" />
                                            </div>
                                        </LoginBtn>
                                ) : (
                                    ""
                                )}

                    </div>
                    <div className='border rounded-2xl bg-white'>
                        {(state?.product?.insights?.products?.is_updated && !tableLoading) ?
                            <div className="overflow-x-auto rounded-2xl">
                                <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(140px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                                    <p>{selectedLanguage?.name == "English" ? "Product Id" : translation[603]?.value ? translation[603]?.value : "Product Id"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity"}</p>
                                    {/* <p>{selectedLanguage?.name == "English" ? 'Product Type' : translation[1006]?.value ? translation[1006]?.value : "Product Type"}</p> */}
                                    <p>{selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}</p>
                                </div>
                                <div className="odd-bg text-[#3A3A3A] transition-all">
                                    {ListProducts?.length > 0 ?
                                        ListProducts?.map((product, index) => {
                                            return (
                                                <>
                                                    <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(140px,1fr))]  min-w-full w-fit">
                                                        <div className="flex items-center">
                                                            <p className="text-sm">{product.id}</p>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <p className="text-sm">{product.name}</p>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <p className="text-sm">{product.category_name}</p>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <p className="text-sm">{product.quantity ? product.quantity : 0}</p>
                                                        </div>
                                                        {/* <div className="flex items-center">
                                                                <p className="text-sm">{product.product_type}</p>
                                                            </div> */}
                                                        <div className="flex items-center">
                                                            <p className="text-sm">{product.brand_name}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        :
                                        <>
                                            <NoProductRecordFound />
                                        </>
                                    }
                                </div>
                            </div>
                            :
                            <TableShimer cols={5} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryInsights