import React from 'react'
import { useAppointmentSummaryReport } from './helper'
import Shimmer from 'Components/Utility/Shimmers/Shimmer'
import ReactDateRangePicker from 'Components/TenantApp/Elements/ReactDateRangePicker'
import AppointmentByTeamMember from './Elements/AppointmentByTeamMember'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import { DateFilterTypes } from 'Constants/Data/Months'
import AppointmentByServices from './Elements/AppointmentByService'
import AppointmentByServiceGroup from './Elements/AppointmentByServiceGroup'

const AppointmentSaleSummaryReport = () => {
    const { dateType, setDateType, loading, employee, startDate, setStartDate, endDate, setEndDate, loadingPermission, csvLoading,
        handleExportCSVData } = useAppointmentSummaryReport()
    return (
        <>
            <div className="px-4 lg:px-6 pb-[21px] flex sm:items-center items-end justify-between sm:flex-row flex-col gap-3">
                <div className="flex md:flex-row flex-col md:items-center justify-end flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                    <div className="flex gap-2 items-center flex-wrap justify-end">
                        {loadingPermission ?
                            <Shimmer className={'w-40 lg:w-44'}>
                                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                            </Shimmer>
                            :
                            <Dropwdown
                                name={"dateType"}
                                value={dateType}
                                onChange={(e) => setDateType(e?.target?.value)}
                                options={DateFilterTypes}
                                title=""
                                placeholder="Select Date Filter"
                            />
                        }
                        {dateType == 'custom' &&
                            <ReactDateRangePicker
                                className="!max-w-none"
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className='flex flex-col space-y-6'>
                <AppointmentByTeamMember
                    loading={loading}
                    loadingPermission={loadingPermission}
                    employee={employee}
                    csvLoading={csvLoading}
                    handleExportCSVData={handleExportCSVData}
                />
                <AppointmentByServices
                    loading={loading}
                    loadingPermission={loadingPermission}
                    employee={employee}
                    csvLoading={csvLoading}
                    handleExportCSVData={handleExportCSVData}
                />
                <AppointmentByServiceGroup
                    loading={loading}
                    loadingPermission={loadingPermission}
                    employee={employee}
                    csvLoading={csvLoading}
                    handleExportCSVData={handleExportCSVData}
                />
            </div>
        </>
    )
}

export default AppointmentSaleSummaryReport
