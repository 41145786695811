import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DashboardChart = ({ GraphData, animate, expense }) => {
    let colorIndex = 0; // Initialize the color index

    const staticColors = [
        '#5679FE', '#CED1FF', '#054CD5', '#FFC0CB', '#FF5733', '#FFD700', '#32CD32', '#FF1493', '#800080', '#00FFFF',
        '#00FF00', '#FFFF00', '#FF4500', '#8B4513', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF',
        '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57',
        '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500',
        '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22',
        '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A',
        '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2',
        '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50',
        '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB',
        '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0',
        '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC',
        '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1'
    ];

    const [barThickness, setBarThickness] = useState(30);

    useEffect(() => {
        // Update barThickness based on screen width
        const updateBarThickness = () => {
            const screenWidth = window.innerWidth;
            let thickness;

            if (screenWidth < 600) {
                thickness = 15; // Mobile
            } else if (screenWidth < 992) {
                thickness = 20; // Tablet
            } else {
                thickness = 25; // Laptop and larger
            }

            setBarThickness(thickness);
        };

        // Initial call to set barThickness
        updateBarThickness();

        // Attach event listener to update barThickness on window resize
        window.addEventListener('resize', updateBarThickness);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateBarThickness);
        };
    }, []);

    const options = {
        // responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x', // Set indexAxis to 'y' for vertical bar chart
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false, // Remove grid lines on the chart
                    drawTicks: false, // Remove small tick marks below X-axis labels
                    drawBorder: false, // ✅ Removes the connecting line at the top of the X-axis
                },
                ticks: {
                    // maxRotation: 30, // Disable rotation of labels
                    autoSkip: false, // Prevent labels from being skipped
                    maxTicksLimit: 10, // Adjust the maximum number of visible labels
                    callback: function (value) {
                        value = GraphData[value]?.key;
                        return value?.length > 10 ? value.substring(0, 10) + '...' : value;
                    },
                },
            },
            y: {
                grid: {
                    display: true, // Remove grid lines for Y-axis
                    drawTicks: false, // Remove small tick marks below X-axis labels
                    drawBorder: false, // ✅ Removes the connecting line at the top of the Y-axis
                },
                ticks: {
                    display: true, // Keep Y-axis labels
                    padding: 6,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'white',
                titleColor: '#101928',
                bodyColor: 'rgba(16, 25, 40, 0.50)',
                borderColor: '#cecece',
                borderWidth: 1,
                yAlign: 'bottom',
                padding: 10,
                textAlign: 'center',
                displayColors: false,
                callbacks: {
                    title: function (tooltipItems) {
                        const originalTitle = tooltipItems[0]?.label || '';
                        return originalTitle.length > 40 ? originalTitle.slice(0, 40) + '...' : originalTitle;
                    },
                    label: function (tooltipItem) {
                        return `${tooltipItem.dataset.label.trim()}: ${tooltipItem.raw}`;
                    },
                },
            },

        },
    };

    let final = {
        labels: GraphData?.map(item => item?.key),
        datasets: [
            {
                label: expense ? ' Expense ' : ' Sale ',
                data: GraphData.map(item => (item.data).toString()),
                backgroundColor: animate ? "#e5e7eb" : GraphData.map(item => {
                    const color = staticColors[colorIndex];
                    colorIndex = (colorIndex + 1) % staticColors.length; // Move to the next color index
                    return color;
                }),
                borderColor: animate ? "#e5e7eb" : GraphData?.map(item => staticColors[colorIndex]),
                borderWidth: 0,
                borderRadius: 1000,
                barThickness: barThickness, // Set the fixed thickness for the bars
            },
        ],
    }

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '250px',
            }}
        >
            {/* Shadow Effect */}
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(to top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0))',
                    pointerEvents: 'none',
                    zIndex: 2,
                }}
            />

            {/* Chart */}
            <Bar
                height={80}
                style={{ width: '100%', position: 'relative', zIndex: 1 }}
                options={options}
                data={final}
            />
        </div>
    );
    ;
}

export default DashboardChart

