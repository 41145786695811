import React from 'react'

const FindUsS = () => {
    return (
        <svg width="70" height="60" viewBox="0 0 121 109" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="84.8922" cy="72.7427" r="36.1071" fill="#E4EAF0" />
            <g clipPath="url(#clip0_182_904)">
                <g clipPath="url(#clip1_182_904)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.26953 48.6371C5.26953 26.6529 23.0913 8.83105 45.0756 8.83105C67.0599 8.83105 84.8814 26.6529 84.8814 48.6371C84.8814 70.6213 67.0599 88.4434 45.0756 88.4434C23.0913 88.4434 5.26953 70.6213 5.26953 48.6371ZM45.0756 14.5409C26.2448 14.5409 10.9795 29.8063 10.9795 48.6371C10.9795 67.4676 26.2448 82.7335 45.0756 82.7335C63.9062 82.7335 79.1715 67.4676 79.1715 48.6371C79.1715 29.8063 63.9062 14.5409 45.0756 14.5409ZM49.5835 66.67C49.5835 69.1595 47.5653 71.1778 45.0757 71.1778C42.5861 71.1778 40.5679 69.1595 40.5679 66.67C40.5679 64.1805 42.5861 62.1622 45.0757 62.1622C47.5653 62.1622 49.5835 64.1805 49.5835 66.67ZM36.3612 41.1257C36.3612 37.0513 39.8778 33.1619 45.0763 33.1619C50.2749 33.1619 53.7914 37.0513 53.7914 41.1257C53.7914 44.0779 52.1686 45.5862 49.3704 47.286C49.0581 47.4758 48.6979 47.685 48.3126 47.9087L48.3122 47.909C47.2111 48.5484 45.9049 49.307 44.9228 50.0722C43.4662 51.2071 41.7706 53.0383 41.7706 55.8513C41.7706 57.677 43.2506 59.157 45.0764 59.157C46.8937 59.157 48.3685 57.6905 48.382 55.8763L48.3852 55.8711C48.4374 55.7888 48.5918 55.5949 48.9864 55.2875C49.5964 54.8122 50.2756 54.4186 51.1807 53.894L51.181 53.8938C51.6529 53.6204 52.1862 53.3113 52.8031 52.9366C56.0154 50.9851 60.4027 47.7601 60.4027 41.1257C60.4027 33.1793 53.7018 26.5504 45.0763 26.5504C36.4509 26.5504 29.7497 33.1793 29.7497 41.1257C29.7497 42.9514 31.2298 44.4315 33.0555 44.4315C34.8812 44.4315 36.3612 42.9514 36.3612 41.1257Z" fill="#101928" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_182_904">
                    <rect width="97.274" height="97.274" fill="white" />
                </clipPath>
                <clipPath id="clip1_182_904">
                    <rect width="84.225" height="84.225" fill="white" transform="translate(2.37109 7.11853)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FindUsS