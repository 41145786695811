import { apiErrorMessage } from "Constants/Data/Errors";
import { useLocation } from "new-components/location/helper";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateBlockTime, getAppointments, getCalendarMainAppointments } from "../../../../../Adapters/Api/apointments";
import Svgs from "../../../../../Assets/svgs";
import {
  DURATIONS,
  OPENING_TIME,
} from "../../../../../Constants/Data/OpeningHours";
import CircleIcon from "../../../Elements/CircleIcon";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import Textarea from "../../../Elements/Textarea";
import { useService } from "../../Sales/quick-sales/Services/helper";
import { deletecalender } from "Adapters/Api/saleServices";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import { getEmployeeDropDownListPagination } from "Adapters/Api/employee";
let employeeTimeOutId = undefined

export const BlockTimePopup = ({
  btn_loading,
  edit_mode,
  EditData,
  onClose,
  onSubmit,
  setBlockTime,
  SelectedDate,
  getCalendarAppointments
}) => {
  const formRef = useRef()

  // const { locationDetail } = useLocation()
  const { selected_location } = useLocation();

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [blocked_data, setBlockedData] = useState({});
  const [errors, setErrors] = useState(false);
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)

  const [del_btn_loading, setDelBtnLoading] = useState(false)
  // const { onDeleteblock } = useService();

  const employeeRedux = state?.employee?.employeesDropdown
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === "details") {
      let trimmedValue = value;
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
      trimmedValue = value?.slice(0, 150); // Trim the value to a maximum of 150 characters
      if (value?.length > 150) {
        setErrors((prev) => ({
          ...prev,
          // details: "You can not add more than 150 characters",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          details: "",
        }));
        setBlockedData({ ...blocked_data, [name]: trimmedValue });
      }
    }

    if (name != "details") {
      setBlockedData({ ...blocked_data, [name]: value });
      // setErrors((prev) => ({ ...prev, [name]: "" }))
    }
  };

  useEffect(() => {
    if (edit_mode) {
      setBlockedData({
        id: EditData.id,
        date: EditData.appointment_date,
        start_time: EditData.appointment_time,
        duration: EditData.duration,
        member: EditData.member,
        details: EditData.details,
        // EditData
      });
    }
  }, []);

  // delete api call here due to state issues
  const onDeleteblock = (id) => {
    dispatch(
      deletecalender(
        { id },
        () => {
          dispatch(getAppointments());
          toast.success("Block Time Deleted!", { toastId: "toast" });
          setDelBtnLoading(false)
          onClose();
          getCalendarAppointments && getCalendarAppointments()
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])

  return (
    <>
      <Popup
        open={true}
        close={onClose}
        heading={
          edit_mode ? selectedLanguage?.name == "English" ? "Update Block Time" : translation[121]?.value ? translation[121]?.value : "Update Block Time"
            : selectedLanguage?.name == "English" ? "Add Block Time" : translation[107]?.value ? translation[107]?.value : "Add Block Time"
        }
      >
        <div className="flex flex-col gap-6 w-full" ref={formRef}>
          <div className="flex items-center gap-4">
            <CircleIcon>
              <Svgs.Block size="28" />
            </CircleIcon>
            <div className="flex flex-col gap-1">
              <h1 className="font-semibold text-xl text-primary">
                {selectedLanguage?.name == "English" ? "Block Time Details" : translation[109]?.value ? translation[109]?.value : "Block Time Details"}
              </h1>

              <p className="text-sm text-[#7B7B7B]">
                {selectedLanguage?.name == "English" ? "The staff member will not be available at this time." : translation[110]?.value ? translation[110]?.value : "The staff member will not be available at this time."}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <LoginInput
              type="date"
              title={selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}
              placeholder={selectedLanguage?.name == "English" ? "Choose Date" : translation[113]?.value ? translation[113]?.value : "Choose Date"}
              name={"date"}
              min={new Date().toISOString().split('T')[0]}
              value={blocked_data?.date}
              onChange={handleChange}
              error={errors && !blocked_data?.date ? true : false}
            />
            <div className="grid lg:grid-cols-2 gap-4">
              <Dropwdown
                required
                title={selectedLanguage?.name == "English" ? "Start Time" : translation[73]?.value ? translation[73]?.value : "Start Time"}
                placeholder={selectedLanguage?.name == "English" ? "Select Start Time" : translation[114]?.value ? translation[114]?.value : "Select Start Time"}
                name={"start_time"}
                value={blocked_data?.start_time}
                onChange={handleChange}
                error={errors && !blocked_data?.start_time ? true : false}
                options={OPENING_TIME}
              />
              <Dropwdown
                required
                title={selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                placeholder={selectedLanguage?.name == "English" ? "Select Duration" : translation[115]?.value ? translation[115]?.value : "Select Duration"}
                name={"duration"}
                value={blocked_data?.duration}
                onChange={handleChange}
                error={errors && !blocked_data?.duration ? true : false}
                options={DURATIONS}
              />
            </div>
            {/* <Dropwdown
              options={state?.employee?.employees?.filter(emp => emp?.is_active == true)
                ?.filter((itm) => itm?.location?.find((loc) => loc?.id === locationDetail?.id) ? true : false)?.sort(itm => itm.level == "Above_Averge" ? -1 : 1)?.map((empl) => {
                  return {
                    label: empl.full_name,
                    value: empl.id,
                    otherData: {
                      image: empl?.image,
                      DataOne: empl.email,
                      showImage: true,
                    },
                    email: empl.email,
                    mobile_number: empl.mobile_number
                  };
                })}
              filterOption={(option, inputValue) => {
                const { mobile_number, label, email } = option?.data
                const normalizedInput = inputValue ? inputValue?.toLowerCase() : '';
                return label?.toLowerCase()?.includes(normalizedInput)
                  || (mobile_number && mobile_number?.includes(normalizedInput))
                  || (email && email?.includes(normalizedInput));
              }}
            // options={[
            //   ...state?.employee?.employees?.filter(emp => emp?.is_active == true)
            //     ?.filter((itm) => itm?.location?.find((loc) => loc?.id === locationDetail?.id) ? true : false)?.map((emp) => {
            //       return {
            //         label: emp?.full_name,
            //         value: emp?.id,
            //       };
            //     }),
            // ]}
            /> */}
            <PaginationDropdown
              required
              title={selectedLanguage?.name == "English" ? "Team Member" : translation[116]?.value ? translation[116]?.value : "Team Member"}
              placeholder={selectedLanguage?.name == "English" ? "Select Team Member" : translation[117]?.value ? translation[117]?.value : "Select Team Member"}
              name={"member"}
              value={blocked_data?.member}
              onChange={handleChange}
              error={errors && !blocked_data?.member ? true : false}
              setPage={setEmployeePage}
              page={employeePage}
              setDropdownSearch={setEmployeeSearch}
              dropdownSearch={employeeSearch}
              lastPage={employeePaginationData?.employeePages}
              currentPage={employeePaginationData?.employeeCurrentPage}
              loading={employeeLoader}
              options={employeeRedux?.map((empl, i) => {
                return {
                  label: empl.full_name,
                  value: empl.id,
                  otherData: {
                    image: empl?.image,
                    DataOne: empl.email,
                    showImage: true,
                    index: i
                  },
                  email: empl.email,
                  mobile_number: empl.mobile_number
                };
              })
              }
            />
            <Textarea
              required
              title={selectedLanguage?.name == "English" ? "Details" : translation[118]?.value ? translation[118]?.value : "Details"}
              placeholder={selectedLanguage?.name == "English" ? "Write something..." : translation[119]?.value ? translation[119]?.value : "Write something..."}
              name={"details"}
              value={blocked_data?.details}
              onChange={handleChange}
              error={errors && !blocked_data?.details ? true : false}
            />
            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn
                // disabled={btn_loading ||
                //   errors ||
                //   Object.keys(blocked_data).length == 0 ||
                //   !blocked_data?.details ||
                //   !blocked_data?.start_time ||
                //   !blocked_data?.date ||
                //   !blocked_data?.duration ||
                //   !blocked_data?.member
                //   ? true
                //   : false
                // }
                disabled={btn_loading || del_btn_loading}
                onClick={() => {
                  if (
                    !blocked_data?.date ||
                    !blocked_data?.start_time ||
                    !blocked_data?.duration ||
                    !blocked_data?.member ||
                    !blocked_data?.details
                  ) {
                    formRef.current?.scrollIntoView({ behavior: "smooth" });
                    setErrors(true);
                    return;
                  }
                  onSubmit &&
                    onSubmit(
                      blocked_data,
                      () => { },
                      () => { }
                    );
                }}
                loading={btn_loading}
              >
                {edit_mode ? selectedLanguage?.name == "English" ? "Update Block Time" : translation[121]?.value ? translation[121]?.value : "Update Block Time"
                  : selectedLanguage?.name == "English" ? "Add Block Time" : translation[107]?.value ? translation[107]?.value : "Add Block Time"
                }
              </LoginBtn>

              {edit_mode ? (
                <div className="ml-2.5">
                  <LoginBtn
                    loading={del_btn_loading}
                    disabled={del_btn_loading || btn_loading}
                    onClick={() => {
                      setDelBtnLoading(true)
                      onDeleteblock(EditData?.id);
                    }}
                  >
                    {selectedLanguage?.name == "English" ? "Delete" : translation[120]?.value ? translation[120]?.value : "Delete"}

                  </LoginBtn>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

const AddBlockTime = ({ dateeee, SelectedDate, onUpdateParam, BlockTime, setBlockTime }) => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [btn_loading, seBtnLoading] = useState(false);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const HandleSubmit = (blocked_data, success, fail) => {
    seBtnLoading(true);
    dispatch(
      CreateBlockTime(
        { ...blocked_data, business: state?.business?.business?.id },
        () => {
          onUpdateParam && onUpdateParam(true)
          toast.success("Block time created");
          seBtnLoading(false);
          success && success();
          setBlockTime(false);
          navigate("/dashboard/calendar");
        },
        (message) => {
          fail && fail();
          seBtnLoading(false);
          toast.error(message ? message : apiErrorMessage, { toastId: "toast" });
        }
      )
    );

  };

  return (
    <>
      {BlockTime && (
        <BlockTimePopup
          onClose={() => {
            setBlockTime(false);
          }}
          btn_loading={btn_loading}
          onSubmit={HandleSubmit}
        />
      )}
    </>
  );
};

export default AddBlockTime;
