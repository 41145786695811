import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getLocations } from "Adapters/Api/index";
import { getClientDropDownListPagination } from "Adapters/Api/clients/index";
import { getAllSaleOders, getAllSaleOrdersNoPagination, getSingleInvoiceDetail } from "Adapters/Api/saleOrders";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getserviceDropdownListPagination } from "Adapters/Api/services";
import moment from "moment";
import { get_refund_time_period } from "Adapters/Api/financialSettings";
import { useMemo } from "react";
import { checkRefundPermission } from "Adapters/Api/refund";

let timeOutId = undefined;
let clientTimeOutId = undefined;
let serviceTimeOutId = undefined;

export const useSaleHistory = () => {
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const current_location = getDataFromLocalStorage("selected_location");
  const client_fullName = localStorage.getItem("client_search")
  const access_token = getDataFromLocalStorage('access_token')
  const checkoutId = getDataFromLocalStorage("checkoutId");
  // useSaleModule()


  const [invoiceId, setInvoiceId] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const reduxlocationsList = state?.locations?.locations;
  const total_tax = state?.saleService?.total_tax;

  const reduxEmployees = state.employee?.employees;
  // const reduxServices = state?.service?.services;
  const businessCurrency = state.business.business;
  const location_currency = state?.utility?.currency?.location_currency;
  const allSaleorders = state?.saleOrder?.allSaleorders;
  const invoice_titles = state?.saleOrder?.invoice_titles;
  const allSaleordersWithNoPagination = state?.saleOrder?.allSaleordersWithNoPagination;
  const pages = state?.saleOrder?.pages;
  let selectedLocation = state?.locations?.selected_location || current_location
  const refundTimePeriodRedux = state?.financialSettings?.refundTimePeriod
  // const reduxInvoices = state?.multilanguageInvoices?.invoices
  // const { selected_location } = state?.locations;

  const [InvoicePopup, setInvoicePopup] = useState(false);
  const [FilterPopup, setFilterPopup] = useState(false);
  const [detailLoading, setDetailLoading] = useState(true)
  const [selectedInvice, setSelectedInvice] = useState();
  const [search_text, setSearchText] = useState("");
  const [client, setclient] = useState("");
  const [location, setlocation] = useState("");
  const [staff, setstaff] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(true);
  const [noPaginationLoader, setNoPaginationLoader] = useState(true);
  const [services, setservices] = useState("");
  const [values, setValues] = useState({
    clientt: "",
    locationn: "",
    stafff: "",
    servicess: "",
  });

  // const [Filter, setFilter] = useState(false);
  // const [dataList, setDataList] = useState([]);

  const { clientt, locationn, stafff, servicess } = values;

  // const getInovices = () => {
  //   const success = () => { }
  //   const fail = () => { }
  //   dispatch(getMultiLanguageInvoice(access_token, success, fail))
  // }

  // const invoiceLabels = useMemo(() => {
  //   return (
  //     reduxInvoices?.find(itm => itm?.location == current_location)
  //   )
  // }, [reduxInvoices?.length, current_location])

  const [clientSearchText, setClientSearchText] = useState("");
  const [clientCurrentPage, setClientCurrentPage] = useState(1)
  const [clientLoader, setClientLoader] = useState(false)
  const clientPaginationData = state?.client?.clientPaginationData;
  const clientDropdownRedux = state?.client?.clientsRedux;

  const [serviceSearchText, setServiceSearchText] = useState("");
  const [serviceCurrentPage, setServiceCurrentPage] = useState(1)
  const [serviceLoader, setServiceLoader] = useState(false)
  const servicePaginationData = state?.service?.servicesPagination;
  const reduxServices = state?.service?.servicesDropdown;

  const applyFilters = () => {
    setCurrentPage(1)
    setValues({
      clientt: client,
      locationn: location,
      stafff: staff,
      servicess: services,
    });
    setFilterPopup(!FilterPopup);
  };
  // const refundExpirey = refundTimePeriodRedux?.find(itm => {
  //   return itm?.location?.id == selectedLocation
  // })

  const refundExpirey = useMemo(() => {
    //   if (!refundTimePeriodRedux) {
    //   }

    return []; // Return an empty array if refundTimePeriodRedux is undefined or null
    //   return refundTimePeriodRedux.find(itm => itm?.location?.id === selectedLocation) || [];
  }, [refundTimePeriodRedux, selectedLocation]);

  const getInvoiceDetailData = (id) => {
    setInvoicePopup(!InvoicePopup);
    dispatch(getSingleInvoiceDetail(
      access_token, id, selectedLocation, setDetailLoading,
      (result) => {
        setSelectedInvice(result);
      },
      () => { }
    ));
  }

  useEffect(() => {
    if (checkoutId) {
      getInvoiceDetailData(checkoutId)
      localStorage.removeItem('checkoutId')
    }
  }, [checkoutId])

  const onChange = (e) => {
    setSearchText(e.target.value);
  };

  //get business location and stored it in redux
  const getBusinessLocation = (business_id) => {
    dispatch(getLocations(business_id));
  };

  useEffect(() => {
    dispatch(get_refund_time_period(access_token))
  }, [])

  useEffect(() => {
    // dispatch(getserviceDropdownList());
  }, [])

  const getClients = (clientCurrentPage, clientSearchText) => {
    dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
  }

  useEffect(() => {
    if (clientSearchText === null) {
      getClients(clientCurrentPage, "")
    } else {
      if (clientTimeOutId) {
        clearTimeout(clientTimeOutId)
      }
      clientTimeOutId = setTimeout(() => {
        getClients(clientCurrentPage, clientSearchText)
      }, 500);
    }
  }, [clientSearchText, clientCurrentPage])

  useEffect(() => {
    if (serviceSearchText === null) {
      dispatch(getserviceDropdownListPagination(serviceCurrentPage, "", setServiceLoader))
    } else {
      if (serviceTimeOutId) {
        clearTimeout(serviceTimeOutId)
      }
      serviceTimeOutId = setTimeout(() => {
        dispatch(getserviceDropdownListPagination(serviceCurrentPage, serviceSearchText, setServiceLoader))
      }, 500);
    }
  }, [serviceSearchText, serviceCurrentPage])


  useEffect(() => {
    if (businessData?.id) {
      getBusinessLocation(businessData?.id);
    }
  }, [businessData?.id])

  const getSaleHistory = () => {
    let payload = {
      currentPage: currentPage,
      setLoading: setLoading,
      location: selectedLocation,
      startDate: startDate,
      endDate: endDate,
      clientt: clientt,
      search_text: search_text,
      servicess: services

    }
    dispatch(getAllSaleOders(access_token, payload));
    dispatch(getAllSaleOrdersNoPagination(access_token, payload, setNoPaginationLoader));

  }

  useEffect(() => {
    if (selectedLocation) {
      if (search_text === null) {
        getSaleHistory()
      }
      else {
        if (timeOutId) { clearTimeout(timeOutId) }
        timeOutId = setTimeout(() => {
          getSaleHistory()
        }, 500);
      }
    }
  }, [startDate, endDate, selectedLocation, search_text, currentPage, clientt, servicess])

  useEffect(() => {
    if (client_fullName) {
      setSearchText(client_fullName)
      const timeout = setTimeout(() => {
        localStorage.removeItem("client_search");
        return () => clearTimeout(timeout);
      }, 500);
    }
  }, [client_fullName])

  useEffect(() => {
    setLoading(true)
    setNoPaginationLoader(true)
    setCurrentPage(1)
  }, [startDate, endDate, selectedLocation, clientt, servicess])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  // const toggleInviceModal = (id = "", type = "") => {
  //   if (id && type == "history") {
  //     const result = state?.saleOrder?.allSaleorders?.find(
  //       (item) => item?.id == id
  //     );
  //     // calculate_totals(result);
  //     setSelectedInvice(result);
  //   }

  //   setInvoicePopup(!InvoicePopup);
  // };

  const calculate_totals = (item) => {
    let total_bill = 0;
    if (typeof item?.membership == "object") {
      if (item?.membership?.length > 0) {
        item?.membership?.forEach((itm) => {
          total_bill += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
        });
      }
    }

    if (typeof item?.voucher == "object") {
      if (item?.voucher?.length > 0) {
        item?.voucher?.forEach((itm) => {
          total_bill += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
        });
      }
    }

    if (typeof item?.service == "object") {
      if (item?.service?.length > 0) {
        item?.service?.forEach((single_service) => {
          // let this_price = single_service?.service?.priceservice?.find(price => price.currency_name == location_currency)
          // if (this_price) {
          // total_bill += (this_price?.price) * (single_service?.quantity ? single_service?.quantity : 1);
          // }
          total_bill += (single_service?.price) * (single_service?.quantity ? single_service?.quantity : 1);
        });
      }
    }


    if (typeof item?.product == "object") {
      if (item?.product?.length > 0) {
        item?.product?.forEach((itm) => {
          total_bill += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
        });
      }
    }

    // if (item?.tip?.length > 0) {
    //   const selected_invoice_tip = (item?.tip?.length > 0 ? item?.tip?.reduce(function (total, obj) {
    //     return total + (obj.tip !== null && obj?.tip);
    //   }, 0) : 0)

    //   if (selected_invoice_tip) {
    //     total_bill += selected_invoice_tip
    //   }
    // }


    if (item?.order_type === "Appointment") {
      total_bill += item?.total_price;
    }
    let total = total_bill?.toFixed(2)
    return total;
  };

  // // filter data on location base
  // useEffect(() => {
  //   if (allSaleorders?.length === 0) {
  //     // dispatch(getSaleServices());
  //     setDataList(allSaleorders);
  //   } else {
  //     const val = allSaleorders?.filter(
  //       ({ location }) => location?.id === selected_location
  //     );
  //     setDataList(val);
  //   }
  // }, [selected_location, allSaleorders]);


  // const calculateTotalAmount = (item) => {
  //   let total_amount = 0;

  //   if (typeof item?.membership == "object") {
  //     if (item?.membership?.length > 0) {
  //       item?.membership?.forEach((itm) => {
  //         total_amount += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
  //       });
  //     }
  //   }

  //   if (typeof item?.voucher == "object") {
  //     if (item?.voucher?.length > 0) {
  //       item?.voucher?.forEach((itm) => {
  //         total_amount += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
  //       });
  //     }
  //   }

  //   if (typeof item?.service == "object") {
  //     if (item?.service?.length > 0) {
  //       item?.service?.forEach((itm) => {
  //         total_amount += (itm?.price * itm?.quantity)
  //         // itm?.service?.priceservice?.forEach((tm) => {
  //         //   total_amount += (tm?.price) * (itm?.quantity ? itm?.quantity : 1);
  //         // });
  //       });
  //     }
  //   }

  //   if (typeof item?.product == "object") {
  //     if (item?.product?.length > 0) {
  //       item?.product?.forEach((itm) => {
  //         total_amount += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
  //       });
  //     }
  //   }

  //   if (item?.order_type === "Appointment") {
  //     total_amount += item?.total_price;
  //   }

  //   return total_amount;
  // }


  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  let salehistory = [
    { label: `${selectedLanguage?.name == "English" ? "Ref" : translation[348]?.value ? translation[348]?.value : "Ref"} #`, key: 'ref_no' },
    { label: selectedLanguage?.name == "English" ? "Payment Date" : translation[349]?.value ? translation[349]?.value : "Payment Date", key: 'payment_date' },
    { label: selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type", key: 'type' },
    { label: selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client", key: 'client' },
    { label: selectedLanguage?.name == "English" ? "Donation" : translation[34]?.value ? translation[34]?.value : "Donation", key: 'donation' },
    { label: selectedLanguage?.name == "English" ? "Total Tips" : translation[352]?.value ? translation[352]?.value : "Total Tips", key: 'tips' },
    { label: selectedLanguage?.name == "English" ? "Sub Total" : translation[242]?.value ? translation[242]?.value : "Sub Total", key: 'sub_total' },
    { label: selectedLanguage?.name == "English" ? "Tax" : translation[733]?.value ? translation[733]?.value : "Tax", key: 'tax' },
    { label: selectedLanguage?.name == "English" ? "Total Sale" : translation[354]?.value ? translation[354]?.value : "Total Sale", key: 'total' },
  ]

  const exportSaleHistory = {
    filename: "Sale History Report.csv",
    headers: salehistory,
    data: allSaleordersWithNoPagination?.length > 0 ? allSaleordersWithNoPagination?.map((item) => {
      return {
        ref_no: item?.invoice ? item?.invoice?.id?.split("-")[0] : "----",
        payment_date: (item?.created_at || item?.end_date) ? moment(item?.created_at || item?.end_date).format('DD-MM-YYYY') : "----",
        type: item?.checkout_type ? item?.checkout_type : "----",
        client: typeof item?.client == "string" ? item?.client_data?.full_name : "Walk In",
        donation: item?.donations_records?.length > 0 ? `${item?.donations_records[0]?.amount || 0} ${location_currency}` : "----",
        tips: `${(+item?.total_tip)?.toFixed(2)} ${location_currency}`,
        sub_total: `${(+item?.sub_total)?.toFixed(2)} ${location_currency}`,
        tax: `${(+item?.total_tax)?.toFixed(2)} ${location_currency}`,
        total: `${(+item?.total_price)?.toFixed(2)} ${location_currency}`
      }
    }) : []
  }

  const clearFilter = () => {
    setCurrentPage(1)
    setValues({
      clientt: "",
      locationn: "",
      stafff: "",
      servicess: "",
    })
    setFilterPopup(!FilterPopup);
  }

  return {
    currentPage, setCurrentPage, navigate, search_text, onChange, FilterPopup, setFilterPopup, getInvoiceDetailData, detailLoading,
    setstaff, setservices, client, setclient, clientDropdownRedux, setlocation, reduxlocationsList, reduxEmployees, staff, reduxServices, location,
    services, applyFilters, InvoicePopup, businessCurrency, selectedInvice, exportSaleHistory, total_tax, location_currency, clearFilter,
    pages, loading, startDate, setStartDate, endDate, setEndDate, allSaleordersWithNoPagination, invoice_titles, allSaleorders,
    calculate_totals, setInvoicePopup, setSelectedInvice,
    clientSearchText, setClientSearchText, clientCurrentPage, setClientCurrentPage, clientLoader, clientPaginationData,
    serviceSearchText, setServiceSearchText, serviceCurrentPage, setServiceCurrentPage, serviceLoader, servicePaginationData,
    // openRefundPopup, setOpenRefundPopup,
    // checkRefundAvailablity, checkRefundLoading,
    invoiceId, setInvoiceId,
    refundExpirey, getSaleHistory
  };
};
