import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { updateLocations } from 'Adapters/Api';
import { updateLocation } from 'Redux/TenantRedux/Actions/locationActions';
import { toast } from 'react-toastify';
import { LOCATION } from 'Redux/TenantRedux/ActionTypes/locationTypes';
import useValidations from 'Hooks/useValidations';
import { useRef } from 'react';

function useAdminBooking() {
    const formRef = useRef()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const { setErrors, validation, errors, onBlurHandler } = useValidations();


    // get business_data and access_token from localStorage
    const businessData = getDataFromLocalStorage("business_data");
    const access_token = getDataFromLocalStorage("access_token");
    const [searchParams, setSearchParams] = useSearchParams();
    const [locationInfo, setLocationInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [firstPopup, setfirstPopup] = useState(false);

    const id = searchParams.get("id");
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const locationsList = state?.locations?.locations;
    const locationDetail = state?.locations?.current_location_data;
    const selected_location = locationsList?.find((itm) => itm?.id === id);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    useEffect(() => {
        if ((locationInfo == null) && (locationDetail == null)) {
            const matched_location = locationsList?.find((itm) => itm?.id === id);
            setLocationInfo(matched_location);
            dispatch({
                type: LOCATION.STORE_CURRENT_LOCATION_DATA,
                payload: matched_location
            });
            dispatch({
                type: LOCATION.IMAGE_PREVIEW,
                // payload: matched_location?.images?.image,
                payload: matched_location?.images,
            })
        } else {
            setLocationInfo(locationDetail);
        }
    }, [id, locationsList, dispatch])

    useEffect(() => {
        if (selected_location?.mobile_number && locationInfo?.mobile_number == "") {
            setLocationInfo((prevLocationInfo) => ({
                ...prevLocationInfo,
                mobile_number: selected_location.mobile_number,
            }));
        }
    }, [selected_location?.mobile_number, locationInfo?.mobile_number]);

    const onSubmitMarketPlaceProfile = async () => {
        setLoading(true)
        let seleted_days = locationInfo?.opening_hours.filter((itm) => itm.is_closed ? false : true)
        let new_data = {}
        let formatdays = seleted_days?.map((itm) => {
            new_data[itm?.day] = { day_id: itm.id, start_time: itm?.start_time, end_time: itm?.close_time }

            return {
                [itm?.day]: { day_id: itm.id, start_time: itm?.start_time, end_time: itm?.close_time }
                // [itm?.day]: { day_id: itm.id, start_time: itm?.start_time, close_time: itm?.close_time, is_closed: itm?.is_closed }
            }
        })

        let formatData = {
            ...locationInfo,
            opening_hours: "",
            id: "",
            location: locationInfo?.id,
            open_day: new_data,
            city: locationInfo?.city?.name ? locationInfo?.city?.name : "",
            country: locationInfo?.country?.unique_id ? locationInfo?.country?.unique_id : "",
            currency: locationInfo?.currency?.id ? locationInfo?.currency?.id : "",
            state: locationInfo?.state?.unique_id ? locationInfo?.state?.unique_id : "",
            business: businessData?.id,
            is_publish: true,
        }
        let form_data = new FormData();
        for (let key in formatData) {
            if (formatData[key]) {
                if ((typeof formatData[key] == "object") && (key != 'images')) {
                    form_data.append(key, JSON.stringify(formatData[key]));
                } else {
                    if ((key == "images") && (typeof formatData["images"] == "string")) {
                        continue;
                    } else {
                        form_data.append(key, formatData[key]);
                    }
                }
            }
        }
        const response = await updateLocations(form_data, access_token);
        let location = response?.data?.response?.location;
        let successMessage = response?.data?.response?.message;

        dispatch({
            type: LOCATION.STORE_CURRENT_LOCATION_DATA,
            payload: null
        });
        dispatch({
            type: LOCATION.IMAGE_PREVIEW,
            payload: null
        })
        setfirstPopup(!firstPopup)
        dispatch(updateLocation(location));
        setLoading(false)
        // toast.success(successMessage,{ toastId: "toast" });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == "description") {
            setLocationInfo((prev) => ({ ...prev, description: value }));
            setErrors((prev) => ({ ...prev, description: "" }));
            // if (value && value.length <= 150) {
            //     setLocationInfo((prev) => ({ ...prev, description: value }));
            //     setErrors((prev) => ({ ...prev, description: "" }));
            // } else {
            //     if (value && value.length > 150) {
            //         setErrors((prev) => ({
            //             ...prev,
            //             description: selectedLanguage?.name == "English" ? "You can not add more than 150 characters" : translation[1200]?.value ? translation[1200]?.value : "You can not add more than 150 characters",
            //         }));
            //     }
            // }
        }
        if (name === 'finance_email') {
            // Regular expression for validating email addresses
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            // Trim spaces, split by comma, and remove empty strings
            const emails = value
                ?.split(',')
                ?.map((email) => email.trim())
                ?.filter((email) => email !== '');

            // Validate emails if input is not empty
            if (emails?.length > 0) {
                const allValid = emails?.every((email) => emailRegex?.test(email)) && !value?.endsWith(',');

                if (!allValid) {
                    setErrors((prev) => ({ ...prev, [name]: 'Enter Valid Financial Email' }));
                }
            }
            setLocationInfo((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        if (name != "description" && name != 'finance_email') {
            setLocationInfo((prev) => ({
                ...prev,
                [name]: value
            }))
            setErrors((prev) => ({
                ...prev,
                [name]: ""
            }))
        }
    }



    return {
        locationInfo,
        locationsList,
        setLocationInfo,
        translation,
        selectedLanguage,
        onSubmitMarketPlaceProfile,
        handleChange,
        locationDetail,
        setErrors, errors, onBlurHandler, validation, firstPopup, setfirstPopup, loading, formRef,
    }

}

export default useAdminBooking
