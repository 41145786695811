import React, { useState } from 'react'
import Svgs from '../../../../../Assets/svgs'

const PermissionParent = ({ options, selectedLanguage, translation, ...props }) => {
    const [Open, setOpen] = useState(false)
    return (
        <div className='shadow rounded-lg bg-white'>
            <div className='px-5 py-3 flex items-center justify-between cursor-pointer' onClick={() => {
                setOpen(!Open)
            }}>
                <h1 className='font-semibold text-xl text-primary capitalize'>{props?.title}</h1>
                <div className={`transition-all ${Open && 'rotate-180'}`}>
                    <Svgs.Chevron_down />
                </div>
            </div>
            {
                Open && <div className={`overflow-auto scale-in-center`}>
                    <div className='inline-grid xl:grid gap-y-5 gap-x-3 bg-[#FBFCFF] px-5 py-3 w-full'
                        style={{
                            gridTemplateColumns: `repeat(${options?.length + 2},minmax(8.125rem,1fr))`
                        }}
                    >
                        <div className='col-span-2'>
                            <p className='text-[#3A3A3A] text-sm font-semibold'>
                                {selectedLanguage?.name == "English" ? "Permission Name" : translation[571]?.value ? translation[571]?.value : "Permission Name"}
                            </p>
                        </div>
                        {
                            options?.map((opt, index) => {
                                return (
                                    <div key={index} className='text-[#3A3A3A] text-sm font-semibold capitalize'>
                                        {opt}
                                    </div>
                                )
                            })
                        }
                    </div>
                    {props?.children}
                </div>
            }
        </div>
    )
}

PermissionParent.defaultProps = {
    options: []
}

export default PermissionParent