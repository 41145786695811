import StarRatings from 'Components/TenantApp/CustomerComplaintFeedBack/StarsRating'
import Popup from 'Components/TenantApp/Elements/Popup'
import Textarea from 'Components/TenantApp/Elements/Textarea'
import React from 'react'

const ClientFeedbackPopup = ({ data, open, close }) => {
    return (
        <Popup
            open={open}
            close={close}
            size={'xs'}
            heading={'Customer Feedback'}
        >
            <div className="flex flex-col gap-4">
                <div className="space-y-2">
                    <h4 className="">Rating</h4>
                    <StarRatings
                        name={"ratings"}
                        className="h-7 items-center"
                        value={data?.rating}
                        totalStars={5}
                        allowHalfStar={false}
                    />
                </div>
                <div>
                    <Textarea
                        name="description"
                        title="Feedback"
                        required={false}
                        placeholder="Please share your experience..."
                        value={data?.feedback_text}
                        hideOptional
                    />
                </div>
            </div>
        </Popup>
    )
}

export default ClientFeedbackPopup
