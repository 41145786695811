import { submitContactUsForm, subscribeNewsLetter } from 'Adapters/Api/NewsLetterAndContact';
import useObjectKeys from 'Hooks/useObjectKeys';
import useValidations from 'Hooks/useValidations'
import { useState } from 'react'
import { toast } from 'react-toastify';

const initialState = {
    full_name: '',
    email: '',
    position: '',
    subject: '',
    message: '',
};

const useContactUsHelper = () => {
    const [formData, setFormData] = useState(initialState)
    const [submitLoading, setSubmitLoading] = useState(false)
    const { errors, setErrors, validation, validateEmail } = useValidations()
    const { objectKeyConvertToArray } = useObjectKeys()

    const [newsLetterLoading, setNewsLetterLoading] = useState(false)
    const [newsLetterEmail, setNewsLetterEmail] = useState('')

    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    // Contact us on change
    const onChangeHandler = (e) => {
        const { name, value } = e?.target
        setErrors({ ...errors, [name]: '' })
        if (name == 'email') {
            validateEmail(name, value);
            setFormData((prev) => ({ ...prev, [name]: value }))
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }))
        }
    }

    // Contact Us 
    const onSubmitHandler = () => {
        let result = validation(formData);
        setErrors(result);

        if (objectKeyConvertToArray(result)?.length === 0) {
            const success = () => {
                setFormData(initialState)
                setSubmitLoading(false)
            }
            const fail = () => {
                setSubmitLoading(false)
            }
            setSubmitLoading(true)
            submitContactUsForm(formData, success, fail)
        }
    }

    // News Letter Change Handler
    const onNewsChangeHandler = (e) => {
        const { name, value } = e?.target
        setErrors({ ...errors, [name]: '' })
        validateEmail(name, value);
        setNewsLetterEmail(value)
    }

    // News Letter Submission
    const newsLetterSubmission = () => {
        if (newsLetterEmail) {
            let result = validation({ email: newsLetterEmail });
            toast.error(result?.email, { toastId: 'toast' })

            if (objectKeyConvertToArray(result)?.length === 0) {
                const success = () => {
                    setFormData(initialState)
                    setNewsLetterLoading(false)
                }
                const fail = () => {
                    setNewsLetterLoading(false)
                }
                const payload = {
                    email: newsLetterEmail
                }
                setNewsLetterLoading(true)
                subscribeNewsLetter(payload, success, fail)
            }
        }
    }

    return {
        submitLoading, onSubmitHandler, errors, formData, onChangeHandler, onNewsChangeHandler, newsLetterSubmission, newsLetterLoading, newsLetterEmail,
        openIndex, toggleFAQ
    }

}

export default useContactUsHelper