import React, { useEffect, useState } from 'react'
import Svgs from '../../../../Assets/svgs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LoginBtn from '../../Elements/LoginBtn'
import { useDispatch, useSelector } from 'react-redux'
import { getCalendarEmployeeDropDownList, getEmployeeList } from '../../../../Adapters/Api/employee'
import SupportCard from '../Clients/Elements/SupportCard'
import { getServiceForCalendar, getservice } from '../../../../Adapters/Api/services'
import { checkEmployeeWorkingTimes, getAppointmentLog, getAppointments, getAppointmentServices, getEmployeeOfSelectedService, updateAppointmentDetails } from '../../../../Adapters/Api/apointments'
import DateSlider from '../../../Utility/DateSlider'
import { toast } from 'react-toastify'
import moment from 'moment'
import { getClient, getClientDropDownListPagination } from 'Adapters/Api/clients'
import { apiErrorMessage } from 'Constants/Data/Errors'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { getSaleServices, getServiceEmployee, getServiceGroup, getServiceGroupCalendar } from 'Adapters/Api/saleServices'
import { useLocation } from 'new-components/location/helper'
import { useMemo } from 'react'
import AppointmentsDetails from './AppointmentsDetails'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import Popup from 'Components/TenantApp/Elements/Popup'
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull'
import AddAppointmentCard from './Elements/AddAppointmentCard'
import { get_afterward_time_range } from 'Constants/Functions/DateTime'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import AddService from '../Sales/quick-sales/Services/addService'
import { useService } from '../Sales/quick-sales/Services/helper'
import AddServiceGroup from '../Sales/quick-sales/Services/AddServiceGroup'
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown'
import AddAppointmentShimmer from 'Components/Utility/Shimmers/AddAppointmentShimmer'


let clientTimeOutId = undefined
const AppointmentEdit = () => {

    const { locationDetail } = useLocation()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams()
    const action = searchParams?.get("action")
    const appointment_group = searchParams?.get("appointment_group")
    const app_group_id = searchParams?.get("app_group_id");
    const selected_location = getDataFromLocalStorage("selected_location");
    const access_token = getDataFromLocalStorage("access_token");
    const selectedCurrency = getDataFromLocalStorage("selectedLocationCurrency")

    const employeeRedux = useSelector(state => state?.employee)
    const state = useSelector(state => state)
    const location_currency = state?.utility?.currency?.location_currency;
    const clientPaginationData = state?.client?.clientPaginationData;
    const clientRedux = state?.client?.clientsRedux;
    const reduxServices = state?.saleService?.saleServices;

    const [clientSearchText, setClientSearchText] = useState("");
    const [clientCurrentPage, setClientCurrentPage] = useState(1)
    const [clientLoader, setClientLoader] = useState(false)
    const [ServiceGroup, setServiceGroup] = useState(false);
    const [SelectServicesValue, setSelectServicesValue] = useState(false);
    const [btn_loading, setBtnLoading] = useState(false)
    const [isVailible, setisVailible] = useState(true)
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(true)
    const [pageLoader, setPageLoader] = useState(true)
    const [SelectServices, setSelectServices] = useState(false);
    const [dropdown, setdropdown] = useState("False");
    const [serviceEmployee, setServiceEmployee] = useState([]);
    const [appointmentData, setAppointmentData] = useState();
    const [selectedIndex, setSelectedIndex] = useState()
    const [serviceId, setServiceId] = useState()
    const [groupLoader, setGroupLoader] = useState(true);
    const [appointmentLogPopup, setAppointmentLogPopup] = useState(false);
    const [clickedLogIndex, setClickedLogIndex] = useState(-1);
    const [appointmentLog, setAppointmentLog] = useState([])
    const [currentAppointmentData, setCurrentAppointmentDate] = useState()
    const [submitButtonClick, setSubmitButtonClick] = useState(false)
    const [backPath, setBackPath] = useState("")

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const handleChange = (e, client_can_book, slot_availible_for_online, extra = {}) => {

        let { name, value } = e.target
        if (name === "service") {
            setAppointmentData({
                ...appointmentData,
                client_can_book,
                slot_availible_for_online,
                [name]: value
            })
        } else {
            setAppointmentData({
                ...appointmentData,
                [name]: value
            })
        }


    }

    const getClients = (clientCurrentPage, clientSearchText) => {
        dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
    }

    useEffect(() => {
        if (clientSearchText === null) {
            getClients(clientCurrentPage, "")
        } else {
            if (clientTimeOutId) {
                clearTimeout(clientTimeOutId)
            }
            clientTimeOutId = setTimeout(() => {
                getClients(clientCurrentPage, clientSearchText)
            }, 500);
        }
    }, [clientSearchText, clientCurrentPage])

    useEffect(() => {
        if (appointmentData?.appointment_date != "Invalid date") {
            setCurrentAppointmentDate(appointmentData?.appointment_date)
        }
    }, [appointmentData])

    useEffect(() => {
        if (selected_location) {
            dispatch(getServiceForCalendar(selected_location, setLoader));
            dispatch(getServiceGroupCalendar(selected_location, setGroupLoader));
            dispatch(getCalendarEmployeeDropDownList(selected_location));
        }
    }, [selected_location])

    // useEffect(() => {
    //     dispatch(getEmployeeList())
    // }, [])

    useEffect(() => {

        // dispatch(getservice())
        if (clientRedux?.length === 0) {
            dispatch(getClient());
        }

        dispatch(getAppointmentServices(
            app_group_id,
            (response) => {
                setAppointmentData({
                    ...response?.appointment,
                    appointment_date: response?.appointment?.appointmnet_service[0]?.appointment_date
                });
                setPageLoader(false)
            },
            () => {
                setPageLoader(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
            },

        ));

    }, [])


    const update_appointment = () => {
        setSubmitButtonClick(true)
        let isFormatAppointmentDataValid = false
        let allAppointments = []
        if (action === "edit" && appointmentData?.appointments?.length > 0) {
            allAppointments = [...appointmentData?.appointmnet_service, ...appointmentData?.appointments]
        } else {
            allAppointments = [...appointmentData?.appointmnet_service]
        }
        const formatAppointmentData = allAppointments?.map((itm, index) => {
            return {
                id: itm?.id,
                appointment_date: itm?.appointment_date ? itm?.appointment_date : appointmentData?.appointmnet_service[0]?.appointment_date,
                date_time: itm?.appointment_time ? itm?.appointment_time : itm?.date_time,
                service: itm?.service,
                is_favourite: itm?.is_favourite,
                client_can_book: itm?.client_can_book,
                slot_availible_for_online: itm?.slot_availible_for_online,
                duration: itm?.duration,
                srv_name: itm?.srv_name,
                price: itm?.price,
                member: itm.member,
                is_deleted: itm?.is_deleted ? itm?.is_deleted : false
            }
        })
        const updatedAppointments = formatAppointmentData?.map(appointment => {
            // Remove is_favourite only when it's false
            const { is_favourite, ...rest } = appointment;
            return is_favourite === false ? rest : appointment;
        });
        isFormatAppointmentDataValid = updatedAppointments?.every(item => (
            item?.hasOwnProperty('member') && item?.member !== undefined
        ))
        if (isFormatAppointmentDataValid) {
            setBtnLoading(true)
            dispatch(updateAppointmentDetails(
                {
                    appointments: updatedAppointments,
                    id: appointmentData?.id,
                    client: appointmentData?.client,
                    action_type: action
                },
                access_token,
                (message) => {
                    setBtnLoading(false)
                    toast?.success(message)
                    // dispatch(getAppointments());
                    navigate(backPath)
                    // navigate(`/dashboard/calendar/appointment-detail/${params.appointment_service_id}/?parent_id=${app_group_id}`)
                    // navigate('/dashboard/calendar')
                },
                () => {
                    setBtnLoading(false)
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            ))
        }
    }

    const selecteMember = employeeRedux?.employees?.find((emp) => emp?.id == appointmentData?.member_id)
    let client_time = appointmentData?.appointment_time
    let strd = parseFloat(selecteMember?.employee_info?.start_time?.split(":")?.slice(0, 2)?.join("."))
    let end = parseFloat(selecteMember?.employee_info?.end_time?.split(":")?.slice(0, 2)?.join("."))

    let client_Start_time = client_time
    let duration = appointmentData?.duration?.split(" ")[0];
    let hr_and_mints = client_Start_time?.split(":")?.slice(0, 2);
    let hr_to_min = hr_and_mints?.length > 0 && parseInt(hr_and_mints[0]) * 60;
    let mins = hr_and_mints?.length > 0 && parseInt(hr_and_mints[1])
    let total = hr_to_min + mins + parseInt(duration);

    //generate hours:minutes formate form from minutes
    function timeConvert(total_minutes) {
        let mins = total_minutes;
        let float_hours = (mins / 60);
        let int_hours = Math.floor(float_hours);
        let float_minutes = (float_hours - int_hours) * 60;
        let int_minutes = Math.round(float_minutes);

        return int_hours + "." + int_minutes;
    }
    const client_end_time = timeConvert(total)


    //check team member availibility for appiontment
    const checkTeamMemberAvailiblity = () => {
        if (parseFloat(client_end_time) >= parseFloat(strd) && parseFloat(client_end_time) <= parseFloat(end)) {
            setisVailible(true)
        } else {
            setisVailible(false)
        }
    }

    useEffect(() => {
        checkTeamMemberAvailiblity()
    }, [appointmentData?.appointment_time])


    let time_manage = client_end_time?.toString()?.split(".")?.join(":")
    const hr = client_end_time?.toString()?.split(".")[0]
    const min = client_end_time?.toString()?.split(".")[1]


    //employee for dropdown list
    const Filtered_employees = () => {

        //filter  employee on the base of slected service and selected locations
        const selected_location_employee = state.employee.employees.filter(empl => {
            if (serviceEmployee.includes(empl.id) && empl.location[0]?.id == selected_location) {
                return true
            }
            return false
        })

        // added level to employee of selected service 
        return selected_location_employee.map((ds) => {
            const result = ds?.services?.find((itm) => itm?.service === appointmentData?.service?.id);
            return {
                ...ds,
                level: result?.level
            }
        })
    }

    //return all service against the selected location on dashboard or calender
    const appointmentServices = () => {
        let result = state?.saleService?.servicesgroup?.filter((grp) => grp?.status === true)?.map((itm) => {
            let grp_services = itm?.services?.filter((srv) => {
                let loc_service = srv?.location?.find((loc) => loc === selected_location);
                if (loc_service) {
                    return true;
                } else {
                    return false;
                }
            });

            if (grp_services.length > 0) {
                return { ...itm, services: grp_services };
            } else {
                return undefined;
            }
        });
        result = result.filter((itm) => itm);
        return result;
    };

    // filter group in the dropdown
    const FilterDataGroup = () => {
        let filter_data = []
        appointmentServices()?.forEach((itm) => {
            if (search) {
                if (itm.name.toLowerCase().includes(search.toLowerCase())) {
                    const results = itm?.services?.filter((dt) => {
                        if (dt.name.toLowerCase().includes(search.toLowerCase())) {
                            return true;
                        }
                    })
                    results?.length > 0 ?
                        filter_data.push({ name: itm?.name, services: results }) :
                        filter_data.push({ name: itm?.name })
                } else {
                    const result = itm?.services?.filter((dt) => {
                        if (dt.name.toLowerCase().includes(search.toLowerCase())) {
                            return true;
                        }
                    })
                    result?.length > 0 && filter_data.push({ name: itm?.name, services: result })
                }

            }
        });
        return search ? filter_data : appointmentServices()
    };

    useEffect(() => {
        if (select_service_serviceGroups && select_service_serviceGroups.length == 0) {
            FilterDataGroup()
        }
    }, [search, state?.saleService?.servicesgroup])


    // useEffect(() => {
    //     if (state?.saleService?.servicesgroup?.length === 0) {
    //         dispatch(getServiceGroup());
    //     }
    // }, [appointmentData?.appointment_date])


    useMemo(() => {
        const res = reduxServices?.find((srv) => srv?.id === appointmentData?.service)
        setAppointmentData((prev) => ({
            ...prev,
            client_can_book: res?.client_can_book,
            slot_availible_for_online: res?.slot_availible_for_online,
        }))
    }, [reduxServices])

    //here get employee of selected service api hit on service onChange 
    useEffect(() => {
        if (serviceId) {
            dispatch(getEmployeeOfSelectedService(
                { service: serviceId, location_id: selected_location },
                (response) => {
                    setAppointmentData({
                        ...appointmentData,
                        appointmnet_service: [
                            ...appointmentData?.appointmnet_service?.map(
                                (app, i) => {
                                    if (selectedIndex == i) {
                                        return {
                                            ...app,
                                            employee_list: response
                                        }
                                    } else {
                                        return app
                                    }
                                }
                            ),
                        ],
                    });
                },
                () => {
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            ))
        }
    }, [serviceId])

    //check employee availability of working
    const checkEmployeetiming = (dt) => {

        if ((dt?.member) && (dt?.duration) && currentAppointmentData) {
            const formate_data = {
                service: dt?.service,
                member_id: dt?.member,
                duration: dt?.duration,
                app_time: dt?.appointment_time,
                // date: dt?.appointment_date
                date: currentAppointmentData
            }

            dispatch(checkEmployeeWorkingTimes(
                formate_data,
                (response) => {
                    setAppointmentData({
                        ...appointmentData,
                        appointmnet_service: [
                            ...appointmentData.appointmnet_service.map(
                                (appmnt, ind) => {
                                    if (ind == dt?.index) {
                                        return {
                                            ...dt,
                                            message: response?.message
                                        };

                                    }
                                    return appmnt;
                                }
                            ),
                        ],
                    });
                },
                () => {
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            ))
        }

    }

    const handleAppointmentLog = (appointment_id) => {
        dispatch(getAppointmentLog(
            selected_location,
            appointment_id,
            access_token,
            (response) => {
                setAppointmentLog(response?.appointment_logs)
                // dispatch(get_user_email(response?.data?.data?.data))
            },
            (result) => {
                toast.error(apiErrorMessage, { toastId: "toast" })
            }))
    }



    // add new appointment on edit logic here
    const [select_service_serviceGroups, setSelServGroups] = useState([])
    const [SORTED_TIME, SETSORTEDTIME] = useState([])
    const [select, setselect] = useState("");
    const [inp_data, setInpData] = useState({});
    const [addNewService, setAddNewService] = useState()
    const [showEditDate, setShowEditDate] = useState(true)
    const [possible_error, setPossibleError] = useState({
        booking_error: "",
        future_slot_error: "",
    });

    useEffect(() => {
        let onward_time_range = get_afterward_time_range(appointmentData?.appointment_date)
        SETSORTEDTIME(onward_time_range)
    }, [appointmentData?.appointment_date])

    const addNewAppointment = (data) => {
        if (appointmentData.appointments?.length > 0) {
            setAppointmentData({
                ...appointmentData,
                appointments: [
                    ...appointmentData.appointments,
                    {
                        ...data,
                    },
                ],
            });
        } else {
            setAppointmentData({
                ...appointmentData,
                appointments: [{ ...data }]
            });
        }
        setInpData({});
        setselect("");
    };

    useEffect(() => {
        if (appointmentServices()?.length > 0) {
            let filter_data = []
            appointmentServices()?.forEach((itm) => {
                if (search) {
                    if (itm.name.toLowerCase().includes(search.toLowerCase())) {
                        const results = itm?.services?.filter((dt) => {
                            if (dt.name.toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                        })
                        results?.length > 0 ?
                            filter_data.push({ name: itm?.name, services: results }) :
                            filter_data.push({ name: itm?.name })
                    } else {
                        const result = itm?.services?.filter((dt) => {
                            if (dt.name.toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                        })
                        result?.length > 0 && filter_data.push({ name: itm?.name, services: result })
                    }
                }
            });
            setSelServGroups(search ? filter_data : appointmentServices())
        }

    }, [search, appointmentData?.appointments?.length, appointmentServices()?.length])

    //selection of service for new appointment object 
    const handleNewServiceAppointment = (itm, itmSrv) => {
        handleServices(itm?.id, {
            srv_name: itm?.name,
            price: itmSrv?.discount_price || itmSrv?.discount_price == 0 ? itmSrv?.discount_price : itmSrv.price,
            srv_duration: itmSrv?.duration
                .toLowerCase()
                .replaceAll("_", " "),
        });
        let sel_data = {
            ...inp_data,
            service: itm?.id,
            client_can_book: itm?.client_can_book,
            slot_availible_for_online: itm?.slot_availible_for_online,
            duration: itmSrv?.duration.toLowerCase().replaceAll("_", " "),
            srv_name: itm?.name,
            price: itmSrv.price,
            srv_duration: itmSrv?.duration
                .toLowerCase()
                .replaceAll("_", " "),
        };
        setInpData(sel_data);
        if (itm?.id && inp_data.date_time) {
            addNewAppointment(sel_data);
        }
    }

    //selected service name
    const handleServices = (id, name) => {
        setdropdown("False");
        let formatdata = `${name.srv_name} (${name.srv_duration}, ${selectedCurrency} ${name.price})`;

        setselect(formatdata);
        getServiceEmployee(id)
            .then((result) => {
                const employees = result?.response?.data;
                setServiceEmployee(employees);
            })
            .catch((error) => {
                toast.error(apiErrorMessage, { toastId: "toast" });
            });
    };

    const searchfunction = (e) => {
        setSearch(e.target.value);
    };


    //get duration of selected service
    const selectedServiceDurations = () => {
        let result = appointmentServices()?.map((itm) => {
            let selected_serv = itm?.services?.find((srv) => srv?.name === select);
            if (selected_serv) {
                return selected_serv?.priceservice;
            } else {
                return false;
            }
        });

        result = result.filter((itm) => itm);
        return result;
    };
    selectedServiceDurations();


    //set duration option list
    let [...duration_options] = selectedServiceDurations()?.map((itm) => {
        return itm?.map((i) => {
            return {
                label: i?.duration.replaceAll("_", " "),
                value: i?.duration.replaceAll("_", " "),
            };
        });
    });

    const handleRemoveAppointments = (id) => {
        // delete appointmentData?.appointments
        delete appointmentData?.selected_promotion
        delete appointmentData?.selected_promotion_type
        delete appointmentData?.selected_promotion_id

        setAppointmentData({
            ...appointmentData,
            appointments: [
                ...appointmentData.appointments?.filter(
                    (app, app_i) => {
                        return app_i != id;
                    }
                ),
            ],
        })
    }

    useEffect(() => {
        if (action === "edit" && appointmentData?.appointmnet_service?.length > 0) {
            if (appointmentData?.appointmnet_service?.find(itm => (itm?.status === "Void")
                || (itm?.status === "Started") || (itm?.status === "Finished"))) {
                setShowEditDate(false)
            }
        }

    }, [action, appointmentData?.appointmnet_service, appointmentData?.appointmnet_service?.length])

    useEffect(() => {
        if (appointment_group) {
            setBackPath(`/dashboard/calendar/appointment-group-detail/${appointmentData?.id}/?parent_id=${appointment_group}`)
        } else {
            if (appointmentData?.appointmnet_service?.length > 0) {
                setBackPath(`/dashboard/calendar/appointment-detail/${appointmentData?.appointmnet_service[0]?.id}/?parent_id=${appointmentData?.id}`)
            }
        }
    }, [appointmentData?.appointmnet_service?.length, appointment_group])

    // check for only services api call when on calendar
    let allowGroupsApiCall = true
    const { durationOptions, serviceModal, isEdit, active, errors, loading, serviceData, ServiceType, setService, setAddService,
        onSubmitService, serviceHandleChange, onBlurHandler, setIsEdit, removedSelectedEmployee, setErrors, setServiceType, setIsDelete,
        formRef
    } = useService(allowGroupsApiCall);


    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                {loader ?
                    <>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-3 animate-pulse">
                                <div className="h-10 w-2/5 bg-gray-200 rounded-lg" />
                            </div>
                            <div className="lg:grid grid-cols-5 mt-[3rem] gap-[3rem]  ">
                                <AddAppointmentShimmer notView isEdit />
                            </div>
                        </div>
                    </>
                    :
                    <div className='flex flex-col gap-4'>
                        <div className='flex items-center gap-3'>
                            <div>
                                <Svgs.Back link={backPath} />
                            </div>
                            <h2 className='text-primary font-bold lg:text-3xl text-xl'>
                                {
                                    action === "edit" ?
                                        selectedLanguage?.name == "English" ? "Update Appointment" : translation[226]?.value ? translation[226]?.value : "Update Appointment"
                                        :
                                        action === "reschedul" ?
                                            selectedLanguage?.name == "English" ? "Reschedule Appointment" : translation[222]?.value ? translation[222]?.value : "Reschedule Appointment"
                                            :
                                            ""
                                }

                            </h2>
                        </div>
                        <div className='lg:grid grid-cols-5 mt-[3rem] gap-[3rem]'>

                            {(appointmentData && Object.keys(appointmentData)?.length > 0) &&
                                <div className='flex flex-col gap-5 lg:col-span-3'>
                                    {(action === "edit") &&
                                        <DateSlider
                                            selectedDate={moment(appointmentData?.appointment_date).format('YYYY-MM-DD') ? moment(appointmentData?.appointment_date).format('YYYY-MM-DD') : ""}
                                            onUpdate={(data) => {
                                                setAppointmentData({
                                                    ...appointmentData,
                                                    appointment_date: moment(data.date).format('YYYY-MM-DD'),
                                                    appointmnet_service: appointmentData?.appointmnet_service?.map((itm) => {
                                                        return {
                                                            ...itm,
                                                            appointment_date: moment(data.date).format('YYYY-MM-DD'),
                                                        }
                                                    }),
                                                });
                                            }}
                                            translation={translation}
                                            selectedLanguage={selectedLanguage}
                                            disabled={!showEditDate}
                                            appointmentData={appointmentData}
                                            setAppointmentData={setAppointmentData}
                                        />
                                    }
                                    {(action === "edit" && appointmentData?.client) &&
                                        <div className='flex gap-6'>
                                            <div className='mt-[2rem] bg-white border px-4 py-3 rounded-md w-full flex flex-col gap-4 relative'>
                                                <div className='my-[2rem]'>
                                                    <PaginationDropdown
                                                        title={selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                                                        placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}

                                                        value={appointmentData?.client}
                                                        onChange={(e) => setAppointmentData({
                                                            ...appointmentData,
                                                            client: e.target.value
                                                        })}
                                                        disabled={!showEditDate}
                                                        showTitle={false}
                                                        setPage={setClientCurrentPage}
                                                        page={clientCurrentPage}
                                                        setDropdownSearch={setClientSearchText}
                                                        dropdownSearch={clientSearchText}
                                                        lastPage={clientPaginationData?.pages}
                                                        currentPage={clientPaginationData?.currentPage}
                                                        loading={clientLoader}
                                                        custom_min_width={'min-w-[15rem]'}
                                                        options={clientRedux?.map((clnt, i) => {
                                                            return {
                                                                label: clnt.full_name,
                                                                value: clnt.id,
                                                                otherData: {
                                                                    image: clnt?.image,
                                                                    DataOne: clnt.email,
                                                                    showImage: true,
                                                                    index: i
                                                                },
                                                                email: clnt.email,
                                                                mobile_number: clnt.mobile_number
                                                            }
                                                        })
                                                        }
                                                    />
                                                    {/* <Dropwdown
                                                        name={"client"}
                                                        required
                                                        value={appointmentData?.client}
                                                        onChange={(e) => setAppointmentData({
                                                            ...appointmentData,
                                                            client: e.target.value
                                                        })}
                                                        options={clientRedux?.map(clnt => {
                                                            return {
                                                                label: `${clnt.full_name}`,
                                                                value: clnt.id,
                                                            }
                                                        })}
                                                        title={selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                                                        placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}

                                                    /> */}

                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {appointmentData?.appointmnet_service?.filter(item => !["Void", "Started", "Finished"].includes(item?.status))
                                        ?.filter((itm) => itm?.is_deleted ? false : true)?.map((itm, index) => {
                                            return (
                                                <AppointmentsDetails
                                                    setSelectedIndex={setSelectedIndex}
                                                    setServiceId={setServiceId}
                                                    index={index}
                                                    appointmentData={itm}
                                                    Filtered_employees={Filtered_employees}
                                                    setSelectServices={setSelectServices}
                                                    selecteMember={selecteMember}
                                                    action={action}
                                                    showEditDate={showEditDate}
                                                    clientRedux={clientRedux}
                                                    handleChange={handleChange}
                                                    isVailible={isVailible}
                                                    SelectServices={SelectServices}
                                                    // handleNewServices={handleNewServiceAppointment}
                                                    location_currency={selectedCurrency}
                                                    hr_and_mints={hr_and_mints}
                                                    hr={hr}
                                                    min={min}
                                                    translation={translation}
                                                    selectedLanguage={selectedLanguage}
                                                    FilterDataGroup={FilterDataGroup}
                                                    setdropdown={setdropdown}
                                                    search={search}
                                                    setSearch={setSearch}
                                                    locationDetail={locationDetail}
                                                    selectedIndex={selectedIndex}
                                                    employeeRedux={employeeRedux?.employees}
                                                    checkEmployeetiming={(dt) => {
                                                        checkEmployeetiming(dt)
                                                    }}
                                                    onRemove={(id) => {
                                                        setAppointmentData({
                                                            ...appointmentData,
                                                            appointmnet_service: [
                                                                ...appointmentData?.appointmnet_service?.map(
                                                                    (app, i) => {
                                                                        if (id == i) {
                                                                            return {
                                                                                ...app,
                                                                                is_deleted: true
                                                                            }
                                                                        } else {
                                                                            return {
                                                                                ...app,
                                                                                is_deleted: false
                                                                            }
                                                                        }
                                                                    }
                                                                ),
                                                            ],
                                                        });
                                                    }}
                                                    onUpdate={(
                                                        { name, value, indexValue },
                                                        extraValues,
                                                        book,
                                                        online_slot,
                                                        id = "",
                                                        additional_data
                                                    ) => {
                                                        let ex_data = extraValues ? extraValues : {};

                                                        setAppointmentData({
                                                            ...appointmentData,
                                                            appointmnet_service: [
                                                                ...appointmentData?.appointmnet_service?.map(
                                                                    (appmnt, ind) => {
                                                                        if (indexValue === ind) {
                                                                            if (name === "service") {
                                                                                return {
                                                                                    ...appmnt,
                                                                                    client_can_book: book,
                                                                                    slot_availible_for_online: online_slot,
                                                                                    srv_name: additional_data?.srv_name,
                                                                                    srv_duration: additional_data?.srv_duration,
                                                                                    price: additional_data?.price,
                                                                                    duration: additional_data?.srv_duration,
                                                                                    [name]: value,
                                                                                    ...ex_data,
                                                                                };
                                                                            } else {
                                                                                return {
                                                                                    ...appmnt,
                                                                                    [name]: value,
                                                                                    ...ex_data,
                                                                                };
                                                                            }
                                                                        }
                                                                        return appmnt;
                                                                    }
                                                                ),
                                                            ],
                                                        });
                                                    }}
                                                />
                                            )
                                        })
                                    }

                                    {action === "edit" &&
                                        <div>
                                            {appointmentData &&
                                                appointmentData?.appointments?.length > 0 &&
                                                appointmentData?.appointments?.map((appointment, index) => {
                                                    return (
                                                        <>
                                                            <AddAppointmentCard
                                                                location_currency={location_currency}
                                                                is_last_card={appointmentData?.appointments?.length == (index + 1) ? true : false}
                                                                select_service_serviceGroups={select_service_serviceGroups}
                                                                time_range={SORTED_TIME}
                                                                //   addOffer={addOffer}
                                                                //   filterOffer={filterOffer}
                                                                servicesRedux={reduxServices}
                                                                selectedLanguage={selectedLanguage}
                                                                translation={translation}
                                                                action={true}
                                                                //   availOfferPopup={availOfferPopup}
                                                                //   setAvailOfferPopup={setAvailOfferPopup}
                                                                submitButtonClick={submitButtonClick}
                                                                possible_error={possible_error}
                                                                setPossibleError={setPossibleError}
                                                                selectedLocation={selected_location}
                                                                serviceEmployee={serviceEmployee}
                                                                search={search}
                                                                select={select}
                                                                length={appointmentData.appointments?.length - 1}
                                                                appointmentServices={appointmentServices}
                                                                setselect={setselect}
                                                                searchfunction={searchfunction}
                                                                FilterDataGroup={FilterDataGroup}
                                                                data={appointment}
                                                                appointmentData={appointmentData}
                                                                setAppointmentData={setAppointmentData}
                                                                appointment_date={appointmentData?.appointment_date}
                                                                index={index}
                                                                key={index}
                                                                durationOption={duration_options[0]}
                                                                checkEmployeetiming={(dt) => {
                                                                    checkEmployeetiming({ ...dt, index })
                                                                }}
                                                                onRemove={(id) => handleRemoveAppointments(id)}
                                                                // onRemoveOffer={() => removePromotions()}
                                                                // setNoPromotionAvailed={setNoPromotionAvailed}
                                                                setSelServGroups={setSelServGroups}
                                                                serviceGroupArray={select_service_serviceGroups}
                                                                onUpdate={(
                                                                    { name, value },
                                                                    extraValues,
                                                                    book,
                                                                    online_slot,
                                                                    additional_data
                                                                ) => {
                                                                    let ex_data = extraValues ? extraValues : {};
                                                                    setAppointmentData({
                                                                        ...appointmentData,
                                                                        appointments: [
                                                                            ...appointmentData.appointments.map(
                                                                                (appmnt, ap_i) => {
                                                                                    if (ap_i == index) {
                                                                                        if (name === "service") {
                                                                                            return {
                                                                                                ...appmnt,
                                                                                                client_can_book: book,
                                                                                                slot_availible_for_online: online_slot,
                                                                                                srv_name: additional_data?.srv_name,
                                                                                                srv_duration: additional_data?.srv_duration,
                                                                                                price: additional_data?.price,
                                                                                                discount_price: additional_data?.discount_price,
                                                                                                duration: additional_data?.srv_duration,
                                                                                                [name]: value,
                                                                                                ...ex_data,
                                                                                            };
                                                                                        } else {
                                                                                            return {
                                                                                                ...appmnt,
                                                                                                [name]: value,
                                                                                                ...ex_data,
                                                                                            };
                                                                                        }
                                                                                    }
                                                                                    return appmnt;
                                                                                }
                                                                            ),
                                                                        ],
                                                                    });
                                                                }}
                                                            />
                                                        </>
                                                    );
                                                })}

                                            <div className="flex gap-6">
                                                {/* <div className="relative">
                                                    <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                                                        <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                                                    </div>
                                                    {appointmentData &&
                                                        appointmentData.appointments &&
                                                        appointmentData.appointments.length > 0 && (
                                                            <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                                                        )}
                                                </div> */}
                                                <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4 bg-white">
                                                    <div className="grid lg:grid-cols-2 gap-4">
                                                        <Dropwdown
                                                            title={selectedLanguage?.name == "English" ? "Time" : translation[1728]?.value ? translation[1728]?.value : "Time"}
                                                            placeholder={selectedLanguage?.name == "English" ? "Select Time" : translation[130]?.value ? translation[130]?.value : "Select Time"}
                                                            required={true}
                                                            name={"date_time"}
                                                            value={inp_data?.date_time}
                                                            onChange={(e) => {
                                                                let sel_data = {
                                                                    ...inp_data,
                                                                    [e.target.name]: e.target.value,
                                                                };
                                                                setInpData(sel_data);
                                                                if (e.target.value && inp_data.service) {
                                                                    addNewAppointment(sel_data);
                                                                }
                                                            }}
                                                            options={SORTED_TIME}
                                                        />
                                                        <div>
                                                            <p className="mb-2 text-sm">
                                                                {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}<span class="text-red-600"> *</span></p>


                                                            {dropdown == "False" ? (
                                                                <div
                                                                    className="cursor-pointer inputdesign overflow-hidden overflow-x-auto lg:max-h-9  "
                                                                    onClick={() => {
                                                                        setSelectServicesValue(!SelectServicesValue)
                                                                        // if (appointmentData?.appointmnet_service?.filter(item => !["Void", "Started", "Finished"].includes(item?.status))?.length == 0) {
                                                                        //     setSelectServicesValue(!SelectServicesValue)
                                                                        // } else {
                                                                        //     setSelectServices(!SelectServices);
                                                                        // }
                                                                    }}
                                                                >
                                                                    <p className={`!text-black placeholder`}>
                                                                        {select ?
                                                                            select :
                                                                            selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"
                                                                        }
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                    <div className='flex justify-end items-center gap-3'>
                                        {/* <LoginBtn
                                                text="Appointment Log"
                                                onClick={() => {
                                                    setAppointmentLogPopup(true);
                                                    handleAppointmentLog(appointmentData?.id)
                                                }}
                                            /> */}
                                        <LoginBtn
                                            text={
                                                action === "edit" ?
                                                    selectedLanguage?.name == "English" ? "Update Appointment" : translation[226]?.value ? translation[226]?.value : "Update Appointment"
                                                    :
                                                    action === "reschedul" ?
                                                        selectedLanguage?.name == "English" ? "Confirm Reschedule" : translation[223]?.value ? translation[223]?.value : "Confirm Reschedule"
                                                        :
                                                        ""
                                            }
                                            loading={btn_loading}
                                            // disabled={(Object.keys(appointmentData).length == 0 || !appointmentData.appointment_date || appointmentData.appointments?.find(itm => (!itm.date_time || !itm.service || !itm.member || !itm.duration))) ? true : false}
                                            onClick={() => {
                                                update_appointment()
                                            }}
                                        />

                                    </div>
                                </div>
                            }



                            <div className='lg:col-span-2 flex flex-col gap-6'>
                                <SupportCard />
                            </div>

                        </div>
                    </div>
                }
            </div>

            <Popup heading={`Booking Id: #${appointmentLog ? appointmentLog[0]?.id?.split("-")[0].split("").slice(0, 6).join("") : "-------"}`} close={setAppointmentLogPopup} open={appointmentLogPopup}>
                {appointmentLog?.map((log, i) => (
                    <div className="flex flex-col gap-3">
                        <div className="border rounded-lg">
                            <div className="px-5 py-3 bg-[#ECF3FF]">
                                <div className="grid grid-cols-4 gap-3">
                                    <div className="flex flex-col gap-1 justify-center">
                                        <p className="text-[#101928]/50 text-xs">
                                            {selectedLanguage?.name == "English" ? "Log Type" : translation[174]?.value ? translation[174]?.value : "Log Type"}
                                        </p>
                                        <p className="text-[#101928] text-sm">{log?.log_type}</p>
                                    </div>
                                    <div className="flex flex-col gap-1 justify-center">
                                        <p className="text-[#101928]/50 text-xs">
                                            {selectedLanguage?.name == "English" ? "Logged By" : translation[175]?.value ? translation[175]?.value : "Logged By"}
                                        </p>
                                        <p className="text-[#101928] text-sm">{log?.logged_by ? log?.logged_by : "------"}</p>
                                    </div>
                                    <div className="flex flex-col gap-1 justify-center">
                                        <p className="text-[#101928]/50 text-xs">
                                            {selectedLanguage?.name == "English" ? "Date & Time" : translation[164]?.value ? translation[164]?.value : "Date & Time"}
                                        </p>
                                        <p className="text-[#101928] text-sm">{moment(log?.created_at).format('DD MMMM YYYY')} at {moment(log?.created_at).format('hh:mm:ss')}</p>
                                    </div>
                                    <div className="flex flex-col gap-1 justify-center">
                                        {clickedLogIndex === i ?
                                            <LoginBtn key={i} onClick={() => setClickedLogIndex(-1)}>
                                                {selectedLanguage?.name == "English" ? "Click to close" : translation[177]?.value ? translation[177]?.value : "Click to close"}
                                            </LoginBtn> :
                                            <LoginBtn key={i} onClick={() => setClickedLogIndex(i)}>
                                                {selectedLanguage?.name == "English" ? "Log Details" : translation[176]?.value ? translation[176]?.value : "Log Details"}
                                            </LoginBtn>
                                        }
                                    </div>
                                </div>
                            </div>
                            {clickedLogIndex === i ? log?.log_details?.map((detail, ind) => (
                                <div key={ind}>
                                    <div className="py-2 px-5">
                                        <h1 className="font-semibold text-lg">
                                            {selectedLanguage?.name == "English" ? "Log Details" : translation[176]?.value ? translation[176]?.value : "Log Details"}:
                                        </h1>
                                    </div>
                                    <div className="bg-[#FBFCFF] px-5 py-2 grid grid-cols-4 gap-3 text-sm text-[#101928]/50">
                                        <h1>{selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}</h1>
                                        <h1>{selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}</h1>
                                        <h1>{selectedLanguage?.name == "English" ? "Start Time" : translation[73]?.value ? translation[73]?.value : "Start Time"}</h1>
                                        <h1>{selectedLanguage?.name == "English" ? "Assigned Staff" : translation[178]?.value ? translation[178]?.value : "Assigned Staff"}</h1>
                                    </div>
                                    <div className="border-b px-5 py-3 grid grid-cols-4 gap-3 text-sm text-[#101928]">
                                        <h1>{detail?.service}</h1>
                                        <h1>{detail?.duration}</h1>
                                        <h1>{moment(detail?.appointment_time, "HH:mm:ss").format("hh:mm:ss A")}</h1>
                                        <h1>{detail?.assigned_staff}</h1>
                                    </div>
                                </div>
                            )) : null}

                        </div>
                    </div>
                ))}
                {/* {appointmentLog?.map((log, i) => (
                    <div key={i}>
                        <div className="flex flex-col gap-4 mb-[2rem]">
                            <h1>Logged Type</h1>
                            <p>{log?.log_type}</p>
                            <h1>Logged By</h1>
                            <p>{log?.logged_by ? log?.logged_by : "------"}</p>
                            <h1>Date & Time</h1>
                            <p>{moment(log?.created_at).format('yyy mm ddd HH:mm:ss')}</p>
                            <h1>Log Details</h1>
                            <LoginBtn key={i} onClick={() => setClickedLogIndex(i)}>
                                Click to view
                            </LoginBtn>
                        </div>
                        {clickedLogIndex === i ? log?.log_details?.map((detail, ind) => (
                            <div className="flex flex-col gap-4 mt-[2rem] mb-[2rem]" key={ind}>
                                <h1>Log Details:</h1>
                                <div className="flex flex-col gap-4">
                                    <p>Service:<span>{detail?.service}</span></p>
                                    <p>Duration:<span>{detail?.duration}</span></p>
                                    <p>Start Time:<span>{detail?.appointment_time}</span></p>
                                    <p>Assigned Staff:<span>{detail?.assigned_staff}</span></p>
                                </div>
                            </div>
                        )) : null}
                    </div>
                ))} */}
            </Popup>
            {
                <Popup
                    no
                    size="md"
                    heading={selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"}
                    open={SelectServicesValue}
                    close={() => setSelectServicesValue(false)}
                >
                    <div className="flex flex-col gap-4 max-h-[40vh]">
                        <LoginInput
                            title=""
                            placeholder={selectedLanguage?.name == "English" ? "Search Service" : translation[1266]?.value ? translation[1266]?.value : "Search Service"}
                            padding="py-1"
                            leftIcon={<Svgs.Search />}
                            value={search}
                            onChange={searchfunction}
                        />
                        {select_service_serviceGroups?.length > 0 ? (
                            <>
                                {select_service_serviceGroups?.map((srv) => {
                                    return (
                                        <div className="flex flex-col gap-2">
                                            <h1 className="font-semibold text-primary">
                                                {srv?.name ? srv?.name : ""}
                                            </h1>
                                            {srv?.services?.length > 0 && srv?.services?.map((itm) => {
                                                const filteredServices = itm?.priceservice?.filter(itm => itm?.currency_name?.toLowerCase() == location_currency?.toLowerCase())
                                                return (
                                                    <>
                                                        {filteredServices?.map((itm_srv) => {
                                                            let itmSrv = { ...itm_srv }
                                                            return (
                                                                <div
                                                                    className="flex items-center justify-between gap-3 border-b py-2 px-1 hover:bg-gray-50 rounded-md cursor-pointer"
                                                                    onClick={() => {
                                                                        handleServices(itm?.id, {
                                                                            srv_name: itm?.name,
                                                                            price: itmSrv?.discount_price || itmSrv?.discount_price == 0 ? itmSrv?.discount_price : itmSrv.price,
                                                                            srv_duration: itmSrv?.duration
                                                                                .toLowerCase()
                                                                                .replaceAll("_", " "),
                                                                        });
                                                                        let sel_data = {
                                                                            ...inp_data,
                                                                            service: itm?.id,
                                                                            client_can_book: itm?.client_can_book,
                                                                            slot_availible_for_online: itm?.slot_availible_for_online,
                                                                            duration: itmSrv?.duration.toLowerCase().replaceAll("_", " "),
                                                                            srv_name: itm?.name,
                                                                            price: itmSrv.price,
                                                                            srv_duration: itmSrv?.duration
                                                                                .toLowerCase()
                                                                                .replaceAll("_", " "),
                                                                        };
                                                                        setInpData(sel_data);
                                                                        if (itm?.id && inp_data.date_time) {
                                                                            addNewAppointment(sel_data);
                                                                        }
                                                                        setSelectServicesValue(!SelectServicesValue);
                                                                    }}
                                                                >
                                                                    <div className="">
                                                                        <h1 className="text-primary">
                                                                            {itm?.name}
                                                                        </h1>
                                                                        <p className="text-xs text-[#949494]">
                                                                            {itmSrv?.duration.replaceAll("_", " ")}
                                                                        </p>
                                                                    </div>
                                                                    <div className="d-flex flex-col text-end">
                                                                        <p className="text-primary text-sm font-semibold">
                                                                            {selectedCurrency ? selectedCurrency : ""}{" "}
                                                                            {itmSrv?.price?.toFixed(2)}
                                                                        </p>
                                                                        {(itmSrv?.discount_price || itmSrv?.discount_price == 0) &&
                                                                            <p className="text-xs text-[#949494]">
                                                                                {selectedLanguage?.name == "English" ? "Discount Price" : translation[245]?.value ? translation[245]?.value : "Discount Price"} :
                                                                                {selectedCurrency ? selectedCurrency : ""}{" "}
                                                                                {itmSrv?.discount_price?.toFixed(2)}
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="flex flex-col gap-4 items-center text-center">
                                <p className="text-sm text-[#000]/40">
                                    {selectedLanguage?.name == "English" ? "You have not added any services yet." : translation[246]?.value ? translation[246]?.value : "You have not added any services yet."}
                                    <br className="hidden md:block" />
                                    {selectedLanguage?.name == "English" ? "Click on the button below to add new services." : translation[247]?.value ? translation[247]?.value : "Click on the button below to add new services."}

                                </p>
                                <div>
                                    <AddService
                                        formRef={formRef}
                                        setServiceGroup={setServiceGroup}
                                        setSelectServices={setSelectServices}
                                        ServiceType={ServiceType}
                                        setServiceType={setServiceType}
                                        serviceData={serviceData}
                                        isEdit={isEdit}
                                        setService={setService}
                                        serviceHandleChange={serviceHandleChange}
                                        onSubmitService={onSubmitService}
                                        active={active}
                                        onBlurHandler={onBlurHandler}
                                        errors={errors}
                                        removedSelectedEmployee={removedSelectedEmployee}
                                        serviceModal={serviceModal}
                                        setAddService={setAddService}
                                        durationOptions={durationOptions}
                                        setIsEdit={setIsEdit}
                                        setErrors={setErrors}
                                        setIsDelete={setIsDelete}
                                        loading={loading}

                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Popup>
            }

            {
                ServiceGroup && (
                    <AddServiceGroup
                        onClose={() => {
                            setServiceGroup(false);
                        }}
                        open={ServiceGroup}
                        update={false}
                        addSuccess={() => {
                            dispatch(getServiceGroupCalendar(selected_location, setGroupLoader));
                        }}
                    />
                )
            }
        </>
    )
}

export default AppointmentEdit