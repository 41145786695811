import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "Screens/TenantApp/Dashboard";
import Home from "Components/TenantApp/Home";
import { tenantRoutes } from "./helper";
import { useTenant } from "Hooks/useTenant";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { fetchBusiness, selectedLocation } from "Redux/TenantRedux/Actions/locationActions";
import { PageLoader } from "Components/Utility/Loader";
import {
  getAllBusinessLanguages,
  getBusinessByDomain,
  getBusinessById,
  getLocations,
  getUser,
  get_language_translation,
} from "Adapters/Api/index";
import { getCurrencyList } from "Adapters/Api/currency";
import { change_color } from "Redux/Utility/Actions/ThemeActions";
import { getBusinessTheme } from "Adapters/Api/themeCustomization";
import EmployeePassword from "Components/TenantApp/EmployeeSetPassword/EmployeePassword";
import { get_user_details, get_user_permissions } from "Redux/TenantRedux/Actions/UserActions/UserActions";
import { addLanguages, getLanguagesTranslation, selected_language } from "Redux/TenantRedux/Actions/languageActions";
import { getMultiLanguageInvoice } from "Adapters/Api/multiLanguageInvoice";


const RouteList = () => {
  const { storeDataInLocalStorage, getDataFromLocalStorage } =
    useSetDataInLocalStorage();
  const dispatch = useDispatch();
  const location = useLocation();
  const { tenant } = useTenant();
  const state = useSelector((state) => state);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const reduxInvoices = state?.multilanguageInvoices?.invoices

  let user_id = getDataFromLocalStorage("user_id");
  let isEmployee = getDataFromLocalStorage("isEmployee");
  let access_token = getDataFromLocalStorage("access_token");

  useEffect(() => {
    setEmployeePermissions(state?.user?.permissions);
    setEmployee(state?.user?.user?.employee)
  }, [state?.user]);


  const getBusiness = async () => {
    const access_token = getDataFromLocalStorage("access_token");
    const user_id = getDataFromLocalStorage("user_id");
    const isEmployee = getDataFromLocalStorage("isEmployee");

    if (user_id && access_token) {
      const response = await getBusinessById(user_id, isEmployee ? isEmployee : false, access_token);
      if (response.status === 200) {
        let business = response?.data?.response?.business;
        dispatch(fetchBusiness(business));
        business?.id && dispatch(getLocations(business?.id));
      }
    }
  };

  const getBusinessByDomainName = async () => {
    const response = await getBusinessByDomain(tenant);

    if (response?.status === 200) {
      const business_data = response.data.response.business;
      storeDataInLocalStorage("business_data", business_data);
      storeDataInLocalStorage("hash", business_data?.tenant_id);
      getBusiness();
      getBusinessThemeData(business_data?.id);
      getBusinessLanguagesList(business_data?.id);
    } else {
      window.location = `http://${process.env.REACT_APP_SERVER_APPLICATION_DOMAIN}`;
    }
  };

  // get business theme data
  const getBusinessThemeData = async (business_id) => {
    const response = await getBusinessTheme(business_id);
    if (response.status === 200) {
      const businessTheme = response?.data?.response?.theme;
      dispatch(
        change_color({
          label: businessTheme?.theme_name,
          primary: businessTheme?.primary_color,
          secondary: businessTheme?.secondary_color,
        })
      );
    }
  };


  const getUserdetails = async (user_id) => {
    const response = await getUser(user_id, isEmployee ? isEmployee : false, access_token);
    if (response.status === 200) {
      const userData = response?.data?.response?.data;
      storeDataInLocalStorage("userData", userData);
      dispatch(get_user_details(response.data.response.data));
      let employee_assigned_location = response?.data?.response?.selected_location
      if (employee_assigned_location) {
        let localstorage_location = localStorage.getItem('selected_location')
        if (localstorage_location) {
          localstorage_location = localstorage_location.replaceAll('"', '')
          if (localstorage_location != employee_assigned_location) {
            localStorage.setItem('selected_location', JSON.stringify(employee_assigned_location))
          }
        }
      }
      const employeePermissions = response?.data?.response?.permissions;
      storeDataInLocalStorage("employeePermissions", employeePermissions);
      dispatch(get_user_permissions(response.data.response.permissions));
    }
  };


  const getTranslation = async (name) => {
    const response = await get_language_translation(name)
    if (response.status === 200) {
      const data = response?.data?.response?.data;
      dispatch(getLanguagesTranslation(data))
    }
  }

  const getInovices = () => {
    const success = () => { }
    const fail = () => { }
    dispatch(getMultiLanguageInvoice(access_token, success, fail))
  }
  useEffect(() => {
    if (reduxInvoices?.length == 0) {
      getInovices()
    }
  }, [reduxInvoices?.length])

  useEffect(() => {
    let this_user_id = getDataFromLocalStorage("user_id");
    if (this_user_id) {
      getUserdetails(this_user_id)
      getBusinessByDomainName();
    }
  }, [state.user.loggedIn])



  useEffect(() => {
    dispatch(getCurrencyList());
  }, []);


  useEffect(() => {
    let id = getDataFromLocalStorage("selected_location")
    if (id) {
      id = id?.replaceAll('"', '')
      dispatch(
        selectedLocation(id)
      )
    }
  }, [])
  const all_languages = useSelector((state) => state.languages.languages);

  useEffect(() => {
    if (all_languages?.length > 0) {
      localStorage.setItem("selected_language", JSON.stringify(all_languages[0]?.language))
    }
  }, [])


  useEffect(() => {
    if (state?.languages?.selected_language?.name) {
      getTranslation(state?.languages?.selected_language?.name)
    } else {
      if (!state?.languages?.selected_language?.name && all_languages[0]?.language?.name) {
        getTranslation(all_languages[0]?.language?.name)
      }
    }
  }, [state?.languages?.selected_language?.name])

  // get business languages
  const getBusinessLanguagesList = async (business_id) => {
    const response = await getAllBusinessLanguages(business_id);
    if (response.status === 200) {
      const businessLanguages = response?.data?.response?.languages;

      // dispatch action to store default(single language used for complete theme) business language
      dispatch(selected_language(businessLanguages[0]?.language))
      // dispatch action to store business languages in redux (dont remove)
      dispatch(addLanguages(businessLanguages));
    }
  };


  return (
    <>

      <Routes>
        <Route path="/authentication/login/auto_login/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />}>
          {tenantRoutes?.map(({ path, element }, index) => (
            <Route path={path} element={element} key={index} />
          ))}
        </Route>
        <Route path="/set-password" element={<EmployeePassword />} />

        <Route path="*" element={<Navigate to={`/dashboard`} />} />
      </Routes>
    </>
  );
};

export default RouteList;
