import Svgs from 'Assets/svgs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import CircleIcon from '../../../Elements/CircleIcon'
import LoginInput from '../../../Elements/LoginInput'
import Layout from '../Layout'
import useAdminBooking from '../helper';
import { LOCATION } from 'Redux/TenantRedux/ActionTypes/locationTypes';

const FinancialSetting = ({ count, setStepCount, StepCount }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        translation,
        selectedLanguage,
        locationInfo,
        setLocationInfo,
        handleChange,
        errors,
        formRef
    } = useAdminBooking()

    return (
        <Layout
            title={selectedLanguage?.name == "English" ? "Setup your business profile" : translation[81]?.value ? translation[81]?.value : "Setup your business profile"}
            heading={selectedLanguage?.name == "English" ? "Add your financial details" : translation[82]?.value ? translation[82]?.value : "Add your financial details"}
            subHeading={selectedLanguage?.name == "English" ? "Add key details about your business to display on your online booking profile." : translation[83]?.value ? translation[83]?.value : "Add key details about your business to display on your online booking profile."}
            count={count}
            NextClick={() => {
                console.log(locationInfo, "shs")
                setStepCount(StepCount + 1)
                dispatch({
                    type: "STORE_CURRENT_LOCATION_DATA",
                    payload: locationInfo
                })
            }}
            backClick={() => {
                setStepCount(StepCount - 1)
            }}
            btnInner={selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
        >
            <div className='flex flex-col gap-4' ref={formRef}>
                <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
                    <CircleIcon className='!bg-[#5679FF]/20 !rounded-md'>
                        <Svgs.Business_info_outline />
                    </CircleIcon>
                    <div className="flex flex-col gap-1 lg:text-left text-center">
                        <h2 className="text-primary font-semibold text-xl">
                            {locationInfo?.address_name}
                        </h2>
                        <p className="text-[#7B7B7B] text-sm">
                            {locationInfo?.address}
                        </p>
                    </div>
                </div>
                <h2 className="font-semibold">Financial Settings</h2>
                <LoginInput
                    required={false}
                    name={"finance_email"}
                    error={errors?.finance_email}
                    value={locationInfo?.finance_email}
                    onChange={handleChange}
                    title={"Financial Email"}
                    placeholder={"Enter Financial Email"}
                />
                <h2 className="font-semibold">Bank Details</h2>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                    <LoginInput
                        required={false}
                        name={"bank_name"}
                        error={errors?.bank_name}
                        value={locationInfo?.bank_name}
                        onChange={handleChange}
                        title={"Bank Name"}
                        placeholder={"Enter Bank Name"}
                    />
                    <LoginInput
                        required={false}
                        name={"branch_name"}
                        error={errors?.branch_name}
                        value={locationInfo?.branch_name}
                        onChange={handleChange}
                        title={"Branch Name"}
                        placeholder={"Enter Branch Name"}
                    />
                </div>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                    <LoginInput
                        required={false}
                        name={"iban_no"}
                        error={errors?.iban_no}
                        value={locationInfo?.iban_no}
                        onChange={handleChange}
                        title={"IBAN"}
                        placeholder={"Enter IBAN"}
                    />
                    <LoginInput
                        required={false}
                        name={"account_no"}
                        error={errors?.account_no}
                        value={locationInfo?.account_no}
                        onChange={handleChange}
                        title={"Account Number"}
                        placeholder={"Enter Account Number"}
                    />
                </div>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
                    <LoginInput
                        required={false}
                        name={"trn_no"}
                        error={errors?.trn_no}
                        value={locationInfo?.trn_no}
                        onChange={handleChange}
                        title={"Tax Registration Number"}
                        placeholder={"Enter Tax Registration Number"}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default FinancialSetting