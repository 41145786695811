import { combineReducers } from "redux";

import UtilityRootReducer from "../Utility/UtilityRootReducer";
import AppointmentReducer from "./Reducers/Appointment/Appointment";
import AttendanceReducers from "./Reducers/attendanceReducer";
import BrandReducer from "./Reducers/Brand/BrandReducer";
import BusinessSettingsReducers from "./Reducers/businessSettingsReducers";
import CategoryReducer from "./Reducers/Categories/CategoryReducer";
import ClienGrouptReducer from "./Reducers/clientGroupReducer";
import ClientReducer from "./Reducers/clientReducer";
import IncrementReducer from "./Reducers/countReducer/IncrementReducer";
import EmployeeReducer from "./Reducers/employeeReducer";
import FinancialSettingsReducer from "./Reducers/financialSettings";
import LanguageReducer from "./Reducers/languageReducer";

import NotificationReducer from "./Reducers/notificationsReducer";
import PayrollReducers from "./Reducers/payrollReducer";
import ProductReducer from "./Reducers/productReducer";
import ServiceReducer from "./Reducers/ServiceReducer";
import VoucherReducer from "./Reducers/voucherReducer";
import StaffGroupReducers from "./Reducers/staffGroup";
import UserReducer from "./Reducers/User/UserReducer";
import VendorReducer from "./Reducers/vendorsReducer";
import MembershipReducer from "./Reducers/MembershipReducer";
import SaleServicesReducer from "./Reducers/SaleServiceReducer";
import SaleOrderReducer from './Reducers/saleOrderReducer/index';
import CartItemReducer from "./Reducers/cartItemsReducer";
import DashboardReducer from "./Reducers/dashboardReducer";
import ConsumptionReducer from "./Reducers/consumptionReducer";
import PurchasesReducer from "./Reducers/purchaseOrder";
import VacationReducer from "./Reducers/vacationReducer";
import AbsenceReducer from "./Reducers/absenceReducer";
import StoreTargetReducer from "./Reducers/storeTarget";
import StaffTargetReducer from "./Reducers/staffTargetReducer";
import ServiceTargetReducer from './Reducers/serviceTargetReducer';
import RetailTargetReducer from "./Reducers/retailTargetReducer";
import ClientDiscountPromotionReducer from "./Reducers/clientDiscountPromotionReducer";
import MultiLanguageInvoices from "./Reducers/MultiLanguageInvoices";
import SegmentReducer from "./Reducers/segmentReducer";
import CampaignReducer from "./Reducers/campaignReducer";
import HolidayReducer from "./Reducers/holidayReducer";
import GiftCardReducer from "./Reducers/GiftCardReducer";
import CRMReducer from "./Reducers/CRMReducer";
import ExpenseReducer from "./Reducers/expenseReducer";
import SaleReportReducer from "./Reducers/saleReportReducer";
import maintanenceReducer from "./Reducers/maintanenceReducer";
import ComplaintReducer from "./Reducers/complaintReducer";

const TenantRootReducer = combineReducers({
  utility: UtilityRootReducer,
  counter: IncrementReducer,
  locations: BusinessSettingsReducers,
  business: BusinessSettingsReducers,
  languages: LanguageReducer,
  notification: NotificationReducer,
  financialSettings: FinancialSettingsReducer,
  product: ProductReducer,
  consumption: ConsumptionReducer,
  purchases: PurchasesReducer,
  brand: BrandReducer,
  category: CategoryReducer,
  vendors: VendorReducer,
  employee: EmployeeReducer,
  vacation: VacationReducer,
  absence: AbsenceReducer,
  staffGroup: StaffGroupReducers,
  staffTarget: StaffTargetReducer,
  attendance: AttendanceReducers,
  payroll: PayrollReducers,
  user: UserReducer,
  client: ClientReducer,
  clientGroup: ClienGrouptReducer,
  clientDiscountPromotion: ClientDiscountPromotionReducer,
  appointment: AppointmentReducer,
  service: ServiceReducer,
  voucher: VoucherReducer,
  membership: MembershipReducer,
  giftCards: GiftCardReducer,
  saleService: SaleServicesReducer,
  saleOrder: SaleOrderReducer,
  cartItem: CartItemReducer,
  dashborad: DashboardReducer,
  storeTarget: StoreTargetReducer,
  serviceTarget: ServiceTargetReducer,
  retailTarget: RetailTargetReducer,
  multilanguageInvoices: MultiLanguageInvoices,
  segments: SegmentReducer,
  campaigns: CampaignReducer,
  holidays: HolidayReducer,
  crm: CRMReducer,
  expense: ExpenseReducer,
  saleReport: SaleReportReducer,
  complaint: ComplaintReducer,
  maintanence: maintanenceReducer
});

export default TenantRootReducer;
