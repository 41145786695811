import React, { useState } from 'react'
import Svgs from '../../../Assets/svgs'
import { error_message } from '../../../Constants/Variables'

const PasswordInput = ({ hideError, onEnterSubmit, title, placeholder, name, value, onChange, onBlur, error }) => {

    const [show, setshow] = useState(false)

    const HandleKeyPress = (e) => {
        if (e.key.toLowerCase() == 'enter') {
            onEnterSubmit()
        }
    }
    return (
        <div className='flex flex-col gap-2 relative'>
            <p className='text-sm'>{title} <span className='text-red-500'> *</span></p>
            <div className='flex flex-col gap-2 relative'>
                <input
                    onKeyDown={HandleKeyPress}
                    type={show ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    // onBlur={onBlur}
                    className={`border  ${error && "!border-l-[7px] !border-[#eb3b3b]"} input w-full lg:pr-8 pr-7`}
                    placeholder={placeholder} />
                {show ? <div onClick={() => { setshow(!show) }} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer'>
                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.53125 4.5C8.53125 5.03872 8.31724 5.55538 7.93631 5.93631C7.55538 6.31724 7.03872 6.53125 6.5 6.53125C5.96128 6.53125 5.44462 6.31724 5.06369 5.93631C4.68276 5.55538 4.46875 5.03872 4.46875 4.5C4.46875 3.96128 4.68276 3.44462 5.06369 3.06369C5.44462 2.68276 5.96128 2.46875 6.5 2.46875C7.03872 2.46875 7.55538 2.68276 7.93631 3.06369C8.31724 3.44462 8.53125 3.96128 8.53125 4.5Z" fill="#A1A1A1" />
                        <path d="M0 4.5C0 4.5 2.4375 0.03125 6.5 0.03125C10.5625 0.03125 13 4.5 13 4.5C13 4.5 10.5625 8.96875 6.5 8.96875C2.4375 8.96875 0 4.5 0 4.5ZM6.5 7.34375C7.25421 7.34375 7.97753 7.04414 8.51083 6.51083C9.04414 5.97753 9.34375 5.25421 9.34375 4.5C9.34375 3.74579 9.04414 3.02247 8.51083 2.48917C7.97753 1.95586 7.25421 1.65625 6.5 1.65625C5.74579 1.65625 5.02247 1.95586 4.48917 2.48917C3.95586 3.02247 3.65625 3.74579 3.65625 4.5C3.65625 5.25421 3.95586 5.97753 4.48917 6.51083C5.02247 7.04414 5.74579 7.34375 6.5 7.34375V7.34375Z" fill="#A1A1A1" />
                    </svg>
                </div> : <div onClick={() => { setshow(!show) }} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer'>
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.25538 1.98355C7.0074 1.95679 6.75542 1.94145 6.50001 1.93764C5.3839 1.94258 4.22692 2.21341 3.13178 2.73237C2.31865 3.13356 1.52657 3.69995 0.838685 4.39859C0.500847 4.75524 0.0696692 5.27164 0 5.8226C0.00823334 6.29988 0.521658 6.88897 0.838685 7.24663C1.48373 7.91783 2.25519 8.468 3.13178 8.91285C3.16157 8.92728 3.19147 8.94153 3.22144 8.95559L2.40815 10.3725L3.51321 11.024L9.48689 0.648531L8.42321 0L7.25538 1.98355V1.98355ZM9.77777 2.69121L8.96606 4.09462C9.33949 4.5786 9.56116 5.17543 9.56116 5.8226C9.56116 7.43568 8.19051 8.74345 6.4992 8.74345C6.42609 8.74345 6.35523 8.73559 6.28338 8.73079L5.74621 9.6585C5.99395 9.68496 6.24444 9.70424 6.49999 9.70757C7.61716 9.70257 8.77349 9.42861 9.86743 8.91285C10.6806 8.51166 11.4734 7.94527 12.1613 7.24663C12.4992 6.88999 12.9303 6.37358 13 5.8226C12.9918 5.34533 12.4783 4.75624 12.1613 4.39858C11.5163 3.72738 10.744 3.17721 9.86743 2.73235C9.83784 2.71804 9.80755 2.70517 9.77777 2.69121V2.69121ZM6.49921 2.90177C6.57338 2.90177 6.64696 2.90473 6.71979 2.90968L6.09058 3.99648C5.20751 4.17509 4.54492 4.92462 4.54492 5.82182C4.54492 6.0472 4.58653 6.26297 4.66315 6.46297C4.66323 6.4632 4.66306 6.46354 4.66315 6.46377L4.03234 7.55374C3.65804 7.06932 3.43725 6.47053 3.43725 5.82259C3.43726 4.20953 4.80792 2.90176 6.49921 2.90177V2.90177ZM8.32971 5.19332L6.91181 7.64398C7.79014 7.46202 8.44795 6.7159 8.44795 5.82182C8.44795 5.60055 8.40368 5.39018 8.32971 5.19332V5.19332Z" fill="#A1A1A1" />
                    </svg>
                </div>
                }
                {
                    !hideError && (error) && (typeof error == "string") ?
                        <p className='text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'>
                            <Svgs.I fill='#eb3b3b' />{error}</p>
                        :
                        (typeof error == "boolean" && error == true)
                            ?
                            <p className='text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'>
                                <Svgs.I fill='#eb3b3b' />{error_message}</p>
                            :
                            ""
                }

            </div>

        </div>
    )
}

PasswordInput.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    onEnterSubmit: () => { },
    hideError: false
}

export default PasswordInput