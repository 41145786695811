import Svgs from 'Assets/svgs';
import Footer from 'Components/Utility/Footer';
import Navbar from 'Components/Utility/Navbar';
import React from 'react';
import useBlogDetailHelper from './helper';
import moment from 'moment';

const BlogDetail = () => {
  const { loading, blogDetail, navigation, createMarkup } = useBlogDetailHelper()
  
  return (
    <>
      <div className="flex flex-col w-full font-manrope landing_page">
        <Navbar />
        <div className="flex bg-secondary-white relative pt-6 pb-11 flex-col">
          <div className="flex gap-5 w-[95%] max-w-[1200px] mx-auto">
            <button onClick={() => navigation(-1)}><Svgs.CircleBack /></button>
            {loading
              ? <div className="flex w-full relative gap-4 flex-col">
                <h1 className='w-full h-16 rounded-xl bg-gray-200 animate-pulse' />
                <div className='w-2/3 h-8 rounded-xl bg-gray-200 animate-pulse' />
              </div>
              :
              <div className="flex relative gap-4 flex-col">
                <h1 className='text-[2.75rem] font-bold text-primary-black '>{blogDetail?.subject}</h1>
                <div className="flex gap-1.5 items-center">
                  <div className="bg-primary-blue text-white px-4 font-semibold text-sm rounded-full py-1.5">{blogDetail?.category_title}</div>
                  <p className='text-sm text-primary-black'>{moment(blogDetail?.created_at).format('MMMM DD, YYYY')}</p>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="w-full max-w-5xl mx-auto px-4 py-8">
          {
            loading
              ? <div className="flex w-full relative gap-1 flex-col">
                <div className='w-full h-8 rounded-xl bg-gray-200 animate-pulse' />
                <div className='w-2/3 h-8 rounded-xl bg-gray-200 animate-pulse' />
                <div className='w-full h-40 rounded-xl bg-gray-200 animate-pulse' />
                <div className='w-full h-8 rounded-xl bg-gray-200 animate-pulse' />
                <div className='w-full h-8 rounded-xl bg-gray-200 animate-pulse' />
                <div className='w-full h-8 rounded-xl bg-gray-200 animate-pulse' />
                <div className='w-2/3 h-8 rounded-xl bg-gray-200 animate-pulse' />
              </div>
              :
              <div
                className='flex w-full flex-col'
                dangerouslySetInnerHTML={createMarkup(blogDetail?.message)}
              />
          }
          
        </div>

        <Footer />
      </div>
    </>
  );
};

export default BlogDetail;