import React from "react";
import LocationSelection from "new-components/location";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import useSalesReportHelper from "./helper";
// import ProductSaleReport from "./Tabs/ProductReport";
import AppointmentSaleSummaryReport from "./Tabs/AppointmentReport";
import ProductSaleSummaryReport from "./Tabs/ProductReport";

const SalesSummaryReports = () => {
    const {
        setSearchParams, searchParams, tabList, active_tab, translation, selectedLanguage, storeDataInLocalStorage, topRef
    } = useSalesReportHelper();

    let tabsList = {
        appointment: <AppointmentSaleSummaryReport />,
        product: <ProductSaleSummaryReport />,
    };

    return (
        <div ref={topRef} className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                url={'/dashboard/reports'}
                second={"Sales Summary"}
            />

            <div className="flex items-center justify-between sm:gap-4 gap-2 my-[1rem]">
                <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                    Sales Summary
                </h2>

                <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
            </div>
            <div className={"border-b mt-[2rem]"}>
                <div className="flex items-center gap-6">
                    {tabList?.length > 0 &&
                        tabList.map((tab, index) => {
                            return (
                                <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${active_tab == tab.value && "font-semibold border-primary"}`}
                                    onClick={() => {
                                        setSearchParams({ ...searchParams, active_tab: tab.value });
                                    }}
                                    key={index}
                                >
                                    <h2 className="text-primary capitalize">{tab.label}</h2>
                                </div>
                            );
                        })}
                </div>
            </div>

            <div className="mt-[2rem] mb-[3rem]">{active_tab && tabsList[active_tab]}</div>
        </div>
    );
};

export default SalesSummaryReports;
