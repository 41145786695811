import moment from "moment";

export const exportCSVFile = (data, exportName) => {
    // Get current date & time in "DD-MM-YYYY hh:mm A" format
    const formattedDate = moment().format("DD-MM-YYYY hh:mm A");

    // Create file name dynamically
    const fileName = `${exportName} Data [ ${formattedDate} ].csv`;

    // Create a Blob URL for the CSV data
    const url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Dynamic file name
    document.body.appendChild(link);
    link.click();
    link.remove();
};
