import Svgs from 'Assets/svgs';
import React from 'react'
import FloatInput from './FloatInput';

const PaymentCheckboxCard = ({
  title,
  id,
  checked,
  onClick,
  error,
  padding,
  disabled,
  onChange,
  totalPaymentMethods,
  leftIcon,
  currency,
  onInputChange,
  inputValue,
  restrictedAmount,
  inputField,
  inputDisabled,
  maxCharactersInput,
  errorClass,
  inputClass,
  amount,
  checkboxClass,
  firstLetterGreaterThanZero = false,
  customDisable,
  isCustomDisable
}) => {
  const uniqueID = id ? id : crypto.randomUUID();
  return (
    <div>
      <div
        onClick={disabled ? () => { } : onClick && onClick}
        className={`bg-white ${disabled && "opacity-[0.3] cursor-not-allowed"
          }  cursor-pointer border rounded-lg  ${error && " border-[#eb3b3b]"
          } ${checked ? " border border-solid border-[#5679ff] bg-[#F8FBFB]" : ""
          } pr-6 ${padding ? padding : ""
          } ${checkboxClass ? "pl-6" : ""} flex relative items-center gap-3 min-h-[2.8rem] h-[2.8rem]`}>
        <div className={`${checkboxClass} payment-checkbox h-full rounded-l-md ${checked ? error ? 'bg-[#eb3b3b]' : 'bg-[#5679ff] ' : 'bg-[#A1A1A1]'}`}>
          <input
            type="checkbox"
            name={'payment_method'}
            value={uniqueID}
            id={`checkbox-${uniqueID}`}
            onChange={(e) => onChange && onChange(e)}
            checked={checked ? checked : false}
            disabled={disabled}
          />
          <label className='my-3' htmlFor={`checkbox-${uniqueID}`}></label>
        </div>
        <div className="flex items-center justify-between w-full gap-4">
          <div className="flex items-center gap-2 py-3">
            {leftIcon}
            <p className="font-semibold text-sm">{title}</p>
          </div>
          {inputField &&
            <div className="flex items-center gap-2">
              <FloatInput
                type="text"
                charactersMaxLength={maxCharactersInput}
                title={""}
                restrictedAmount={restrictedAmount}
                disabled={isCustomDisable ? customDisable : !checked || inputDisabled}
                value={inputValue}
                name={'amount'}
                paymentId={uniqueID}
                totalPaymentMethods={totalPaymentMethods}
                onChange={(e) => onInputChange && onInputChange(e)}
                firstLetterGreaterThanZero={firstLetterGreaterThanZero}
                inputPadding={`${inputClass} md:!w-[4.5625rem] !w-[6rem] !h-[1.625rem] border border-solid border-[#5679ff] p-1`}
                parentClass=' rounded-md  outline-none'
              />
              <span className='text-sm font-medium'>{currency ? currency : 'AED'}</span>
            </div>
          }
          {amount &&
            <div className="flex items-center gap-2">
              <p className="font-semibold text-sm">{amount.toFixed(2)}</p>
              <span className='text-sm font-medium'>{currency ? currency : 'AED'}</span>
            </div>
          }
        </div>
      </div>
      {error &&
        <p className={`text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] z-30 w-full justify-end ${errorClass}`}>
          <Svgs.I fill={'#eb3b3b'} /> {error}
        </p>
      }
    </div>
  )
}

PaymentCheckboxCard.defaultProps = {
  onChange: () => { },
};

export default PaymentCheckboxCard