import React from "react";
import { CSVLink } from "react-csv";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LocationSelection from "new-components/location";
import Svgs from "Assets/svgs";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoAppointmentRecordFound from "Components/Utility/NoAppointmentRecordFound";
import moment from "moment";
import useAppointmentsHelper from "./helper";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import { CALENDAR_OPENING_TIME } from "Constants/Data/OpeningHours";
import Popup from "Components/TenantApp/Elements/Popup";
import Textarea from "Components/TenantApp/Elements/Textarea";

const Appointments = () => {
    const { selectedLanguage, translation, loader, allCount, toggleDateRange, setToggleDateRange, startDate,
        setStartDate, endDate, setEndDate, setSearch, Search, exportLoader, employee, csvReport, handleExportClick,
        all_appointments_no_pagination, all_appointments, selectedLocationCurrency, currentPage, count, PageSize,
        setCurrentPage, ShowDetails, setShowDetails, StatusOptions, dropdownStatus, setDropdownStatus, loadingPermission,
        cancelReasonPopup, setCancelReasonPopup, cancelReason, setCancelReason
    } = useAppointmentsHelper()

    return (
        <>
            <div className="">
                <div className="">
                    <div className="border rounded-md bg-white">
                        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-1 gap-2">
                                <div className="flex items-center sm:gap-4 gap-2 ">
                                    <h2 className="font-semibold sm:text-xl text-lg whitespace-nowrap">
                                        {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                    </h2>

                                    {loader ?
                                        <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                            <p className="rounded-md h-5 w-16 mx-1"> </p>
                                        </div>
                                        :
                                        <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                            <p className="text-primary">{allCount} Appointments</p>
                                        </div>
                                    }
                                </div>

                                {loadingPermission ?
                                    <EmployeesHeadShimmer classOn1={'!w-24 lg:!w-32 sm:!w-28'} classOn2={'!w-28 lg:!w-36 sm:!w-32'} />
                                    :
                                    <div className="mb-00 flex items-center gap-2">
                                        <div className="mb-00">
                                            <Dropwdown
                                                title=""
                                                value={dropdownStatus}
                                                onChange={(e) => setDropdownStatus(e.target.value)}
                                                showTitle={false}
                                                placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[1433]?.value ? translation[1433]?.value : "Select Status"}
                                                options={StatusOptions}
                                            />
                                        </div>
                                        <LoginInput
                                            title=""
                                            placeholder={selectedLanguage?.name == "English" ? "Search Appointment" : translation[1269]?.value ? translation[1269]?.value : "Search Appointment"}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                            onChange={(e) => { setSearch(e.target.value) }}
                                            value={Search}
                                            name="firstName"
                                        />
                                    </div>
                                }
                            </div>
                            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                                <div className='flex items-center gap-3'>
                                    <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                        <div className='p-2 relative gap-4 flex items-center'>
                                            <p onClick={() => {
                                                setToggleDateRange(!toggleDateRange)
                                            }} className='cursor-pointer'>
                                                {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                            </p>
                                            <span className='cursor-pointer' onClick={() => {
                                                setStartDate()
                                                setEndDate()
                                            }}>Clear</span>

                                            {
                                                toggleDateRange && <>
                                                    <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                                    <div className='absolute top-full right-0 z-[2]'>
                                                        <DateRangePicker
                                                            start={startDate}
                                                            end={endDate}
                                                            setStartDate={setStartDate}
                                                            setEndDate={setEndDate}
                                                            setToggleDateRange={setToggleDateRange}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {exportLoader ? (
                                    <div className="h-[4rem] flex items-center justify-center">
                                        <PreloaderSm />
                                    </div>
                                ) :
                                    !employee ? (
                                        all_appointments_no_pagination?.length > 0 ?
                                            <CSVLink
                                                {...csvReport}
                                                target="_blank"
                                                onClick={handleExportClick}
                                            >
                                                <LoginBtn
                                                    className="rounded-lg !py-[7px]"
                                                    bg="bg-white"
                                                    animation={false}
                                                    customThemeBtn={'theme-btn2'}
                                                >
                                                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                        <Svgs.ExportSvg />
                                                    </div>
                                                </LoginBtn>
                                            </CSVLink> :
                                            <LoginBtn
                                                className="rounded-lg !py-[7px]"
                                                bg="bg-white"
                                                animation={false}
                                                onClick={handleExportClick}
                                                customThemeBtn={'theme-btn2'}
                                            >
                                                <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                    <Svgs.ExportSvg />
                                                </div>
                                            </LoginBtn>
                                    ) : (
                                        ""
                                    )}
                            </div>
                        </div>
                        <div className="py-3">
                            {loader ?
                                <TableShimmer_v2 cols={5} rowHeight={'h-9'} />
                                :
                                <div className="overflow-x-auto">
                                    <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                        <p>{selectedLanguage?.name == "English" ? "Appointment ID" : translation[1344]?.value ? translation[1344]?.value : "Appointment ID"} #</p>
                                        <p>{selectedLanguage?.name == "English" ? "Appointment Date" : translation[1345]?.value ? translation[1345]?.value : "Appointment Date"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Client Type" : translation[330]?.value ? translation[330]?.value : "Client Type"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Client Name" : translation[474]?.value ? translation[474]?.value : "Client Name"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                                    </div>
                                    <div
                                        className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                                    >
                                        {all_appointments?.length > 0 ? (
                                            all_appointments?.map((item, index) => {
                                                return (
                                                    <>
                                                        <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] min-w-full w-fit">
                                                            <div className={`before-left-arrow cursor-pointer`}>
                                                                <div className="h-full flex items-center">
                                                                    <p
                                                                        className={`before-left-arrow cursor-pointer ${ShowDetails == item.id
                                                                            ? "before-left-arrow-open"
                                                                            : "before-left-arrow-close"
                                                                            }`}
                                                                        onClick={() => {
                                                                            setShowDetails(ShowDetails != item.id ? item.id : undefined);
                                                                        }}
                                                                    >
                                                                        #{item?.id ? item?.id?.split('-')[0] : ''}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm line-clamp-2">
                                                                    {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY') : ""}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm line-clamp-1 pr-3">
                                                                    {item?.client_type ? item?.client_type : "----"}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm">
                                                                    {item?.client?.full_name ? item?.client?.full_name : "----"}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-5">
                                                                <p className="text-sm ">
                                                                    {item?.status ? item?.status : "----"}
                                                                </p>
                                                                {item?.status === 'Cancelled' &&
                                                                    <LoginBtn
                                                                        className={'!bg-transparent !border-none !shadow-none !p-0 !text-2xl'}
                                                                        onClick={() => {
                                                                            setCancelReason(item)
                                                                            setCancelReasonPopup(!cancelReasonPopup);
                                                                        }}
                                                                    >
                                                                        <Svgs.NoteBook className={'!w-6 !h-6'} />
                                                                    </LoginBtn>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            ShowDetails == item.id ? (
                                                                <div className="scale-in-center md:min-w-[50rem] min-w-[69rem]">
                                                                    {item?.appointment_services?.map((srvData, srvIndex) => {
                                                                        return (
                                                                            <>
                                                                                <div className="bg-[#FAF9FF] px-6 py-3 grid grid-cols-6 col-span-3 gap-y-5 gap-x-3 w-full">
                                                                                    <div className="flex items-center gap-3">
                                                                                        <p className="font-semibold text-lg text-[#949494]">
                                                                                            #{srvIndex + 1}
                                                                                        </p>
                                                                                        <div className="flex flex-col gap-1">
                                                                                            <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Booking ID" : translation[173]?.value ? translation[173]?.value : "Booking ID"}</p>
                                                                                            <p className="text-black font-semibold">
                                                                                                #{srvData?.id ? srvData?.id?.split('-')[0] : ''}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex flex-col gap-1">
                                                                                        <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? 'Service Name' : translation[260]?.value ? translation[260]?.value : "Service Name"}</p>
                                                                                        <p className="text-black font-semibold">
                                                                                            {srvData?.service?.name ? srvData?.service?.name : "-------"}
                                                                                        </p>
                                                                                    </div>
                                                                                    {/* <div className="flex flex-col gap-1">
                                              <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? 'Booked By' : translation[428]?.value ? translation[428]?.value : "Booked By"}</p>
                                              <p className="text-black font-semibold">
                                                {srvData?.user?.first_name ? `${srvData?.user?.first_name} ${srvData?.user?.last_name}` : "-------"}
                                              </p>
                                            </div> */}
                                                                                    <div className="flex flex-col gap-1">
                                                                                        <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? 'Booked With' : translation[332]?.value ? translation[332]?.value : "Booked With"}</p>
                                                                                        <p className="text-black font-semibold">
                                                                                            {srvData?.member?.full_name ? srvData?.member?.full_name : "-------"}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="flex flex-col gap-1">
                                                                                        <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}</p>
                                                                                        <p className="text-black font-semibold">
                                                                                            {+(srvData?.price)?.toFixed(2)} {selectedLocationCurrency}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="flex flex-col gap-1">
                                                                                        <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Time and Date" : translation[962]?.value ? translation[962]?.value : "Time and Date"}</p>
                                                                                        <p className="text-black font-semibold">
                                                                                            {`${moment(`${srvData.appointment_date} ${srvData?.appointment_time}`, 'YYYY-MM-DD, hh:mm:ss').format('LT')} - ${srvData?.duration}`}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="flex flex-col gap-1">
                                                                                        <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? 'Status' : translation[260]?.value ? translation[260]?.value : "Status"}</p>
                                                                                        <p className="text-black font-semibold">
                                                                                            {srvData?.status ? srvData?.status : "-------"}
                                                                                        </p>
                                                                                    </div>
                                                                                    {srvData?.reason ?
                                                                                        (<div className="flex flex-col gap-1 col-span-6 pl-8 border border-[#A1A1A1] p-3 rounded-md text-sm bg-[#F9FAFC]">
                                                                                            {/* <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? 'Void Reason' : translation[1607]?.value ? translation[1607]?.value : "Void Reason"}</p> */}
                                                                                            <p className="text-black font-semibold">
                                                                                                {srvData?.reason ? srvData?.reason : "-------"}
                                                                                            </p>
                                                                                        </div>) : ''
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}

                                                                </div>
                                                            ) : (
                                                                ""
                                                            )
                                                        }
                                                    </>
                                                );
                                            })
                                        ) : (
                                            <NoAppointmentRecordFound />
                                        )}
                                    </div>
                                </div>
                            }
                            {/* {loader ?
                <div className="grid lg:grid-cols-2 gap-5 py-5">
                  {[0, 1, 2, 3].map((appointment, index) => {
                    return (
                      <Card
                        animate
                      />
                    )
                  })}
                </div>
                :
                all_appointments?.length > 0 ? (
                  <>
                    <div className="grid lg:grid-cols-2 gap-5 py-5">
                      {all_appointments?.map((appointment, index) => {
                        return (
                          <Card
                            appointment_status={appointment?.appointment_status}
                            key={index}
                            service={appointment?.service}
                            employee={appointment?.member}
                            sale={appointment.client}
                            earnings={`${appointment.price} ${selectedLocationCurrency}`}
                            booking_id={appointment?.booking_id}
                            appointment_date={appointment?.appointment_date}
                            appointment_time={appointment?.appointment_time}
                            appointment_type={appointment?.appointment_type}
                            selectedLanguage={selectedLanguage}
                            translation={translation}
                            member={appointment?.member}
                            booked_by={appointment?.booked_by}
                            client={appointment?.client}
                            duration={appointment?.duration}
                            startEnd={`${moment(`${appointment.appointment_date} ${appointment?.appointment_time}`, 'YYYY-MM-DD, hh:mm:ss').format('LT')} - ${appointment?.duration}`}
                          />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center">
                    <NoAppointmentRecordFound className={"my-[3rem]"} />
                  </div>
                )
              } */}
                        </div>
                        {(all_appointments?.length > 0 && !loader) &&
                            <Pagination
                                currentPage={currentPage}
                                totalCount={Math.ceil(count)}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        }
                    </div>
                </div>
            </div>

            {cancelReasonPopup && (
                <>
                    <Popup
                        heading={"Cancel Reason"}
                        size={"md"}
                        open={cancelReasonPopup}
                        close={setCancelReasonPopup}
                    >
                        <div className="flex flex-col gap-2">
                            <h4 className="font-medium text">{cancelReason?.cancel_reason}</h4>
                            <Textarea
                                title={selectedLanguage?.name == "English" ? "Description" : translation[1570]?.value ? translation[1570]?.value : "Description"}
                                rows={5}
                                value={cancelReason?.cancel_note}
                                readOnly
                            />
                        </div>
                    </Popup>
                </>
            )}
        </>
    );
};

export default Appointments;
