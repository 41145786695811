import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom";

const useSalesSummaryReportHelper = () => {
    const navigate = useNavigate();
    const topRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const active_tab = searchParams.get("active_tab");

    const { storeDataInLocalStorage } = useSetDataInLocalStorage();

    // redux states
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    // states
    const [backButtonClick, setBackButtonClick] = useState(false)

    let tabList = [
        {
            value: "appointment",
            label: "Appointment Summary"
        },
        {
            value: "product",
            label: "Product Summary"
        }
    ];

    useEffect(() => {
        // custom browser back button functionality implemented
        const handlePopstate = () => {
            setBackButtonClick(true)
            navigate('/dashboard/reports');
        };
        window.addEventListener('popstate', handlePopstate);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    useEffect(() => {
        if (!backButtonClick && !(active_tab && !tabList?.includes(active_tab))) {
            setSearchParams({ ...searchParams, active_tab: tabList[0]?.value });
        }
    }, [active_tab, backButtonClick, tabList]);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [active_tab]);

    return {
        navigate, setSearchParams, searchParams, tabList, active_tab, translation, selectedLanguage, storeDataInLocalStorage, topRef
    }
}

export default useSalesSummaryReportHelper