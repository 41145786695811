import React from "react";
import LocationSelection from "new-components/location";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import useTipReportsHelper from "./helper";
import TipTable from "./Elements/TipTable";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import ReactDateRangePicker from "Components/TenantApp/Elements/ReactDateRangePicker";
import { DateFilterTypes } from "Constants/Data/Months";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import moment from "moment";

const TipReports = () => {
    const {
        dateType, setDateType, loading, translation, selectedLanguage, employeeTip, startDate, setStartDate, endDate, setEndDate,
        loadingPermission, csvLoading, handleExportCSVData, storeDataInLocalStorage, location_currency
    } = useTipReportsHelper();

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative bg-primary-background">
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                url={'/dashboard/reports'}
                second={"Tip Reports Summary"}
            />

            <div className="flex items-center justify-between sm:gap-4 gap-2 my-[1rem]">
                <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                    Tip Reports
                </h2>

                <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
            </div>
            <div className='flex flex-col bg-white rounded-xl border border-[#3F3F441A]'>
                <div className="px-4 lg:px-6 py-5 flex items-center justify-between gap-3">
                    {loading ?
                        <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                            <p className="rounded-md h-3 w-12 mx-1"> </p>
                        </div>
                        :
                        <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                            <p className="text-primary">
                                {employeeTip?.length ? employeeTip?.length : 0} {" "}
                                {employeeTip?.length && employeeTip?.length > 1 ?
                                    selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                    : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                }
                            </p>
                        </div>
                    }
                    <div className="flex flex-wrap md:items-center justify-end flex-1 w-full gap-2">
                        {/* {loadingPermission ?
                        <Shimmer className={'w-40 lg:w-44'}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer>
                        : */}
                        <Dropwdown
                            name={"dateType"}
                            value={dateType}
                            className={'w-fit flex-none'}
                            onChange={(e) => setDateType(e?.target?.value)}
                            options={DateFilterTypes}
                            title=""
                            placeholder="Select Date Filter"
                        />
                        {/* } */}
                        {dateType == 'custom' &&
                            <ReactDateRangePicker
                                maxDate={moment().toDate()}
                                className="!max-w-none"
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        }
                    </div>
                </div>
                {loading ?
                    <TableShimer cols={2} rows={10} />
                    :
                    <TipTable
                        employeeTip={employeeTip}
                        location_currency={location_currency}
                    />}
            </div>
        </div>
    );
};

export default TipReports;
