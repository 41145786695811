import { apiErrorMessage } from 'Constants/Data/Errors'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getsProductsDropdownList, getsProductsInMembership } from '../../../../../../Adapters/Api/Products'
import { getservice, getserviceDropdownList } from '../../../../../../Adapters/Api/services'
import { AddGiftCards, GetGiftCards, UpdateGiftCards, createMembers, deleteGiftCard, deleteMember, getMembers, updateMember } from '../../../../../../Adapters/Api/vouchers'
import Svgs from '../../../../../../Assets/svgs'
import useValidations from '../../../../../../Hooks/useValidations'
import useObjectKeys from "Hooks/useObjectKeys";
import NotFound from '../../../../../Utility/NotFound'
import CircleIcon from '../../../../Elements/CircleIcon'
import Dropwdown from '../../../../Elements/Dropwdown'
import LoginBtn from '../../../../Elements/LoginBtn'
import LoginInput from '../../../../Elements/LoginInput'
import Popup from '../../../../Elements/Popup'
import PreloaderFull from '../../../../Elements/PreloaderFull'
import Textarea from '../../../../Elements/Textarea'
import AddGiftCard from './AddGiftCard'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { generateUniqueCode } from 'Constants/Functions/generateRandomCodes'
import { ValidityTime } from 'Constants/Data/AppointmentsData'
// import NumberInput from 'Components/TenantApp/Elements/NumberInput'

let initialState = {
    title: "",
    description: "",
    currency_gift_card_price: [],
    valid_till: "",
    term_condition: "",
    is_custom_card: false,
    is_active: true
}
const GiftCard = () => {
    const formRef = useRef()
    const mainRef = useRef()
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const locationList = state.locations.locations;
    const currencyList = state?.utility?.currency?.currency_list;
    const businessCurrency = state.business.business;
    const giftCards_updated = state?.giftCards?.giftCards_updated;

    // const [promoData, setpromoData] = useState([]);

    // const [gift_card_input, setgift_card_input] = useState({})
    const [is_delete, setDelete] = useState(undefined)
    const [btn_loading, setBtnLoading] = useState(false)
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [edit_id, setEditId] = useState(undefined)
    const [duration, setDuration] = useState('')
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [price, setPrice] = useState();
    const [retail_price, setRetail_price] = useState();
    const [product_percentage, setProduct_percentage] = useState('')
    const [servicePercentage, setServicePercentage] = useState();
    const [productPercentage, setProductPercentage] = useState();
    const [submitButtonClick, setSubmitButtonClick] = useState(false)
    // const [submitButtonClick, setSubmitButtonClick] = useState(false)
    const [loading, setLoading] = useState(true)

    const [memberError, setgiftCardError] = useState({})
    const [editError, setEditError] = useState({})

    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    const { setErrors, validation, setEditErrors, editErrors, errors, onBlurHandler, validateMoblieNumber } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();

    const [gift_card_input, setgift_card_input] = useState(initialState)

    let allUinqueCurrencyList = [];

    locationList?.filter((item) => item?.currency?.id)?.filter((itm) => {
        if (!allUinqueCurrencyList?.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
            allUinqueCurrencyList?.push(itm)
            return true
        }
    })

    const generateCouponCode = () => {
        const uniqueCode = generateUniqueCode(3, 3)
        return uniqueCode;
    }

    const { title, valid_till, currency_gift_card_price, description, term_condition, is_active } = gift_card_input;

    const handlegift_card_input = (e) => {
        const { name, value } = e.target;
        let trimmedValue = value;
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));

        if (name === "description") {
            trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters
            if (value.length > 150) {
                setErrors((prev) => ({
                    ...prev,
                    // description: "You can not add more than 150 characters",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    description: "",
                }));
            }
        }

        if (name === "term_condition") {
            trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters
            if (value.length > 150) {
                setErrors((prev) => ({
                    ...prev,
                    // term_condition: "You can not add more than 150 characters",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    term_condition: "",
                }));
            }
        }

        setgift_card_input({
            ...gift_card_input,
            [name]: trimmedValue,
        });

    }

    const giftCardsubmithandler = () => {
        setSubmitButtonClick(true)
        const uniqueCode = generateCouponCode()
        errors['code'] = ''
        gift_card_input['code'] = uniqueCode
        gift_card_input['business'] = state?.business?.business?.id
        let requiredFields = {}

        requiredFields = { title, description, valid_till, term_condition }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if ((objectKeyConvertToArray(result)?.length === 0)) {
            const success = () => {
                setProduct_percentage('')
                setDuration('')
                setgiftCardError({})
                setServicePercentage()
                setProductPercentage()
                setBtnLoading(false)
                getGiftCards()
                setSubmitButtonClick(false)
                setgift_card_input(initialState)
                mainRef.current?.scrollIntoView({ behavior: "smooth" });
            }
            const fail = () => {
                setBtnLoading(false)
                setSubmitButtonClick(false)
            }

            if (allUinqueCurrencyList?.length != gift_card_input?.currency_gift_card_price?.length) {
                setErrors((prev) => ({
                    ...prev,
                    price: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
                }))
            }
            else {
                setErrors((prev) => ({
                    ...prev,
                    price: "" // Remove the error message
                }));
                dispatch(AddGiftCards(access_token, gift_card_input, success, fail))
            }
        } else {
            formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }

    const getGiftCards = () => {
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        dispatch(GetGiftCards(access_token, {}, success, fail))
    }

    const GiftCardDelete = (id) => {
        const success = () => {
            getGiftCards()
            setDeleteLoader(false)
        }
        const fail = () => {
            setDeleteLoader(false)
        }
        setDeleteLoader(true)
        dispatch(deleteGiftCard(access_token, id, success, fail))
    }

    useEffect(() => {
        getGiftCards()
    }, [])

    const onMemberEditClickHnadler = (id) => {
        setEditId(id)
    }

    const onMemberUpdateHnadler = (edited_data, seteditId) => {
        const { title, description, valid_till, term_condition } = edited_data;

        setSubmitButtonClick(true)
        let requiredFields = { title, description, valid_till, term_condition }

        setEditErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if ((objectKeyConvertToArray(result)?.length === 0)) {
            if (allUinqueCurrencyList?.length != edited_data?.currency_gift_card_price?.length) {
                setEditError((prev) => ({
                    ...prev,
                    price: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
                }))
            }
            else {
                setEditError((prev) => ({
                    ...prev,
                    price: "" // Remove the error message
                }));
                setUpdateLoading(true)
                dispatch(
                    UpdateGiftCards(
                        access_token,
                        edited_data,
                        () => {
                            setProduct_percentage('')
                            setDuration('')
                            setgiftCardError({})
                            setServicePercentage()
                            setProductPercentage()
                            getGiftCards()
                            setSubmitButtonClick(false)
                            seteditId('')
                            setEditId(false)
                            setUpdateLoading(false)
                        },
                        () => {
                            setUpdateLoading(false)
                        }
                    )
                )
            }
        }
    }

    useEffect(() => {
        const serviceResult = gift_card_input?.services?.map((itm, i) => {
            return {
                percentage: duration,
                service: itm?.service,
            }
        });

        setgift_card_input({ ...gift_card_input, services: serviceResult })

    }, [duration])

    useEffect(() => {
        setEditError((prev) => ({
            ...prev,
            ...editErrors
        }))
    }, [editErrors])

    useEffect(() => {
        const prodcutResult = gift_card_input?.products?.map((itm, i) => {
            return {
                percentage: product_percentage,
                product: itm?.product,
            }
        });

        setgift_card_input({
            ...gift_card_input, products: prodcutResult
        })

    }, [product_percentage])


    const removedSelectedItems = (id, type) => {

        if (type === "service") {
            setgift_card_input((prev) => ({
                ...prev,
                services: gift_card_input?.services?.filter((itm) => itm.service !== id)
            }))
        }

        if (type === "product") {
            setgift_card_input((prev) => ({
                ...prev,
                products: gift_card_input?.products?.filter((itm) => itm?.product !== id)
            }))
        }

    }

    //set currency and giftCard price object here
    const addNewItem = () => {
        setgift_card_input((prev) => {
            let prev_mm = prev?.currency_gift_card_price ? prev?.currency_gift_card_price : []
            return ({
                ...prev,
                currency_gift_card_price: [
                    ...prev_mm,
                    { currencies: selectedCurrency, price: price, retail_price: retail_price }
                ]
            })
        })
        setSelectedCurrency();
        setRetail_price()
        setPrice()
    }

    // **************************** remove selected items *********************************
    const removedItem = (value, type) => {

        if ((type === "price")) {
            if (gift_card_input?.currency_gift_card_price?.find((itm) => itm?.currencies == value)) {
                setgift_card_input((prev) => ({
                    ...prev,
                    currency_gift_card_price: prev?.currency_gift_card_price ? prev?.currency_gift_card_price?.filter((itm) => itm?.currencies != value) : [],
                }));
            }
        } else {
            if (gift_card_input?.currency_gift_card_price?.find((itm) => itm?.currencies == value)) {
                setgift_card_input((prev) => ({
                    ...prev,
                    currency_gift_card_price: prev?.currency_gift_card_price ? prev?.currency_gift_card_price?.map((itm) => {
                        if (itm?.currencies === value) {
                            return {
                                ...itm,
                                is_deleted: true
                            }
                        }
                        return itm
                    }) : [],
                }));
            }
        }

    };

    useEffect(() => {
        if (allUinqueCurrencyList?.length == gift_card_input?.currency_gift_card_price?.length) {
            setErrors((prev) => ({
                ...prev,
                price: ""
            }))
        }
    }, [gift_card_input?.currency_gift_card_price])

    return (
        loading ? <PreloaderFull /> : <>
            <div ref={mainRef} className='col-span-4 slide-in-bottom'>
                <div className='flex flex-col gap-5 '>
                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Promotions />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>
                                {selectedLanguage?.name == "English" ? "Add Gift Card" : translation[1543]?.value ? translation[1543]?.value : "Add Gift Card"}
                            </h2>
                            <p className='text-[#7B7B7B] text-sm'>
                                {selectedLanguage?.name == "English" ? "Add Gift Card to clients" : translation[1544]?.value ? translation[1544]?.value : "Add Gift Card to clients"}
                            </p>
                        </div>
                    </div>
                    <div className='w-full col-span-1'>
                        <div className=''>
                            <>
                                {
                                    state.giftCards?.giftCards?.length > 0 &&
                                    state.giftCards?.giftCards?.map((giftCard, index) => {
                                        return (
                                            <AddGiftCard
                                                editError={editError}
                                                setEditError={setEditError}
                                                currency={businessCurrency?.currency?.code}
                                                key={index}
                                                index={index}
                                                data={giftCard}
                                                is_edit={edit_id == giftCard.id ? true : false}
                                                onUpdate={(edited_data, seteditId) => {
                                                    onMemberUpdateHnadler(edited_data, seteditId)
                                                }}
                                                formRef={formRef}
                                                // onDelete={(id) => {
                                                //   GiftCardDelete(id)
                                                // }}
                                                onDelete={(id) => {
                                                    setDelete(id);
                                                }}
                                                submitButtonClick={submitButtonClick}
                                                onEditClick={(id) => { onMemberEditClickHnadler(id) }}
                                                onBlurHandler={onBlurHandler}
                                                errors={errors}
                                                setErrors={setErrors}
                                                updateLoading={updateLoading}
                                                translation={translation}
                                                selectedLanguage={selectedLanguage}
                                            />
                                        )
                                    }
                                    )
                                }
                            </>
                            {(!employee || (employee && employeePermissions?.client_sharing?.includes("create"))) &&
                                <div className='flex gap-6' ref={formRef}>
                                    <div className='relative'>
                                        <div className='flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 '>

                                            <div className='h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center'>
                                            </div>
                                        </div>
                                        {
                                            state.giftCards?.giftCards?.length != 0 &&
                                            <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2'></div>
                                        }
                                    </div>
                                    <div className='mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4 bg-white'>

                                        <LoginInput
                                            name={"title"}
                                            required={true}
                                            title={selectedLanguage?.name == "English" ? "Title" : translation[1426]?.value ? translation[1426]?.value : "Title"}
                                            placeholder={selectedLanguage?.name == "English" ? "Enter Title" : translation[1428]?.value ? translation[1428]?.value : "Enter Title"}
                                            value={title}
                                            // charactersMaxLength={25}
                                            onChange={handlegift_card_input}
                                            // onEnterSubmit={membersubmithandler}
                                            onBlur={() => onBlurHandler({ name: title })}
                                            error={errors?.title}
                                        />

                                        <Textarea
                                            required={true}
                                            name={"description"}
                                            value={description}
                                            onChange={handlegift_card_input}
                                            onBlur={() => onBlurHandler({ description: description })}
                                            error={errors?.description}
                                            title={selectedLanguage?.name == "English" ? "Description" : translation[1167]?.value ? translation[1167]?.value : "Description"}
                                            placeholder={selectedLanguage?.name == "English" ? "Enter Description" : translation[1168]?.value ? translation[1168]?.value : "Enter Description"}
                                        />

                                        <div className='md:flex-row flex flex-col gap-4'>
                                            <div className='flex-1'>
                                                <FloatInput
                                                    required={true}
                                                    name={"price"}
                                                    value={price}
                                                    parentClass={errors?.price && 'mb-5'}
                                                    // onChange={handlegift_card_input}
                                                    disabled={allUinqueCurrencyList?.length == gift_card_input?.currency_gift_card_price?.length}
                                                    onChange={(e) => {
                                                        setPrice(e.target.value)
                                                        setErrors((prev) => ({
                                                            ...prev,
                                                            price: ""
                                                        }));
                                                    }}
                                                    onBlur={() => onBlurHandler({ price: price })}
                                                    error={errors?.price}
                                                    title={selectedLanguage?.name == "English" ? "Pricing" : translation[269]?.value ? translation[269]?.value : "Pricing"}
                                                    placeholder={selectedLanguage?.name == "English" ? "Enter Pricing" : translation[270]?.value ? translation[270]?.value : "Enter Pricing"}
                                                />
                                            </div>

                                            <div className='flex-1'>
                                                <FloatInput
                                                    required={true}
                                                    name={"retail_price"}
                                                    value={retail_price}
                                                    parentClass={errors?.retail_price && 'mb-5'}
                                                    // onChange={handlegift_card_input}
                                                    disabled={allUinqueCurrencyList?.length == gift_card_input?.currency_gift_card_price?.length}
                                                    onChange={(e) => {
                                                        setRetail_price(e.target.value)
                                                        setErrors((prev) => ({
                                                            ...prev,
                                                            retail_price: ""
                                                        }));
                                                    }}
                                                    onBlur={() => onBlurHandler({ price: retail_price })}
                                                    error={errors?.retail_price}
                                                    title={selectedLanguage?.name == "English" ? "Selling Price" : translation[294]?.value ? translation[294]?.value : "Selling Price"}
                                                    placeholder={selectedLanguage?.name == "English" ? "Enter selling price" : translation[944]?.value ? translation[944]?.value : "Enter selling price"}
                                                />
                                            </div>

                                            <div className='flex-1'>
                                                <Dropwdown
                                                    required={true}
                                                    title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                                                    placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                                                    name={"selectedCurrency"}
                                                    value={selectedCurrency}
                                                    onChange={(e) => setSelectedCurrency(e.target.value)}
                                                    options={allUinqueCurrencyList
                                                        ?.filter(
                                                            (itm) =>
                                                                !currency_gift_card_price?.find(
                                                                    ({ currencies }) => currencies === itm?.currency?.id
                                                                )
                                                        )?.map((itm) => {
                                                            return {
                                                                label: itm?.currency?.code,
                                                                value: itm?.currency?.id,
                                                            };
                                                        })}
                                                />
                                            </div>

                                            <div className='flex items-end'>
                                                <LoginBtn
                                                    className={(selectedCurrency && price) && "shake-bottom"}
                                                    btnCustomHight={'h-[2.1875rem]'}
                                                    disabled={!selectedCurrency || !price || !retail_price}
                                                    onClick={addNewItem}
                                                >
                                                    <div className='flex items-center gap-2 text-sm'>
                                                        <Svgs.Plus />
                                                        <p>
                                                            {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                        </p>
                                                    </div>
                                                </LoginBtn>
                                            </div>
                                        </div>

                                        {currency_gift_card_price?.length > 0 && (
                                            <div className="flex items-center flex-wrap gap-2 mt-[2ch]">
                                                {currency_gift_card_price &&
                                                    currency_gift_card_price?.map((item, i) => {
                                                        const crrncy = currencyList?.find(
                                                            (itm) => itm?.id === item?.currencies
                                                        );
                                                        return (
                                                            !item?.is_deleted && (
                                                                <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                                                    <p className="text-primary text-sm font-bold">{`${item?.price} ${crrncy?.code}`}</p>
                                                                    <span
                                                                        onClick={() =>
                                                                            removedItem(item?.currencies, "price")
                                                                        }
                                                                    >
                                                                        <Svgs.CirlceClose fill="#5679FF" />
                                                                    </span>
                                                                </div>
                                                            )
                                                        );
                                                    })}
                                            </div>
                                        )}

                                        <div className=''>
                                            <Dropwdown
                                                required={true}
                                                name={"valid_till"}
                                                value={valid_till}
                                                onChange={handlegift_card_input}
                                                onBlur={() => onBlurHandler({ valid_till: valid_till })}
                                                error={errors?.valid_till}
                                                options={ValidityTime}
                                                title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                                                placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                                            />
                                        </div>
                                        {/* <Dropwdown
                                            name={"is_active"}
                                            value={is_active}
                                            onChange={handlegift_card_input}
                                            error={errors?.is_active}
                                            required={true}
                                            title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                            placeholder={selectedLanguage?.name == "English" ? "Select status" : translation[483]?.value ? translation[483]?.value : "Select status"}
                                            options={[
                                                {
                                                    value: true,
                                                    label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                                },
                                                {
                                                    value: false,
                                                    label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                                                },
                                            ]}
                                        /> */}
                                        <Textarea
                                            required={true}
                                            name={"term_condition"}
                                            value={term_condition}
                                            onChange={handlegift_card_input}
                                            onBlur={() => onBlurHandler({ term_condition: term_condition })}
                                            error={errors?.term_condition}
                                            title={selectedLanguage?.name == "English" ? "Terms & Conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & Conditions"}
                                            placeholder={selectedLanguage?.name == "English" ? "Enter Terms & Conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & Conditions"}

                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {(!employee || (employee && employeePermissions?.client_sharing?.includes("create"))) &&
                        <LoginBtn
                            text={selectedLanguage?.name == "English" ? "Add Gift Card" : translation[1543]?.value ? translation[1543]?.value : "Add Gift Card"}
                            disabled={btn_loading}
                            loading={btn_loading}
                            onClick={() => {
                                setSubmitButtonClick(true)
                                giftCardsubmithandler()
                            }}
                        />
                    }
                </div>

            </div>
            {
                is_delete &&
                <Popup
                    heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Membership?" : translation[1176]?.value ? translation[1176]?.value : "Are you sure you want to delete this Membership?"}
                    onclose={() => {
                        setDelete(false)
                    }}
                >
                    <div>
                        <p>{selectedLanguage?.name == "English" ? "Are you sure you want to delete this Membership? You won't be able to see this again." : translation[1177]?.value ? translation[1177]?.value : "Are you sure you want to delete this Membership? You won't be able to see this again."}</p>
                        <div className='flex justify-end mt-4'>
                            <LoginBtn
                                deleteBtn
                                loading={deleteLoader}
                                disabled={deleteLoader}
                                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                                onClick={() => {
                                    GiftCardDelete(is_delete)
                                    setTimeout(() => {
                                        setDelete(false)
                                    }, 500);
                                }}
                            />
                        </div>
                    </div>
                </Popup>
            }
        </>
    )
}

export default GiftCard