import { useState, useEffect } from "react"
import Moment from "react-moment"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { deleteCategory } from "../../../../../../Adapters/Api/categories"
import Svgs from "../../../../../../Assets/svgs"
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage"
import { removeCategroy } from "../../../../../../Redux/TenantRedux/Actions/categoryActions"
import LoginBtn from "../../../../Elements/LoginBtn"
import Popup from "../../../../Elements/Popup"
import StatusBtn from "../../../../Elements/StatusBtn"
import { CategoryPopup } from "../AddHeaderBtns/AddCategoryBtn"
import { getAllCategories, getAllCategoriesWithNoPagination } from '../../../../../../Adapters/Api/categories'


const CategoryCard = ({ item, search_text, setCurrentPage, hideDeleteIcon, setLoader, setNoPaginationLoader }) => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();

    const [del_popup, setDelPopup] = useState(undefined)
    const [edit_popup, setEditPopup] = useState(undefined)
    const [deleting, setDeleting] = useState(undefined)
    const [isToastVisible, setIsToastVisible] = useState(false);

    const dispatch = useDispatch()
    const state = useSelector((state) => state);

    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);
    return (
        <>
            <div className={`text-[#3A3A3A] transition-all "border-l-[0px]`} key={item.id}>
                <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(155px,1fr))] px-12 py-3 min-w-full w-fit">
                    <div className="flex items-center gap-3">
                        <p className="text-sm">{item.name}</p>
                    </div>
                    {/* <div className="flex items-center">
                        <p className="text-sm"><Moment fromNow>{item.created_at}</Moment></p>
                    </div> */}
                    <div className="flex items-center">
                        <StatusBtn
                            profile_status={
                                item?.is_active ?
                                    selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                    :
                                    selectedLanguage?.name == "English" ? "In Active" : translation[1303]?.value ? translation[1303]?.value : "In Active"
                            }
                            status={item?.is_active ? "Active" : "Inactive"}
                        />
                    </div>
                    {!employee ? (
                        <div className="flex items-center gap-2">
                            <div className="flex items-center gap-2">
                                <div
                                    onClick={() => setEditPopup(true)}
                                    className="h-[2.5rem] w-[2.5rem] border cursor-pointer rounded-full flex items-center justify-center"
                                >
                                    <span >  <Svgs.Pen /> </span>
                                </div>
                                {!hideDeleteIcon &&
                                    <div
                                        onClick={() => setDelPopup(item?.id)}
                                        className="h-[2.5rem] w-[2.5rem] border rounded-full cursor-pointer flex items-center justify-center"
                                    >
                                        <span > <Svgs.Delete /> </span>
                                    </div>
                                }
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                </div>
            </div>
            {
                del_popup &&
                <Popup
                    heading={
                        selectedLanguage?.name == "English"
                            ? "Are you sure you want to delete this Category?"
                            : translation[910]?.value
                                ? translation[910]?.value
                                : "Are you sure you want to delete this Category?"
                    }
                    onclose={() => { setDelPopup(false); }}
                >
                    <p>
                        {selectedLanguage?.name == "English"
                            ? "Are you sure you want to delete this Category? You won't be able to see this again."
                            : translation[912]?.value
                                ? translation[912]?.value
                                : "Are you sure you want to delete this Category? You won't be able to see this again."}
                    </p>
                    <div className='flex justify-end mt-4'>
                        <LoginBtn
                            loading={deleting}
                            text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                            deleteBtn
                            onClick={async () => {
                                setDeleting(true)
                                const access_token = getDataFromLocalStorage("access_token");
                                let response = await deleteCategory(item.id, access_token);
                                if (response.status_code === 200) {
                                    let successMessage = response?.response?.message;
                                    if (!isToastVisible) {
                                        toast.success(successMessage, {
                                            toastId: "toast",
                                            onClose: () => setIsToastVisible(false),
                                        });
                                        setIsToastVisible(true);
                                    }
                                    // dispatch(removeCategroy(item.id));
                                    setCurrentPage(1)
                                    dispatch(getAllCategories(1, search_text, setLoader))
                                    dispatch(getAllCategoriesWithNoPagination(search_text, setNoPaginationLoader))
                                }
                                setTimeout(() => {
                                    setDeleting(false)
                                    setDelPopup(false)
                                }, 500);
                            }}
                        />
                    </div>
                </Popup>
            }
            {
                edit_popup &&
                <CategoryPopup
                    edit_mode={true} edit_data={item}
                    onClose={() => { setEditPopup(false) }}
                    onSubmit={() => {
                        setCurrentPage(1)
                        dispatch(getAllCategories(1, search_text, setLoader))
                        dispatch(getAllCategoriesWithNoPagination(search_text, setNoPaginationLoader))
                    }}
                />
            }
        </>
    )
}

export default CategoryCard