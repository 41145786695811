import React, { useEffect, useState } from "react";
import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "./../../../Elements/LoginBtn";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
import Textarea from "Components/TenantApp/Elements/Textarea";
import FloatInput from "Components/TenantApp/Elements/FloatInput";

function AddCashBackCard({ reduxlocationsList, applicableData, editId, setErrors, errors, seteditId, data, index, updateCashBack, onDelete, updateLoader, translation, selectedLanguage, }) {
  const state = useSelector((state) => state);

  const [cashBackEditData, setCashBackEditData] = useState({});
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const {
    name, status, validTill, applicableFor, termsAndCondition, allowMembership, allowedLocations, redeemableLocations, amount_spend, percentage,
    redeemptionAmount
  } = cashBackEditData

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (editId) {
      let newData = {
        id: data?.id,
        name: data?.name,
        amount_spend: data?.amount_spend,
        percentage: data?.percentage,
        redeemptionAmount: data?.redumption_amount,
        validTill: data?.expiry,
        status: data?.is_active,
        applicableFor: data?.applicable_for,
        termsAndCondition: data?.terms_and_conditions,
        allowMembership: data?.allowMembership,
        allowedLocations: data?.cashback_allowed_locations_records?.length > 0 ? data?.cashback_allowed_locations_records?.map(itm => itm?.location) : [],
        redeemableLocations: data?.cashback_redeemed_locations_records?.length > 0 ? data?.cashback_redeemed_locations_records?.map(itm => itm?.location) : []
      }
      setCashBackEditData(newData)
    }
  }, [editId]);

  // handle change of allow membership
  const allowMembershipChangeHandler = (e) => {
    const { name, checked } = e.target;
    setCashBackEditData((prev) => ({
      ...prev,
      [name]: checked
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: ""
    }))
  }

  // handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'redeemableLocations' || name == 'allowedLocations') {
      const prevData = cashBackEditData?.[name] || []
      setCashBackEditData((prev) => ({
        ...prev,
        [name]: [...prevData, value]
      }))
    }

    if (name != 'redeemableLocations' && name != 'allowedLocations') {
      setCashBackEditData((prev) => ({
        ...prev,
        [name]: value
      }))
    }
    setErrors((prev) => ({
      ...prev,
      [name]: ""
    }))
  }

  // remove cashback spending item
  const removeItem = (name, data) => {
    if (name == 'allowedLocations') {
      const filterData = cashBackEditData?.allowedLocations?.filter(itm => itm != data)
      setCashBackEditData((prev) => ({
        ...prev,
        allowedLocations: filterData
      }))
    }

    if (name == 'redeemableLocations') {
      const filterData = cashBackEditData?.redeemableLocations?.filter(itm => itm != data)
      setCashBackEditData((prev) => ({
        ...prev,
        redeemableLocations: filterData
      }))
    }
  }

  return (
    <>
      <div className="flex gap-6">
        <div className="relative">
          {index != 0 && (
            <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
          )}
          <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <div className="h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-primary-background">
              <Svgs.Appointment_Bullet />
            </div>
          </div>
          <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2"></div>
        </div>
        {editId && (editId === cashBackEditData?.id) ? (
          <>
            <div className="mt-[2rem] border px-4 py-5 rounded-md w-xl flex flex-col gap-4 flex-1 bg-white">
              <div className="flex gap-6">

                <div className="mt-[2rem] px-4 py-3 rounded-md w-xl flex flex-col gap-4 flex-1">
                  <LoginInput
                    required={true}
                    name={"name"}
                    // charactersMaxLength={25}
                    value={name}
                    onChange={handleChange}
                    error={errors?.name}
                    title={selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Name" : translation[1090]?.value ? translation[1090]?.value : "Enter Name"}
                  />

                  <Dropwdown
                    required={true}
                    name="allowedLocations"
                    onChange={handleChange}
                    error={errors?.location}
                    options={reduxlocationsList?.filter((adrs) => {
                      if (!(allowedLocations?.includes(adrs?.id))) {
                        return adrs
                      }
                    }).map((adrs) => {
                      return {
                        label: adrs?.address_name,
                        value: adrs?.id,
                      };
                    })}
                    title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                  />
                  {allowedLocations?.length > 0 && (
                    <div className="flex items-center flex-wrap gap-2">
                      {allowedLocations &&
                        allowedLocations?.map((data, i) => {
                          const matchingAddressName = reduxlocationsList?.find(item => item?.id === data)?.address_name
                          return (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">{`${matchingAddressName}`}</p>
                              <span
                                className="cursor-pointer"
                                onClick={() =>
                                  removeItem('allowedLocations', data)
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  )}

                  <Dropwdown
                    required={true}
                    name="redeemableLocations"
                    onChange={handleChange}
                    error={errors?.location}
                    options={reduxlocationsList?.filter((adrs) => {
                      if (!(redeemableLocations?.includes(adrs?.id))) {
                        return adrs
                      }
                    }).map((adrs) => {
                      return {
                        label: adrs?.address_name,
                        value: adrs?.id,
                      };
                    })}
                    title={selectedLanguage?.name == "English" ? "Redeemable Location" : translation[19]?.value ? translation[19]?.value : "Redeemable Location"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Redeemable Location" : translation[20]?.value ? translation[20]?.value : "Select Redeemable Location"}
                  />
                  {redeemableLocations?.length > 0 && (
                    <div className="flex items-center flex-wrap gap-2">
                      {redeemableLocations &&
                        redeemableLocations?.map((data, i) => {
                          const matchingAddressName = reduxlocationsList?.find(item => item?.id === data)?.address_name
                          return (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">{`${matchingAddressName}`}</p>
                              <span
                                className="cursor-pointer"
                                onClick={() =>
                                  removeItem('redeemableLocations', data)
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  )}

                  <div className="grid md:grid-cols-2 gap-3">
                    <FloatInput
                      required={true}
                      name="amount_spend"
                      value={amount_spend}
                      onChange={handleChange}
                      error={errors?.amount_spend}
                      title={"Minimum Spend"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Spend Amount" : translation[1095]?.value ? translation[1095]?.value : "Enter Spend Amount"}
                    />

                    <FloatInput
                      required={true}
                      name="percentage"
                      value={percentage}
                      onChange={handleChange}
                      error={errors?.percentage}
                      title={"Percentage"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Percentage" : translation[1701]?.value ? translation[1701]?.value : "Enter Percentage"}
                    />
                  </div>

                  <FloatInput
                    required={true}
                    name="redeemptionAmount"
                    value={redeemptionAmount}
                    onChange={handleChange}
                    error={errors?.redeemptionAmount}
                    title={"Redeemption Amount"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Redeemption Amount" : translation[1095]?.value ? translation[1095]?.value : "Enter Redeemption Amount"}
                  />

                  <Dropwdown
                    required={true}
                    name={"applicableFor"}
                    value={applicableFor}
                    onChange={handleChange}
                    error={errors?.applicableFor}
                    options={applicableData}
                    title={selectedLanguage?.name == "English" ? "Select Category" : translation[889]?.value ? translation[889]?.value : "Select Category"}
                    placeholder={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
                  />
                  <Dropwdown
                    required={true}
                    name={"status"}
                    value={status}
                    onChange={handleChange}
                    error={errors?.status}
                    title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
                    options={[
                      {
                        value: true,
                        label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                      },
                      {
                        value: false,
                        label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                      },
                    ]}
                  />
                  <div className="grid md:grid-cols-2 gap-3">
                    <Dropwdown
                      required={true}
                      name={"validTill"}
                      value={validTill}
                      onChange={handleChange}
                      error={errors?.validTill}
                      options={[
                        { label: '7 Days', value: '7 Days' },
                        { label: '14 Days', value: '14 Days' },
                        { label: '1 Month', value: '1 Months' },
                        { label: '2 Months', value: '2 Months' },
                        { label: '3 Months', value: '3 Months' },
                        { label: '4 Months', value: '4 Months' },
                        { label: '6 Months', value: '6 Months' },
                        { label: '8 Months', value: '8 Months' },
                        { label: '1 Years', value: '1 Years' },
                        { label: '18 Months', value: '18 Months' },
                        { label: '2 Years', value: '2 Years' },
                        { label: '5 Years', value: '5 Years' },
                      ]}
                      title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                      placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                    />

                  </div>

                  <div className="space-y-5">
                    <Textarea
                      required={true}
                      name={"termsAndCondition"}
                      value={termsAndCondition}
                      onChange={handleChange}
                      error={errors?.termsAndCondition}
                      title={selectedLanguage?.name == "English" ? "Terms & conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & conditions"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Terms & conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & conditions"}
                    />
                    <CheckBox
                      name="allowMembership"
                      onChange={allowMembershipChangeHandler}
                      value={allowMembership}
                      checked={allowMembership}
                      label_Class={"!normal-case"}
                      label={"Allow it with membership"}
                    />
                  </div>

                </div>
              </div>
              <LoginBtn
                className="ml-6"
                text={selectedLanguage?.name == "English" ? "Update CashBack" : translation[1705]?.value ? translation[1705]?.value : "Update CashBack"}
                loading={updateLoader}
                disabled={updateLoader}
                onClick={() => {
                  updateCashBack(cashBackEditData);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="mt-[2rem] rounded-md w-full flex flex-col gap-4">
              <div className="loyalty-gradient flex">
                <div className="px-3 md:px-8 py-4 bg-[#5679FF] flex items-center justify-center flex-col text-white">
                  <button className="bg-white text-[#5679FF] rounded-full px-6 py-2 font-bold">
                    {selectedLanguage?.name == "English" ? "Offer" : translation[1102]?.value ? translation[1102]?.value : "Offer"}
                  </button>
                  <p className="w-[70%] md:w-fit mx-auto text-center">{data?.name}</p>
                </div>
                <div className="flex gap-2 flex-col px-6 py-8 flex-1">
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex items-end ">
                      {/* <h1 className="text-[#22283D] font-bold text-2xl">
                        {data?.name}
                      </h1> */}
                      {
                        data?.location &&
                        <div className="flex gap-2 mx-2 items-center text-[#22283D] font-bold text-lg">
                          {/* <Svgs.Location fill='#fff' /> */}
                          <Svgs.MAP_PIN stroke='#fff' />
                          <p>{data?.location?.address_name}</p>
                        </div>
                      }
                    </div>
                    <div className="flex items-center gap-3">
                      {!employee ||
                        (employee &&
                          employeePermissions?.cash_back?.includes(
                            "edit"
                          )) ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            seteditId(data?.id);
                          }}
                        >
                          <Svgs.Edit fill="#767A87" />
                        </div>
                      ) : (
                        ""
                      )}
                      {!employee ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onDelete(data?.id);
                          }}
                        >
                          <Svgs.Delete fill="#767A87" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-[#22283D] text-lg">
                    <p className="">
                      {/* <span className="text-lg">
                        Spent {data?.amount_spend} {currency} On{" "}
                        {data?.loyaltytype} to get {data?.number_points} points
                      </span> */}
                      <span className="text-lg">
                        {selectedLanguage?.name == "English" ? "Spent" : translation[1103]?.value ? translation[1103]?.value : "Spent"} {data?.amount_spend} {" "}
                        {selectedLanguage?.name == "English" ? "to get" : translation[1105]?.value ? translation[1105]?.value : "to get"} {data?.number_points} {selectedLanguage?.name == "English" ? "points" : translation[1104]?.value ? translation[1104]?.value : "points"}
                      </span>
                    </p>
                    {/* <p className="text-2xl font-bold">
                      {" "}
                      Get {data?.total_earn_from_points} {currency} of on{" "}
                      {data?.earn_points} points
                    </p> */}
                    <p className="text-2xl font-bold">
                      {" "}
                      {selectedLanguage?.name == "English" ? "Get" : translation[1106]?.value ? translation[1106]?.value : "Get"} {data?.total_earn_from_points} {selectedLanguage?.name == "English" ? "amount for every" : translation[1109]?.value ? translation[1109]?.value : "amount for every"}{" "}
                      {data?.earn_points} {selectedLanguage?.name == "English" ? "points" : translation[1104]?.value ? translation[1104]?.value : "points"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddCashBackCard;
