import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import { BASE_URL, BaseUrlWithoutV1, contact_us_form_url, news_letter_url } from "../api_variables";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import axios from "axios";

export const submitContactUsForm = async (payload, success, fail) => {
    try {
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BaseUrlWithoutV1}${contact_us_form_url}`, formData);
        if (response?.status == 200) {
            toast.success(response?.data?.response?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail();
        return
    }
}

export const subscribeNewsLetter = async (payload, success, fail) => {
    try {
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${news_letter_url}`, formData);
        if (response?.status == 200) {
            if (response?.data?.status_code == 200) {
                toast.success(response?.data?.response?.message, { toastId: 'toast' })
                success && success()
            } else {
                toast.error(response?.data?.response?.error_message || response?.data?.response?.message, { toastId: 'toast' })
                fail && fail()
            }
        }
    } catch (error) {
        toast.error(error?.response?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail();
        return
    }
}