import Svgs from "Assets/svgs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { resendVerificationCodeToPhoneNumberOrEmail } from "../../../Adapters/Api";
import useValidations from "../../../Hooks/useValidations";
import LoginBtn from "../../TenantApp/Elements/LoginBtn";
import LoginInput from "../../TenantApp/Elements/LoginInput";
import PhoneInput from "../../TenantApp/Elements/PhoneInput";
import useObjectKeys from "Hooks/useObjectKeys";

const VerificationForm = ({ method_type, ignore_activity, ...props }) => {
  let active = false;
  const { errors, setErrors, onBlurHandler, onFocusHandler, validation } =
    useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const [email, setemail] = useState("");
  const [mobile_number, setMobileNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [isEmailvalid, setIsEmailValid] = useState(true)

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setemail(value);
      setErrors({});
    } else {
      setemail(value);
      setMobileNumber(value);
      setErrors({});
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  //enabled or disabled submit button
  if (email || mobile_number) {
    active = true;
  }

  const onSubmitHandler = async () => {
    setErrors(validation({ email }));
    const result = validation({ email });
    if (objectKeyConvertToArray(result)?.length === 0) {
      const isValidEmail = validateEmail(email);
      if (isValidEmail) {
        setIsEmailValid(isValidEmail);
        setLoading(true);
        const response = await resendVerificationCodeToPhoneNumberOrEmail({
          email: email,
          code_for: method_type,
          ignore_activity: ignore_activity ? true : false
        });

        if (response && response?.status === 200) {
          //set select verification method like mobile, email
          props.onSendCode({ sent_to: email });
          let successMessage = response?.data?.response?.message;
          toast.success(successMessage, { toastId: "toast" });
        } else {
          toast.error(response?.response?.data?.response?.message, { toastId: "toast" });
        }
        setLoading(false);
      } else {
        setLoading(false)
        setIsEmailValid(isValidEmail);
        const element = document.querySelector(".topscroll");
        if (element) {
          element.scrollIntoView();
        }
      }
    } else {
      const element = document.querySelector(".topscroll");
      if (element) {
        element.scrollIntoView();
      }
    }

  };

  return (
    <>
      <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center flex items-center gap-2">
        <Svgs.Back link="/auth/login/?account_type=business" />
        Account <span className="font-semibold text-black">Verification?</span>
      </h1>
      <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
        Enter the email address associated with your <br /> account.
      </p>
      {method_type === "Email" ? (
        <LoginInput
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          onBlur={() => onBlurHandler({ email: email })}
          onFocus={() => onFocusHandler({ email: "" })}
          title="Email Address"
          placeholder="Enter your email address"
          error={errors?.email || (!isEmailvalid && 'Email is invalid')}
          onEnterSubmit={async () => {
            await onSubmitHandler();
            setLoading(false)
          }}
        />
      ) : (
        <PhoneInput
          required={true}
          name="mobile_number"
          title="Phone Number"
          value={mobile_number}
          onChange={handleChange}
          onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
          onFocus={() => onFocusHandler({ mobile_number: "" })}
          placeholder="Enter your phone number"
          error={errors?.mobile_number}
          onEnterSubmit={async () => {
            await onSubmitHandler();
            setLoading(false)
          }}
        />
      )}
      {/* <p className='underline text-primary text-sm cursor-pointer' onClick={props.onCancel}>Try another way</p> */}
      <div className="flex lg:justify-start justify-center">
        <LoginBtn
          loading={loading}
          disabled={loading}
          onClick={async () => {
            await onSubmitHandler();
            setLoading(false)
          }}
        >
          <div className="flex items-center gap-2">
            <div className="">
              Next
            </div>
            <Svgs.NextArrow />
          </div>
        </LoginBtn>
      </div>
    </>
  );
};

VerificationForm.defaultProps = {
  onSendCode: () => { },
  method_type: "Email",
};

export default VerificationForm;
