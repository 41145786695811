import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "new-components/location/helper";
import { GetSaleReportCSVExcelDataApi, getVoucherSaleReportApi } from "Adapters/Api/salesReport";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

let timeOutId = undefined
const filterInitialData = {
    staff: "",
    voucher: ""
}
const PageSize = 10
export const useVoucherSaleReport = (clientData) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { locationDetail } = useLocation();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");

    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const voucherData = state?.saleReport?.voucherData
    const paginationData = state?.saleReport?.voucherPaginationData
    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const location_currency = state?.utility?.currency?.location_currency;
    const { locations } = useSelector((state) => state);
    const { selected_location } = locations;

    // states
    const [loading, setLoading] = useState(true)
    const [search_text, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState(filterInitialData);
    const [loadingPermission, setLoadingPermission] = useState(true)
    const [InvoicePopup, setInvoicePopup] = useState(false);
    const [csvLoading, setCSVLoading] = useState(false)
    const [voucherDetail, setVoucherDetail] = useState(false)

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    useEffect(() => {
        if (search_text === null) {
            // Initial load with empty search_text 
            getVoucherReportData()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getVoucherReportData()
            }, 500);
        }
    }, [currentPage, search_text, selected_location, filterData, startDate, endDate])

    useEffect(() => {
        setLoading(true)
        setCurrentPage(1)
    }, [selected_location, filterData, startDate, endDate])

    useEffect(() => {
        setCurrentPage(1)
    }, [search_text])

    // handle export CSV Data
    const handleExportCSVData = () => {
        setCSVLoading(true)
        const success = () => {
            setCSVLoading(false)
        }
        const fail = () => {
            setCSVLoading(false)
        }
        let payload = {
            location_id: selected_location,
            data_type: "vouchers",
            exportName: "Vouchers"
        }
        if (clientData?.id) {
            payload = {
                ...payload,
                client_id: clientData?.id
            }
        }
        dispatch(GetSaleReportCSVExcelDataApi(access_token, payload, success, fail))
    }

    // apply filter
    const handleApplyRemoveFilter = (type, data) => {
        if (type == "apply") {
            setFilterData(data)
        } else {
            setFilterData(filterInitialData)
        }
        setCurrentPage(1)
        setShowFilter(false)
    }

    // get voucher report data
    const getVoucherReportData = async () => {
        setLoading(true)
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        let payload = {
            location_id: selected_location,
            start_date: startDate,
            end_data: endDate,
            search_text: search_text,
            page: currentPage
        }
        if (clientData?.id) {
            payload = {
                ...payload,
                client_id: clientData?.id
            }
        }
        dispatch(getVoucherSaleReportApi(access_token, payload, success, fail))
    }

    // handle success callback
    const successCallback = () => {
        setCurrentPage(1)
        setSearchText("")
        getVoucherReportData(selected_location, 1, "")
    }

    return {
        navigate, setSearchText, currentPage, setCurrentPage, search_text, selected_location, locationDetail, loading, translation,
        selectedLanguage, employee, setEmployee, employeePermissions, setEmployeePermissions, PageSize, isPermissionUpdated,
        successCallback, startDate, setStartDate, endDate, setEndDate, handleApplyRemoveFilter, showFilter, setShowFilter,
        loadingPermission, voucherData, paginationData, InvoicePopup, setInvoicePopup, location_currency, filterData,
        csvLoading, handleExportCSVData, voucherDetail, setVoucherDetail 
    };
};
