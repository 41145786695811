import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useService } from "../Services/helper";
import { getsProductsQuickSales } from "Adapters/Api/Products";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { useLocation } from "new-components/location/helper";
import { PRODUCT } from "Redux/TenantRedux/ActionTypes/productTypes";
import { getServiceGroup } from "Adapters/Api/saleServices";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
// import { get_client_discount_promotion } from "Adapters/Api/clientDiscountPromotion";
let timeOutId = undefined

export const useSaleProduct = () => {
  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const cartFromLocalStorage = getDataFromLocalStorage('cart_items')

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { removedFromCart } = useService();
  const { locationDetail } = useLocation();
  const state = useSelector(
    (state) => state
  );
  const { product, locations, cartItem, business, utility } = useSelector(
    (state) => state
  );

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const { products, products_updated } = product;
  const productsCount = product?.allCount
  const { selected_location } = locations;
  const businessCurrency = business;
  const { cart_items } = cartItem;
  const location_currency = utility?.currency?.location_currency;
  const isPermissionUpdated = state?.user?.isPermissionUpdated;

  // const [productsData, setProductsData] = useState([])
  // const [listArr, setListArr] = useState([]);
  const [page_loading, setPage_loading] = useState(true)
  const [search_text, setSearchText] = useState("");
  const [product_details, setProductDetails] = useState({});
  const [addProductPopup, setAddProductPopup] = useState(false);
  const [seletectedProduct, setSeletectedProduct] = useState([]);
  const [OpenCart, setOpenCart] = useState(false);
  const [availOfferPopup, setAvailOfferPopup] = useState(false)
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [lastPage, setLastPage] = useState()
  const [updatedProducts, setUpdatedProducts] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1)

  const PageSize = 10

  // const discountPromotions = state?.clientDiscountPromotion?.discountPromotions;

  const getProductList = async (selected_location, page, search_text) => {
    setPage_loading(true)
    const quickSales = true
    const response = await getsProductsQuickSales(selected_location, page, search_text, quickSales);
    setPages(response?.data?.response?.pages)
    if (response.status === 200) {
      setPage_loading(false)
      dispatch(getProducts(response?.data?.response?.products))
      let pages = response?.data?.response?.pages
      let count = response?.data?.response?.count
      dispatch({
        type: PRODUCT.GET_PRODUCT_PAGES,
        payload: pages
      })
      dispatch({
        type: PRODUCT.GET_ALL_COUNT,
        payload: count
      })
    }
  }

  // handle success callback
  const successCallback = () => {
    setCurrentPage(1)
    setSearchText("")
    getProductList(selected_location, 1, "")
  }

  const handleSelection = (id, crnt_stock, price, discount_price) => {
    // getProductList()
    if (!seletectedProduct.includes(id)) {
      setSeletectedProduct([...seletectedProduct, id]);
    } else {
      setSeletectedProduct(seletectedProduct.filter((item) => item != id));
    }

    const result = updatedProducts?.find((itm) => itm.id === id);
    let payloadData = {
      ...result,
      current_stock: crnt_stock,
      quantity: 1,
      price,
      selection_type: "PRODUCT",
    }
    if ((discount_price || discount_price == 0) && discount_price !== undefined) {
      payloadData = { ...payloadData, discount_price }
    }
    dispatch({
      type: "ADD_TO_CART",
      payload: payloadData,
    });
    let updatedCartForLocal = []
    if (cartFromLocalStorage?.length > 0 && cartFromLocalStorage != undefined) {
      updatedCartForLocal = [...cartFromLocalStorage]
    }
    updatedCartForLocal = [...updatedCartForLocal, payloadData]
    storeDataInLocalStorage('cart_items', updatedCartForLocal)
    // dispatch({
    //   type: "ADD_TO_CART",
    //   payload: {
    //     ...result,
    //     current_stock: crnt_stock,
    //     quantity: 1,
    //     price,
    //     selection_type: "PRODUCT",
    //     discount_price: result?.discount_price ? result?.discount_price : undefined
    //   },
    // });
    // toast.success(selectedLanguage?.name == "English" ? "Item added to cart" : translation[251]?.value ? translation[251]?.value : "Item added to cart")
  };


  // useEffect(() => {
  //   getProductList(currentPage)
  // }, [currentPage, selected_location])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text 
      getProductList(selected_location, currentPage, "")
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        getProductList(selected_location, currentPage, search_text)
      }, 500);
    }
  }, [currentPage, search_text, selected_location])

  useEffect(() => {
    setPage_loading(true)
    setCurrentPage(1)
  }, [selected_location])

  // useEffect(() => {
  //   dispatch(getAllProductsWithNoPagination())
  // }, [selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    //   getProductList(currentPage);
    // } else {
    // getProductList();
    setUpdatedProducts(products);
    // if (products?.length !== 0) {
    //   const val = products?.map((element) => {
    //     const data = element?.location_quantities?.filter(
    //       ({ location }) => location?.id === selected_location
    //     );
    //     if (data.length > 0 && data !== undefined) return { ...element };
    //   });
    //   const productArr = val.filter((element) => element !== undefined);
    // }
  }, [products, products?.length]);

  // useEffect(() => {
  //   // if (productsData?.some(item => item?.is_active && item?.currency_retail_price?.some(itm => itm?.currency == locationDetail?.currency?.id))) {
  //   //   const val = productsData?.map((element) => {
  //   //     const data = element?.location_quantities?.filter(
  //   //       ({ location }) => location?.id === selected_location
  //   //     );
  //   //     if (data.length > 0 && data !== undefined) return { ...element };
  //   //   });
  //   //   const productArr = val?.filter((element) => element !== undefined);
  //   //   setUpdatedProducts(productArr);
  //   // } else {
  //   //   setUpdatedProducts([])
  //   // }

  //   setUpdatedProducts(productsData)
  // }, [productsData, productsData?.length, locationDetail, selected_location, state.product.products, products, products?.length]);

  //   prodcut details
  const handleProductDetails = (productId, retail) => {
    setAddProductPopup(true);
    const res = products?.find(({ id }) => id === productId);
    setProductDetails({ ...res, retail_price: retail });
  };


  useEffect(() => {
    dispatch(getServiceGroup());
  }, []);

  // const FilterProduct = () => {
  //   // let filtered_prods = listArr?.filter((itm) =>
  //   //   itm.name.toLowerCase().includes(search_text.toLowerCase())
  //   // );
  //   let filtered_prods = listArr
  //   setProductsData(filtered_prods)
  //   return filtered_prods
  // };

  return {
    dispatch, navigate, setSearchText, handleSelection, removedFromCart, setAddProductPopup, handleProductDetails, getProductList,
    currentPage, setCurrentPage, pages, products, cart_items, search_text, product_details, addProductPopup, businessCurrency,
    products_updated, seletectedProduct, selected_location, location_currency, locationDetail, state, page_loading, updatedProducts,
    productsCount, OpenCart, setOpenCart, availOfferPopup, setAvailOfferPopup, translation, selectedLanguage, setUpdatedProducts,
    employee, setEmployee, employeePermissions, setEmployeePermissions, lastPage, setLastPage, PageSize, isPermissionUpdated,
    successCallback
  };
};
