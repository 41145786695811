import React from "react";
import LocationSelection from "new-components/location";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import useAppointmentsHelper from "./helper";
import Appointments from "./Appointments";
import ReversalRequest from "./ReversalRequest";

const SalesAppointments = () => {
  const { selectedLanguage, translation, tabList, setSearchParams, searchParams, active_tab
  } = useAppointmentsHelper()

  const tabsList = {
    "appointments": <Appointments />,
    "reversal-requests": <ReversalRequest />,
  };

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales"}
          second={selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
          url={'/dashboard/sales/'}
        />

        <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

          <div className="flex items-center gap-4">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
            </h2>
            <LocationSelection />
          </div>
        </div>

        <div className={"border-b mt-[2rem]"}>
          <div className="flex items-center gap-6">
            {tabList?.length > 0 &&
              tabList?.map((tab, index) => {
                return (
                  <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${active_tab == tab.value && "font-semibold border-primary"}`}
                    onClick={() => {
                      setSearchParams({ ...searchParams, active_tab: tab.value });
                    }}
                    key={index}
                  >
                    <h2 className="text-primary capitalize">{tab.label}</h2>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="mt-[3rem] mb-[3rem]">{active_tab && tabsList[active_tab]}</div>

      </div>
    </>
  );
};

export default SalesAppointments;
