import React, { useState } from 'react';
import Svgs from 'Assets/svgs';
import { error_message } from 'Constants/Variables';
import { useEffect } from 'react';

const FloatInput = ({
    onEnterSubmit,
    rightIcon,
    leftIcon,
    title,
    required,
    name,
    placeholder,
    value,
    onChange,
    error,
    disabled,
    parentClass,
    inputPadding,
    min,
    max,
    isPercentage,
    isCapitalized,
    errorClass,
    charactersMaxLength,
    firstLetterGreaterThanZero,
    maxDecimals }) => {
    const [sanitizedValue, setSanitizedValue] = useState('');
    const parsedPlaceholder = isCapitalized ? placeholder : placeholder && placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

    useEffect(() => {
        let inputValue = value ? (typeof value == 'string') ? value?.replace(/[^0-9.]/g, '') : value : ''
        // // Allow only one dot in the value
        // const parts = inputValue ? inputValue?.split('.') : '';
        // if (parts) {
        //     const firstPart = parts.shift(); // Get the first part (before the first dot)
        //     inputValue = firstPart + (parts.length > 0 ? '.' + parts.join('') : '');
        // }
        setSanitizedValue(inputValue)
    }, [value])

    const handleInputChange = (e) => {
        if (firstLetterGreaterThanZero && e.target.value === '0') {
            e.target.value = '';
        } else {
            let inputValue = e.target.value;

            // Allow only digits and at most one dot
            inputValue = inputValue.replace(/[^0-9.]/g, '');

            // Ensure only one dot is allowed
            const parts = inputValue.split('.');
            const firstPart = parts.shift(); // Get the integer part
            let decimalPart = parts.length > 0 ? parts.join('') : '';

            // Allow typing the dot but prevent multiple dots
            inputValue = firstPart + (parts.length > 0 ? '.' + decimalPart : '');

            // Limit the decimal places (only if a dot exists)
            if (decimalPart.length > maxDecimals && maxDecimals) {
                decimalPart = decimalPart.slice(0, maxDecimals);
                inputValue = firstPart + '.' + decimalPart;
            }

            // Validate maximum character length
            if (charactersMaxLength && inputValue.length > charactersMaxLength) {
                inputValue = inputValue.slice(0, charactersMaxLength);
            }

            // Validate percentage (should not exceed 100)
            if (isPercentage && parseFloat(inputValue) > 100) {
                inputValue = '100';
            }

            e.target.value = inputValue;
            setSanitizedValue(inputValue);

            // Call external onChange handler (if provided)
            if (typeof onChange === 'function') {
                onChange(e);
            }
        }
    };


    const HandleKeyPress = (e) => {
        // Allow navigation keys and control keys (copy, paste, select, etc.)
        const allowedKeys = [
            'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab',
            'Control', 'Meta', 'Shift', 'Home', 'End', 'Enter'
        ];

        const key = e.key;

        // Allow keyboard shortcuts (Ctrl+A, Ctrl+C, Ctrl+V, etc.)
        if ((e.ctrlKey || e.metaKey) && ['a', 'c', 'v', 'x'].includes(key.toLowerCase())) {
            return; // Allow these actions without preventing them
        }


        // Allow only numbers, a single decimal point, and control keys
        const isNumber = /^\d$/; // Match a single digit (0-9)
        const isDecimal = e.key === '.';

        // Check if the key is allowed (numbers, decimal point, or control keys)
        if (!allowedKeys.includes(e.key) && !isNumber.test(e.key) && !isDecimal) {
            // Prevent the default behavior for disallowed keys
            e.preventDefault();
        }

        // Disallow multiple decimal points
        if (isDecimal && e.target.value.includes('.')) {
            e.preventDefault();
        }

        // Execute submit action on Enter key
        if (e.key === 'Enter') {
            onEnterSubmit();
        }
    };

    const handleBlur = () => {
        if (sanitizedValue && sanitizedValue?.endsWith('.')) {
            const updatedValue = sanitizedValue?.slice(0, -1)
            setSanitizedValue(updatedValue); // Remove the last character (dot)
            // Call external onChange handler (if provided)
            if (typeof onChange === 'function') {
                let event = {
                    target: {
                        name: name,
                        value: updatedValue
                    }
                }
                onChange(event);
            }
        }
    };

    return (
        <div className={`${parentClass} flex flex-col gap-2 relative `}>
            {title != "" && (
                <div className="text-sm flex items-center gap-1">
                    <div>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            {rightIcon ? (
                <div className="w-full relative">
                    <input
                        onKeyDown={HandleKeyPress}
                        min={min}
                        max={max}
                        name={name}
                        disabled={disabled}
                        maxLength={charactersMaxLength}
                        type="text"
                        value={sanitizedValue}
                        onChange={handleInputChange}
                        placeholder={parsedPlaceholder}
                        onBlur={handleBlur}
                        className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
                            } ${inputPadding} input w-full lg:pr-8 pr-7 `}
                    />
                    <div className="absolute top-1/2 -translate-y-1/2 right-3 text-sm input-right-icon">
                        {rightIcon}
                    </div>
                </div>
            ) : leftIcon ? (
                <div className="w-full relative">
                    <div className="absolute top-1/2 -translate-y-1/2 left-3">
                        {leftIcon}
                    </div>
                    <input
                        onKeyDown={HandleKeyPress}
                        min={min}
                        max={max}
                        name={name}
                        disabled={disabled}
                        maxLength={charactersMaxLength}
                        type="text"
                        value={sanitizedValue}
                        onChange={handleInputChange}
                        placeholder={parsedPlaceholder}
                        onBlur={handleBlur}
                        className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
                            } ${inputPadding} input w-full lg:pl-8 !pl-7`}
                    />
                </div>
            ) : (
                <input
                    onKeyDown={HandleKeyPress}
                    min={min}
                    max={max}
                    name={name}
                    disabled={disabled}
                    maxLength={charactersMaxLength}
                    type="text"
                    value={sanitizedValue}
                    onChange={handleInputChange}
                    placeholder={parsedPlaceholder}
                    onBlur={handleBlur}
                    className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
                        } ${inputPadding}  input `}
                />
            )}
            {error && typeof error == "string" ? (
                <p className={`text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
                    <Svgs.I fill="#eb3b3b" />
                    {error}
                </p>
            ) : typeof error == "boolean" && error == true ? (
                <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                    <Svgs.I fill="#eb3b3b" /> {error_message}
                </p>
            ) : (
                ""
            )}
        </div>
    );
};

export default FloatInput;