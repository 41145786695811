

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', "July", 'August', 'September', 'October', 'November', 'December']
export const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const WEEKDAYS_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const MONTHS_DURATIONS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]


let currentYear = new Date().getFullYear();
let years = [];

for (let year = 2021; year <= currentYear; year++) {
    years.push({ label: year.toString(), value: year.toString() });
}
export const YearsDropdown = years

export const MonthData = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
];

export const DateFilterTypes = [
    { label: 'All', value: '' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'This Week', value: 'this_week' },
    { label: 'Last Week', value: 'last_week' },
    { label: 'This Year', value: 'this_year' },
    { label: 'Last Year', value: 'last_year' },
    { label: 'Custom', value: 'custom' }
]

export const maintainencePriorityLevel = [
    { label: 'Medium', value: 'Medium' },
    { label: 'High', value: 'High' },
    { label: 'Critical', value: 'Critical' }
]

export const maintainenceStatus = [
    { label: 'Pending', value: 'Pending' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Resolved', value: 'Resolved' },
    { label: 'Rejected', value: 'Rejected' }
]

export const statusClasses = {
    Pending: 'bg-secondary-peach text-primary-orange ',
    'In Progress': 'bg-secondary-lightBlue text-primary-blue',
    Rejected: 'bg-secondary-pink text-primary-red',
    Resolved: 'bg-secondary-lightGreen text-primary-green',
    'Not Resolved': 'bg-secondary-pink text-primary-red',
};

export const complaintStatus = [
    // { label: 'All', value: '' },
    { label: 'Pending', value: 'Pending' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Resolved', value: 'Resolved' },
    { label: 'Not Resolved', value: 'Not Resolved' }
]
