import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Popup from 'Components/TenantApp/Elements/Popup';
import React, { useEffect, useState } from 'react'
import useAddClient from './useAddClient';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import PhoneInput from 'Components/TenantApp/Elements/PhoneInput';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import I_Info_Icon from 'Components/TenantApp/Elements/I_Info_Icon';
import { useSearchParams } from 'react-router-dom';
import { getAllLanguages } from 'Adapters/Api';
import { getUniqueClientId } from 'Adapters/Api/clients';
import { toast } from 'react-toastify';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';

const AddClientPopup = ({ open, close, getClients, onSuccess }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const clientId = searchParams?.get("client_id");
    const [clientsSuccess, setClientsSuccess] = useState(false);

    const {
        clientsData, setClientsData,
        clientHandleChange,
        checkClientExistenceBeforeSubmit,
        errors, onBlurHandler, languageList, setLanguageList,
        finished, isEdit, loading, access_token,
        translation, selectedLanguage, formRef
    } = useAddClient(getClients, onSuccess);

    const { client_id, full_name, gender, is_active, mobile_number, language, allow_loyalty } = clientsData;

    //get all languages
    const getLanguageList = async () => {
        const response = await getAllLanguages();
        if (response.status === 200) {
            let languageList = response?.data?.response?.languages;
            setLanguageList(languageList)
        }
    }

    const fetchClientUniqueId = () => {
        getUniqueClientId(access_token).then((result) => {
            const res = result?.response?.id;
            let idToString = res.toString();
            let idSrigToArray = idToString.split("-");
            let shortDomian = idSrigToArray[0].split("").filter((i) => {
                if (i != "," && i != "'" && i != "[" && i != "]" && i != " ") { return i }
            }).join("").toUpperCase()

            idSrigToArray.splice(0, 1, shortDomian)
            setClientsData((prev) => ({
                ...prev,
                client_id: idSrigToArray.join("-")
            }))
        }).catch((err) => {
            toast.error("Error in get employee id")
        })
    }


    useEffect(() => {
        if (!clientId) {
            fetchClientUniqueId()
        }
        getLanguageList()
    }, [])

    useEffect(() => {
        if (finished) {
            close()
        }
    }, [finished])

    return (
        <Popup
            heading={selectedLanguage?.name == "English"
                ? "Add Client"
                : translation[652]?.value
                    ? translation[652]?.value
                    : "Add Client"
            }
            close={close}
            open={open}
        // onclose={() => setIsDelete(false)}
        >
            <>
                <div className='bounce-in-right topscroll' ref={formRef}>
                    <div className='flex flex-col gap-5'>
                        <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col py-2'>
                            <CircleIcon>
                                <Svgs.Profile />
                            </CircleIcon>
                            <div className='flex flex-col gap-1 lg:text-left text-center'>
                                <h2 className='text-primary font-semibold text-xl'>{selectedLanguage?.name == "English" ? "Client Information" : translation[490]?.value ? translation[490]?.value : "Client Information"}</h2>
                                <p className='text-[#7B7B7B] text-sm'>
                                    {selectedLanguage?.name == "English" ? "Provide all the information about the client for the" : translation[493]?.value ? translation[493]?.value : "Provide all the information about the client for the"} <br className='hidden md:block' />
                                    {selectedLanguage?.name == "English" ? "records." : translation[497]?.value ? translation[497]?.value : "records."}</p>
                            </div>
                        </div>
                        <div className='flex items-center flex-col gap-5 justify-center'>

                            <div className='w-full grid sm:grid-cols-2 gap-5'>
                                <LoginInput
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Full Name" : translation[472]?.value ? translation[472]?.value : "Full Name"}
                                    name={"full_name"}
                                    specialCharacterNotAllowed={true}
                                    onEnterSubmit={checkClientExistenceBeforeSubmit}
                                    value={full_name}
                                    // charactersMaxLength={25}
                                    onChange={clientHandleChange}
                                    onBlur={() => onBlurHandler({ full_name: full_name })}
                                    error={errors?.full_name}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Your Name" : translation[509]?.value ? translation[509]?.value : "Enter Your Name"} />

                                <LoginInput
                                    required={true}
                                    name={"client_id"}
                                    value={client_id}
                                    onEnterSubmit={checkClientExistenceBeforeSubmit}
                                    onChange={clientHandleChange}
                                    onBlur={() => onBlurHandler({ client_id: client_id })}
                                    error={errors?.client_id}
                                    title={
                                        <I_Info_Icon text={selectedLanguage?.name == "English" ? "Unique Id" : translation[511]?.value ? translation[511]?.value : "Unique Id"} info={selectedLanguage?.name == "English" ? "Client ID can be a unique identifier or contact HR deparment for client ID" : translation[512]?.value ? translation[512]?.value : "Client ID can be a unique identifier or contact HR deparment for client ID"} />
                                    }
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Your Name Unique Id" : translation[513]?.value ? translation[513]?.value : "Enter Your Name Unique Id"}
                                    disabled={client_id ? true : false}
                                />
                            </div>
                            <div className='w-full grid sm:grid-cols-2 gap-5'>

                                <Dropwdown
                                    required={true}
                                    name={"gender"}
                                    value={gender}
                                    onChange={clientHandleChange}
                                    onBlur={() => onBlurHandler({ gender: gender })}
                                    error={errors?.gender}
                                    title={selectedLanguage?.name == "English" ? "Gender" : translation[479]?.value ? translation[479]?.value : "Gender"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Gender" : translation[516]?.value ? translation[516]?.value : "Select Gender"}
                                    options={[
                                        { label: selectedLanguage?.name == "English" ? "Male" : translation[86]?.value ? translation[86]?.value : "Male", value: 'Male' },
                                        { label: selectedLanguage?.name == "English" ? "Female" : translation[87]?.value ? translation[87]?.value : "Female", value: 'Female' }
                                    ]}
                                />

                                <PhoneInput
                                    required={true}
                                    name={"mobile_number"}
                                    value={mobile_number}
                                    onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
                                    onChange={clientHandleChange}
                                    error={errors?.mobile_number}
                                    title={selectedLanguage?.name == "English" ? "Phone Number" : translation[62]?.value ? translation[62]?.value : "Phone Number"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter your phone number" : translation[514]?.value ? translation[514]?.value : "Enter your phone number"}
                                    onEnterSubmit={checkClientExistenceBeforeSubmit}
                                    edit={isEdit ? true : false}

                                />
                            </div>
                        </div>
                        <div className='grid sm:grid-cols-2 gap-5'>
                            <Dropwdown
                                required={true}
                                name={"is_active"}
                                value={is_active}
                                onChange={clientHandleChange}
                                onBlur={() => onBlurHandler({})}
                                error={errors?.is_active}
                                title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
                                options={[
                                    {
                                        value: true,
                                        label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                    },
                                    {
                                        value: false,
                                        label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                                    },
                                ]}
                            />

                            <Dropwdown
                                required={true}
                                name="language"
                                value={language}
                                onChange={clientHandleChange}
                                onBlur={() => onBlurHandler({ language: language })}
                                error={errors.language}
                                options={languageList?.map((lng) => {
                                    return { label: lng.name, value: lng.id }
                                })}
                                errorClass={"!static"}
                                title={selectedLanguage?.name == "English" ? "Preferred Language" : translation[522]?.value ? translation[522]?.value : "Preferred Language"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Preferred Language" : translation[523]?.value ? translation[523]?.value : "Select Preferred Language"}
                            />

                            <CheckBox
                                name="allow_loyalty"
                                onChange={clientHandleChange}
                                value={allow_loyalty}
                                checked={allow_loyalty}
                                label_Class={"!normal-case"}
                                label={"Allow loyalty"}
                            />
                        </div>
                        <div className='flex items-center justify-end'>
                            <LoginBtn
                                className={"!px-7"}
                                loading={loading}
                                onClick={() => {
                                    checkClientExistenceBeforeSubmit();
                                }}
                                disabled={loading}
                                // || !language
                                // || !mobile_number
                                // || mobile_number?.split("-", 2)[1]?.length < 8
                                // || mobile_number?.split("-", 2)[1]?.length > 12
                                // || errors?.mobile_number
                                // || !full_name}
                                // value={details}
                                // onChange={(e)=>{setDetails(e.target.value)}}
                                text={`${clientId ?
                                    selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                                    : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}`}
                            />
                        </div>
                    </div>
                </div>
            </>
        </Popup>
    )
}

export default AddClientPopup