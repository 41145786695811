import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Svgs from "../../../../../Assets/svgs";
import LoginBtn from "../../../Elements/LoginBtn";
import Popup from "../../../Elements/Popup";
import { useSelector } from "react-redux";
import moment from "moment";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";
import ThreeDotMenu from "Components/TenantApp/Elements/ThreeDotMenu";

const ClientCard = ({ data, onEdit, index, onDelete, animate, countryName, translation, selectedLanguage, hideDeleteIcon }) => {
  const state = useSelector((state) => state);
  const navigate = useNavigate()

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(null);

  // *******************handle delete modal *********************
  const deleteModelHandler = () => {
    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>

      {animate ?
        // Shimmer
        <div className="rounded-[10px] border border-lightGray p-3 flex flex-col space-y-3 relative">
          <div className="flex flex-col text-center space-y-1">
            <div className="flex items-center mx-auto justify-between">
              <div className="h-[7.5rem] w-[7.5rem] rounded-full relative bg-gray-200"> </div>
            </div>
            <div className="h-6 w-32 bg-gray-200 rounded-md mx-auto"></div>
          </div>
          <hr className="border-lightGray" />
          <div className="flex flex-col space-y-1">
            <div className="flex items-center">
              <p className="bg-gray-200 rounded-[4px] h-5 my-1 w-6 mx-1"></p>
              <p className="bg-gray-300 rounded-[4px] h-3 my-1 w-32 mx-1"></p>
            </div>
            <div className="flex items-center">
              <p className="bg-gray-200 rounded-[4px] h-5 my-1 w-6 mx-1"></p>
              <p className="bg-gray-300 rounded-[4px] h-3 my-1 w-32 mx-1"></p>
            </div>
            <div className="flex items-center">
              <p className="bg-gray-200 rounded-[4px] h-5 my-1 w-6 mx-1"></p>
              <p className="bg-gray-300 rounded-[4px] h-3 my-1 w-32 mx-1"></p>
            </div>
          </div>
        </div>
        :
        <div className="rounded-[10px] border border-lightGray p-3 flex flex-col space-y-3 relative">
          <div className="flex flex-col text-center space-y-1">
            <div className="flex items-center mx-auto justify-between">
              <div className="h-[7.5rem] w-[7.5rem] rounded-full relative">
                {data?.image ? (
                  <img src={data?.image} className="h-full w-full object-cover rounded-full" />
                ) : (
                  <LabelAvatar iconLabel={data?.full_name} index={index} className="rounded-full h-[7.5rem] w-[7.5rem]" fontSize={"!text-lg"} />
                )}
                <span className={`absolute z-10 bottom-1 right-4 h-4 w-4 rounded-full ${data?.is_active ? 'bg-green-500' : 'bg-red-500'}  content-['']`} />
              </div>
            </div>
            <h1 className="font-semibold font-2xl line-clamp-1">
              {data?.full_name}
            </h1>
          </div>
          <div className="absolute top-2 right-2 cursor-pointer">
            <ThreeDotMenu
              visible={dropdownVisible === data?.id}
              dropdownVisible={dropdownVisible}
              setDropdownVisible={setDropdownVisible}
              index={data.id}
            >
              <div className={`absolute right-0 top-10 !w-[200px] py-2 bg-white border border-gray-300 rounded-md shadow-lg z-50`}>
                {!employee ? (
                  <button
                    className="w-full text-left p-[10px] font-medium text-primary-black flex gap-2 items-center hover:bg-[#E8ECFF]"
                    onClick={() => navigate(`/dashboard/clients/sale-history/${data?.id}`)}
                  >
                    <Svgs.Eye width={24} height={24} stroke={"#646F80"} />
                    View Sale History
                  </button>
                ) : ""}
                {!employee ||
                  (employee &&
                    employeePermissions?.client_profile?.includes("edit")) ? (
                  <button
                    className="w-full text-left p-[10px] font-medium text-primary-black flex gap-2 items-center hover:bg-[#E8ECFF]"
                    onClick={() => onEdit(data?.id)}
                  >
                    <Svgs.EditIcon width={24} height={24} stroke={"#646F80"} />
                    Edit
                  </button>
                ) : ""}
                {(!employee && !hideDeleteIcon) ? (
                  <button
                    className="w-full text-left p-[10px] font-medium text-primary-black flex gap-2 items-center hover:bg-[#E8ECFF]"
                    onClick={() => deleteModelHandler()}
                  >
                    <Svgs.DeleteIcon fill="#646F80" />
                    Delete
                  </button>
                ) : ""}
              </div>
            </ThreeDotMenu>
          </div>
          <hr className="border-lightGray" />
          <div className="flex flex-col space-y-2 !text-primary-text !text-sm">
            <div className="flex items-center gap-2">
              <Svgs.EmailIcon2 />
              <p className="line-clamp-1 !text-ellipsis">{data?.email ? data?.email : "------"}</p>
            </div>
            <div className="flex items-center gap-2">
              <Svgs.PhoneIcon2 />
              <p className="line-clamp-1">{data?.mobile_number ? data?.mobile_number : "------"}</p>
            </div>
            <div className="flex items-center gap-2">
              <Svgs.LocationPin stroke={"#646F80"} width={20} height={20} />
              <p className="line-clamp-1">{data?.country_obj?.name ? data?.country_obj?.name : "------"}</p>
            </div>
            <div className="flex items-center gap-1">
              <p className="font-semibold">Last Transaction: </p>
              <p className="line-clamp-1">{data?.last_transaction_date ? moment(data?.last_transaction_date).format('DD-MM-YYYY') : "------"}</p>
            </div>
          </div>
        </div>
      }

      {deleteModal && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Client?" : translation[464]?.value ? translation[464]?.value : "Are you sure want to delete this Client?"}
          onclose={deleteModelHandler}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English" ? "Are you sure you want to delete this Client? You won't be able to see this again." : translation[463]?.value ? translation[463]?.value : "Are you sure you want to delete this Client? You won't be able to see this again."}
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={async () => {
                  await onDelete(data?.id);
                  deleteModelHandler();
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ClientCard;
