import Svgs from 'Assets/svgs';
import React from 'react';

const FeatureCard = ({ number, title, description, icon: Icon }) => (
    <div className="bg-white p-8 border border-[#BDC7D4] hover:border-primary-blue rounded-3xl shadow-sm hover:shadow-md transition-shadow relative">
        {/* Number */}
        <span className="absolute top-6 right-6 text-[3.75rem] font-bold text-[#DBE5F2]">
            {number}
        </span>

        {/* Icon */}
        <div className="mb-6 text-primary-blue">
            {Icon}
        </div>

        {/* Content */}
        <h3 className="text-2xl font-semibold mb-1.5 text-black">{title}</h3>
        <p className="text-black text-base">{description}</p>
    </div>
);

const SmartFeatures = () => {
    const features = [
        {
            title: "Wide Range of Choices",
            description: "Explore multiple brands and shops in one place, offering a variety of products and services to suit your needs.",
            icon: <Svgs.RangeChoices />
        },
        {
            title: "Easy & Secure Payments",
            description: "Enjoy hassle-free transactions with multiple payment options and top-tier security.",
            icon: <Svgs.SecurePayment />
        },
        {
            title: "Seamless Booking & Orders",
            description: "Schedule appointments or place orders effortlessly with a user-friendly interface.",
            icon: <Svgs.SeamlessBookings />
        },
        {
            title: "Real-Time Updates & Notifications",
            description: "Stay informed with instant alerts on offers, order status, and upcoming appointments.",
            icon: <Svgs.RealTimeUpdates />
        },
        {
            title: "Exclusive Deals & Discounts",
            description: "Unlock special promotions and seasonal discounts from your favorite brands.",
            icon: <Svgs.DealsAndDiscount />
        },
        {
            title: "24/7 Customer Support",
            description: "Get assistance anytime with dedicated support for your queries and concerns.",
            icon: <Svgs.CustomerSupport />
        }
    ];

    return (
        <div className="bg-secondary-white py-20 px-4">
            <div className="max-w-[1200px] mx-auto">
                {/* Heading */}
                <div className="text-center mb-12">
                    <div className="text-center mb-9 relative z-10">
                        <h1 className="text-black w-[95%] text-[3.125rem] font-bold max-w-[38rem] mx-auto leading-tight">
                            Smart Features for a
                            Seamless Experience
                        </h1>
                    </div>
                </div>

                {/* Features Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {features.map((feature, index) => (
                        <FeatureCard
                            key={index}
                            number={index + 1}
                            title={feature.title}
                            description={feature.description}
                            icon={feature.icon}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SmartFeatures;