import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import PreloaderFull from "../Elements/PreloaderFull";

import { getBusinessById, getLocations } from "Adapters/Api";
import useSearchParamsHandle from "Hooks/useSearchParamsHandle";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { fetchBusiness } from "Redux/TenantRedux/Actions/locationActions";
import { MAKE_LOGGEDIN_USER } from "Redux/TenantRedux/ActionTypes/UserActionTypes";

export default function Home() {
  const { storeDataInLocalStorage, getDataFromLocalStorage } =
    useSetDataInLocalStorage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allParams } = useSearchParamsHandle();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsValue = searchParams.get("user_login_id");
  const isEmployee = searchParams.get("isEmployee");
  const selected_location = searchParams.get("selected_location");
  const state = useSelector((state) => state);

  // get access_token from url
  let [{ key }] = allParams?.filter((item) => {
    if (item.value === "access_token") {
      return item;
    }
  });

  //  added access_token in localStorage
  let access_token = key;
  if (access_token) {
    storeDataInLocalStorage("access_token", access_token);
  }
  if (selected_location) {
    localStorage.setItem('selected_location', JSON.stringify(selected_location));
  }

  const getBusiness = async () => {
    const access_token = getDataFromLocalStorage("access_token");
    const user_id = getDataFromLocalStorage("user_id");
    const isEmployee = getDataFromLocalStorage("isEmployee");

    const response = await getBusinessById(user_id, isEmployee, access_token);

    if (response.status === 200) {
      let business = response?.data?.response?.business;
      dispatch(fetchBusiness(business));
      business?.id && dispatch(getLocations(business?.id));
      //rendere on dashboard or account setup screeen
      if (searchParamsValue) {
        //store userId in local storage
        storeDataInLocalStorage("user_id", searchParamsValue);
        storeDataInLocalStorage(
          "domain-name",
          window.location.hostname.split(".")[0]
        );

        //get next redirection path
        const [{ key }] = allParams.filter((item) => {
          if (item.value === "next_path") {
            return item;
          }
        });

        // redirect to next page/screen if user already completed basic setup go to dashboar else basic info page
        const nextPath = key;
        let redirect_params = ''
        let is_asked_for_data_update = searchParams.get('is_asked_for_data_update')
        if (is_asked_for_data_update == true) {
          redirect_params += 'ask_for_change_default_data=true'
          localStorage.setItem('ask_for_change_default_data', true)
        } else {
          localStorage.removeItem('ask_for_change_default_data')
        }
        if (business?.currency?.id) {
          navigate(`/dashboard?${redirect_params}`);
        } else {
          if (nextPath) {
            navigate(`${nextPath}?${redirect_params}`);
          }
        }
      }
    }
  };

  useEffect(() => {
    storeDataInLocalStorage("user_id", searchParamsValue);
    storeDataInLocalStorage("isEmployee", isEmployee);
    getBusiness();
    dispatch({
      type: MAKE_LOGGEDIN_USER,
      payload: true
    })
  }, []);

  return <PreloaderFull />;
}
