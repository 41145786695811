import { useState, useMemo } from "react";
import { useSelector } from "react-redux";

const useProductByBrandHelper = () => {
    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation;
    const selectedLanguage = state?.languages?.selected_language;
    const location_currency = state?.utility?.currency?.location_currency;
    const productSummaryData = state?.saleReport?.productSummaryData;
    const Brand = productSummaryData?.brand;

    // states
    const [searchText, setSearchText] = useState("");

    // Debounced search filtering
    const filteredBrand = useMemo(() => {
        const allData = Brand || []
        if (!searchText) return allData;
        return allData?.filter(brnd =>
            brnd?.brand_name?.toLowerCase().includes(searchText?.toLowerCase())
        );
    }, [searchText, Brand]);

    return {
        translation, selectedLanguage, location_currency, searchText, setSearchText, filteredBrand
    };
};

export default useProductByBrandHelper;
