import React, { useEffect, useState } from "react";
import Svgs from "../../../../../../Assets/svgs";
import CircleIcon from "../../../../Elements/CircleIcon";
import Dropwdown from "../../../../Elements/Dropwdown";
import LoginBtn from "../../../../Elements/LoginBtn";
import LoginInput from "../../../../Elements/LoginInput";
import { useDispatch, useSelector } from "react-redux";
import {
  createvouchers,
  deleteVoucher,
  getvouchers,
  setvouchers,
  updateVoucher,
} from "../../../../../../Adapters/Api/vouchers";
import { get_vouchers } from "../../../../../../Redux/TenantRedux/Actions/voucherActions";
import { toast } from "react-toastify";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import AddVoucherCard from "./AddVoucherCard";
import Popup from "../../../../Elements/Popup";
import I_Info_Icon from "../../../../Elements/I_Info_Icon";
import PreloaderFull from "../../../../Elements/PreloaderFull";
import { apiErrorMessage } from "Constants/Data/Errors";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { useRef } from "react";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import { ValidityTime } from "Constants/Data/AppointmentsData";

const Vouchers = () => {
  const formRef = useRef()
  const mainRef = useRef()
  const { objectKeyConvertToArray } = useObjectKeys()
  const state = useSelector((state) => state);
  const voucherRedux = state?.voucher?.voucher;
  const voucher_updated = state?.voucher?.voucher_updated;
  const businessCurrency = state.business.business;
  const currencyList = state?.utility?.currency?.currency_list;
  const locationList = state.locations.locations;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [is_delete, setDelete] = useState(false);
  const [edit_id, setEditId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [price, setPrice] = useState();
  const [voucherData, setvoucherData] = useState({
    is_active: true
  });
  const [voucherError, setvoucherError] = useState({});
  const [editVoucherError, setEditVoucherError] = useState({})
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const dispatch = useDispatch();
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [submitButtonClick, setSubmitButtonClick] = useState(false)
  const [apiLoading, setApiLoading] = useState(true)
  const { setErrors, validation, errors, onBlurHandler, setEditErrors, editErrors } = useValidations();

  const handlevoucherData = (e) => {
    const { name, value } = e.target;
    setvoucherData({
      ...voucherData,
      [name]: value,
    });
    if (name !== 'discount_percentage') {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };
  const fetchVoucherlist = async () => {
    setApiLoading(true)
    dispatch(getvouchers("", "", setApiLoading));
  };

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    fetchVoucherlist();
  }, []);

  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  let business = businessData.id;

  const { currency_voucher_price } = voucherData;


  const vouchersubmithandler = () => {
    setSubmitButtonClick(true)
    const { name, voucher_type, validity, sales, discount_percentage } = voucherData
    let requiredFields = { name, voucher_type, validity, sales, discount_percentage }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);
    if ((objectKeyConvertToArray(result)?.length === 0)) {
      if (allUinqueCurrencyList?.length != currency_voucher_price?.length) {
        setErrors((prev) => ({
          ...prev,
          price: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
        }))
        setLoading(false);
      }
      else {
        setErrors((prev) => ({
          ...prev,
          price: "" // Remove the error message
        }));
        setLoading(true);
        createvouchers(
          { ...voucherData, business: business },
          () => {
            setvoucherData({});
            setvoucherError({});
            setTimeout(() => {
              setLoading(false);
            }, 500);
            fetchVoucherlist();
            if (!isToastVisible) {
              toast.success("Voucher created Successfully", {
                toastId: "toast",
                onClose: () => setIsToastVisible(false),
              });
              setIsToastVisible(true);
              mainRef.current?.scrollIntoView({ behavior: "smooth" });
            }
          },
          () => {
            if (!isToastVisible) {
              toast.error("Voucher did not created", {
                toastId: "toast",
                onClose: () => setIsToastVisible(false),
              });
              setIsToastVisible(true);
            }
            setLoading(false);
          }
        );
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onVoucherUpdateHnadler = (edited_data, seteditId) => {
    const { name, voucher_type, validity, sales, discount_percentage } = edited_data
    let requiredFields = { name, voucher_type, validity, sales, discount_percentage }
    setEditErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if ((objectKeyConvertToArray(result)?.length === 0)) {
      if (allUinqueCurrencyList?.length != edited_data?.currency_voucher?.length) {
        setEditErrors((prev) => ({
          ...prev,
          price: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"

        }))
      }
      else {
        setEditErrors((prev) => ({
          ...prev,
          price: "" // Remove the error message
        }));
        setUpdateLoading(true)
        dispatch(
          updateVoucher(
            edited_data,
            () => {
              if (!isToastVisible) {
                toast.success("Promotion Updated successfully", {
                  toastId: "toast",
                  onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
              }
              seteditId('')
              setEditId(undefined);
              fetchVoucherlist();
              setUpdateLoading(false)
            },
            () => {
              if (!isToastVisible) {
                toast.error(apiErrorMessage, {
                  toastId: "toast",
                  onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
              }
              setUpdateLoading(false)
            }
          )
        );
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onVoucherEditClickHnadler = (id) => {
    setEditId(id);
  };

  const onVoucherDeleteHandler = (id) => {
    setDeleteLoader(true)
    dispatch(
      deleteVoucher(
        { id: id },
        () => {
          if (!isToastVisible) {
            toast.success("Voucher Deleted successfully", {
              toastId: "toast",
              onClose: () => setIsToastVisible(false),
            });
            setIsToastVisible(true);
          }
          fetchVoucherlist();
          setDeleteLoader(false)
        },
        () => {
          if (!isToastVisible) {
            toast.error(apiErrorMessage, {
              toastId: "toast",
              onClose: () => setIsToastVisible(false),
            });
            setIsToastVisible(true);
          }
          setDeleteLoader(false)
        }
      )
    );
  };

  let allUinqueCurrencyList = [];

  locationList?.filter((item) => item?.currency?.id)?.filter((itm) => {
    if (!allUinqueCurrencyList?.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
      allUinqueCurrencyList?.push(itm)
      return true
    }
  })

  //set currency and voucher price object here
  const addNewItem = () => {
    setvoucherData((prev) => {
      let prev_mm = prev?.currency_voucher_price ? prev?.currency_voucher_price : []
      return ({
        ...prev,
        currency_voucher_price: [
          ...prev_mm,
          { currency: selectedCurrency, price: price }
        ]
      })
    })
    setSelectedCurrency();
    setPrice()
  }


  // **************************** remove selected items *********************************
  const removedItem = (value, type) => {

    if ((type === "price")) {
      if (voucherData?.currency_voucher_price?.find((itm) => itm?.currency == value)) {
        setvoucherData((prev) => ({
          ...prev,
          currency_voucher_price: prev?.currency_voucher_price ? prev?.currency_voucher_price?.filter((itm) => itm?.currency != value) : [],
        }));
      }
    } else {
      if (voucherData?.currency_voucher_price?.find((itm) => itm?.currency == value)) {
        setvoucherData((prev) => ({
          ...prev,
          currency_voucher_price: prev?.currency_voucher_price ? prev?.currency_voucher_price?.map((itm) => {
            if (itm?.currency === value) {
              return {
                ...itm,
                is_deleted: true
              }
            }
            return itm
          }) : [],
        }));
      }
    }

  };

  useEffect(() => {
    if (allUinqueCurrencyList?.length == voucherData?.currency_voucher_price?.length) {
      setErrors((prev) => ({
        ...prev,
        price: ""
      }))
    }
  }, [voucherData?.currency_voucher_price])

  return (!voucher_updated || apiLoading) ? (
    <PreloaderFull />
  ) : (
    <>
      <div ref={mainRef} className="col-span-4 slide-in-bottom">
        <div className="flex flex-col gap-5 ">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
            <CircleIcon>
              <Svgs.Vouchers />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Add Vouchers" : translation[1182]?.value ? translation[1182]?.value : "Add Vouchers"}
              </h2>
              <p className="text-[#7B7B7B] text-sm">
                {selectedLanguage?.name == "English" ? "Add vouchers for particular products/services and sell to clients" : translation[1183]?.value ? translation[1183]?.value : "Add vouchers for particular products/services and sell to clients"}
              </p>
            </div>
          </div>
          <div className=" w-full">
            <div className="">
              <>
                {voucherRedux?.length > 0 ? (
                  voucherRedux?.map((vouc, index) => {
                    return (
                      <AddVoucherCard
                        editVoucherError={editVoucherError}
                        setEditVoucherError={setEditVoucherError}
                        currency={businessCurrency?.currency?.code}
                        key={index}
                        index={index}
                        data={vouc}
                        is_edit={edit_id == vouc.id ? true : false}
                        onUpdate={(edited_data, seteditId) => {
                          onVoucherUpdateHnadler(edited_data, seteditId);
                        }}
                        submitButtonClick={submitButtonClick}
                        onDelete={(id) => {
                          setDelete(id);
                        }}
                        onEditClick={(id) => {
                          onVoucherEditClickHnadler(id);
                        }}
                        errors={editErrors}
                        setErrors={setEditErrors}
                        updateLoading={updateLoading}
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </>
              {!employee ||
                (employee &&
                  employeePermissions?.client_sharing?.includes("create")) ? (
                <div className="flex gap-6">
                  <div className="relative">
                    <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                      <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                    </div>
                    {voucherRedux?.length > 0 && <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>}
                  </div>
                  <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4 pb-5 bg-white">
                    <LoginInput
                      title={selectedLanguage?.name == "English" ? "Vouchers Name" : translation[1184]?.value ? translation[1184]?.value : "Vouchers Name"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Vouchers Name" : translation[1185]?.value ? translation[1185]?.value : "Enter Vouchers Name"}
                      required={true}
                      name="name"
                      // charactersMaxLength={25}
                      value={voucherData?.name}
                      error={
                        errors.name
                          ?
                          selectedLanguage?.name == "English"
                            ? "This field is required"
                            : translation[287]?.value
                              ? translation[287]?.value
                              : "This field is required"
                          : ""
                      }
                      onEnterSubmit={() => {
                        vouchersubmithandler();
                      }}
                      onChange={(e) => {
                        handlevoucherData(e);
                        setvoucherError({
                          ...voucherError,
                          name: false,
                        });
                      }}
                      onBlur={() => {
                        if (!voucherData.name) {
                          setvoucherError({
                            ...voucherError,
                            name: true,
                          });
                        }
                      }}
                    />
                    <Dropwdown
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Select Service / Product" : translation[1186]?.value ? translation[1186]?.value : "Select Service / Product"}
                      placeholder={selectedLanguage?.name == "English" ? "Select on which service / product you want to implement this voucher" : translation[1187]?.value ? translation[1187]?.value : "Select on which service / product you want to implement this voucher"}
                      name="voucher_type"
                      value={voucherData?.voucher_type}
                      error={errors?.voucher_type ? errors?.voucher_type :
                        errors.voucher_type
                          ?
                          selectedLanguage?.name == "English"
                            ? "This field is required"
                            : translation[287]?.value
                              ? translation[287]?.value
                              : "This field is required"
                          : ""
                      }
                      options={[
                        { label: selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service", value: "Service" },
                        { label: selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product", value: "Product" },
                      ]}
                      onChange={(e) => {
                        handlevoucherData(e);
                        setvoucherError({
                          ...voucherError,
                          voucher_type: false,
                        });
                      }}
                      onBlur={() => {
                        if (!voucherData.voucher_type) {
                          setvoucherError({
                            ...voucherError,
                            voucher_type: true,
                          });
                        }
                      }}
                    />

                    <div className="grid lg:grid-cols-2 gap-5">
                      <Dropwdown
                        title={
                          <I_Info_Icon
                            text={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                            info={selectedLanguage?.name == "English" ? "For how many days the voucher will be valid." : translation[1188]?.value ? translation[1188]?.value : "For how many days the voucher will be valid."}
                          />
                        }
                        options={ValidityTime}
                        type="number"
                        required={true}
                        placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                        value={voucherData?.validity}
                        onEnterSubmit={() => {
                          vouchersubmithandler();
                        }}
                        error={errors?.validity ? errors?.validity :
                          voucherError.validity
                            ?
                            selectedLanguage?.name == "English"
                              ? "This field is required"
                              : translation[287]?.value
                                ? translation[287]?.value
                                : "This field is required"
                            : ""
                        }

                        name="validity"
                        onChange={(e) => {
                          handlevoucherData(e);
                          setvoucherError({
                            ...voucherError,
                            validity: false,
                          });
                        }}
                        onBlur={() => {
                          if (!voucherData.validity) {
                            setvoucherError({
                              ...voucherError,
                              validity: true,
                            });
                          }
                        }}
                      />
                      <LoginInput
                        required={true}
                        type="number"
                        title={selectedLanguage?.name == "English" ? "No. Of Sales" : translation[1189]?.value ? translation[1189]?.value : "No. Of Sales"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter no. of vouchers to sale" : translation[1190]?.value ? translation[1190]?.value : "Enter no. of vouchers to sale"}
                        value={voucherData?.sales}
                        error={errors?.sales ? errors?.sales :
                          voucherError.sales
                            ?
                            selectedLanguage?.name == "English"
                              ? "This field is required"
                              : translation[287]?.value
                                ? translation[287]?.value
                                : "This field is required"
                            : ""
                        }
                        onEnterSubmit={() => {
                          vouchersubmithandler();
                        }}
                        name="sales"
                        onChange={(e) => {
                          handlevoucherData(e);
                          setvoucherError({
                            ...voucherError,
                            sales: false,
                          });
                        }}
                        onBlur={() => {
                          if (!voucherData.sales) {
                            setvoucherError({
                              ...voucherError,
                              sales: true,
                            });
                          }
                        }}
                      />

                      {/* <LoginInput
                        title={
                          <I_Info_Icon
                            text="Retail Price "
                            info="A retail price is the cost paid for a good at retail stores. It is a term applied to the price that final consumers pay at retail outlets"
                          />
                        }
                        required={true}
                        placeholder="Enter Retail Price"
                        type="number"
                        value={voucherData?.price}
                        error={voucherError.price}
                        onEnterSubmit={() => {
                          vouchersubmithandler();
                          setLoading(true);
                        }}
                        name="price"
                        onChange={(e) => {
                          handlevoucherData(e);
                          setvoucherError({
                            ...voucherError,
                            price: false,
                          });
                        }}
                        onBlur={() => {
                          if (!voucherData.price) {
                            setvoucherError({
                              ...voucherError,
                              price: true,
                            });
                          }
                        }}
                      /> */}
                    </div>
                    <FloatInput
                      title={selectedLanguage?.name == "English" ? "Percentage" : translation[199]?.value ? translation[199]?.value : "Percentage"}
                      required={true}
                      placeholder="%"
                      value={voucherData?.discount_percentage}
                      error={errors?.discount_percentage
                        ? errors?.discount_percentage
                        : voucherError.discount_percentage
                          ? selectedLanguage?.name == "English" ? "This field is required" : translation[287]?.value ? translation[287]?.value : "This field is required"
                          : ""
                      }
                      onEnterSubmit={() => {
                        vouchersubmithandler();
                      }}
                      name="discount_percentage"
                      onChange={(e) => {
                        handlevoucherData(e);
                        setvoucherError({
                          ...voucherError,
                          discount_percentage: false,
                        });
                      }}
                      isPercentage={true}
                      onBlur={() => {
                        if (!voucherData.discount_percentage) {
                          setvoucherError({
                            ...voucherError,
                            discount_percentage: true,
                          });
                        }
                      }}
                    />
                    {/* <Dropwdown
                      name={"is_active"}
                      value={voucherData?.is_active}
                      onChange={handlevoucherData}
                      error={errors?.is_active}
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                      placeholder={selectedLanguage?.name == "English" ? "Select status" : translation[483]?.value ? translation[483]?.value : "Select status"}
                      options={[
                        {
                          value: true,
                          label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                        },
                        {
                          value: false,
                          label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                        },
                      ]}
                    /> */}
                    <div className='md:flex-row flex flex-col gap-5'>
                      <div className='flex-1'>
                        <FloatInput
                          required={true}
                          name={"price"}
                          value={price}
                          disabled={allUinqueCurrencyList?.length == currency_voucher_price?.length}
                          // onChange={handlememberInput}
                          onChange={(e) => setPrice(e.target.value)}
                          onBlur={() => onBlurHandler({ price: price })}
                          error={errors?.price ? errors?.price : (submitButtonClick && currency_voucher_price?.length == 0) && "Please add all currencies and their price"}
                          title={
                            <I_Info_Icon
                              text={selectedLanguage?.name == "English" ? "Selling Price" : translation[294]?.value ? translation[294]?.value : "Selling Price"}
                              info={selectedLanguage?.name == "English" ? "A selling price is the cost paid for a good at selling stores. It is a term applied to the price that final consumers pay at selling outlets" : translation[1191]?.value ? translation[1191]?.value : "A selling price is the cost paid for a good at selling stores. It is a term applied to the price that final consumers pay at selling outlets"}
                            />
                          }
                          placeholder={selectedLanguage?.name == "English" ? "Enter selling price" : translation[944]?.value ? translation[944]?.value : "Enter selling price"}
                        />
                      </div>
                      <div className='flex-1 flex justify-between gap-4'>
                        <Dropwdown
                          required={true}
                          title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                          placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                          name={"selectedCurrency"}
                          value={selectedCurrency}
                          onChange={(e) => setSelectedCurrency(e.target.value)}
                          options={allUinqueCurrencyList
                            ?.filter(
                              (itm) =>
                                !currency_voucher_price?.find(
                                  ({ currency }) => currency === itm?.currency?.id
                                )
                            )?.map((itm) => {
                              return {
                                label: itm?.currency?.code,
                                value: itm?.currency?.id,
                              };
                            })}
                        />
                        <div className='self-end'>
                          <LoginBtn
                            className={(selectedCurrency && price) && "shake-bottom"}
                            btnCustomHight={'h-[2.207rem]'}
                            disabled={!selectedCurrency || !price}
                            onClick={() => {
                              addNewItem();
                            }}
                          >
                            <div className='flex items-center gap-2 text-sm'>
                              <Svgs.Plus />
                              <p>
                                {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                              </p>
                            </div>
                          </LoginBtn>
                        </div>
                      </div>
                    </div>
                    {currency_voucher_price?.length > 0 && (
                      <div className="flex items-center flex-wrap gap-2">
                        {currency_voucher_price &&
                          currency_voucher_price?.map((item, i) => {
                            const crrncy = currencyList?.find(
                              (itm) => itm?.id === item?.currency
                            );
                            return (
                              !item?.is_deleted && (
                                <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                  <p className="text-primary text-sm font-bold">{`${item?.price} ${crrncy?.code}`}</p>
                                  <span
                                    onClick={() =>
                                      removedItem(item?.currency, "price")
                                    }
                                  >
                                    <Svgs.CirlceClose fill="#5679FF" />
                                  </span>
                                </div>
                              )
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {!employee ||
              (employee &&
                employeePermissions?.client_sharing?.includes("create")) ? (
              <div className="mt-3">
                <LoginBtn
                  text={selectedLanguage?.name == "English" ? "Add Voucher" : translation[290]?.value ? translation[290]?.value : "Add Voucher"}
                  // disabled={
                  //   !voucherData?.name ||
                  //   // // !voucherData?.value ||
                  //   !voucherData?.voucher_type ||
                  //   // !voucherData?.valid_for ||
                  //   !voucherData?.validity ||
                  //   !voucherData?.sales ||
                  //   // !voucherData?.price ||
                  //   loading
                  //   // || (allUinqueCurrencyList?.length !== voucherData?.currency_voucher_price?.length)

                  // }
                  disabled={loading}
                  className="mt-4"
                  loading={loading}
                  onClick={() => {
                    vouchersubmithandler();
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {is_delete && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Promotion?" : translation[1192]?.value ? translation[1192]?.value : "Are you sure you want to delete this Promotion?"}
          onclose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English" ? "Are you sure you want to delete this Promotion? You won't be able to this again." : translation[1193]?.value ? translation[1193]?.value : "Are you sure you want to delete this Promotion? You won't be able to this again."}
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                loading={deleteLoader}
                disabled={deleteLoader}
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  onVoucherDeleteHandler(is_delete);
                  setTimeout(() => {
                    setDelete(false);
                  }, 500);
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Vouchers;
