import moment from 'moment/moment';
import React, { useRef } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import useCalculateTaxes from "Hooks/useCalculateTaxes";
import { useDispatch, useSelector } from 'react-redux';
import { getLocationTax } from 'Adapters/Api/saleServices';
import Pagination from 'new-components/Pagination';
import NoLoyalityLogByCustomerFound from '../../Elements/NoLoyalityLogByCustomer';
import SelectedInvoiceModal from 'Components/TenantApp/Dashboard/Sales/sale-history/SelectedInvoiceModal';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';

const LoyaltyLogDetailed = (props) => {
    const { setSection, start, setStartDate, client, end, setEndDate, customer, setCustomer, loading, data,
        toggleInviceModal, selectedInvice, InvoicePopup, calculate_totals, detailInvoiceLoading,
        reduxEmployees, total_tax, invoiceLabels, translation, selectedLanguage, currentPage,
        setCurrentPage, PageSize, pages, allCount, invoice_titles, setSelectedInvice, setInvoicePopup,
        clientSearchText, setClientSearchText, clientCurrentPage, setClientCurrentPage, clientLoader, clientPaginationData
    } = props

    const dispatch = useDispatch()
    const [toggleDateRange, setToggleDateRange] = useState(false)

    const state = useSelector(state => state)
    const locationTax = state?.saleService?.location_tax
    const { calculateTaxes } = useCalculateTaxes(locationTax);
    const { selected_location } = state?.locations;


    useEffect(() => {
        if (selected_location) {
            dispatch(getLocationTax(selected_location))
        }
    }, [selected_location]);

    useEffect(() => {
        if (locationTax) {
            calculateTaxes(locationTax)
        }
    }, [locationTax])
    return (
        <>
            <div className="border rounded-lg relative bg-white">
                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row flex-wrap gap-3">
                    <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4">
                        <div
                            onClick={() => setSection("ByStaff")}
                            className="flex items-center sm:gap-4 gap-2 "
                        >
                            <h2 className="font-semibold text-xl whitespace-nowrap">
                                {selectedLanguage?.name == "English" ? "All Loyalty Logs" : translation[1156]?.value ? translation[1156]?.value : "All Loyalty Logs"}
                            </h2>

                            {loading ?
                                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                                </div>
                                :
                                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                    <p className="text-primary">
                                        {allCount ? allCount : 0}{" "}
                                        {allCount && allCount > 1 ?
                                            selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                            : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="grid grid-cols-2 sm:flex items-center justify-end gap-3 w-full sm:w-fit">
                        <PaginationDropdown
                            title=""
                            value={customer}
                            onChange={(e) => setCustomer(e.target.value)}
                            showTitle={false}
                            placeholder={selectedLanguage?.name == "English" ? "All Client" : translation[1154]?.value ? translation[1154]?.value : "All Client"}
                            setPage={setClientCurrentPage}
                            page={clientCurrentPage}
                            setDropdownSearch={setClientSearchText}
                            dropdownSearch={clientSearchText}
                            lastPage={clientPaginationData?.pages}
                            currentPage={clientPaginationData?.currentPage}
                            loading={clientLoader}
                            custom_min_width={'min-w-[15rem]'}
                            options={[
                                { label: selectedLanguage?.name == "English" ? "All Client" : translation[1154]?.value ? translation[1154]?.value : "All Client", value: "" },
                                ...client?.map((clnt, i) => {
                                    return {
                                        label: clnt.full_name,
                                        value: clnt.id,
                                        otherData: {
                                            image: clnt?.image,
                                            DataOne: clnt.email,
                                            showImage: true,
                                            index: i
                                        },
                                        email: clnt.email,
                                        mobile_number: clnt.mobile_number
                                    }
                                }),
                            ]}
                        />
                        <div className='flex items-center gap-3 w-full sm:w-auto'>
                            <div className='w-full border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                <div className='w-full p-2 relative gap-4 flex justify-between items-center'>
                                    <p onClick={() => {
                                        setToggleDateRange(!toggleDateRange)
                                    }} className='cursor-pointer'>
                                        {`${start ? moment(start).format("DD-MM-YYYY") : "--/--/----"} - ${end ? moment(end).format("DD-MM-YYYY") : "--/--/----"}`}
                                    </p>
                                    <span className='cursor-pointer' onClick={() => {
                                        setStartDate()
                                        setEndDate()
                                    }}>Clear</span>

                                    {
                                        toggleDateRange && <>
                                            <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                            <div className='absolute top-full right-0 z-[2]'>
                                                <DateRangePicker
                                                    start={start}
                                                    end={end}
                                                    setStartDate={setStartDate}
                                                    setEndDate={setEndDate}
                                                    setToggleDateRange={setToggleDateRange}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {loading ?
                    <TableShimer cols={5} rows={11} colsHeight={9} />
                    :
                    <div className="overflow-x-auto">
                        <div className="inline-grid xl:grid grid-cols-[repeat(9,minmax(205px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                            <p>{selectedLanguage?.name == "English" ? "Client ID" : translation[1158]?.value ? translation[1158]?.value : "Client ID"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Client Name" : translation[1159]?.value ? translation[1159]?.value : "Client Name"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Loyalty Name" : translation[1160]?.value ? translation[1160]?.value : "Loyalty Name"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Invoice Number" : translation[363]?.value ? translation[363]?.value : "Invoice Number"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Points Earned" : translation[1161]?.value ? translation[1161]?.value : "Points Earned"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Points Redeemed" : translation[1162]?.value ? translation[1162]?.value : "Points Redeemed"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Balance" : translation[1163]?.value ? translation[1163]?.value : "Balance"}</p>
                            <p className='text-center'>{selectedLanguage?.name == "English" ? "Actual Sales Value Redeemed" : translation[1164]?.value ? translation[1164]?.value : "Actual Sales Value Redeemed"}</p>
                            <p className='text-center'>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((itm) => {
                                return (
                                    <>
                                        <div className="text-[#3A3A3A] transition-all px-12 py-3 odd-bg inline-grid xl:grid grid-cols-[repeat(9,minmax(205px,1fr))] min-w-full w-fit">
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.customer?.customer_id}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.customer?.customer_name}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.loyalty?.loyalty_name}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm cursor-pointer" onClick={() => {
                                                    toggleInviceModal(itm?.id)
                                                    // toggleInviceModal(itm?.checkout_data && itm?.checkout_data[0]?.id, "history")
                                                }}>
                                                    #{itm?.invoice && itm?.invoice
                                                        ?.split("-")[0]
                                                        .split("")
                                                        .slice(0, 8)
                                                        .join("")}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {parseFloat(itm?.points_earned.toFixed(2))}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {parseFloat(itm?.points_redeemed.toFixed(2))}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {parseFloat(itm?.balance?.toFixed(2))}
                                                </p>
                                            </div>
                                            <div className="flex items-center justify-center">
                                                <p className="text-sm">
                                                    {itm?.actual_sale_value_redeemed}
                                                </p>
                                            </div>
                                            <div className="flex items-center justify-center">
                                                <p className="text-sm">
                                                    {moment(itm?.date).format('DD-MM-YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                        ) : (
                            <NoLoyalityLogByCustomerFound />
                        )}

                        {(data?.length > 0 && !loading) &&
                            <Pagination
                                currentPage={currentPage}
                                totalCount={Math.ceil(pages)}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        }

                        {/* <div className="mt-[4rem] inline-grid xl:grid grid-cols-[repeat(8,minmax(205px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                        <p>Total</p>
                        <p>{totals?.total_services_sales}</p>
                        <p>{totals?.total_services_commissions}</p>
                        <p>{totals?.total_products_sales}</p>
                        <p>{totals?.total_products_commissions}</p>
                        <p>{totals?.total_vouchers_sales}</p>
                        <p>{totals?.total_vouchers_commissions}</p>
                        <p>{totals?.total_commission}</p>
                    </div> */}
                    </div>
                }

                <SelectedInvoiceModal
                    open={InvoicePopup}
                    close={() => {
                        setInvoicePopup(false)
                    }}
                    invoice_titles={invoice_titles}
                    selectedInvice={selectedInvice}
                    selectedLanguage={selectedLanguage}
                    translation={translation}
                    calculate_totals={calculate_totals}
                    loading={detailInvoiceLoading}
                />
            </div>

        </>

    )
}

export default LoyaltyLogDetailed