import Svgs from 'Assets/svgs'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import I_Info_Icon from 'Components/TenantApp/Elements/I_Info_Icon'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Textarea from 'Components/TenantApp/Elements/Textarea'
import { APPOINTMENT_TIME } from 'Constants/Data/OpeningHours'
import React, { useState } from 'react'

const ServicesStep = ({ errors, formErrors, descriptionErrors, currency, handleChange, serviceStepHandleChange, formData, selecteDuration, setDuration, setPrice, selectePrice, addDurationPrice, removeSelectedPrice }) => {
    const [onChangeIndex, setOnChangeIndex] = useState(0)
    const [addPriceErr, setAddPriceErr] = useState([{ priceErr: "", index: "" }])
    return (
        <div className='my-[2rem] flex flex-col gap-4'>
            <h1 className='font-semibold text-xl'>Setup service menu</h1>
            <div>
                <LoginInput
                    required={true}
                    value={formData?.service_group?.name}
                    name={'name'}
                    error={(!formData?.service_group?.name && errors?.name) && errors?.name}
                    onChange={(e) => { handleChange(e, 'service_group') }}
                    title={
                        <I_Info_Icon
                            text="Service Group Name"
                            info="A mandatory group that helps organize your services. Each service must be assigned to a service group, making it easier to manage and keep everything in the right place."
                        />}
                    placeholder={'Select Service Group'} />
            </div>
            {formData?.service?.map((serv, index) => {
                let filteredErrorObject = {}
                filteredErrorObject = formErrors && formErrors?.find(itm => itm?.index == index)

                const filteredDuration = selecteDuration?.find(itm => itm?.index == index)?.duration
                const filteredPrice = selectePrice?.find(itm => itm?.index == index)?.price
                return (
                    <><h1 className='font-semibold text-md'>Service {index + 1}</h1>
                        <div className="space-y-4 px-8 py-6 rounded-[0.625rem] bg-[#F0F6FF]">
                            <div>
                                <LoginInput
                                    required={true}
                                    value={serv?.name}
                                    name={'name'}
                                    error={filteredErrorObject && filteredErrorObject != {} ? filteredErrorObject?.name : ''}
                                    onChange={(e) => { serviceStepHandleChange(e, 'service', index) }}
                                    title={'Service Name'}
                                    placeholder={'Enter Service Name'} />
                            </div>
                            <div className='flex items-end gap-4'>
                                <div className='flex-1'>
                                    <Dropwdown
                                        name={"duration"}
                                        value={filteredDuration ? filteredDuration : ""}
                                        onChange={(e, extraValues) => {
                                            const { name, value } = e.target;
                                            const exist = selecteDuration?.find(itm => itm?.index == index)
                                            if (exist) {
                                                const prevData = selecteDuration?.filter(itm => itm?.index != index)
                                                setDuration([...prevData, { index: index, duration: value }])
                                            } else {
                                                const prevData = selecteDuration || []
                                                setDuration([...prevData, { index: index, duration: value }])
                                            }
                                            const prevData = addPriceErr?.filter(itm => itm?.index != index) || []
                                            setAddPriceErr([...prevData])
                                            // setDuration(value)
                                            setOnChangeIndex(index)
                                        }}
                                        options={APPOINTMENT_TIME}
                                        title={'Duration'}
                                        placeholder={'Select Duration'} />
                                </div>
                                <div className='flex-1'>
                                    <FloatInput
                                        type={"number"}
                                        title={`Pricing ${currency ? currency : "AED"}`}
                                        name={"price"}
                                        error={(filteredErrorObject && filteredErrorObject != {} && serv?.priceservice?.length == 0) ? filteredErrorObject?.price
                                            : addPriceErr?.find(itm => itm?.index == index)?.priceErr ? addPriceErr?.find(itm => itm?.index == index)?.priceErr : ""}
                                        value={filteredPrice ? filteredPrice : ""}
                                        onChange={(e, extraValues) => {
                                            const { name, value } = e.target;
                                            const exist = selectePrice?.find(itm => itm?.index == index)
                                            if (exist) {
                                                const prevData = selectePrice?.filter(itm => itm?.index != index)
                                                setPrice([...prevData, { index: index, price: value }])
                                            } else {
                                                const prevData = selectePrice || []
                                                setPrice([...prevData, { index: index, price: value }])
                                            }
                                            const prevData = addPriceErr?.filter(itm => itm?.index != index) || []
                                            setAddPriceErr([...prevData])
                                            // setPrice(value);
                                            setOnChangeIndex(index)
                                        }}
                                        placeholder={'Enter Pricing'} />
                                </div>
                                <div className="">
                                    <LoginBtn
                                        disabled={!filteredDuration || !filteredPrice}
                                        className={`{'!py-1.5' h-[2.3rem] ${(filteredDuration && filteredPrice && onChangeIndex == index) && "shake-bottom"}`}
                                        onClick={() => {
                                            if (serv?.priceservice?.find(itm => itm?.price == filteredPrice && itm?.duration?.toLowerCase() == filteredDuration)) {
                                                setAddPriceErr([...addPriceErr, { index: index, priceErr: "Value already exist" }])
                                            } else {
                                                addDurationPrice({
                                                    duration: filteredDuration,
                                                    price: filteredPrice,
                                                },
                                                    index
                                                );
                                                setDuration([{ index: "", duration: "" }]);
                                                setPrice([{ index: "", price: "" }]);
                                            }
                                        }}
                                    >
                                        <div className="flex items-center gap-1 py-1">
                                            <div className='flex items-center gap-2' > <Svgs.Plus /><p>Add</p></div>
                                            {/* <Svgs.Plus /> */}
                                        </div>
                                    </LoginBtn>
                                </div>
                            </div>
                            {serv?.priceservice?.length > 0 ? (
                                <div className="flex items-center flex-wrap gap-2">
                                    {serv?.priceservice &&
                                        serv?.priceservice?.map((item, i) => {
                                            return (
                                                !item?.is_deleted && (
                                                    <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                                        <p className="text-primary text-sm font-bold">{`${item?.duration.split("_")[0]
                                                            } - ${item?.price} ${currency ? currency : "AED"}`}</p>
                                                        <span
                                                            className='cursor-pointer'
                                                            onClick={() =>
                                                                removeSelectedPrice(item?.duration, item?.price, item?.id, "priceservice", index)
                                                            }
                                                        >
                                                            <Svgs.CirlceClose fill="#5679FF" />
                                                        </span>
                                                    </div>
                                                )
                                            );
                                        })}
                                </div>
                            ) : (
                                <></>
                            )}

                            <div>
                                <Textarea
                                    required={true}
                                    value={serv?.description}
                                    name={'description'}
                                    onChange={(e) => { serviceStepHandleChange(e, 'service', index) }}
                                    title={'Description'}
                                    placeholder={'Enter Description'}
                                    rows={'5'}
                                    error={filteredErrorObject && filteredErrorObject != {} ? filteredErrorObject?.description : descriptionErrors ? descriptionErrors[index] : ''}
                                />
                            </div>
                        </div>
                    </>
                )
            })}
        </div>
    )
}

export default ServicesStep