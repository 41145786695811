import Svgs from 'Assets/svgs'
import React, { useState, useEffect } from 'react'
import CircleIcon from '../../../Elements/CircleIcon'
import LoginBtn from '../../../Elements/LoginBtn'
import Popup from '../../../Elements/Popup'
import useAdminBooking from '../helper'
import Layout from '../Layout'
import { useDispatch } from 'react-redux';
import { LOCATION } from 'Redux/TenantRedux/ActionTypes/locationTypes'
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2'

const BusinessPhotos = ({ count, setStepCount, StepCount, btnInner }) => {
    const { translation, selectedLanguage, locationInfo, setLocationInfo, onSubmitMarketPlaceProfile, firstPopup, setfirstPopup,
        loading } = useAdminBooking();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const image_preview = state?.locations?.image_preview;
    const [buttonClick, setButtonClick] = useState(false)
    const [showError, setShowError] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const edit = searchParams.get("edit");

    useEffect(() => {
        if (buttonClick) {
            if (image_preview == null) {
                setShowError(true)
            } else {
                setShowError(false)
            }
        }
    }, [image_preview, buttonClick])
    console.log(locationInfo, "locationInfo")
    const handleClick = async () => {
        if (!showError && image_preview != null) {
            await onSubmitMarketPlaceProfile()
        }
    }

    return (
        <>
            <Layout
                title={selectedLanguage?.name == "English" ? "Setup your business profile" : translation[81]?.value ? translation[81]?.value : "Setup your business profile"}
                heading={selectedLanguage?.name == "English" ? "Add your business info" : translation[82]?.value ? translation[82]?.value : "Add your business info"}
                subHeading={selectedLanguage?.name == "English" ? "Add key details about your business to display on your online booking profile." : translation[83]?.value ? translation[83]?.value : "Add key details about your business to display on your online booking profile."}

                count={count}
                NextClick={() => {
                    setButtonClick(true)
                    handleClick()
                }
                    // Swal.fire({
                    //     title: '<strong>Business Uploaded Successfully</strong>',
                    //     icon: 'success',
                    //     // showCloseButton: true,
                    //     html: "Congratulations! Your business listing has been successfully added to NSTYLE.",
                    //     confirmButtonColor: 'theme-btn',
                    // }).then((result) => {
                    //     if (result.isConfirmed) {
                    //         navigate("/dashboard/marketplace-profile")
                    //     }
                    // })
                    // dispatch({
                    //     type: "STORE_CURRENT_LOCATION_DATA",
                    //     payload: null
                    // })
                    // setfirstPopup(!firstPopup)                        
                }
                backClick={() => {
                    if (StepCount > 0) {
                        setStepCount(StepCount - 1)
                    }
                }}
                disabled={loading}
                loading={loading}
                btnInner={edit ?
                    selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                    : selectedLanguage?.name == "English" ? "Publish" : translation[105]?.value ? translation[105]?.value : "Publish"}
            >
                <div className='mx-auto w-[75%] flex flex-col gap-3'>
                    <label htmlFor="upload_photo" className='cursor-pointer'>
                        <div className='flex flex-col items-center justify-center bg-[#5679FF]/10 h-[15rem] rounded-lg'>
                            {/* {
                                (typeof locationInfo?.images == "string") &&
                                <div className="h-[13rem] w-full md:w-[30rem] bg-[#E4EAF0] rounded-lg flex items-center justify-center overflow-hidden">
                                    <img src={locationInfo?.images} />
                                </div>} */}

                            {(image_preview != null && image_preview) &&
                                <div className="h-[13rem] w-full md:w-[30rem] bg-[#E4EAF0] rounded-lg flex items-center justify-center overflow-hidden">
                                    <img src={image_preview && image_preview} />
                                </div>}


                            <div className='text-center flex flex-col gap-2 items-center justify-center'>
                                {!image_preview ? <Svgs.Camera fill='#5679FF' /> : ""}
                                <p className={`${showError ? "text-red-500" : "text-primary"} text-sm`}>
                                    {selectedLanguage?.name == "English" ? "Add New Photo *" : translation[103]?.value ? translation[103]?.value : "Add New Photo *"}
                                </p>
                            </div>
                            {/* <div className='text-center flex flex-col gap-2 items-center justify-center'>
                                {imgPreview ?
                                    <img src={imgPreview} />
                                    : <Svgs.Camera fill='#5679FF' />
                                }
                                <p className='text-primary text-xs'>Add New Photo</p>
                            </div> */}
                        </div>
                    </label>
                    <input type="file" accept="image/jpeg, image/png, image/jpg" hidden id="upload_photo" onChange={(e) => {
                        setLocationInfo((prev) => ({
                            ...prev,
                            images: e.target.files[0]
                        }));
                        dispatch({
                            type: LOCATION.IMAGE_PREVIEW,
                            payload: URL.createObjectURL(e.target.files[0])
                        })
                    }} />
                    {/* <div className='bg-[#FFF3CD] rounded-md px-5 py-3 text-xs flex items-center gap-3'>
                        <Svgs.Bulb fill='#101928' />
                        <div>
                            <p className='text-[#101928]'>
                                {selectedLanguage?.name == "English" ? "At least one photo is required, drag and drop photos to rearrange the order and tap on photos to edit." : translation[102]?.value ? translation[102]?.value : "At least one photo is required, drag and drop photos to rearrange the order and tap on photos to edit."}
                            </p>
                        </div>
                    </div> */}
                </div>
            </Layout>
            <Popup header={false} close={setfirstPopup} open={firstPopup} size='md'>
                <div className='flex flex-col gap-4 justify-center items-center text-center'>
                    <div>
                        <CircleIcon className='!h-[7rem] !w-[7rem]'>
                            <Svgs.Todo />
                        </CircleIcon>
                    </div>
                    {edit ?
                        <>
                            <h2 className='font-bold text-2xl text-[#101928]'>
                                {selectedLanguage?.name == "English" ? "Business updated successfully" : translation[1310]?.value ? translation[1310]?.value : "Business updated successfully"}
                            </h2>
                            <p className='text-[#101928]/60 text-xs'>
                                {selectedLanguage?.name == "English" ? "Congratulations! Your business listing has been edit successfully." : translation[1311]?.value ? translation[1311]?.value : "Congratulations! Your business listing has been edit successfully."}
                            </p>
                        </> :
                        <>
                            <h1 className='font-bold text-2xl text-[#101928] normal-case'>
                                {selectedLanguage?.name == "English" ? "Business uploaded successfully" : translation[1281]?.value ? translation[1281]?.value : "Business uploaded successfully"}
                            </h1>
                            <p className='text-[#101928]/60 text-xs'>
                                {selectedLanguage?.name == "English" ? "Congratulations! Your business listing has been successfully added to NSTYLE." : translation[1282]?.value ? translation[1282]?.value : "Congratulations! Your business listing has been successfully added to NSTYLE."}
                            </p>
                        </>}
                    <div className='flex items-center justify-end gap-3'>
                        {/* <LoginBtn padding='px-6 py-2' text='Preview profile' borderBtn /> */}
                        <LoginBtn
                            text={selectedLanguage?.name == "English" ? "Ok" : translation[1280]?.value ? translation[1280]?.value : "Ok"}
                            // onClick={async () => {
                            //     await onSubmitMarketPlaceProfile()
                            //     dispatch({
                            //         type: "STORE_CURRENT_LOCATION_DATA",
                            //         payload: null
                            //     })
                            //     navigate("/dashboard/marketplace-profile")
                            // }}
                            onClick={() => {
                                navigate("/dashboard/marketplace-profile")
                                dispatch({
                                    type: "STORE_CURRENT_LOCATION_DATA",
                                    payload: null
                                })
                            }}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default BusinessPhotos