import React from 'react'
import useCreateMaintenancePopup from './helper'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Textarea from 'Components/TenantApp/Elements/Textarea'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import Popup from 'Components/TenantApp/Elements/Popup'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import { maintainencePriorityLevel } from 'Constants/Data/Months'

const CreateMaintenancePopup = ({ open, close, onSuccessCallBack }) => {
    const { submitLoading, formData, onChangeHandler, translation, selectedLanguage, errors, onSubmit } = useCreateMaintenancePopup(open, onSuccessCallBack)
    return (
        <>
            <Popup open={open} close={close} heading={"Create Maintenance "}>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-5">
                        <LoginInput
                            title={'Title'} placeholder={'Enter title'}
                            required={true}
                            value={formData?.title}
                            error={errors?.title}
                            name={'title'}
                            charactersMaxLength={50}
                            onChange={onChangeHandler}
                        />

                        <Textarea
                            title={'Description'} placeholder={'Enter description'}
                            required={true}
                            value={formData?.description}
                            error={errors?.description}
                            name={'description'}
                            onChange={onChangeHandler}
                        />

                        <Dropwdown
                            title={'Priority Level'} placeholder={'Enter Priority Level'}
                            required={true}
                            options={maintainencePriorityLevel}
                            value={formData?.priority_level}
                            error={errors?.priority_level}
                            name={'priority_level'}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <LoginBtn
                        text={'Submit'}
                        title={'Submit'}
                        onClick={onSubmit}
                        loading={submitLoading}
                    />
                </div>
            </Popup>
        </>
    )
}

export default CreateMaintenancePopup