import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import React from 'react'
import moment from 'moment';
import { statusClasses } from 'Constants/Data/Months';

const MaintenanceTable = ({ data, setOpenUpdateStatus, setViewDetailId, isPopup }) => {
    return (
        <div className="overflow-x-auto">
            <div className={`inline-grid xl:grid grid-cols-[repeat(11,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                <p className='col-span-2'>Title</p>
                <p className='col-span-2'>Description</p>
                <p className=''>Priority Level</p>
                <p className=''>Requested By</p>
                <p className=''>Resolved By</p>
                <p className=''>Date</p>
                <p className=''>Status</p>
                <p className='col-span-2'>Action</p>
            </div>
            {data?.length > 0 ? (
                data?.map((item, index) => {
                    return (
                        <div key={index} className={`text-xs items-center  px-12 py-3 inline-grid xl:grid grid-cols-[repeat(11,minmax(115px,1fr))]  min-w-full w-fit`}>
                            <p className='col-span-2 line-clamp-2 !break-all pr-2'>
                                {item?.title || "------"}
                            </p>
                            <p className='col-span-2 line-clamp-2 !break-all pr-2'>
                                {item?.description || "------"}
                            </p>
                            <p className='pr-2'>
                                {item?.priority_level || "------"}
                            </p>
                            <p className='pr-2'>
                                {item?.requested_by || "------"}
                            </p>
                            <p className='pr-2'>
                                {item?.resolved_by || "------"}
                            </p>
                            <p className='pr-2'>
                                {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY') : "------"}
                            </p>
                            <p className={`px-2 py-1 rounded text-sm font-bold ${statusClasses[item?.status] || 'bg-gray-100 text-gray-800'} w-fit`}>
                                {item?.status || "------"}
                            </p>
                            <p className='flex gap-2 col-span-2 '>
                                {(item?.status !== 'Rejected' && item?.status !== 'Resolved') && <LoginBtn customThemeBtn={'theme-btn3 !px-1.5 !py-1.5'} title={"Update Status"} text={"Update Status"} onClick={() => setOpenUpdateStatus && setOpenUpdateStatus(item)} />}
                                <LoginBtn customThemeBtn={'theme-btn !px-1.5 !py-1.5'} title={"View Detail"} text={"View Detail"} onClick={() => setViewDetailId && setViewDetailId(item)} />
                            </p>
                        </div>
                    );
                })
            ) : (
                <NoReportDataFound />
            )}
        </div>
    )
}

export default MaintenanceTable
