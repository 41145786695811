import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Svgs from "../../../../../../Assets/svgs";
import CircleIcon from "../../../../Elements/CircleIcon";
import Dropwdown from "../../../../Elements/Dropwdown";
import FormSteps from "../../../../Elements/FormSteps";
import LoginBtn from "../../../../Elements/LoginBtn";
import LoginInput from "../../../../Elements/LoginInput";
import Popup from "../../../../Elements/Popup";
import { getBrandDropdownlist, getBrandDropdownlistPagination } from "../../../../../../Adapters/Api/Brand";
import useProducts from "../Products/useProducts";
import ToggleWithText from "../../../../Elements/ToggleWithText";
import UploadFile from "../../../../Elements/UploadFile";
import Textarea from "../../../../Elements/Textarea";
import { getVendorsDropdownList, getVendorsList } from "../../../../../../Adapters/Api/vendor";
import { getCategoriesDropdownList, getCategoriesDropdownListPagination } from "../../../../../../Adapters/Api/categories";
import { useNavigate } from "react-router-dom";
import AddCategoryBtn from "./AddCategoryBtn";
import AddBrandPopup from "../../../../Brand/AddBrandPopup";
import I_Info_Icon from "../../../../Elements/I_Info_Icon";
import { getLocations } from "./../../../../../../Adapters/Api/index";
import useSetDataInLocalStorage from "./../../../../../../Hooks/useSetDataInLocalStorage";
import useVendors from "../Vendors/useVendors";
import PhoneInput from "Components/TenantApp/Elements/PhoneInput";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import ProductFormShimmer from "Components/Utility/Shimmers/ProductFormShimmer";
// import CardTile from "../../../CardTile";
// import { getMultiLanguageInvoice } from "Adapters/Api/multiLanguageInvoice";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
// import FormShimmer from "Components/Utility/Shimmers/FormShimmer";
import { useMemo } from "react";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import AutoPlaceInput from "Components/TenantApp/Elements/AutoPlaceInput";

let brandTimeOutId = undefined;
let categoryTimeOutId = undefined;
export const ProductPopup = ({ loader, edit_mode, edit_data, onClose, quickSales, successCallback }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const access_token = getDataFromLocalStorage("access_token");

  const [edit_brand, setEditBrand] = useState(false);
  const [editLoader, setEditLoader] = useState(true)

  const reduxState = useSelector((state) => state)
  const translation = reduxState?.languages?.language_translation
  const selectedLanguage = reduxState?.languages?.selected_language

  const reduxInvoices = useSelector((state) => state?.multilanguageInvoices?.invoices);

  const removeDuplicate = [];
  reduxInvoices?.filter(itm => itm?.language_data?.code != "eng").forEach((item) => {
    if (!removeDuplicate.find((element) => element.language === item.language)) {
      removeDuplicate.push(item);
    }
  });

  const handle_edit_brand = (data) => {
    setEditBrand(data);
  };


  const {
    products,
    setProducts,
    productHandleChange,
    productModalToggler,
    onProdcutSubmit,
    errors,
    onBlurHandler,
    step,
    setStep,
    removedItem,
    addNewInvoiceLanguage,
    product_name, setProduct_name,
    setProductIsEdit,
    invoiceLanguage, setInvoiceLanguage,
    // isEdit,
    loading,
    location_currency,
    selectedCurrency,
    retailPrice,
    addNewItem,
    setSelectedCurrency,
    setRetailPrice,
    allUinqueCurrencyList,
    currencyList,
    formRef,
    secondStepError,
    setSecondStepError,
    vendorsRedux,
    brand_state,
    categoryRedux,
    reduxlocationsList, allCurrenciesData
  } = useProducts(quickSales, successCallback);

  const {
    AddVendor,
    setAddVendor,
    vendorsData,
    vendorModalToggler,
    vendorHandleChange,
    vendorErrors, setVendorErrors,
    vendorActive,
    // onVendorsSubmit,
    // errors,
    setIsEdit,
    isEdit,
    // vendorDeleteHandler,
    // vendorEditHandler,
    countriesList,
    stateList,
    // cityList,
    stateOptions,
    // onBlurHandler,
    // loading,
    checkVendorExistenceBeforeSubmit,
    vendorSubmitLoading
  } = useVendors();

  // let productTypeOptionList = [
  //   { label: "Both", value: "Both" },
  //   { label: "Sellable", value: "Sellable" },
  //   { label: "Consumable", value: "Consumable" },
  // ];

  const {
    vendor,
    category,
    brand,
    name,
    location_quantities,
    size,
    alert_when_stock_becomes_lowest,
    cost_price,
    currency_retail_price,
    invoices,
    short_description,
    description,
    barcode_id,
    sku,
    is_active,
    // product_unit,
    // location,
    // product_type,
    // sell_price,
    // full_price,
    // product_size,
    // consumable_quantity,
    // unit,
    // amount,
    // stock_status,
    // product_images,
    // sellable_quantity,
    // available_quantity,
    // current_stock,
    // low_stock,
    // reorder_quantity,
  } = products;

  const {
    vendor_name,
    address,
    mobile_number,
    email,
    country,
    state,
    city,
    gstin,
    website,
    formRefVendor,
    // is_active,
  } = vendorsData;

  const [brandSearchText, setBrandSearchText] = useState("");
  const [brandCurrentPage, setBrandCurrentPage] = useState(1)
  const [brandLoader, setBrandLoader] = useState(false)
  const brandListDropdown = reduxState?.brand?.brandsDropdown;
  const brandPaginationData = reduxState?.brand?.brandsPagination;

  const [categorySearchText, setCategorySearchText] = useState("");
  const [categoryCurrentPage, setCategoryCurrentPage] = useState(1)
  const [categoryLoader, setCategoryLoader] = useState(false)
  const categoryListDropdown = reduxState?.category?.categoriesDropdown;
  const categoryPaginationData = reduxState?.category?.categoriesPagination;

  const [catLoader, setCatLoader] = useState(true)
  const [brndLoader, setBrndLoader] = useState(true)


  useEffect(() => {
    if (brandSearchText === null) {
      dispatch(getBrandDropdownlistPagination(brandCurrentPage, "", setBrandLoader, setBrndLoader))
    } else {
      if (brandTimeOutId) {
        clearTimeout(brandTimeOutId)
      }
      brandTimeOutId = setTimeout(() => {
        dispatch(getBrandDropdownlistPagination(brandCurrentPage, brandSearchText, setBrandLoader, setBrndLoader))
      }, 500);
    }
  }, [brandSearchText, brandCurrentPage])

  useEffect(() => {
    if (categorySearchText === null) {
      dispatch(getCategoriesDropdownListPagination(categoryCurrentPage, "", setCategoryLoader, setCatLoader))
    } else {
      if (categoryTimeOutId) {
        clearTimeout(categoryTimeOutId)
      }
      categoryTimeOutId = setTimeout(() => {
        dispatch(getCategoriesDropdownListPagination(categoryCurrentPage, categorySearchText, setCategoryLoader, setCatLoader))
      }, 500);
    }
  }, [categorySearchText, categoryCurrentPage])

  // useEffect(() => {
  //   dispatch(getBrandDropdownlist());
  // }, []);


  //get business location and stored it in redux
  const getBusinessLocation = (business_id) => {
    dispatch(getLocations(business_id));
  };

  // const getInovices = () => {
  //   const success = () => { }
  //   const fail = () => { }
  //   dispatch(getMultiLanguageInvoice(access_token, success, fail))
  // }
  // useEffect(() => {
  //   getInovices()
  // }, [reduxInvoices?.length])


  useEffect(() => {
    dispatch(getVendorsDropdownList());
    // dispatch(getCategoriesDropdownList());
    if (reduxlocationsList?.length === 0) {
      getBusinessLocation(businessData?.id);
    }
  }, []);

  useEffect(() => {
    if (!catLoader && !brndLoader) {
      if (loader)
        setEditLoader(loader)
      if (loader)
        setEditLoader(loader)
      else
        setEditLoader(false)
    } else if (loader)
      setEditLoader(loader)
  }, [loader, catLoader, brndLoader])

  // useEffect(() => {
  //   if (edit_data?.id && edit_mode) {
  //     const locationList = edit_data?.location?.map((item) => item?.id);
  //     const lctn_qty = edit_data?.location_quantities?.map(
  //       ({ location, current_stock, low_stock, reorder_quantity }) => ({
  //         id: location?.id,
  //         current_stock,
  //         low_stock,
  //         reorder_quantity,
  //       })
  //     );
  //     setProducts({
  //       ...edit_data,
  //       barcode_id: edit_data?.barcode_id != "null" ? edit_data?.barcode_id : "",
  //       brand: edit_data?.brand?.id ? edit_data?.brand?.id : undefined,
  //       category: edit_data?.category?.id ? edit_data?.category?.id : undefined,
  //       vendor: edit_data?.vendor?.id ? edit_data?.vendor?.id : undefined,
  //       short_description: edit_data?.short_description != "null" ? edit_data?.short_description : "",
  //       description: edit_data?.description != "null" ? edit_data?.description : "",
  //       location: locationList.length > 0 ? locationList : [],

  //       product_images: edit_data?.media?.length > 0 ? edit_data?.media[0].image : undefined,
  //       alert_when_stock_becomes_lowest: edit_data?.stocks?.length > 0
  //         ? edit_data?.stocks[0].alert_when_stock_becomes_lowest
  //         : false,
  //       currency_retail_price: edit_data?.currency_retail_price?.length > 0
  //         ? edit_data?.currency_retail_price
  //         : [],
  //       location_quantities: lctn_qty ? lctn_qty : [],
  //       size: edit_data?.size
  //     });
  //     setProductIsEdit(true);
  //   }
  // }, [edit_mode, edit_data]);

  useEffect(() => {
    if (edit_data?.id && edit_mode) {
      const locationList = edit_data?.location?.map((item) => item?.id);
      const lctn_qty = edit_data?.location_quantities?.map(
        ({ location, current_stock, low_stock, reorder_quantity }) => ({
          id: location?.id,
          current_stock,
          low_stock,
          reorder_quantity,
        })
      );
      setProducts({
        ...edit_data,
        barcode_id: edit_data?.barcode_id != "null" ? edit_data?.barcode_id : "",
        brand: edit_data?.brand?.id ? edit_data?.brand?.id : undefined,
        category: edit_data?.category?.id ? edit_data?.category?.id : undefined,
        vendor: edit_data?.vendor?.id ? edit_data?.vendor?.id : undefined,
        short_description: edit_data?.short_description != "null" ? edit_data?.short_description : "",
        description: edit_data?.description != "null" ? edit_data?.description : "",
        location: locationList.length > 0 ? locationList : [],

        product_images: edit_data?.media?.length > 0 ? edit_data?.media[0].image : undefined,
        alert_when_stock_becomes_lowest: edit_data?.stocks?.length > 0
          ? edit_data?.stocks[0].alert_when_stock_becomes_lowest
          : false,
        currency_retail_price: edit_data?.currency_retail_price?.length > 0
          ? edit_data?.currency_retail_price
          : [],
        location_quantities: lctn_qty ? lctn_qty : [],
        size: edit_data?.size
      });
      setProductIsEdit(true)
      setSelectedCurrency()
    }
  }, [edit_data, edit_mode])

  useEffect(() => {
    if (products?.vendor == '' && vendorsRedux?.length > 0) {
      const activeVendors = vendorsRedux
      setProducts({
        ...products,
        vendor: vendorsRedux?.length == 1 ? activeVendors[0]?.id : ''
      })
    } else {
      setProducts({
        ...products,
        vendor: ''
      })
    }
  }, [vendorsRedux, vendorsRedux?.length])

  useEffect(() => {
    if (products?.category === "" && categoryListDropdown?.length > 0) {
      const activeCategories = categoryListDropdown
      let productObj = { ...products, category: activeCategories?.length == 1 ? activeCategories[0]?.id : '' }
      setProducts(productObj)
    }
  }, [categoryListDropdown, categoryListDropdown?.length, products?.category])

  useEffect(() => {
    if (products?.brand == '' && brandListDropdown?.length > 0) {
      const activeBrands = brandListDropdown
      setProducts({
        ...products,
        brand: brandListDropdown?.length == 1 ? activeBrands[0]?.id : ''
      })
    }
  }, [brandListDropdown, brandListDropdown?.length])

  useEffect(() => {
    let all_location = [];
    if (step === 2 && location_quantities?.length === 0 && !edit_mode) {
      reduxlocationsList?.forEach((itm) => {
        all_location?.push({
          id: itm?.id,
          current_stock: 0,
          low_stock: 0,
          reorder_quantity: 0,
        });
      });
      setProducts((prev) => ({ ...prev, location_quantities: all_location }));
    }

    if (step === 2 && edit_mode) {
      const result = reduxlocationsList?.filter(
        (elem) => !location_quantities?.find(({ id }) => elem.id === id)
      );
      result?.forEach((itm) => {
        all_location?.push({
          id: itm?.id,
          current_stock: 0,
          low_stock: 0,
          reorder_quantity: 0,
        });
      });

      setProducts((prev) => ({
        ...prev,
        location_quantities: [...prev?.location_quantities, ...all_location],
      }));
    }
  }, [step]);

  const [togglecat, settogglecat] = useState(false);
  const [togglebrand, settogglebrand] = useState(false);

  return (
    <>
      {togglecat && (
        <AddCategoryBtn
          fill={false}
          border={false}
          icon={false}
          open={true}
          showText={false}
          close={settogglecat}
        />
      )}

      {togglebrand && (
        <AddBrandPopup
          // onclose={() => {
          //   settogglebrand(false);
          // }}
          onclose={() => {
            settogglebrand(false);
            setEditBrand(false);
          }}
          edit={edit_brand ? true : false}
          edit_data={edit_brand}
        />
      )}

      <Popup
        heading={edit_mode ?
          selectedLanguage?.name == "English" ? "Edit Product" : translation[235]?.value ? translation[235]?.value : "Edit Product"
          : selectedLanguage?.name == "English" ? "Add new product" : translation[235]?.value ? translation[235]?.value : "Add new product"}
        z="z-[445]"
        onclose={() => {
          setProducts({
            vendor: "",
            category: "",
            brand: "",
            name: "",
            cost_price: "",
            full_price: "",
            size: "",
            invoices: [],
            currency_retail_price: [],
            // sell_price: "",
            short_description: "",
            description: "",
            barcode_id: "",
            sku: "",
            product_images: "",
            is_active: true,
            alert_when_stock_becomes_lowest: true,
            location_quantities: [],
          })
          onClose && onClose();
        }}
        close={productModalToggler}
      >
        {(catLoader || brndLoader || editLoader) ?
          <ProductFormShimmer />
          :
          <div className="flex flex-col gap-6">
            <FormSteps
              currentStep={step}
              disabledStep={3}
              active={step}
              setStep={setStep}
              steps={[
                selectedLanguage?.name == "English" ? "Product Details" : translation[291]?.value ? translation[291]?.value : "Product Details",
                selectedLanguage?.name == "English" ? "Stock Details" : translation[930]?.value ? translation[930]?.value : "Stock Details",
                selectedLanguage?.name == "English" ? "Finish" : translation[547]?.value ? translation[547]?.value : "Finish"
              ]}
              stepsLength="3"
            />
            {step == 1 && (
              <div ref={formRef} className="flex flex-col gap-5">
                <div className="flex items-center gap-4 lg:flex-row flex-col">
                  <CircleIcon>
                    <Svgs.Bricks />
                  </CircleIcon>
                  <div className="flex flex-col gap-1 lg:text-left text-center">
                    <h2 className="text-primary font-semibold text-xl">
                      {selectedLanguage?.name == "English" ? "Product Details" : translation[291]?.value ? translation[291]?.value : "Product Details"}
                    </h2>
                    <p className="text-[#7B7B7B] text-sm">
                      {selectedLanguage?.name == "English" ? "Add details of the product with a unique"
                        : translation[931]?.value
                          ? translation[931]?.value
                          : "Add details of the product with a unique"}{" "}{selectedLanguage?.name == "English" ? "SKU" : translation[932]?.value ? translation[932]?.value : "SKU"}.
                    </p>
                  </div>
                </div>
                <LoginInput
                  title={selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter product name" : translation[933]?.value ? translation[933]?.value : "Enter product name"}
                  name={"name"}
                  value={name}
                  // charactersMaxLength={25}
                  onEnterSubmit={() => {
                    onProdcutSubmit(1);
                  }}
                  onBlur={() => onBlurHandler({ name: name })}
                  error={errors?.name}
                  onChange={(e) => productHandleChange(e)}
                />
                <div className="grid lg:grid-cols-2 gap-3">
                  <LoginInput
                    required={false}
                    title={
                      <I_Info_Icon
                        text={selectedLanguage?.name == "English" ? "Barcode ID" : translation[934]?.value ? translation[934]?.value : "Barcode ID"}
                        info={selectedLanguage?.name == "English"
                          ? "Barcode ID can be the product’s identifier, a series of numerical or alphanumerical digits that are used to identify a specific product. Ultimately, they are the key to helping customers locate products online."
                          : translation[935]?.value
                            ? translation[935]?.value
                            : "Barcode ID can be the product’s identifier, a series of numerical or alphanumerical digits that are used to identify a specific product. Ultimately, they are the key to helping customers locate products online."
                        }
                      />
                    }
                    isCapitalized
                    placeholder={selectedLanguage?.name == "English" ? "Enter product's barcode ID" : translation[936]?.value ? translation[936]?.value : "Enter product's barcode ID"}
                    name={"barcode_id"}
                    value={barcode_id}
                    onEnterSubmit={() => {
                      onProdcutSubmit(1);
                    }}
                    onChange={(e) => productHandleChange(e)}
                  />
                  <LoginInput
                    title={
                      <I_Info_Icon
                        text={selectedLanguage?.name == "English" ? "SKU" : translation[932]?.value ? translation[932]?.value : "SKU"}
                        info={selectedLanguage?.name == "English"
                          ? "SKU stands for “stock keeping unit” and – as the name suggests – it is a number (usually eight alphanumeric digits) that retailers assign to products to keep track of stock internally."
                          : translation[938]?.value
                            ? translation[938]?.value
                            : "SKU stands for “stock keeping unit” and – as the name suggests – it is a number (usually eight alphanumeric digits) that retailers assign to products to keep track of stock internally."
                        }
                      />
                    }
                    isCapitalized
                    placeholder={selectedLanguage?.name == "English" ? "Enter SKU ID" : translation[937]?.value ? translation[937]?.value : "Enter SKU ID"}
                    name={"sku"}
                    value={sku}
                    onBlur={() => onBlurHandler({ sku: sku })}
                    error={errors?.sku}
                    onChange={(e) => productHandleChange(e)}

                  />
                  <div>
                    <PaginationDropdown
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Brands" : translation[395]?.value ? translation[395]?.value : "Select Brands"}
                      name={"brand"}
                      value={brand}
                      onChange={productHandleChange}
                      onBlur={() => onBlurHandler({ brand: brand })}
                      error={(!brand && errors?.brand) && errors?.brand}
                      options={brandListDropdown && brandListDropdown?.map((itm) => {
                        return {
                          label: itm?.name,
                          value: itm?.id,
                        }
                      })}

                      setPage={setBrandCurrentPage}
                      setDropdownSearch={setBrandSearchText}
                      page={brandCurrentPage}
                      dropdownSearch={brandSearchText}
                      lastPage={brandPaginationData?.total_pages}
                      currentPage={brandPaginationData?.currentPage}
                      loading={brandLoader}
                    />
                    {brandListDropdown && brandListDropdown.length == 0 && (
                      <div
                        className="flex items-center gap-2 cursor-pointer pt-2"
                        onClick={() => {
                          settogglebrand(true)
                          // settogglecat(true);
                          // navigate('/dashboard/account/inventory-operations/brands')
                        }}
                      >
                        <p className="text-sm mt-[0.75rem]">
                          {selectedLanguage?.name == "English" ? "No brand found. " : translation[838]?.value ? translation[838]?.value : "No brand found. "}
                          <span className="underline mx-px text-[#5679FF]">
                            {selectedLanguage?.name == "English" ? "Click here" : translation[502]?.value ? translation[502]?.value : "Click here"}
                          </span>
                          {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <PaginationDropdown
                      required
                      title={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Category" : translation[889]?.value ? translation[889]?.value : "Select Category"}
                      name={"category"}
                      value={category}
                      onChange={productHandleChange}
                      onBlur={() => onBlurHandler({ category: category })}
                      error={(!category && errors?.category) && errors?.category}
                      options={categoryListDropdown && categoryListDropdown?.map((item) => {
                        return { label: item.name, value: item.id };
                      })}

                      setPage={setCategoryCurrentPage}
                      setDropdownSearch={setCategorySearchText}
                      page={categoryCurrentPage}
                      dropdownSearch={categorySearchText}
                      lastPage={categoryPaginationData?.total_pages}
                      currentPage={categoryPaginationData?.currentPage}
                      loading={categoryLoader}
                    />

                    {categoryListDropdown?.length == 0 && (
                      <div
                        className="flex items-center gap-2 cursor-pointer pt-2"
                        onClick={() => {
                          localStorage.setItem("category_create_from_sale", true)
                          settogglecat(true);
                          // navigate('/dashboard/account/inventory-operations/category')
                        }}
                      >
                        <p className="text-sm mt-[0.75rem]">
                          {selectedLanguage?.name == "English" ? "No category found. " : translation[939]?.value ? translation[939]?.value : "No category found. "}
                          <span className="underline mx-px text-[#5679FF]">
                            {selectedLanguage?.name == "English" ? "Click here" : translation[502]?.value ? translation[502]?.value : "Click here"}
                          </span>
                          {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <Dropwdown
                      title={selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Vendor" : translation[961]?.value ? translation[961]?.value : "Select Vendor"}
                      name={"vendor"}
                      value={vendor}
                      onChange={productHandleChange} vendorsReduxvendorModalToggler
                      options={
                        vendorsRedux?.length > 0 ?
                          [
                            { label: selectedLanguage?.name == "English" ? "Select Vendor" : translation[961]?.value ? translation[961]?.value : "Select Vendor", value: "" },
                            ...vendorsRedux?.map((item) => {
                              return {
                                label: item.vendor_name,
                                value: item.id,
                              };
                            }),
                          ] :
                          vendorsRedux?.map((item) => {
                            return {
                              label: item.vendor_name,
                              value: item.id,
                            };
                          })
                      }
                    // options={
                    //   vendorsRedux &&
                    //   vendorsRedux
                    //     ?.filter((itm) => itm.is_active)
                    //     .map((item) => {
                    //       return { label: item.vendor_name, value: item.id };
                    //     })
                    // }
                    />
                    {vendorsRedux && vendorsRedux?.length == 0 && (
                      <div
                        className="flex items-center gap-2 cursor-pointer pt-2"
                        onClick={() => {
                          // settogglecat(true);
                          setVendorErrors({})
                          vendorModalToggler();
                          setAddVendor(true)
                          setIsEdit(false);
                        }}
                      >
                        <p className="text-sm mt-6">
                          {selectedLanguage?.name == "English" ? "No vendor found. " : translation[940]?.value ? translation[940]?.value : "No vendor found. "}
                          <span className="underline mx-px text-[#5679FF]">
                            {selectedLanguage?.name == "English" ? "Click here" : translation[502]?.value ? translation[502]?.value : "Click here"}
                          </span>
                          {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex-1">
                    <FloatInput
                      rightIcon={location_currency}
                      onEnterSubmit={() => {
                        onProdcutSubmit(1);
                      }}
                      title={
                        <I_Info_Icon
                          text={selectedLanguage?.name == "English" ? "Cost price" : translation[941]?.value ? translation[941]?.value : "Cost price"}
                          info={selectedLanguage?.name == "English"
                            ? "The total costs involved in making a product and getting it ready for sale including manufacturing, the cost of raw materials, labor and manufacturing overhead."
                            : translation[941]?.value
                              ? translation[941]?.value
                              : "The total costs involved in making a product and getting it ready for sale including manufacturing, the cost of raw materials, labor and manufacturing overhead."}
                        />
                      }

                      placeholder={selectedLanguage?.name == "English" ? "Enter cost price" : translation[942]?.value ? translation[942]?.value : "Enter cost price"}
                      name={"cost_price"}
                      value={cost_price}
                      onBlur={() => onBlurHandler({ cost_price: cost_price })}
                      error={errors?.cost_price}
                      onChange={(e) => productHandleChange(e)}
                      required
                      firstLetterGreaterThanZero
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-2 gap-3">
                  <div className="">
                    <Dropwdown
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                      placeholder={selectedLanguage?.name == "English" ? "Select currency" : translation[68]?.value ? translation[68]?.value : "Select currency"}
                      name={"selectedCurrency"}
                      value={selectedCurrency}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                      options={allUinqueCurrencyList?.map((itm) => {
                        return {
                          label: itm?.currency?.code,
                          value: itm?.currency?.id,
                        };
                      })}
                    />
                  </div>
                  <div className="flex items-end gap-2">
                    <FloatInput
                      parentClass='flex-1'
                      onEnterSubmit={() => {
                        onProdcutSubmit(1);
                      }}
                      disabled={allCurrenciesData?.length == products?.currency_retail_price?.length}
                      name={"retailPrice"}
                      value={retailPrice}
                      onBlur={() => onBlurHandler({ retailPrice: retailPrice })}
                      error={errors?.retailPrice}
                      onChange={(e) => setRetailPrice(e.target.value)}
                      title={
                        <I_Info_Icon
                          top={true}
                          text={selectedLanguage?.name == "English" ? "Retail Price" : translation[294]?.value ? translation[294]?.value : "Retail Price"}
                          info={selectedLanguage?.name == "English"
                            ? "The retail price is how much a buyer pays for a product or service. It depends on how much buyers and sellers are willing to pay, and what is the trend in the market."
                            : translation[963]?.value
                              ? translation[963]?.value
                              : "The retail price is how much a buyer pays for a product or service. It depends on how much buyers and sellers are willing to pay, and what is the trend in the market."
                          }
                        />
                      }
                      placeholder={selectedLanguage?.name == "English" ? "Enter retail price" : translation[944]?.value ? translation[944]?.value : "Enter retail price"}
                    />
                    <LoginBtn
                      disabled={!selectedCurrency || !retailPrice}
                      // className={"mt-6"}
                      className={(retailPrice && selectedCurrency) && "shake-bottom"}
                      onClick={() => {
                        // if (selectedCurrency && retailPrice) {
                        addNewItem();
                        // } else {
                        //   setErrors((prev) => ({
                        //     ...prev,
                        //     retailPrice: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
                        //   }))
                        // }
                      }}
                      text={
                        <div className="flex items-center gap-2">
                          <Svgs.Plus />
                          <p className="">{selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}</p>
                        </div>
                      }
                    />
                  </div>
                </div>

                {currency_retail_price?.length > 0 && (
                  <div className="flex items-center flex-wrap gap-2">
                    {currency_retail_price &&
                      currency_retail_price?.map((item, i) => {
                        const crrncy = currencyList?.find(
                          (itm) => itm?.id === item?.currency
                        );
                        return (
                          !item?.is_deleted && (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">{`${item?.retail_price} ${crrncy?.code}`}</p>
                              <span
                                onClick={() =>
                                  removedItem(item?.currency, "retail")
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          )
                        );
                      })}
                  </div>
                )}

                {removeDuplicate?.length > 0 ?
                  <div className="grid md:grid-cols-2 gap-5 my-4">
                    <div className="">
                      <Dropwdown
                        required={false}
                        title={selectedLanguage?.name == "English" ? "Invoice Language" : translation[271]?.value ? translation[271]?.value : "Invoice Language"}
                        placeholder={selectedLanguage?.name == "English" ? "Select invoice languages" : translation[964]?.value ? translation[964]?.value : "Select invoice languages"}
                        name={"invoiceLanguage"}
                        value={invoiceLanguage}
                        onChange={(e) => setInvoiceLanguage(e.target.value)}
                        options={removeDuplicate?.filter(current => !invoices?.find(itm => itm?.invoiceLanguage === current?.language))
                          ?.filter(cur => !invoices?.find(itm => itm?.invoiceLanguage === cur?.language))?.map((itm) => {
                            return {
                              label: itm?.language_data?.name,
                              value: itm?.language_data?.id,
                            };
                          })}
                      />
                    </div>
                    <div className="flex items-end gap-2">
                      <LoginInput
                        type={"text"}
                        parentClass='flex-1'
                        title={selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter product name" : translation[933]?.value ? translation[933]?.value : "Enter product name"}
                        required={false}
                        name={"product_name"}
                        value={product_name}
                        // charactersMaxLength={25}
                        onBlur={() => onBlurHandler({ product_name: product_name })}
                        // disabled={allUinqueCurrencyList?.length == priceservice ? priceservice?.length : serviceData.priceservice?.length}
                        disabled={products?.invoices?.length == reduxInvoices?.length}
                        onChange={(e, extraValues) => {
                          const { name, value } = e.target;
                          setProduct_name(value);
                        }}
                        error={errors?.product_name}
                      />
                      <LoginBtn
                        disabled={!invoiceLanguage || !product_name}
                        // className={"mt-6"}
                        className={(invoiceLanguage && product_name) && "shake-bottom"}
                        onClick={() => {
                          addNewInvoiceLanguage();
                        }}
                        text={selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                      />
                    </div>
                  </div>
                  : ""
                }
                {/* {removeDuplicate && removeDuplicate?.length == 0 && (
                <div
                  className="flex items-center gap-2 cursor-pointer pt-2"
                  onClick={() => {
                    navigate('/dashboard/account/setup/invoice-translation')
                  }}
                >
                  <p className="text-sm">
                    {selectedLanguage?.name == "English" ? "No invoices found. " : translation[838]?.value ? translation[838]?.value : "No invoices found. "}
                    <span className="underline mx-px">
                      {selectedLanguage?.name == "English" ? "Click here" : translation[502]?.value ? translation[502]?.value : "Click here"}
                    </span>
                    {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                  </p>
                </div>
              )} */}
                {invoices?.length > 0 && (
                  <div className="flex items-center flex-wrap gap-2">
                    {invoices &&
                      invoices?.map((item, i) => {
                        const language = reduxInvoices?.find((itm) => itm?.language === item?.invoiceLanguage);
                        return (
                          !item?.is_deleted && (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">{`${item?.product_name} ${language?.language_data?.name}`}</p>
                              <span
                                onClick={() =>
                                  removedItem(item?.invoiceLanguage, "invoices")
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          )
                        );
                      })}
                  </div>
                )}

                {/* <div className="lg:flex-row flex-col flex lg:items-center gap-2 flex-wrap">
                  <FloatInput
                    rightIcon={"g"}
                    required={"true"}
                    title={selectedLanguage?.name == "English" ? "Product Size" : translation[945]?.value ? translation[945]?.value : "Product Size"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter product size" : translation[946]?.value ? translation[946]?.value : "Enter product size"}
                    name={"size"}
                    value={size}
                    onEnterSubmit={() => {
                      onProdcutSubmit(1);
                    }}
                    onBlur={() => onBlurHandler({ size: size })}
                    error={errors?.size}
                    onChange={(e) => productHandleChange(e)}
                  />
                </div> */}
                <Textarea
                  title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter description Product" : translation[965]?.value ? translation[965]?.value : "Enter description Product"}
                  name={"short_description"}
                  value={short_description}
                  onChange={(e) => productHandleChange(e)}
                  rows="3"
                  limit="50"
                  // onBlur={() => onBlurHandler({ short_description: short_description })}
                  error={errors.short_description}
                />
                <Textarea
                  title={selectedLanguage?.name == "English" ? "Consumptions Notes" : translation[947]?.value ? translation[947]?.value : "Consumptions Notes"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter consumptions note" : translation[948]?.value ? translation[948]?.value : "Enter consumptions note"}
                  parent=''
                  name={"description"}
                  value={description}
                  onChange={(e) => productHandleChange(e)}
                  rows="5"
                  limit="150"
                  // onBlur={() => onBlurHandler({ description: description })}
                  error={errors.description}
                />

                <UploadFile
                  accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                  onChange={productHandleChange}
                  name="product_images"
                  mb_file_size={10}
                  required={false}
                  edit={edit_mode ? true : false}
                  value={
                    typeof products.product_images == "string"
                      ? products.product_images
                      : typeof products.product_images == "object"
                        ? products.product_images[0]
                        : undefined
                  }
                  title={selectedLanguage?.name == "English" ? "Product Image" : translation[949]?.value ? translation[949]?.value : "Product Image"}
                  error={errors?.product_images}
                />
                <div className="flex items-center justify-end ">
                  <LoginBtn
                    // disabled={
                    //   (products?.currency_retail_price?.length === 0) ||
                    //   (allUinqueCurrencyList?.length != products?.currency_retail_price?.length)
                    // }
                    loading={loading}
                    onClick={() => {
                      onProdcutSubmit(1);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <div className="">
                        {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                      </div>
                      <Svgs.NextArrow fill={"#fff"} />
                    </div>
                  </LoginBtn>
                </div>
              </div>
            )}

            {/* Add Vendor popup starts here */}
            {AddVendor && (
              <Popup
                heading={isEdit
                  ? selectedLanguage?.name == "English" ? "Update Vendor" : translation[970]?.value ? translation[970]?.value : "Update Vendor"
                  : selectedLanguage?.name == "English" ? "Add Vendor" : translation[970]?.value ? translation[970]?.value : "Add Vendor"
                }
                close={() => vendorModalToggler()}
                open={AddVendor}
              >
                <div className="flex flex-col gap-6" ref={formRefVendor}>
                  <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col ">
                    <CircleIcon>
                      <Svgs.Vendor_details />
                    </CircleIcon>
                    <div className="flex flex-col gap-1 lg:text-left text-center">
                      <h2 className="text-primary font-semibold text-xl">
                        {selectedLanguage?.name == "English" ? "Vendor Details" : translation[978]?.value ? translation[978]?.value : "Vendor Details"}
                      </h2>
                      <p className="text-[#7B7B7B] text-sm">
                        {selectedLanguage?.name == "English" ? "Adding vendor information with ordered product stock" : translation[1324]?.value ? translation[1324]?.value : "Adding vendor information with ordered product stock"}
                      </p>
                    </div>
                  </div>
                  <LoginInput
                    required={true}
                    title={selectedLanguage?.name == "English" ? "Vendor Name" : translation[971]?.value ? translation[971]?.value : "Vendor Name"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter vendor name" : translation[980]?.value ? translation[980]?.value : "Enter vendor name"}
                    name={"vendor_name"}
                    value={vendor_name}
                    // charactersMaxLength={25}
                    onChange={(e) => vendorHandleChange(e)}
                    onBlur={() => onBlurHandler({ vendor_name: vendor_name })}
                    error={vendorErrors?.vendor_name}
                  />

                  <PhoneInput
                    required={false}
                    name="mobile_number"
                    title={selectedLanguage?.name == "English" ? "Contact Number" : translation[981]?.value ? translation[981]?.value : "Contact Number"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter your contact number" : translation[982]?.value ? translation[982]?.value : "Enter your contact number"}
                    value={mobile_number}
                    onChange={(e) => vendorHandleChange(e)}
                    // onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
                    error={vendorErrors?.mobile_number}
                    edit={isEdit ? true : false}
                  />

                  <LoginInput
                    required={false}
                    title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter your email" : translation[510]?.value ? translation[510]?.value : "Enter your email"}
                    name={"email"}
                    value={email}
                    onChange={(e) => vendorHandleChange(e)}
                    onBlur={() => onBlurHandler({ email: email })}
                    error={vendorErrors?.email}
                  />

                  <Dropwdown
                    required={false}
                    title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
                    placeholder={selectedLanguage?.name == "English" ? "Select country" : translation[53]?.value ? translation[53]?.value : "Select country"}
                    name={"country"}
                    value={country}
                    onChange={(e) => vendorHandleChange(e, "country")}
                    // onBlur={() => onBlurHandler({ country: country })}
                    error={vendorErrors?.country}
                    options={countriesList}
                    edit={isEdit ? true : false}
                  />
                  <div className="grid md:grid-cols-2 gap-5">
                    <Dropwdown
                      title={selectedLanguage?.name == "English" ? "State" : translation[481]?.value ? translation[481]?.value : "State"}
                      placeholder={selectedLanguage?.name == "English" ? "Select State" : translation[55]?.value ? translation[55]?.value : "Select State"}
                      required={false}
                      name={"state"}
                      value={state}
                      onChange={(e) => vendorHandleChange(e, "state")}
                      // onBlur={() => onBlurHandler({ state: state })}
                      // error={vendorErrors?.state}
                      options={stateList}
                      edit={isEdit ? true : false}
                    />
                    <LoginInput
                      required={false}
                      name={"city"}
                      value={city}
                      disabled={!state || !country}
                      onChange={(e) => vendorHandleChange(e, "city")}
                      title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter city name" : translation[57]?.value ? translation[57]?.value : "Enter city name"}
                    />
                    {/* <Dropwdown
                      title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                      placeholder={selectedLanguage?.name == "English" ? "Select city" : translation[57]?.value ? translation[57]?.value : "Select city"}
                      required={false}
                      name={"city"}
                      value={city}
                      onChange={(e) => vendorHandleChange(e, "city")}
                      // onBlur={() => onBlurHandler({ city: city })}
                      // error={vendorErrors?.city}
                      options={cityList}
                      edit={isEdit ? true : false}
                    /> */}
                  </div>
                  <AutoPlaceInput
                    onChange={vendorHandleChange}
                    name={"address"}
                    value={address}
                    required={true}
                    title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter address" : translation[482]?.value ? translation[482]?.value : "Enter address"}
                    error={vendorErrors?.address}
                  />
                  {/* <LoginInput
                    required={true}
                    title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter address" : translation[482]?.value ? translation[482]?.value : "Enter address"}
                    name={"address"}
                    value={address}
                    onChange={(e) => vendorHandleChange(e)}
                    onBlur={() => onBlurHandler({ address: address })}
                    error={vendorErrors?.address}
                  /> */}

                  <div className="grid md:grid-cols-2 gap-5">
                    <LoginInput
                      required={false}
                      title={
                        <I_Info_Icon
                          text={selectedLanguage?.name == "English" ? "GSTIN" : translation[977]?.value ? translation[977]?.value : "GSTIN"}
                          info={selectedLanguage?.name == "English" ? "GSTIN is a unique identification number assigned to a business registered."
                            : translation[986]?.value ? translation[986]?.value : "GSTIN is a unique identification number assigned to a business registered."
                          }
                        />
                      }
                      placeholder={selectedLanguage?.name == "English" ? "Enter tax tegistration no." : translation[987]?.value ? translation[987]?.value : "Enter tax tegistration no."}
                      name={"gstin"}
                      value={gstin}
                      onChange={(e) => vendorHandleChange(e)}
                      // onBlur={() => onBlurHandler({ gstin: gstin })}
                      error={vendorErrors?.gstin}
                    />
                    <LoginInput
                      required={false}
                      title={selectedLanguage?.name == "English" ? "Website" : translation[917]?.value ? translation[917]?.value : "Website"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter website link" : translation[681]?.value ? translation[681]?.value : "Enter website link"}
                      name={"website"}
                      value={website}
                      onChange={(e) => vendorHandleChange(e)}
                      // onBlur={() => onBlurHandler({ website: website })}
                      error={vendorErrors?.website}
                    />
                  </div>

                  <Dropwdown
                    required={true}
                    name={"is_active"}
                    value={vendorActive}
                    onChange={(e) => vendorHandleChange(e, "is_active")}
                    // onBlur={() => onBlurHandler({ is_active: is_active })}
                    error={vendorErrors?.is_active}
                    title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
                    options={[
                      {
                        value: true,
                        label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                      },
                      {
                        value: false,
                        label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                      },
                    ]}
                  />

                  <div className="flex items-center justify-start ">
                    <LoginBtn
                      loading={vendorSubmitLoading}
                      disabled={vendorSubmitLoading}
                      // disabled={vendorSubmitLoading
                      //   || !vendor_name
                      //   || !email}
                      text={selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                      onClick={() => checkVendorExistenceBeforeSubmit()}
                    />
                  </div>
                </div>
              </Popup>
            )}
            {/* Add Vendor popup ends here */}

            {step == 2 && (
              <div ref={formRef} className="flex flex-col gap-5">
                <ToggleWithText
                  title={selectedLanguage?.name == "English" ? "Alert when stock is at its lowest"
                    : translation[950]?.value
                      ? translation[950]?.value
                      : "Alert when stock is at its lowest"
                  }
                  checked={alert_when_stock_becomes_lowest}
                  onChange={() =>
                    setProducts((prev) => ({
                      ...prev,
                      alert_when_stock_becomes_lowest:
                        !alert_when_stock_becomes_lowest,
                    }))
                  }
                />

                {location_quantities?.length > 0 && (
                  <h1 className="text-primary text-xl font-semibold ">
                    {selectedLanguage?.name == "English" ? "Add Quantity" : translation[951]?.value ? translation[951]?.value : "Add Quantity"}
                  </h1>
                )}
                {location_quantities?.map((lctn, index) => {
                  let res = reduxlocationsList?.find(
                    (itm) => itm?.id === lctn?.id
                  );
                  const handleStockChange = (e, index) => {
                    const { name, value } = e.target;

                    setProducts((prev) => ({
                      ...prev,
                      location_quantities: [
                        ...location_quantities.map((address, ind) => {
                          if (index == ind) {
                            return {
                              ...address,
                              [name]: value,
                              allow_edit_non_disable: true
                            };
                          }
                          return address;
                        }),
                      ],
                    }));

                    if (secondStepError?.length > 0) {
                      const updatedFormErrors = secondStepError != undefined ? [...secondStepError] : {};
                      if (name && parseInt(value, 10) === 0) {
                        updatedFormErrors[index] = {
                          ...updatedFormErrors[index],
                          [name]: 'Value must be greater than 0.',
                        };
                      } else {
                        // Clear the error for the changed field
                        updatedFormErrors[index] = {
                          ...updatedFormErrors[index],
                          [name]: '',
                        };
                      }
                      setSecondStepError(updatedFormErrors);
                    }
                  };

                  let filteredErrorObject = {}
                  if (secondStepError != undefined && secondStepError?.length > 0) {
                    filteredErrorObject = secondStepError?.find(itm => itm?.id == lctn?.id)
                  }
                  return (
                    <>
                      <div className="flex items-center gap-4 lg:flex-row flex-col mt-5" key={index}>
                        <CircleIcon className="!rounded-lg" bg="#5679FF">
                          <Svgs.Home />
                        </CircleIcon>
                        <div className="flex flex-col gap-1 lg:text-left text-center cursor-pointer">
                          <h2 className="text-primary font-semibold text-xl cursor-pointer">
                            {res?.address_name}
                          </h2>
                          <p className="text-[#7B7B7B] text-sm">{res?.address}</p>
                        </div>
                      </div>

                      <LoginInput
                        title={selectedLanguage?.name == "English" ? "Add Quantity" : translation[951]?.value ? translation[951]?.value : "Add Quantity"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter stock" : translation[953]?.value ? translation[953]?.value : "Enter stock"}
                        type="number"
                        name={"current_stock"}
                        value={lctn?.current_stock}
                        onChange={(e) => { handleStockChange(e, index) }}
                        onlyNumericAllowed={true}
                        // onChange={(e) => {
                        //   const { name, value } = e.target;

                        //   setProducts((prev) => ({
                        //     ...prev,
                        //     location_quantities: [
                        //       ...location_quantities?.map((address, ind) => {
                        //         if (index == ind) {
                        //           return {
                        //             ...address,
                        //             [name]: value ? Number(value) : "",
                        //           };
                        //         }
                        //         return address;
                        //       }),
                        //     ],
                        //   }));
                        // }}
                        disabled={edit_mode ? lctn?.current_stock ? !lctn?.allow_edit_non_disable ? true : false : false : false}
                        // onBlur={() => onBlurHandler({ current_stock: lctn?.current_stock })}
                        error={filteredErrorObject && filteredErrorObject != {} ? filteredErrorObject?.current_stock === '0' ? 'Value must be greater than 0.' : filteredErrorObject.current_stock : ''}
                      />
                      {(
                        (edit_mode && !lctn?.allow_edit_non_disable &&
                          (lctn?.current_stock !== 0 || lctn?.current_stock != ""))
                      ) && (
                          <p className="text-sm text-[#7B7B7B]">
                            {selectedLanguage?.name == "English"
                              ? "You can not edit your current stock"
                              : translation[966]?.value
                                ? translation[966]?.value
                                : "You can not edit your current stock"
                            }
                          </p>
                        )}
                      <div className="grid lg:grid-cols-2 gap-4">
                        <LoginInput
                          title={selectedLanguage?.name == "English" ? "Low Stock Level" : translation[954]?.value ? translation[954]?.value : "Low Stock Level"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter quantity" : translation[956]?.value ? translation[956]?.value : "Enter quantity"}
                          type="number"
                          name={"low_stock"}
                          value={lctn?.low_stock}
                          onChange={(e) => { handleStockChange(e, index) }}
                          onlyNumericAllowed={true}
                          // onChange={(e) => {
                          //   const { name, value } = e.target;

                          //   setProducts((prev) => ({
                          //     ...prev,
                          //     location_quantities: [
                          //       ...location_quantities?.map((address, ind) => {
                          //         if (index == ind) {
                          //           return {
                          //             ...address,
                          //             [name]: value ? Number(value) : "",
                          //           };
                          //         }
                          //         return address;
                          //       }),
                          //     ],
                          //   }));
                          // }}
                          // onBlur={() => onBlurHandler({ low_stock: lctn?.low_stock })}
                          error={filteredErrorObject && filteredErrorObject != {} ? filteredErrorObject?.low_stock === '0' ? 'Value must be greater than 0.' : filteredErrorObject.low_stock : ''}
                        />
                        <LoginInput
                          title={
                            <I_Info_Icon
                              text={selectedLanguage?.name == "English" ? "Threshold Quantity" : translation[955]?.value ? translation[955]?.value : "Threshold Quantity"}
                              info={selectedLanguage?.name == "English" ?
                                "This is the threshold quantity that you can add while placing a purchase order"
                                : translation[967]?.value
                                  ? translation[967]?.value
                                  : "This is the threshold quantity that you can add while placing a purchase order"}
                            />
                          }
                          placeholder={selectedLanguage?.name == "English" ? "Enter quantity" : translation[956]?.value ? translation[956]?.value : "Enter quantity"}
                          type="number"
                          name={"reorder_quantity"}
                          value={lctn?.reorder_quantity}
                          onChange={(e) => { handleStockChange(e, index) }}
                          onlyNumericAllowed={true}
                          // onChange={(e) => {
                          //   const { name, value } = e.target;

                          //   setProducts((prev) => ({
                          //     ...prev,
                          //     location_quantities: [
                          //       ...location_quantities?.map((address, ind) => {
                          //         if (index == ind) {
                          //           return {
                          //             ...address,
                          //             [name]: value ? Number(value) : "",
                          //           };
                          //         }
                          //         return address;
                          //       }),
                          //     ],
                          //   }));
                          // }}
                          // onBlur={() => onBlurHandler({ reorder_quantity: lctn?.reorder_quantity })}
                          error={filteredErrorObject && filteredErrorObject != {} ? filteredErrorObject?.reorder_quantity === '0' ? 'Value must be greater than 0.' : filteredErrorObject.reorder_quantity : ''}
                        />
                      </div>
                    </>
                  );
                })}
                <hr />
                <Dropwdown
                  name={"is_active"}
                  value={is_active}
                  onChange={productHandleChange}
                  error={errors?.is_active}
                  required={true}
                  title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                  placeholder={selectedLanguage?.name == "English" ? "Select status" : translation[483]?.value ? translation[483]?.value : "Select status"}
                  options={[
                    {
                      value: true,
                      label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                    },
                    {
                      value: false,
                      label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                    },
                  ]}
                />
                <div className="flex items-center justify-start ">
                  <LoginBtn
                    disabled={loading}
                    // disabled={
                    //   loading ||
                    //   !location_quantities ||
                    //   location_quantities?.length === 0 ||
                    //   !location_quantities?.every(
                    //     (itm) =>
                    //       itm?.low_stock !== 0 &&
                    //       itm?.reorder_quantity !== 0 &&
                    //       itm?.current_stock !== 0
                    //   )
                    // }
                    loading={loading}
                    text={edit_mode
                      ? selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                      : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                    onClick={() => {
                      onProdcutSubmit(2);
                    }}
                  />
                </div>
              </div>
            )}

            {step == 3 && (
              <>
                <div className="flex flex-col gap-5 text-center mt-[1rem] mb-[2rem]">
                  <div className="h-[150px]">
                    <Svgs.Form_Complete_check />
                  </div>
                  <h1 className="text-primary font-semibold text-lg normal-case">
                    {edit_mode ?
                      selectedLanguage?.name == "English" ? "Product updated successfully" : translation[968]?.value ? translation[968]?.value : "Product updated successfully"
                      :
                      selectedLanguage?.name == "English" ? "Product added successfully" : translation[957]?.value ? translation[957]?.value : "Product added successfully"
                    }
                  </h1>
                  {/* <p className="text-[#7B7B7B] text-sm">
                    {edit_mode ?
                      selectedLanguage?.name == "English"
                        ? "Product has updated been successfully. Want to see"
                        : translation[969]?.value
                          ? translation[969]?.value
                          : "Product has updated been successfully. Want to see"
                      :
                      selectedLanguage?.name == "English"
                        ? "Product has added been successfully. Want to see"
                        : translation[958]?.value
                          ? translation[958]?.value
                          : "Product has added been successfully. Want to see"
                    }
                    <br className="hidden md:block" />
                    {selectedLanguage?.name == "English" ? "your product?" : translation[959]?.value ? translation[959]?.value : "your product?"}
                  </p> */}
                  <button
                    className="border border-primary text-primary w-fit mx-auto text-sm px-5 py-2 rounded-md font-semibold"
                    onClick={() => {
                      onClose && onClose();
                      if (edit_mode) {
                        localStorage.setItem('Get_Products_after_Update', true)
                      }
                      navigate(
                        "/dashboard/account/inventory-operations/products"
                      );
                    }}
                  >
                    {selectedLanguage?.name == "English" ? "View All Products" : translation[960]?.value ? translation[960]?.value : "View All Products"}
                  </button>
                </div>
              </>
            )}
          </div>
        }
      </Popup>
      {/* {
        reduxInvoices && reduxInvoices?.length > 0 ? 
        
       :<Popup
          size={"md"}
          // open={employeePopup}
          onclose={() => {
            onClose && onClose();
          }}
          close={productModalToggler}
          heading={selectedLanguage?.name == "English" ? "Add Invoice to Enable Add Product" : translation[1246]?.value ? translation[1246]?.value : "Add Invoice to Enable Add Product"}
        >
          <h1 className="normal-case">
            {selectedLanguage?.name == "English" ? "Add an invoice, so you can access the add product feature." : translation[1246]?.value ? translation[1246]?.value : "Add an invoice, so you can access the add product feature."}

          </h1>
          <div className="flex justify-end items-center mt-5">
            <LoginBtn
              onClick={() => {
                navigate("/dashboard/account/setup/invoice-translation");
              }}
            >
              {selectedLanguage?.name == "English" ? "Add Invoice" : translation[788]?.value ? translation[788]?.value : "Add Invoice"}
            </LoginBtn>
          </div>
        </Popup>
      */}

    </>
  );
};

const AddProductBtn = (props) => {
  const { setProducts } = useProducts();

  const reduxState = useSelector((state) => state)
  const translation = reduxState?.languages?.language_translation
  const selectedLanguage = reduxState?.languages?.selected_language


  const [product_popup, setProductPopup] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {props.quickSales ? (
        <LoginBtn
          onClick={() => {
            setProductPopup(true);
          }}
          bg={props.fill ? 'bg-primary' : 'bg-white'}
          text={props.title}
        ></LoginBtn>
      ) : (
        <LoginBtn
          onClick={() => {
            setProductPopup(true);
          }}
          borderBtn={!props.fill && true}
          bg={props.fill ? 'bg-primary' : 'bg-white'}
        >
          <div className={`flex items-center gap-2 ${props.fill ? 'text-white' : 'text-primary'}`}>
            <Svgs.Plus color={props.fill ? '#fff' : '#101928'} />
            <p className="font-semibold">
              {selectedLanguage?.name == "English" ? "Add new product" : translation[235]?.value ? translation[235]?.value : "Add new product"}
            </p>
          </div>
        </LoginBtn>
      )}
      {
        product_popup && (
          <>
            <ProductPopup
              loader={false}
              quickSales={props?.quickSales || false}
              successCallback={props?.successCallback}
              onClose={() => {
                setProductPopup(false);
                setProducts({
                  vendor: "",
                  category: "",
                  brand: "",
                  name: "",
                  product_images: "",
                  location: [],
                  product_type: "",
                  cost_price: "",
                  full_price: "",
                  // sell_price: "",
                  short_description: "",
                  description: "",
                  barcode_id: "",
                  sku: "",
                  currency_retail_price: [],
                  consumable_quantity: "",
                  unit: "",
                  amount: "",
                  is_active: "",
                  product_size: "",
                  product_unit: "",
                  sellable_quantity: "",
                  available_quantity: "",
                  location_quantities: [],
                  alert_when_stock_becomes_lowest: true,
                });
                // navigate('/dashboard/account/inventory-operations/products')
              }}
            />
          </>
        )
      }
    </>
  );
};

export default AddProductBtn;
