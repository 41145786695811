import { EMPLOYEETIPREPORT, SALESREPORT, SALESUMMARYREPORT } from "Redux/TenantRedux/ActionTypes/salesReportTypes";

const initialState = {
    productsData: [],
    productsPaginationData: {},
    servicesData: [],
    servicesPaginationData: {},
    membershipData: [],
    membershipPaginationData: {},
    voucherData: [],
    voucherPaginationData: {},
    giftcardData: [],
    giftcardPaginationData: {},
    appointmentSalesData: [],
    appointmentSalesPaginationData: {},
    clientSalesData: [],
    clientSalesPaginationData: {},
    appointmentSummaryData: {},
    productSummaryData: {},
    employeeTipReportData: {},
    taxReportData: [],
    taxReportTableFields: [],
};

const SaleReportReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // product
        case SALESREPORT.GET_PRODUCT_SALES_REPORT:
            return {
                ...state,
                productsData: payload?.response?.data,
                productsPaginationData: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page
                }
            };

        // service
        case SALESREPORT.GET_SERVICE_SALES_REPORT:
            return {
                ...state,
                servicesData: payload?.response?.data,
                servicesPaginationData: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page
                }
            };

        // membership
        case SALESREPORT.GET_MEMBERSHIP_SALES_REPORT:
            return {
                ...state,
                membershipData: payload?.response?.data,
                membershipPaginationData: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page
                }
            };

        // voucher
        case SALESREPORT.GET_VOUCHER_SALES_REPORT:
            return {
                ...state,
                voucherData: payload?.response?.data,
                voucherPaginationData: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page
                }
            };

        // gift card
        case SALESREPORT.GET_GIFTCARD_SALES_REPORT:
            return {
                ...state,
                giftcardData: payload?.response?.data,
                giftcardPaginationData: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page
                }
            };

        // client all sales
        case SALESREPORT.GET_CLIENT_ALL_SALES_REPORT:
            return {
                ...state,
                clientSalesData: payload?.response?.data,
                clientSalesPaginationData: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page
                }
            };

        // appointment all sales
        case SALESREPORT.GET_APPOINTMENT_SALES_REPORT:
            return {
                ...state,
                appointmentSalesData: payload?.response?.data,
                appointmentSalesPaginationData: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page
                }
            };

        // appointment sale summary
        case SALESUMMARYREPORT.GET_APPOINTMENT_SALE_SUMMARY_REPORT:
            return {
                ...state,
                appointmentSummaryData: {
                    teamMember: payload?.response?.employees || [],
                    services: payload?.response?.services || [],
                    service_groups: payload?.response?.service_groups || []
                },
            };

        // product sale summary
        case SALESUMMARYREPORT.GET_PRODUCT_SALE_SUMMARY_REPORT:
            return {
                ...state,
                productSummaryData: {
                    teamMember: payload?.response?.employees || [],
                    brand: payload?.response?.sale_by_brand || [],
                    category: payload?.response?.sale_by_category || []
                },
            };

        // Employee Tip Report
        case EMPLOYEETIPREPORT.GET_EMPLOYEE_TIP_REPORT:
            return {
                ...state,
                employeeTipReportData: payload?.response?.employee_tips || [],
            };

        // Tax Report
        case SALESREPORT.GET_TAX_REPORT:
            return {
                ...state,
                taxReportData: payload?.response?.data || [],
                taxReportTableFields: payload?.response?.overall_tax_summary || [],
            };
        default:
            return state;
    }

}
export default SaleReportReducer