import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { createUserAccount, getTanentData } from "Adapters/Api";
import useSearchParamsHandle from "Hooks/useSearchParamsHandle";
import { VERIFICATION } from "Constants/Variables";
import useObjectKeys from "Hooks/useObjectKeys";
import useValidations from "Hooks/useValidations";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { signupUserPersonalInfo } from "Redux/NstyleRedux/Actions/sigupAction";
import { createBusinessClient, getClientAutoGenerateId } from "Adapters/Api/businessLocationInNstyleApp";
import { CLIENT_SIGNUP } from "Redux/NstyleRedux/ActionTypes/signupTypes";
import { apiErrorMessage } from "Constants/Data/Errors";

let strongPasswordError = "Please enter a strong password.";
const usePersonlInfoForm = (props) => {
  let active = false;
  const formRef = useRef()
  const state = useSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const account_type = searchParams.get("account_type");
  const nexts = searchParams.get("next");
  const info_data = useSelector((state) => state.userData.personalInfo);
  const tenant_data = state?.client_appointments?.common_tenant_data;

  const {
    errors,
    setErrors,
    onFocusHandler,
    onBlurHandler,
    removeWhiteSpacesFromStartAndEnd,
    validation,
    validateMoblieNumber,
    trimEmptySpace,
  } = useValidations();

  const [clientStats, setClientStats] = useState({
    full_name: "",
    email: "",
    mobile_number: "",
    password: "",
    confirm_password: "",
    terms_condition: "",
  });

  const { objectKeyConvertToArray } = useObjectKeys();
  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const hash = getDataFromLocalStorage("hash")
  const bsns_id = getDataFromLocalStorage("business");
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState();
  const [TermConditionPopup, setTermConditionPopup] = useState(false);

  const {
    allParams,
    setDynamicSearchParams,
    getSearchParams,
    searchParamsValue,
  } = useSearchParamsHandle();

  const paramsObject = setDynamicSearchParams(allParams, VERIFICATION);
  const [loading, setLoading] = useState(false);

  //enabled Next here
  if (!Object.values(info_data).includes("") && info_data.terms_condition) {
    active = true;
  }

  // set state personalinfo form state
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      signupUserPersonalInfo({
        ...info_data,
        [name]: value,
      })
    );
    if (e.target) {
      if (name === "username") {
        let result = removeWhiteSpacesFromStartAndEnd(value);
        // remove space from very where
        result = result.replace(/\s+/g, "");
        dispatch(
          signupUserPersonalInfo({
            ...info_data,
            [name]: result,
          })
        );
        return setErrors((prev) => ({ ...prev, [name]: "" }));
      }

      if (name === "business_name") {
        // let result = value.replace(/[^a-zA-Z ]/g, "");
        // result = result.replace(/\s+/g, " ");
        let result = value
        dispatch(
          signupUserPersonalInfo({
            ...info_data,
            [name]: result,
          })
        );
        return setErrors((prev) => ({ ...prev, [name]: "" }));
      }

      if (name === "mobile_number") {
        let result = value
        dispatch(
          signupUserPersonalInfo({
            ...info_data,
            [name]: result,
          })
        );
        if (validateMoblieNumber(value)) {
          return setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          return setErrors((prev) => ({ ...prev, [name]: "Mobile number is invalid" }));
        }
      }

      dispatch(
        signupUserPersonalInfo({
          ...info_data,
          [name]: value,
        })
      );
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }

    if (name === "terms_condition") {
      dispatch(
        signupUserPersonalInfo({
          ...info_data,
          [name]: e.target.checked,
        })
      );
      return setErrors((prev) => ({ ...prev, terms_condition: "" }));
    }

    if (name === "is_subscribed") {
      dispatch(
        signupUserPersonalInfo({
          ...info_data,
          [name]: e.target.checked,
        })
      );
    }
  };

  let count = 0;
  const redirectToDashboard = () => {
    getTanentData(
      { email: info_data?.email },
      (data) => {
        setLoading(false);
        let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN;
        if (typeof window !== 'undefined') {
          hostName = window.location.host;
        }
        const hostArr = hostName.split(".");
        hostArr.unshift(`${data.domain ? data.domain : null}`);
        const result = hostArr.join(".");
        // navigate("/auth/login")
        window.location = `http://${result}/authentication/login/auto_login/?user_login_id=${data.id}&access_token=${data.access_token}&next_path=/dashboard/account/setup/business-settings/additional-information&redirection_source=nstyle_app&redirection_purpose=account_and_mobile_otp_verification`;
      },
      (err) => {
        count = count + 1;
        if (count < 3) {
          setTimeout(() => {
            redirectToDashboard();
          }, 2000);
        }
      }
    );
  };

  // personalInfo form submit and check validation data if data is valid the user create at backend and also store user data in redux
  const handleSubmit = async () => {
    const {
      business_name,
      business_industry,
      first_name,
      last_name,
      email,
      mobile_number,
      password,
      confirm_password,
      country,
      terms_condition,
      state
    } = info_data;
    let requiredFields = { first_name, last_name, email, mobile_number };

    if (info_data?.account_type?.toLowerCase() == "business") {
      requiredFields = {
        ...requiredFields,
        business_name,
        business_industry,
        password,
        confirm_password,
        country
      };
    }
    if (!info_data.social_account) {
      requiredFields = {
        ...requiredFields,
        password,
        confirm_password
      };
    }

    // removed empty spacess from input field value
    const emptySpaceFreeObject = trimEmptySpace(requiredFields);

    setErrors(validation(emptySpaceFreeObject));
    const result = validation(emptySpaceFreeObject);

    if (
      terms_condition &&
      ((!info_data.social_account && password === confirm_password) ||
        info_data.social_account)
    ) {
      if (objectKeyConvertToArray(result)?.length === 0) {
        setLoading(true);
        const response = await createUserAccount(info_data);
        if (response && response?.data?.status_code === 201) {
          if (info_data.social_account) {
            setTimeout(() => {
              redirectToDashboard();
            }, 10000);
            toast.success("Account created successfully", { toastId: "toast" });
            setTimeout(() => {
              toast.info("Setting up your business account, Please wait...", { toastId: "toast" });
            }, 1000);
          } else {
            props.setStep(2);
            setLoading(false);
          }
          dispatch(signupUserPersonalInfo({ account_email: info_data?.email, account_type: info_data?.account_type }));
          storeDataInLocalStorage("currentStep", 2);
          storeDataInLocalStorage("verified", true);

          setSearchParams(paramsObject);
        } else {
          // let errorMessage = response?.response?.data?.response.message
          let strongPassword = response?.response?.data?.response.error_message;
          //mark passwordd feild in case of strong password error
          if (strongPassword == strongPasswordError) {
            setErrors((prev) => ({ ...prev, password: strongPasswordError }));
          }

          setLoading(false);
          toast.error(strongPassword ? strongPassword : response.message, { toastId: "toast" });

          // return exsit fields array from backend
          const taken_fields = response?.response?.data?.response.taken_fields;
          // Remove duplicates
          const uniqueArray = [...new Set(taken_fields)];
          // return data exist fields arrays to object  like ["email", "mobile_number"] to  {email:"", mobile_number:""}
          let taken_fields_obj = Object.assign(
            {},
            ...uniqueArray?.map((item) => ({ [item]: "" }))
          );
          if (uniqueArray?.includes("mobile_number")) {
            setErrors((prev) => ({
              ...prev,
              mobile_number: "Phone number already exist",
            }));
            formRef.current?.scrollIntoView({ behavior: "smooth" });
          }
          if (uniqueArray?.includes("email")) {
            setErrors((prev) => ({
              ...prev,
              email: "Email already exist",
            }));
            formRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      if (password !== confirm_password) {
        setErrors((prev) => ({
          ...prev,
          confirm_password: "Confirm password not match",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          terms_condition: "Please accept Terms and Conditions to continue.",
        }));
      }
    }
  };


  const handleClientDetails = (e) => {
    const { name, value } = e.target;

    if (name === "terms_condition") {
      setClientStats((prev) => ({ ...prev, [name]: e.target.checked }));
      return setErrors((prev) => ({ ...prev, terms_condition: "" }));
    }

    if (name === "is_subscribed") {

      setClientStats((prev) => ({ ...prev, [name]: e.target.checked }));
      return setErrors((prev) => ({ ...prev, [name]: "" }));

    }

    setErrors((prev) => ({ ...prev, [name]: "" }));
    setClientStats((prev) => ({ ...prev, [name]: value }));
  };

  const submitClientDetails = () => {
    const requiredFields = {
      full_name: clientStats?.full_name,
      email: clientStats?.email,
      mobile_number: clientStats?.mobile_number,
      password: clientStats?.password,
      confirm_password: clientStats?.confirm_password,
    }

    setErrors(validation(requiredFields));
    const result = validation(requiredFields);
    if (clientStats?.password === clientStats?.confirm_password) {
      if ((objectKeyConvertToArray(result)?.length === 0) && (clientId)) {
        setLoading(true);

        const formatDate = (nexts != null && nexts != undefined) ?
          { ...clientStats, client_id: clientId, hash, business: bsns_id } :
          { ...clientStats, client_id: clientId, hash: tenant_data?.hash, business: tenant_data?.business };

        dispatch(
          createBusinessClient(
            formatDate,
            (response) => {
              dispatch({
                type: CLIENT_SIGNUP.CLIENT_INFO,
                payload: { ...clientStats, hash, business: bsns_id }
              })
              toast.success(response, { toastId: "toast" });
              props.setStep(2);
            },
            (result) => {
              if (result?.response?.data?.status_code === 400) {
                setLoading(false);
                toast.error(result?.response?.data?.response?.error_message, { toastId: "toast" })
              } else {
                setLoading(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
              }

            }
          )
        )
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        confirm_password: "password does not match"
      }))
    }
  }
  //get client auto generate id
  useEffect(() => {
    if (account_type === "everyone") {
      dispatch(
        getClientAutoGenerateId(
          hash,
          (result) => {
            const res = result?.response?.id;
            let idToString = res.toString();
            let idSrigToArray = idToString.split("-");
            let shortDomian = idSrigToArray[0].split("").filter((i) => {
              if (i != "," && i != "'" && i != "[" && i != "]" && i != " ") { return i }
            }).join("").toUpperCase()
            idSrigToArray.splice(0, 1, shortDomian);
            setClientId(idSrigToArray.join("-"))
          }),
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" })
        },
      )
    }
  }, [])


  return {
    getSearchParams,
    onFocusHandler,
    onBlurHandler,
    handleChange,
    handleSubmit,
    setErrors,
    active,
    errors,
    loading,
    searchParamsValue,
    clientStats,
    setClientStats,
    handleClientDetails,
    submitClientDetails,
    TermConditionPopup,
    setTermConditionPopup,
    account_type,
    formRef,
    validation
  };
};

export default usePersonlInfoForm;
