import Svgs from 'Assets/svgs'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import CircleIcon from '../../../Elements/CircleIcon'
import Dropwdown from '../../../Elements/Dropwdown'
import LoginInput from '../../../Elements/LoginInput'
import PhoneInput from '../../../Elements/PhoneInput'
import Textarea from '../../../Elements/Textarea'
import Layout from '../Layout'
import useBusinessInfo from './helper'
import useAdminBooking from './../helper';
import { LOCATION } from 'Redux/TenantRedux/ActionTypes/locationTypes';
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull';

const BusinessInfo = ({ count, setStepCount, StepCount }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)

    const {
        translation,
        selectedLanguage,
        locationInfo,
        setLocationInfo,
        handleChange,
        locationDetail,
        errors, setErrors,
        onBlurHandler, validation,
        formRef
    } = useAdminBooking()

    useEffect(() => {
        setTimeout(() => {
            setLocationInfo(locationDetail)
            setLoader(false)
        }, 1500)
    }, [locationDetail])

    return loader ? <PreloaderFull /> : (
        <Layout
            // disabled={!locationInfo?.address_name || !locationInfo?.email || !locationInfo?.mobile_number || !locationInfo?.service_avaiable || !locationInfo?.description}
            title={selectedLanguage?.name == "English" ? "Setup your business profile" : translation[81]?.value ? translation[81]?.value : "Setup your business profile"}
            heading={selectedLanguage?.name == "English" ? "Add your business info" : translation[82]?.value ? translation[82]?.value : "Add your business info"}
            subHeading={selectedLanguage?.name == "English" ? "Add key details about your business to display on your online booking profile." : translation[83]?.value ? translation[83]?.value : "Add key details about your business to display on your online booking profile."}
            count={count}
            NextClick={() => {
                const { address_name, email, mobile_number, service_avaiable, description } = locationInfo;
                let requiredFields = {
                    address_name, email, mobile_number, service_avaiable, description
                }
                setErrors(validation(requiredFields));
                const result = validation(requiredFields);
                if (Object.keys(result)?.length === 0) {
                    dispatch({
                        type: "STORE_CURRENT_LOCATION_DATA",
                        payload: locationInfo
                    })
                    setStepCount(StepCount + 1)
                } else {
                    formRef.current?.scrollIntoView({ behavior: "smooth" });
                }
            }}
            backClick={() => {
                setLocationInfo(null)
                dispatch({
                    type: LOCATION.STORE_CURRENT_LOCATION_DATA,
                    payload: null
                });
                dispatch({
                    type: LOCATION.IMAGE_PREVIEW,
                    payload: null,
                })
                navigate("/dashboard/marketplace-profile")
            }}
            btnInner={selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
        >
            <div className='flex flex-col gap-4' ref={formRef}>
                <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
                    <CircleIcon className='!bg-[#5679FF]/20 !rounded-md'>
                        <Svgs.Business_info_outline />
                    </CircleIcon>
                    <div className="flex flex-col gap-1 lg:text-left text-center">
                        <h2 className="text-primary font-semibold text-xl">
                            {locationInfo?.address_name}
                        </h2>
                        <p className="text-[#7B7B7B] text-sm">
                            {locationInfo?.address}
                        </p>
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 gap-4'>
                    <LoginInput
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Location Name" : translation[48]?.value ? translation[48]?.value : "Location Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Location Name" : translation[50]?.value ? translation[50]?.value : "Enter Location Name"}
                        name={"address_name"}
                        // charactersMaxLength={25}
                        onChange={handleChange}
                        onBlur={() => onBlurHandler({ address_name: locationInfo?.address_name })}
                        error={errors?.address_name}
                        value={locationInfo?.address_name}
                    />

                    <Dropwdown
                        name={"service_avaiable"}
                        onChange={handleChange}
                        value={locationInfo?.service_avaiable}
                        options={[
                            { label: selectedLanguage?.name == "English" ? "Everyone" : translation[85]?.value ? translation[85]?.value : "Everyone", value: 'Everyone' },
                            { label: selectedLanguage?.name == "English" ? "Male" : translation[86]?.value ? translation[86]?.value : "Male", value: 'Male' },
                            { label: selectedLanguage?.name == "English" ? "Female" : translation[87]?.value ? translation[87]?.value : "Female", value: 'Female' }
                        ]}
                        onBlur={() => onBlurHandler({ service_avaiable: locationInfo?.service_avaiable })}
                        error={errors?.service_avaiable}
                        title={selectedLanguage?.name == "English" ? "Services Available For" : translation[84]?.value ? translation[84]?.value : "Services Available For"}
                        placeholder={selectedLanguage?.name == "English" ? "Select" : translation[89]?.value ? translation[89]?.value : "Select"}

                    // title={'Services Available For'}
                    // placeholder='Select' 
                    />
                    {/* {console.log(locationInfo, "locationInfo")} */}

                    <PhoneInput
                        required={true}
                        name={"mobile_number"}
                        onChange={handleChange}
                        value={locationInfo?.mobile_number}
                        onBlur={() => onBlurHandler({ mobile_number: locationInfo?.mobile_number })}
                        error={errors?.mobile_number}
                        title={selectedLanguage?.name == "English" ? "Phone Number" : translation[62]?.value ? translation[62]?.value : "Phone Number"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Phone Number" : translation[63]?.value ? translation[63]?.value : "Enter Phone Number"}
                        edit={locationInfo?.mobile_number ? true : false}
                    />
                    <LoginInput
                        required={true}
                        name={"email"}
                        onChange={handleChange}
                        value={locationInfo?.email}
                        onBlur={() => onBlurHandler({ email: locationInfo?.email })}
                        error={errors?.email}
                        title={selectedLanguage?.name == "English" ? "Location Email" : translation[90]?.value ? translation[90]?.value : "Location Email"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Location Email" : translation[91]?.value ? translation[91]?.value : "Enter Location Email"}
                    />
                    <div className='lg:col-span-2'>
                        <Textarea
                            required={true}
                            name={"description"}
                            onChange={handleChange}
                            value={locationInfo?.description}
                            onBlur={() => onBlurHandler({ description: locationInfo?.description })}
                            error={errors?.description}
                            title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Description" : translation[93]?.value ? translation[93]?.value : "Enter Description"}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BusinessInfo