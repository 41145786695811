import React, { useState, useEffect, useRef } from "react";
import Popup from "Components/TenantApp/Elements/Popup";
import Svgs from "Assets/svgs";
import IconButton from "Components/TenantApp/Elements/IconButton";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Textarea from "new-components/text-area";
import RadioBtn from "Components/TenantApp/Elements/RadioBtn";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import moment from 'moment'
import TotalReadingCards from "../../TotalReadingCards";
import ConversionTrackerCard from "../../Employee/Elements/ConversionTrackerCard";
import { Chart, Line } from 'react-chartjs-2';
import useConversion from "./helper";
import AddConversion from "./addConversion";
import DetailConversion from "./DetailConversion";
import { useSelector } from "react-redux";

// Dummy Data For Campaigns
const ConversionTrackerData = [
  {
    customer_name: "Alfreda",
    img: "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png",
    email: "alfreda20@dicki.com",
    phone: "52538134",
    is_active: true,
  },
  {
    customer_name: "Alfreda",
    img: "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png",
    email: "alfreda20@dicki.com",
    phone: "52538134",
    is_active: false,
  },
  {
    customer_name: "Alfreda",
    img: "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png",
    email: "alfreda20@dicki.com",
    phone: "52538134",
    is_active: true,
  },

];



const ConversionTracker = () => {
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const {
    data, options,
    CreateConversion, setCreateConversion,
    ViewDetails, setViewDetails,
  } = useConversion()


  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb first={selectedLanguage?.name == "English" ? "CRM" : translation[1354]?.value ? translation[1354]?.value : "CRM"} url={"/dashboard/crm/"} second={selectedLanguage?.name == "English" ? "Conversion Tracker" : translation[1359]?.value ? translation[1359]?.value : "Conversion Tracker"} />
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Conversion Tracker" : translation[1359]?.value ? translation[1359]?.value : "Conversion Tracker"}
            </h2>
          </div>
          <LoginBtn
            onClick={() => {
              setCreateConversion(!CreateConversion);
            }}
            text={
              <div className="flex items-center gap-3">
                <div>
                  <Svgs.Plus />
                </div>
                <div>
                  <p>{selectedLanguage?.name == "English" ? "Create Conversion" : translation[1381]?.value ? translation[1381]?.value : "Create Conversion"}</p>
                </div>
              </div>
            }
          />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 items-center py-5">

          <ConversionTrackerCard text={selectedLanguage?.name == "English" ? "Overview" : translation[1382]?.value ? translation[1382]?.value : "Overview"}>
            <div className="">
              <div className="text-[1.1rem] pb-2">
                <span className="text-[1.7rem] font-bold">100,000 </span>
                / 50,000
              </div>
              <div className="border border-[#ECECED] bg-[#fff] h-[10px] w-full rounded-md">
                <div className="h-full w-[40%] bg-[#5679FF] rounded-md"></div>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              <span className="text-[1.1rem] text-[#45C61A] font-semibold flex items-center">
                <Svgs.UpArrow fill='#45C61A' />
                18%
              </span>
              <span>{selectedLanguage?.name == "English" ? "last month" : translation[1383]?.value ? translation[1383]?.value : "last month"}</span>
            </div>
          </ConversionTrackerCard>
          <ConversionTrackerCard text={selectedLanguage?.name == "English" ? "New Leads" : translation[1385]?.value ? translation[1385]?.value : "New Leads"}>
            <div className="flex items-center">
              <div className="text-[1.7rem] font-bold pr-2">127 </div>
              <div className="flex gap-3 items-center">
                <span className="text-[1.1rem] text-[#45C61A] font-semibold flex items-center">
                  <Svgs.UpArrow fill='#45C61A' />
                  25%
                </span>
                <span>{selectedLanguage?.name == "English" ? "last month" : translation[1383]?.value ? translation[1383]?.value : "last month"}</span>
              </div>
            </div>
            <div className=" w-[150px] sm:w-full sm:h-full overflow-hidden">
              <Line data={data} options={options} />
            </div>
          </ConversionTrackerCard>
          <ConversionTrackerCard text={selectedLanguage?.name == "English" ? "Conversion Rate" : translation[1384]?.value ? translation[1384]?.value : "Conversion Rate"}>
            <div className="flex items-center">
              <div className="text-[1.7rem] font-bold pr-2">5.6% </div>
              <span>{selectedLanguage?.name == "English" ? "last month" : translation[1383]?.value ? translation[1383]?.value : "last month"}</span>
            </div>
            <div className="w-[150px] sm:w-full sm:h-full overflow-hidden">
              <Line data={data} options={options} />
            </div>
          </ConversionTrackerCard>
        </div>
        <div className="">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Conversion Tracker" : translation[1359]?.value ? translation[1359]?.value : "Conversion Tracker"}</h2>
                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                      <p className="text-primary">5 {selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"} </p>
                    </div>
                  </div>
                </div>
                <div className="mb-00">
                  <LoginInput
                    title=""
                    name={"search_text"}
                    placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                </div>
              </div>
              <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                <LoginBtn
                  borderBtn
                //   onClick={() => {
                //     setFilterSection(!FilterSection);
                //   }}
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Filters />
                    <p>{selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}</p>
                  </div>
                </LoginBtn>
                <IconButton>
                  <Svgs.Import />
                </IconButton>
                <IconButton filled>
                  <Svgs.Export />
                </IconButton>
              </div>
            </div>

            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(4,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Client Name" : translation[1159]?.value ? translation[1159]?.value : "Client Name"}</p>
                <p>{selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"} </p>
                <p>{selectedLanguage?.name == "English" ? "Assign Sement" : translation[1365]?.value ? translation[1365]?.value : "Assign Sement"}</p>
                <p>{selectedLanguage?.name == "English" ? "Lead" : translation[1386]?.value ? translation[1386]?.value : "Lead"}</p>

              </div>
              <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
              >
                {/* --------- Conversion Trackers ---------- */}
                {ConversionTrackerData?.map((v, i) => {

                  return (
                    <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(115px,1fr))] min-w-full w-fit">
                      <div
                        onClick={() => setViewDetails(!ViewDetails)}
                        className="cursor-pointer h-full flex items-center gap-3">
                        <div>
                          <div className="h-[2rem] w-[2rem] rounded-full"><img className="w-full h-full" src={v.img} alt="img" /></div>
                        </div>
                        <p className="text-sm text-primary">{v.customer_name}</p>
                        {ConversionTrackerData && <DetailConversion v={v} ViewDetails={ViewDetails} setViewDetails={setViewDetails} />}
                      </div>
                      <div className="flex items-center text-sm">
                        {v.email}
                      </div>

                      <div className="flex items-center">
                        <p className="text-sm">{v.phone}</p>
                      </div>
                      <div className="flex items-center">
                        <StatusBtn
                          status={v.is_active ? "Active" : "Inactive"}
                          text={v.is_active ? "Win" : "Loss"}
                        />
                      </div>

                    </div>
                  );
                })}
                {/* -------------- */}
              </div>
            </div>
          </div>
        </div>
        {CreateConversion && (
          <AddConversion CreateConversion={CreateConversion} setCreateConversion={setCreateConversion} />
        )
        }
      </div>
    </>
  )
}

export default ConversionTracker