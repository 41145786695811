import React from "react";
import PreloaderSm from "./PreloaderSm";

const LoginBtn = ({
  title,
  type,
  onClick,
  disabled,
  animation,
  padding,
  text,
  children,
  borderBtn,
  bg,
  loading,
  deleteBtn,
  newDeleteBtn,
  newDeleteBtnFocus,
  successBtn,
  newSuccessBtn,
  newSuccessBtnFocus,
  customThemeBtn,
  btnCustomHight,
  customBtnWidth,
  className,
}) => {

  const handleClick = (e) => {
    if (!loading && !disabled) {
      onClick(e)
    }
  }
  return (
    <button
      title={title}
      type={type}
      onClick={handleClick}
      disabled={disabled || loading}
      className={` ${customThemeBtn ? customThemeBtn : 'theme-btn'} ${className} ${deleteBtn && "deleteBtn"} ${newDeleteBtn && "newDeleteBtn"} ${newDeleteBtnFocus && "newDeleteBtnFocus"} ${successBtn && 'successBtn'} ${newSuccessBtn && 'newSuccessBtn'} ${newSuccessBtnFocus && 'newSuccessBtnFocus'} ${borderBtn && "borderBtn"} ${disabled && "cursor-not-allowed"} ${btnCustomHight ? btnCustomHight : ''} ${customBtnWidth ? customBtnWidth : ''}`}>
      {loading ? (
        <>
          <PreloaderSm />
        </>
      ) : (
        <>
          {text ? text : ""}
          {children}
        </>
      )}
    </button>
  );
};




LoginBtn.defaultProps = {
  animation: true,
  onClick: () => { },
  handleSubmit: () => { },
  borderBtn: false,
  deleteBtn: false,
  successBtn: false,
  newDeleteBtn: false,
  newSuccessBtn: false,
  newSuccessBtnFocus: false,
  newDeleteBtnFocus: false,
};

export default LoginBtn;