import React from 'react'
import { useNavigate } from 'react-router-dom'

const Breadcrumb = ({ url, first, second, secondurl, third, loading }) => {
    const navigate = useNavigate();

    if (loading) {
        return (
            <div className="w-60 h-6 bg-gray-300 rounded animate-pulse"></div>
        );
    }

    return (
        <p className="text-[#7B7B7B]">
            <span
                className="cursor-pointer"
                onClick={() => navigate(url)}
            >
                {first}
            </span>{" "}
            &gt;{" "}
            <span
                onClick={() => {
                    if (secondurl) navigate(secondurl);
                }}
                className={`${!secondurl && 'text-primary font-semibold'} cursor-pointer`}
            >
                {second}
            </span>
            {third && (
                <>
                    {" "}
                    &gt;{" "}
                    <span className="text-primary font-semibold cursor-pointer">
                        {third}
                    </span>
                </>
            )}
        </p>
    );
};

export default Breadcrumb;
