import React from 'react'
import { useVoucherSaleReport } from './helper'
import Shimmer from 'Components/Utility/Shimmers/Shimmer'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Svgs from 'Assets/svgs'
import ReactDateRangePicker from 'Components/TenantApp/Elements/ReactDateRangePicker'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import TableShimer from 'Components/Utility/Shimmers/TableShimer'
import Pagination from 'new-components/Pagination'
import OpenDetailInvoice from '../../Elements/OpenInvoice'
import VoucherTable from '../../Elements/Tables/VoucherTable'
import DetailPopup from '../../Elements/DetailPopup'
// import SaleReportFilter from '../../Elements/Filter'

const VoucherSaleReport = ({ clientData }) => {
    const { setSearchText, currentPage, setCurrentPage, search_text, loading, translation, selectedLanguage, employee, PageSize,
        startDate, setStartDate, endDate, setEndDate, handleApplyRemoveFilter, showFilter, setShowFilter, paginationData,
        loadingPermission, voucherData, InvoicePopup, setInvoicePopup, location_currency, filterData, csvLoading,
        handleExportCSVData, voucherDetail, setVoucherDetail
    } = useVoucherSaleReport(clientData)
    return (
        <div className="rounded-[10px] bg-white cardShadow">
            <div className="px-4 lg:px-6 py-[21px] flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                <div className="flex md:flex-row flex-col md:items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                    <div className="flex items-center sm:gap-4 gap-2 ">
                        <h2 className="font-semibold capitalize text-xl">
                            {selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers"}
                        </h2>

                        {loading ?
                            <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                <p className="rounded-md h-3 w-12 mx-1"> </p>
                            </div>
                            :
                            <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                <p className="text-primary">
                                    {paginationData?.allCount ? paginationData?.allCount : 0} {" "}
                                    {paginationData?.allCount && paginationData?.allCount > 1 ?
                                        selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers"
                                        : selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"
                                    }
                                </p>
                            </div>
                        }
                    </div>
                    <div className="flex gap-2 items-center flex-wrap justify-end">
                        {loadingPermission ?
                            <Shimmer className={'w-40 lg:w-44'}>
                                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                            </Shimmer>
                            :
                            <LoginInput
                                title=""
                                placeholder={"Search Invoice"}
                                leftIcon={<Svgs.Search />}
                                padding="py-1"
                                value={search_text}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        }
                        <ReactDateRangePicker
                            className="!max-w-none"
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                        {/* <LoginBtn
                            customThemeBtn={'theme-btn2'}
                            onClick={() => {
                                setShowFilter(!showFilter);
                            }}
                        >
                            <div className="flex items-center gap-2 text-primary font-semibold">
                                <Svgs.Filters />
                                <p>
                                    {selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                                </p>
                            </div>
                        </LoginBtn> */}
                        {!employee ? (
                            <LoginBtn
                                className="rounded-lg !py-[7px]"
                                bg="bg-white"
                                animation={false}
                                disabled={csvLoading}
                                onClick={handleExportCSVData}
                                customThemeBtn={'theme-btn2'}
                            >
                                <div className="flex items-center gap-2 text-[#FFFFFF]">
                                    {csvLoading ? (
                                        <div className="w-5 h-5 border-2 border-black border-t-transparent rounded-full animate-spin"></div>
                                    ) : (
                                        <Svgs.ExportSvg />
                                    )}
                                </div>
                            </LoginBtn>

                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            {loading ?
                <TableShimer cols={7} rows={10} />
                :
                <VoucherTable
                    data={voucherData}
                    setCheckoutId={setInvoicePopup}
                    setVoucherDetail={setVoucherDetail}
                    location_currency={location_currency}
                    isFromClient={clientData?.id}
                />
            }
            <div className="pb-0.5">
                {(paginationData?.pages > 0 && !loading) &&
                    <Pagination
                        currentPage={currentPage}
                        totalCount={Math.ceil(paginationData?.pages)}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                }
            </div>

            <OpenDetailInvoice
                setCheckoutId={setInvoicePopup}
                checkoutId={InvoicePopup}
            />

            <DetailPopup
                open={voucherDetail}
                close={setVoucherDetail}
                setCheckoutId={setInvoicePopup}
                location_currency={location_currency}
            />

            {/* {showFilter &&
                <SaleReportFilter
                    FilterPopup={showFilter}
                    setFilterPopup={setShowFilter}
                    applyRemoveFilter={handleApplyRemoveFilter}
                    type={"product"}
                    appliedFilterData={filterData}
                />
            } */}
        </div>
    )
}

export default VoucherSaleReport
