

export const APPOINTMENT_TIPS = [
    { tip: 0 },
    { tip: 10 },
    { tip: 20 },
    { tip: 25 },
    { tip: 35 },
    { tip: 45 },
]

export const APPOINTMENT_STATUSES = [
    { label: 'Appointment Booked', value: 'Appointment_Booked' },
    { label: 'Arrived', value: 'Arrived' },
    { label: 'Start', value: 'Started' },
    { label: 'Finish', value: 'Finished' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Done', value: 'Done' },
    { label: 'Cancel', value: 'Cancel' },
]

export const ValidityTime = [
    { label: '1 Day', value: '1 Day' },
    { label: '7 Days', value: '7 Days' },
    { label: '14 Days', value: '14 Days' },
    { label: '1 Month', value: '1 Months' },
    { label: '2 Months', value: '2 Months' },
    { label: '3 Months', value: '3 Months' },
    { label: '4 Months', value: '4 Months' },
    { label: '6 Months', value: '6 Months' },
    { label: '8 Months', value: '8 Months' },
    { label: '1 Years', value: '1 Years' },
    { label: '18 Months', value: '18 Months' },
    { label: '2 Years', value: '2 Years' },
    { label: '5 Years', value: '5 Years' },
]