import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "new-components/location/helper";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Svgs from "Assets/svgs";

const MembershipCard = ({
  onClick,
  data,
  view_details,
  index,
  currency
}) => {
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const { locationDetail } = useLocation();
  const LocationCurrency = locationDetail?.currency?.id;


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      <div className={`border justify-between relative rounded-[0.625rem]`}>
        <div className="flex items-center gap-4 p-4">

          <div className="absolute top-2 right-2">
            <div className="cursor-pointer" onClick={() => view_details()}>
              <Svgs.INFO />
            </div>
          </div>

          <div>
            <div className="h-[6rem] w-[6rem] overflow-hidden flex items-center justify-center rounded-[0.625rem]">
              <Svgs.MembershipIcon />
            </div>
          </div>

          <div className="pt-2">
            <h1 className="font-semibold whitespace-normal line-clamp-2 overflow-ellipsis">{data?.name}</h1>
            <div className="">
              <p className="text-[#5679ff] text-xs font-semibold">
                {data?.currency_membership?.find((itm) =>
                  itm?.currency === LocationCurrency)?.price ?
                  <>
                    {data?.currency_membership?.find((itm) =>
                      itm?.currency === LocationCurrency)?.price} {data?.currency_membership?.find((itm) => itm?.currency === LocationCurrency) ? locationDetail?.currency?.code : "AED"}
                  </>
                  : ""
                }
              </p>
              <p className="text-[#555555] text-xs">
                {selectedLanguage?.name == "English" ? "Including Tax" : translation[303]?.value ? translation[303]?.value : "Including Tax"} <span class="text-red-600"> *</span>
              </p>
            </div>
          </div>
        </div>

        <div className="border-t-2 border-[#CFCFDC] border-dashed px-4 py-2.5 flex items-center justify-between">
          <div>
            <p className="text-[#555555] text-xs">
              {selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}: {" "}
              <span className="text-[#5679ff]">
                {data?.validity} {" "} {data?.valid_for}
              </span>
            </p>
          </div>
          {(!employee || (employee && employeePermissions?.sales_apply_offer?.includes("create")) || employeePermissions?.sales_apply_offer?.includes("edit")) ? (
            <LoginBtn
              className={'ml-auto !py-2'}
              onClick={() => {

                onClick()
              }}
              text={'Add to Cart'}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

MembershipCard.defaultProps = {
  data: {},
  index: undefined,
};

export default MembershipCard;
