import React from "react";
import LoginBtn from "../../../Elements/LoginBtn";
import ProgressBar from "../../../Elements/ProgressBar";
import usePOSAnalytics from "./helper";
import Popup from "Components/TenantApp/Elements/Popup";
import Svgs from "Assets/svgs";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import SalesAnalyticsCards from "Components/TenantApp/Elements/SalesAnalyticsCards";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LineChatCurve from "Components/TenantApp/Elements/Charts/LineChatCurve";
import LineChart from "Components/TenantApp/Elements/Charts/LineChart";
import Donet from "Components/TenantApp/Elements/Charts/Donet";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LocationSelection from "new-components/location";
import { YearsDropdown } from "Constants/Data/Months";

const SalesAnalytics = () => {

    const { translation, selectedLanguage, salesData, loader, onChangeHandler, Filter, setFilter, filterData, MonthsDropdown,
        location_currency, onDateRangeHandler, minEndDateLimit, dateRange, resetFilters, ApplyFilter
    } = usePOSAnalytics();
    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

            <div className="my-[1rem] flex items-end justify-between gap-4 flex-wrap">
                <div className="flex flex-col items-start gap-2 md:gap-4">
                    <Breadcrumb
                        first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                        second={selectedLanguage?.name == "English" ? "Sales Analytics" : translation[1586]?.value ? translation[1586]?.value : "Sales Analytics"}
                        url={'/dashboard/pos-analytics'}
                    />
                    <div className="flex items-center justify-between gap-4 flex-wrap">
                        <div className="flex items-center sm:gap-4 gap-2">

                            <h2 className="text-primary font-bold lg:text-3xl text-xl">
                                {selectedLanguage?.name == "English" ? "Sales Analytics" : translation[1586]?.value ? translation[1586]?.value : "Sales Analytics"}
                            </h2>
                            <LocationSelection />
                        </div>

                    </div>
                </div>
                <div className="flex items-center sm:gap-4 gap-2">
                    <Dropwdown
                        className={'w-[7rem]'}
                        name="type"
                        title=""
                        showTitle={false}
                        placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                        options={[
                            { value: 'today', label: 'Today' },
                            { value: 'month', label: 'Monthly' },
                            { value: 'year', label: 'Yearly' },
                        ]}
                        value={filterData?.type}
                        onChange={onChangeHandler}
                    />
                    {filterData?.type != 'today'
                        ? <>
                            <Dropwdown
                                placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                                className={'w-[7rem]'}
                                name="year"
                                title=""
                                showTitle={false}
                                options={YearsDropdown}
                                onChange={onChangeHandler}
                                value={filterData?.year}
                            />
                            {filterData?.type == 'month' &&
                                <Dropwdown
                                    placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                                    className={'w-[7rem]'}
                                    name="month"
                                    title=""
                                    showTitle={false}
                                    options={MonthsDropdown}
                                    onChange={onChangeHandler}
                                    value={filterData?.month}
                                />
                            }
                        </>
                        : ''}
                </div>
            </div>

            <div className="border-t py-5 md:py-10 flex flex-col gap-5 md:gap-10">
                <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-5 md:gap-10">
                    {loader
                        ? [1, 2, 3, 4, 5]?.map(itm => { return <SalesAnalyticsCards animate /> })
                        : <>
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={(salesData?.sales_cards?.avg_sale)?.toFixed(2)}
                                subvalueType={"positive"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Average Sales" : translation[1590]?.value ? translation[1590]?.value : "Average Sales"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={(salesData?.sales_cards?.appointment_average)?.toFixed(2)}
                                subvalueType={"positive"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.sales_cards?.total_refund_amount?.toFixed(2)}
                                subvalueType={"positive"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Refund Amount" : translation[1727]?.value ? translation[1727]?.value : "Refund Amount"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.sales_cards?.total_client_sale?.toFixed(2)}
                                subvalueType={"positive"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Total Client Sales" : translation[1740]?.value ? translation[1740]?.value : "Total Client Sales"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.sales_cards?.total_welkin_sale?.toFixed(2)}
                                subvalueType={"positive"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Total Walk In Sales" : translation[1741]?.value ? translation[1741]?.value : "Total Walk In Sales"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.tips?.tip_amount_total?.toFixed(2)}
                                subvalueType={"positive"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Total Tip Amount" : translation[1592]?.value ? translation[1592]?.value : "Total Tip Amount"}
                                graph={<Svgs.NewGraphUp />}
                            />
                        </>
                    }
                </div>
                <div className="grid sm:grid-cols-2 xl:grid-cols-6 gap-5 md:gap-10">
                    <div className="flex flex-col gap-5 sm:gap-0 bg-white xl:gap-10 h-auto xl:col-span-2 py-3 xl:py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex flex-col gap-1">
                            <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}</h4>
                            <h5 className="text-[#484848] font-bold text-2xl">{(salesData?.sales_progress?.total_sale)?.toFixed(2)} {location_currency}</h5>
                        </div>
                        <div className="flex flex-col gap-3 xl:gap-5 py-3 xl:py-5">
                            {loader
                                ? [0, 1, 2, 3, 4]?.map(itm => { return <ProgressBar xl={true} animate /> })
                                : <>
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={(salesData?.sales_progress?.service?.service_total_sale !== 0 || salesData?.sales_progress?.service?.total_service_target !== 0) ? ((salesData?.sales_progress?.service?.service_total_sale * 100) / salesData?.sales_progress?.service?.total_service_target > 100) ? 100 : ((salesData?.sales_progress?.service?.service_total_sale * 100) / salesData?.sales_progress?.service?.total_service_target) : 0}
                                        heading={"Services"}
                                        value={`${salesData?.sales_progress?.service?.service_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={(salesData?.sales_progress?.product?.total_retail_target !== 0 || salesData?.sales_progress?.product?.product_total_sale !== 0) ? ((salesData?.sales_progress?.product?.product_total_sale * 100) / salesData?.sales_progress?.product?.total_retail_target > 100) ? 100 : (salesData?.sales_progress?.product?.product_total_sale * 100) / salesData?.sales_progress?.product?.total_retail_target : 0}
                                        heading={"Products"}
                                        value={`${salesData?.sales_progress?.product?.product_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={`${(salesData?.sales_progress?.vouchers_total_sale) > 0 ? '100' : '0'}`}
                                        heading={"Vouchers"}
                                        value={`${salesData?.sales_progress?.vouchers_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={`${(salesData?.sales_progress?.membership_total_sale) > 0 ? '100' : '0'}`}
                                        heading={"Membership"}
                                        value={`${salesData?.sales_progress?.membership_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={`${(salesData?.sales_progress?.gift_card_total_sale) > 0 ? '100' : '0'}`}
                                        heading={"Gift Card"}
                                        value={`${salesData?.sales_progress?.gift_card_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 xl:gap-10 bg-white justify-between h-auto xl:col-span-4 py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center">
                            <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}</h4>
                            <div className="flex items-center gap-4">
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#587AFE]"></div>
                                    <p className="text-sm text-[#1C1C1C]">Current Year</p>
                                </div>
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#556E94]"></div>
                                    <p className="text-sm text-[#1C1C1C]">Previous Year</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <LineChatCurve fill={true} chartData1={salesData?.total_sale_chart?.current_year_sales} chartData2={salesData?.total_sale_chart?.previous_year_sales} chartData1Color={'#5679FF'} chartData2Color={'#556E94'} />
                        </div>
                    </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-5 md:gap-10">
                    <div className="flex flex-col gap-10 bg-white h-auto 3xl:h-[25rem] py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Sales channel" : translation[1588]?.value ? translation[1588]?.value : "Sales channel "} POS</h4>
                                <h5 className="text-[#484848] font-bold text-2xl">{(salesData?.sales_channel_pos?.total_pos_sale)?.toFixed(2)} {location_currency}</h5>
                            </div>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <LineChart chartData={salesData?.sales_channel_pos?.current_year_pos_sale} />
                        </div>
                    </div>
                    <div className="min-h-[20rem] py-5 px-6 bg-white border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5 flex flex-col justify-between gap-2">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}</h4>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <div className=" xl:h-[170px] xl:w-[170px] lg:h-[130px] lg:w-[130px] overflow-hidden">
                                <Donet appointment_progress={salesData?.appointment_progress}
                                    chartData={[salesData?.appointment_progress?.finished_appointment > 0 ? salesData?.appointment_progress?.finished_appointment : (salesData?.appointment_progress?.cancel_appointment > 0 ? 0 : 1), salesData?.appointment_progress?.cancel_appointment > 0 ? (salesData?.appointment_progress?.cancel_appointment) : (salesData?.appointment_progress?.finished_appointment > 0 ? 0 : 1)]}
                                    chartDataColors={['#3856C9', '#AFCDEE']}
                                    circleCutout={'80%'} />
                            </div>
                        </div>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center gap-1 text-xl font-medium"><span className="w-8 h-4 rounded-full bg-[#3856C9]"></span><span>{(salesData?.appointment_progress?.finished_appointment != 0 && salesData?.appointment_progress?.total_appointment != 0) ? (((salesData?.appointment_progress?.finished_appointment * 100) / salesData?.appointment_progress?.total_appointment)?.toFixed(2)) : 0}%</span><span>{selectedLanguage?.name == "English" ? "Completed" : translation[322]?.value ? translation[322]?.value : "Completed"}</span></div>
                            <div className="flex items-center gap-1 text-xl font-medium"><span className="w-8 h-4 rounded-full bg-[#AFCDEE]"></span><span>{(salesData?.appointment_progress?.cancel_appointment != 0 && salesData?.appointment_progress?.total_appointment != 0) ? (((salesData?.appointment_progress?.cancel_appointment * 100) / salesData?.appointment_progress?.total_appointment)?.toFixed(2)) : 0}%</span><span>{selectedLanguage?.name == "English" ? "Cancelled" : translation[1589]?.value ? translation[1589]?.value : "Cancelled"}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <Popup
                // size={"lg"}
                heading={selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                open={Filter}
                close={setFilter}
                customPadding={"!p-0"}
            >
                <div className="flex flex-col w-full">
                    <div className="flex flex-col gap-2 py-4 px-6">
                        <div className='flex flex-col gap-4'>
                            <div className='grid grid-cols-2 gap-3'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                    name="start_date"
                                    value={dateRange?.start_date}
                                    type="date"
                                    onChange={onDateRangeHandler}
                                />
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                    name="end_date"
                                    value={dateRange?.end_date}
                                    type="date"
                                    onChange={onDateRangeHandler}
                                    min={minEndDateLimit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-end gap-2 py-4 px-6">
                        <LoginBtn onClick={resetFilters} className="!px-6" borderBtn text={selectedLanguage?.name == "English" ? "RESET" : translation[1141]?.value ? translation[1141]?.value : "RESET"} />
                        <LoginBtn onClick={ApplyFilter} text={selectedLanguage?.name == "English" ? "APPLY FILTER" : translation[595]?.value ? translation[595]?.value : "APPLY FILTER"} />
                    </div>
                </div>

            </Popup>
        </div>
    )
}

export default SalesAnalytics