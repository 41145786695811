import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { checkClientExistance, createClient, deleteClient, getClient, getClientDropDownListPagination, getClientWithNoPagination, updateClient, uploadSingleImage } from '../../../../../Adapters/Api/clients';
import useAddress from '../../../../../Hooks/useAddress';
import useObjectKeys from '../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from '../../../../../Hooks/useSetDataInLocalStorage';
import useValidations from '../../../../../Hooks/useValidations';
import { addClient, editClient, removeClient } from '../../../../../Redux/TenantRedux/Actions/clientActions';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCommonHooks from '../../../../../Hooks/useCommonHooks';
import { apiErrorMessage } from 'Constants/Data/Errors';

function useAddClient(getClients, onSuccess) {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const { setErrors, validation, errors, onBlurHandler, validateMoblieNumber, validateEmail } = useValidations();
    const { checkMobileNumber } = useCommonHooks();
    const [searchParams, setSearchParams] = useSearchParams();
    const from_url = searchParams?.get("from");
    const clientId = searchParams?.get("client_id");
    const reduxState = useSelector((state) => state);
    const translation = reduxState?.languages?.language_translation
    const selectedLanguage = reduxState?.languages?.selected_language


    const { objectKeyConvertToArray } = useObjectKeys();
    const formRef = useRef()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const [languageList, setLanguageList] = useState();
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [finished, setFinished] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [img, setImage] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(true)
    const [search_text, setSearchText] = useState("");
    const [noPaginationLoader, setNoPagiantionLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownStatus, setDropdownStatus] = useState('')
    const [allImages, setAllImages] = useState([])
    const [clientsData, setClientsData] = useState({
        image: "",
        client_id: "",
        full_name: "",
        email: "",
        gender: "Male",
        country: "",
        state: "",
        city: "",
        address: "",
        is_active: true,
        mobile_number: "",
        dob: "",
        postal_code: "",
        language: "",
        customer_note: "",
        about_us: "",
        card_number: "", marketing: "opt_in",
        allow_loyalty: false
    });

    const [countryId, setCountryId] = useState()
    const [stateId, setStateId] = useState();
    const { countriesList, stateList, setStateList } = useAddress(countryId);


    // ******************* on change handler *******************
    const clientHandleChange = (e, fieldName = "") => {

        if (e?.target) {
            const { name, value } = e.target;
            if (name == "customer_note") {
                setClientsData((prev) => ({ ...prev, customer_note: value }));
                setErrors((prev) => ({ ...prev, customer_note: "" }));
            }
            if (name == "allow_loyalty") {
                const { name, checked } = e.target;
                setClientsData((prev) => ({ ...prev, [name]: checked }))
                setErrors((prev) => ({ ...prev, [name]: "" }))
            }

            if (name == "mobile_number") {
                const { name, value } = e.target;
                const isValidNumber = validateMoblieNumber(value);
                setClientsData((prev) => ({ ...prev, [name]: value }))
                if (isValidNumber)
                    return setErrors((prev) => ({ ...prev, [name]: "" }))
                else
                    return setErrors((prev) => ({ ...prev, [name]: "Mobile number is invalid" }))
            }

            if (name != "customer_note" && name != "allow_loyalty") {
                setClientsData((prev) => ({ ...prev, [name]: value }));
                setErrors((prev) => ({ ...prev, [name]: "" }))
                name == "country" && setCountryId(value)
                name == "state" && setStateId(value)
            }

        }

        if (fieldName && fieldName === "image") {
            setImage(URL.createObjectURL(e.target.files[0]));
            setClientsData((prev) => ({ ...prev, image: e.target.files[0] }));
            setErrors((prev) => ({ ...prev, image: "" }))
        }
    }

    // handle image change
    const handleImageChange = (e) => {
        let imageData = {}
        const file = e.target.files[0]
        const fileType = e.target.files[0]?.type;
        const fileFormat = fileType?.split("/")[1];
        const url = URL.createObjectURL(file)
        const fileName = e.target.files[0]?.name

        imageData = {
            fileFormat: fileFormat,
            image: file,
            file_name: fileName,
            file: file,
            file_type: fileType
        }

        let success = (result) => {
            imageData = {}
            setAllImages([...allImages, { ...result }])
        }
        let fail = () => {
        }
        dispatch(uploadSingleImage(imageData, access_token, success, fail))
    }

    // handle delete image
    const handleDeleteImage = (id) => {
        const filteredImages = allImages?.filter(itm => itm?.id != id)
        setAllImages(filteredImages)
    }



    // ******************* client submit *******************
    const onClientSubmit = async () => {
        const { client_id, full_name, email, gender, mobile_number, language } = clientsData;

        let requiredFields = { client_id, full_name, gender, mobile_number, language };

        if (mobile_number) {
            const isPhoneNumber = checkMobileNumber(mobile_number);
            if (isPhoneNumber) {
                delete clientsData.mobile_number;
            } else {
                setLoading(false)
                requiredFields = { ...requiredFields, mobile_number }
            }
        }
        if (email) {
            requiredFields = { ...requiredFields, email }
            // validateEmail(email)
        }

        // const isValidNumber = mobile_number && validateMoblieNumber(mobile_number);
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if ((objectKeyConvertToArray(result)?.length === 0)) {
            // if ((objectKeyConvertToArray(result)?.length === 0) && ((mobile_number && isValidNumber) || !mobile_number)) {
            setLoading(true)

            let form_data = new FormData();

            for (let key in clientsData) {
                if (clientsData[key]) {
                    if (key == 'image' && typeof clientsData[key] == 'string') {
                        continue
                    }
                    form_data.append(key, clientsData[key])
                }
            }
            if (allImages?.length > 0) {
                form_data.append('image_ids', `[${allImages?.map(itm => itm?.id)}]`)
            } else {
                form_data.append('image_ids', `[]`)
            }

            //check here if isEdit true hit update client api then hit create client api in else part
            if (isEdit) {
                updateClient(form_data, access_token).then((result) => {
                    if (result.status_code == 200) {
                        dispatch(editClient(result?.response?.client));
                        if (!isToastVisible) {
                            toast.success(result?.response?.message, {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                        setClientsData({
                            image: "", client_id: "", full_name: "", email: "",
                            gender: "", country: "", state: "", city: "",
                            address: "", is_active: true, mobile_number: "", dob: "", about_us: "",
                            postal_code: "", card_number: "", customer_note: "", language: "", marketing: "opt_in", allow_loyalty: false
                        });
                        setImage(undefined)
                        setLoading(false)
                        setCurrentPage(1)
                        dispatch(getClient(1, search_text, setLoader, dropdownStatus))
                        dispatch(getClientWithNoPagination(search_text, setNoPagiantionLoader, dropdownStatus))
                        navigate('/dashboard/clients/client-profiles/')
                        getClients && getClients()
                    }
                }).catch((error) => {
                    setLoading(false)
                    if (!isToastVisible) {
                        toast.error(apiErrorMessage, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                })

            } else {
                form_data.append('business', businessData.id)
                createClient(form_data, access_token).then((result) => {
                    if (result?.status_code == 201) {
                        getClients && getClients(1, '')
                        onSuccess && onSuccess(result?.response?.client)
                        // Cliend Dropdown data
                        // dispatch(addClient(result?.response?.client));
                        setClientsData({
                            image: "", client_id: "", full_name: "", email: "",
                            gender: "", country: "", state: "", city: "",
                            address: "", is_active: true, mobile_number: "", dob: "", about_us: "",
                            postal_code: "", card_number: "", customer_note: "", language: "", marketing: "opt_in", allow_loyalty: false
                        });

                        if (from_url === "appointment") {
                            dispatch({
                                type: "CREATED_CLIENT_FROM_APPOINTMENT",
                                payload: result?.response?.client,
                            })
                            // navigate(-1)
                        }
                        if (localStorage.getItem('add_client_from_sales')) {
                            dispatch(getClient())
                            if (!isToastVisible) {
                                toast.success(result?.response?.message, {
                                    onClose: () => setIsToastVisible(false),
                                });
                                setIsToastVisible(true);
                            }
                            localStorage.removeItem('add_client_from_sales')
                        } else {
                            setCurrentPage(1)
                            dispatch(getClient(1, search_text, setLoader, dropdownStatus))
                            dispatch(getClientWithNoPagination(search_text, setNoPagiantionLoader, dropdownStatus))
                        }
                        setImage(undefined)
                        setFinished(true)
                        setLoading(false)
                        getClients && getClients()
                    }
                    if (result?.status_code == 404) {
                        setLoading(false)
                        if (!isToastVisible) {
                            toast.error(result?.response?.message ? result.response.message : "Something went wrong!", {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                    }
                }).catch((error) => {
                    setLoading(false)
                    if (!isToastVisible) {
                        toast.error(error ? error : "Something went wrong!", {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                })

            }



        } else {
            document.querySelector('.topscroll').scrollIntoView();
            // if (mobile_number && !isValidNumber) {
            //     setErrors((prev) => ({
            //         ...prev,
            //         mobile_number: "Invalid mobile number"
            //     }))
            //     toast.error("Invalid mobile number!")
            // }
        }

    }




    // ********************* CHECK CLIENT EMAIL BEFORE ADDING OR UPDATING CLIENT ******************
    const checkClientExistenceBeforeSubmit = () => {
        const { client_id, full_name, email, gender, is_active, address, mobile_number, language, customer_note } = clientsData;
        let requiredFields = { client_id, full_name, gender, mobile_number, language };
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if ((objectKeyConvertToArray(result)?.length === 0)) {
            if (clientId) {
                onClientSubmit()
            } else {
                setLoading(true)
                const { email, mobile_number } = clientsData;
                dispatch(
                    checkClientExistance(
                        {
                            email: email,
                            phone_number: mobile_number
                        },
                        (response) => {
                            if (response?.data?.status_code == 200) {
                                const data = response?.data?.response?.fields
                                if (data?.length > 0) {
                                    setLoading(false)
                                    if (data?.includes("EMAIL")) {
                                        if (!isToastVisible) {
                                            toast.error("Email already registered", {
                                                onClose: () => setIsToastVisible(false),
                                            });
                                            setIsToastVisible(true);
                                        }
                                        setErrors({
                                            ...errors,
                                            mobile_number: "Phone  already registered"
                                        })
                                        formRef.current?.scrollIntoView({ behavior: "smooth" });
                                    } else {
                                        if (data?.includes("PHONE_NUMBER")) {
                                            if (!isToastVisible) {
                                                toast.error("Phone number already registered", {
                                                    onClose: () => setIsToastVisible(false),
                                                });
                                                setIsToastVisible(true);
                                            }
                                            setErrors({
                                                ...errors,
                                                mobile_number: "Phone number already registered"
                                            })
                                            formRef.current?.scrollIntoView({ behavior: "smooth" });
                                        }
                                    }
                                } else {
                                    onClientSubmit()
                                }
                            }
                        },
                        (result) => {
                            // if (result?.data?.status_code == 404) {

                            // }
                        }
                    )
                )
            }
        } else {
            formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }


    // ********************* DELETE CLIENT ******************
    const clientDeleteHandler = (id) => {
        deleteClient(id, access_token).then((response) => {
            if (response.status_code === 200) {
                if (!isToastVisible) {
                    toast.success(response?.response?.message, {
                        onClose: () => setIsToastVisible(false),
                    });
                    setIsToastVisible(true);
                }
                setCurrentPage(1)
                dispatch(getClient(1, search_text, setLoader, dropdownStatus))
                dispatch(getClientWithNoPagination(search_text, setNoPagiantionLoader, dropdownStatus))
            }
        }).catch((error) => {
            if (!isToastVisible) {
                toast.success(error ? error : "Somethings went wrong!"?.response?.message, {
                    onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
            }
        })

    }


    //********************* EDIT CLIENT *********************/
    const clientEditHandler = (id) => {
        navigate(`/dashboard/clients/client-profiles/add-client/?client_id=${id}`);
    }


    return {
        finished, setFinished, clientsData, setClientsData, countriesList, stateList, setStateList, clientHandleChange, setIsEdit,
        isEdit, onClientSubmit, img, loading, errors, onBlurHandler, languageList, setLanguageList, clientDeleteHandler, clientEditHandler,
        setCountryId, setStateId, access_token, checkClientExistenceBeforeSubmit, translation, selectedLanguage, currentPage, setCurrentPage,
        loader, setLoader, isToastVisible, setIsToastVisible, formRef, noPaginationLoader, setNoPagiantionLoader, search_text, setSearchText,
        dropdownStatus, setDropdownStatus, handleImageChange, allImages, handleDeleteImage, setAllImages
    }

}

export default useAddClient
