import React from 'react'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Popup from 'Components/TenantApp/Elements/Popup'
import { maintainenceStatus } from 'Constants/Data/Months'

const UpdateStatusPopup = ({ open, close, submitLoading, onSubmit }) => {
    return (
        <>
            <Popup open={open} close={close} heading={"Update Maintenance Status "}>
                <div className="flex flex-col gap-6">
                    <Dropwdown
                        title={'Status'}
                        placeholder={'Select Status'}
                        required={true}
                        options={maintainenceStatus}
                        value={open?.status}
                        name={'status'}
                        onChange={(e) => close && close({
                            ...open,
                            status: e.target.value
                        })}
                    />
                    <LoginBtn
                        text={'Update Status'}
                        title={'Update Status'}
                        onClick={onSubmit}
                        loading={submitLoading}
                    />
                </div>
            </Popup>
        </>
    )
}

export default UpdateStatusPopup
