import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import React,{useState, useEffect} from "react";
import LocationSelection from "new-components/location";
import useWeekendManagement from "./helper";
import AddWeekend from "./AddWeekend";
import DeleteConfirmation from "new-components/delete-popup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import moment from "moment";
import DateInput from "Components/TenantApp/Elements/DateInput";
import Popup from "Components/TenantApp/Elements/Popup";

// full calendar plugin
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"

const WeekendManagement = () => {
    const {
        translation, selectedLanguage, handleDateClick, events, createWeekend, setCreateWeekend,
        selectedDate, isEdit, setIsEdit, weekendDetail, eventContent, deleteWeekend, todayClickFunc,
        handleDeleteWeekend, deleteLoader, handleCloseDeletePopup, successCallBack, updatedDate, handleDatesSet ,
        setUpdatedDate, currentDate, handleGoToDate, setGoToDate, GoToDateFunc, goToDate, calendarRef
    } = useWeekendManagement()

    const [height, setHeight] = useState('auto');

  useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth >= 770) {
            setHeight('90vh');
        } else {
            setHeight('auto');
        }
        };

        // Initial setup
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <>

        <div className="weekend_management lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right bg-primary-background">
            <Breadcrumb first={selectedLanguage?.name == "English" ? "HRM" : translation[1469]?.value ? translation[1469]?.value : "HRM"} url={"/dashboard/hrm/"} second={selectedLanguage?.name == "English" ? "Weekend Management" : translation[1497]?.value ? translation[1497]?.value : "Weekend Management"} />
            <div className="flex items-center gap-3 justify-between">
                <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
                    <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                        {selectedLanguage?.name == "English" ? "Weekend Management" : translation[1497]?.value ? translation[1497]?.value : "Weekend Management"}
                    </h2>
                    <LocationSelection />
                </div>

            </div>
            <div className="rounded-md bg-white">
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,]}
                    initialView={"dayGridMonth"}
                    headerToolbar={{
                        start: 'title', // will normally be on the left. if RTL, will be on the right
                        center: '',
                        end: 'prev today,goToDateButton next' // will normally be on the right. if RTL, will be on the left
                        //   end: 'prev todayButton,goToDateButton next dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    customButtons={{
                        goToDateButton: {
                            text: `${moment(currentDate).format('DD-MM-YYYY')}`,
                            // click: handleGoToDate,
                        },
                        // todayButton: {
                        //     text: 'Today',
                        //     click: todayClickFunc,
                        // },
                    }}
                    height={height}
                    border={'1px solid black'}
                    events={events}
                    datesSet={handleDatesSet}
                    dateClick={handleDateClick}
                    eventContent={eventContent}
                    buttonText={{
                        day: 'Day',
                        week: 'Week',
                        month: 'Month',
                        today: 'Today',
                    }}
                    buttonIcons={{
                        prev: 'chevron-left',
                        next: 'chevron-right',
                        prevYear: '<<',
                        nextYear: '>>',
                    }}
                    dayHeaderFormat={{ weekday: 'short', omitCommas: true }}
                    titleFormat={{
                        month: 'long', // Format for the month title
                        year: 'numeric', // Format for the year in the title
                    }}

                    // Apply custom styles
                    headerToolbarStyle={{
                        background: '#5679FF', // Change this to your desired background color
                        color: '#000', // Change this to your desired text color
                        border: '1px solid #ddd',
                    }}
                // eventClick={handleEventClick}
                // editable={true} // Enable drag-and-drop
                // eventDurationEditable={false} // Disable resizing
                // eventDrop={handleEventDrop} // Handle event drop
                // eventMouseEnter={handleEventMouseEnter} // Handle event mouse enter
                // eventMouseLeave={handleEventMouseLeave} // Handle event mouse leave
                />

            </div>
        </div>

        {createWeekend &&
            <AddWeekend
                data={weekendDetail}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                selectedDate={selectedDate}
                createWeekend={createWeekend}
                successCallBack={successCallBack}
                setCreateWeekend={setCreateWeekend}
            />
        }

        {deleteWeekend && (
            <DeleteConfirmation
                heading={selectedLanguage?.name == "English" ? "Delete Weekend" : translation[626]?.value ? translation[626]?.value : "Delete Weekend"}
                description={selectedLanguage?.name == "English"
                    ? "Are you sure you want to delete the Weekend?"
                    : translation[627]?.value ? translation[627]?.value
                        : "Are you sure you want to delete the Weekend?"}
                handleClose={handleCloseDeletePopup}
                handleDelete={handleDeleteWeekend}
                isLoading={deleteLoader}
            />
        )}

        {goToDate && (
            <Popup size={"md"}
                heading={"Go to Date"}
                open={goToDate}
                close={setGoToDate}>
                <div className="flex flex-col gap-5">
                    <DateInput
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Go to Date" : translation[477]?.value ? translation[477]?.value : "Go to Date"}
                        name={"goToDate"}
                        value={updatedDate}
                        onChange={(e) => setUpdatedDate(e.target.value)}
                    />
                    <LoginBtn
                        text={"Go to Date"}
                        onClick={GoToDateFunc}
                    />
                </div>
            </Popup>)
        }
    </>;
};

export default WeekendManagement;
