import Svgs from 'Assets/svgs';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import SelectedInvoiceShimmer from 'Components/Utility/Shimmers/SelectedInvoiceShimmer';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import useRefund from './Refund/helper';
import RefundPopup from './Refund';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';

const GroupInvoiceModal = (props) => {
    const { close, open, invoice_titles, selectedInvice, selectedLanguage, translation, calculate_totals, loading, getSaleHistory } = props

    let paymentDate;
    let paymenTime;

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const RefundDone = getDataFromLocalStorage("RefundDone");

    const [openRefundPopup, setOpenRefundPopup] = useState(false)
    const [disableInvoice, setDisableInvoice] = useState(true)
    const { invoiceId, checkRefundLoading, setProductList, checkRefundAvailablity } = useRefund(setOpenRefundPopup, selectedInvice)

    useEffect(() => {
        if (selectedInvice?.id) {
            setProductList(selectedInvice)
        }
        if (selectedInvice?.appointment_services?.length > 0) {
            setDisableInvoice(false)
        }
    }, [selectedInvice])

    const state = useSelector(state => state)
    const location_currency = state?.utility?.currency?.location_currency;

    const ref = useRef();
    const [services, setServices] = useState([])
    const [selectedInvoiceTip, setSelectedInvoiceTip] = useState(0);
    const [selectedInvoiceTotal, setSelectedInvoiceTotal] = useState(0);
    const [selectedInvoiceTax, setSelectedInvoiceTax] = useState(0);

    const handlePrint = useReactToPrint({
        content: () => ref.current,
        documentTitle: "Invoice",
        onAfterPrint: () => { },
    });

    if (selectedInvice?.created_at) {
        const dataArr = selectedInvice?.created_at?.split("-");
        const date_join = dataArr[2]?.split("")?.slice(0, 2)?.join("");
        dataArr?.splice(2, 1, date_join);
        paymentDate = dataArr?.join("-");
        paymenTime = selectedInvice?.created_at
            ?.split("T")[1]
            ?.split(":")
            ?.join()
            ?.split(".")[0]
            ?.replaceAll(",", ":");
    }

    useEffect(() => {
        // Check if selectedInvoice is defined
        if (!selectedInvice) return;

        // Array to store organized appointment services
        const organizedServices = [];

        // Iterate through each appointment service
        selectedInvice?.appointment_services?.forEach(appointment => {
            const { service_names, price, discount_percentage, client, client_data } = appointment;

            // Find if the client already exists in the organized services array
            const existingClientIndex = organizedServices?.findIndex(item => item.client === client);

            if (existingClientIndex !== -1) {
                // If the client exists, push the service details to its services array
                organizedServices[existingClientIndex].services?.push({ service_names, price, discount_percentage });
            } else {
                // If the client doesn't exist, create a new client object with services array
                organizedServices?.push({
                    client: client,
                    client_name: client_data?.full_name,
                    services: [{ service_names, price, discount_percentage }]
                });
            }
        });

        // store data in services
        setServices(organizedServices)
    }, [selectedInvice]);

    useEffect(() => {
        if (selectedInvice) {
            if (selectedInvice?.tip) {
                const selected_invoice_tip = (selectedInvice?.tip?.length > 0 ? selectedInvice?.tip?.reduce(function (total, obj) {
                    return total + (obj.tip !== null && obj?.tip);
                }, 0) : 0)
                setSelectedInvoiceTip(selected_invoice_tip)
            }
        }
    }, [selectedInvice]);

    useEffect(() => {
        if (selectedInvice) {
            const tax1 = selectedInvice?.gst_price ? +selectedInvice?.gst_price : 0
            const tax2 = selectedInvice?.gst_price1 ? +selectedInvice?.gst_price1 : 0
            const selected_invoice_tax = (tax1 + tax2)?.toFixed(2)
            setSelectedInvoiceTax(selected_invoice_tax)
        }
    }, [selectedInvice])


    useEffect(() => {
        if (selectedInvice) {
            let selectedInvoiceTotal = selectedInvice?.order_type === "Appointment"
                ?
                (+(selectedInvice?.total_price + +selectedInvoiceTax + +selectedInvoiceTip)?.toFixed(2))
                :
                +calculate_totals(selectedInvice) + +selectedInvoiceTip + +selectedInvoiceTax
            // console.log(selectedInvoiceTax, "selectedInvoiceTax")

            if (selectedInvice?.coupon_discounted_price) {
                selectedInvoiceTotal = +selectedInvoiceTotal - +selectedInvice?.coupon_discounted_price
            }
            setSelectedInvoiceTotal(selectedInvoiceTotal)
        }
    }, [selectedInvice, selectedInvoiceTip, selectedInvoiceTax])

    useEffect(() => {
        if (RefundDone) {
            setOpenRefundPopup(false)
            close && close()
            getSaleHistory && getSaleHistory()
            localStorage.removeItem('RefundDone')
        }
    }, [RefundDone])


    return (
        <>
            {open &&
                <div
                    id="invoice"
                    className={`invoice__print fixed inset-0 h-[100vh] flex-1 z-[4444] flex justify-center items-center top-0`}
                    ref={ref}
                >
                    <div className='invoice_print_style'>
                        <div className="bg-[#101928]/30 absolute inset-0 z-[1] remove_bg not_show_in_print"></div>
                        <div
                            className={`relative overflow-hidden rounded-2xl lg:w-[26vw] w-[90vw] min-w-[30rem] z-[2] slide-in-elliptic-top-fwd`}
                        >

                            <div className="bg-white rounded-t-2xl shadow-2xl border">
                                <div className="sticky top-0 z-[22] print_padding_top_20">
                                    <div
                                        className="absolute top-0 right-0 bg-primary px-[1.4rem] py-[0.9rem] rounded-bl-2xl rounded-tr-2xl cursor-pointer not_show_in_print"
                                        onClick={close}
                                    >
                                        <Svgs.Close size={'h-[1rem] w-[1rem]'} />
                                    </div>
                                    <h2 className='p-[1rem] text-[#101928] font-semibold text-2xl not_show_in_print'>Sale Details</h2>
                                    <div className='flex justify-center items-center'>
                                        <Svgs.InvoiceCheck size={'3.5rem'} />
                                    </div>
                                    {loading ? "" :
                                        <>
                                            {selectedInvice?.gift_cards_records?.length > 0 && (
                                                <div className='p-[0.4rem] pb-0'>
                                                    <div className='flex cursor-pointer items-center justify-between rounded-md gap-2 bg-[#E6E9FF] p-1'>
                                                        <div className='flex gap-2 items-center'>
                                                            <div className="flex bg-white rounded-md">
                                                                <Svgs.Gift width={'2.8rem'} height={'2.8rem'} />
                                                            </div>
                                                            <div className="">
                                                                <p className="font-bold text-sm"> Gift Card</p>
                                                                <p className=" text-xs text-[#000000] ">{selectedInvice?.gift_cards_records[0].sale_code}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className='flex flex-col gap-2'>
                                                    <LoginBtn text={"View Details"} />
                                                    <LoginBtn className={"!justify-center !w-full !text-[#5679FF]"} borderBtn text={"Action"} />
                                                </div> */}
                                                    </div>
                                                </div>)}
                                            {selectedInvice?.cashback_coupons && (
                                                <div className='p-[1.2rem] pb-0'>
                                                    <div className='flex cursor-pointer items-center justify-between rounded-md gap-2 bg-[#E6E9FF] p-2'>
                                                        <div className='flex gap-2 items-center'>
                                                            <div className="flex bg-white p-1 rounded-md">
                                                                <Svgs.Gift width={'2.8rem'} height={'2.8rem'} />
                                                            </div>
                                                            <div className="">
                                                                <p className="font-bold text-sm leading-4"> Cashback Coupon</p>
                                                                <p className=" text-xs text-[#000000] ">{selectedInvice?.cashback_coupons?.code}</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col'>
                                                            <p className="font-semibold text-sm text-[#000000]">Amount:  {+selectedInvice?.cashback_coupons?.dicounted_amount?.toFixed(2)} {location_currency}</p>
                                                            <p className="font-semibold text-sm text-[#000000] text-end">Expiry Date: {moment(selectedInvice?.cashback_coupons?.expiry).format('DD-MM-YYYY')}</p>
                                                        </div>
                                                    </div>
                                                </div>)}

                                        </>}
                                </div>
                                {loading ?
                                    <SelectedInvoiceShimmer /> :
                                    <div className={`print_style  px-[0.8rem] overflow-y-auto overflow-x-hidden scroll-hidden text-center space-y-3 ${(selectedInvice?.gift_cards_records?.length > 0 || selectedInvice?.cashback_coupons) ? "max-h-[50vh]" : "max-h-[65vh]"}`}>
                                        <h1 className="font-bold text-xl normal-case">
                                            <p>
                                                {invoice_titles?.primary_translation?.invoice}  {invoice_titles?.secondary_translation?.invoice ? `/ ${invoice_titles?.secondary_translation?.invoice}` : ""}
                                            </p>
                                            #
                                            {selectedInvice?.invoice?.id
                                                ?.split("-")[0]
                                            }
                                        </h1>
                                        <div className='flex justify-end items-center gap-2'>
                                            <LoginBtn className={"theme-btn not_show_in_print"} text={"Print Invoice"} onClick={handlePrint} />
                                            <a className="download_btn" href={selectedInvice?.invoice?.file} target="_blank" download>
                                                <LoginBtn className={"theme-btn not_show_in_print"} text={"Download"} />
                                            </a>
                                        </div>
                                        <div className="w-full flex justify-between items-center gap-2 text-sm text-center">
                                            <div className='flex flex-col justify-between'>
                                                <div className='text-left'>
                                                    <p>{paymentDate ? moment(paymentDate).format('DD-MM-YYYY') : "----"}</p>
                                                </div>
                                                <div>
                                                    <p>
                                                        Billed to {"  "}
                                                        <span className="font-bold">
                                                            {selectedInvice?.client_data?.full_name
                                                                ? selectedInvice?.client_data?.full_name
                                                                : "Walk In"}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <LoginBtn className={"theme-btn newSuccessBtnFocus"} text={selectedInvice?.checkout_type == "Refund" ? "Refunded" : "Completed"} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="text-left divide-y border-b">
                                                    <div className="flex items-center justify-between text-[#000] font-semibold text-sm pb-3">
                                                        <p className="text-left text-lg font-semibold">
                                                            {invoice_titles?.primary_translation?.items} {invoice_titles?.secondary_translation?.items ? `/ ${invoice_titles?.secondary_translation?.items}` : ""}
                                                        </p>
                                                        <p className='text-lg font-semibold'>
                                                            {invoice_titles?.primary_translation?.amount} {invoice_titles?.secondary_translation?.amount ? `/ ${invoice_titles?.secondary_translation?.amount}` : ""}
                                                        </p>
                                                    </div>
                                                    {services?.length > 0 && services?.map((itm) => {
                                                        const filteredClientMembership = selectedInvice?.applied_memberships_records?.length > 0 ? selectedInvice?.applied_memberships_records?.find(data => data?.client == itm?.client) : undefined
                                                        const filteredClientVoucher = selectedInvice?.applied_vouchers_records?.length > 0 ? selectedInvice?.applied_vouchers_records?.find(data => data?.client == itm?.client) : undefined
                                                        const filteredClientCoupon = selectedInvice?.applied_coupons_records?.length > 0 ? selectedInvice?.applied_coupons_records?.find(data => data?.client == itm?.client) : undefined
                                                        const filteredClientLoyalty = selectedInvice?.applied_loyalty_points_records?.length > 0 ? selectedInvice?.applied_loyalty_points_records?.find(data => data?.client == itm?.client) : undefined
                                                        const filteredClientPromotion = selectedInvice?.applied_promotions_records?.length > 0 ? selectedInvice?.applied_promotions_records?.find(data => data?.client == itm?.client) : undefined
                                                        return (
                                                            <div className='py-3'>
                                                                <p className="text-left font-bold mb-2">{itm?.client_name}</p>
                                                                {itm?.services?.map((itm) => {
                                                                    return (
                                                                        <div className="grid grid-cols-3 justify-between text-primary text-sm font-medium ">
                                                                            <div className='flex gap-2 col-span-2'>
                                                                                <div>
                                                                                    <p className="text-left">{itm?.service_names?.name}</p>
                                                                                    <p className="text-left">{itm?.service_names?.secondary_name}</p>
                                                                                </div>
                                                                                {itm?.discount_percentage &&
                                                                                    `(Discount ${itm?.discount_percentage}%)`
                                                                                }
                                                                            </div>
                                                                            <p className='flex justify-end'>
                                                                                <span className="text-[#7B7B7B]">1 X&nbsp;</span>
                                                                                {itm?.price?.toFixed(2)} {location_currency}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {filteredClientVoucher &&
                                                                    <div className='flex items-center justify-between gap-2 text-sm '>
                                                                        <div className="flex justify-between w-full">
                                                                            <p className='font-medium'>
                                                                                {selectedLanguage?.name == "English" ? "Paid from Voucher" : translation[218]?.value ? translation[218]?.value : "Paid from Voucher"}
                                                                            </p>
                                                                            <p>{filteredClientVoucher?.price} {location_currency}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {filteredClientPromotion &&
                                                                    <div className='flex items-center justify-between gap-2 text-sm '>
                                                                        <div className="flex justify-between w-full">
                                                                            <p className='font-medium'>
                                                                                Paid from Promotion
                                                                            </p>
                                                                            <p>{filteredClientPromotion?.price} {location_currency}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {filteredClientMembership &&
                                                                    <div className='flex items-center justify-between gap-2 text-sm '>
                                                                        <div className="flex justify-between w-full">
                                                                            <p className='font-medium'>
                                                                                {selectedLanguage?.name == "English" ? "Membership Redeemed" : translation[219]?.value ? translation[219]?.value : "Membership Redeemed"}
                                                                            </p>
                                                                            <p>{filteredClientMembership?.price} {location_currency}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {filteredClientCoupon &&
                                                                    <div className='flex items-center justify-between gap-2 text-sm '>
                                                                        <div className="flex justify-between w-full">
                                                                            <p className='font-medium'>
                                                                                {selectedLanguage?.name == "English" ? "Coupon Redeem " : translation[1480]?.value ? translation[1480]?.value : "Coupon Redeem "} ({filteredClientCoupon?.coupon_name})
                                                                            </p>
                                                                            <p>{filteredClientCoupon?.coupon_discounted_price} {location_currency}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {filteredClientLoyalty &&
                                                                    <div className='flex items-center justify-between gap-2 text-sm '>
                                                                        <div className="flex justify-between w-full">
                                                                            <p className='font-medium'>
                                                                                {selectedLanguage?.name == "English" ? "Redeemed Loyality Points " : translation[220]?.value ? translation[220]?.value : "Redeemed Loyality Points "}
                                                                            </p>
                                                                            <p>{filteredClientLoyalty?.redeemed_points} {location_currency}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    })}
                                                    {selectedInvice?.products_records?.length > 0 &&
                                                        selectedInvice?.products_records?.map((prdct) => {
                                                            return (
                                                                <div className="grid grid-cols-3 justify-between text-primary text-sm font-medium py-3">
                                                                    <div className='col-span-2'>
                                                                        <p className="text-left">
                                                                            {prdct?.product_names?.name && (
                                                                                <>
                                                                                    {prdct?.product_names?.name}
                                                                                    <br />
                                                                                    {prdct?.product_names?.secondary_name}
                                                                                </>
                                                                            )}
                                                                            {prdct?.discount_percentage
                                                                                && (<> (Discount {prdct.discount_percentage}%)</>)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <p className='flex justify-end'>
                                                                        <span className="text-[#7B7B7B]">
                                                                            {prdct?.quantity ? prdct?.quantity : 1} x&nbsp;
                                                                        </span>
                                                                        {prdct?.price?.toFixed(2)} {location_currency}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}

                                                    {selectedInvice?.services_records?.length > 0 &&
                                                        selectedInvice?.services_records?.map((srv) => {
                                                            return (
                                                                <div className="grid grid-cols-3 justify-between text-primary text-sm font-medium py-3">
                                                                    <div className='col-span-2'>
                                                                        <p className="text-left">
                                                                            <>
                                                                                {srv?.service_names?.name}
                                                                                <br />
                                                                                {srv?.service_names?.secondary_name}</>
                                                                            {" "}{srv?.discount_percentage ? `(Discount ${srv?.discount_percentage}%)` : ""}
                                                                        </p>
                                                                    </div>
                                                                    <p className='flex justify-end'>
                                                                        <span className="text-[#7B7B7B]">
                                                                            {srv?.quantity ? srv?.quantity : 1} x
                                                                        </span>
                                                                        {srv?.price?.toFixed(2)} {location_currency}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}

                                                    {selectedInvice?.vouchers_records?.length > 0 &&
                                                        selectedInvice?.vouchers_records?.map((vchr) => {
                                                            return (
                                                                <div className="grid grid-cols-3 justify-between text-primary text-sm font-medium py-3">
                                                                    <p className='col-span-2'>
                                                                        <p>{vchr?.voucher_names?.name}</p>
                                                                        <p>{vchr?.voucher_names?.secondary_name}</p>
                                                                    </p>
                                                                    <p className='flex justify-end'>
                                                                        <span className="text-[#7B7B7B]">
                                                                            {vchr?.quantity ? vchr?.quantity : 1} x {' '}
                                                                        </span>
                                                                        {vchr?.price?.toFixed(2)} {location_currency}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}

                                                    {selectedInvice?.membership_records?.length > 0 &&
                                                        selectedInvice?.membership_records?.map((mem) => {
                                                            return (
                                                                <div className="grid grid-cols-3 justify-between text-primary text-sm font-medium py-3">
                                                                    <p className='col-span-2'>
                                                                        <p>
                                                                            {mem?.membership_names?.name}
                                                                            <br />
                                                                            {mem?.membership_names?.secondary_name}
                                                                        </p>
                                                                    </p>
                                                                    <p className="text-sm flex justify-end">
                                                                        <span>
                                                                            {mem?.quantity ? mem?.quantity : 1} x {' '}
                                                                        </span>
                                                                        {mem?.price?.toFixed(2)} {location_currency}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    {selectedInvice?.gift_cards_records?.length > 0 &&
                                                        selectedInvice?.gift_cards_records?.map((gc) => {
                                                            return (
                                                                <div className="grid grid-cols-3 justify-between text-primary text-sm font-medium py-3">
                                                                    <p className='col-span-2'>
                                                                        <p>
                                                                            {gc?.gift_card_detail?.title}
                                                                            <br />
                                                                        </p>
                                                                    </p>
                                                                    <p className="text-sm flex justify-end">
                                                                        <span>
                                                                            {gc?.quantity ? gc?.quantity : 1} x {' '}
                                                                        </span>
                                                                        {gc?.price?.toFixed(2)} {location_currency}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    {selectedInvice?.donations_records?.length > 0 &&
                                                        selectedInvice?.donations_records?.map(itm => {
                                                            return (
                                                                <div className='flex items-center justify-between  text-sm'>
                                                                    <div className='col-span-2'>
                                                                        <p className='text-black'>
                                                                            Donation
                                                                        </p>
                                                                    </div>
                                                                    <p className="text-sm">{(+itm?.amount)?.toFixed(2)} {location_currency}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                        <div className="text-left flex flex-col gap-1">
                                            <div className="flex items-center justify-between text-primary text-base font-semibold mb-1">
                                                <div>
                                                    <p>{invoice_titles?.primary_translation?.subtotal} {invoice_titles?.secondary_translation?.subtotal ? `/ ${invoice_titles?.secondary_translation?.subtotal}` : ""}</p>
                                                </div>
                                                <p className="text-sm">
                                                    {selectedInvice?.sub_total?.toFixed(2)}{' '}
                                                    {location_currency}
                                                </p>
                                            </div>

                                            {(selectedInvice?.total_tip !== null && selectedInvice?.tip_records?.length > 0) &&
                                                <div className="flex items-center justify-between text-[#000] font-medium text-sm">
                                                    <div>
                                                        <p>{invoice_titles?.primary_translation?.tips} {invoice_titles?.secondary_translation?.tips ? `/ ${invoice_titles?.secondary_translation?.tips}` : ""}</p>
                                                    </div>
                                                    <p className="text-sm">
                                                        {selectedInvice?.total_tip?.toFixed(2)}{" "}
                                                        {location_currency}
                                                    </p>
                                                </div>
                                            }
                                            {selectedInvice?.tax_records?.length > 0 &&
                                                <>
                                                    <div>
                                                        <p className='text-sm'>{invoice_titles?.primary_translation?.taxes} {invoice_titles?.secondary_translation?.taxes ? `/ ${invoice_titles?.secondary_translation?.taxes}` : ""}</p>
                                                    </div>
                                                    {selectedInvice?.tax_records?.map(tax => {
                                                        return <div className="flex items-center justify-between text-primary font-medium text-sm">
                                                            <div>
                                                                <p className='text-[#7B7B7B]'> {tax?.name} ({tax?.tax_rate}%) </p>
                                                            </div>
                                                            <p className="text-sm">
                                                                {(tax?.value)?.toFixed(2)}
                                                                {" "}
                                                                {location_currency}
                                                            </p>
                                                        </div>
                                                    })}
                                                </>
                                            }
                                            {selectedInvice?.applied_gift_cards_records?.length > 0 &&
                                                selectedInvice?.applied_gift_cards_records?.map(itm => {
                                                    return (
                                                        <div className='flex items-center justify-between text-[#000] font-medium text-sm'>
                                                            <div>
                                                                <p className='font-medium'>
                                                                    {selectedLanguage?.name == "English" ? "Gift Card Redeems" : translation[1574]?.value ? translation[1574]?.value : "Gift Card Redeem"}

                                                                </p>
                                                            </div>
                                                            <p className="text-sm">{(+itm?.partial_price)?.toFixed(2)} {location_currency}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {selectedInvice?.change != 0 &&
                                            <div className="flex items-center justify-between text-primary font-medium text-sm">
                                                <p>
                                                    <p className="text-left">
                                                        {invoice_titles?.primary_translation?.change}
                                                        {invoice_titles?.secondary_translation?.change ? `/ ${invoice_titles?.secondary_translation?.change}` : ""}</p>
                                                </p>
                                                <p className="text-sm ">
                                                    {selectedInvice?.change?.toFixed(2)}{" "}
                                                    {location_currency}
                                                </p>
                                            </div>
                                        }
                                        <div className="flex items-center justify-between text-primary font-bold text-base py-2 border-y">
                                            <p>
                                                <p className="text-left">{invoice_titles?.primary_translation?.total} {invoice_titles?.secondary_translation?.total ? `/ ${invoice_titles?.secondary_translation?.total}` : ""}</p>
                                            </p>
                                            <p className="text-sm ">
                                                {selectedInvice?.total_price?.toFixed(2)}{" "}
                                                {location_currency}
                                            </p>
                                        </div>
                                        {/* <div className="flex items-center justify-between text-primary text-sm font-bold"> */}
                                        <div className="text-left flex flex-col gap-2 pb-2 border-b">

                                            <div className="flex items-center justify-between text-primary text-lg font-semibold">
                                                <p>
                                                    <p>{invoice_titles?.primary_translation?.payment_method} {invoice_titles?.secondary_translation?.payment_method ? `/ ${invoice_titles?.secondary_translation?.payment_method}` : ""}</p>
                                                </p>
                                            </div>
                                            <div className="flex items-center flex-col justify-between text-primary">
                                                {selectedInvice?.payment_methods_records?.length > 0 &&
                                                    selectedInvice?.payment_methods_records?.map(itm => {
                                                        return (
                                                            <>
                                                                <div className='flex items-center justify-between text-[#000] font-semibold w-full text-sm'>
                                                                    <p className='font-semibold'>
                                                                        {itm?.payment_method ?
                                                                            itm?.payment_method
                                                                            : "----"
                                                                        }
                                                                    </p>
                                                                    <p className="text-sm">{(itm?.amount)?.toFixed(2)} {location_currency} </p>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between text-primary text-sm font-bold">

                                            <div className="flex items-center justify-between text-primary text-sm text-[#7B7B7B]">
                                                <p className="">
                                                    Payment on {paymentDate ? moment(paymentDate).format('DD-MM-YYYY') : "----"} at{' '}
                                                    {paymenTime ? paymenTime : "----"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {props?.hideRefund ? "" :
                                    <div className="flex items-center justify-end p-5 not_show_in_print">
                                        {loading
                                            ?
                                            <div className="h-9 w-20 animate-pulse bg-gray-200"></div>
                                            : <LoginBtn
                                                text={selectedLanguage?.name == "English" ? "Refund" : translation[135]?.value ? translation[135]?.value : "Refund"}
                                                loading={checkRefundLoading == selectedInvice?.invoice?.id ? true : false}
                                                disabled={disableInvoice || selectedInvice?.is_refund || (checkRefundLoading == selectedInvice?.invoice?.id)}
                                                onClick={() => checkRefundAvailablity(selectedInvice?.invoice?.id, selectedInvice?.id)}
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                openRefundPopup &&
                <RefundPopup
                    open={openRefundPopup}
                    close={() => {
                        setOpenRefundPopup(false)
                        close && close()
                    }}
                    invoiceId={selectedInvice?.invoice?.id}
                    selectedInvice={selectedInvice}
                    setOpenRefundPopup={setOpenRefundPopup}
                />
            }
        </>
    )
}

export default GroupInvoiceModal