import { getAllBlogs, getBlogsCategories } from "Adapters/Api/blogs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DOMPurify from 'dompurify';

const useBlogsHelper = () => {
    const navigation = useNavigate()
    const [loading, setLoading] = useState(true)
    const [blogs, setBlogs] = useState([])
    const [categories, setCategories] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedCategory, setSelectedCategory] = useState()
    const [pagination, setPagination] = useState({})
    const [catLoading, setCatLoading] = useState(true)

    const createMarkup = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = DOMPurify.sanitize(html);
        return tempDiv.textContent || tempDiv.innerText || '';
    }

    const toggleCategorySelection = (category_id) => {
        if (selectedCategory == category_id)
            setSelectedCategory()
        else
            setSelectedCategory(category_id)
    }

    const getBlogs = () => {
        const success = (response) => {
            setLoading(false)
            setPagination({
                count: response?.count,
                total_pages: response?.total_pages,
                current_page: response?.current_page,

            })
            setBlogs(response?.response?.data)
        };

        const fail = () => {
            setLoading(false)
        };

        const payload = {
            page: currentPage,
            category: selectedCategory,
            is_paginated: true,
        }
        getAllBlogs(payload, success, fail);
    }

    const getBlogCategory = () => {
        const success = (data) => {
            setCatLoading(false)
            setCategories(data)
        };

        const fail = () => {
            setCatLoading(false)
        };
        getBlogsCategories(success, fail);
    }

    useEffect(() => {
        setLoading(true)
        getBlogs()
    }, [currentPage, selectedCategory])

    useEffect(() => {
        setCatLoading(true)
        getBlogCategory()
    }, [])


    return {
        loading, blogs, currentPage, selectedCategory, pagination, catLoading, categories, setCurrentPage, setSelectedCategory, navigation,
        createMarkup, toggleCategorySelection
    }
}

export default useBlogsHelper