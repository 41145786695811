import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AddAppointmentCard from "../Elements/AddAppointmentCard";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "./../../../Elements/LoginBtn";
import Popup from "../../../Elements/Popup";
import ClientSection from "./ClientSection";
import Textarea from "../../../Elements/Textarea";
import LoginInput from "../../../Elements/LoginInput";
import AddService from "../../Sales/quick-sales/Services/addService";
import { CREATE_APPOINTMENT, GET_CLIENT_SALE, RESET_APPOINTMENT_DATA, SET_APPOINTMENT_INPUT_DATA } from "Redux/TenantRedux/ActionTypes/Appointment/Appointment";
import { getServiceEmployee, getServiceGroup, getServiceGroupCalendar } from "Adapters/Api/saleServices";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useService } from "./../../Sales/quick-sales/Services/helper";
import { getEmployeeList } from "Adapters/Api/employee";
import { getServiceForCalendar, getservice } from "Adapters/Api/services";
import {
  MONTHS,
  MONTHS_DURATIONS,
  WEEKDAYS,
  WEEKDAYS_SHORT,
} from "Constants/Data/Months";
import Svgs from "Assets/svgs";
import { apiErrorMessage } from "Constants/Data/Errors";
import { checkEmployeeAvailabilityForAppointment, checkEmployeeWorkingTimes, createAppointment, createGroupAppointment, getTodyasAppointments } from "Adapters/Api/apointments";
import { useGroupAppointment } from "./helper";
import DiscountPopup from "./DiscountPromotion/DiscountPopup";
import { discountTypes } from "Redux/TenantRedux/Reducers/clientDiscountPromotionReducer";
import { get_afterward_time_range } from "Constants/Functions/DateTime";
import { CLIENT } from "Redux/TenantRedux/ActionTypes/clientActionTypes";
import AddAppointmentShimmer from "Components/Utility/Shimmers/AddAppointmentShimmer";
import AddServiceGroup from "../../Sales/quick-sales/Services/AddServiceGroup";
import ClientSectionShimmer from "Components/Utility/Shimmers/ClientSectionShimmer";
import CheckBoxClientSection from "./CheckBoxClientSection";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";

const DateCard = ({
  year,
  month,
  date,
  selected,
  onSelected,
  disabled,
  date_card_ref,
}) => {
  const [c_day, setCDay] = useState("");
  const [full_day, setFullDay] = useState("");


  useEffect(() => {
    let new_date = new Date(`${month} ${date}, ${year}`);
    setCDay(
      WEEKDAYS_SHORT[new_date.getDay() - 1]
        ? WEEKDAYS_SHORT[new_date.getDay() - 1]
        : "Sun"
    );
    setFullDay(
      WEEKDAYS[new_date.getDay() - 1]
        ? WEEKDAYS[new_date.getDay() - 1]
        : "Sunday"
    );
  }, [month]);

  return (
    <div
      ref={date_card_ref}
      className={`${date} border rounded-md bg-white text-xs px-5 py-2 text-center cursor-pointer ${selected
        ? "border-primary selected_scroll border-[2px] text-primary bg-[#F8FBFB]"
        : `${disabled ? "bg-[#f2f2f2] cursor-not-allowed" : ""
        } text-[#A1A1A1] border-[#A1A1A1]`
        }`}
      onClick={() => {
        if (!disabled) {
          onSelected &&
            onSelected(`${year}-${MONTHS.indexOf(month) + 1}-${date}`, full_day);
        }
      }}
    >
      { }
      <p>{c_day ? c_day : ""}</p>
      <p>{date}</p>
    </div>
  );
};


const AddGroupAppointment = () => {
  // check for only services api call when on calendar
  let allowGroupsApiCall = true

  const {
    durationOptions,
    serviceModal,
    isEdit,
    active,
    errors,
    loading,
    serviceData,
    ServiceType,
    setService,
    setAddService,
    onSubmitService,
    serviceHandleChange,
    onBlurHandler,
    setIsEdit,
    removedSelectedEmployee,
    setErrors,
    setServiceType,
    setIsDelete,
    formRef
  } = useService(allowGroupsApiCall);

  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const selected_location = getDataFromLocalStorage("selected_location");
  const clientLength = getDataFromLocalStorage("clientLength");
  let yearArr = [2022, 2023, 2024];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sliderRef = useRef();
  const DateCardRef = useRef();

  const state = useSelector((state) => state);
  const location_currency = state?.utility?.currency?.location_currency;
  const employeeRedux = useSelector((state) => state?.employee);
  const appoint_inp_data = useSelector(
    (state) => state?.appointment?.appointment_inp_data
  );
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const clientRedux = state?.client?.clients;
  const reduxlocationsList = state?.locations?.locations;
  const selected_client = state?.appointment?.selected_client;
  const selected_client_group = state?.appointment?.selected_client_group;
  let future_err = state?.appointment?.appointment_future_errors;
  let hours_err = state?.appointment?.appointment_hours_errors;
  const appointment_client = state?.appointment?.appointment_client;

  const [selectedLocation, setLocationSelection] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [btn_loading, setBtnLoading] = useState(false);
  const [serviceEmployee, setServiceEmployee] = useState([]);
  const [SelectServices, setSelectServices] = useState(false);
  const [dropdown, setdropdown] = useState("False");
  const [AddNotes, setAddNotes] = useState(false);
  const [notes_inp, setNotesInp] = useState("");
  const [inp_data, setInpData] = useState({});
  const [search, setSearch] = useState("");
  const [select, setselect] = useState("");
  const [SORTED_TIME, SETSORTEDTIME] = useState([])
  const [ServiceGroup, setServiceGroup] = useState(false);
  const [confirmClient, setConfirmClient] = useState(false)
  const [clientInputPopup, setClientInputPopup] = useState(true)
  const [selectClientInputNumber, setSelectClientInputNumber] = useState(null)
  const [selectClientValue, setSelectClientValue] = useState(null)
  const [allSelectedClients, setAllSelectedClient] = useState([])
  const [deletedClientID, setDeletedClientID] = useState('')
  const [confirm, setConfirm] = useState(false)
  const [selectedId, setSelectedId] = useState();
  const [selectedClientIdIndex, setSelectedClientIdIndex] = useState();
  const [possible_error, setPossibleError] = useState({
    booking_error: "",
    future_slot_error: "",
  });
  const [createAppButtonDisable, setCreateAppButtonDisable] = useState(false)
  const [createAppErr, setCreateAppErr] = useState({})
  const [submitButtonClick, setSubmitButtonClick] = useState(false)
  const [select_service_serviceGroups, setSelServGroups] = useState([])
  const [noPromotionAvailed, setNoPromotionAvailed] = useState(true)
  const [Loader, setLoader] = useState(true)
  const [memberInputErrorMessage, setMemberInputErrorMessage] = useState("")

  const {
    availOfferPopup,
    appointmentData,
    setAvailOfferPopup,
    setAppointmentData,
    addOffer,
    filterOffer,
    combinedAppointment,
    setCombinedAppointment,
    serviceGroupRedux,
    servicesRedux,
    setGroupLoader,
    addCombinedData,
    initialState
  } = useGroupAppointment({ allSelectedClients, selectedId });

  // get_required_amount_validation 
  const get_required_amount_validation = useCallback(() => {
    let total_price = 0
    appointmentData?.appointments?.forEach(srvice => {
      total_price += srvice?.discount_price || srvice?.discount_price == 0 ? srvice?.discount_price : srvice?.price
    })

    if (appointmentData?.total_required_ammount) {
      if (total_price >= appointmentData?.total_required_ammount) {
        return true
      }
    }
    else {
      return true
    }

    return false
  }, [appointmentData]);

  useEffect(() => {
    if (appointmentData?.selected_promotion_id) {
      setNoPromotionAvailed(false)
    }
  }, [appointmentData, appointmentData?.selected_promotion_id])

  //Updates selectedId to the first client ID in selected_client_group if it doesn't exist.
  //Also, sets the selected client index accordingly.
  useEffect(() => {
    if (selected_client_group?.length > 0) {
      if (!selectedId) {
        let clientId = selected_client_group[0]
        setSelectedId(clientId?.id);
        setSelectedClientIdIndex(0)
      }
    }
  }, [selected_client, selected_client_group, selectedId]);

  useEffect(() => {
    const someClientHaveMissingData = combinedAppointment
      ?.filter(item => item.client !== selectedId)
      ?.filter(item => item?.appointments?.length > 0 && item?.appointments?.find(itm =>
        !itm.date_time ||
        !itm.service ||
        !itm.member ||
        !itm.duration));

    if (btn_loading
      || combinedAppointment?.filter(app => app?.client != selectedId)?.length !== allSelectedClients?.filter(app => app?.id != selectedId)?.length
      || Object.keys(appointmentData).length == 0
      || appointmentData?.appointments?.length == 0
      || !appointmentData.appointment_date
      || hours_err?.length != 0
      || future_err.length != 0
      || appointmentData.appointments?.find(
        (itm) =>
          !itm.date_time ||
          !itm.service ||
          !itm.member ||
          !itm.duration
      )
      || someClientHaveMissingData?.length > 0
      || !get_required_amount_validation()
      || (appointmentData?.is_client_compulsary && Object.keys(state?.appointment?.selected_client).length == 0)) {
      setCreateAppButtonDisable(true)
    } else {
      setCreateAppButtonDisable(false)
    }
  }, [btn_loading, combinedAppointment, allSelectedClients, appointmentData, future_err, hours_err, get_required_amount_validation, selectedId, state])

  useEffect(() => {
    let onward_time_range = get_afterward_time_range(appointmentData?.appointment_date)
    SETSORTEDTIME(onward_time_range)
  }, [appointmentData?.appointment_date])

  useEffect(() => {
    setTimeout(() => {
      setAppointmentData({
        ...appointmentData,
        selected_year: new Date().getFullYear(),
      });
    }, 100);
  }, [])

  // Updates appointmentData based on selected_year changes, setting relevant date properties.
  useEffect(() => {
    let dt = new Date();
    let date = new Date(
      appointmentData?.selected_year &&
        appointmentData?.selected_year != dt.getFullYear()
        ? appointmentData?.selected_year
        : new Date()
    );
    let today_year = date.getFullYear();
    let today_month = date.getMonth();
    let today_date = date.getDate();
    let today_day = date.getDay();

    setAppointmentData({
      ...appointmentData,
      current_year: today_year,
      current_month: today_month,
      current_date: today_date,
      selected_month: MONTHS[today_month],
      selected_day: WEEKDAYS[today_day - 1],
      appointment_date: `${today_year}-${today_month + 1}-${today_date}`,
    });
  }, [appointmentData?.selected_year]);

  useEffect(() => {
    FilterDataGroup()
  }, [availOfferPopup, appointmentData?.selected_promotion_id, search])


  useEffect(() => {
    if (select_service_serviceGroups && select_service_serviceGroups.length == 0) {
      FilterDataGroup()
    }
  }, [search, state?.saleService?.servicesgroup])


  useEffect(() => {
    FilterDataGroup()
    if (!employeeRedux.employee_updated) {
      dispatch(getEmployeeList());
    }

    if (Object.keys(appoint_inp_data).length > 0) {
      setAppointmentData(appoint_inp_data);
    }

  }, []);

  useEffect(() => {
    if (selected_location) {
      dispatch(getServiceForCalendar(selected_location, setLoader));
    }
  }, [selected_location])

  useEffect(() => {
    if (appointmentData?.selected_promotion_id) {
      FilterDataGroup()
    }
  }, [appointmentData?.selected_promotion_id])

  useEffect(() => {
    if (!Loader)
      if (sliderRef && sliderRef.current && DateCardRef && DateCardRef.current) {
        setTimeout(() => {
          SliderRef_Hanlder();
        }, 500);
      } else {
      }
  }, [Loader, appointmentData?.selected_month]);

  useEffect(() => {
    if (selected_location) {
      setLocationSelection(selected_location);
    } else {
      if (reduxlocationsList?.length > 0) {
        setLocationSelection(reduxlocationsList[0]?.id);
      }
    }
  }, [reduxlocationsList?.length]);


  useEffect(() => {
    let lctin = reduxlocationsList.find((itm) => itm?.id === selected_location);
    if (lctin != undefined || lctin != null) {
      if (Object.keys(lctin).includes("currency") && lctin?.currency?.code) {
        setSelectedCurrency(lctin?.currency?.code);
        dispatch({
          type: "LOCATION_CURRENCY",
          payload: lctin?.currency?.code,
        });
      }
    }
  }, [selected_location, reduxlocationsList?.length, reduxlocationsList]);

  useEffect(() => {
    if (appointmentData?.appointments?.length > 0) {
      dispatch(checkEmployeeAvailabilityForAppointment(
        { check_availability: appointmentData.appointments, appointment_date: appointmentData?.appointment_date },
        (response) => {
          setAppointmentData({
            ...appointmentData,
            appointments: response?.employee
          })
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" })
        }
      ))
    }

  }, [appointmentData?.appointment_date])

  // This function is responsible for Sets data for the next client.
  const setAnotherClientData = () => {
    if (selected_client_group?.length > selectedClientIdIndex) {
      let clientId = selected_client_group[selectedClientIdIndex + 1]
      setSelectedId(clientId?.id);
      setSelectedClientIdIndex(selectedClientIdIndex + 1)
      const prevData = combinedAppointment?.find(itm => itm?.client == clientId?.id)
      if (prevData?.appointments?.length > 0) {
        setAppointmentData(prevData)
      } else {
        setNoPromotionAvailed(true)
        setAppointmentData({
          ...appointmentData,
          appointments: [],
          avail_offer: [],
          client_type: "IN HOUSE",
          selected_promotion: {},
          selected_promotion_id: "",
          selected_promotion_type: "",
          is_promotion_availed: false,
          appointment_notes: ""
        });
      }
    }
  }

  const handleAppointmentNoteChange = (e) => {

    if (e?.target) {
      const { name, value } = e.target;
      if (name == "notes_inp") {
        // setAppointmentData({
        //   ...appointmentData,
        //   appointment_notes: value
        // });
        setNotesInp(value);
        setErrors((prev) => ({ ...prev, notes_inp: "" }));
      }
    }

  }

  const searchfunction = (e) => {
    setSearch(e.target.value);
  };

  //return all service against the selected location on dashboard or calender
  const appointmentServices = () => {
    let result = state?.saleService?.servicesgroup?.filter((grp) => grp?.status === true)?.map((itm) => {
      let grp_services = itm?.services?.filter((srv) => {
        let loc_service = srv?.location?.find((loc) => loc === selected_location);
        if (loc_service) {
          return true;
        } else {
          return false;
        }
      });

      if (grp_services.length > 0) {
        return { ...itm, services: grp_services };
      } else {
        return undefined;
      }
    });
    result = result.filter((itm) => itm);
    return result;
  }
  // const appointmentServices = () => {
  //   let avail_offer_type = appointmentData?.avail_offer?.find(obj => obj?.type === discountTypes.directOrFlat || discountTypes.specificGroup);
  //   let selectedServic = appointmentData?.selected_promotion?.excluded_services?.map(itm => itm.id)
  //   let results = [];

  //   if (avail_offer_type?.type === discountTypes.directOrFlat) {
  //     let directOrFlatDiscount = avail_offer_type?.category_discount?.find(obj => obj.category_type === 'Service')?.discount;
  //     if (directOrFlatDiscount) {
  //       let result = state?.saleService?.servicesgroup?.filter((grp) => grp?.status === true)?.map((itm) => {

  //         let grp_services = itm?.services?.map((srv) => {
  //           let discountService = srv.priceservice.map(obj => {
  //             return {
  //               ...obj,
  //               discount_price: parseInt(obj?.price) - parseInt(obj?.price) * directOrFlatDiscount / 100
  //             }

  //           })
  //           let loc_service = srv?.location?.find((loc) => loc == selectedLocation);
  //           if (loc_service) {
  //             return {
  //               ...srv,
  //               priceservice: discountService,
  //               offer: appointmentData?.avail_offer
  //             };
  //           }
  //           else {
  //             return {
  //               ...srv,
  //               priceservice: discountService,
  //             };
  //           }
  //         });

  //         if (grp_services.length > 0) {
  //           return { ...itm, services: grp_services };
  //         } else {
  //           return undefined;
  //         }
  //       });

  //       result = result?.filter((itm) => itm);

  //       return result;
  //     }
  //   }
  //   if (avail_offer_type?.type === discountTypes.specificGroup) {
  //     let result = state?.saleService?.servicesgroup?.filter((grp) => grp?.status === true)?.map((itm) => {
  //       let specificGroupDiscount = avail_offer_type.servicegroup_discount.find(obj => obj.servicegroup === itm.id)?.discount;
  //       if (specificGroupDiscount) {
  //         let grp_services = itm?.services?.map((srv) => {
  //           let discountService = srv.priceservice.map(obj => {
  //             return {
  //               ...obj,
  //               discount_price: parseInt(obj?.price) - parseInt(obj?.price) * specificGroupDiscount / 100
  //             }

  //           })
  //           let loc_service = srv?.location?.find((loc) => loc == selectedLocation);
  //           if (loc_service) {
  //             return {
  //               ...srv,
  //               priceservice: discountService,
  //               offer: appointmentData?.avail_offer
  //             };
  //           }
  //           else {
  //             return {
  //               ...srv,
  //               priceservice: discountService,
  //             };
  //           }
  //         });

  //         if (grp_services.length > 0) {
  //           return { ...itm, services: grp_services };
  //         } else {
  //           return undefined;
  //         }
  //       }
  //       else {
  //         let grp_services = itm?.services?.filter((srv) => {
  //           let loc_service = srv?.location?.find((loc) => loc == selectedLocation);
  //           if (loc_service) {
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         });
  //         if (grp_services.length > 0) {
  //           return { ...itm, services: grp_services };
  //         } else {
  //           return undefined;
  //         }
  //       }
  //     })
  //     result = result.filter((itm) => itm);

  //     return result;
  //   }
  //   else {
  //     let result = state?.saleService?.servicesgroup?.filter((grp) => grp?.status === true)?.map((itm) => {
  //       let grp_services = itm?.services?.filter((srv) => {
  //         let loc_service = srv?.location?.find((loc) => loc == selectedLocation);
  //         if (loc_service) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       });

  //       if (grp_services.length > 0) {
  //         return { ...itm, services: grp_services };
  //       } else {
  //         return undefined;
  //       }
  //     });
  //     result = result.filter((itm) => itm);

  //     if (selectedServic?.length > 0) {
  //       result?.length > 0 && result?.map(itm => {
  //         let newResults = itm?.services?.filter(ser => {
  //           if (!selectedServic?.find(s => s == ser?.id)) return true
  //         })
  //         if (newResults?.length > 0) {
  //           results.push({ ...itm, services: newResults })
  //         }
  //       })
  //       result = results;
  //     }
  //     return result;
  //   }
  // }

  // filter group in the dropdown
  const FilterDataGroup = () => {
    let filter_data = []

    appointmentServices()?.forEach((itm) => {
      if (search) {
        if (itm.name.toLowerCase().includes(search.toLowerCase())) {
          const results = itm?.services?.filter((dt) => {
            if (dt.name.toLowerCase().includes(search.toLowerCase())) {
              return true;
            }
          })
          results?.length > 0 ?
            filter_data.push({ name: itm?.name, services: results }) :
            filter_data.push({ name: itm?.name })
        } else {
          const result = itm?.services?.filter((dt) => {
            if (dt.name.toLowerCase().includes(search.toLowerCase())) {
              return true;
            }
          })
          result?.length > 0 && filter_data.push({ name: itm?.name, services: result })
        }
      }
    });

    setSelServGroups(search ? filter_data : appointmentServices())

    return search ? filter_data : appointmentServices()
  };

  const selectedServiceDurations = () => {
    let result = appointmentServices()?.map((itm) => {
      let selected_serv = itm?.services?.find((srv) => srv?.name === select);
      if (selected_serv) {
        return selected_serv?.priceservice;
      } else {
        return false;
      }
    });

    result = result.filter((itm) => itm);
    return result;
  };
  selectedServiceDurations();


  //set duration option list
  let [...duration_options] = selectedServiceDurations()?.map((itm) => {
    return itm?.map((i) => {
      return {
        label: i?.duration.replaceAll("_", " "),
        value: i?.duration.replaceAll("_", " "),
      };
    });
  });


  const addNewAppointment = (data) => {
    setAppointmentData({
      ...appointmentData,
      appointments: [
        ...appointmentData.appointments,
        {
          ...data,
        },
      ],
    });
    setInpData({});
    setselect("");
  };


  const SliderRef_Hanlder = () => {
    let slider = sliderRef.current;
    let date_card = DateCardRef.current;

    let slider_left_ofset = slider?.offsetLeft;
    let card_left_ofset = date_card?.offsetLeft;

    let ofset_dif = card_left_ofset - slider_left_ofset;

    if (slider) {
      slider.scrollLeft = ofset_dif - 100;
    }
  };

  //selected service name
  const handleServices = (id, name) => {
    setdropdown("False");
    let formatdata = `${name.srv_name} (${name.srv_duration}, ${selectedCurrency} ${name.price})`;

    setselect(formatdata);
    getServiceEmployee(id)
      .then((result) => {
        const employees = result?.response?.data;
        setServiceEmployee(employees);
      })
      .catch((error) => {
        toast.error(apiErrorMessage, { toastId: "toast" });
      });
  };

  //create appointment handler here
  const handle_submit = (groupAppointmentsData) => {
    setSubmitButtonClick(true)
    if (appointmentData.appointments?.length == 0) {
      setCreateAppErr({ error: `${selectedLanguage?.name == "English" ? "Add atleast one appointment service" : translation[1722]?.value ? translation[1722]?.value : "Add atleast one appointment service"}`, selectedClient: selected_client })
    } else {
      if (hours_err?.length == 0 && future_err.length == 0 && appointmentData.appointments?.every(
        (itm) =>
          itm.date_time &&
          itm.service &&
          itm.member &&
          itm.duration
      )) {
        setBtnLoading(true);
        const business = getDataFromLocalStorage("business_data");
        const selected_location = getDataFromLocalStorage("selected_location");

        const combinedAppointments = groupAppointmentsData?.map(combined => {
          const updatedAppointments = combined?.appointments?.map(appointment => {
            // Remove is_favourite only when it's false
            const { is_favourite, ...rest } = appointment;
            return is_favourite === false ? rest : appointment;
          });
          return { ...combined, appointments: updatedAppointments };
        })

        dispatch(
          createGroupAppointment(
            { group_appointments: combinedAppointments, appointment_notes: notes_inp, business: business?.id, business_address: selected_location },
            async (response) => {
              setSubmitButtonClick(false)
              setCreateAppErr({})
              toast.success("Appointment created successfully", { toastId: "toast" });
              await dispatch({
                type: RESET_APPOINTMENT_DATA,
              });
              setBtnLoading(false);
              setAppointmentData({});
              dispatch({
                type: "CREATED_CLIENT_FROM_APPOINTMENT",
                payload: [],
              })
              dispatch({
                type: "REST_SELECTED_APPOINTMENT_CLIENT",
                payload: {},
              })
              dispatch({
                type: CLIENT.SET_SPECIFIC_CLIENTS_FOR_CHECKOUT,
                payload: {}
              });

              navigate(`/dashboard/calendar/appointment-group-detail/${response.appointment_id}/?parent_id=${response?.appointment_group?.id}`);

            },
            () => {
              setBtnLoading(false);
              toast.error(apiErrorMessage, { toastId: "toast" });
            }
          )
        );
      }
    }
  };

  //check employee availability of working
  const checkEmployeetiming = (dt) => {

    if ((dt?.member) && (dt?.duration)) {
      const formate_data = {
        service: dt?.service,
        member_id: dt?.member,
        duration: dt?.duration,
        app_time: dt?.date_time,
        date: dt?.appointment_date
      }

      dispatch(checkEmployeeWorkingTimes(
        formate_data,
        (response) => {
          setAppointmentData({
            ...appointmentData,
            appointments: [
              ...appointmentData.appointments.map(
                (appmnt, ind) => {
                  if (ind == dt?.index) {

                    return {
                      ...dt,
                      message: response?.message
                    };

                  }
                  return appmnt;
                }
              ),
            ],
          });
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" })
        }
      ))
    }
  }

  const handleRemoveAppointments = (id) => {
    delete appointmentData?.selected_promotion
    delete appointmentData?.selected_promotion_type
    delete appointmentData?.selected_promotion_id

    if (appointmentData.is_promotion_availed)
      setAppointmentData({
        ...appointmentData,
        appointments: [],
        is_promotion_availed: false
      })
    else {
      setAppointmentData({
        ...appointmentData,
        appointments: [
          ...appointmentData.appointments?.filter(
            (app, app_i) => {
              return app_i != id;
            }
          ),
        ],
      })
    }
  }

  const handleMemberInputValue = (e) => {
    let value = parseInt(e.target.value);

    if (isNaN(+value) && value !== 0) {
      setSelectClientInputNumber(null)
      setMemberInputErrorMessage("")
    } else {
      if ((+value > 1 && +value <= 10)) {
        setSelectClientInputNumber(e.target.value);
        setMemberInputErrorMessage("")
      } else {
        setSelectClientInputNumber(null)
        setMemberInputErrorMessage("Only allowed 2 to 10 members")
      }
    }
  };

  const handleClientInput = () => {
    setSelectClientValue(selectClientInputNumber);
    setClientInputPopup(false)
  };

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1.3rem] h-full bounce-in-right">
        {Loader ?
          <>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-3 animate-pulse">
                <div className="h-10 w-2/5 bg-gray-200 rounded-lg" />
              </div>
              <div className="lg:grid grid-cols-5 mt-[3rem] gap-[3rem]  ">
                <AddAppointmentShimmer />
                <ClientSectionShimmer />
              </div>
            </div>
          </>
          :
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <span
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Svgs.Back />
                </span>
                <h2 className="text-primary font-bold lg:text-3xl text-xl">

                  {selectedLanguage?.name == "English" ? "Group Appointments" : translation[1567]?.value ? translation[1567]?.value : "Group Appointments"}

                </h2>
              </div>
              <div className="flex items-center gap-3">
                {/* <LoginBtn
                  disabled={appointmentData?.is_promotion_availed ? true : false}
                  text={selectedLanguage?.name == "English" ? "Group Appointment" : translation[1567]?.value ? translation[1567]?.value : "Group Appointment"}

                  onClick={() => {
                    setClientInputPopup(!clientInputPopup)
                  }}
                /> */}
                <LoginBtn
                  disabled={appointmentData?.is_promotion_availed ? true : false}
                  text={selectedLanguage?.name == "English" ? "Single Appointment" : translation[129]?.value ? translation[129]?.value : "Single Appointment"}

                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>

            </div>
            <div className="lg:grid grid-cols-5 mt-[3rem] gap-[3rem]">
              {/* Appointment Service */}
              <div className="flex flex-col gap-5 lg:col-span-3 pb-5">
                <div className="flex flex-col gap-3">
                  <div className="flex items-center justify-end">
                    <div className="text-sm flex items-center gap-2">
                      <Dropwdown
                        title=""
                        placeholder="Select Year"
                        showTitle={false}
                        value={appointmentData.selected_year}
                        name="selected_month"
                        className={'w-[100px] flex-0'}
                        disabled={selectedId != allSelectedClients[0]?.id}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            selected_year: e.target.value,
                          });
                          const updatedCombinedAppointment = combinedAppointment?.map(appointment => ({
                            ...appointment,
                            selected_year: e.target.value,
                          }));
                          setCombinedAppointment(updatedCombinedAppointment)
                        }}
                        options={
                          yearArr?.map((itm, ind) => {
                            let date = new Date();
                            let crnt_yr = date.getFullYear();
                            return (
                              {
                                label: itm,
                                value: itm,
                                disabled: itm < crnt_yr ? true : false,
                              }
                            );
                          })}
                      />
                      <Dropwdown
                        title=""
                        placeholder="Select Month"
                        showTitle={false}
                        value={appointmentData.selected_month}
                        name="selected_month"
                        className={'w-[120px] flex-0'}
                        onChange={(e) => {
                          setAppointmentData({
                            ...appointmentData,
                            selected_month: e.target.value,
                          });
                          const updatedCombinedAppointment = combinedAppointment?.map(appointment => ({
                            ...appointment,
                            selected_month: e.target.value,
                          }));
                          setCombinedAppointment(updatedCombinedAppointment)
                        }}
                        disabled={selectedId != allSelectedClients[0]?.id}
                        options={MONTHS.map((mnth, index) => {
                          const dt = new Date();
                          return {
                            label: mnth,
                            value: mnth,
                            disabled:
                              appointmentData.selected_year > dt.getFullYear()
                                ? false
                                : index >= dt.getMonth()
                                  ? false
                                  : true,
                          };
                        })}
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-3 overflow-auto py-3 scroll-smooth"

                    ref={sliderRef}
                  >
                    {appointmentData?.current_month >= 0 &&
                      [
                        ...Array(
                          MONTHS_DURATIONS[
                          MONTHS.indexOf(appointmentData?.selected_month)
                          ]
                        ).keys(),
                      ].map((day, index) => {
                        let is_selected =
                          appointmentData.appointment_date ==
                            `${appointmentData?.current_year}-${MONTHS.indexOf(appointmentData?.selected_month) + 1
                            }-${day + 1}`
                            ? true
                            : false;
                        let is_start =
                          MONTHS.indexOf(appointmentData?.selected_month) ==
                            appointmentData?.current_month
                            ? appointmentData?.current_date == day + 1
                            : index == 0;
                        return (
                          <DateCard
                            date_card_ref={
                              is_selected
                                ? DateCardRef
                                : is_start
                                  ? DateCardRef
                                  : { current: undefined }
                            }
                            year={appointmentData?.current_year}
                            month={appointmentData?.selected_month}
                            current_month={appointmentData?.current_month}
                            date={day + 1}
                            disabled={
                              (MONTHS.indexOf(appointmentData?.selected_month) ==
                                appointmentData?.current_month
                                ? appointmentData?.current_date <= day + 1
                                  ? false
                                  : true
                                : false) || selectedId != allSelectedClients[0]?.id
                            }
                            selected={is_selected}
                            onSelected={(value, selected_day) => {
                              setAppointmentData({
                                ...appointmentData,
                                appointment_date: value,
                                selected_day: selected_day
                              });
                              const updatedCombinedAppointment = combinedAppointment?.map(appointment => ({
                                ...appointment,
                                appointment_date: value,
                                selected_day: selected_day
                              }));
                              setCombinedAppointment(updatedCombinedAppointment)
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="flex items-center gap-3 flex-wrap">
                  {!confirm &&
                    allSelectedClients?.map((client) => (
                      <div className="border border-[#5679FF] border-solid  rounded-md p-1 flex items-center gap-2">
                        {client?.image ? <img className="w-8 h-8 object-cover rounded" src={client?.image} alt="" /> : <LabelAvatar className='!w-8 !h-8 rounded' fontSize='text-sm' iconLabel={client?.full_name} index={1} />}
                        <h4 className="text-[#5679FF] font-medium">{client?.full_name}</h4>
                        <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#3150C6] cursor-pointer" onClick={() => {
                          const prevData = combinedAppointment?.filter(itm => itm?.client != client?.id)
                          setCombinedAppointment([...prevData])
                          setDeletedClientID(client?.id)
                        }}>
                          <Svgs.Cross className='w-2 h-2' fill='#fff' />
                        </div>
                      </div>
                    ))
                  }
                </div>

                {confirm &&
                  <div className="flex flex-col gap-4">

                    {/* {confirm &&
                      <div className="flex items-center gap-4 justify-end flex-wrap mt-2">
                        <LoginBtn
                          disabled={appointmentData?.is_promotion_availed ? true : false}
                          text={
                            <div className='flex items-center gap-2'>
                              <a data-tip={'Click to add an available offer or discount to the appointment.'} className='cursor-pointer flex items-center gap-1'>
                                <Svgs.Plus />
                                {selectedLanguage?.name == "English" ? "Add Offer/Discount" : translation[129]?.value ? translation[129]?.value : "Add Offer/Discount"}
                              </a>
                            </div>
                          }
                          onClick={() => {
                            setAvailOfferPopup(!availOfferPopup)
                          }}
                        />
                      </div>
                    } */}
                    <div>
                      {appointmentData &&
                        appointmentData?.appointments?.length > 0 &&
                        appointmentData?.appointments?.map((appointment, index) => {
                          return (
                            <>
                              <AddAppointmentCard
                                submitButtonClick={submitButtonClick}
                                location_currency={location_currency}
                                is_last_card={appointmentData?.appointments?.length == (index + 1) ? true : false}
                                select_service_serviceGroups={select_service_serviceGroups}
                                time_range={SORTED_TIME}
                                addOffer={addOffer}
                                filterOffer={filterOffer}
                                servicesRedux={servicesRedux}
                                selectedLanguage={selectedLanguage}
                                translation={translation}
                                availOfferPopup={availOfferPopup}
                                setAvailOfferPopup={setAvailOfferPopup}
                                possible_error={possible_error}
                                setPossibleError={setPossibleError}
                                selectedLocation={selectedLocation}
                                serviceEmployee={serviceEmployee}
                                search={search}
                                select={select}
                                length={appointmentData.appointments?.length - 1}
                                appointmentServices={appointmentServices}
                                setselect={setselect}
                                searchfunction={searchfunction}
                                FilterDataGroup={FilterDataGroup}
                                data={appointment}
                                appointmentData={appointmentData}
                                setAppointmentData={setAppointmentData}
                                appointment_date={appointmentData?.appointment_date}
                                index={index}
                                key={index}
                                durationOption={duration_options[0]}
                                checkEmployeetiming={(dt) => {
                                  checkEmployeetiming({ ...dt, index })
                                }}
                                onRemove={(id) => handleRemoveAppointments(id)}
                                setNoPromotionAvailed={setNoPromotionAvailed}
                                setSelServGroups={setSelServGroups}
                                serviceGroupArray={select_service_serviceGroups}
                                onUpdate={(
                                  { name, value },
                                  extraValues,
                                  book,
                                  online_slot,
                                  additional_data
                                ) => {
                                  let ex_data = extraValues ? extraValues : {};
                                  setAppointmentData({
                                    ...appointmentData,
                                    appointments: [
                                      ...appointmentData.appointments.map(
                                        (appmnt, ap_i) => {
                                          if (ap_i == index) {
                                            if (name === "service") {
                                              return {
                                                ...appmnt,
                                                offer: appointmentData?.avail_offer[0],
                                                client_can_book: book,
                                                slot_availible_for_online: online_slot,
                                                srv_name: additional_data?.srv_name,
                                                srv_duration: additional_data?.srv_duration,
                                                price: additional_data?.price,
                                                discount_price: additional_data?.discount_price,
                                                duration: additional_data?.srv_duration,
                                                [name]: value,
                                                ...ex_data,
                                              };
                                            } else {
                                              return {
                                                ...appmnt,
                                                offer: appointmentData?.avail_offer[0],
                                                [name]: value,
                                                ...ex_data,
                                              };
                                            }
                                          }
                                          return appmnt;
                                        }
                                      ),
                                    ],
                                  });
                                }}
                              />
                            </>
                          );
                        })}

                      <div className="flex gap-6">
                        <div className="relative">
                          <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                            <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                          </div>
                          {appointmentData &&
                            appointmentData.appointments &&
                            appointmentData.appointments.length > 0 && (
                              <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                            )}
                        </div>
                        <div className="mt-[2rem] border bg-white px-4 py-3 rounded-md w-full flex flex-col gap-4">
                          <div className="grid lg:grid-cols-2 gap-4">
                            <Dropwdown
                              title={selectedLanguage?.name == "English" ? "Time" : translation[1728]?.value ? translation[1728]?.value : "Time"}
                              placeholder={selectedLanguage?.name == "English" ? "Select Time" : translation[130]?.value ? translation[130]?.value : "Select Time"}
                              required={true}
                              name={"date_time"}
                              value={inp_data?.date_time}
                              onChange={(e) => {
                                let sel_data = {
                                  ...inp_data,
                                  [e.target.name]: e.target.value,
                                };
                                setInpData(sel_data);
                                if (e.target.value && inp_data.service) {
                                  addNewAppointment(sel_data);
                                }
                              }}
                              options={SORTED_TIME}
                            />
                            <div>
                              <p className="mb-2 text-sm">
                                {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}
                                <span class="text-red-600"> *</span></p>


                              {dropdown == "False" ? (

                                <div
                                  className="cursor-pointer inputdesign overflow-hidden overflow-x-auto lg:max-h-9  "
                                  onClick={() => {

                                    setSelectServices(!SelectServices);
                                  }}
                                >
                                  <p className={"!text-black placeholder"}>
                                    {select ?
                                      select :
                                      selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"
                                    }
                                  </p>
                                </div>
                              ) : (
                                <>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {(createAppErr && createAppErr?.selectedClient == selectedId && appointmentData?.appointments?.length == 0) && <p className="text-sm text-red-600 text-right">{createAppErr?.error}</p>}
                {confirm &&
                  <div className="flex gap-6">
                    <div className="relative">
                      <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                        <div className="h-[1.8rem] w-[1.8rem] rounded-full bg-transparent flex items-center justify-center"></div>
                      </div>
                      <div className="absolute h-[50%] w-[2px] border-dashed z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                    </div>
                    <div className="rounded-md border appointment-shadow w-full px-4 py-3 flex flex-col gap-4 bg-white">
                      <div className="flex items-center gap-4">
                        <div>
                          <Svgs.Instructions />
                        </div>
                        <div className="flex flex-col gap-1">
                          <h2 className="font-bold text-primary text-lg">
                            {selectedLanguage?.name == "English" ? "Appointment Notes" : translation[1557]?.value ? translation[1557]?.value : "Appointment Notes"}
                          </h2>
                        </div>
                      </div>
                      <div className="flex flex-col gap-1">
                        <Textarea
                          value={notes_inp}
                          rows={'5'}
                          name={"notes_inp"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter notes" : translation[1606]?.value ? translation[1606]?.value : "Enter notes"}
                          onChange={handleAppointmentNoteChange}
                          // onBlur={() => onBlurHandler({ notes_inp: notes_inp })}
                          error={errors.notes_inp}
                        />
                      </div>
                    </div>
                  </div>
                }
                {confirm &&
                  <div className="flex justify-end">
                    {selectedId === allSelectedClients[allSelectedClients.length - 1]?.id ?
                      <LoginBtn
                        text={"Create Appointment"}
                        // disabled={createAppButtonDisable}
                        disabled={btn_loading}
                        loading={btn_loading}
                        onClick={() => {
                          addCombinedData((data) => { handle_submit(data) })
                        }}
                      />
                      :
                      <LoginBtn
                        text={"Next"}
                        // disabled={btn_loading ||
                        //   Object.keys(appointmentData).length == 0 ||
                        //   appointmentData?.appointments?.length == 0 ||
                        //   !appointmentData.appointment_date ||
                        //   hours_err?.length != 0 ||
                        //   future_err.length != 0 ||
                        //   appointmentData.appointments?.find(
                        //     (itm) =>
                        //       !itm.date_time ||
                        //       !itm.service ||
                        //       !itm.member ||
                        //       !itm.duration
                        //   ) ||
                        //   !get_required_amount_validation()
                        //   ||
                        //   (appointmentData?.is_client_compulsary && Object.keys(state?.appointment?.selected_client).length == 0)
                        //   ? true
                        //   : false
                        // }
                        onClick={() => {
                          setSubmitButtonClick(true)
                          if (appointmentData.appointments?.length == 0) {
                            setCreateAppErr({ error: `${selectedLanguage?.name == "English" ? "Add atleast one appointment service" : translation[1722]?.value ? translation[1722]?.value : "Add atleast one appointment service"}`, selectedClient: selectedId })
                          } else {
                            if (hours_err?.length == 0 && future_err.length == 0 && appointmentData.appointments?.every(
                              (itm) =>
                                itm.date_time &&
                                itm.service &&
                                itm.member &&
                                itm.duration
                            )) {
                              addCombinedData()
                              setAnotherClientData()
                              setCreateAppErr({})
                              setSubmitButtonClick(false)
                            }
                          }
                        }}
                      />
                    }
                  </div>
                }
              </div>
              {
                (<CheckBoxClientSection
                  setAllSelectedClient={setAllSelectedClient}
                  deletedClientID={deletedClientID}
                  selected_client={selected_client}
                  requiredLength={selectClientInputNumber}
                  setConfirmClient={setConfirmClient}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  confirm={confirm}
                  combinedAppointment={combinedAppointment}
                  setCombinedAppointment={setCombinedAppointment}
                  setAppointmentData={setAppointmentData}
                  initialState={initialState}
                  appointmentData={appointmentData}
                  setSelectedClientIdIndex={setSelectedClientIdIndex}
                  selectedClientIdIndex={selectedClientIdIndex}
                  setNoPromotionAvailed={setNoPromotionAvailed}
                  setConfirm={setConfirm}
                  setSubmitButtonClick={setSubmitButtonClick}
                  setCreateAppErr={setCreateAppErr}
                  hours_err={hours_err}
                  future_err={future_err}
                />
                )
              }
            </div>
          </div>
        }
      </div>
      {/* {AddNotes && (
        <Popup
          size="md"
          heading={selectedLanguage?.name == "English" ? "Appointment Notes" : translation[131]?.value ? translation[131]?.value : "Appointment Notes"}
          open={AddNotes}
          close={setAddNotes}
        >
          <div className="flex flex-col gap-1">
            <h1 className="text-primary text-sm">
              {selectedLanguage?.name == "English" ? "Add Details" : translation[133]?.value ? translation[133]?.value : "Add Details"}
            </h1>
            <Textarea
              // onChange={(e) => {
              //   setNotesInp(e.target.value);
              // }}
              value={notes_inp}
              name={"notes_inp"}
              onChange={handleAppointmentNoteChange}
              onBlur={() => onBlurHandler({ notes_inp: notes_inp })}
              error={errors.notes_inp}
            />
            <div className="flex justify-end">
              <LoginBtn
                // disabled={!notes_inp}
                text={selectedLanguage?.name == "English" ? "Add Note" : translation[134]?.value ? translation[134]?.value : "Add Note"}
                onClick={() => {
                  setAppointmentData({
                    ...appointmentData,
                    appointment_notes: notes_inp
                  });
                  setAddNotes(false);
                }}
              />
            </div>
          </div>
        </Popup>
      )
      } */}

      {
        <Popup
          no
          size="md"
          heading={selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"}
          open={SelectServices}
          close={() => setSelectServices(false)}
        >
          <div className="flex flex-col gap-4 max-h-[40vh]">
            <LoginInput
              title=""
              placeholder={selectedLanguage?.name == "English" ? "Search Service" : translation[1266]?.value ? translation[1266]?.value : "Search Service"}
              padding="py-1"
              leftIcon={<Svgs.Search />}
              value={search}
              onChange={searchfunction}
            />
            {select_service_serviceGroups?.length > 0 ? (
              <>
                {select_service_serviceGroups?.map((srv) => {
                  return (
                    <div className="flex flex-col gap-2">
                      <h1 className="font-semibold text-primary">
                        {srv?.name ? srv?.name : ""}
                      </h1>
                      {srv?.services?.length > 0 && srv?.services?.map((itm) => {
                        const filteredServices = itm?.priceservice?.filter(itm => itm?.currency_name?.toLowerCase() == location_currency?.toLowerCase())
                        return (
                          <>
                            {filteredServices?.map((itm_srv) => {
                              let itmSrv = { ...itm_srv }
                              let discount_priceallow = {}
                              if (!noPromotionAvailed) {
                                discount_priceallow = { discount_price: itm_srv.discount_price, }
                              } else {
                                delete itmSrv?.discount_price
                                discount_priceallow = {}
                              }
                              return (
                                <div
                                  className="flex items-center justify-between gap-3 border-b py-2 px-1 hover:bg-gray-50 rounded-md cursor-pointer"
                                  onClick={() => {
                                    handleServices(itm?.id, {
                                      srv_name: itm?.name,
                                      price: itmSrv?.discount_price || itmSrv?.discount_price == 0 ? itmSrv?.discount_price : itmSrv.price,
                                      srv_duration: itmSrv?.duration
                                        .toLowerCase()
                                        .replaceAll("_", " "),
                                    });
                                    let sel_data = {
                                      ...inp_data,
                                      ...discount_priceallow,
                                      service: itm?.id,
                                      client_can_book: itm?.client_can_book,
                                      slot_availible_for_online: itm?.slot_availible_for_online,
                                      duration: itmSrv?.duration.toLowerCase().replaceAll("_", " "),
                                      srv_name: itm?.name,
                                      price: itmSrv.price,

                                      offer: appointmentData?.avail_offer[0],
                                      srv_duration: itmSrv?.duration
                                        .toLowerCase()
                                        .replaceAll("_", " "),
                                    };
                                    setInpData(sel_data);
                                    if (itm?.id && inp_data.date_time) {
                                      addNewAppointment(sel_data);
                                    }
                                    setSelectServices(!SelectServices);
                                  }}
                                >
                                  <div className="">
                                    <h1 className="text-primary">
                                      {itm?.name}
                                    </h1>
                                    <p className="text-xs text-[#949494]">
                                      {itmSrv?.duration.replaceAll("_", " ")}
                                    </p>
                                  </div>
                                  <div className="d-flex flex-col text-end">
                                    <p className="text-primary text-sm font-semibold">
                                      {selectedCurrency ? selectedCurrency : ""}{" "}
                                      {itmSrv?.price?.toFixed(2)}
                                    </p>
                                    {(itmSrv?.discount_price || itmSrv?.discount_price == 0) &&
                                      <p className="text-xs text-[#949494]">
                                        {selectedLanguage?.name == "English" ? "Discount Price" : translation[245]?.value ? translation[245]?.value : "Discount Price"} :
                                        {selectedCurrency ? selectedCurrency : ""}{" "}
                                        {itmSrv?.discount_price?.toFixed(2)}
                                      </p>
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex flex-col gap-4 items-center text-center">
                <p className="text-sm text-[#000]/40">
                  {selectedLanguage?.name == "English" ? "You have not added any services yet." : translation[246]?.value ? translation[246]?.value : "You have not added any services yet."}
                  <br className="hidden md:block" />
                  {selectedLanguage?.name == "English" ? "Click on the button below to add new services." : translation[247]?.value ? translation[247]?.value : "Click on the button below to add new services."}

                </p>
                <div>
                  <AddService
                    formRef={formRef}
                    setServiceGroup={setServiceGroup}
                    setSelectServices={setSelectServices}
                    ServiceType={ServiceType}
                    setServiceType={setServiceType}
                    serviceData={serviceData}
                    isEdit={isEdit}
                    setService={setService}
                    serviceHandleChange={serviceHandleChange}
                    onSubmitService={onSubmitService}
                    active={active}
                    onBlurHandler={onBlurHandler}
                    errors={errors}
                    removedSelectedEmployee={removedSelectedEmployee}
                    serviceModal={serviceModal}
                    setAddService={setAddService}
                    durationOptions={durationOptions}
                    setIsEdit={setIsEdit}
                    setErrors={setErrors}
                    setIsDelete={setIsDelete}
                    loading={loading}

                  />
                </div>
              </div>
            )}
          </div>
        </Popup>
      }
      {
        availOfferPopup && (
          <DiscountPopup
            MODULE_TYPE='APPOINTMENT'
            SetData={(data, extra_data) => {
              setAppointmentData({
                ...data,
                ...extra_data
              })
            }}
            mainData={appointmentData}
            select_service_serviceGroups={select_service_serviceGroups}
            setSelServGroups={setSelServGroups}

            selectedLanguage={selectedLanguage}
            translation={translation}

            availOfferPopup={availOfferPopup}
            setAvailOfferPopup={setAvailOfferPopup}
            filterOffer={filterOffer}
            appointmentData={appointmentData}
            selected={appointmentData.avail_offer[0]}
            serviceGroupRedux={serviceGroupRedux}
            servicesRedux={servicesRedux}
            clientRedux={clientRedux}
          />
        )
      }

      {
        ServiceGroup && (
          <AddServiceGroup
            onClose={() => {
              setServiceGroup(false);
            }}
            open={ServiceGroup}
            update={false}
            addSuccess={() => {
              dispatch(getServiceGroupCalendar(selected_location, setGroupLoader));
            }}
          />
        )
      }


      {
        clientInputPopup && (
          <Popup
            size={"sm"}
            customPadding={"!px-[2.5rem]"}
            heading={selectedLanguage?.name == "English" ? "Group Member Count" : translation[1567]?.value ? translation[1567]?.value : "Group Member Count"}
            open={clientInputPopup}
            close={() => {
              setClientInputPopup(!clientInputPopup)
              navigate(-1)
            }}
          // removeClose={true}
          >
            <div className="space-y-8">
              <div className="flex items-center gap-3">
                <CircleIcon children={<Svgs.UsersGroup />} />
                <div className="flex flex-col gap-1">
                  <div>
                    <h1 className="font-semibold text-xl normal-case">
                      {selectedLanguage?.name == "English" ? "Select number of members" : translation[1568]?.value ? translation[1568]?.value : "Select number of members"}
                    </h1>
                  </div>
                  <div>
                    <p className="text-sm text-[#7B7B7B]">
                      {selectedLanguage?.name == "English" ? "Fill in the following information to group appointment" : translation[1368]?.value ? translation[1368]?.value : "Fill in the following information to group appointment"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center w-full">
                <LoginInput
                  type={'number'}
                  onlyNumericAllowed={true}
                  name={"memberInput"}
                  min={2}
                  max={10}
                  error={memberInputErrorMessage}
                  charactersMaxLength={2}
                  value={selectClientInputNumber}
                  onChange={handleMemberInputValue}
                  title="Number of members"
                  placeholder="Enter group members count"
                  parentClass={"w-full"}

                />
              </div>
              <div className="flex items-center justify-end w-full">
                <LoginBtn
                  disabled={!selectClientInputNumber ? true : false}
                  text={selectedLanguage?.name == "English" ? "create" : translation[1573]?.value ? translation[1573]?.value : "create"}
                  onClick={() => {
                    handleClientInput()
                  }}
                />
              </div>
            </div>
          </Popup>
        )
      }
    </>
  );
};

export default AddGroupAppointment;
