/* eslint-disable no-lone-blocks */
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

import LocationSelection from "new-components/location";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import NotFound from "Components/Utility/NotFound";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Svgs from "Assets/svgs";

import { InventoryReport, useReports } from "./helper";
import Consumed from "./InventoryReport/Consumed";
import Sold from "./InventoryReport/Sold";
import Purchase from "./InventoryReport/Purchase";
import TransferFrom from "./InventoryReport/TransferFrom";
import TransferTo from "./InventoryReport/TransferTo";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import moment from "moment";
import NoPurchaseOrderDataFound from "Components/Utility/NoPurchaseOrderDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";

const Reports = () => {
  const { text, toggle, state, ShowDetails, filterData, filterBrand, filterReportType, brands, productReportsExport,
    ProductStockNestedReportsExport, NestedProductReportsHeader, isLoading, filter_product_stock_report, navigate,
    setToggle, setFilterData, setShowDetails, setFilterReportType, setFilterBrand, product_stock, translation,
    selectedLanguage, currentPage, setCurrentPage, PageSize, pages, allCount, loadingPermission,
    brandSearchText, setBrandSearchText, brandCurrentPage, setBrandCurrentPage, brandLoader, brandPaginationData,
  } = useReports();

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/inventory-operations");
            }}
          >
            {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
          </span>{" "}
          &gt; <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
            </h2>
            <LocationSelection />
          </div>
        </div>

        <div className="border rounded-lg bg-white">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col  gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 lg:gap-1 gap-2">
              <div className="flex items-center lg:gap-4 gap-3">
                <div
                  className={`py-2 rounded-2xl text-xs cursor-pointer`}
                  onClick={() => {
                    setToggle("Stock");
                  }}
                >
                  <h1 className="text-primary font-semibold sm:text-xl whitespace-nowrap text-lg ">
                    {selectedLanguage?.name == "English" ? "Product Cycle Reports" : translation[1119]?.value ? translation[1119]?.value : "Product Cycle Reports"}
                  </h1>
                </div>
                {!state?.product?.products_stock_updated || isLoading ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-36 sm:w-40 lg:!w-44'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  :
                  <LoginInput
                    title=""
                    name={"text"}
                    value={text}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        let new_data = {
                          ...filterData,
                        };
                        delete new_data["text"];
                        setFilterData(new_data);
                      } else {
                        setFilterData({ ...filterData, text: e.target.value });
                      }
                    }}
                    placeholder={selectedLanguage?.name == "English" ? "Search product" : translation[1265]?.value ? translation[1265]?.value : "Search product"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-36'} classOn2={'!w-32 lg:!w-36'} />
              :
              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                <Dropwdown
                  title=''
                  placeholder={selectedLanguage?.name == "English" ? "Report Type" : translation[1120]?.value ? translation[1120]?.value : "Report Type"}
                  options={[
                    { label: selectedLanguage?.name == "English" ? "All Report Types" : translation[1122]?.value ? translation[1122]?.value : "All Report Types", value: '' },
                    { label: InventoryReport.purchase, value: InventoryReport.purchase },
                    { label: InventoryReport.consumed, value: InventoryReport.consumed },
                    { label: InventoryReport.transferFromHeading, value: InventoryReport.transferFrom },
                    { label: InventoryReport.transferToHeading, value: InventoryReport.transferTo },
                    { label: InventoryReport.sold, value: InventoryReport.sold }
                  ]}
                  value={filterData.report_type}
                  onChange={(e) => { setFilterData({ ...filterData, report_type: e.target.value }) }}
                >
                  {selectedLanguage?.name == "English" ? "Report Type" : translation[1120]?.value ? translation[1120]?.value : "Report Type"}
                </Dropwdown>
                <PaginationDropdown
                  title=''
                  placeholder={selectedLanguage?.name == "English" ? "All Brand" : translation[398]?.value ? translation[398]?.value : "All Brand"}
                  options={brands}
                  value={filterData.brand_id}
                  onChange={(e) => { setFilterData({ ...filterData, brand_id: e.target.value }) }}

                  setPage={setBrandCurrentPage}
                  setDropdownSearch={setBrandSearchText}
                  page={brandCurrentPage}
                  dropdownSearch={brandSearchText}
                  lastPage={brandPaginationData?.total_pages}
                  currentPage={brandPaginationData?.currentPage}
                  loading={brandLoader}
                >
                  {selectedLanguage?.name == "English" ? "All Brand" : translation[398]?.value ? translation[398]?.value : "All Brand"}
                </PaginationDropdown>
                {toggle === "Stock" ? (
                  <>

                  </>
                ) : (
                  filter_product_stock_report()?.length > 0 ?
                    <CSVLink
                      {...productReportsExport}
                      target="_blank"
                      onClick={() => {
                        setTimeout(() => {
                          toast.success("File downloaded successfully", { toastId: "toast" });
                        }, 300);
                      }}
                    >
                      <LoginBtn
                        className="rounded-lg !py-[7px]"
                        bg="bg-white"
                        animation={false}
                        customThemeBtn={'theme-btn2'}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.ExportSvg />
                        </div>
                      </LoginBtn>
                    </CSVLink> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={() => {
                        toast.error("No data to export")
                      }}
                    >
                      <div className="flex items-center gap-2 text-[#101928]">
                        <Svgs.Export bg="#101928" />
                      </div>
                    </LoginBtn>
                )}
              </div>
            }
          </div>
          {toggle === "Stock" && (
            !state?.product?.products_stock_updated || isLoading ? (
              <TableShimer cols={5} rows={12} colsHeight={10} />
            ) :
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                  <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
                  {/* <p>Type</p> */}
                  <p>{selectedLanguage?.name == "English" ? "Current Stock" : translation[998]?.value ? translation[998]?.value : "Current Stock"}</p>
                  {/* <p>Cost Price</p> */}
                  <p>{selectedLanguage?.name == "English" ? "Retail Price" : translation[294]?.value ? translation[294]?.value : "Retail Price"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                </div>
                {product_stock?.length > 0
                  ? (
                    product_stock?.map((product_obj, i) => {
                      let SingleProductStockNestedReportsExport = {
                        filename: "Product Cycle Report.csv",
                        headers: NestedProductReportsHeader,
                        data: product_obj?.reports?.map((report_obj) => {
                          return {
                            ...report_obj,
                            type: report_obj?.report_choice?.replace('_', ' '),
                            stock_before: report_obj?.before_quantity ? report_obj?.before_quantity : '------',
                            sold_stock: (report_obj?.report_choice == 'Sold' && report_obj?.before_quantity && report_obj?.after_quantity) ? (report_obj?.before_quantity - report_obj?.after_quantity) : '------',
                            location: `${report_obj?.report_choice == 'Sold' ? report_obj?.location?.address_name : report_obj?.report_choice == 'Consumed' ? report_obj?.consumed_location?.address_name : (report_obj.report_choice == 'Transfer_to') ? report_obj?.to_location?.address_name : report_obj.report_choice == 'Transfer_from' ? report_obj?.from_location?.address_name : '------'}`,
                            updated_stock: report_obj?.after_quantity ? report_obj?.after_quantity : '------',
                            consumed_amount: report_obj?.quantity ? report_obj?.quantity : '------',
                            vendor: report_obj?.vendor_name ? report_obj?.vendor_name : '------',
                            po_id: report_obj?.report_choice == 'Purchase' ? report_obj?.id?.split("-", 1) : '------',
                            reorder_amount: report_obj?.reorder_quantity ? report_obj?.reorder_quantity : '------',
                            received_amount: report_obj?.report_choice != 'Consumed' ? report_obj?.quantity ? report_obj?.quantity : "------" : '------',
                            date: report_obj?.created_at,
                          };
                        }),
                      };
                      return (
                        <>
                          <div className={`px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))] min-w-full w-fit odd-bg text-[#3A3A3A] transition-all ${ShowDetails
                            ? "border-l-[4px] border-[#5679FFB2]"
                            : "border-l-[0px]"
                            }`}>
                            <div
                              className={`before-left-arrow cursor-pointer ${ShowDetails == product_obj.id
                                ? "before-left-arrow-open"
                                : "before-left-arrow-close"
                                }`}
                              onClick={() => {
                                // setShowDetails(product_obj.id);
                                if (ShowDetails === product_obj.id) {
                                  setShowDetails(null); // Close the report
                                } else {
                                  setShowDetails(product_obj.id); // Open the report
                                }
                              }}
                            >
                              <div className="h-full flex items-center">
                                <p className="text-sm">{product_obj?.name} </p>
                              </div>
                            </div>

                            <div className="flex items-center">
                              <p className="text-sm line-clamp-1 pr-3">
                                {product_obj?.current_stock}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <p className="text-sm">
                                {(product_obj?.retail_price && product_obj?.retail_price?.retail_price) ? `${product_obj?.retail_price?.retail_price} ${product_obj?.retail_price?.currency_code}` : '0'}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <p className="text-sm">
                                {product_obj?.brand}
                              </p>
                            </div>

                            <div className="flex items-center">
                              <p className="text-sm">
                                {moment(product_obj?.created_at).format('DD-MM-YYYY')}
                              </p>
                            </div>

                            {ShowDetails == product_obj.id ? (
                              <div className="overflow-x-auto bg-[#FAF9FF] col-span-5 mt-3">
                                {
                                  product_obj?.reports?.slice(0, 10)?.map((report_obj) => {
                                    return (
                                      <>
                                        <div key={report_obj.id} className="">
                                          {
                                            report_obj.report_choice == 'Sold' &&
                                            <>
                                              <Sold report_obj={report_obj} selectedLanguage={selectedLanguage} translation={translation} />
                                            </>
                                          }
                                          {
                                            report_obj.report_choice == 'Purchase' &&
                                            <>
                                              <Purchase report_obj={report_obj} selectedLanguage={selectedLanguage} translation={translation} />
                                            </>
                                          }
                                          {
                                            report_obj.report_choice == 'Consumed' &&
                                            <>
                                              <Consumed report_obj={report_obj} i={i} selectedLanguage={selectedLanguage} translation={translation} />
                                            </>
                                          }
                                          {
                                            report_obj.report_choice == 'Transfer_to' &&
                                            <>
                                              <TransferTo report_obj={report_obj} i={i} selectedLanguage={selectedLanguage} translation={translation} />
                                            </>
                                          }
                                          {
                                            report_obj.report_choice == 'Transfer_from' &&
                                            <>
                                              <TransferFrom report_obj={report_obj} i={i} selectedLanguage={selectedLanguage} translation={translation} />
                                            </>
                                          }
                                        </div>
                                      </>
                                    )
                                  })
                                }
                                <>
                                  {product_obj?.reports?.length > 0 ?
                                    <CSVLink
                                      {...SingleProductStockNestedReportsExport}
                                      target="_blank"
                                      onClick={() => {
                                        setTimeout(() => {
                                          toast.success("File downloaded successfully", { toastId: "toast" });
                                        }, 300);
                                      }}
                                    >
                                      <p className="text-center font-bold cursor-pointer text-lg mb-3 text-[#5679ff]">
                                        {selectedLanguage?.name == "English" ? "Download" : translation[1329]?.value ? translation[1329]?.value : "Download"}
                                      </p>
                                    </CSVLink>
                                    :
                                    <p className="text-center font-bold cursor-pointer text-lg mb-3 text-[#5679ff]" onClick={() => {
                                      toast.error("No data to export", { toastId: "toast" })
                                    }}>
                                      {selectedLanguage?.name == "English" ? "Download" : translation[1329]?.value ? translation[1329]?.value : "Download"}
                                    </p>}
                                </>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )
                    })
                  ) : (
                    <NoPurchaseOrderDataFound />
                  )}

                {(product_stock?.length > 0 && !isLoading) &&
                  <Pagination
                    currentPage={currentPage}
                    totalCount={Math.ceil(pages)}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
              </div>
          )}

          {/* <Consumed data={item} i={i} />
<Sold data={product_obj} i={i} />
<Purchase data={item} i={i} />
<TransferFrom data={item} i={i} />
<TransferTo data={item} i={i} /> */}

          {/* switch (data?.reports?.map(itmm => itmm?.report_choice)) {
                          case InventoryReport.consumed:
                            return (
                              <Consumed ShowDetails={ShowDetails} setShowDetails={setShowDetails} data={data} i={i} />
                            )
                          case InventoryReport.sold:
                            return (
                              <Sold ShowDetails={ShowDetails} setShowDetails={setShowDetails} data={data} i={i} />
                            );
                          case InventoryReport.purchase:
                            return (
                              <Purchase ShowDetails={ShowDetails} setShowDetails={setShowDetails} data={data} i={i} />
                            );
                          case InventoryReport.transferFrom:
                            return (
                              <TransferFrom ShowDetails={ShowDetails} setShowDetails={setShowDetails} data={data} i={i} />
                            );
                          case InventoryReport.transferTo:
                            return (
                              <TransferTo ShowDetails={ShowDetails} setShowDetails={setShowDetails} data={data} i={i} />
                            );
                          default:
                            return <></>
                        } */}
        </div>
      </div>
    </>
  );
};

export default Reports;
