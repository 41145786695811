import Popup from 'Components/TenantApp/Elements/Popup'
import { AccountSetupHelper } from './Steps/helper';
import FormSteps from 'Components/TenantApp/Elements/FormSteps';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Svgs from 'Assets/svgs';

const AccountSetupPopup = () => {
    const { Open, setOpen, Step, setStep, data, btn_loading, isButtonDisabled, handleDataSubmit } = AccountSetupHelper();
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[4444]">
            <Popup open={Open} close={setOpen} removeClose={true} heading={'Setup Your Account'}>
                <FormSteps
                    currentStep={Step}
                    setStep={setStep}
                    active={Step}
                    steps={[
                        "Location",
                        "Services",
                        "Employee",
                        "Clients"
                    ]}
                    stepsLength="4"
                />
                {
                    data[Step]
                }
                <div className='flex items-center justify-between gap-3'>
                    {
                        Step != 1 && <LoginBtn onClick={() => {
                            setStep(Step > 1 ? Step - 1 : 1)
                            document.querySelector('.topscroll').scrollIntoView();
                        }}>
                            <div className="flex items-center gap-2">
                                <div className="rotate-180">
                                    <Svgs.NextArrow />
                                </div>
                                <div className="">
                                    Back
                                </div>
                            </div>
                        </LoginBtn>
                    }

                    <LoginBtn
                        loading={btn_loading}
                        disabled={btn_loading}
                        // disabled={btn_loading || isButtonDisabled}
                        onClick={handleDataSubmit}
                        text={`${Step != 4 ? 'Next' : 'Submit'}`}>
                        {Step != 4 ? <div className='h-fit pl-1.5'><Svgs.NextArrow /></div> : ""}
                    </LoginBtn>
                </div>
            </Popup>
        </div>
    )
}

export default AccountSetupPopup