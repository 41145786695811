import { useEffect, useState } from 'react'
import { UpdateAppointmentServiceStatus, getAppointmentGroupServices, getAppointmentLog, requestAppointmentServiceReversal } from 'Adapters/Api/apointments';
import { apiErrorMessage } from 'Constants/Data/Errors';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { GET_APPOINTMENT_DETAIL } from 'Redux/TenantRedux/ActionTypes/Appointment/Appointment';
import useValidations from 'Hooks/useValidations';
import useObjectKeys from 'Hooks/useObjectKeys';

function useGroupAppointmentServiceDetails(app_group_id) {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const selectedLocationCurrency = getDataFromLocalStorage("selectedLocationCurrency");

    const [appointmentServices, setAppointmentService] = useState([])
    const [appointmentNotes, setAppointmentNotes] = useState('')
    const [groupCheckInTime, setGroupCheckInTime] = useState('')
    const [appointmentLog, setAppointmentLog] = useState([])
    const [fetchLogs, setFetchLogs] = useState(false)
    const [loader, setLoader] = useState(true)
    const [buttonLoader, setButtonLoader] = useState(true)
    const [voidPopup, setVoidPopup] = useState(false)
    const [clientDetails, setClientDetails] = useState({
        client_id: '',
        isOpen: false
    })
    const [requestReversalPopup, setRequestReversalPopup] = useState(false)
    const [callCalendarApi, setCallCalendarApi] = useState(false);
    const [reversalLoader, setReversalLoader] = useState(false)
    const [requestReversalNote, setRequestReversalNote] = useState({
        description: "",
        appointment_id: "",
        appointment_service: "",
        existingStatus: ""
    })
    const [statusLoader, setStatusLoader] = useState({
        Void: false,
        Started: false,
        Finished: false,
    });
    const [filteredClientsAppointments, setFilteredClientsAppointments] = useState([])
    const [payNowBtnDisabled, setPayNowBtnDisabled] = useState(true)
    const [totalCartPrice, setTotalCartPrice] = useState(0)
    const [cartTotal, setCartTotal] = useState(0)
    const [cartRemainingPrice, setCartRemainingPrice] = useState(0)
    const [cartChange, setCartChange] = useState(0)

    useEffect(() => {
        getAppointmentServicesApi()
    }, [app_group_id]);

    useEffect(() => {
        // filtered those appointments whose status is paid or cancel
        const filteredStatusAppointments = appointmentServices?.filter(itm => itm?.status !== "Cancelled" && itm?.status !== "Done");
        // filtered services whose status is void
        let filteredServices = filteredStatusAppointments?.map(appointment => {
            const nonVoidServices = appointment.appointmnet_service?.filter(service => service?.status !== "Void");
            const totalPrice = nonVoidServices?.reduce((accumulator, curValue) => accumulator + curValue?.price, 0);
            return {
                ...appointment,
                appointmnet_service: nonVoidServices?.map((itm) => {
                    let data = {
                        id: itm?.id,
                        service: itm?.service,
                        price: itm?.price,
                        name: itm?.srv_name ? itm?.srv_name : itm?.name,
                        avaliable_service_group: itm?.avaliable_service_group,
                        employee: itm?.member ? itm?.member : itm?.employee,
                        service_start_time: itm?.service_start_time,
                        service_end_time: itm?.service_end_time,
                        discount_price: itm?.discount_price,
                        discount_percentage: itm?.discount_percentage
                    }
                    if (itm?.is_coupon_redeemed) {
                        data = { ...data, is_coupon_redeemed: itm?.is_coupon_redeemed }
                    }
                    return data
                }),
                total_price: totalPrice
            };
        });
        filteredServices = filteredServices?.filter(itm => itm?.appointmnet_service?.length > 0)
        setFilteredClientsAppointments(filteredServices)

        // check if all appointments has status finished
        const allAppointmentshasStatusFinished = filteredStatusAppointments?.every(appointment => appointment?.status === 'Finished');
        if (allAppointmentshasStatusFinished) {
            setPayNowBtnDisabled(false)
        } else {
            setPayNowBtnDisabled(true)
        }

        // calculating total cart price
        const totalPrice = filteredServices?.reduce((accumulator, appointment) => accumulator + appointment?.total_price, 0);
        setTotalCartPrice(totalPrice)
    }, [appointmentServices])

    const getAppointmentServicesApi = () => {
        setButtonLoader(true)
        dispatch(getAppointmentGroupServices(
            app_group_id,
            (response) => {
                setAppointmentService(response?.appointment);
                setAppointmentNotes(response?.group_notes);
                setGroupCheckInTime(response?.group_check_in_time);
                dispatch({
                    type: GET_APPOINTMENT_DETAIL,
                    payload: { ...response?.appointment, total_sales: response?.total_sales },
                });
                setLoader(false)
                setButtonLoader(false)
            },
            () => {
                toast.error(apiErrorMessage, { toastId: "toast" })
                setButtonLoader(false)
            },

        ));
    }

    const handleAppointmentLog = (appointment_id) => {
        setFetchLogs(true)
        dispatch(getAppointmentLog(
            selected_location,
            appointment_id,
            access_token,
            (response) => {
                setFetchLogs(false)
                setAppointmentLog(response?.appointment_logs)
            },
            (result) => {
                setFetchLogs(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
            }))
    }

    const updateAppointmentServiceStatus = (srvId, appointmentId, status) => {
        setCallCalendarApi(true)
        setStatusLoader(() => ({
            id: srvId,
            [status]: true,
        }));
        UpdateAppointmentServiceStatus(
            {
                appointment_service_id: srvId, appointment_id: appointmentId, status: status
            },
            (response) => {
                setStatusLoader(() => ({
                    id: srvId,
                    [status]: false,
                }));
                if (status === "Void") {
                    setVoidPopup(!voidPopup)
                }
                getAppointmentServicesApi()
            },
            (result) => {
                setStatusLoader(() => ({
                    id: srvId,
                    [status]: false,
                }));
                toast.error(apiErrorMessage, { toastId: "toast" })
            })
    }

    const handleReversalRequest = () => {
        let requiredField = { reversalNote: requestReversalNote?.description }
        setErrors(validation(requiredField));
        const result = validation(requiredField);
        const filteredAppointment = appointmentServices?.find(itm => itm?.id == requestReversalNote?.appointment_id)
        const filteredService = filteredAppointment?.appointmnet_service?.find(itm => itm?.id == requestReversalNote?.appointment_service)
        if (objectKeyConvertToArray(result)?.length === 0) {
            setReversalLoader(true)
            // Get the current URL pathname
            const pathname = window.location.href;
            // Find the index of "/dashboard" in the pathname
            const dashboardIndex = pathname.indexOf('/dashboard');
            // Extract the part of the pathname before "/dashboard"
            const desiredPath = dashboardIndex !== -1 ? pathname.substring(0, dashboardIndex) : pathname;

            dispatch(requestAppointmentServiceReversal(
                {
                    ...requestReversalNote,
                    business: businessData?.id,
                    email: state?.user?.user?.email,
                    url: `${desiredPath}/dashboard/sales/appointments?active_tab=reversal-requests`,
                    generated_by: state?.user?.user?.username,
                    generated_by_id: state?.user?.user?.id,
                    appointment_date: filteredService?.appointment_date,
                    service_name: filteredService?.srv_name,
                    client_type: filteredAppointment?.client_type,
                    client_name: filteredAppointment?.client_name,
                    client_phone: filteredAppointment?.client_phone,
                    location_id: filteredService?.location?.id,
                },
                (response) => {
                    getAppointmentServicesApi()
                    toast.success(response?.response?.message, { toastId: "toast" })
                    setRequestReversalPopup(false)
                    setReversalLoader(false)
                    setRequestReversalNote({
                        description: "",
                        appointment_id: "",
                        appointment_service: ""
                    })
                },
                (result) => {
                    setReversalLoader(false)
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }))
        }
    }

    return {
        appointmentServices, appointmentLog, fetchLogs, handleAppointmentLog, updateAppointmentServiceStatus, statusLoader, voidPopup,
        setVoidPopup, clientDetails, setClientDetails, setAppointmentService, appointmentNotes, loader, callCalendarApi, setCallCalendarApi, requestReversalPopup,
        setRequestReversalPopup, requestReversalNote, setRequestReversalNote, handleReversalRequest, reversalLoader, setErrors, validation,
        errors, onBlurHandler, objectKeyConvertToArray, getAppointmentServicesApi, filteredClientsAppointments, payNowBtnDisabled,
        selected_location, totalCartPrice, setTotalCartPrice, cartTotal, setCartTotal, groupCheckInTime, cartRemainingPrice,
        setCartRemainingPrice, cartChange, setCartChange, buttonLoader, selectedLocationCurrency
    }
}

export default useGroupAppointmentServiceDetails