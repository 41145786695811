import React from "react";
import Svgs from "Assets/svgs";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import useLeave from "./helper";
import Pagination from "new-components/Pagination";
import moment from "moment";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import NoVacationRecordFound from "Components/TenantApp/Dashboard/Employee/Elements/NoVacationRecordFound";


const Leave = () => {
  const {
    ShowDetails, setShowDetails, searchText, setSearchText, translation, selectedLanguage, loader,
    filterStaff, setFilterStaff, loadingPermission, vacationList, pages, allCount, employeeListRedux,
    employeePaginationData, currentPage, setCurrentPage, PageSize, employeePage, setEmployeePage,
    employeeSearch, setEmployeeSearch, employeeLoader, handleUpdateVacationStatus, statusLoader
  } = useLeave()

  return (
    <div className="mt-[3rem]">
      <div className="border rounded-md bg-white">
        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
          <div className="flex items-center flex-wrap sm:flex-nowrap justify-between flex-1 w-full md:gap-0 lg:gap-1 gap-2">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Leaves" : translation[1487]?.value ? translation[1487]?.value : "Leaves"}
                </h2>

                {(loader) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
            </div>


            <div className="mb-00 grid grid-cols-2 sm:flex items-center gap-2 w-full sm:w-auto">
              <LoginInput
                title=""
                placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                padding="py-1"
                leftIcon={<Svgs.Search />}
                onChange={(e) => { setSearchText(e.target.value) }}
                value={searchText}
                name="firstName"
              />
              {loadingPermission ?
                <EmployeesHeadShimmer classOn2={'!w-12 lg:!16'} />
                :
                <div className="flex items-center lg:justify-center justify-end gap-3 w-full sm:w-fit ">
                  <PaginationDropdown
                    placeholder="All team Member"
                    title=""
                    name="filterStaff"
                    value={filterStaff}
                    onChange={(e) => {
                      setFilterStaff(e.target.value);
                    }}
                    className={"w-full"}
                    custom_min_width={'sm:min-w-[15rem] min-w-full'}
                    options={[
                      { label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: "" },
                      ...employeeListRedux?.map((empl, i) => {
                        return {
                          label: empl.full_name,
                          value: empl.id,
                          otherData: {
                            image: empl?.image,
                            DataOne: empl.email,
                            showImage: true,
                            index: i
                          },
                          email: empl.email,
                          mobile_number: empl.mobile_number
                        };
                      }),
                    ]}
                    setPage={setEmployeePage}
                    page={employeePage}
                    setDropdownSearch={setEmployeeSearch}
                    dropdownSearch={employeeSearch}
                    lastPage={employeePaginationData?.employeePages}
                    currentPage={employeePaginationData?.employeeCurrentPage}
                    loading={employeeLoader}
                  />
                </div>
              }
            </div>
          </div>

        </div>
        <div className="pb-3">
          {loader ?
            <TableShimmer_v2 cols={5} rowHeight={'h-14'} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                <p className="col-span-2">{selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name"}</p>
                <p>{selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date"}</p>
                <p>{selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date"}</p>
                <p>{selectedLanguage?.name == "English" ? "Vacation Type" : translation[1494]?.value ? translation[1494]?.value : "Vacation Type"}</p>
                <p>{selectedLanguage?.name == "English" ? "Number of Leaves" : translation[1539]?.value ? translation[1539]?.value : "Number of Leaves"}</p>
                <p className="text-center">{selectedLanguage?.name == "English" ? "Action" : translation[1366]?.value ? translation[1366]?.value : "Action"}</p>
              </div>
              <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
              >
                {vacationList?.length > 0 ? (
                  vacationList?.map((obj, i) => {
                    return (
                      <>
                        <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(115px,1fr))] min-w-full w-fit">
                          <div className={`before-left-arrow cursor-pointer col-span-2`}>
                            <div className={`text-sm font-semibold text-[#3A3A3A]" before-left-arrow cursor-pointer ${ShowDetails == i + 1
                              ? "before-left-arrow-open"
                              : "before-left-arrow-close"
                              }`}
                              onClick={() => {
                                setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                              }}>
                              <div className="flex items-center gap-3">
                                <div className="min-w-[3rem]">
                                  {obj?.employee?.image ? (
                                    <img
                                      alt=""
                                      src={obj?.employee?.image}
                                      className="h-[2.8125rem] w-[2.8125rem] object-cover rounded-full"
                                    />
                                  ) : (
                                    <LabelAvatar iconLabel={obj?.employee?.full_name} index={i} className={'!h-[2.8125rem] !w-[2.8125rem] !rounded-full'} />
                                  )}
                                </div>
                                <p className="text-sm line-clamp-2">{obj?.employee?.full_name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-2">
                              {obj?.from_date ? moment(obj?.from_date).format("D MMM, YYYY") : '--'}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-1 pr-3">
                              {obj?.to_date ? moment(obj?.to_date).format("D MMM, YYYY") : '--'}
                            </p>
                          </div>
                          <div className="flex items-center gap-5">
                            <p className="text-sm capitalize">
                              {obj?.vacation_type ? obj?.vacation_type : "----"}
                            </p>
                          </div>
                          <div className="flex items-center gap-5">
                            <p className="text-sm capitalize">
                              {obj?.vacation_details?.length}
                            </p>
                          </div>
                          <div className="flex items-center justify-center gap-3">
                            {
                              (obj?.vacation_status == 'pending' || obj.vacation_status == 'accepted') &&
                              <LoginBtn
                                className={'!capitalize'}
                                newSuccessBtnFocus={obj?.vacation_status == "accepted" ? true : false}
                                successBtn={obj?.vacation_status == "pending" ? true : false}


                                text={selectedLanguage?.name == "English" ? `${obj?.vacation_status == "pending" ? 'Accept' : 'Accepted'}` : translation[1492]?.value ? translation[1492]?.value : `${obj?.vacation_status == "pending" ? 'Accept' : 'Accepted'}`}
                                onClick={() => {
                                  handleUpdateVacationStatus(obj?.id, obj?.employee?.id, 'accepted', obj?.vacation_type, obj?.vacation_details?.length)
                                }}
                                disabled={(statusLoader && statusLoader?.id == obj?.id) ||
                                  obj?.vacation_status != "pending"}
                                loading={statusLoader && statusLoader?.accepted && statusLoader?.id == obj?.id}
                              />
                            }
                            {
                              (obj?.vacation_status == 'pending' || obj.vacation_status == 'declined') &&
                              <LoginBtn
                                className={'!capitalize'}
                                newDeleteBtnFocus={obj?.vacation_status == "declined" ? true : false}
                                deleteBtn={obj?.vacation_status == "pending" ? true : false}
                                text={selectedLanguage?.name == "English" ? `${obj?.vacation_status == "pending" ? 'Reject' : 'Rejected'}` : translation[1493]?.value ? translation[1493]?.value : `${obj?.vacation_status == "pending" ? 'Reject' : 'Rejected'}`}
                                onClick={() => {
                                  handleUpdateVacationStatus(obj?.id, obj?.employee?.id, 'declined', obj?.vacation_type, obj?.vacation_details?.length)
                                }}
                                disabled={(statusLoader && statusLoader?.id == obj?.id) ||
                                  obj?.vacation_status != "pending"}
                                loading={statusLoader && statusLoader?.declined && statusLoader?.id == obj?.id}
                              />
                            }

                          </div>
                        </div>
                        {
                          ShowDetails == i + 1 ? (
                            <div className="scale-in-center md:min-w-[50rem] min-w-[69rem]">
                              <>
                                <div className="bg-[#fff] border-t border-b border-[#E5E7EB] px-6 py-3 flex flex-col gap-5 w-full">
                                  {obj?.employee?.leave_data &&
                                    <div className="flex gap-3 items-center text-sm text-primary">
                                      <div className="rounded-xl flex flex-col justify-center items-center gap-3 bg-[#F2F5FF] py-3 px-5">
                                        <p>{selectedLanguage?.name == "English" ? "Casual Leaves" : translation[420]?.value ? translation[420]?.value : "Casual Leaves"}</p>
                                        <p className="font-semibold">{obj?.employee?.leave_data?.casual_leave}</p>
                                      </div>
                                      <div className="rounded-xl flex flex-col justify-center items-center gap-3 bg-[#F2F5FF] py-3 px-5">
                                        <p>{selectedLanguage?.name == "English" ? "Medical Leaves" : translation[420]?.value ? translation[420]?.value : "Medical Leaves"}</p>
                                        <p className="font-semibold">{obj?.employee?.leave_data?.medical_leave}</p>
                                      </div>
                                      <div className="rounded-xl flex flex-col justify-center items-center gap-3 bg-[#F2F5FF] py-3 px-5">
                                        <p>{selectedLanguage?.name == "English" ? "Annual Leaves" : translation[420]?.value ? translation[420]?.value : "Annual Leaves"}</p>
                                        <p className="font-semibold">{obj?.employee?.leave_data?.annual_leave}</p>
                                      </div>
                                    </div>
                                  }
                                  {obj?.note &&
                                    <div className="rounded-md bg-[#F9FAFC] border border-[#A1A1A1] py-3 px-3 text-sm">
                                      <p className="whitespace-pre-wrap">{obj?.note}</p>
                                    </div>
                                  }
                                </div>
                              </>
                            </div>
                          ) : (
                            ""
                          )
                        }
                      </>
                    );
                  })
                ) :
                  (
                    <NoVacationRecordFound className={"my-[3rem]"} />
                  )}
              </div>
            </div>
          }
        </div>
        {(vacationList?.length > 0 && !loader) &&
          <Pagination
            currentPage={currentPage}
            totalCount={Math.ceil(pages)}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        }
      </div>
    </div>
  );
};

export default Leave;
