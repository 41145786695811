import React, { useState, useEffect } from "react";
import moment from 'moment'
import LoginBtn from "../../../Elements/LoginBtn";
import ProgressBar from "../../../Elements/ProgressBar";
import useCustomerAnalytics from "./helper";
// import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Popup from "Components/TenantApp/Elements/Popup";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import Svgs from "Assets/svgs";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import SalesAnalyticsCards from "Components/TenantApp/Elements/SalesAnalyticsCards";
import AccordionDropdown from "Components/TenantApp/Elements/AccordionDropdown";
import RadioBtn from "Components/TenantApp/Elements/RadioBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LineChatCurve from "Components/TenantApp/Elements/Charts/LineChatCurve";
import LineChart from "Components/TenantApp/Elements/Charts/LineChart";
import Donet from "Components/TenantApp/Elements/Charts/Donet";
import BarChart from "Components/TenantApp/Elements/Charts/BarChart";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import { YearsDropdown } from "Constants/Data/Months";
import LocationSelection from "new-components/location";

const CustomerAnalytics = () => {

    const { translation, selectedLanguage, navigate, FilterDetails, setFilterDetails, toggleDateRange, setToggleDateRange, start_date, setStartDate, end_date, setEndDate,
        customerData, loader, onChangeHandler, DateFilter, selected_location, setSelected_location, reduxlocationsList,
        MonthsDropdown, location_currency, filterData,
        onDateRangeHandler, minEndDateLimit, dateRange,
        resetFilters, ApplyFilter, Filter, setFilter
    } = useCustomerAnalytics();

    const products = [
        {
            name: "Silk & Shine",
            value: 1508,
        },
        {
            name: "Cosmic Care",
            value: 888,
        },
        {
            name: "Shinewave",
            value: 854,
        },
        {
            name: "LightDew",
            value: 790,
        },
        {
            name: "Nail Care",
            value: 641,
        },
    ]

    const SearchedResult = [
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
    ];

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

            <div className="my-[1rem] flex items-end justify-between gap-4 flex-wrap">

                <div className="flex flex-col items-start gap-2 md:gap-4">
                    <Breadcrumb
                        first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                        second={selectedLanguage?.name == "English" ? "Client Analytics" : translation[1594]?.value ? translation[1594]?.value : "Client Analytics"}
                        url={'/dashboard/pos-analytics'}
                    />
                    <div className="flex items-center justify-between gap-4 flex-wrap">
                        <div className="flex items-center sm:gap-4 gap-2">

                            <h2 className="text-primary font-bold lg:text-3xl text-xl">
                                {selectedLanguage?.name == "English" ? "Client Analytics" : translation[1594]?.value ? translation[1594]?.value : "Client Analytics"}
                            </h2>
                            <LocationSelection />
                            {/* <Dropwdown
                                className={'w-[10rem] lg:w-[14rem]'}
                                showTitle={false}
                                name={"selected_location"}
                                value={selected_location}
                                onChange={(e) => { setSelected_location(e.target.value) }}
                                options={[
                                    { label: selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations", value: "" },
                                    ...reduxlocationsList?.map((adrs) => {
                                        return {
                                            label: adrs?.address_name,
                                            value: adrs?.id,
                                        };
                                    })]}
                                title={''}
                                placeholder={selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations"}
                            /> */}
                        </div>

                    </div>
                </div>
                <div className="flex items-center sm:gap-4 gap-2">
                    {/* <Dropwdown
                        className={'w-[7rem]'}
                        name="type"
                        title=""
                        showTitle={false}
                        placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                        options={[
                            { value: 'today', label: 'Today' },
                            { value: 'month', label: 'Monthly' },
                            { value: 'year', label: 'Yearly' },
                        ]}
                        // onBlur={() => onBlurHandler({ country_name: country_name.value })}
                        value={filterData?.type}
                        onChange={onChangeHandler}
                    // error={errors.country_name}
                    /> */}
                    {filterData?.type != 'today'
                        ? <>
                            <Dropwdown
                                placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                                className={'w-[7rem]'}
                                name="year"
                                title=""
                                showTitle={false}
                                options={YearsDropdown}
                                onChange={onChangeHandler}
                                value={filterData?.year}
                            />
                            {filterData?.type == 'month' &&
                                <Dropwdown
                                    placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                                    className={'w-[7rem]'}
                                    name="month"
                                    title=""
                                    showTitle={false}
                                    options={MonthsDropdown}
                                    onChange={onChangeHandler}
                                    value={filterData?.month}
                                />
                            }
                        </>
                        : ''}
                    <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={() => setFilter(!Filter)}
                    >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                            <Svgs.DateFilter fill={"#fff"} />
                        </div>
                    </LoginBtn>
                </div>
            </div>

            <div className="border-t py-5 md:py-10 flex flex-col gap-5 md:gap-10">
                <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-5 md:gap-10">
                    {loader
                        ? [1, 2, 3, 4]?.map(itm => { return <SalesAnalyticsCards animate /> })
                        : <>
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={parseFloat(customerData?.sales_cards?.total_customer_sale)?.toFixed(2)}
                                subvalueType={"positive"}
                                subvalue={"+11.01"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Total Spend" : translation[1608]?.value ? translation[1608]?.value : "Total Spend"}
                                graph={<Svgs.NewGraphUp />}
                            />

                            <SalesAnalyticsCards
                                value={customerData?.sales_cards?.total_new_customer}
                                subvalueType={"negative"}
                                subvalue={"-0.03"}
                                // currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "New Customers" : translation[1609]?.value ? translation[1609]?.value : "New Customers"}
                                graph={<Svgs.NewGraphDown />}
                            />
                            <SalesAnalyticsCards
                                value={customerData?.sales_cards?.total_returning_customer}
                                subvalueType={"positive"}
                                subvalue={"+15.01"}
                                // currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Returning Customers" : translation[1610]?.value ? translation[1610]?.value : "Returning Customers"}
                                graph={<Svgs.NewGraphUp />}
                            />

                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={customerData?.sales_cards?.total_customer}
                                subvalueType={"positive"}
                                subvalue={"+11.01"}
                                text={selectedLanguage?.name == "English" ? "Total Customers" : translation[1611]?.value ? translation[1611]?.value : "Total Customers"}
                                graph={<Svgs.NewGraphUp />}
                            />
                        </>
                    }
                </div>
                <div className="grid sm:grid-cols-2 xl:grid-cols-6 gap-5 md:gap-10">

                    <div className="flex flex-col gap-5 justify-between h-auto xl:col-span-4 py-5 px-6 bg-white border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center flex-wrap gap-2">
                            <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Total Customers" : translation[1611]?.value ? translation[1611]?.value : "Total Customers"}</h4>
                            <div className="flex items-center gap-2 2xl:gap-4">
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#5679FF]"></div>
                                    <p className="text-sm text-[#1C1C1C]">{selectedLanguage?.name == "English" ? "Returning Customers" : translation[1610]?.value ? translation[1610]?.value : "Returning Customers"}</p>
                                </div>
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#002FE1]"></div>
                                    <p className="text-sm text-[#1C1C1C]">{selectedLanguage?.name == "English" ? "New Customers" : translation[1609]?.value ? translation[1609]?.value : "New Customers"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <BarChart
                                chartData1={customerData?.new_and_returning_customers?.returning_customer_monthly}
                                chartData2={customerData?.new_and_returning_customers?.new_customer_monthly}
                                chartData1Color={'#5679FF'}
                                chartData2Color={'#002FE1'}
                                chartBorderRadius={10}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-5 h-auto xl:col-span-2 py-5 px-6 bg-white border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex flex-col gap-1">
                            <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Vouchers & Memberships Sales" : translation[1612]?.value ? translation[1612]?.value : "Vouchers & Memberships Sales"}</h4>
                        </div>
                        <div className="grid grid-cols-2 gap-5 py-2 xl:py-5 text-center">
                            <div className="flex flex-col gap-2">
                                <div className="flex justify-center items-center relative">
                                    <div className="h-[120px] w-[120px] sm:w-[100px] sm:h-[100px] xl:w-[140px] xl:h-[140px] overflow-hidden">
                                        <Donet chartData={[
                                            customerData?.promotions_and_memberships_utilization?.percentage_applied_voucher,
                                            100 - customerData?.promotions_and_memberships_utilization?.percentage_applied_voucher
                                        ]} chartDataColors={['#5679FF', '#5679FF33']} circleCutout={'85%'} />
                                    </div>
                                    <div className="flex justify-center items-center absolute top-1/4 2xl:top-auto -z-10 h-[60px] w-[60px] 2xl:h-[90px] 2xl:w-[90px]">
                                        <Svgs.Gift />
                                    </div>
                                </div>
                                <div>
                                    <h4 className="py-3 font-semibold text-2xl text-[#101928]">{(customerData?.promotions_and_memberships_utilization?.percentage_applied_voucher)?.toFixed(2)} %</h4>
                                    <p className="text-[#949494] text-sm 2xl:text-base">{selectedLanguage?.name == "English" ? "Total Vouchers Sold" : translation[1618]?.value ? translation[1618]?.value : "Total Vouchers Sold"}</p>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex justify-center items-center relative overflow-hidden">
                                    <div className="h-[120px] w-[120px] sm:w-[100px] sm:h-[100px] xl:w-[140px] xl:h-[140px] overflow-hidden">
                                        <Donet chartData={[
                                            customerData?.promotions_and_memberships_utilization?.percentage_applied_promotion,
                                            100 - customerData?.promotions_and_memberships_utilization?.percentage_applied_promotion
                                        ]} chartDataColors={['#5679FF', '#5679FF33']} circleCutout={'85%'} />
                                    </div>
                                    <div className="flex justify-center items-center absolute top-1/4 2xl:top-auto -z-10 h-[60px] w-[60px] 2xl:h-[90px] 2xl:w-[90px]">
                                        <Svgs.MembershipIcon />
                                    </div>
                                </div>
                                <div>
                                    <h4 className="py-3 font-semibold text-2xl text-[#101928]">{(customerData?.promotions_and_memberships_utilization?.percentage_applied_promotion)?.toFixed(2)}%</h4>
                                    <p className="text-[#949494] text-sm 2xl:text-base">{selectedLanguage?.name == "English" ? "Total Memberships Sold" : translation[1742]?.value ? translation[1742]?.value : "Total Memberships Sold"}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="px-5 text-center">
                            <hr />
                            <h4 className="py-2 xl:py-3 font-semibold text-lg 2xl:text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Total" : translation[1612]?.value ? translation[1612]?.value : "Total"}: <span className="text-xl 2xl:text-2xl">60,071.16 {location_currency}</span></h4>
                        </div> */}
                    </div>

                </div>
                <div className="grid sm:grid-cols-2 gap-5 md:gap-10">
                    <div className="flex flex-col gap-10 h-auto 3xl:h-[25rem] py-5 px-6 bg-white border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Retail Spendings" : translation[1613]?.value ? translation[1613]?.value : "Retail Spendings"}</h4>
                                <h5 className="text-[#484848] font-bold text-2xl">{(customerData?.product_spending?.product_total_sale)?.toFixed(2)} {location_currency}</h5>
                            </div>
                            {/* <div className="flex items-center gap-1 text-[#00C287]">
                            <div className="border border-[#00C287] rounded-full p-1"><Svgs.UpArrow size={"10"} fill={"#00C287"} /></div>
                            <p className="text-sm font-semibold">6.2%</p>
                        </div> */}
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <LineChatCurve chartData1={customerData?.product_spending?.product_yearly_sales} chartData1Color={'#2F44B2'} LineBorderWidth={5} />
                        </div>
                    </div>
                    <div className="min-h-[20rem] py-5 px-6 bg-white border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5 flex flex-col justify-between gap-2">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Service Spendings" : translation[1614]?.value ? translation[1614]?.value : "Service Spendings"}</h4>
                                <h5 className="text-[#484848] font-bold text-2xl">{(customerData?.service_spending?.service_spending_avg)?.toFixed(2)} {location_currency}</h5>
                            </div>
                            {/* <div className="flex items-center gap-1 text-[#BC2323]">
                            <div className="border border-[#BC2323] rounded-full p-1 rotate-180"><Svgs.UpArrow size={"10"} fill={"#BC2323"} /></div>
                            <p className="text-sm font-semibold">6.2%</p>
                        </div> */}
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <div className="h-auto w-full overflow-hidden">
                                <LineChatCurve chartData1={customerData?.service_spending?.service_yearly_sales} chartData1Color={'#4B8DCA'} LineBorderWidth={5} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="border rounded-lg">
                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b gap-5">

                    <div className="flex items-center sm:gap-4 gap-2 ">
                        <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Search Result" : translation[1143]?.value ? translation[1143]?.value : "Search Result"}</h2>
                    </div>

                    <div className="flex items-center gap-5">
                        <div className='relative flex items-center gap-3 w-full z-[1000]'>
                            <div className={`border text-sm rounded-md font-semibold flex items-center w-full`}>
                                <div className='p-2 relative gap-4 flex items-center whitespace-nowrap w-full'>
                                    <p
                                        onClick={() => {
                                            setIndexValue(1)
                                            setErrors((prev) => ({ ...prev, 'date_range': "" }))
                                            setToggleDateRange(!toggleDateRange)
                                        }}
                                        className='cursor-pointer xl:text-xs w-full'>
                                        {start_date && end_date ?
                                            `${start_date ? moment(start_date).format("DD-MM-YYYY") : "--/--/----"} - ${end_date ? moment(end_date).format("DD-MM-YYYY") : "--/--/----"}` :
                                            '--/--/---- - --/--/----'}
                                    </p>
                                    <span className='cursor-pointer ' onClick={() => {
                                        setStartDate()
                                        setEndDate()
                                    }}>
                                        {selectedLanguage?.name == "English" ? "Clear" : translation[340]?.value ? translation[340]?.value : "Clear"}
                                    </span>

                                    {(toggleDateRange) && <>
                                        <div className='absolute inset-0 top-0 left-0 w-[95%] mb-4' onClick={() => { setToggleDateRange(false) }}></div>
                                        <div className={`absolute top-0 right-0 mb-4`}>
                                            <DateRangePicker
                                                start={start_date}
                                                end={end_date}
                                                setStartDate={setStartDate}
                                                setEndDate={setEndDate}
                                                setToggleDateRange={setToggleDateRange}
                                            addCustomDataOnSelection={() => {
                                                let date_name = `is_date_${ele.name}`
                                                setFilterQueries({
                                                    [date_name]: true,
                                                })
                                            }}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                                {errors?.date_range &&
                            <p className={`!text-[#eb3b3b] flex items-center gap-1 !text-xs !font-normal absolute top-[100%] w-full justify-end normal-case pt-1`}>
                            <Svgs.I fill="#eb3b3b" />
                            {errors?.date_range}
                            </p>
                        }
                            </div>
                        </div>
                        <LoginBtn>
                            <Svgs.Import fill="#fff" width="1.5rem" height="1.5rem" />
                        </LoginBtn>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] px-12 py-3 bg-[#F3F6FF] text-sm font-semibold min-w-full w-fit">
                        <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"} </p>
                        <p>{selectedLanguage?.name == "English" ? "Spending" : translation[1615]?.value ? translation[1615]?.value : "Spending"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Feedback" : translation[1616]?.value ? translation[1616]?.value : "Feedback"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Promotion" : translation[1583]?.value ? translation[1583]?.value : "Promotion"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Footfall" : translation[1617]?.value ? translation[1617]?.value : "Footfall"}</p>
                    </div>
                    <div className={` text-[#3A3A3A] transition-all border-l-[0px]`} >
                        {SearchedResult?.map((v, i) => {

                            return (
                                <div key={i} className="even:bg-[#FBFCFF] px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] min-w-full w-fit">
                                    <div>
                                        {v.date}
                                    </div>
                                    <div>
                                        {v.channel}
                                    </div>

                                    <div>
                                        {v.product}
                                    </div>
                                    <div>
                                        {v.quantity}
                                    </div>

                                    <div>
                                        {v.promotion}
                                    </div>
                                    <div>
                                        {v.tax_type}
                                    </div>

                                </div>
                            );
                        })}
                    </div>
                </div>
            </div> */}

            <Popup
                size={"lg"}
                customPadding={"!p-0"}
                heading={selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                open={Filter}
                close={setFilter}
            >
                <div>
                    {/* <div className="grid lg:grid-cols-2 transition-all duration-300 max-h-[35rem] xl:max-h-[27rem] xl:min-h-[27rem] overflow-y-auto">
                        <div className="">
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Customer Type" : translation[1619]?.value ? translation[1619]?.value : "Customer Type"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, customer_type: !FilterDetails?.customer_type })}
                                    checked={FilterDetails?.customer_type}
                                />
                                {FilterDetails?.customer_type &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6 bg-white">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "New Customers" : translation[1609]?.value ? translation[1609]?.value : "New Customers"}
                                            name="new_customers"
                                            id={'new_customers'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Returning Customers" : translation[1610]?.value ? translation[1610]?.value : "Returning Customers"}
                                            name="returning_customers"
                                            id={'returning_customers'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Spending Range" : translation[1621]?.value ? translation[1621]?.value : "Spending Range"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, spending_range: !FilterDetails?.spending_range })}
                                    checked={FilterDetails?.spending_range}
                                />
                                {FilterDetails?.spending_range &&
                                    <div className="transition-all py-6 px-6 flex items-center gap-5">
                                        <LoginInput
                                            //   error={error.includes('name')}
                                            title={""}
                                            placeholder={"100"}
                                            name='name'
                                            parentClass={"w-[100px]"}
                                            type={'number'}
                                        //   // charactersMaxLength={25}
                                        //   onChange={handleChange}
                                        //   onBlur={blur_change}
                                        //   disabled={loading}
                                        //   value={form_data.name}
                                        //   onEnterSubmit={handle_click}
                                        />
                                        <p>To</p>
                                        <LoginInput
                                            //   error={error.includes('name')}
                                            title={""}
                                            placeholder={"200"}
                                            name='name'
                                            parentClass={"w-[100px]"}
                                            type={'number'}
                                        //   // charactersMaxLength={25}
                                        //   onChange={handleChange}
                                        //   onBlur={blur_change}
                                        //   disabled={loading}
                                        //   value={form_data.name}
                                        //   onEnterSubmit={handle_click}
                                        />
                                        <LoginBtn className="!px-6" text={selectedLanguage?.name == "English" ? "Done" : translation[1431]?.value ? translation[1431]?.value : "Done"} />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Feedback Type" : translation[1620]?.value ? translation[1620]?.value : "Feedback Type"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, feedback_type: !FilterDetails?.feedback_type })}
                                    checked={FilterDetails?.feedback_type}
                                />
                                {FilterDetails?.feedback_type &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">

                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Positive" : translation[1623]?.value ? translation[1623]?.value : "Positive"}
                                            name={"positive"}
                                            id={"positive"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Negative" : translation[1624]?.value ? translation[1624]?.value : "Negative"}
                                            name={"negative"}
                                            id={"negative"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Neutral" : translation[1625]?.value ? translation[1625]?.value : "Neutral"}
                                            name={"neutral"}
                                            id={"neutral"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />

                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Promotion/Membership Usage" : translation[1622]?.value ? translation[1622]?.value : "Promotion/Membership Usage"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, promotion_usage: !FilterDetails?.promotion_usage })}
                                    checked={FilterDetails?.promotion_usage}
                                />
                                {FilterDetails?.promotion_usage &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
                                            name={"promotion_usage"}
                                            id={"voucher"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"}
                                            name={"promotion_usage"}
                                            id={"membership"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Coupon" : translation[1566]?.value ? translation[1566]?.value : "Coupon"}
                                            name={"promotion_usage"}
                                            id={"coupon"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Loyalty Points" : translation[447]?.value ? translation[447]?.value : "Loyalty Points"}
                                            name={"promotion_usage"}
                                            id={"Loyalty Points"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Customer Footfall Comparison" : translation[1583]?.value ? translation[1583]?.value : "Customer Footfall Comparison"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, customer_footfall_comparison: !FilterDetails?.customer_footfall_comparison })}
                                    checked={FilterDetails?.customer_footfall_comparison}
                                />
                                {FilterDetails?.customer_footfall_comparison &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Service" : translation[361]?.value ? translation[361]?.value : "Service"}
                                            name={"customer_footfall_comparison"}
                                            id={"service"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Product" : translation[1316]?.value ? translation[1316]?.value : "Product"}
                                            name={"customer_footfall_comparison"}
                                            id={"product"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="lg:border-l lg:border-[#E5E7EB]">
                            <div className="px-6 flex flex-col gap-3 pt-5 lg:pt-3">
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Customer Type" : translation[1619]?.value ? translation[1619]?.value : "Customer Type"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Returning</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Spending Range" : translation[1621]?.value ? translation[1621]?.value : "Spending Range"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">100UED to 6000UED </h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Feedback Type" : translation[1620]?.value ? translation[1620]?.value : "Feedback Type"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Negative</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Promotion/Membership Usage" : translation[1622]?.value ? translation[1622]?.value : "Promotion/Membership Usage"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Membership</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Customer Footfall Comparison" : translation[1583]?.value ? translation[1583]?.value : "Customer Footfall Comparison"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Service</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="flex flex-col gap-2 py-4 px-6">
                        <div className='flex flex-col gap-4'>
                            <div className='grid grid-cols-2 gap-3'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                    name="start_date"
                                    value={dateRange?.start_date}
                                    type="date"
                                    onChange={onDateRangeHandler}
                                />
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                    name="end_date"
                                    value={dateRange?.end_date}
                                    type="date"
                                    onChange={onDateRangeHandler}
                                    min={minEndDateLimit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-end gap-2 py-4 px-6">
                        <LoginBtn onClick={resetFilters} className="!px-6" borderBtn text={selectedLanguage?.name == "English" ? "RESET" : translation[1141]?.value ? translation[1141]?.value : "RESET"} />
                        <LoginBtn onClick={ApplyFilter} text={selectedLanguage?.name == "English" ? "APPLY FILTER" : translation[595]?.value ? translation[595]?.value : "APPLY FILTER"} />
                    </div>
                </div>

            </Popup>
        </div>
    )
}

export default CustomerAnalytics