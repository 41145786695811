/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable array-callback-return */
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { delete_bundle_discount, delete_client_discount_promotion, delete_complimentry_discount, delete_coupons_discount, delete_fixed_price_service_discount, delete_free_service_discount, delete_package_discount, delete_purchase_discount, delete_retail_getservice_promotion, delete_service_group_discount, delete_specific_brandservice_group_discount, delete_spend_some_amount_discount, delete_user_restricted_discount, get_client_discount_promotion } from "Adapters/Api/clientDiscountPromotion";
import { getSaleServices, getServiceGroup } from "Adapters/Api/saleServices";
import { discountTypes } from "Redux/TenantRedux/Reducers/clientDiscountPromotionReducer";
import { get_business_brands } from "Adapters/Api/Brand";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getsProducts, getsProductsDropdownList } from "Adapters/Api/Products";
import { getClient, getClientDropDownList } from "Adapters/Api/clients";
import { apiErrorMessage } from "Constants/Data/Errors";
import { getserviceDropdownList } from "Adapters/Api/services";

export const useDiscountPromotion = () => {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const { setErrors, validation, onBlurHandler } = useValidations();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const dispatch = useDispatch();
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const discountPromotionData = state?.clientDiscountPromotion?.discountPromotions_updated;
    const serviceGroupRedux = state?.saleService?.servicesgroup;
    const brandRedux = state?.brand?.brands;
    const productRedux = state.product?.products;
    const servicesRedux = state?.service?.services;
    const clientRedux = useSelector((state) => state?.client?.clients);
    const allDiscountPromotions = state?.clientDiscountPromotion?.discountPromotions;

    const [create, setCreate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState({ id: '', type: '' });
    const [deleteLoading, setDeleteLoading] = useState();
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [loader, setLoader] = useState(true)
    const [serviceGroupLoader, setServiceGroupLoader] = useState(true)
    const [clientLoader, setClientLoader] = useState(true)
    const [brandLoader, setBrandLoader] = useState(true)
    const [serviceLoader, setServiceLoader] = useState(true)
    const [productLoader, setProductLoader] = useState(true)
    const [getLoader, setGetLoader] = useState(true)

    // useEffect(() => {
    //     !discountPromotionData && dispatch(get_client_discount_promotion())
    // }, [state?.clientDiscountPromotion?.discountPromotions?.length, !discountPromotionData])

    const get_products = async () => {
        setProductLoader(true)
        const response = await getsProductsDropdownList();
        if (response.status === 200) {
            let productList = response?.data?.response?.products;
            dispatch(getProducts(productList));
            setProductLoader(false)
        }
    };

    useEffect(() => {
        dispatch(getServiceGroup("", "", "", setServiceGroupLoader));
        dispatch(get_business_brands("", "", setBrandLoader));
        dispatch(getserviceDropdownList(setServiceLoader));
        dispatch(getClientDropDownList(setClientLoader))
        dispatch(get_client_discount_promotion(setGetLoader))
        get_products();
    }, [])

    useEffect(() => {
        if (!serviceGroupLoader && !clientLoader && !brandLoader && !serviceLoader) {
            setLoader(false)
        }
    }, [serviceGroupLoader, clientLoader, brandLoader, serviceLoader, productLoader])

    const discountPromotion = useMemo(() => {
        return allDiscountPromotions?.map(obj => {
            let choice = obj?.type;
            switch (choice) {
                case discountTypes.directOrFlat:
                    return {
                        ...obj,
                        heading: headingTypes.directOrFlat,
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: obj?.category_discount?.map((obj) => {
                            return { type: obj?.category_type, percentage: obj?.discount }
                        })
                    }
                case discountTypes.specificGroup:
                    return {
                        ...obj,
                        heading: headingTypes.specificGroup,
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: obj?.servicegroup_discount?.map((obj) => {
                            return { type: serviceGroupRedux?.find(service => service.id === obj?.servicegroup)?.name, percentage: obj?.discount }
                        })
                    }
                case discountTypes.purchase:
                    return {
                        ...obj,
                        heading: headingTypes.purchase,
                        title: '',
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: [],
                        sub_title: obj?.select_type === 'Product' ?
                            `Purchase ${productRedux?.find(({ id }) => id === obj?.product)?.name} and get ${obj?.discount_value}% Off on ${obj?.purchase} ${productRedux?.find(({ id }) => id === obj?.discount_product)?.name}` :
                            `Purchase  ${servicesRedux?.find(({ id }) => id === obj?.service)?.name} and get ${obj?.discount_value}% Off on ${obj?.purchase} ${servicesRedux?.find(({ id }) => id === obj?.discount_service)?.name}`
                        // `Get ${obj?.discount_value}% Off on 1 service ${obj?.purchase} ${productRedux?.find(({ id }) => id === obj?.discount_product)?.name} on purchasing ${productRedux?.find(({ id }) => id === obj?.product)?.name}` :
                        // `Get ${obj?.discount_value}% Off on 1 service ${obj?.purchase} ${servicesRedux?.find(({ id }) => id === obj?.discount_service)?.name} of purchasing  ${servicesRedux?.find(({ id }) => id === obj?.service)?.name}`
                    }
                case discountTypes.specificBrandServiceGroup:
                    return {
                        ...obj,
                        heading: headingTypes.specificBrandServiceGroup,
                        title: '',
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: [
                            {
                                type: brandRedux.find(({ id }) => id === obj?.brand)?.name,
                                percentage: obj?.discount_brand
                            },
                            {
                                type: serviceGroupRedux.find(({ id }) => id === obj?.servicegroup)?.name,
                                percentage: obj?.discount_service_group
                            },
                        ]
                    }
                case discountTypes.spendSomeAmount:
                    return {
                        ...obj,
                        heading: headingTypes.spendSomeAmount,
                        title: '',
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: obj?.spend_service?.map((obj) => {
                            return { type: servicesRedux?.find(service => service?.id === obj?.service)?.name, amount: obj?.spend_amount }
                        })
                    }
                case discountTypes.fixedPrice:
                    return {
                        ...obj,
                        heading: headingTypes.fixedPrice,
                        title: '',
                        sub_title: `get ${obj?.service?.map(obj => servicesRedux?.find(service => service?.id === obj)?.name)} for ${obj.duration} on ${obj?.spend_amount} AED`,
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: [],
                    }
                case discountTypes.freeService:
                    return {
                        ...obj,
                        heading: headingTypes.freeService,
                        title: '',
                        sub_title: `get ${servicesRedux?.find(service => service?.id === obj.service)?.name} on availing  ${obj?.free_service?.map(obj => `${servicesRedux?.find(service => service?.id === obj.service)?.name} at quantity ${obj.quantity}`)}`,
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: [],
                    }
                case discountTypes.bundleDiscount:
                    return {
                        ...obj,
                        heading: headingTypes.bundleDiscount,
                        title: '',
                        sub_title: `get ${obj?.service?.map(obj => `${servicesRedux?.find(service => service?.id === obj)?.name}`)} at ${obj?.spend_amount} AED`,
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        multiple_discounts: [],
                    }
                case discountTypes.retailPromotion:
                    return {
                        ...obj,
                        heading: headingTypes.retailPromotion,
                        title: '',
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        sub_title: `${obj?.promotion?.map((obj) => `Buy ${productRedux?.find(({ id }) => id === obj?.product)?.name} and get ${servicesRedux?.find(service => service?.id === obj?.service)?.name}`)}  `,
                        multiple_discounts: []
                    }
                case discountTypes.userRestricted:
                    return {
                        ...obj,
                        heading: headingTypes.userRestricted,
                        title: '',
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        sub_title: `The selected users ${obj?.client?.map(obj => `${clientRedux?.find(client => client?.id === obj)?.full_name}`)} 
                                    can get ${obj?.discount_percentage} % on ${obj?.corporate_type}
                        `,
                        multiple_discounts: []
                    }
                case discountTypes.complimentaryVoucher:
                    return {
                        ...obj,
                        heading: headingTypes.complimentaryVoucher,
                        title: '',
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        sub_title: `${obj?.freeservice?.map(obj => `${servicesRedux?.find(service => service?.id === obj?.service)?.name}
                                    can get ${obj?.discount_percentage} % discount for ${obj?.discount_duration}
                        ` )}`,
                        multiple_discounts: []
                    }
                case discountTypes.packages:
                    return {
                        ...obj,
                        heading: headingTypes.packages,
                        title: '',
                        start_date: obj?.date_restrictions?.start_date,
                        end_date: obj?.date_restrictions?.end_date,
                        sub_title: `${obj?.service_duration?.map(obj => (`Get ${obj?.service?.map((serviceId) => ` ${servicesRedux?.find(service => service?.id === serviceId)?.name} `)} for ${obj?.service_duration} for ${obj?.total_amount} AED with package duration ${obj?.package_duration}`))} `,
                        multiple_discounts: []
                    }
                case discountTypes.coupons:
                    return {
                        ...obj,
                        heading: headingTypes.coupons,
                        title: '',
                        start_date: obj?.start_date,
                        end_date: obj?.end_date,
                        sub_title: obj?.coupon_type,
                        // sub_title: `${obj?.service_duration?.map(obj => (`Get ${obj?.service?.map((serviceId) => ` ${servicesRedux?.find(service => service?.id === serviceId)?.name} `)} for ${obj?.service_duration} for ${obj?.total_amount} AED with package duration ${obj?.package_duration}`))} `,
                        multiple_discounts: []
                    }
            }
        })?.filter(obj => obj !== undefined)
    }, [state?.clientDiscountPromotion, serviceGroupRedux, productRedux, servicesRedux])


    const deleteDiscountPromotion = (id, type) => {
        setDeleteId({ id: id, type: type });
    }

    const deleteDiscount = (handleClose) => {
        setDeleteLoading(true);
        const fail = () => {
            setDeleteLoading(false);
            if (!isToastVisible) {
                toast.error(apiErrorMessage, {
                    onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
            }
        }
        const success = () => {
            setGetLoader(true)
            setLoader(false)
            dispatch(get_client_discount_promotion(setGetLoader))
            setDeleteLoading(false);
            handleClose();
        }
        const { type } = deleteId;
        switch (type) {
            case discountTypes.directOrFlat:
                dispatch(delete_client_discount_promotion(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.specificGroup:
                dispatch(delete_service_group_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.purchase:
                dispatch(delete_purchase_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.specificBrandServiceGroup:
                dispatch(delete_specific_brandservice_group_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.spendSomeAmount:
                dispatch(delete_spend_some_amount_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.fixedPrice:
                dispatch(delete_fixed_price_service_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.freeService:
                dispatch(delete_free_service_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.bundleDiscount:
                dispatch(delete_bundle_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.retailPromotion:
                dispatch(delete_retail_getservice_promotion(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.userRestricted:
                dispatch(delete_user_restricted_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.complimentaryVoucher:
                dispatch(delete_complimentry_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.packages:
                dispatch(delete_package_discount(deleteId.id, access_token, success, fail))
                break;
            case discountTypes.coupons:
                dispatch(delete_coupons_discount(deleteId.id, access_token, success, fail))
                break;
            default:
                break;
        }
    }

    const editDiscount = (data) => {
        let path = '/dashboard/clients/discounts-promotions'
        const { type } = data;
        switch (type) {
            case discountTypes.directOrFlat:
                navigate(`${path}/edit-flat-discount/${data.id}`)
                break;
            case discountTypes.specificGroup:
                navigate(`${path}/edit-specific-discount/${data.id}`)
                break;
            case discountTypes.purchase:
                navigate(`${path}/edit-purchase-discount/${data.id}`)
                break;
            case discountTypes.specificBrandServiceGroup:
                navigate(`${path}/edit-specific-brand-discount/${data.id}`)
                break;
            case discountTypes.spendSomeAmount:
                navigate(`${path}/edit-item-amount-discount/${data.id}`)
                break;
            case discountTypes.fixedPrice:
                navigate(`${path}/edit-item-fixed-discount/${data.id}`)
                break;
            case discountTypes.freeService:
                navigate(`${path}/edit-free-item-discount/${data.id}`)
                break;
            case discountTypes.bundleDiscount:
                navigate(`${path}/edit-bundle-discount/${data.id}`)
                break;
            case discountTypes.retailPromotion:
                navigate(`${path}/edit-retail-services-discount/${data.id}`)
                break;
            case discountTypes.userRestricted:
                navigate(`${path}/edit-restricted-discounts/${data.id}`)
                break;
            case discountTypes.complimentaryVoucher:
                navigate(`${path}/edit-complimentary-vouchers/${data.id}`)
                break;
            case discountTypes.packages:
                navigate(`${path}/edit-packages-discount/${data.id}`)
                break;
            case discountTypes.coupons:
                navigate(`${path}/edit-coupons-discount/${data.id}`)
                break;
            default:
                navigate(`${path}`)
        }
    }
    return {
        setDeleteLoading, navigate, setErrors, validation, onBlurHandler, getLoader,
        setCreate, deleteDiscountPromotion, setDeleteModal, deleteDiscount, editDiscount,
        discountPromotionData, discountPromotion, create, deleteModal, deleteLoading,
        translation, selectedLanguage, loader
    };
};
export const headingTypes = {
    directOrFlat: "Direct Or Flat",  //#1
    specificGroup: "Specific group of category",  //#2
    purchase: "Purchase specific product/service and Get Discount for other",  //#3
    specificBrandServiceGroup: "Specific brand and service group",  //#4
    spendSomeAmount: "Get Free item if you spend some amount",  //#5
    fixedPrice: "Get an Item for a fixed price or in the group",  //#6,
    freeService: "Get free item when purchase specific service/retail", //#7
    bundleDiscount: "Get Selected Services/Service Group With A Fixed Amount",   //#8
    retailPromotion: "Buy retail and get service",    //#9
    userRestricted: "Client specific discount", // #10,
    complimentaryVoucher: "Complimentary Voucher",// #11
    packages: "Packages", //#12
    coupons: "coupons" //#13
}
export const discountRoutes = [
    {
        'name': 'Direct or flat',
        'slug': '/create-flat-discount'
    },

    {
        'name': 'Specific group of category',
        'slug': '/create-specific-discount'
    },

    // {
    //     'name': 'Purchase specific product/service and get discount for other',
    //     'slug': '/create-purchase-discount'
    // },

    // {
    //     'name': 'Spend and Get Discount',
    //     'slug': '/create-spend-discount'
    // },

    {
        'name': 'Get discount on specific brand and service group',
        'slug': '/create-specific-brand-discount'
    },

    {
        'name': 'Get free item when purchase specific service/retail',
        'slug': '/create-free-item-discount'
    },

    {
        'name': 'Get an Item for a fixed price or in the group',
        'slug': '/create-item-fixed-discount'
    },

    // {
    //     'name': 'Get a free item if you spend some amount',
    //     'slug': '/create-item-amount-discount'
    // },

    // { // duplicate same as 'Get an Item for a fixed price or in the group'
    //     'name': 'Fixed Price Bundle/Package',
    //     'slug': '/create-bundle-discount'
    // },

    {
        'name': 'Buy retail and get specific services free',
        'slug': '/create-retail-services-discount'
    },

    // {
    //     'name': 'Customer specific discount',
    //     'slug': '/create-restricted-discounts'
    // },

    {
        'name': 'Coupons',
        'slug': '/create-coupons-discount'
    },
]

export const discountRoutes_2 = [
    {
        'name': 'Complimentary vouchers',
        'slug': '/create-complimentary-vouchers'
    },
    // {
    //     'name': 'Packages',
    //     'slug': '/create-packages-discount'
    // },
]


export const navigateToDiscountPromotion = (navigate) => {
    navigate("/dashboard/clients/discounts-promotions");
}


export const discountErrors = {
    dateAlreadySelected: 'Date already selected',
    dateRange: "Date must be between start date and end date"
}