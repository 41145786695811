import Popup from 'Components/TenantApp/Elements/Popup';
import Pagination from 'new-components/Pagination';
import React, { useState } from 'react'
import usePaidUnpaidAppointment from './helper';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Svgs from 'Assets/svgs';
import moment from 'moment';
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import NotFound from 'Components/Utility/NotFound';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import { useSelector } from 'react-redux';
import PaidUnpaidShimmer from 'Components/Utility/Shimmers/PaidUnpaidShimmer';
import { useNavigate } from "react-router-dom";
import AppointmentDetailPopup from '../AppointmentDetailPopup';
import NoAppointmentRecordFound from 'Components/Utility/NoAppointmentRecordFound';

export const PaidUnpaidAppointmentPopup = ({ onClose, open }) => {
    const { searchText, setSearchText, toggleDateRange, setToggleDateRange, currentPage, setCurrentPage, loader, PageSize, data, setData,
        translation, selectedLanguage, startDate, setStartDate, endDate, setEndDate, pages, setDropdownStatus, dropdownStatus,
        selectedLocationCurrency, count, loadingPermission
    } = usePaidUnpaidAppointment()
    const navigate = useNavigate();

    const [appointmentDetail, setAppointmentDetail] = useState(false)
    const [appointmentDetailData, setAppointmentDetailData] = useState({
        appointment_id: "",
        service_id: ""
    })

    return (
        <>
            <Popup
                heading={'Paid/Unpaid Appointments'}
                size="xl"
                close={() => {
                    onClose()
                    setData([])
                }}
                open={open}
            >
                <div className="">
                    <div className="border rounded-lg">
                        <div className="px-4 lg:px-6 py-2 flex md:items-center items-end justify-between border-b md:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-1 lg:gap-1 gap-2">
                                <div className="flex items-center lg:gap-4 gap-3">
                                    <div className="flex items-center sm:gap-4 gap-2 ">
                                        {/* <h2 className="font-semibold text-xl sm:whitespace-nowrap">
                                            {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                        </h2> */}
                                        {loader ?
                                            <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                                <p className="rounded-md h-5 w-12 mx-1"> </p>
                                            </div>
                                            : <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                                <p className="text-primary">
                                                    {count ? count : 0}{" "}
                                                    {count && count > 1 ?
                                                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                                    }
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {loadingPermission ?
                                    <PaidUnpaidShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} />
                                    :
                                    <div className="mb-00 flex items-center gap-2">
                                        <div className="mb-00">
                                            <Dropwdown
                                                title=""
                                                value={dropdownStatus}
                                                onChange={(e) => setDropdownStatus(e.target.value)}
                                                showTitle={false}
                                                placeholder={selectedLanguage?.name == "English" ? "Select Type" : translation[334]?.value ? translation[334]?.value : "Select Type"}
                                                options={[
                                                    { label: selectedLanguage?.name == "English" ? "All Appointments" : translation[1342]?.value ? translation[1342]?.value : "All Appointments", value: "" },
                                                    { label: selectedLanguage?.name == "English" ? "Paid" : translation[1340]?.value ? translation[1340]?.value : "Paid", value: 'paid' },
                                                    { label: selectedLanguage?.name == "English" ? "Un-Paid" : translation[1341]?.value ? translation[1341]?.value : "Un-Paid", value: 'unpaid' }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            {loadingPermission ?
                                <PaidUnpaidShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} has3Itm />
                                :
                                <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                                    <div className='flex items-center gap-3'>
                                        <LoginInput
                                            title=""
                                            name={"search_text"}
                                            placeholder={selectedLanguage?.name == "English" ? "Search appointments" : translation[1343]?.value ? translation[1343]?.value : "Search appointments"}
                                            value={searchText}
                                            onChange={(e) => {
                                                setSearchText(e.target.value)
                                            }}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                        />
                                        <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                            <div className='p-2 relative gap-4 flex items-center'>
                                                <p onClick={() => {
                                                    setToggleDateRange(!toggleDateRange)
                                                }} className='cursor-pointer whitespace-nowrap'>
                                                    {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                                </p>
                                                <span className='cursor-pointer' onClick={() => {
                                                    setStartDate()
                                                    setEndDate()
                                                }}>Clear</span>

                                                {
                                                    toggleDateRange && <>
                                                        <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                                        <div className='absolute top-full right-0 z-[2]'>
                                                            <DateRangePicker
                                                                start={startDate}
                                                                end={endDate}
                                                                setStartDate={setStartDate}
                                                                setEndDate={setEndDate}
                                                                setToggleDateRange={setToggleDateRange}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* {exportLoader ? (
                                        <div className="h-[4rem] flex items-center justify-center">
                                            <PreloaderSm />
                                        </div>
                                    ) :
                                        !employee ? (
                                            commissionAndReportsWithNoPagination?.length > 0 ?
                                                <CSVLink
                                                    {...exportPromotionDiscountReport}
                                                    target="_blank"
                                                    onClick={handleExportClick}
                                                >
                                                         <LoginBtn
                              className="rounded-lg !py-[7px]"
                              bg="bg-white"
                              animation={false}
                              customThemeBtn={'theme-btn2'}
                            >
                              <div className="flex items-center gap-2 text-[#FFFFFF]">
                                <Svgs.ExportSvg />
                              </div>
                            </LoginBtn>
                                                </CSVLink> :
                                                <LoginBtn
                                                    className="rounded-lg border-2 border-primary"
                                                    bg="bg-white"
                                                    animation={false}
                                                    onClick={handleExportClick}
                                                >
                                                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                        <Svgs.Export bg="#FFFFFF" />
                                                    </div>
                                                </LoginBtn>
                                        ) : (
                                            ""
                                        )} */}
                                </div>

                            }
                        </div>
                        {loader ?
                            <TableShimer rows={10} cols={10} />
                            :
                            <div className="overflow-x-auto">
                                <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(115px,1fr))] xl:gap-12 px-12 py-3 bg-[#F7F9FF] text-sm font-semibold min-w-full w-fit">
                                    <p className='whitespace-nowrap'>{selectedLanguage?.name == "English" ? "Appointment ID" : translation[1344]?.value ? translation[1344]?.value : "Appointment ID"} #</p>
                                    <p>{selectedLanguage?.name == "English" ? "Client Name" : translation[474]?.value ? translation[474]?.value : "Client Name"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Booking Date" : translation[1345]?.value ? translation[1345]?.value : "Booking Date"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Amount" : translation[793]?.value ? translation[793]?.value : "Amount"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Payment Date" : translation[349]?.value ? translation[349]?.value : "Payment Date"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Checkout" : translation[182]?.value ? translation[182]?.value : "Checkout"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "View Detail" : translation[244]?.value ? translation[244]?.value : "View Detail"}</p>
                                </div>
                                <div
                                    className={`min-h-[60vh] odd-bg text-[#3A3A3A] transition-all border-l-[0px] relative`}
                                >
                                    {data?.length > 0 ? (
                                        data?.map((item, index) => {
                                            return (
                                                <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(8,minmax(115px,1fr))] xl:gap-12 min-w-full w-fit even:bg-[#F9FBFF]">
                                                    <div className={`before-left-arrow cursor-pointer`}>
                                                        <div className="h-full flex items-center">
                                                            <p className="text-sm text-primary line-clamp-1">
                                                                #{item?.booking_id ? item?.booking_id?.slice(0, 8) : ''}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-1 pr-3">
                                                            {item?.client_name ? item?.client_name : "----"}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-2">
                                                            {item?.booking_date ? moment(item?.booking_date).format('DD-MM-YYYY') : "----"}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm">
                                                            {item?.subtotal ? (+item?.subtotal)?.toFixed(2) : 0}
                                                            {" "}
                                                            {selectedLocationCurrency}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm ">
                                                            {item?.payment_status ? item?.payment_status : "----"}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm ">
                                                            {item?.payment_date ? moment(item?.payment_date).format('DD-MM-YYYY') : "----"}
                                                        </p>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <LoginBtn
                                                            // loading={submitLoader}
                                                            onClick={() => {
                                                                navigate(`/dashboard/calendar/appointment-detail/${item?.appointment_services?.id}/?parent_id=${item?.appointment}`)
                                                            }}
                                                            disabled={item?.payment_status == "Paid" ? true : false}
                                                            text={selectedLanguage?.name == "English" ? "Checkout" : translation[182]?.value ? translation[182]?.value : "Checkout"}
                                                            customThemeBtn={`theme-btn !px-6 !rounded-[10px] !shadow-[2px_3px_10px_0px_#5679FF80] ${item?.payment_status == "Paid" ? "opacity-50" : ""}`}
                                                        />
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <LoginBtn
                                                            // loading={submitLoader}
                                                            onClick={() => {
                                                                setAppointmentDetailData({
                                                                    appointment_id: item?.appointment,
                                                                    service_id: item?.appointment_services?.id,
                                                                })
                                                                setAppointmentDetail(true)
                                                            }}
                                                            customThemeBtn={"theme-btn !px-10 !rounded-[10px] !shadow-[2px_3px_10px_0px_#5679FF80]"}
                                                            // disabled={submitLoader}
                                                            text={selectedLanguage?.name == "English" ? "View" : translation[1579]?.value ? translation[1579]?.value : "View"}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <>
                                            <NoAppointmentRecordFound className={'absolute h-4/5 w-full'} />
                                        </>
                                    )}
                                </div>
                            </div>
                        }
                        {/* Pagination */}
                        {(data?.length > 0 && !loader) &&
                            <Pagination
                                currentPage={currentPage}
                                totalCount={Math.ceil(pages)}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        }

                    </div>
                </div>
            </Popup>
            {appointmentDetail && (
                <AppointmentDetailPopup
                    open={appointmentDetail}
                    onClose={setAppointmentDetail}
                    appGroupId={appointmentDetailData?.appointment_id}
                    serviceId={appointmentDetailData?.service_id}
                    // onUpdateParam={handleParamUpdate}
                    view={true} />)
            }
        </>
    )
}

const PaidUnpaidAppointment = ({ paidUnpaidAppointmentpopup, setPaidUnpaidAppointmentPopup }) => {
    const state = useSelector((state) => state);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>

            {paidUnpaidAppointmentpopup && (
                <PaidUnpaidAppointmentPopup
                    onClose={() => {
                        setPaidUnpaidAppointmentPopup(false);
                    }}
                    open={paidUnpaidAppointmentpopup}
                />
            )}
        </>
    );
};

export default PaidUnpaidAppointment