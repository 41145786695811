import React from "react";
import Svgs from "../../../Assets/svgs";

const Popup = ({ customPadding, z, open, close, removeClose, heading, children, size, onclose, noanimation, header, customHeight, customSizeStyle }) => {
  return (
    <>
      {open ? (
        <div
          className={`fixed inset-0 h-[100vh] w-[100vw] ${z ? z : "z-[4444]"
            } flex justify-center items-center`}
        >
          <div
            className="bg-[#101928]/30 absolute inset-0 z-[1]"
          // onClick={() => { close(false); onclose() }}
          ></div>
          <div
            className={`relative overflow-hidden bg-white shadow-2xl rounded-2xl ${customSizeStyle ? customSizeStyle : size == "md"
              ? "lg:w-[45vw] xl:w-[40vw]"
              : size == "lg"
                ? "lg:w-[65vw]"
                : size == "xl"
                  ? "lg:w-[90vw]"
                  : size == "sm" ? "lg:w-fit" : "lg:w-[50vw]"
              } w-[90vw] z-[2] ${!noanimation && 'slide-in-elliptic-top-fwd'} ${customHeight ? customHeight : ''}`}
          >
            {header && <div className="sticky top-0 bg-white z-[22]">
              {!removeClose &&
                <div
                  className="absolute top-0 right-0 bg-primary px-[1.6rem] py-[1rem] rounded-bl-2xl cursor-pointer"
                  onClick={() => {
                    close(false);
                    onclose();
                  }}
                >
                  <Svgs.Close />
                </div>
              }
              <h1
                className={`${size == "md" ? "p-[1rem]" : "p-[1.2rem]"
                  } normal-case font-semibold text-xl text-left pr-[4.15rem]`}
              >
                {heading ? heading : "Add prop heading"}
              </h1>
              <hr />
            </div>}
            <div className={`${customPadding ? customPadding : ''} p-[1.2rem] overflow-y-auto overflow-x-hidden scroll-hidden lg:max-h-[75vh] max-h-[65vh]`}>
              {children ? children : "Add prop children!"}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

Popup.defaultProps = {
  close: () => { },
  open: true,
  header: true,
  children: <></>,
  onclose: () => { },
  removeClose: false
};

export default Popup;
