import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import moment from 'moment';
import React from 'react'

const ProductTable = ({ data, setCheckoutId, location_currency, isFromClient }) => {
    return (
        <div className="overflow-x-auto">
            <div className={`inline-grid xl:grid 
            ${isFromClient ? "grid-cols-[repeat(6,minmax(115px,1fr))]" : "grid-cols-[repeat(8,minmax(115px,1fr))]"}
             px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                <p>Invoice ID</p>
                {!isFromClient ?
                    <>
                        <p>Client</p>
                        <p>Client Type</p>
                    </> : ""}
                <p>Sold Date</p>
                <p>Sold By</p>
                <p className='col-span-2'>Product Name</p>
                <p>Price</p>
            </div>
            <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
            >
                {data?.length > 0 ? (
                    data?.map((item, index) => {
                        return (
                            <div key={index} className={`px-12 py-3 inline-grid xl:grid       
                               ${isFromClient ? "grid-cols-[repeat(6,minmax(115px,1fr))]" : "grid-cols-[repeat(8,minmax(115px,1fr))]"}`}>
                                <div className={`before-left-arrow cursor-pointer`}>
                                    <div className="h-full flex items-center">
                                        <p
                                            className="text-xs text-[#5679FF] font-black line-clamp-1"
                                            onClick={() => {
                                                setCheckoutId(item?.checkout_id)
                                            }}
                                        >
                                            #{item?.invoice_id}
                                        </p>
                                    </div>
                                </div>
                                {!isFromClient ?
                                    <>
                                        <p className="text-xs line-clamp-1 pr-3 capitalize">
                                            {item?.client_type == "In_Saloon"
                                                ? item?.client
                                                : "Walk In"}
                                        </p>
                                        <p className="text-xs line-clamp-1 pr-3">
                                            {item?.client_type == "In_Saloon"
                                                ? "In Saloon"
                                                : "Walk In"}
                                        </p>
                                    </> : ""}
                                <p className="text-xs">
                                    {item?.sold_date ? moment(item?.sold_date).format("DD-MM-YYYY") : "----"}
                                </p>
                                <p className="text-xs line-clamp-2">
                                    {item?.sold_by}
                                </p>
                                <p className="text-xs line-clamp-2 col-span-2">
                                    {item?.product_name}
                                </p>
                                <p className="text-xs">
                                    {item?.price} {location_currency}
                                </p>
                            </div>
                        );
                    })
                ) : (
                    <NoReportDataFound />
                )}
            </div>
        </div>
    )
}

export default ProductTable
