import { get_discount_promotions } from "Adapters/Api/clientDiscountPromotion";
import Popup from "Components/TenantApp/Elements/Popup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PromotionCardShimmer from "Components/Utility/Shimmers/PromotionCardShimmer";
import DiscountPromotion from "./DiscountPromotion";

const DiscountPopup = ({
    availOfferPopup,
    setAvailOfferPopup,
    SetData,
    MODULE_TYPE,
    appointmentData,
    cartData,
    setCartData,
    appointmentServices,
    setAppointmentService
}) => {
    const dispatch = useDispatch()

    const [promotions_data, setPromotionsData] = useState({})
    const [promotions_updated, setPromotionsUpdated] = useState(false)

    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    useEffect(() => {
        let date = appointmentData?.appointment_date
        let day = appointmentData?.selected_day

        if (!date) {
            let new_date = new Date()
            let today_year = new_date.getFullYear()
            let today_month = new_date.getMonth()
            let today_date = new_date.getDate()
            date = `${today_year}-${today_month + 1}-${today_date}`
        }

        if (!day) {
            day = ''
        }

        dispatch(
            get_discount_promotions(
                {
                    selected_date: date,
                    selected_day: day
                },
                (result) => {
                    let MODULES = {
                        'SERVICE': ['directFlat', 'specificCategoryGroup', 'get_free_item_for_other_purchase', 'bundleDiscount', 'fixedPrice', 'specificBrandServiceGroup', 'purchase', 'spendSomeAmount', 'retailPromotion', 'userRestricted'],
                        'APPOINTMENT': ['directFlat', 'specificCategoryGroup', 'get_free_item_for_other_purchase', 'bundleDiscount', 'fixedPrice', 'specificBrandServiceGroup', 'purchase', 'spendSomeAmount', 'userRestricted', 'complimentaryVoucher', 'packages',],
                        'PRODUCT': ['directFlat', 'specificCategoryGroup', 'specificBrandServiceGroup', 'purchase', 'retailPromotion', 'userRestricted',],
                    }
                    let allowed_offers = [];

                    if (MODULE_TYPE === 'quickSales') {
                        // Exclude APPOINTMENT, take both PRODUCT and SERVICE
                        allowed_offers = [...MODULES['SERVICE'], ...MODULES['PRODUCT']];
                    } else {
                        allowed_offers = MODULES[MODULE_TYPE] || [];
                    }

                    let any_1_exist = false;

                    if (result) {
                        allowed_offers.forEach(offr => {
                            if (result[offr]?.length > 0) {
                                any_1_exist = true;
                            }
                        });
                    }
                    if (any_1_exist) {
                        setPromotionsUpdated(true);
                        setPromotionsData(result);
                    } else {
                        setPromotionsUpdated(true);
                    }
                },
                () => {
                    setPromotionsUpdated(true)
                }
            )
        )
    }, [])
    return (
        <>
            <Popup
                no
                size="md"
                heading={selectedLanguage?.name == "English" ? "Available Offer" : translation[1723]?.value ? translation[1723]?.value : "Available Offer"}

                open={availOfferPopup}
                close={setAvailOfferPopup}
            >
                {
                    promotions_updated ?
                        <>
                            <DiscountPromotion
                                promotions={promotions_data}
                                SetData={(all_data, extra_data) => {
                                    SetData(all_data, extra_data)
                                    setAvailOfferPopup(false)
                                }}
                                setAvailOfferPopup={setAvailOfferPopup}
                                appointmentData={appointmentData}
                                cartData={cartData}
                                setCartData={setCartData}
                                MODULE_TYPE={MODULE_TYPE}
                                appointmentServices={appointmentServices}
                                setAppointmentService={setAppointmentService}
                            />

                        </>
                        :
                        <>
                            <PromotionCardShimmer />
                            <PromotionCardShimmer />
                        </>
                }
            </Popup>
        </>
    );
};


DiscountPopup.defaultProps = {
    SetData: () => { },
}
export default DiscountPopup;
