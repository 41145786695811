import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import React, { useState, useEffect } from "react";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import NotFound from "Components/Utility/NotFound";
import moment from "moment";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";

const ByStaff = ({
  setSection,
  start,
  end,
  setStartDate,
  setEndDate,
  setMember,
  selectMember,
  staffCommissionList,
  currency,
  locationEmployee,
  totals,
  commissionReportData,
  loading,
  translation,
  selectedLanguage, handleExportClick,
  employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, employeePaginationData

}) => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);
  const [toggleDateRange, setToggleDateRange] = useState(false)
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <div className="border rounded-lg bg-white">
      <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b sm:flex-row flex-col gap-3">
        <div className="flex items-center justify-between flex-1 w-full md:gap-1 gap-4">
          <div
            onClick={() => setSection("ByStaff")}
            className="flex items-center sm:gap-4 gap-2 "
          >
            <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Staff Commission Summary" : translation[333]?.value ? translation[333]?.value : "Staff Commission Summary"}</h2>
            {loading ?
              <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                <p className="rounded-md h-5 w-12 mx-1"> </p>
              </div>
              :
              <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                <p className="text-primary">
                  {staffCommissionList?.length ? staffCommissionList?.length : 0}{" "}
                  {staffCommissionList?.length && staffCommissionList?.length > 1 ?
                    selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                    : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                  }
                </p>
              </div>
            }
          </div>
        </div>
        {loadingPermission ?
          <EmployeesHeadShimmer classOn1={'w-40 lg:w-44'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} />
          :
          <div className="flex items-center lg:justify-center justify-end gap-3 sm:w-fit w-full xs:flex-row flex-col">
            {/* <DateRangePicker
            value={[start, end]}
            clearIcon={
              <p
                onClick={() => {
                  if (start && end) setStartDate();
                  setEndDate();
                }}
              >
                {" "}
                {selectedLanguage?.name == "English" ? "clear" : translation[340]?.value ? translation[340]?.value : "clear"}{" "}
              </p>
            }
            onChange={(e) => {
              if (e && e.length > 0) {
                const from = moment(e[0])?.format("YYYY-MM-DD");
                const to = moment(e[1])?.format("YYYY-MM-DD");
                if (from && to) {
                  setStartDate(from);
                  setEndDate(to);
                }
              }
            }}
          /> */}
            <div className="mb-00 sm:flex-none flex-1 xs:w-fit w-full">
              <PaginationDropdown
                title=""
                className={'sm:w-[10rem] lg:w-[14rem]'}
                custom_min_width={'min-w-[15rem]'}
                value={selectMember}
                onChange={(e) => setMember(e.target.value)}
                placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                options={[
                  { label: selectedLanguage?.name == "English" ? "All Member" : translation[358]?.value ? translation[358]?.value : "All Member", value: "" },
                  ...locationEmployee?.map((empl, i) => {
                    return {
                      label: empl.full_name,
                      value: empl.id,
                      otherData: {
                        image: empl?.image,
                        DataOne: empl.email,
                        showImage: true,
                        index: i
                      },
                      email: empl.email,
                      mobile_number: empl.mobile_number
                    };
                  }),
                ]}
                setPage={setEmployeePage}
                page={employeePage}
                setDropdownSearch={setEmployeeSearch}
                dropdownSearch={employeeSearch}
                lastPage={employeePaginationData?.employeePages}
                currentPage={employeePaginationData?.employeeCurrentPage}
                loading={employeeLoader}
                customHeight={'h-[2rem]'}
              />
            </div>
            <div className='flex items-center gap-3 flex-1 xs:w-fit w-full'>
              <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none sm:flex-none flex-1'>
                <div className='p-2 relative gap-4 flex items-center  justify-between flex-1 sm:flex-none sm:justify-start'>
                  <p onClick={() => {
                    setToggleDateRange(!toggleDateRange)
                  }} className='cursor-pointer'>
                    {`${start ? moment(start).format("DD-MM-YYYY") : "--/--/----"} - ${end ? moment(end).format("DD-MM-YYYY") : "--/--/----"}`}
                  </p>
                  <span className='cursor-pointer' onClick={() => {
                    setStartDate()
                    setEndDate()
                  }}>Clear</span>

                  {
                    toggleDateRange && <>
                      <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                      <div className='absolute top-full right-0 z-[2]'>
                        <DateRangePicker
                          start={start}
                          end={end}
                          setStartDate={setStartDate}
                          setToggleDateRange={setToggleDateRange}
                          setEndDate={setEndDate} />
                      </div>
                    </>
                  }
                </div>
              </div>
              {exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) :
                !employee ? (
                  staffCommissionList?.length > 0 ?
                    <CSVLink
                      {...commissionReportData}
                      target="_blank"
                      onClick={() => {
                        // // setExportLoader(true)
                        handleExportClick()
                      }}
                    >
                      <LoginBtn
                        className="rounded-lg !py-[7px]"
                        bg="bg-white"
                        animation={false}
                        customThemeBtn={'theme-btn2'}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.ExportSvg />
                        </div>
                      </LoginBtn>
                    </CSVLink> :
                    <LoginBtn
                      className="rounded-lg !py-[7px]"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                      customThemeBtn={'theme-btn2'}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.ExportSvg />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}
            </div>
          </div>
        }
      </div>
      {loading ?
        <TableShimer cols={8} rows={15} colsHeight={10} className={"inline-grid xl:grid grid-cols-[repeat(8,minmax(185px,1fr))] text-sm font-semibold"} />
        : <div className="overflow-x-auto">
          <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(205px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
            <p>{selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}</p>
            <p>{selectedLanguage?.name == "English" ? "Service Sales Total" : translation[336]?.value ? translation[336]?.value : "Service Sales Total"}</p>
            <p>{selectedLanguage?.name == "English" ? "Service Commission Total" : translation[337]?.value ? translation[337]?.value : "Service Commission Total"}</p>
            <p>{selectedLanguage?.name == "English" ? "Product Sales Total" : translation[342]?.value ? translation[342]?.value : "Product Sales Total"}</p>
            <p>{selectedLanguage?.name == "English" ? "Product Commission Total" : translation[345]?.value ? translation[345]?.value : "Product Commission Total"}</p>
            <p>{selectedLanguage?.name == "English" ? "Voucher Sales Total" : translation[347]?.value ? translation[347]?.value : "Voucher Sales Total"}</p>
            <p>{selectedLanguage?.name == "English" ? "Voucher Commission Total" : translation[350]?.value ? translation[350]?.value : "Voucher Commission Total"}</p>
            <p>{selectedLanguage?.name == "English" ? "Commission Total" : translation[353]?.value ? translation[353]?.value : "Commission Total"}</p>
          </div>
          {staffCommissionList?.length > 0 ? (
            staffCommissionList?.map((itm) => {
              return (
                <div className={`odd-bg text-[#3A3A3A] transition-all w-fit`}>
                  <>
                    <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(8,minmax(205px,1fr))] min-w-full w-fit">
                      <div className="flex items-center">
                        <p className="text-sm">{itm?.full_name}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {parseFloat(itm?.service_sale_price?.toFixed(2))} {currency}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {parseFloat(itm?.product_sale_price?.service_commission?.toFixed(2))} {currency}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {parseFloat(itm?.product_sale_price?.product_sale_price?.toFixed(2))} {currency}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {parseFloat(itm?.product_sale_price?.product_commission?.toFixed(2))} {currency}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {parseFloat(itm?.voucher_sale_price?.toFixed(2))} {currency}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {parseFloat(itm?.product_sale_price?.voucher_commission.toFixed(2))} {currency}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {parseFloat(itm?.product_sale_price?.commission_total.toFixed(2))} {currency}
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              );
            })
          ) : (
            <NoReportDataFound />
          )}

          <div className="mt-[4rem] inline-grid xl:grid grid-cols-[repeat(8,minmax(205px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
            <p>{selectedLanguage?.name == "English" ? "Total" : translation[800]?.value ? translation[800]?.value : "Total"}</p>
            <p>{parseFloat(totals?.total_services_sales?.toFixed(2))}</p>
            <p>{parseFloat(totals?.total_services_commissions?.toFixed(2))}</p>
            <p>{parseFloat(totals?.total_products_sales?.toFixed(2))}</p>
            <p>{parseFloat(totals?.total_products_commissions?.toFixed(2))}</p>
            <p>{parseFloat(totals?.total_vouchers_sales?.toFixed(2))}</p>
            <p>{parseFloat(totals?.total_vouchers_commissions?.toFixed(2))}</p>
            <p>{parseFloat(totals?.total_commission?.toFixed(2))}</p>
          </div>
        </div>
      }
    </div>
  );
};

export default ByStaff;
