/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Svgs from "Assets/svgs";
import LocationSelection from "new-components/location";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Popup from "Components/TenantApp/Elements/Popup";
import AddTarget from "./AddTarget";
import useStoreTarget from "./helper";
import { MONTHS } from "./../../../../../../Constants/Data/Months";
import NotFound from "./../../../../../Utility/NotFound";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import CopyStoreTarget from "./AddTarget/CopyStoreTarget";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import moment from "moment";

const StoreTarget = () => {
  const navigate = useNavigate();
  const { AddStore_Target, setAddStore_Target, CopyStore_Target, setCopyStore_Target, AddNewTarget, setAddNewTarget, errors,
    onBlurHandler, storeData, setStoreData, handleChange, onSubmitStoreTarget, onEidtStateData, onUpdateStoreTarget, tierError,
    onDeleteHandler, searchText, setSearchText, is_delete, setDelete, setDeleteId, storeTargetData, yearDropdown, setTierError,
    locationDropdown, locationList, storeTargetList, editId, setEditId, searchYear, setSearchYear, searchLocation,
    setSearchLocation, monthFilter, setMonthFilter, store_target_fiter, currency, CSV_data, selectedLocationCurrencyCode,
    currentPage, setCurrentPage, pages, allCount, PageSize, loader, handleExportClick, exportLoader, formRef, setErrors, isLoading
  } = useStoreTarget();

  const { location, store_tier } = storeData;

  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          second={selectedLanguage?.name == "English" ? "Target Control" : translation[569]?.value ? translation[569]?.value : "Target Control"}
          third={selectedLanguage?.name == "English" ? "Store Target" : translation[815]?.value ? translation[815]?.value : "Store Target"}
          url={'/dashboard/account/'}
          secondurl={'/dashboard/account/target-control'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <div className="flex items-center gap-4">
              <h2 className="text-primary font-bold lg:text-3xl text-xl">
                {selectedLanguage?.name == "English" ? "Store Target" : translation[815]?.value ? translation[815]?.value : "Store Target"}
              </h2>
              {/* <LocationSelection /> */}
            </div>
          </div>
          <div className="flex items-center sm:gap-4 gap-2">
            {/* <LoginBtn onClick={() => { setCopyStore_Target(!CopyStore_Target) }} className='rounded-lg border-2 border-primary text-white' >
                            <div className='flex items-center gap-2'>
                                <Svgs.Copy />
                                <p className='font-semibold'>Copy Store Target</p>
                            </div>
                        </LoginBtn> */}
            {loadingPermission ?
              <Shimmer className={'w-44 lg:w-48'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              : !employee || (employee && employeePermissions?.target_control_store?.includes("create")) ? (
                <LoginBtn
                  onClick={() => {
                    setAddStore_Target(!AddStore_Target);
                    setEditId();
                  }}
                >
                  <div className="flex items-center gap-2">
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Add Store Target" : translation[853]?.value ? translation[853]?.value : "Add Store Target"}
                    </p>
                  </div>
                </LoginBtn>
              ) : ""
            }
          </div>
        </div>

        <div className="border rounded-lg bg-white">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Store Target" : translation[815]?.value ? translation[815]?.value : "Store Target"}
                </h2>

                {loader ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-[#101928]">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
              {loadingPermission ?
                <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-32 lg:!w-44 sm:!w-40'} classOn4={'!w-12 lg:!16'} has4Itm />
                :
                <div className="flex items-center gap-3 sm:w-fit w-full">
                  {/* <LoginInput
                  name="searchText"
                  value={searchText}
                  title=""
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                /> */}
                  <Dropwdown
                    placeholder={selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations"}
                    title=""
                    name="searchLocation"
                    value={searchLocation}
                    options={
                      locationDropdown && [
                        { label: selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations", value: "" },
                        ...locationDropdown,
                      ]
                    }
                    onChange={(e) => {
                      setSearchLocation(e.target.value);
                    }}
                  />
                  <Dropwdown
                    placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                    title=""
                    name="searchYear"
                    value={searchYear}
                    options={
                      yearDropdown && [
                        { label: selectedLanguage?.name == "English" ? "All Year" : translation[823]?.value ? translation[823]?.value : "All Year", value: "" },
                        ...yearDropdown,
                      ]
                    }
                    onChange={(e) => {
                      setSearchYear(e.target.value);
                    }}
                  />

                  <Dropwdown
                    title=""
                    placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                    showTitle={false}
                    value={monthFilter}
                    onChange={(e) => {
                      setMonthFilter(e.target.value);
                    }}
                    options={[
                      { label: selectedLanguage?.name == "English" ? "All Month" : translation[821]?.value ? translation[821]?.value : "All Month", value: "" },
                      ...MONTHS.map((mnth, i) => {
                        return {
                          label: mnth,
                          value: mnth,
                        };
                      }),
                    ]}
                  />
                  {exportLoader ? (
                    <div className="h-[4rem] flex items-center justify-center">
                      <PreloaderSm />
                    </div>
                  ) : !employee ? (
                    CSV_data?.length > 0 ?
                      <CSVLink
                        {...storeTargetData}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <LoginBtn
                          className="rounded-lg !py-[7px]"
                          bg="bg-white"
                          animation={false}
                          customThemeBtn={'theme-btn2'}
                        >
                          <div className="flex items-center gap-2 text-[#FFFFFF]">
                            <Svgs.ExportSvg />
                          </div>
                        </LoginBtn>
                      </CSVLink> :
                      <LoginBtn
                        className="rounded-lg !py-[7px]"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                        customThemeBtn={'theme-btn2'}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.ExportSvg />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}

                  {/* <LoginBtn className='rounded-lg border-2 border-primary' bg='bg-white' >
                                <div className='flex items-center gap-2 text-[#101928]'>
                                    <Svgs.Import />
                                </div>
                            </LoginBtn> */}
                </div>
              }
            </div>
          </div>
          {loader ?
            <TableShimer cols={5} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid gap-3 grid-cols-[repeat(7,minmax(145px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                {/* <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p> */}
                <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
                <p>{selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}</p>
                <p>{selectedLanguage?.name == "English" ? "Retail" : translation[604]?.value ? translation[604]?.value : "Retail"}</p>
                <p>{selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}</p>
                <p>{selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"}</p>
                <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
                {!employee ||
                  (employee &&
                    employeePermissions?.target_control_store?.includes("edit")) ? (
                  <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                ) : (
                  ""
                )}
              </div>
              {store_target_fiter()?.length > 0 ? (
                store_target_fiter()?.map((str) => {
                  const currentMonth = moment().month(); // 0-based index (0 = January, 1 = February, etc.)
                  const currentYear = moment().year(); // Example: 2024

                  const itmCurrency = locationList?.find(loc => loc?.id == str?.location?.id)?.currency?.code
                  return (
                    <>
                      <div className={`odd-bg text-[#3A3A3A] transition-all px-12 py-3 inline-grid xl:grid gap-3 grid-cols-[repeat(7,minmax(145px,1fr))] min-w-full w-fit`}>
                        {/* <div className="flex items-center cursor-pointer">
                          <p className="text-sm">
                            {str?.id.toString().split("-")[0]}
                          </p>
                        </div> */}
                        <div className="flex items-center">
                          <p className="text-sm">{str?.location?.address_name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {str?.tier?.map((elm) => <p>{elm?.service_target} {itmCurrency}</p>)}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {str?.tier?.map((elm) => <p>{elm?.retail_target} {itmCurrency}</p>)}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {str?.tier?.map((elm) => <p>{elm?.voucher_target} {itmCurrency}</p>)}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {str?.tier?.map((elm) => <p>{elm?.membership_target} {itmCurrency}</p>)}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {str?.tier?.map((elm) => <p>{elm?.month} , {elm?.year?.slice(0, 4)}</p>)}{" "}
                            {/* {str?.tier?.map((elm) => elm?.year) &&
                            }{" "} */}
                          </p>
                        </div>
                        {str?.tier?.some((elm) => {
                          const itemMonth = moment().month(elm?.month).month();
                          const itemYear = parseInt(elm?.year?.slice(0, 4), 10);
                          return itemYear > currentYear || (itemYear === currentYear && itemMonth >= currentMonth);
                        }) ?
                          <div className="flex items-center gap-2">
                            {!employee ||
                              (employee &&
                                employeePermissions?.target_control_store?.includes(
                                  "edit"
                                )) ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                onClick={() => {
                                  onEidtStateData(str);
                                }}
                              >
                                <Svgs.Pen />
                              </div>
                            ) : (
                              ""
                            )}
                            {!employee ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center"
                                onClick={() => {
                                  setDelete(true);
                                  setDeleteId(str?.id);
                                }}
                              >
                                <Svgs.Delete />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          : "-----"
                        }
                      </div>
                    </>
                  );
                })
              ) : (
                <NoReportDataFound />
              )}

              {(store_target_fiter()?.length > 0 && !loader) &&
                <Pagination
                  currentPage={currentPage}
                  totalCount={Math.ceil(pages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              }
            </div>
          }
        </div>
      </div>

      {AddStore_Target && (
        <Popup
          heading={editId
            ? selectedLanguage?.name == "English" ? "Update Store Target" : translation[855]?.value ? translation[855]?.value : "Update Store Target"
            : selectedLanguage?.name == "English" ? "Add Store Target" : translation[853]?.value ? translation[853]?.value : "Add Store Target"
          }
          close={() => {
            setAddStore_Target(false);
            setStoreData({
              location: "",
              store_tier: [{
                month: "",
                service_target: "",
                retail_target: "",
                voucher_target: "",
                membership_target: "",
                is_primary: false,
              }],
            });
            setErrors()
            setTierError()
          }}
          open={AddStore_Target}
        >
          <div className="flex flex-col gap-4">
            <Dropwdown
              title={`${selectedLanguage?.name == "English" ? "Store" : translation[822]?.value ? translation[822]?.value : "Store"}`}
              placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[380]?.value ? translation[380]?.value : "Select Stores"}
              name={"location"}
              required={true}
              value={location}
              onChange={handleChange}
              onBlur={() => onBlurHandler({ location: location })}
              error={errors?.location}
              options={locationList?.map((itm) => {
                return {
                  label: itm?.address_name,
                  value: itm?.id,
                };
              })}
            />
            {store_tier?.map((ties, i) => {
              return (
                <AddTarget
                  selectedLanguage={selectedLanguage}
                  translation={translation}
                  yearDropdown={yearDropdown}
                  onBlurHandler={onBlurHandler}
                  errors={tierError}
                  ties={ties}
                  data={store_tier}
                  location={location}
                  store_tier={store_tier}
                  currency={selectedLocationCurrencyCode}
                  id={i + 1}
                  index={i}
                  formRef={formRef}
                  setTierError={setTierError}
                  onUpdate={({ name, value }) => {
                    setStoreData((prev) => ({
                      ...storeData,
                      store_tier: [
                        ...storeData?.store_tier?.map((itm, ind) => {
                          if (i === ind) {
                            if (name == "year") {
                              return {
                                ...itm,
                                month: "",
                                [name]: value,
                              };
                            } else {
                              return {
                                ...itm,
                                [name]: value,
                              };
                            }
                          } else {
                            return itm;
                          }
                        }),
                      ],
                    }));
                  }}
                />
              );
            })}

            {storeData?.store_tier?.length < 12 &&
              <h4
                className="cursor-pointer flex items-center gap-2 text-sm text-primary"
                onClick={() => {
                  if (storeData?.store_tier?.length < 12) {
                    setAddNewTarget([...AddNewTarget, 1]);
                    setStoreData((prev) => ({
                      ...prev,
                      store_tier: [
                        ...prev?.store_tier,
                        {
                          month: "",
                          service_target: "",
                          retail_target: "",
                          voucher_target: "",
                          membership_target: "",
                          is_primary: false,
                        },
                      ],
                    }));
                  }
                }
                }
              >
                {selectedLanguage?.name == "English" ? "Add Target" : translation[854]?.value ? translation[854]?.value : "Add Target"}
                <div>
                  {" "}
                  <Svgs.Plus color="#1C8AFE" />
                </div>
              </h4>
            }
            <LoginBtn
              // disabled={
              //   !storeData?.location ||
              //   storeData?.store_tier.length === 0 ||
              //   storeData?.store_tier?.find(
              //     (itm) =>
              //       !itm?.month ||
              //       !itm?.service_target ||
              //       !itm?.retail_target ||
              //       !itm?.voucher_target ||
              //       !itm?.membership_target
              //   )
              // }
              text={editId ?
                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
              }
              loading={isLoading}
              disabled={isLoading}
              onClick={() => {
                if (editId) {
                  onUpdateStoreTarget();
                } else {
                  onSubmitStoreTarget();
                }
              }}
            />
          </div>
        </Popup>
      )}

      {CopyStore_Target && (
        <CopyStoreTarget onClose={() => setCopyStore_Target(false)} />
      )}

      {is_delete && (
        <Popup
          heading={selectedLanguage?.name == "English" ?
            "Are you sure want to delete this Store targets?"
            : translation[862]?.value
              ? translation[862]?.value
              : "Are you sure want to delete this Store targets?"
          }

          onclose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English" ?
                "Are you sure want to delete this Store targets? You won't be able to see this again."
                : translation[863]?.value
                  ? translation[863]?.value
                  : "Are you sure want to delete this Store targets? You won't be able to see this again."
              }
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  onDeleteHandler();
                  setTimeout(() => {
                    setDelete(false);
                  }, 500);
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default StoreTarget;
