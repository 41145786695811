import React from 'react'
import Shimmer from 'Components/Utility/Shimmers/Shimmer';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Svgs from 'Assets/svgs';
import Pagination from 'new-components/Pagination';
import NoPurchaseOrderDataFound from 'Components/Utility/NoPurchaseOrderDataFound';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import moment from 'moment';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';
import useServiceRefundInvoice from './helper';

const ServiceRefundInvoice = () => {
    const {
        selectedLanguage,
        translation,
        location_currency,
        refunded_record,
        serviceGroupDropdown,
        allCount,
        pages,
        isLoading,
        filterData, setFilterData,
        loadingPermission,
        currentPage, setCurrentPage,
        setServiceGroupSearchText,
        setServiceGroupCurrentPage,
        serviceGroupCurrentPage,
        serviceGroupSearchText,
        serviceGroupPaginationData,
        serviceGroupLoader,
    } = useServiceRefundInvoice()
    return (
        <>
            <div className="border rounded-lg mt-3 bg-white">
                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row  gap-3">
                    <div className="flex sm:items-center justify-between flex-col sm:flex-row flex-1 w-full md:gap-0 gap-4 ">
                        <div className="flex items-center sm:gap-4 gap-2 ">
                            <h2 className="font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Refund Services Details" : translation[1669]?.value ? translation[1669]?.value : "Refund Services Details"}
                            </h2>
                            {isLoading ?
                                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                                </div>
                                :
                                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                    <p className="text-primary">
                                        {allCount ? allCount : 0}{" "}
                                        {allCount && allCount > 1
                                            ? selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                            : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                        <div className="mb-00">
                            {loadingPermission ?
                                <Shimmer className={'w-44 lg:w-48'}>
                                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                                </Shimmer>
                                :
                                <div className='flex gap-2'>
                                <div className="flex-1">
                                    <LoginInput
                                        title=""
                                        placeholder={selectedLanguage?.name == "English" ? "Search Service" : translation[1266]?.value ? translation[1266]?.value : "Search Service"}
                                        padding="py-1"
                                        value={filterData?.text}
                                        onChange={(e) => {
                                            setFilterData({ ...filterData, text: e.target.value });
                                        }}
                                        leftIcon={<Svgs.Search />}
                                    />
                                </div>
                                <div className="flex-1">
                                    <PaginationDropdown
                                        title=''
                                        placeholder={selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
                                        options={serviceGroupDropdown}
                                        value={filterData.service_group}
                                        onChange={(e) => { setFilterData({ ...filterData, service_group: e.target.value }) }}
                                        setDropdownSearch={setServiceGroupSearchText}
                                        setPage={setServiceGroupCurrentPage}
                                        page={serviceGroupSearchText}
                                        dropdownSearch={serviceGroupCurrentPage}
                                        lastPage={serviceGroupPaginationData?.total_pages}
                                        currentPage={serviceGroupPaginationData?.currentPage}
                                        loading={serviceGroupLoader}
                                    />
                                </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {isLoading
                    ? <TableShimer cols={5} rows={12} colsHeight={10} />
                    : <div className="overflow-x-auto ">
                        <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                            <p>{selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Refunded Price" : translation[1453]?.value ? translation[1453]?.value : "Refunded Price"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Refund Type" : translation[1506]?.value ? translation[1506]?.value : "Refund Type"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                        </div>
                        {refunded_record?.length > 0
                            ? refunded_record?.map((srvc, i) => {
                                return (
                                    <div
                                        key={srvc.id}
                                        className={`odd-bg text-[#3A3A3A] transition-all `}
                                    >
                                        <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))] min-w-full w-fit">
                                            <div className="h-full flex items-center">
                                                <p className="text-sm">{srvc?.service_data?.service_name} </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{`${srvc?.refunded_amount} ${location_currency}`}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{srvc?.refund_data?.refund_type}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{`${srvc?.refund_data?.client ? srvc?.refund_data?.client : '--------'}`}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{srvc?.service_data?.service_group ? srvc?.service_data?.service_group:'--------'}</p>
                                            </div>

                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {moment(srvc?.created_at).format('DD-MM-YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : <NoPurchaseOrderDataFound />
                        }

                        <Pagination
                            currentPage={currentPage}
                            totalCount={Math.ceil(pages)}
                            pageSize={10}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default ServiceRefundInvoice