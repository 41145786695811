import React from 'react'
import Popup from 'Components/TenantApp/Elements/Popup'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import useCreateComplainCategoryPopup from './helper'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'

const CreateComplaintCategoryPopup = ({ open, close, onSucessCallBack }) => {
    const { submitLoading, formData, onChangeHandler, onSubmit, statusOptions, errors } = useCreateComplainCategoryPopup(close, open, onSucessCallBack)

    return (
        <>
            <Popup open={open} close={close} heading={"Create Complaint Category"} size={'md'}>
                <div className="flex flex-col gap-6">
                    <LoginInput
                        name={'name'}
                        error={errors?.name}
                        value={formData?.name}
                        title={'Category Name'}
                        charactersMaxLength={50}
                        onChange={onChangeHandler}
                        placeholder={'Enter Category Name'}
                    />

                    <Dropwdown
                        required={true}
                        name={'status'}
                        error={errors?.status}
                        options={statusOptions}
                        value={formData?.status}
                        title={'Category Status'}
                        onChange={onChangeHandler}
                        placeholder={'Select Category Status'}
                    />

                    <LoginBtn
                        text={'Submit'}
                        title={'Submit'}
                        onClick={onSubmit}
                        loading={submitLoading}
                    />
                </div>

            </Popup>
        </>
    )
}

export default CreateComplaintCategoryPopup