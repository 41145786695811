import Svgs from 'Assets/svgs'
import AutoPlaceInput from 'Components/TenantApp/Elements/AutoPlaceInput'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import { DESIGNATIONS } from 'Constants/Data/Designation'
import React from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

const EmployeeStep = ({ errors, isValidEmail, setFormData, handleChange, formData, setErrors, servicesList, handleAssignedServicesChange, removeEmployeeAssignedService }) => {
  const handleAddressSuggetion = async (value) => {
    const result = await geocodeByAddress(value);
    setFormData((prev) => ({
      ...prev,
      employee: {
        ...prev.employee,
        address: value
      },
    }));
  };

  const handleLocationChange = (e, fieldName = "") => {
    if (e?.target) {
      const { name, value } = e?.target;
      setFormData((prev) => ({
        ...prev,
        employee: {
          ...prev.employee,
          [name]: value,
        },
      }));
    }
  };
  return (
    <div className='my-[2rem] flex flex-col gap-4'>
      <h1 className='font-semibold text-xl'>Setup your first employee</h1>
      <div className='grid lg:grid-cols-2 gap-4'>
        <div>
          <LoginInput
            required={true}
            specialCharacterNotAllowed={true}
            value={formData?.employee?.name}
            name={'name'}
            // charactersMaxLength={25}
            onChange={(e) => { handleChange(e, 'employee') }}
            title={'Employee Name'}
            error={(!formData?.employee?.name && errors?.name) && errors?.name}
            placeholder={'Enter Name'} />
        </div>
        <div>
          <LoginInput
            required={false}
            value={formData?.employee?.email}
            name={'email'}
            type={"email"}
            onChange={(e) => {
              handleChange(e, 'employee')
              setErrors((prev) => ({
                ...prev,
                email: ""
              }))
            }}
            title={'Email'}
            error={!isValidEmail ? 'Email is invalid' : errors?.email}
            placeholder={'Enter Email'}
          />
        </div>
      </div>
      <div>
        <AutoPlaceInput
          onChange={(e) => { handleChange(e, 'employee') }}
          name={"address"}
          value={formData?.employee?.address}
          required={true}
          error={(!formData?.employee?.address && errors?.address) && errors?.address}
          title="Address"
          placeholder="Enter Business Address"
        />
      </div>
      <div>
        <Dropwdown
          required={true}
          options={DESIGNATIONS}
          value={formData?.employee?.designation}
          name={'designation'}
          onChange={(e) => { handleChange(e, 'employee') }}
          title={'Designation'}
          error={errors?.designation}
          placeholder={'Add Designation'} />
      </div>
      <div className='grid lg:grid-cols-2 gap-4'>
        <div>
          <Dropwdown
            required={true}
            value={formData?.employee?.income_type}
            name={'income_type'}
            error={errors?.income_type}
            onChange={(e) => { handleChange(e, 'employee') }}
            options={[
              { value: "Hourly_Rate", label: "Hourly Rate" },
              { value: "Daily_Income", label: "Daily Income" },
              { value: "Monthly_Salary", label: "Monthly Salary" },
            ]}
            title={'Income Type'}
            placeholder={'Select Type'} />
        </div>
        <div>
          <FloatInput
            required={true}
            error={(!formData?.employee?.salary && errors?.salary) && errors?.salary}
            value={formData?.employee?.salary}
            name={'salary'}
            onChange={(e) => { handleChange(e, 'employee') }}
            title={'Salary'}
            placeholder={'Enter Salary'} />
        </div>
      </div>
      <div>
        <Dropwdown
          required={true}
          options={
            servicesList
              ?.filter(
                (itm) =>
                  !formData?.employee?.assigned_services?.find(
                    (s_itm) => s_itm.id === itm.id
                  )
              )
              ?.map((srvc) => ({
                label: srvc.name,
                value: srvc.id,
              }))
          }
          value={formData?.employee?.assigned_services}
          onChange={handleAssignedServicesChange}
          error={(formData?.employee?.assigned_services?.length == 0 && errors?.assigned_service) && errors?.assigned_service}
          name={'assigned_services'}
          title={'Assign Services'}
          placeholder={'Select Services'}
        />
      </div>
      {formData?.employee?.assigned_services?.length > 0 && (
        <div className="flex items-center flex-wrap gap-2">
          {formData?.employee?.assigned_services &&
            formData?.employee?.assigned_services?.map((item) => {

              return (
                <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                  <p className="text-primary text-sm font-bold">
                    {item?.name}
                  </p>
                  <span
                    onClick={() =>
                      removeEmployeeAssignedService(item?.id, "assigned_services")
                    }
                  >
                    <Svgs.CirlceClose fill="#5679FF" />
                  </span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  )
}

export default EmployeeStep