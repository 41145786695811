import Svgs from "Assets/svgs";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import React from "react";
import { useNavigate } from "react-router-dom";
import LoginBtn from "../../Elements/LoginBtn";

const Layout = ({
  title,
  heading,
  loading,
  subHeading,
  children,
  count,
  NextClick,
  btnInner,
  disabled,
  backClick,
  className,
  parent_className,
}) => {
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const next = getDataFromLocalStorage("next");

  return (
    <div className={`pb-[4rem] ${parent_className}`}>
      <div className="bg-[#F2F5FF] pb-[4rem] pt-[3rem] relative">
        {/* <div
          onClick={() => {
            if (access_token) {
              navigate("/dashboard/marketplace-profile");
            } else {
              next && navigate(`${next}`);
            }
          }}
          className="top-6 right-6 cursor-pointer absolute"
        >
          <Svgs.Close fill="#101928" />
        </div> */}
        <div className="w-[80%] mx-auto d-flex items-center">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center gap-4 mb-6 ">
              <div className="flex gap-2 items-center">
                <div className="w-fit cursor-pointer" onClick={backClick}>
                  <Svgs.Back />
                </div>
                <h1 className="text-primary text-2xl font-bold normal-case">{heading}</h1>
                {/* <p className="text-xl text-[#101928]/60 font-semibold">{title}</p> */}
              </div>
              <div
                onClick={() => {
                  if (access_token) {
                    navigate("/dashboard/marketplace-profile");
                  } else {
                    next && navigate(`${next}`);
                  }
                }}
                className="cursor-pointer"
              >
                <Svgs.Close fill="#101928" />
              </div>
            </div>

            <div className="text-primary flex items-center justify-between">
              <p className="text-sm text-[#101928]/60 normal-case">{subHeading}</p>
              {/* <h1 className="text-2xl font-bold normal-case">{heading}</h1> */}
              <h1>{count}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className={`flex flex-col gap-4 w-[80%] mx-auto bg-white shadow-md mt-[-2.5rem] relative rounded-lg border p-5 ${className}`}
        >
          {children}
        </div>
        <div className="w-[80%] mx-auto flex justify-end mt-[1.5rem]">
          <LoginBtn
            loading={loading}
            disabled={disabled}
            onClick={NextClick}
            text={btnInner ? btnInner : "Next"}
          />
        </div>
      </div>
    </div>
  );
};

export default Layout;
