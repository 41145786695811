import Svgs from "Assets/svgs";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import moment from "moment";
import NotFound from "Components/Utility/NotFound";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";

const CommissionDetailed = ({
  commissionDetailList,
  setSection,
  currency,
  start,
  end,
  setStartDt,
  setcloseDate,
  selectEmployee,
  setSelectEmployee,
  locationEmployee,
  loading,
  itemType,
  setItemType,
  commissionReportDetail,
  isPopup,
  setIsPopup,
  selectedItem,
  setSeletedItem,
  invoiceLabels,
  translation,
  selectedLanguage,
  pages,
  currentPage,
  setCurrentPage,
  allCount,
  PageSize,
  handleExportClick,
  allCommissionReportByDetail,
  employeeSearch,
  setEmployeeSearch,
  employeePage,
  setEmployeePage,
  employeeLoader,
  employeePaginationData,
  getInvoiceDetailData
}) => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);
  const [toggleDateRange, setToggleDateRange] = useState(false);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true);
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated);
  }, [isPermissionUpdated]);
  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <div className="border rounded-lg bg-white">
      <div className="px-4 lg:px-6 py-2 flex items-start justify-between border-b flex-row flex-wrap md:flex-nowrap gap-3">
        <div className="flex items-center justify-between flex-1 w-full md:gap-1 gap-4 min-w-[18.75rem]">
          <div
            onClick={() => setSection("CommissionDetailed")}
            className="flex items-center sm:gap-4 gap-2 "
          >
            <h2 className="font-semibold text-xl ">
              {selectedLanguage?.name == "English"
                ? "Staff Commission Detailed"
                : translation[356]?.value
                  ? translation[356]?.value
                  : "Staff Commission Detailed"}
            </h2>
            {loading ? (
              <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                <p className="rounded-md h-5 w-12 mx-1"> </p>
              </div>
            ) : (
              <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                <p className="text-primary">
                  {allCount ? allCount : 0}{" "}
                  {allCount && allCount > 1
                    ? selectedLanguage?.name == "English"
                      ? "Records"
                      : translation[497]?.value
                        ? translation[497]?.value
                        : "Records"
                    : selectedLanguage?.name == "English"
                      ? "Record"
                      : translation[1320]?.value
                        ? translation[1320]?.value
                        : "Record"}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end md:w-fit w-full">
          {loadingPermission ? (
            <EmployeesHeadShimmer
              classOn1={"w-40 lg:w-44"}
              classOn2={"!w-32 lg:!w-44 sm:!w-40"}
              classOn3={"!w-32 lg:!w-44 sm:!w-40"}
              classOn4={"!w-12 lg:!16"}
              has4Itm
            />
          ) : (
            <div className="flex items-center justify-end gap-3 w-fit flex-wrap">
              <PaginationDropdown
                title=""
                className={"sm:w-[10rem] lg:w-[14rem] flex-1"}
                custom_min_width={"min-w-[15rem]"}
                value={selectEmployee}
                onChange={(e) => {
                  setSelectEmployee(e.target.value);
                }}
                placeholder={
                  selectedLanguage?.name == "English"
                    ? "Select Staff"
                    : translation[334]?.value
                      ? translation[334]?.value
                      : "Select Staff"
                }
                options={[
                  {
                    label:
                      selectedLanguage?.name == "English"
                        ? "All Member"
                        : translation[358]?.value
                          ? translation[358]?.value
                          : "All Member",
                    value: "",
                  },
                  ...locationEmployee?.map((empl, i) => {
                    return {
                      label: empl.full_name,
                      value: empl.id,
                      otherData: {
                        image: empl?.image,
                        DataOne: empl.email,
                        showImage: true,
                        index: i,
                      },
                      email: empl.email,
                      mobile_number: empl.mobile_number,
                    };
                  }),
                ]}
                setPage={setEmployeePage}
                page={employeePage}
                setDropdownSearch={setEmployeeSearch}
                dropdownSearch={employeeSearch}
                lastPage={employeePaginationData?.employeePages}
                currentPage={employeePaginationData?.employeeCurrentPage}
                loading={employeeLoader}
                customHeight={"h-[2rem]"}
              />
              <Dropwdown
                title=""
                showTitle={false}
                placeholder={
                  selectedLanguage?.name == "English"
                    ? "Item Type"
                    : translation[357]?.value
                      ? translation[357]?.value
                      : "Item Type"
                }
                value={itemType}
                onChange={(e) => setItemType(e.target.value)}
                options={[
                  {
                    label:
                      selectedLanguage?.name == "English"
                        ? "All Types"
                        : translation[359]?.value
                          ? translation[359]?.value
                          : "All Types",
                    value: "",
                  },
                  {
                    label:
                      selectedLanguage?.name == "English"
                        ? "Product"
                        : translation[360]?.value
                          ? translation[360]?.value
                          : "Product",
                    value: "Retail",
                  },
                  {
                    label:
                      selectedLanguage?.name == "English"
                        ? "Service"
                        : translation[361]?.value
                          ? translation[361]?.value
                          : "Service",
                    value: "Service",
                  },
                  {
                    label:
                      selectedLanguage?.name == "English"
                        ? "Voucher"
                        : translation[193]?.value
                          ? translation[193]?.value
                          : "Voucher",
                    value: "Voucher",
                  },
                ]}
                customHeight={"h-[2rem]"}
              />
              {/* <DateRangePicker
            clearIcon={
              <p
                onClick={() => {
                  if (start && end) setStartDt();
                  setcloseDate();
                }}
              >
                {" "}
                {selectedLanguage?.name == "English" ? "clear" : translation[340]?.value ? translation[340]?.value : "clear"}{" "}
              </p>
            }
            value={[start, end]}
            onChange={(e) => {
              if (e && e.length > 0) {
                const from = moment(e[0]).format("YYYY-MM-DD");
                const to = moment(e[1]).format("YYYY-MM-DD");
                setStartDt(from);
                setcloseDate(to);
              }
            }}
          /> */}
              <div className="flex items-center gap-3">
                <div className="border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none">
                  <div className="p-2 relative gap-4 flex items-center">
                    <p
                      onClick={() => {
                        setToggleDateRange(!toggleDateRange);
                      }}
                      className="cursor-pointer"
                    >
                      {`${start
                        ? moment(start).format("DD-MM-YYYY")
                        : "--/--/----"
                        } - ${end ? moment(end).format("DD-MM-YYYY") : "--/--/----"
                        }`}
                    </p>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setStartDt();
                        setcloseDate();
                      }}
                    >
                      Clear
                    </span>

                    {toggleDateRange && (
                      <>
                        <div
                          className="fixed inset-0 z-1"
                          onClick={() => {
                            setToggleDateRange(false);
                          }}
                        ></div>
                        <div className="absolute top-full right-0 z-[2]">
                          <DateRangePicker
                            start={start}
                            end={end}
                            setStartDate={setStartDt}
                            setEndDate={setcloseDate}
                            setToggleDateRange={setToggleDateRange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) : !employee ? (
                  allCommissionReportByDetail?.filter((cmsn) =>
                    Object.keys(cmsn?.sale).length > 0 ? true : false
                  )?.length > 0 ? (
                    <CSVLink
                      {...commissionReportDetail}
                      target="_blank"
                      onClick={() => {
                        // // setExportLoader(true)
                        handleExportClick();
                      }}
                    >
                      <LoginBtn
                        className="rounded-lg !py-[7px]"
                        bg="bg-white"
                        animation={false}
                        customThemeBtn={'theme-btn2'}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.ExportSvg />
                        </div>
                      </LoginBtn>
                    </CSVLink>
                  ) : (
                    <LoginBtn
                      className="rounded-lg !py-[7px]"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                      customThemeBtn={'theme-btn2'}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.ExportSvg />
                      </div>
                    </LoginBtn>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <TableShimer
          cols={9}
          rows={11}
          colsHeight={10}
          className={
            "inline-grid xl:grid grid-cols-[repeat(9,minmax(185px,1fr))] text-sm font-semibold"
          }
        />
      ) : (
        <div className="overflow-x-auto">
            <div className="inline-grid gap-2 xl:grid grid-cols-[repeat(9,minmax(225px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
            <p>
              {selectedLanguage?.name == "English"
                ? "Staff"
                : translation[335]?.value
                  ? translation[335]?.value
                  : "Staff"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Sale Date"
                : translation[362]?.value
                  ? translation[362]?.value
                  : "Sale Date"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Invoice Number"
                : translation[363]?.value
                  ? translation[363]?.value
                  : "Invoice Number"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Item Sold"
                : translation[364]?.value
                  ? translation[364]?.value
                  : "Item Sold"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Item Type"
                : translation[357]?.value
                  ? translation[357]?.value
                  : "Item Type"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Quantity"
                : translation[365]?.value
                  ? translation[365]?.value
                  : "Quantity"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Sale Value"
                : translation[366]?.value
                  ? translation[366]?.value
                  : "Sale Value"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Commission Rate"
                : translation[367]?.value
                  ? translation[367]?.value
                  : "Commission Rate"}
            </p>
            <p>
              {selectedLanguage?.name == "English"
                ? "Commission Amount"
                : translation[368]?.value
                  ? translation[368]?.value
                  : "Commission Amount"}
            </p>
          </div>
          {commissionDetailList?.filter((cmsn) =>
            Object.keys(cmsn?.sale).length > 0 ? true : false
          )?.length > 0 ? (
            commissionDetailList
              ?.filter((cmsn) =>
                Object.keys(cmsn?.sale).length > 0 ? true : false
              )
              ?.map((itm, i) => {
                return (
                  <div className={`odd-bg text-[#3A3A3A] transition-all w-fit`}>
                    <>
                      <div className="px-12 py-3 inline-grid gap-2 xl:grid grid-cols-[repeat(9,minmax(225px,1fr))] min-w-full w-fit">
                        <div className="flex items-center">
                          <p className="text-sm">{itm?.employee?.full_name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {moment(itm?.sale?.created_at).format("DD-MM-YYYY")}
                          </p>
                        </div>
                        <div
                          className="flex items-center"
                          onClick={() => {
                            getInvoiceDetailData(itm?.invoice?.checkout)
                            // setSeletedItem(itm);
                            // setIsPopup(true);
                          }}
                        >
                          <p className="text-sm text-[#5679FF] font-black line-clamp-1">
                            {/* #{itm?.sale?.id?.split("-")[0]} */}#
                            {itm?.invoice?.id
                              ?.split("-")[0]
                              .split("")
                              .slice(0, 8)
                              .join("")}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">{itm?.sale?.name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">{itm?.sale?.order_type}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.sale?.quantity ? itm?.sale?.quantity : 0}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.sale?.price} {currency}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.commission_rate &&
                              itm?.commission_rate != "null"
                              ? itm?.commission_rate
                              : 0}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.commission?.toFixed(2)} {currency}
                          </p>
                        </div>
                      </div>
                    </>
                  </div>
                );
              })
          ) : (
            <NoReportDataFound />
          )}

          {commissionDetailList?.filter((cmsn) =>
            Object.keys(cmsn?.sale).length > 0 ? true : false
          )?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalCount={Math.ceil(pages)}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default CommissionDetailed;
