import StatusBtn from 'Components/TenantApp/Elements/StatusBtn';
import NoReportDataFound from 'Components/Utility/NoReportDataFound';
import moment from 'moment';
import React from 'react'

const AllTable = ({ data, setCheckoutId, location_currency }) => {
    return (
        <div className="overflow-x-auto">
            <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(115px,1fr))] px-12 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit">
                <p>Invoice ID</p>
                <p>Sold Date</p>
                <p>Sold By</p>
                <p className='col-span-2'>Item Name</p>
                <p>Item Type</p>
                <p>Price</p>
                <p>Expiry</p>
            </div>
            <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
            >
                {data?.length > 0 ? (
                    data?.map((item, index) => {
                        let expiryStatus
                        const now = moment();
                        if (item?.expiry) {
                            const expiry = moment(item?.expiry)
                            expiryStatus = expiry?.isValid() ? (expiry.isBefore(now) ? "Expired" : "Active") : null;
                        }

                        // Determine Item Name
                        const itemName =
                            item?.product_name ??
                            item?.service_name ??
                            item?.voucher_name ??
                            item?.membership_name ??
                            item?.giftcard_name ??
                            "-----";

                        // Determine Item Type
                        const itemType = item?.product_name
                            ? "Product"
                            : item?.service_name
                                ? "Service"
                                : item?.voucher_name
                                    ? "Voucher"
                                    : item?.membership_name
                                        ? "Membership"
                                        : item?.giftcard_name
                                            ? "Gift Card"
                                            : "-----";

                        return (
                            <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(8,minmax(115px,1fr))]">
                                <div className={`before-left-arrow cursor-pointer`}>
                                    <div className="h-full flex items-center">
                                        <p
                                            className="text-xs text-[#5679FF] font-black line-clamp-1"
                                            onClick={() => {
                                                setCheckoutId(item?.checkout_id)
                                            }}
                                        >
                                            #{item?.invoice_id}
                                        </p>
                                    </div>
                                </div>
                                <p className="text-xs">
                                    {item?.sold_date ? moment(item?.sold_date).format("DD-MM-YYYY") : "----"}
                                </p>
                                <p className="text-xs line-clamp-2">
                                    {item?.sold_by}
                                </p>
                                <p className="text-xs line-clamp-2 col-span-2">
                                    {itemName}
                                </p>
                                <p className="text-xs line-clamp-2">
                                    {itemType}
                                </p>
                                <p className="text-xs">
                                    {item?.price} {location_currency}
                                </p>
                                <p className="text-xs">
                                    {expiryStatus ?
                                        <StatusBtn
                                            noBorder
                                            rounded={"rounded-[5px]"}
                                            status={expiryStatus}
                                        /> : "-----"
                                    }
                                </p>
                            </div>
                        );
                    })
                ) : (
                    <NoReportDataFound />
                )}
            </div>
        </div>
    )
}

export default AllTable
