import Svgs from 'Assets/svgs';
import Footer from 'Components/Utility/Footer';
import Navbar from 'Components/Utility/Navbar';
import PricingCard from 'Components/Utility/PricingCard';
import React from 'react';

const plans = [
  {
    title: "Free Plan",
    price: "0",
    description: "Best for Individuals & Startups",
    icon: <Svgs.FreePlanIcon />,
    buttonText: "Get Started for Free",
    features: [
      "Online Appointment Booking",
      "Basic Client Management",
      "Secure Payment Processing",
      "Automated Reminders",
      "Advanced Team & Staff Management",
      "Retail & Inventory Features"
    ],
    url: '/auth/signup/?account_type=business'
  },
  {
    title: "Growth Plan",
    price: "49",
    description: "Best for Small Businesses",
    icon: <Svgs.GrowthPlanIcon />,
    buttonText: "Upgrade Now",
    features: [
      "Everything in Free Plan +",
      "Advanced Client & Team Management",
      "Custom Branding & Notifications",
      "Detailed Business Reports",
      "Basic Retail & Inventory Control"
    ],
    url:'',
  },
  {
    title: "Premium Plan",
    price: "75",
    description: "Best for Large Salons & Enterprises",
    icon: <Svgs.PremiumPlanIcon />,
    buttonText: "Get Premium",
    features: [
      "Everything in Free Plan +",
      "Full Retail & Inventory Management",
      "Staff Commission Tracking",
      "Multi-Location Management",
      "Advanced Marketing & Promotions"
    ],
    url:''
  }
];

const PlansFeature = [
  {
    name: "Appointment Scheduling",
    values: ["Yes", "Yes", "Yes"]
  },
  {
    name: "Online Booking",
    values: ["Yes", "Yes", "Yes"]
  },
  {
    name: "Payment Processing",
    values: ["Yes", "Yes", "Yes"]
  },
  {
    name: "Client Management",
    values: ["Yes", "Yes", "Advanced Tools"]
  },
  {
    name: "Staff & Team Management",
    values: ["No", "Basic", "Advanced"]
  },
  {
    name: "Automated Reminders",
    values: ["No", "Yes", "Yes"]
  },
  {
    name: "Custom Branding",
    values: ["No", "No", "Yes"]
  },
  {
    name: "Retail & Inventory Control",
    values: ["No", "No", "Yes"]
  }
];


const renderValue = (value) => {
  if (value === "No") {
    return <div className='flex gap-2.5 w-full justify-start items-center'>
      <div className='rotate-45'>
        <Svgs.PlusIcon height={'1.5rem'} width={'1.5rem'} fill={"#FC4848"} />
      </div>
      <span className='hidden sm:flex'>{value}</span>
    </div>
  } else {
    return <div className='flex gap-2.5 w-full justify-start items-center'>
      <Svgs.TickIconGreen height={'1.5rem'} width={'1.5rem'} />
      <span className='hidden sm:flex'>{value}</span>
    </div >
  }
};


const PricingPage = () => {
  return (
    <>
      <div className="flex flex-col w-full font-manrope landing_page">
        <Navbar active={'pricing'} />
        <div className="flex relative overflow-x-hidden flex-col">
          <div className="relative w-full py-14">
            <div className="max-w-5xl w-[95%] relative mx-auto flex flex-col gap-3.5 items-center justify-center text-center">
              <h1 className="text-[3.5rem] font-bold leading-tight">Choose the Perfect Plan for Your Business</h1>
              <p className="text-base">
                Flexible pricing with no hidden fees - Pay for what you need!
              </p>
            </div>
            <div className="absolute -z-10 -left-16 -top-16 w-80 h-80 bg-primary-blue rounded-full blur-[140px]"></div>
            <div className="absolute -z-10 -right-16 bottom-80 w-80 h-80 bg-primary-green rounded-full blur-[140px] "></div>

            <div className="w-[95%] mt-10 mb-14 max-w-[1200px] mx-auto">
              <div className="grid md:grid-cols-3 gap-8">
                {plans.map((plan, index) => (
                  <PricingCard
                    key={index}
                    data={plan}
                    isMiddle={index === 1}
                  />
                ))}
              </div>
            </div>

          </div>


          <div className="w-[95%] max-w-[1200px] mx-auto mb-10 flex flex-col gap-5 p-4">
            <h1 className="text-[3.5rem] font-bold text-center mb-4 leading-tight">Compare Plan</h1>
            <div className="overflow-x-auto rounded-[1.25rem]">
              <div className="border overflow-hidden border-[#DEE3EA] rounded-[1.25rem]">
                <table className="w-full ">
                  {/* Header */}
                  <thead className='bg-blueGreen-gradient'>
                    <tr className=''>
                      <th className="p-4 text-left w-1/4"></th>
                      {plans.map((plan, index) => (
                        <th key={index} className="px-4 py-9 border border-[#DEE3EA] ">
                          <h1 className="text-start font-manrope font-bold text-[1.375rem]">{plan.title}</h1>
                          <p className="hidden sm:flex text-start font-manrope text-sm">{plan.description}</p>
                        </th>
                      ))}
                    </tr>
                  </thead>

                  {/* Pricing Row */}
                  <tbody>
                    <tr className="border border-[#DEE3EA]">
                      <td className="p-4"></td>
                      {plans.map((plan, index) => (
                        <td key={index} className="p-4 text-start border border-[#DEE3EA]">
                          <h1 className="text-[2.5rem] font-manrope font-bold">${plan.price}</h1>
                        </td>
                      ))}
                    </tr>

                    {/* Features */}
                    {PlansFeature.map((feature, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-4 text-black text-base border border-[#DEE3EA]">{feature.name}</td>
                        {feature.values.map((value, valueIndex) => (
                          <td key={valueIndex} className="p-4 border border-[#DEE3EA] text-center">
                            <div className="flex justify-center">{renderValue(value)}</div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex gap-2.5 pb-16">
              <div>
                <Svgs.RocketBlue />
              </div>
              <div className="flex-1 text-base">
                Upgrade Anytime - Start with the free plan and switch to premium as your business grows!
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default PricingPage;
