import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'
import useSaleReportFilterHelper from './helper';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';

const SaleReportFilter = ({ FilterPopup, setFilterPopup, applyRemoveFilter, type, appliedFilterData }) => {
    const { translation, selectedLanguage, filterData, handleChange, reduxEmployees, productRedux, serviceRedux
    } = useSaleReportFilterHelper(type, appliedFilterData)
    return (
        <>
            {FilterPopup && (
                <Popup
                    heading={selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                    open={FilterPopup}
                    close={() => {
                        setFilterPopup(!FilterPopup);
                    }}
                >
                    <div className="flex flex-col gap-5">
                        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                            <CircleIcon>
                                <Svgs.FilterPopup />
                            </CircleIcon>
                            <div className="flex flex-col gap-1 lg:text-left text-center">
                                <h2 className="text-primary font-semibold text-xl">
                                    {selectedLanguage?.name == "English" ? "Search By Filters" : translation[343]?.value ? translation[343]?.value : "Search By Filters"}
                                </h2>
                                <p className="text-[#7B7B7B] text-xs">
                                    {selectedLanguage?.name == "English" ? "Explore tailored results by applying various filters to enhance your sales report search." : translation[344]?.value ? translation[344]?.value : "Explore tailored results by applying various filters to enhance your sales report search."}
                                </p>
                            </div>
                        </div>
                        <div className="grid xs:grid-cols-2 gap-5">
                            {(type == 'product' || type == "service" || type == "all") ?
                                <div>
                                    <Dropwdown
                                        title="Staff"
                                        placeholder="Select Staff"
                                        name={'staff'}
                                        value={filterData?.staff}
                                        onChange={handleChange}
                                        options={reduxEmployees?.map((emp) => {
                                            return {
                                                value: emp?.id,
                                                label: emp?.full_name,
                                            };
                                        })}
                                    />
                                </div> : ""
                            }
                            {(type == 'product' || type == "all") ?
                                <div>
                                    <Dropwdown
                                        title="Product"
                                        placeholder="Select Product"
                                        name={'product'}
                                        value={filterData?.product}
                                        onChange={handleChange}
                                        options={productRedux?.map((prod) => {
                                            return {
                                                value: prod?.id,
                                                label: prod?.name,
                                            };
                                        })}
                                    />
                                </div> : ""
                            }
                            {(type == 'service' || type == "all") ?
                                <div>
                                    <Dropwdown
                                        title="Service"
                                        placeholder="Select Service"
                                        name={'service'}
                                        value={filterData?.service}
                                        onChange={handleChange}
                                        options={serviceRedux?.map((srv) => {
                                            return {
                                                value: srv?.id,
                                                label: srv?.name,
                                            };
                                        })}
                                    />
                                </div> : ""
                            }
                            {/* <div>
                                <PaginationDropdown
                                    title={selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                                    name={client}
                                    onChange={(e) => setclient(e.target.value)}
                                    value={client}

                                    setPage={setClientCurrentPage}
                                    page={clientCurrentPage}
                                    setDropdownSearch={setClientSearchText}
                                    dropdownSearch={clientSearchText}
                                    lastPage={clientPaginationData?.pages}
                                    currentPage={clientPaginationData?.currentPage}
                                    loading={clientLoader}

                                    options={clientDropdownRedux?.map((clnt, i) => {
                                        return {
                                            label: clnt.full_name,
                                            value: clnt.id,
                                            otherData: {
                                                image: clnt?.image,
                                                DataOne: clnt.email,
                                                showImage: true,
                                                index: i
                                            },
                                            email: clnt.email,
                                            mobile_number: clnt.mobile_number
                                        };
                                    })}
                                />
                            </div> */}

                            {/* <div>
                                <PaginationDropdown
                                    title={selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                                    name={services}
                                    value={services}
                                    onChange={(e) => { setservices(e.target.value); }}

                                    setPage={setServiceCurrentPage}
                                    setDropdownSearch={setServiceSearchText}
                                    page={serviceCurrentPage}
                                    dropdownSearch={serviceSearchText}
                                    lastPage={servicePaginationData?.total_pages}
                                    currentPage={servicePaginationData?.currentPage}
                                    loading={serviceLoader}

                                    options={reduxServices?.map((srv) => {
                                        return {
                                            value: srv?.id,
                                            label: srv?.name,
                                        };
                                    })}
                                />
                            </div> */}
                        </div>
                        <div className="flex gap-2">
                            <LoginBtn
                                text={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                                onClick={() => { applyRemoveFilter("apply", filterData) }}
                            />
                            <LoginBtn
                                text={selectedLanguage?.name == "English" ? "Clear filter" : translation[346]?.value ? translation[346]?.value : "Clear filter"}
                                onClick={() => {
                                    applyRemoveFilter("remove")
                                }}
                            />
                        </div>
                    </div>
                </Popup>
            )}
        </>
    )
}

export default SaleReportFilter
