import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductSaleSummaryReportApi, GetSaleReportCSVExcelDataApi } from "Adapters/Api/salesReport";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

export const useAppointmentSummaryReport = () => {
    const dispatch = useDispatch();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");

    // redux state
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const { locations } = useSelector((state) => state);
    const { selected_location } = locations;
    // states
    const [loading, setLoading] = useState(true)
    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [loadingPermission, setLoadingPermission] = useState(true)
    const [csvLoading, setCSVLoading] = useState(false)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [dateType, setDateType] = useState('')

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    useEffect(() => {
        getAppointmentSummaryReportData()
    }, [selected_location, dateType, startDate, endDate])

    // handle export CSV Data
    const handleExportCSVData = (type) => {
        setCSVLoading(type)
        const success = () => {
            setCSVLoading(false)
        }
        const fail = () => {
            setCSVLoading(false)
        }
        let payload = {
            location_id: selected_location,
            data_type: type,
            exportName: type?.replaceAll("-", " ")
        }
        dispatch(GetSaleReportCSVExcelDataApi(access_token, payload, success, fail))
    }

    // get service report data
    const getAppointmentSummaryReportData = async () => {
        if (selected_location) {
            setLoading(true)
            const success = () => {
                setLoading(false)
            }
            const fail = () => {
                setLoading(false)
            }
            let payload = {
                location_id: selected_location,
                start_date: startDate,
                end_data: endDate,
                date_range: dateType
            }
            dispatch(getProductSaleSummaryReportApi(access_token, payload, success, fail))
        }
    }

    return {
        dateType, setDateType, loading, translation, selectedLanguage, employee, startDate, setStartDate, endDate, setEndDate,
        loadingPermission, csvLoading, handleExportCSVData, employeePermissions
    };
};
