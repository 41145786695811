import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NotFound from "Components/Utility/NotFound";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MONTHS } from "Constants/Data/Months";
import LocationSelection from "new-components/location";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import Svgs from "../../../../../Assets/svgs";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import { useReports } from "./helper";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Pagination from "new-components/Pagination";
import Loading from "new-components/loading";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";

const StaffTargetReports = () => {
  const { reportStatus, filterStaff, filterMonth, filterYear, employeeDropdown, yearDropdown, monthDropdown,
    currencyOfSelectedLocation, csvReport, loading, reportsFilter, handleChange, translation, selectedLanguage,
    currentPage, setCurrentPage, pages, allCount, PageSize, handleExportClick, allReports, exportLoader,
    employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData

  } = useReports();
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])



  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
          url={'/dashboard/reports/'}
          second={selectedLanguage?.name == "English" ? 'Staff Target Reports' : translation[369]?.value ? translation[369]?.value : 'Staff Target Reports'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
            </h2>
            <LocationSelection />
          </div>
        </div>
        <div className="border rounded-lg bg-white">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 gap-4 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl ">{selectedLanguage?.name == "English" ? "Staff Target KPI" : translation[316]?.value ? translation[316]?.value : "Staff Target KPI"}</h2>

                {!reportStatus || loading ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
              {loadingPermission ?
                <Shimmer className={'w-44 lg:w-48'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer> :
                <div className="mb-00">
                  <PaginationDropdown
                    className={'w-[10rem] lg:w-[14rem]'}
                    name="filterStaff"
                    custom_min_width={"min-w-[15rem]"}
                    title=""
                    showTitle={false}
                    placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                    options={employeeDropdown}
                    value={filterStaff}
                    onChange={handleChange}
                    setPage={setEmployeePage}
                    page={employeePage}
                    setDropdownSearch={setEmployeeSearch}
                    dropdownSearch={employeeSearch}
                    lastPage={employeePaginationData?.employeePages}
                    currentPage={employeePaginationData?.employeeCurrentPage}
                    loading={employeeLoader}
                  />

                </div>
              }
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} has3Itm />
              :
              <div className="flex items-center justify-end gap-3 w-fit ">
                <Dropwdown
                  name="filterMonth"
                  title=""
                  showTitle={false}
                  placeholder={selectedLanguage?.name == "English" ? "January" : translation[370]?.value ? translation[370]?.value : "January"}
                  options={monthDropdown}
                  value={filterMonth}
                  onChange={handleChange}
                />
                <Dropwdown
                  name="filterYear"
                  title=""
                  showTitle={false}
                  placeholder="2022"
                  options={yearDropdown}
                  value={filterYear}
                  onChange={handleChange}
                />
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) : !employee ? (allReports?.length > 0 ?
                  <CSVLink
                    {...csvReport}
                    target="_blank"
                    onClick={handleExportClick}
                  >
                    <LoginBtn
                      className="rounded-lg !py-[7px]"
                      bg="bg-white"
                      animation={false}
                      customThemeBtn={'theme-btn2'}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.ExportSvg />
                      </div>
                    </LoginBtn>
                  </CSVLink> :
                  <LoginBtn
                    className="rounded-lg !py-[7px]"
                    bg="bg-white"
                    animation={false}
                    onClick={handleExportClick}
                    customThemeBtn={'theme-btn2'}
                  >
                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                      <Svgs.ExportSvg />
                    </div>
                  </LoginBtn>
                ) : (
                  ""
                )}
              </div>
            }
          </div>
          {!reportStatus || loading ? (
            <TableShimer cols={7} colsHeight={8} rows={12} className={"inline-grid xl:grid grid-cols-[repeat(7,minmax(185px,1fr))] text-sm font-semibold"} />
          ) :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(185px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Service Target" : translation[371]?.value ? translation[371]?.value : "Total Service Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Service Sales" : translation[372]?.value ? translation[372]?.value : "Total Service Sales"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Retail Target" : translation[373]?.value ? translation[373]?.value : "Total Retail Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Retail Sales" : translation[374]?.value ? translation[374]?.value : "Total Retail Sales"}</p>
                <p>{selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}</p>
                <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
              </div>
              <div className={`odd-bg text-[#3A3A3A] transition-all`}>
                {reportsFilter()?.length > 0 ? (
                  reportsFilter()?.map(
                    (
                      {
                        full_name,
                        staff_target,
                        service_sale_price,
                        product_sale_price,
                      },
                      index
                    ) => (
                      <>
                        <div
                          key={index}
                          className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(185px,1fr))] min-w-full w-fit"
                        >
                          <div className="flex items-center">
                            <p className="text-sm">{full_name}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {staff_target?.service_target ? parseFloat(staff_target?.service_target)?.toFixed(2) : 0}{" "}
                              {currencyOfSelectedLocation}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {service_sale_price ? parseFloat(service_sale_price)?.toFixed(2) : 0} {currencyOfSelectedLocation}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {staff_target?.retail_target ? parseFloat(staff_target?.retail_target)?.toFixed(2) : 0}{" "}
                              {currencyOfSelectedLocation}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {product_sale_price ? parseFloat(product_sale_price)?.toFixed(2) : 0} {currencyOfSelectedLocation}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{filterYear}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{MONTHS[filterMonth]}</p>
                          </div>
                        </div>
                      </>
                    )
                  )
                ) : (
                  <>
                    <NoReportDataFound />
                  </>
                )}

                {(reportsFilter()?.length > 0 && !loading) &&
                  <Pagination
                    currentPage={currentPage}
                    totalCount={Math.ceil(pages)}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default StaffTargetReports;
