import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllComplaintCategoryAPI, getAllComplaintAPI, deleteComplaintAPI, updateComplaintStatusAPI, getSingleComplaintAPI } from "Adapters/Api/complaints";

let searchTimeOutId = undefined


const useComplaints = () => {
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const dispatch = useDispatch()
    const access_token = getDataFromLocalStorage('access_token')


    // redux states
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const complaintCategory = state?.complaint?.complaint_category
    const complaint = state?.complaint?.complaint
    const paginationData = state?.complaint?.complaint_pagination


    // states
    const [openCreatePopup, setOpenCreatePopup] = useState(false)
    const [openCreateCategoryPopup, setOpenCreateCategoryPopup] = useState(false)
    const [complaintCatLoading, setComplaintCatLoading] = useState(true)
    const [complaintLoading, setComplaintLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('complaint')
    const [deleteComplaintLoading, setDeleteComplaintLoading] = useState(false)
    const [deleteComplaintPopup, setDeleteComplaintPopup] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchText, setSearchText] = useState("")
    const [status, setStatus] = useState("")

    const [complaintDetail, setComplaintDetail] = useState()
    const [complaintPopup, setComplaintPopup] = useState(false)
    const [complaintDetailLoading, setComplaintDetailLoading] = useState(false)
    const [updateComplaintLoading, setUpdateComplaintLoading] = useState(false)
    const [viewLogs, setViewLogs] = useState(false)

    const getComplaintCategory = () => {
        const success = () => { setComplaintCatLoading(false) }
        const fail = () => { setComplaintCatLoading(false) }
        setComplaintCatLoading(true)
        dispatch(getAllComplaintCategoryAPI({}, access_token, success, fail))
    }

    const getComplaint = () => {
        const success = () => { setComplaintLoading(false) }
        const fail = () => { setComplaintLoading(false) }
        setComplaintLoading(true)
        const payload = {
            status: status,
            page: currentPage,
            search_text: searchText,
        }
        dispatch(getAllComplaintAPI(payload, access_token, success, fail))
    }

    const getSingleComplaint = () => {
        const success = (res) => {
            setComplaintDetail(res?.data)
            setComplaintDetailLoading(false)
        }

        const fail = () => {
            setComplaintDetail(null)
            setComplaintDetailLoading(false)
        }
        setViewLogs(false)
        setComplaintDetailLoading(true)
        dispatch(getSingleComplaintAPI(complaintPopup, access_token, success, fail))
    }

    const deleteComplaint = () => {
        const success = () => {
            setDeleteComplaintLoading(false);
            setDeleteComplaintPopup(false)
            onSucessCallBack();
        }
        const fail = () => {
            setDeleteComplaintLoading(false)
        }
        setDeleteComplaintLoading(true)
        dispatch(deleteComplaintAPI(deleteComplaintPopup, access_token, success, fail))
    }

    const updateComplaintStatus = (isComment, status) => {
        const success = () => {
            getComplaint()
            getSingleComplaint()
            setComplaintDetail(null)
            setUpdateComplaintLoading(false)
        }
        const fail = () => {
            setUpdateComplaintLoading(false)
        }

        if (isComment && !complaintDetail?.comment) {
            return
        }

        const payload = {
            id: complaintDetail?.id,
            status: status || complaintDetail?.status,
            comment: complaintDetail?.comment
        }
        setUpdateComplaintLoading(isComment || true)
        dispatch(updateComplaintStatusAPI(payload, access_token, success, fail))
    }

    const onSucessCallBack = () => {
        getComplaint()
        setComplaintLoading(true)
    }

    useEffect(() => {
        getComplaintCategory()
    }, [])

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty search_text
            onSucessCallBack()

        } else {
            if (searchTimeOutId) {
                clearTimeout(searchTimeOutId)
            }
            searchTimeOutId = setTimeout(() => {
                onSucessCallBack()
            }, 500);
        }

    }, [currentPage, activeTab, searchText, status])

    useEffect(() => {
        if (complaintPopup) {
            getSingleComplaint()
            setComplaintDetail(null)
            setComplaintDetailLoading(true)
        }
    }, [complaintPopup])

    return {
        translation, selectedLanguage, storeDataInLocalStorage, openCreatePopup, setOpenCreatePopup, openCreateCategoryPopup, setOpenCreateCategoryPopup,
        complaintCatLoading, activeTab, setActiveTab, complaintCategory, onSucessCallBack, complaintLoading,
        deleteComplaintPopup, setDeleteComplaintPopup, deleteComplaintLoading, deleteComplaint, paginationData, complaint, currentPage, setCurrentPage,
        searchText, setSearchText, status, setStatus, complaintDetail, setComplaintDetail,
        complaintPopup, setComplaintPopup, complaintDetailLoading, updateComplaintLoading, updateComplaintStatus, viewLogs, setViewLogs
    }
}

export default useComplaints