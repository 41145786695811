import React, { useState } from "react";
import Svgs from "Assets/svgs";
import IconButton from "Components/TenantApp/Elements/IconButton";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import DateCounter from "../../Calendar/CalendarElements/DateCounter";
import useLeads from "./helper";
import DetailLead from "./DetailLead";
import AddFilter from "./addFilter";
import { useSelector } from "react-redux";

const Lead_Generation = () => {
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const {
    loading,
    Lead_GenerationData,
    ViewDetails, setViewDetails,
    FilterPopup, setFilterPopup,

  } = useLeads()


  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb first={selectedLanguage?.name == "English" ? "CRM" : translation[1354]?.value ? translation[1354]?.value : "CRM"} url={"/dashboard/crm/"} second={selectedLanguage?.name == "English" ? "Lead Generation" : translation[1361]?.value ? translation[1361]?.value : "Lead Generation"} />
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Lead Generation" : translation[1361]?.value ? translation[1361]?.value : "Lead Generation"}
            </h2>
          </div>
          <div className="flex items-center gap-2 justify-between flex-1">
            &nbsp;
            {/* <DateCounter
                bigCalendar={true}
                SelectedDate={SelectedDate}
                setSelectedDate={setSelectedDate}
                selectedLanguage={selectedLanguage}
                translation={translation}
            /> */}
          </div>
        </div>
        <div className="">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Lead Generation" : translation[1361]?.value ? translation[1361]?.value : "Lead Generation"}</h2>
                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                      <p className="text-primary">5 {selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"}</p>
                    </div>
                  </div>
                </div>
                <div className="mb-00">
                  <LoginInput
                    title=""
                    name={"search_text"}
                    placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                </div>
              </div>
              <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                <LoginBtn
                  borderBtn
                  onClick={() => {
                    setFilterPopup(!FilterPopup);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Filters />
                    <p>{selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}</p>
                  </div>
                </LoginBtn>
                <IconButton>
                  <Svgs.Import />
                </IconButton>
                <IconButton filled>
                  <Svgs.Export />
                </IconButton>
              </div>
            </div>

            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(4,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Client Name" : translation[1159]?.value ? translation[1159]?.value : "Client Name"}</p>
                <p>{selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"} </p>
                <p>{selectedLanguage?.name == "English" ? "Assign Sement" : translation[1365]?.value ? translation[1365]?.value : "Assign Sement"}</p>
                <p>{selectedLanguage?.name == "English" ? "Lead" : translation[1386]?.value ? translation[1386]?.value : "Lead"}</p>
              </div>
              <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
              >
                {/* --------- Lead Generations ---------- */}
                {Lead_GenerationData?.map((v, i) => {

                  return (
                    <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(115px,1fr))] min-w-full w-fit">
                      <div
                        onClick={() => setViewDetails(!ViewDetails)}
                        className="cursor-pointer h-full flex items-center gap-3">
                        <div>
                          <div className="h-[2rem] w-[2rem] rounded-full"><img className="w-full h-full" src={v.img} alt="img" /></div>
                        </div>
                        <p className="text-sm text-primary">{v.customer_name}</p>
                        {Lead_GenerationData && (
                          <DetailLead v={v} ViewDetails={ViewDetails} setViewDetails={setViewDetails} />
                        )}
                      </div>
                      <div className="flex items-center text-sm">
                        {v.email}
                      </div>

                      <div className="flex items-center">
                        <p className="text-sm">{v.phone}</p>
                      </div>
                      <div className="flex items-center">
                        <StatusBtn
                          status={v.is_active ? "Active" : "Inactive"}
                          text={v.is_active ? "Win" : "Loss"}
                        />
                      </div>

                    </div>
                  );
                })}
                {/* -------------- */}
              </div>
            </div>
          </div>
        </div>

      </div>
      {FilterPopup && (
        <AddFilter setFilterPopup={setFilterPopup} FilterPopup={FilterPopup} />
      )
      }
    </>
  )
}

export default Lead_Generation