import axios from "axios";
import { BASE_URL, create_complaint_category_url, get_complaint_category_url, update_complaint_category_url, delete_complaint_category_url, create_complaint_url, get_complaint_url, update_complaint_url, delete_complaint_url, update_complaint_status_url, submit_customer_feedback_url } from "../api_variables";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { get_complaint_category_action, get_complaint_action } from "Redux/TenantRedux/Actions/complaintActions";

export const createComplaintCategoryAPI = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.post(`${BASE_URL}${create_complaint_category_url}`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 201) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const updateComplaintCategoryAPI = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.patch(`${BASE_URL}${update_complaint_category_url}${payload?.id}/`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 200) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const getAllComplaintCategoryAPI = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${get_complaint_category_url}${query}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        dispatch(get_complaint_category_action(result?.data))
        success && success()
        return;
    } catch (error) {
        dispatch(get_complaint_category_action({}))
        fail && fail(error)
        return error;
    }
};

export const getSingleComplaintCategoryAPI = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.get(`${BASE_URL}${get_complaint_category_url}/${payload?.id}/`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.results?.status_code == 200) {
            success && success(result?.data?.results)
            return;

        } else {
            fail && fail(result)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

export const deleteComplaintCategoryAPI = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.delete(`${BASE_URL}${delete_complaint_category_url}${payload}/`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 204) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const createComplaintAPI = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.post(`${BASE_URL}${create_complaint_url}`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 201) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const updateComplaintAPI = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.patch(`${BASE_URL}${update_complaint_url}${payload?.id}/`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 200) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(error?.response?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const updateComplaintStatusAPI = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.post(`${BASE_URL}${update_complaint_status_url}${payload?.id}/update-status/`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 200) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(error?.response?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const getAllComplaintAPI = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${get_complaint_url}${query}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        dispatch(get_complaint_action(result?.data))
        success && success()
        return;
    } catch (error) {
        dispatch(get_complaint_action({}))
        fail && fail(error)
        return error;
    }
};

export const getSingleComplaintAPI = (id, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.get(`${BASE_URL}${get_complaint_url}${id}/`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 200) {
            success && success(result?.data?.response)
            return;

        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

export const deleteComplaintAPI = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.delete(`${BASE_URL}${delete_complaint_url}${payload}/`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 200) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(error?.response?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
};

export const submitCustomerFeedbackAPI = (payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_SERVER_BASEURL_START}${payload?.tenant}.${process.env.REACT_APP_SERVER_BASEURL_END}${submit_customer_feedback_url}${payload?.id}/feedback/`, payload);
        if (result?.data?.status_code == 201) {
            toast.success(result?.data?.message, { toastId: 'toast' })
            success && success(result)
            return;
        } else {
            toast.error(result?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
            fail && fail(result)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.response?.data?.response?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
}
