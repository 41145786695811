import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Svgs from "../../../../../../Assets/svgs";
import CircleIcon from "../../../../Elements/CircleIcon";
import Dropwdown from "../../../../Elements/Dropwdown";
import LoginBtn from "../../../../Elements/LoginBtn";
import LoginInput from "../../../../Elements/LoginInput";
import CheckboxCard from "../../../../Elements/CheckboxCard";
import CardTile from "../../../CardTile";
import { useNavigate } from "react-router-dom";
import Popup from "../../../../Elements/Popup";
import useFinancialSettings from "./useFinancialSettings";
// import useTaxOnLocations from './useTaxOnLocations'
import useTaxSettings from "./useTaxSettings";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import {
  getBusinessTax,
  getPaymentMethods,
  getTaxSettingBusiness,
} from "../../../../../../Adapters/Api/financialSettings";
import {
  getBusinessTaxs,
  getPaymentMethodList,
} from "../../../../../../Redux/TenantRedux/Actions/financialSettingsActions";
import { getLocations } from "../../../../../../Adapters/Api";
import { addLocations } from "../../../../../../Redux/TenantRedux/Actions/locationActions";
import NotFound from "../../../../../Utility/NotFound";
import { ALLOWED_PAYMENT_METHODS } from "../../../../../../Constants/Data/Payment";
import useValidations from "Hooks/useValidations";
import ToggleWithText from "Components/TenantApp/Elements/ToggleWithText";
import RadioBtn from "Components/TenantApp/Elements/RadioBtn";
import { toast } from "react-toastify";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import CardTileShimmer from "Components/Utility/Shimmers/CardTileShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import RefundSetting from "./RefundSetting";

const FinancialSettings = () => {
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [del_popup, setDelPopup] = useState(undefined);
  const [deleting, setDeleting] = useState(false);
  const [tax_btn_loading, setTaxLoading] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  const { setErrors } = useValidations();

  let selectedPaymentMethods;
  let taxLocationOption;
  let taxGroupOption;
  // add new tax modal
  const {
    groupTaxHandler,
    TaxGroup,
    groupTax,
    groupModalToggler,
    locationTax,
    isUpdate,
    setIsUpdate,
    applyTaxHandleChange,
    applyModalToggle,
    apply,
    errors: addTaxError,
    addTax,
    tax,
    businessTaxDeleteHandler,
    businessTaxEditHandler,
    addTaxHandleChange,
    onAddTaxSubmit,
    taxModalToggler,
    onBlurHandler,
    taxSetting, setTaxSetting,
    onTaxSettingSubmit, taxSettingLoader,
    OpenSettings, setOpenSettings,
  } = useTaxSettings();
  const { name, tax_rate } = addTax;
  const { name: names } = groupTax;
  const { location, tax_rate: taxt_rates } = locationTax;



  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const reduxlocationsList = useSelector(
    (state) => state?.locations?.locations
  );
  const reduxPaymentMethodList = useSelector(
    (state) => state?.financialSettings?.paymentMethod
  );
  const reduxBusinessTax = useSelector(
    (state) => state?.financialSettings.businessTaxs
  );

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [paymentLoader, setPaymentLoader] = useState(true)
  const [taxLoader, setTaxLoader] = useState(true)

  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  if (reduxlocationsList && reduxlocationsList.length >= 1) {
    taxLocationOption =
      reduxlocationsList &&
      reduxlocationsList?.map((item) => {
        return { id: item.id, label: item.address_name, value: item.id };
      });
  }

  if (reduxBusinessTax && reduxBusinessTax.length >= 1) {
    let onlyIndividualTax =
      reduxBusinessTax &&
      reduxBusinessTax?.filter((item) => item?.tax_type === "Individual");

    taxGroupOption =
      onlyIndividualTax &&
      onlyIndividualTax?.map((item) => {
        return { id: item.id, label: item.name, value: item.id };
      });
  }

  // add payment method modal
  const {
    errors,
    method_type,
    paymentMethodHandler,
    onAddPaymentMethod,
    paymentModalToggle,
    Payment,
    paymentMethodDeleteHandler,
    paymentMethodEditHandler,
    setIsEdit,
    isEdit,
    isSelected,
  } = useFinancialSettings();
  const access_token = getDataFromLocalStorage("access_token");

  //get payment methods
  const fetchPaymentMethods = async (business_id) => {
    const from_financial_account_setting = true
    const response = await getPaymentMethods(business_id, from_financial_account_setting);
    if (response.status === 200) {
      let paymentMethods = response?.data?.response?.payment_methods;
      setPaymentLoader(false)
      dispatch(getPaymentMethodList(paymentMethods));
    }
  };

  useEffect(() => {
    if (businessData.id) {
      if (reduxPaymentMethodList?.length === 0) {
        fetchPaymentMethods(businessData.id);
      } else {
        setPaymentLoader(false)
      }
      dispatch(getTaxSettingBusiness(businessData.id, access_token));
    }
  }, []);

  useEffect(() => {
    if (reduxBusinessTax?.length === 0) {
      dispatch(getBusinessTax(businessData.id, setTaxLoader));
    } else {
      setTaxLoader(false)
    }
  }, []);

  //get business location and stored it in redux
  const getBusinessLocation = (business_id) => {
    dispatch(getLocations(business_id));
  };

  useEffect(() => {
    if (businessData?.id) {
      if (reduxlocationsList?.length === 0) {
        getBusinessLocation(businessData?.id);
      }
    }
  }, []);

  if (reduxPaymentMethodList?.length > 0) {
    selectedPaymentMethods = reduxPaymentMethodList?.map(
      (item) => item?.method_type
    );
  }

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/setup/");
            }}
          >
            {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
          </span>{" "}
          &gt;{" "}
          <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Financial Settings" : translation[658]?.value ? translation[658]?.value : "Financial Settings"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Financial Settings" : translation[658]?.value ? translation[658]?.value : "Financial Settings"}
            </h2>
          </div>
          {/* <LoginBtn padding='' className='text-base' >Save Changes</LoginBtn> */}
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
              <CircleIcon>
                <Svgs.Credit_card />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Payment Methods" : translation[190]?.value ? translation[190]?.value : "Payment Methods"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Add various payment methods to be used during checkout." : translation[700]?.value
                    ? translation[700]?.value : "Add various payment methods to be used during checkout."}

                </p>
              </div>
            </div>
            {loadingPermission ?
              <Shimmer className={'w-44 lg:w-48'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer> :
              <h2 className="text-primary font-semibold text-xl">
                {!employee ? (
                  <LoginBtn

                    onClick={() => {
                      paymentModalToggle();
                      setIsEdit(false);
                    }}
                  >
                    {selectedLanguage?.name == "English" ? "Add Payment Method" : translation[714]?.value ? translation[714]?.value : "Add Payment Method"}
                  </LoginBtn>
                ) : employee &&
                  (employeePermissions?.account_finance_setting?.includes(
                    "edit"
                  ) || employeePermissions?.account_root_access?.includes("view")) ? (
                  ""
                ) : (
                  ""
                )}
              </h2>
            }
          </div>

          {paymentLoader ?
            <div className="flex flex-col gap-3 mt-[2rem]">
              {[0, 1, 2, 3]?.map(itm => {
                return <CardTileShimmer />
              })}
            </div>
            :
            reduxPaymentMethodList?.length > 0 ? (
              <>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-3 mt-[2rem]">
                  {reduxPaymentMethodList &&
                    reduxPaymentMethodList?.map((item) => {
                      let lft_icon = ALLOWED_PAYMENT_METHODS?.find(
                        (itm) => itm.name == item.method_type
                      );
                      return (
                        !employee || (employee && (employeePermissions?.account_finance_setting?.includes("edit") || employeePermissions?.account_root_access?.includes("view"))) ? (
                          <CardTile
                            left_icon_color={false}
                            lefticon_class={lft_icon?.icon ? lft_icon?.icon : '!p-0'}
                            leftIcon={lft_icon?.icon ? lft_icon?.icon : <img className="h-[2rem] w-[2rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} />}
                            title={item?.method_type}
                            onDelete={() =>
                              setDelPopup({
                                heading: selectedLanguage?.name == "English" ? "Payment Method" : translation[742]?.value ? translation[742]?.value : "Payment Method",
                                callback: paymentMethodDeleteHandler,
                                data: item.id,
                                popup_for: "delete_payment_method",
                                body: selectedLanguage?.name == "English" ? "payment method" : translation[742]?.value ? translation[742]?.value : "payment method",
                              })
                            }
                            onEdit={() => paymentMethodEditHandler(item.id)}
                            desc=""
                          />
                        ) : (""))
                    })}
                </div>
              </>
            ) : (
              <>
                <NotFound className="" />
              </>
            )}

          <hr className="bg-[#3F3F441A] my-[1.5rem]" />

          {/* Tax Setting */}
          <div className="flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
              <CircleIcon>
                <Svgs.Percent />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Tax Settings" : translation[715]?.value ? translation[715]?.value : "Tax Settings"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English"
                    ? "Add tax and tax groups to apply on all payments during checkout." :
                    translation[716]?.value
                      ? translation[716]?.value
                      : "Add tax and tax groups to apply on all payments during checkout."}
                </p>
              </div>
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-24 lg:!w-32 sm:!w-28'} classOn2={'!w-28 lg:!w-36 sm:!w-32'} classOn3={'w-20 lg:!w-24'} has3Itm />
              :
              <div className="flex items-center sm:gap-4 gap-2">
                {!employee ? (
                  <>
                    <LoginBtn
                      onClick={() => {
                        setIsUpdate(false);
                        taxModalToggler(false);
                        setErrors({});
                      }}
                    >
                      {selectedLanguage?.name == "English" ? "Add New Tax" : translation[717]?.value ? translation[717]?.value : "Add New Tax"}
                    </LoginBtn>
                    <LoginBtn


                      onClick={() => {
                        setIsUpdate(false);
                        setErrors({});
                        groupModalToggler();
                      }}
                    >
                      {selectedLanguage?.name == "English" ? "Add New Tax Group" : translation[718]?.value ? translation[718]?.value : "Add New Tax Group"}
                    </LoginBtn>
                    <LoginBtn
                      text={selectedLanguage?.name == "English" ? "Settings" : translation[719]?.value ? translation[719]?.value : "Settings"}

                      onClick={() => {
                        setOpenSettings(!OpenSettings)
                      }} />
                  </>
                ) : employee &&
                  employeePermissions?.account_finance_setting?.includes(
                    "edit"
                  ) ? (
                  ""
                ) : (
                  ""
                )}
              </div>
            }
          </div>
          {taxLoader ?
            <>
              <CardTileShimmer tax />
              <CardTileShimmer tax />
            </>
            : reduxBusinessTax && reduxBusinessTax?.filter((itm) => itm.tax_type === "Individual" || itm.tax_type === "Group")?.length > 0 ? (
              <>
                <div className="flex flex-col gap-3 mt-[2rem]">
                  {reduxBusinessTax && reduxBusinessTax?.filter((itm) => itm.tax_type === "Individual" || itm.tax_type === "Group")?.map((item) => {
                    return (
                      !employee ? (
                        <CardTile
                          leftIcon={item.tax_type === "Individual" ? <Svgs.singleTaxIcon /> : <Svgs.groupTaxIcon />}
                          onDelete={() => {
                            setDelPopup({
                              heading:
                                item.tax_type === "Individual"
                                  ? selectedLanguage?.name == "English" ? "tax" : translation[733]?.value ? translation[733]?.value : "tax"
                                  : selectedLanguage?.name == "English" ? "tax group" : translation[746]?.value ? translation[746]?.value : "tax group",
                              callback: businessTaxDeleteHandler,
                              data: item.id,
                            });
                          }}
                          onEdit={() =>
                            businessTaxEditHandler(item.id, item.tax_type)
                          }
                          title={item?.name}
                          desc={item?.tax_rate + "%"}
                          groupTax={
                            item.tax_type === "Group" ? item?.parent_tax : []
                          }
                        />
                      ) : employee && employeePermissions?.account_finance_setting?.includes("edit") ? (
                        <CardTile
                          leftIcon={<Svgs.Percent_Circle />}
                          onEdit={() =>
                            businessTaxEditHandler(item.id, item.tax_type)
                          }
                          title={item?.name}
                          desc={item?.tax_rate + "%"}
                          groupTax={
                            item.tax_type === "Group" ? item?.parent_tax : []
                          }
                        />
                      ) : employee && employeePermissions?.account_root_access?.includes("view") ?
                        (
                          <CardTile
                            leftIcon={<Svgs.Percent_Circle />}
                            title={item?.name}
                            desc={item?.tax_rate + "%"}
                            groupTax={
                              item.tax_type === "Group" ? item?.parent_tax : []
                            }
                          />
                        ) : (""))
                  })}
                </div>
              </>
            ) : (
              <>
                <NotFound className="" />
              </>
            )}

          <hr className="bg-[#3F3F441A] my-[1.5rem]" />

          {/* Loaction Tax */}
          <div className="flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
              <CircleIcon>
                <Svgs.Location_percent />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Tax on Locations" : translation[740]?.value ? translation[740]?.value : "Tax on Locations"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Apply tax or tax group on individual business locations." : translation[741]?.value ? translation[741]?.value : "Apply tax or tax group on individual business locations."}
                </p>
              </div>
            </div>
            {loadingPermission ?
              <Shimmer className={'w-20 lg:w-24'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer> :
              <div className="flex items-center sm:gap-4 gap-2">
                {!employee ? (
                  <LoginBtn

                    onClick={() => {
                      setErrors({});
                      setIsUpdate(false);
                      applyModalToggle();
                    }}
                  >
                    {selectedLanguage?.name == "English" ? "Apply Tax" : translation[743]?.value ? translation[743]?.value : "Apply Tax"}
                  </LoginBtn>
                ) : employee &&
                  employeePermissions?.account_finance_setting?.includes(
                    "edit"
                  ) ? (
                  ""
                ) : (
                  ""
                )}
              </div>
            }
          </div>
          {taxLoader ?
            <>
              <CardTileShimmer tax />
              <CardTileShimmer tax />
            </>
            : reduxBusinessTax?.filter((item) => item.tax_type === "Location")
              ?.length > 0 ? (
              <>
                <div className="flex flex-col gap-3 mt-[2rem]">
                  {reduxBusinessTax
                    ?.filter((item) => item.tax_type === "Location")
                    .map((item) => {
                      return (
                        !employee ? (
                          <CardTile
                            leftIcon={<Svgs.Home size={'1.5rem'} />}
                            onEdit={() =>
                              businessTaxEditHandler(item.id, "location")
                            }
                            onDelete={() =>
                              setDelPopup({
                                heading: selectedLanguage?.name == "English" ? "Tax Location" : translation[752]?.value ? translation[752]?.value : "Tax Location",
                                callback: businessTaxDeleteHandler,
                                data: item.id,
                              })
                            }
                            title={
                              item?.location?.address_name
                                ? item?.location?.address_name
                                : "N/A"
                            }
                            desc={`${item?.parent_tax[0]?.name} ` + item?.parent_tax[0]?.tax_rate + "%"}
                            groupTax={
                              item?.parent_tax[0]?.parent_tax.length > 0
                                ? item?.parent_tax[0]?.parent_tax
                                : []
                            }
                          />
                        ) : employee && employeePermissions?.account_finance_setting?.includes("edit") ? (
                          <CardTile
                            leftIcon={<Svgs.Home size={'1.5rem'} />}
                            onEdit={() =>
                              businessTaxEditHandler(item.id, "location")
                            }
                            title={
                              item?.location?.address_name
                                ? item?.location?.address_name
                                : "N/A"
                            }
                            desc={`${item?.parent_tax[0]?.name} ` + item?.parent_tax[0]?.tax_rate + "%"}
                            groupTax={
                              item?.parent_tax[0]?.parent_tax.length > 0
                                ? item?.parent_tax[0]?.parent_tax
                                : []
                            }
                          />
                        ) : employee && employeePermissions?.account_root_access?.includes("view") ?
                          (
                            <CardTile
                              leftIcon={<Svgs.Home size={'1.5rem'} />}
                              title={
                                item?.location?.address_name
                                  ? item?.location?.address_name
                                  : "N/A"
                              }
                              desc={`${item?.parent_tax[0]?.name} ` + item?.parent_tax[0]?.tax_rate + "%"}
                              groupTax={
                                item?.parent_tax[0]?.parent_tax.length > 0
                                  ? item?.parent_tax[0]?.parent_tax
                                  : []
                              }
                            />
                          ) : (""))
                      {/* !employee ? (
                      
                    ) : employee &&
                      employeePermissions?.account_business_setting?.includes(
                        "edit"
                      ) ? (
                      <CardTile
                        leftIcon={<Svgs.Home size={'1.5rem'} />}
                        onEdit={() =>
                          businessTaxEditHandler(item.id, "location")
                        }
                        title={
                          item?.location?.address_name
                            ? item?.location?.address_name
                            : "N/A"
                        }
                        desc={item?.parent_tax[0]?.tax_rate + "%"}
                        groupTax={
                          item?.parent_tax[0]?.parent_tax.length > 0
                            ? item?.parent_tax[0]?.parent_tax
                            : []
                        }
                      />
                    ) : (
                      ""
                    ); */}
                    })}
                </div>
              </>
            ) : (
              <>
                <NotFound className="" />
              </>
            )}
          <div>
            <RefundSetting
              employee={employee}
              employeePermissions={employeePermissions}
              loadingPermission={loadingPermission}
            />
          </div>
        </div>
      </div>

      {
        del_popup && (
          <Popup
            // heading={`Are you sure want to delete this ${del_popup.heading}`}
            heading={reduxBusinessTax
              ?.filter((item) => item?.tax_type === "Location")
              ?.find(itm => itm?.parent_tax?.find(itmm => itmm.id === del_popup?.data)) ?
              `${selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this"
                : translation[744]?.value
                  ? translation[744]?.value
                  : "Are you sure you want to delete this"} 
                    ${del_popup.heading}`
              :
              `${selectedLanguage?.name == "English"
                ? "Delete" : translation[120]?.value
                  ? translation[120]?.value
                  : "Delete"
              }
              ${del_popup.heading}`
            }

            onclose={() => setDelPopup(false)}
          >
            <div>
              <p className="normal-case">
                {reduxBusinessTax
                  ?.filter((item) => item?.tax_type === "Location")
                  ?.find(itm => itm?.parent_tax?.find(itmm => itmm.id === del_popup?.data)) ?

                  selectedLanguage?.name == "English"
                    ? "This tax is already applied to a business location, if you will delete, it will remove tax from that location. Do you still want to delete it?"
                    : translation[747]?.value
                      ? translation[747]?.value
                      : "This tax is already applied to a business location, if you will delete, it will remove tax from that location. Do you still want to delete it?"

                  :
                  `${selectedLanguage?.name == "English"
                    ? "Are you sure you want to delete this"
                    : translation[744]?.value
                      ? translation[744]?.value
                      : "Are you sure you want to delete this"}
                  ${del_popup?.popup_for == "delete_payment_method" ? del_popup.body : del_popup.heading}${selectedLanguage?.name == "English"
                    ? "? You won't be able to see this again."
                    : translation[745]?.value
                      ? translation[745]?.value
                      : "? You won't be able to see this again."}
                `
                }
              </p>
              <div className="flex justify-end mt-4">
                <LoginBtn
                  loading={deleting}
                  text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                  deleteBtn
                  onClick={async () => {
                    if (del_popup?.popup_for == "delete_payment_method") {
                      if (reduxPaymentMethodList?.length == 1) {
                        if (!isToastVisible) {
                          toast.error("You can't delete your last payment method.", {
                            onClose: () => setIsToastVisible(false),
                          });
                          setIsToastVisible(true);
                        }
                      } else {
                        (await del_popup["callback"]) &&
                          del_popup["callback"](del_popup.data, del_popup.heading);
                        setDelPopup(undefined);
                      }
                    } else {
                      (await del_popup["callback"]) &&
                        del_popup["callback"](del_popup.data, del_popup.heading);
                      setDelPopup(undefined);
                    }
                  }}
                />
              </div>
            </div>
          </Popup>
        )
      }

      {/* Add Tax */}
      <Popup
        heading={selectedLanguage?.name == "English" ? "Add Tax" : translation[720]?.value ? translation[720]?.value : "Add Tax"}
        close={() => {
          setErrors({});
          taxModalToggler();
          setIsUpdate(false);
        }}
        open={tax}
        z='z-[44442]'
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
            <CircleIcon>
              <Svgs.Percent />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Tax Details" : translation[721]?.value ? translation[721]?.value : "Tax Details"}
              </h2>
              <p className="text-[#7B7B7B] text-sm">
                {selectedLanguage?.name == "English"
                  ? "Set the tax name and percentage rate. To apply this to your products"
                  : translation[722]?.value
                    ? translation[722]?.value
                    : "Set the tax name and percentage rate. To apply this to your products"}
                <br className="hidden md:block" />
                {selectedLanguage?.name == "English"
                  ? "and services, adjust your tax defaults settings."
                  : translation[723]?.value
                    ? translation[723]?.value
                    : "and services, adjust your tax defaults settings."}
              </p>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
            <LoginInput
              required={true}
              name={"name"}
              value={name}
              // charactersMaxLength={25}
              onChange={addTaxHandleChange}
              onBlur={() => onBlurHandler({ name: name })}
              error={addTaxError.name}
              title={selectedLanguage?.name == "English" ? "Tax Name" : translation[724]?.value ? translation[724]?.value : "Tax Name"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Tax Name" : translation[725]?.value ? translation[725]?.value : "Enter Tax Name"}
            />

            <FloatInput
              required={true}
              name={"tax_rate"}
              value={tax_rate}
              onChange={addTaxHandleChange}
              isPercentage
              onBlur={() => onBlurHandler({ tax_rate: tax_rate })}
              error={addTaxError.tax_rate}
              charactersMaxLength={2}
              rightIcon={<Svgs.Percent />}
              title={selectedLanguage?.name == "English" ? "Tax Rate" : translation[726]?.value ? translation[726]?.value : "Tax Rate"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Tax Rate" : translation[727]?.value ? translation[727]?.value : "Enter Tax Rate"}
            />
          </div>
          <div className="flex items-center justify-start mt-[2rem]">
            <LoginBtn
              onClick={async () => {
                setTaxLoading(true);
                await onAddTaxSubmit("Individual");
                setTimeout(() => {
                  setTaxLoading(false);
                }, 300);
              }}
              text={isUpdate ?
                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
              }
              disabled={tax_btn_loading}
              loading={tax_btn_loading}
            />
          </div>
        </div>
      </Popup>

      {/* Add Tax group */}
      <Popup
        heading={selectedLanguage?.name == "English" ? "Add Tax Group" : translation[728]?.value ? translation[728]?.value : "Add Tax Group"}
        close={groupModalToggler} open={TaxGroup}>
        <div className="flex flex-col gap-6">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
            <CircleIcon>
              <Svgs.Percent />
            </CircleIcon>
            <div className="flex-1 flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Tax Group Details" : translation[729]?.value ? translation[729]?.value : "Tax Group Details"}
              </h2>
              <p className="text-[#7B7B7B] text-sm">
                {selectedLanguage?.name == "English"
                  ? "Combine multiple taxes into a group, each tax still shows individually on invoices and reports. To apply this group to your products and services, adjust your tax default settings."
                  : translation[730]?.value
                    ? translation[730]?.value
                    : "Combine multiple taxes into a group, each tax still shows individually on invoices and reports. To apply this group to your products and services, adjust your tax default settings."
                }

              </p>
            </div>
          </div>
          <LoginInput
            required={true}
            title={selectedLanguage?.name == "English" ? "Tax Group Name" : translation[731]?.value ? translation[731]?.value : "Tax Group Name"}
            placeholder={selectedLanguage?.name == "English" ? "Enter Tax Name" : translation[725]?.value ? translation[725]?.value : "Enter Tax Name"}
            name={"name"}
            // charactersMaxLength={25}
            value={groupTax.name}
            onChange={groupTaxHandler}
            onBlur={() => onBlurHandler({ name: names })}
            error={addTaxError?.name}
          />
          <div>
            <Dropwdown
              required={true}
              title={`${selectedLanguage?.name == "English" ? "Tax" : translation[733]?.value ? translation[733]?.value : "Tax"} 1`}
              placeholder={`${selectedLanguage?.name == "English" ? "Select Tax" : translation[734]?.value ? translation[734]?.value : "Select Tax"} 1`}
              name={"tax1_id"}
              value={groupTax.tax1_id}
              onChange={groupTaxHandler}
              onBlur={() =>
                onBlurHandler({ tax1_id: groupTax?.tax1_id, name: "tax1_id" })
              }
              error={addTaxError?.tax1_id}
              options={taxGroupOption?.filter(
                (item) => item.id != groupTax?.tax2_id
              )}
            />
            {(taxGroupOption?.filter(
              (item) => item.id != groupTax?.tax2_id
            )?.length == 0 || taxGroupOption == undefined) && (
                <div
                  className="flex items-center gap-2 cursor-pointer pt-2"
                >
                  <p className="text-sm">
                    {selectedLanguage?.name == "English" ? "No tax found. Click" : translation[748]?.value ? translation[748]?.value : "No tax found. Click"}
                    <span className="underline mx-1"
                      onClick={() => {
                        // groupModalToggler(false);
                        taxModalToggler(true);
                      }}>
                      {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                    </span>
                    {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                  </p>
                </div>
              )}
          </div>
          <div>
            <Dropwdown
              required={true}
              title={`${selectedLanguage?.name == "English" ? "Tax" : translation[733]?.value ? translation[733]?.value : "Tax"} 2`}
              placeholder={`${selectedLanguage?.name == "English" ? "Select Tax" : translation[734]?.value ? translation[734]?.value : "Select Tax"} 2`}
              name={"tax2_id"}
              value={groupTax.tax2_id}
              onChange={groupTaxHandler}
              onBlur={() => onBlurHandler({ tax2_id: groupTax?.tax2_id })}
              error={addTaxError?.tax2_id}
              options={taxGroupOption?.filter(
                (item) => item.id != groupTax?.tax1_id
              )}
            />
            {(taxGroupOption?.filter(
              (item) => item.id != groupTax?.tax1_id
            )?.length == 0 || taxGroupOption == undefined) && (
                <div
                  className="flex items-center gap-2 cursor-pointer pt-2"
                >
                  <p className="text-sm">
                    {selectedLanguage?.name == "English" ? "You have not added 2nd tax . Click "
                      : translation[749]?.value ? translation[749]?.value : "You have not added 2nd tax . Click "}
                    <span className="underline" onClick={() => {
                      taxModalToggler(true);
                      // groupModalToggler();
                    }}>
                      {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                    </span>
                    {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                  </p>
                </div>
              )}
          </div>
          <div className="flex items-center justify-start mt-[2rem]">
            <LoginBtn
              onClick={async () => {
                setTaxLoading(true);
                onAddTaxSubmit("group");
                setTimeout(() => {
                  setTaxLoading(false);
                }, 300);
              }}
              text={isUpdate ?
                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
              disabled={tax_btn_loading}
              loading={tax_btn_loading}
            />
          </div>
        </div>
      </Popup>


      {/* Payment Method Popup */}
      <Popup
        heading={isEdit ?
          selectedLanguage?.name == "English" ? "Update Payment Method" : translation[714]?.value ? translation[714]?.value : "Update Payment Method" :
          selectedLanguage?.name == "English" ? "Add Payment Method" : translation[714]?.value ? translation[714]?.value : "Add Payment Method"}
        close={paymentModalToggle}
        open={Payment}
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
            <CircleIcon>
              <Svgs.Credit_card />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Payment Methods" : translation[190]?.value ? translation[190]?.value : "Payment Methods"}
              </h2>
              <p className="text-[#7B7B7B] text-sm">
                {selectedLanguage?.name == "English" ? "Select Payment Methods" : translation[736]?.value ? translation[736]?.value : "Select Payment Methods"}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[1rem]">
            <LoginInput
              title={selectedLanguage?.name == "English" ? "Payment Method Name" : translation[737]?.value ? translation[737]?.value : "Payment Method Name"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Payment Method Name" : translation[738]?.value ? translation[738]?.value : "Enter Payment Method Name"}
              name={"name"}
              // charactersMaxLength={25}
              value={method_type.name}
              // onEnterSubmit={() => {
              //   onProdcutSubmit(1);
              // }}
              onBlur={() => onBlurHandler({ name: name })}
              error={errors?.name}
              onChange={(e) => paymentMethodHandler(e)}
            />

            <Dropwdown
              required={true}
              name={"status"}
              value={method_type.status}
              onChange={paymentMethodHandler}
              disabled={isEdit && reduxPaymentMethodList?.length == 1}
              // onBlur={() => onBlurHandler({ name: name })}
              // error={errors?.name}
              title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
              placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
              options={[
                {
                  value: true,
                  label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                },
                {
                  value: false,
                  label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                },
              ]}
            />
            {/* <CheckboxCard
              disabled={selectedPaymentMethods?.includes("Cash")}
              name={"cash"}
              error={errors?.payment_method}
              value={method_type}
              checked={
                selectedPaymentMethods?.includes("Cash") ||
                  method_type?.includes("Cash")
                  ? true
                  : false
              }
              // checked={selectedPaymentMethods?.includes("Cash") ? true : method_type === "Cash"}
              onClick={(e) => {
                paymentMethodHandler(e, "Cash");
              }}
              padding="pl-2"
              title={
                <div className="flex items-center gap-3">
                  <Svgs.Cash_green />
                  <p>Cash</p>
                </div>
              }
            />

            <CheckboxCard
              disabled={selectedPaymentMethods?.includes("Mastercard")}
              name={"mastercard"}
              error={errors?.payment_method}
              value={method_type}
              checked={
                selectedPaymentMethods?.includes("Mastercard") ||
                  method_type?.includes("Mastercard")
                  ? true
                  : false
              }
              // checked={selectedPaymentMethods?.includes("Mastercard") ? true : method_type === "Mastercard"}
              onClick={(e) => {
                paymentMethodHandler(e, "Mastercard");
              }}
              padding="pl-2"
              title={
                <div className="flex items-center gap-3">
                  <Svgs.MasterCard />
                  <p>Master Card</p>
                </div>
              }
            />

            <CheckboxCard
              disabled={selectedPaymentMethods?.includes("Visa")}
              name={"visa"}
              error={errors?.payment_method}
              value={method_type}
              checked={
                selectedPaymentMethods?.includes("Visa") ||
                  method_type?.includes("Visa")
                  ? true
                  : false
              }
              onClick={(e) => {
                paymentMethodHandler(e, "Visa");
              }}
              padding="pl-2"
              title={
                <div className="flex items-center gap-3">
                  <Svgs.VISA />
                  <p>VISA</p>
                </div>
              }
            />

            <CheckboxCard
              disabled={selectedPaymentMethods?.includes("Paypal")}
              name={"paypal"}
              error={errors?.payment_method}
              value={method_type}
              checked={
                selectedPaymentMethods?.includes("Paypal") ||
                  method_type?.includes("Paypal")
                  ? true
                  : false
              }
              onClick={(e) => {
                paymentMethodHandler(e, "Paypal");
              }}
              padding="pl-2"
              title={
                <div className="flex items-center gap-3">
                  <Svgs.paypal />
                  <p>Paypal</p>
                </div>
              }
            />

            <CheckboxCard
              disabled={selectedPaymentMethods?.includes("GooglePay")}
              name={"google_pay"}
              error={errors?.payment_method}
              value={method_type}
              checked={
                selectedPaymentMethods?.includes("GooglePay") ||
                  method_type?.includes("GooglePay")
                  ? true
                  : false
              }
              onClick={(e) => {
                paymentMethodHandler(e, "GooglePay");
              }}
              padding="pl-2"
              title={
                <div className="flex items-center gap-3">
                  <Svgs.Gpay />
                  <p>Google Pay</p>
                </div>
              }
            />

            <CheckboxCard
              disabled={selectedPaymentMethods?.includes("ApplePay")}
              name={"apple_pay"}
              error={errors?.payment_method}
              value={method_type}
              checked={
                selectedPaymentMethods?.includes("ApplePay") ||
                  method_type?.includes("ApplePay")
                  ? true
                  : false
              }
              onClick={(e) => {
                paymentMethodHandler(e, "ApplePay");
              }}
              padding="pl-2"
              title={
                <div className="flex items-center gap-3">
                  <Svgs.ApplePay />
                  <p>Apple Pay</p>
                </div>
              }
            /> */}
          </div>
          <div className="flex items-center justify-start mt-[2rem]">
            <LoginBtn
              // disabled={!method_type.name
              //   // || !method_type.status
              // }
              text={isEdit ?
                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
              onClick={async (e) => {
                setTaxLoading(true);
                onAddPaymentMethod(e);
                setTimeout(() => {
                  setTaxLoading(false);
                }, 300);
              }}

              loading={tax_btn_loading}
            />
          </div>
        </div>
      </Popup>

      {/* Apply Tax on Locations Popup */}
      <Popup
        heading={selectedLanguage?.name == "English" ? "Apply Tax on Locations" : translation[732]?.value ? translation[732]?.value : "Apply Tax on Locations"}
        close={() => {
          setErrors({});
          applyModalToggle();
          setIsUpdate(false);
        }}
        open={apply}
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
            <CircleIcon>
              <Svgs.Location_percent />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Tax Details" : translation[721]?.value ? translation[721]?.value : "Tax Details"}
              </h2>
              <p className="text-[#7B7B7B] text-sm">
                {selectedLanguage?.name == "English"
                  ? "Combine multiple taxes into a group, each tax still shows individually on"
                  : translation[750]?.value
                    ? translation[750]?.value
                    : "Combine multiple taxes into a group, each tax still shows individually on"
                }
                <br className="hidden md:block" />
                {selectedLanguage?.name == "English"
                  ? "invoices and reports"
                  : translation[751]?.value
                    ? translation[751]?.value
                    : "invoices and reports"
                } .
              </p>
            </div>
          </div>

          <Dropwdown
            required={true}
            title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
            placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
            name={"location"}
            value={location}
            onChange={applyTaxHandleChange}
            // onBlur={() => onBlurHandler({ location: location })}
            error={addTaxError?.location}
            // options={taxLocationOption}
            options={taxLocationOption?.filter(
              (tx_loc) =>
                !reduxBusinessTax.find((itm) => {
                  if (!isUpdate) {
                    if (
                      itm.tax_type === "Location" &&
                      itm?.location?.id === tx_loc.id
                    ) {
                      return true;
                    }
                  }
                })
            )}
            disabled={isUpdate}
          />

          <div>
            <Dropwdown
              required={true}
              type="number"
              name={"tax_rate"}
              value={taxt_rates}
              onChange={(e) => applyTaxHandleChange(e)}
              // onBlur={() => onBlurHandler({ tax_rate: taxt_rates })}
              error={addTaxError?.tax_rate}
              rightIcon={<Svgs.Percent />}
              title={selectedLanguage?.name == "English" ? "Tax" : translation[733]?.value ? translation[733]?.value : "Tax"}
              options={reduxBusinessTax
                ?.filter((item) => item.tax_type != "Location")
                .map((itm) => {
                  return {
                    label: itm.tax_type === "Individual" ? <div className="flex items-center gap-2">
                      <Svgs.singleTaxIcon width="24" height="24" /> {itm.name}
                    </div> : <div className="flex items-center gap-2">
                      <Svgs.groupTaxIcon width="24" height="24" /> {itm.name}
                    </div>,
                    value: itm.id,
                  };
                })}
              placeholder={selectedLanguage?.name == "English" ? "Select Tax" : translation[734]?.value ? translation[734]?.value : "Select Tax"}
            />
            {reduxBusinessTax
              ?.filter((item) => item.tax_type != "Location")
              .map((itm) => {
                // item.tax_type === "Individual" &&
                return {
                  label: itm.name,
                  value: itm.id,
                };
              })?.length == 0 && (
                <div
                  className="flex items-center gap-2 cursor-pointer pt-2"
                  onClick={() => {
                    //humayun
                    setErrors({});
                    applyModalToggle();
                    setIsUpdate(false);
                    taxModalToggler(true);
                  }}
                >
                  <p className="text-sm">
                    {selectedLanguage?.name == "English" ? "No tax found. Click" : translation[748]?.value ? translation[748]?.value : "No tax found. Click"}
                    <span className="underline mx-1">
                      {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                    </span>
                    {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                  </p>
                </div>
              )}
          </div>
          {/* <LoginInput
                        required={true}
                        type='number'
                        name={"tax_rate"}
                        value={taxt_rates}
                        onChange={(e) => applyTaxHandleChange(e)}
                        onBlur={() => onBlurHandler({ tax_rate: taxt_rates })}
                        error={addTaxError?.tax_rate} rightIcon={<Svgs.Percent />}
                        title='Tax Rate'
                        placeholder='Enter Tax Rate' /> */}

          <div className="flex items-center justify-start mt-[2rem]">
            <LoginBtn
              text={isUpdate ?
                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
              onClick={async () => {
                setTaxLoading(true);
                onAddTaxSubmit("location");
                setTimeout(() => {
                  setTaxLoading(false);
                }, 300);
              }}
              disabled={tax_btn_loading}
              loading={tax_btn_loading}
            />
          </div>
        </div>
      </Popup>


      {
        OpenSettings && <Popup size={'md'}
          heading={selectedLanguage?.name == "English" ? "Smart Tax Implementation" : translation[1203]?.value ? translation[1203]?.value : "Smart Tax Implementation"}
          open={OpenSettings} close={() => {
            setOpenSettings()
          }}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between gap-3">
              <div>
                <p>{selectedLanguage?.name == "English" ? "Apply Combine Tax" : translation[1204]?.value ? translation[1204]?.value : "Apply Combine Tax"}</p>
              </div>
              <div>
                <ToggleWithText
                  checked={taxSetting === 'Combined'}
                  value={taxSetting}
                  name="taxSetting"
                  onChange={() => setTaxSetting('Combined')}
                />
              </div>
            </div>
            <div className="flex items-center justify-between gap-3">
              <div>
                <p>{selectedLanguage?.name == "English" ? "Apply Tax Separately" : translation[1205]?.value ? translation[1205]?.value : "Apply Tax Separately"}</p>

              </div>
              <div>
                <ToggleWithText
                  checked={taxSetting === 'Seperately'}
                  value={taxSetting}
                  name="taxSetting"
                  onChange={() => setTaxSetting('Seperately')}
                />
              </div>
            </div>
            <div className="flex items-center justify-end mt-[2rem]">
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                loading={taxSettingLoader}
                disabled={taxSettingLoader}
                onClick={() => {
                  onTaxSettingSubmit()
                }}
              />
            </div>
          </div>
        </Popup>
      }

    </>
  );
};

export default FinancialSettings;
