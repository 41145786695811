import { useState, useEffect } from "react";
import LocationStep from "./LocationStep";
import ServicesStep from "./ServicesStep";
import ClientsStep from "./ClientsStep";
import EmployeeStep from "./EmployeeStep";
import { UpdateUserBusinessDefaultData, checkEmployeeExistance, getUserDefaultData } from "Adapters/Api/businessLocationInNstyleApp";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getservice } from "Adapters/Api/services";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

export const AccountSetupHelper = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const business_data = getDataFromLocalStorage("business_data")

    const { setErrors, validation, errors } = useValidations();

    const { objectKeyConvertToArray } = useObjectKeys()

    const state = useSelector((state) => state);
    const currencyList = state?.utility?.currency?.currency_list

    const [Open, setOpen] = useState(true);
    const [Step, setStep] = useState(1);
    const [formData, setFormData] = useState({})
    const [btn_loading, setBtnLoading] = useState(false)
    const [selecteDuration, setDuration] = useState([{ index: "", duration: "" }]);
    const [selectePrice, setPrice] = useState([{ index: "", price: "" }]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [servicesList, setServicesList] = useState([])
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [isClientEmailValid, setIsClientEmailValid] = useState(true)
    const [formErrors, setFormErrors] = useState()
    const [descriptionErrors, setDescriptionErrors] = useState(Array(formData?.service?.length)?.fill(""));

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(email);
    };

    const handleChange = (e, module_name) => {
        let { name, value } = e.target

        setFormData({
            ...formData,
            [module_name]: {
                ...formData[module_name],
                [name]: value
            }
        })
        // setErrors({
        //     ...errors,
        //     [module_name]: {
        //         ...errors[module_name],
        //         [name]: ''
        //     }
        // })
    }

    const serviceStepHandleChange = (e, objectType, index) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData };
        const updatedDescriptionErrors = [...descriptionErrors];

        if (name !== 'description') {
            // Update the respective service object based on the index
            if (objectType === 'service') {
                const updatedService = { ...updatedFormData.service[index], [name]: value };
                updatedFormData.service[index] = updatedService;
            }

            // Update the state with the modified form data
            setFormData(updatedFormData);
        } else {
            if (name === 'description' && value?.length > 150) {
                updatedDescriptionErrors[index] = 'Description length must be less than or equal to 150 characters.';
                setDescriptionErrors(updatedDescriptionErrors);
            } else {
                updatedDescriptionErrors[index] = ''; // Clear the error message if length is within limits
                setDescriptionErrors(updatedDescriptionErrors);

                // Update the respective service object based on the index
                if (objectType === 'service') {
                    const updatedService = { ...updatedFormData.service[index], [name]: value };
                    updatedFormData.service[index] = updatedService;
                }

                // Update the state with the modified form data
                setFormData(updatedFormData);
            }
        }
        const updatedFormErrors = [...formErrors];
        updatedFormErrors[index] = {
            ...updatedFormErrors[index],
            [name]: '',
        };
        setFormErrors(updatedFormErrors);
    };

    const handleAssignedServicesChange = (e) => {
        const newAssignedService = servicesList?.find(srv => srv?.id == e.target.value);
        setFormData((prev) => {
            const existingServices = prev?.employee?.assigned_services || [];

            // Check if the new service already exists
            const isServiceExist = existingServices?.some(service => service?.id == newAssignedService?.id);

            return {
                ...prev,
                employee: {
                    ...prev.employee,
                    assigned_services: isServiceExist
                        ? existingServices // Keep the existing array if service exists
                        : [...existingServices, { id: newAssignedService?.id, name: newAssignedService?.name }],
                },
            };
        });

        // setFormData((prev) => ({
        //     ...prev,
        //     employee: {
        //         ...prev.employee,
        //         assigned_services: Array.from(new Set([...prev?.employee?.assigned_services, { id: newAssignedService?.id, name: newAssignedService?.name }])),
        //     },
        // }));
    };

    const addDurationPrice = (data, index) => {
        setFormData((prevFormData) => {
            const updatedService = {
                ...prevFormData.service[index],
                priceservice: [...prevFormData.service[index].priceservice, { ...data }],
            };
            const updatedServiceArray = [...prevFormData.service];
            updatedServiceArray[index] = updatedService;

            return {
                ...prevFormData,
                service: updatedServiceArray,
            };
        });
    };

    const removeSelectedPrice = (duration, price, id, type, index) => {
        if (type === 'priceservice') {
            setFormData((prev) => {
                const updatedService = {
                    ...prev.service[index],
                    priceservice: prev.service[index].priceservice.filter(
                        (item) => item.duration !== duration || item.price !== price || item?.id !== id
                    ),
                };
                const updatedServiceArray = [...prev.service];
                updatedServiceArray[index] = updatedService;

                return {
                    ...prev,
                    service: updatedServiceArray,
                };
            });
        }
    };


    const removeEmployeeAssignedService = (id, type) => {
        if (type === "assigned_services") {
            setFormData((prev) => ({
                ...prev,
                employee: {
                    ...prev.employee,
                    assigned_services: [
                        ...prev?.employee?.assigned_services?.filter(
                            (itm) => itm.id !== id
                        ),
                    ],
                },
            }));
        }
    };

    useEffect(() => {
        const servicesList = state.service.services;
        if (servicesList) {
            setServicesList(servicesList)
        }
    }, [state.service.services])

    useEffect(() => {
        if (formData?.employee && formData?.service && Step == 2) {
            // updating assigned services array in employee data after updating services in second step 2
            const updatedEmployee = { ...formData.employee };
            const updatedAssignedServices = updatedEmployee.assigned_services?.map((assigned) => {
                const matchingService = formData.service?.find((serv) => serv.id === assigned.id);
                return {
                    ...assigned,
                    name: matchingService ? matchingService?.name : assigned?.name,
                };
            });
            updatedEmployee.assigned_services = updatedAssignedServices;
            setFormData((prevFormData) => ({
                ...prevFormData,
                employee: updatedEmployee,
            }));
        }
    }, [formData, formData?.service, Step]);

    useEffect(() => {
        if (formData?.employee && formData?.service && Step == 2) {
            // Updating the servicesList array after updating services in the third step (Step 3)
            if (formData?.employee?.assigned_services?.length > 0) {
                const updatedServicesList = servicesList?.map((service) => {
                    const matchingAssignedService = formData.employee.assigned_services.find((assigned) => assigned.id === service.id);
                    return {
                        ...service,
                        name: matchingAssignedService ? matchingAssignedService.name : service.name,
                    };
                });

                setServicesList(updatedServicesList);
            } else {
                setServicesList(formData?.service)
            }
        }
    }, [formData, formData?.service, formData?.employee, formData?.employee?.assigned_services, Step]);

    // useEffect(() => {
    //     let isDisabled = false;
    //     if (Step == 1 && (!formData?.location?.name
    //         || !formData?.location?.business_address
    //         || !formData?.location?.currency
    //         // || !formData?.location?.email
    //     )) {
    //         isDisabled = true;
    //     } else if (Step === 2 && (!formData?.service_group?.name
    //         || !formData?.service
    //         || formData?.service.some((serv) =>
    //             !serv?.name ||
    //             !serv?.description ||
    //             (serv?.priceservice?.length === 0)
    //         ))
    //     ) {
    //         isDisabled = true;
    //     } else if (Step == 3 && ((!formData?.employee?.name
    //         // || !formData?.employee?.email
    //         || !formData?.employee?.address
    //         || !formData?.employee?.designation
    //         || !formData?.employee?.income_type
    //         || !formData?.employee?.salary
    //         || formData?.employee?.assigned_services?.length === 0))) {
    //         isDisabled = true;
    //     }
    //     //  else if (Step == 4 && ((!formData?.client?.name
    //     //     || !formData?.client?.phone_number
    //     //     || formData?.client?.phone_number?.split('-')[1]?.length == 0
    //     //     || !formData?.client?.email
    //     //     || formData?.service?.priceservice?.length === 0))) {
    //     //     isDisabled = true;
    //     // }
    //     setIsButtonDisabled(isDisabled);
    // }, [formData, Step]);

    useEffect(() => {
        if (!formData?.location?.currency && currencyList?.length > 0) {
            setFormData((prev) => ({
                ...prev,
                location: {
                    ...prev.location,
                    currency: currencyList[0]?.id,
                },
            }));
        }
    }, [formData?.location?.currency, currencyList])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (localStorage.getItem("callGetDefaultData")) {
                getBusinessDefaultData()
                dispatch(getservice())
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const data = {
        1: <LocationStep
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
            currencyList={currencyList}
            errors={errors}
        />,
        2: <ServicesStep
            handleChange={handleChange}
            serviceStepHandleChange={serviceStepHandleChange}
            formData={formData}
            selecteDuration={selecteDuration}
            setDuration={setDuration}
            selectePrice={selectePrice}
            addDurationPrice={addDurationPrice}
            removeSelectedPrice={removeSelectedPrice}
            currency={currencyList?.find(itm => itm?.id == formData?.location?.currency)?.code}
            descriptionErrors={descriptionErrors}
            currencyList={currencyList}
            errors={errors}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            setPrice={setPrice}
        />,
        3: <EmployeeStep
            handleChange={handleChange}
            handleAssignedServicesChange={handleAssignedServicesChange}
            removeEmployeeAssignedService={removeEmployeeAssignedService}
            servicesList={servicesList}
            setFormData={setFormData}
            errors={errors}
            isValidEmail={isValidEmail}
            formData={formData}
            setErrors={setErrors}
        />,
        4: <ClientsStep
            isClientEmailValid={isClientEmailValid}
            handleChange={handleChange}
            formData={formData}
            errors={errors}
            setErrors={setErrors}
            btn_loading={btn_loading}
        />,
    }

    const onUpdateChanges = () => {
        setBtnLoading(true)
        UpdateUserBusinessDefaultData(
            formData,
            () => {
                toast.success('Data updated successfully', { toastId: "toast" })
                localStorage.removeItem('ask_for_change_default_data')
                window.location.reload()
                setOpen(!Open)
                setBtnLoading(false)
            },
            () => {
                setBtnLoading(false)
            }
        )
    }

    const getBusinessDefaultData = () => {
        console.log(business_data, "business_data")
        getUserDefaultData(
            {
                business_id: business_data?.id
            },
            (result) => {
                if (result) {
                    setFormData(result)
                }
                localStorage.removeItem("callGetDefaultData")
            },
            () => {
                localStorage.removeItem("callGetDefaultData")
            }
        )
    }

    const checkEmailExistance = (email) => {
        dispatch(
            checkEmployeeExistance(
                email,
                (response) => {
                    if (response?.data?.status == 200) {
                        if (Step == 4) {
                            setErrors(prev => ({
                                ...prev,
                                client_email: "Email already registered"
                            }));
                        } else {
                            setErrors(prev => ({
                                ...prev,
                                email: "Email already registered"
                            }));
                        }

                        const element = document.querySelector(".topscroll");
                        if (element) {
                            element.scrollIntoView();
                        }
                        // toast.error("Email already registered", { toastId: "toast" })
                    }
                },
                (result) => {
                    if (result?.data?.status == 404) {
                        if (Step == 4) {
                            if (
                                formData?.client?.phone_number != formData?.admin_phone_number
                            ) {
                                const phoneNumber = formData?.client?.phone_number;
                                const hyphenIndex = phoneNumber.indexOf('-');
                                const digitsAfterHyphen = phoneNumber.length - hyphenIndex - 1;

                                if (digitsAfterHyphen >= 8 && digitsAfterHyphen <= 12) {
                                    // Phone number is valid
                                    onUpdateChanges();
                                } else {
                                    setErrors(prev => ({
                                        ...prev,
                                        phone_number: "Phone number length should be between 8 and 12"
                                    }));
                                    // toast.error("Phone number length should be between 8 and 12", { toastId: "toast" });
                                }
                            } else {
                                setErrors(prev => ({
                                    ...prev,
                                    phone_number: "Phone number already registered"
                                }));
                                // toast.error("Phone number already registered", { toastId: "toast" });
                            }
                        } else {
                            setStep(Step < 4 ? Step + 1 : 1)
                        }
                    }
                }
            )
        )
    }

    const handleDataSubmit = () => {
        console.log(Step, formData, "Step")
        if (Step == 4) {
            if (formData?.client?.name || formData?.client?.phone_number?.split("-")[1] || formData?.client?.email) {
                let requiredFields = {
                    name: formData?.client?.name,
                    phone_number: formData?.client?.phone_number?.split("-")[1]
                }
                setErrors(validation(requiredFields));
                let result = validation(requiredFields);
                console.log(objectKeyConvertToArray(result)?.length, "objectKeyConvertToArray(result)?.length")
                if (objectKeyConvertToArray(result)?.length === 0) {
                    if (!formData?.client?.email) {
                        onUpdateChanges();
                    } else {
                        if (formData?.client?.email != formData?.admin_email && formData?.client?.email != formData?.employee?.email) {
                            const isValidEmail = validateEmail(formData?.client?.email);
                            if (isValidEmail) {
                                setIsClientEmailValid(isValidEmail);
                                checkEmailExistance(formData?.client?.email)
                            } else {
                                setIsClientEmailValid(isValidEmail);
                                const element = document.querySelector(".topscroll");
                                if (element) {
                                    element.scrollIntoView();
                                }
                            }
                        } else {
                            setErrors(prev => ({
                                ...prev,
                                client_email: "Email already registered"
                            }));
                            // toast.error("Email already registered", { toastId: "toast" });
                        }
                    }
                } else {
                    document.querySelector('.topscroll').scrollIntoView();
                }
            } else {
                onUpdateChanges();
            }
        }
        else if (Step == 3) {
            let requiredFields = {
                name: formData?.employee?.name,
                address: formData?.employee?.address,
                designation: formData?.employee?.designation,
                income_type: formData?.employee?.income_type,
                salary: formData?.employee?.salary
            }
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (objectKeyConvertToArray(result)?.length === 0) {
                if (formData?.employee?.assigned_services?.length == 0) {
                    setErrors(prev => ({
                        ...prev,
                        assigned_service: "Assigned at least one service to this employee"
                    }));
                } else {
                    // Additional validation for email input
                    if (formData?.employee?.email) {
                        if (formData?.employee?.email != formData?.admin_email) {
                            const isValidEmail = validateEmail(formData?.employee?.email);
                            if (isValidEmail) {
                                setIsValidEmail(isValidEmail)
                                checkEmailExistance(formData?.employee?.email)
                            } else {
                                setIsValidEmail(isValidEmail)
                                document.querySelector('.topscroll').scrollIntoView();
                            }
                        } else {
                            setErrors(prev => ({
                                ...prev,
                                email: "Email already registered"
                            }));
                            // toast.error("Email already registered", { toastId: "toast" })
                        }
                    } else {
                        setStep(Step < 4 ? Step + 1 : 1)
                        const element = document.querySelector(".topscroll");
                        if (element) {
                            element.scrollIntoView();
                        }
                    }
                }
            } else {
                document.querySelector('.topscroll').scrollIntoView();
            }
        }
        else if (Step == 2) {
            let requiredFields = { name: formData?.service_group?.name }
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (objectKeyConvertToArray(result)?.length === 0) {
                const errors = [];
                formData?.service?.forEach((data, index) => {
                    const fieldErrors = {};
                    if (!data.name) {
                        fieldErrors.name = 'This field is required';
                        fieldErrors.index = index
                    }
                    if (!data.description) {
                        fieldErrors.description = 'This field is required';
                        fieldErrors.index = index
                    }
                    if (data?.priceservice?.length === 0) {
                        fieldErrors.price = "Add atleast one duration and price"
                        fieldErrors.index = index
                    }
                    errors[index] = fieldErrors;
                });
                // Update formErrors state with the new errors
                setFormErrors(errors);
                // If there are errors, return early and do not proceed with form submission
                if (errors?.some((error) => Object.keys(error)?.length > 0)) {
                    document.querySelector('.topscroll').scrollIntoView();
                    return;
                } else {
                    setStep(Step < 4 ? Step + 1 : 1)
                    document.querySelector('.topscroll').scrollIntoView();
                }
            } else {
                document.querySelector('.topscroll').scrollIntoView();
            }
        } else {
            let requiredFields = {
                name: formData?.location?.name,
                address_name: formData?.location?.business_address,
                currency: formData?.location?.currency
            }
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (objectKeyConvertToArray(result)?.length === 0) {
                document.querySelector('.topscroll').scrollIntoView();
                setStep(Step < 4 ? Step + 1 : 1)
            } else {
                document.querySelector('.topscroll').scrollIntoView();
            }
        }
    }
    console.log(errors, "<==== error")
    return {
        Open,
        setOpen,
        Step,
        setStep,
        data,
        onUpdateChanges,
        handleChange,
        btn_loading,
        formData,
        isButtonDisabled,
        validateEmail,
        handleDataSubmit,
        errors
    }
}