import React, { useState, useEffect } from "react";

import { CSVLink } from "react-csv";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";


import Svgs, { Plus } from "Assets/svgs";
import { useNavigate } from "react-router-dom";
import AddInvoiceTranslation from "./AddInvoiceTranslation";
import useInvoiceTranslation from "./helper";
import Popup from "Components/TenantApp/Elements/Popup";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NotFound from "Components/Utility/NotFound";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoInvoiceTranslationRecordFound from "Components/Utility/NoInvoiceTranslationRecordFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const InvoiceTranslation = () => {
    const [invoicesData, setInvoicesData] = useState([])
    const { invoiceData, setInvoiceData, reduxLocations, reduxLanguages, loading, reduxInvoices, submitLoader, edit, setEdit, openInvoice,
        setOpenInvoice, deleteModal, setDeleteModal, deleteInvoices, getInovices, onEditHandler, onDeleteHandler, onHandleSubmit,
        onChangeHandler, translation, selectedLanguage, search_text, setSearchText, errors, csvReport, toast,
        exportLoader, handleExportClick, setErrors, loadingPermission
    } = useInvoiceTranslation()

    useEffect(() => {
        getInovices()
    }, [])

    useEffect(() => {
        let filteredInvoices = []
        if (search_text) {
            filteredInvoices = reduxInvoices?.filter((invoice) => {
                return invoice?.language_data?.name
                    ?.toLowerCase()
                    .includes(search_text?.toLowerCase());
            });
        } else {
            filteredInvoices = reduxInvoices
        }
        setInvoicesData(filteredInvoices)
    }, [reduxInvoices, reduxInvoices?.length, search_text])

    const navigate = useNavigate()
    // const [employee, setEmployee] = useState();
    // const [employeePermissions, setEmployeePermissions] = useState([]);

    // ?.filter((invoice) => {
    //     if (
    //         invoice?.location_data?.address_name
    //             ?.toLowerCase()
    //             .includes(search_text.toLowerCase())
    //     ) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // })

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
            <p className="text-[#7B7B7B] text-sm">
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        navigate("/dashboard/account/");
                    }}
                >
                    {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
                </span>{" "}
                &gt;{" "}
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        navigate("/dashboard/account/setup");
                    }}
                >
                    {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
                </span>{" "}
                &gt; <span className="text-primary font-semibold">
                    {selectedLanguage?.name == "English" ? "Invoice Translation" : translation[668]?.value ? translation[668]?.value : "Invoice Translation"}
                </span>
            </p>
            <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
                <div className="flex items-center sm:gap-4 gap-2">

                    <h2 className="text-primary font-bold lg:text-3xl text-xl">
                        {selectedLanguage?.name == "English" ? "Invoice Translation" : translation[668]?.value ? translation[668]?.value : "Invoice Translation"}
                    </h2>
                </div>
                <div className="flex items-center sm:gap-4 gap-2">
                    {loadingPermission ?
                        <Shimmer className={'w-44 lg:w-48'}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer> :
                        <LoginBtn
                            onClick={() => {
                                setOpenInvoice(!openInvoice)
                            }}
                            text={<div className="flex items-center gap-3">
                                <Plus color={"#fff"} />
                                {selectedLanguage?.name == "English" ? "Add Invoice Translation" : translation[803]?.value ? translation[803]?.value : "Add Invoice Translation"}
                            </div>}
                        />
                    }
                </div>
            </div>
            <div className="border rounded-lg bg-white">
                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
                    <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4">
                        <div className="flex items-center sm:gap-4 gap-2 ">
                            <h2 className="font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Translations" : translation[787]?.value ? translation[787]?.value : "Translations"}
                            </h2>
                            {loading ?
                                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                                </div>
                                :
                                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                    <p className="text-primary">
                                        {invoicesData?.length == 0 ? 0 : invoicesData?.length} {" "}
                                        {invoicesData?.length > 1 ?
                                            selectedLanguage?.name == "English" ? "Invoices" : translation[1305]?.value ? translation[1305]?.value : "Invoices" :
                                            selectedLanguage?.name == "English" ? "Invoice" : translation[1305]?.value ? translation[1305]?.value : "Invoice"}
                                    </p>
                                </div>
                            }
                        </div>
                        <div className="mb-00">
                            {loadingPermission ?
                                <Shimmer className={'w-44 lg:w-48'}>
                                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                                </Shimmer> :
                                <LoginInput
                                    title=""
                                    placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                                    padding="py-1"
                                    leftIcon={<Svgs.Search />}
                                    value={search_text}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                />
                            }
                        </div>
                    </div>
                    {loadingPermission ?
                        <Shimmer className={'w-12 lg:w-16'}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer> :
                        exportLoader ? (
                            <div className="h-[4rem] flex items-center justify-center">
                                <PreloaderSm />
                            </div>
                        ) :
                            reduxInvoices?.length > 0 ?
                                <>
                                    <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                                        <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                                            <LoginBtn
                                                className="rounded-lg !py-[7px]"
                                                bg="bg-white"
                                                animation={false}
                                                customThemeBtn={'theme-btn2'}
                                            >
                                                <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                    <Svgs.ExportSvg />
                                                </div>
                                            </LoginBtn>
                                        </CSVLink>
                                    </div>
                                </> :
                                <LoginBtn
                                    className="rounded-lg border-2 border-primary"
                                    bg="bg-white"
                                    animation={false}
                                    onClick={handleExportClick}
                                >
                                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                                        <Svgs.Export bg="#FFFFFF" />
                                    </div>
                                </LoginBtn>
                    }
                </div>
                {loading ?
                    <TableShimmer_v2 cols={3} />
                    :
                    <div className="overflow-x-auto">
                        <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(155px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                            {/* <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p> */}
                            <p>{selectedLanguage?.name == "English" ? "Language" : translation[805]?.value ? translation[805]?.value : "Language"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                        </div>
                        {invoicesData?.length > 0 ? (invoicesData?.map(itm => {
                            return (
                                <div
                                    className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(155px,1fr))] min-w-full w-fit"
                                >
                                    {/* <div className="flex items-center">
                                        <p className="text-sm">{itm?.location_data?.address_name}</p>
                                    </div> */}
                                    <div className="flex items-center">
                                        <p className="text-sm">{itm?.language_data?.name}</p>
                                    </div>
                                    <div className="flex items-center">
                                        <p className="text-sm capitalize">
                                            <StatusBtn
                                                profile_status={
                                                    itm?.status == "active" ?
                                                        selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                                        :
                                                        selectedLanguage?.name == "English" ? "In-Active" : translation[460]?.value ? translation[460]?.value : "In-Active"
                                                }
                                                status={itm?.status == "active" ? "active" : "inActive"}
                                            />
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div
                                            className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center hover:cursor-pointer"
                                            onClick={() => {
                                                setErrors({})
                                                onEditHandler(itm?.id)
                                            }}
                                        >
                                            <Svgs.Edit fill="#7D7D7D" />
                                        </div>
                                        {/* <div
                                            onClick={() => onDeleteHandler(itm?.id)}
                                            className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center hover:cursor-pointer"
                                        >

                                            <Svgs.Delete fill="#7D7D7D" />
                                        </div> */}
                                    </div>
                                </div>
                            )
                        })
                        ) : (
                            <>
                                <NoInvoiceTranslationRecordFound />
                            </>
                        )
                        }

                    </div>
                }
            </div>

            {openInvoice &&
                <Popup
                    close={() => {
                        setEdit(false)
                        setErrors({})
                        setOpenInvoice(false)
                        setInvoiceData({
                            invoice: '',
                            language: '',
                            items: '',
                            amount: '',
                            subtotal: '',
                            tips: '',
                            taxes: '',
                            status: 'active',
                            total: '',
                            payment_method: '',
                        })
                    }}
                    open={openInvoice}

                    heading={
                        edit ?
                            selectedLanguage?.name == "English" ? "Edit Invoice Translation" : translation[804]?.value ? translation[804]?.value : "Edit Invoice Translation"
                            :
                            selectedLanguage?.name == "English" ? "Add Invoice Translation" : translation[803]?.value ? translation[803]?.value : "Add Invoice Translation"
                    }
                >
                    <div className='grid md:grid-cols-2 gap-4'>
                        <Dropwdown
                            name={'language'}
                            title={selectedLanguage?.name == "English" ? "Language" : translation[805]?.value ? translation[805]?.value : "Language"}
                            placeholder={selectedLanguage?.name == "English" ? "Select language" : translation[766]?.value ? translation[766]?.value : "Select language"}
                            value={invoiceData?.language}
                            onChange={onChangeHandler}
                            options={reduxLanguages
                                ?.filter(inv => {
                                    // remove selected invoice language for data showing
                                    return !reduxInvoices?.some(invoice => invoice.language_data.id === inv.id && !invoiceData?.language?.includes(inv.id));
                                })
                                ?.map(inv => {
                                    return {
                                        label: inv?.name,
                                        value: inv?.id,
                                    };
                                })}
                            // options={reduxLanguages?.filter(inv => !reduxInvoices?.some(invoice => invoice.language_data.id == inv.id))
                            //     ?.map((inv) => {
                            //         return {
                            //             label: inv?.name,
                            //             value: inv?.id,
                            //         };
                            //     })}
                            error={errors?.language}
                        />
                        <Dropwdown
                            name={'status'}
                            value={invoiceData?.status}
                            onChange={onChangeHandler}
                            title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
                            options={[
                                {
                                    value: "active",
                                    label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                },
                                {
                                    value: "inactive",
                                    label: selectedLanguage?.name == "English" ? "Inactive" : translation[460]?.value ? translation[460]?.value : "Inactive"
                                },
                            ]}
                        />
                        <div className='md:col-span-2'>
                            <h1 className='text-sm normal-case'>
                                {selectedLanguage?.name == "English"
                                    ? "Add translation of all important labels of the invoice in the selected language"
                                    : translation[790]?.value
                                        ? translation[790]?.value
                                        : "Add translation of all important labels of the invoice in the selected language"}
                            </h1>
                        </div>

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.invoice}
                            name={'invoice'}
                            title={selectedLanguage?.name == "English" ? "Invoice" : translation[411]?.value ? translation[411]?.value : "Invoice"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Invoice" : translation[792]?.value ? translation[792]?.value : "Enter Invoice"}
                            error={errors?.invoice}
                        />

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.items}
                            name={'items'}
                            title={selectedLanguage?.name == "English" ? "Items" : translation[789]?.value ? translation[789]?.value : "Items"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Items" : translation[792]?.value ? translation[792]?.value : "Enter Items"}
                            error={errors?.items}
                        />

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.amount}
                            name={'amount'}
                            title={selectedLanguage?.name == "English" ? "Amount" : translation[793]?.value ? translation[793]?.value : "Amount"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Amount" : translation[794]?.value ? translation[794]?.value : "Enter Amount"}
                            error={errors?.amount}
                        />

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.subtotal}
                            name={'subtotal'}
                            title={selectedLanguage?.name == "English" ? "Subtotal" : translation[242]?.value ? translation[242]?.value : "Subtotal"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Subtotal" : translation[795]?.value ? translation[795]?.value : "Enter Subtotal"}
                            error={errors?.subtotal}
                        />

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.tips}
                            name={'tips'}
                            title={selectedLanguage?.name == "English" ? "Tips" : translation[796]?.value ? translation[796]?.value : "Tips"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Tips" : translation[797]?.value ? translation[797]?.value : "Enter Tips"}
                            error={errors?.tips}
                        />

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.taxes}
                            name={'taxes'}
                            title={selectedLanguage?.name == "English" ? "Taxes" : translation[798]?.value ? translation[798]?.value : "Taxes"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Taxes" : translation[799]?.value ? translation[799]?.value : "Enter Taxes"}
                            error={errors?.taxes}
                        />

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.total}
                            name={'total'}
                            title={selectedLanguage?.name == "English" ? "Total" : translation[800]?.value ? translation[800]?.value : "Total"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Total" : translation[801]?.value ? translation[801]?.value : "Enter Total"}
                            error={errors?.total}
                        />

                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.payment_method}
                            name={'payment_method'}
                            title={selectedLanguage?.name == "English" ? "Payment Method" : translation[742]?.value ? translation[742]?.value : "Payment Method"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Payment Method" : translation[802]?.value ? translation[802]?.value : "Enter Payment Method"}
                            error={errors?.payment_method}
                        />
                        <LoginInput
                            onChange={onChangeHandler}
                            value={invoiceData?.change}
                            name={'change'}
                            title={selectedLanguage?.name == "English" ? "Change" : translation[1581]?.value ? translation[1581]?.value : "Change"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter change" : translation[1641]?.value ? translation[1641]?.value : "Enter change"}
                            error={errors?.change}
                        />

                    </div>
                    <div className='mt-4'>
                        <div>
                            <LoginBtn
                                disabled={submitLoader}
                                // disabled={
                                //     !invoiceData?.language ||
                                //     !invoiceData?.status ||
                                //     !invoiceData?.invoice ||
                                //     !invoiceData?.items ||
                                //     !invoiceData?.amount ||
                                //     !invoiceData?.subtotal ||
                                //     !invoiceData?.tips ||
                                //     !invoiceData?.taxes ||
                                //     !invoiceData?.total ||
                                //     !invoiceData?.payment_method
                                // }
                                loading={submitLoader}
                                text={edit ?
                                    selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                                    : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                                onClick={onHandleSubmit}
                            />
                        </div>
                    </div>
                </Popup>
            }

            {/* 
            {edit &&
                <AddInvoiceTranslation open={edit} close={setEdit} heading={'Edit Invoice Translation'} />
            } */}


            <Popup
                heading={selectedLanguage?.name == "English"
                    ? "Are you sure want to delete this Invoice Translation?"
                    : translation[652]?.value
                        ? translation[652]?.value
                        : "Are you sure want to delete this Invoice Translation?"
                }
                close={setDeleteModal} open={deleteModal}
            >
                <div className="">
                    <p>

                        {selectedLanguage?.name == "English"
                            ? "Are you sure want to delete this"
                            : translation[744]?.value
                                ? translation[744]?.value
                                : "Are you sure want to delete this"
                        }
                        {" "}
                        {selectedLanguage?.name == "English" ? "Invoice Translation?" : translation[668]?.value ? translation[668]?.value : "Invoice Translation?"}
                        {" "}

                        {selectedLanguage?.name == "English"
                            ? "You won't be able to see this again."
                            : translation[745]?.value
                                ? translation[745]?.value
                                : "You won't be able to see this again."
                        }
                    </p>
                    <div className="flex items-center justify-end mt-[2rem]">
                        <LoginBtn
                            loading={submitLoader}
                            disabled={submitLoader}
                            deleteBtn
                            text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                            onClick={deleteInvoices}
                        />
                    </div>
                </div>
            </Popup>

        </div>
    );
};

export default InvoiceTranslation;
