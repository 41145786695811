import Svgs from 'Assets/svgs';
import Footer from 'Components/Utility/Footer';
import Navbar from 'Components/Utility/Navbar';
import React from 'react';
import { useNavigate } from 'react-router-dom';
const FeatureCardData = [
  {
    title: "Seamless Scheduling",
    description: "Let clients book and manage appointments with ease—no more double bookings or confusion.",
    icon: <Svgs.CalenderIcon />
  },
  {
    title: "Secure & Fast Payments",
    description: "Let clients book and manage appointments with ease—no more double bookings or confusion.",
    icon: <Svgs.WalletIcon />
  },
  {
    title: "Organize Staff Efficiently",
    description: "Manage schedules, roles, and staff availability in one place—boost productivity effortlessly.",
    icon: <Svgs.StaffIcon />
  },
  {
    title: "Track & Sell with Ease",
    description: "Keep your stock updated, manage product sales, and simplify retail operations.",
    icon: <Svgs.TrackIcon />
  }
];

const FeaturePage = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="flex flex-col w-full font-manrope landing_page">
        <Navbar active={'feature'} />
        <div className="flex relative overflow-x-hidden flex-col"
        >
          <div className="relative w-full pt-14 pb-28"
            style={{
              background: 'url(/images/gradient-bar.png)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }}>
            <div className="max-w-5xl w-[95%] relative mx-auto flex flex-col gap-3.5 items-center justify-center text-center">
              <h1 className="text-[3.5rem] font-bold">Why Choose NStyle?</h1>
              <p className="text-base">
                Empowering Beauty & Wellness Professionals with Innovation & Simplicity
                <br />
                Experience a smarter way to manage your business—seamless, efficient, and completely hassle-free.
              </p>

              <button
                onClick={() => navigate('/auth/signup/?account_type=business')}
                className='bg_gradient-blue-tr w-full !max-w-[16rem] mt-2.5 rounded-full p-5 text-white text-xl font-semibold'>
                Signup Now
              </button>

              {/* Dashboard Image */}
              <div className="relative mt-11">
                <img
                  src="/images/slider_img.png"
                  alt="NStyle Dashboard Interface"
                  className="rounded-[1.875rem] shadow-2xl w-full max-w-5xl mx-auto"
                />
              </div>
              {/* Decorative Background Shapes */}
            </div>
            <div className="absolute -z-10 -left-16 -top-16 w-80 h-80 bg-primary-blue rounded-full blur-[140px]"></div>
            <div className="absolute -z-10 -right-16 bottom-5 w-80 h-80 bg-primary-green rounded-full blur-[140px] "></div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 mx-auto w-[95%] max-w-[1200px]">
            {FeatureCardData?.map((card, index) => (
              <div key={index} className="border flex flex-col gap-7 rounded-[1.25rem] px-[1.625rem] py-10">
                <div>
                  {card.icon}
                </div>
                <div className="flex flex-col gap-1.5">
                  <h3 className="font-bold text-xl">{card.title}</h3>
                  <p className="text-sm ">{card.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col gap-y-16 py-24 mx-auto w-[95%] max-w-[1200px]">
            {/* Section 1 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Intuitive & Effortless Booking System</h1>
                <p className='text-base'>Say goodbye to complicated appointment scheduling! With NStyle, clients can book and manage their appointments easily while you enjoy a streamlined calendar system.</p>
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>One-click appointment booking</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Real-Time Notifications</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Secure Payments</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex max-h-[28rem]">
                <img src="/images/features/Feature-1.png" alt="" srcset="" className='w-full object-contain' />
              </div>
            </div>

            {/* Section 2 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex order-2 sm:order-none max-h-[28rem]">
                <img src="/images/features/Feature-2.png" alt="" srcset="" className='w-full object-contain' />
              </div>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Fast & Secure Payment Processing</h1>
                <p className='text-base'>Accept payments effortlessly with multiple payment options, ensuring a smooth checkout experience for your clients.</p>
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Credit/Debit card, digital wallets & contactless payments</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Safe, encrypted transactions</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Transparent pricing—no hidden fees</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 3 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Smart Client & Business Management</h1>
                <p className='text-base'>
                  Keep all your client details in one place, track history, preferences, and boost customer engagement.
                </p>
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Client profiles with past services & preferences</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Loyalty & rewards program integration</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Personalized communication & promotions</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex max-h-[28rem]">
                <img src="/images/features/Feature-3.png" alt="" srcset="" className='w-full object-contain' />
              </div>
            </div>

            {/* Section 4 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex order-2 sm:order-none max-h-[28rem]">
                <img src="/images/features/Feature-4.png" alt="" srcset="" className='w-full object-contain' />
              </div>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Staff & Team Coordination Made Easy</h1>
                <p className='text-base'>
                  Manage your team efficiently with advanced scheduling, role assignments, and performance tracking.
                </p>
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Customizable staff schedules</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Role-based access & permissions</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Performance insights & reports</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 5 */}
            <div className='grid sm:grid-cols-2 gap-5 items-center'>
              <div className="flex flex-col gap-5">
                <h1 className='text-[2.5rem] font-bold'>Sell More with Integrated Retail & Inventory</h1>
                <p className='text-base'>
                  Increase revenue by selling beauty products with a fully integrated retail management system.
                </p>
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Track stock levels & sales trends</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Easily add products & manage promotions</p>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex">
                        <Svgs.TickBlueIcon />
                      </div>
                      <p className='text-lg flex-1'>Barcode scanning for quick checkout</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex max-h-[28rem]">
                <img src="/images/features/Feature-5.png" alt="" srcset="" className='w-full object-contain' />
              </div>
            </div>

          </div>

          <div className="w-[95%] max-w-[1200px] feature-cta-bg rounded-[1.25rem] mx-auto mb-32 p-8 md:p-14 relative">
            <div className="grid sm:grid-cols-5 gap-5 w-full max-w-5xl mx-auto">
              <div className="flex flex-col sm:col-span-3 gap-2.5">
                <h1 className="text-[2.125rem] font-bold">
                  Ready to Experience the Future of Beauty & Wellness?
                </h1>
                <p className="text-lg">
                  Join NStyle today and take your business to the next level!
                </p>
              </div>

              <div className="flex justify-between sm:col-span-2 gap-4 items-center">
                <div className="hidden sm:flex w-fit">
                  <Svgs.CurveArrowIcon />
                </div>

                <button
                  onClick={() => navigate('/auth/signup/?account_type=business')}
                  className='flex-1 bg_gradient-blue-tr w-full max-w-max mt-2.5 rounded-full py-4 px-7 text-white text-base font-semibold'>
                  Get Started for Free
                </button>
              </div>
            </div>
          </div>

        </div>

        <Footer />
      </div>
    </>
  );
};

export default FeaturePage;
