import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Svgs from "Assets/svgs";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";

const StaffTargetItem = ({
  item,
  handleUpdateStaffId,
  handleDeleteStaffId,
  currency,
  index,
  showActions
}) => {
  const state = useSelector((state) => state);

  const [Employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const { id, employee, service_target, retail_target, month, year } = item;
  return (
    <>
      {/* <div className> */}
      <div className={`odd-bg text-[#3A3A3A] transition-all px-12 py-3 inline-grid xl:grid gap-3 grid-cols-[repeat(5,minmax(145px,1fr))] min-w-full w-fit`}>
        {/* <div className="flex items-center cursor-pointer">
            <p className="text-sm">{id}</p>
          </div> */}
        <div className={`before-left-arrow cursor-pointer`}>
          <div className="flex items-center gap-3">
            {employee?.image ? (
              <img
                alt=""
                src={employee?.image}
                className="h-[2.8125rem] w-[2.8125rem] object-cover rounded-full"
              />
            ) : (
              <LabelAvatar iconLabel={item?.employee?.full_name} index={index} className={'!h-[2.8125rem] !w-[2.8125rem] !rounded-full'} />
            )}
            <p className="text-sm">{employee?.full_name}</p>
          </div>
        </div>
        <div className="flex items-center">
          <p className="text-sm">
            {service_target} {currency}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">
            {retail_target} {currency}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">
            {month}
            {item?.year && `, ${item?.year?.slice(0, 4)}`}
          </p>
        </div>
        {showActions ?
          <div className="flex items-center gap-2">
            {!Employee ||
              (Employee &&
                employeePermissions?.target_control_staff?.includes("edit")) ? (
              <div
                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                onClick={() => {
                  handleUpdateStaffId(id);
                }}
              >
                <Svgs.Pen />
              </div>
            ) : (
              ""
            )}

            {!Employee ? (
              <div
                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                onClick={() => {
                  handleDeleteStaffId(id);
                }}
              >
                <Svgs.Delete />
              </div>
            ) : (
              ""
            )}
          </div>
          : "-----"
        }
      </div>
      {/* </div> */}
    </>
  );
};

export default StaffTargetItem;
