import Svgs from 'Assets/svgs'
import React from 'react'
import { useNavigate } from 'react-router'

const AppointmentCard = (props) => {
    const navigate = useNavigate()
    return (
        props?.animate ? <div className="flex items-center justify-between border rounded-xl md:p-3 p-1.5">
            <div className="flex items-center w-3/5 gap-1">
                <div className="rounded-xl !h-[4rem] !w-[6rem] flex justify-center items-center bg-[#F6F6F6]">

                </div>
                <div className='flex w-full flex-col gap-1'>
                    <div className="h-2 w-full rounded-sm bg-gray-100"></div>
                    <div className="h-5 w-full rounded-sm bg-gray-100"></div>
                    <div className="h-2 w-full rounded-sm bg-gray-100"></div>
                </div>
            </div>
            <div className='flex w-1/5 justify-center text-center flex-col text-[#101928] leading-[1.4]'>
                <div className="h-5 w-full rounded-sm bg-gray-100"></div>
                <div className="h-5 w-full rounded-sm bg-gray-100"></div>
            </div>
        </div>
            :
            <div className="flex items-center justify-between border rounded-xl md:p-3 p-1.5 cursor-pointer"
                onClick={() => {
                    navigate(`/dashboard/calendar/appointment-detail/${props?.id}/?parent_id=${props?.appointment_id}`)
                }}
            >
                <div className="flex items-center gap-3">
                    <div className="rounded-xl h-[4rem] w-[4rem] flex justify-center items-center bg-[#F6F6F6] flex-shrink-0">
                        {props.img
                            ?
                            <img src={props?.img} />
                            :
                            <Svgs.NewService />
                        }
                    </div>
                    <div className='flex flex-col gap-1'>
                        <p className=' text-xs font-light'>{props.startEnd}</p>
                        <p className="font-semibold line-clamp-2 overflow-ellipsis">{props.type}</p>
                        <div className='flex items-center gap-1'>
                            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5625 10.3125H6.875V8.59375C6.875 8.13791 6.69392 7.70074 6.37159 7.37841C6.04926 7.05608 5.61209 6.875 5.15625 6.875H3.09375C2.63791 6.875 2.20074 7.05608 1.87841 7.37841C1.55608 7.70074 1.375 8.13791 1.375 8.59375V10.3125H0.6875V8.59375C0.6875 7.95557 0.941015 7.34353 1.39227 6.89227C1.84353 6.44102 2.45557 6.1875 3.09375 6.1875H5.15625C5.79443 6.1875 6.40647 6.44102 6.85773 6.89227C7.30898 7.34353 7.5625 7.95557 7.5625 8.59375V10.3125ZM4.125 1.375C4.46494 1.375 4.79724 1.4758 5.07989 1.66466C5.36253 1.85352 5.58283 2.12195 5.71292 2.43601C5.84301 2.75007 5.87704 3.09566 5.81072 3.42906C5.74441 3.76247 5.58071 4.06872 5.34034 4.30909C5.09997 4.54946 4.79372 4.71316 4.46031 4.77947C4.12691 4.84579 3.78132 4.81176 3.46726 4.68167C3.1532 4.55158 2.88477 4.33128 2.69591 4.04864C2.50705 3.76599 2.40625 3.43369 2.40625 3.09375C2.40625 2.63791 2.58733 2.20074 2.90966 1.87841C3.23199 1.55608 3.66916 1.375 4.125 1.375ZM4.125 0.6875C3.64909 0.6875 3.18387 0.828624 2.78816 1.09303C2.39245 1.35743 2.08404 1.73323 1.90191 2.17292C1.71979 2.6126 1.67214 3.09642 1.76499 3.56319C1.85783 4.02995 2.087 4.45871 2.42352 4.79523C2.76004 5.13175 3.1888 5.36092 3.65556 5.45376C4.12233 5.54661 4.60615 5.49896 5.04583 5.31683C5.48552 5.13471 5.86132 4.8263 6.12572 4.43059C6.39013 4.03488 6.53125 3.56966 6.53125 3.09375C6.53125 2.45557 6.27773 1.84353 5.82648 1.39227C5.37522 0.941015 4.76318 0.6875 4.125 0.6875Z" fill="#101928" />
                            </svg>
                            <p className=' text-xs font-light'>{props.name}</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center-center text-center flex-col text-[#101928] leading-[1.4]'>
                    <h1 className='font-semibold text-[2rem]'>{props.date}</h1>
                    <p className='text-xs'>{props.month}</p>
                </div>
            </div>
    )
}

export default AppointmentCard