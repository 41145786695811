import React, { memo } from "react";
import ChooseLocationModal from "./choose-location-modal";
import { useLocation } from "./helper";
import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";

const LocationSelection = ({ onlyShow, conditionalOnClick }) => {
  const state = useSelector(s => s)
  const { locationPopup, locationName, loading, locationList, setLocationPopup } = useLocation();

  return (
    <>
      {loading
        ? <>
          <div className="py-4 w-32 rounded-lg bg-gray-200 animate-pulse"></div>
        </>
        :
        onlyShow ?
          <div className="flex items-center justify-center border border-black px-5 py-2 rounded-[5px] cursor-none bg-white">
            <Svgs.LocationPin />
            <h1 className="text-primary text-sm font-semibold">{locationName}</h1>
          </div>
          :
          <div
            className={`flex text-center items-center gap-1 border border-lightGray px-[20px] py-1.5 rounded-[5px] bg-white ${locationName ? "cursor-pointer" : "cursor-default"}`}
            onClick={() => {
              if (locationName) {
                setLocationPopup(true);
              }
            }}
          >
            {(!state.user.user.employee || (state.user.user.employee && state.user?.permissions?.system_root_access?.includes('view'))) && (
              <>
                <Svgs.LocationPin />
                <h1 className="text-primary-black text-sm">{locationName}</h1>

                {/* Vertical Line */}
                <div className="border-l border-lightGray h-5 mx-2"></div>

                <span><Svgs.EditIcon /></span>
              </>
            )}
          </div>

      }

      {locationPopup && (
        <ChooseLocationModal
          setLocationPopup={setLocationPopup}
          locationList={locationList}
          conditionalOnClick={conditionalOnClick}
        />
      )}
    </>
  );
};

export default memo(LocationSelection);
