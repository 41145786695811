import Svgs from 'Assets/svgs'
import FAQ from 'Components/Utility/Faqs'
import Footer from 'Components/Utility/Footer'
import HeroSliders from 'Components/Utility/HeroSliders'
import Navbar from 'Components/Utility/Navbar'
import PricingFeatures from 'Components/Utility/PaidFeatures'
import SelectBusinessType from 'Components/Utility/SelectBusinessType'
import SmartFeatures from 'Components/Utility/SmartFeatures'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const LandingPage = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="flex flex-col w-full landing_page">
        <Navbar />
        <div className="flex bg-secondary-white relative flex-col">
          <div className="absolute flex items-center justify-center h-20 md:h-40 lg:h-[17rem] top-0 right-0">
            <img src="/images/hero-top.png" alt="hero-top" className='w-full h-full' />
          </div>

          <div className="grid z-10 items-center sm:grid-cols-12 pt-7 sm:pt-[4.25rem] pb-40 sm:pb-28 gap-10 w-[95%] max-w-[1200px] mx-auto">
            <div className="sm:col-span-7 flex flex-col gap-8">
              <div className="flex flex-col gap-3.5">
                <h1 className='font-bold leading-[4.375rem] text-[3.375rem] font-manrope'>
                  The Best <span className='text-primary-green'>Free Solution</span> for Beauty & Wellness Pros
                </h1>
                <p className='text-base'>Join the leading platform with no subscriptions or hidden fees—experience the future of beauty and wellness today!</p>
              </div>
              <button
                onClick={() => navigate('/auth/signup/?account_type=business')}
                className='rounded-full w-fit bg_gradient-blue-tb py-[1.44rem] text-white text-xl font-semibold text-center px-[4.5rem]'
              >
                Signup Now
              </button>
            </div>
            <div className="sm:col-span-5">
              <div className="flex flex-col bg_gradient-blue-tb rounded-[1.875rem] px-8 py-7">
                <div className="flex w-full">
                  <img src="/images/hero.png" alt="hero-bg" className='w-full' />
                </div>
                <div className="grid grid-cols-2 items-center h-[40px] mb-5">
                  <div className="flex items-center gap-2.5">
                    <Svgs.VideoIcon />
                    <p className='text-xl font-medium text-white'>Your Beauty, <br /> Our Passion</p>
                  </div>
                  <div className='flex overflow-hidden justify-end h-full'>
                    <Svgs.LookIcon />
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div className="absolute left-0 bottom-0 h-32 flex items-center justify-center">
            <img src="/images/hero-bottom.png" alt="hero-bottom" className='w-full h-full' />
          </div>
        </div>
        <HeroSliders />
        <SmartFeatures />
        <PricingFeatures />
        <SelectBusinessType />
        <FAQ />
        <Footer />
      </div>
    </>
  )
}

export default LandingPage