import React, { useEffect, useState } from 'react'

import Svgs from 'Assets/svgs';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';


let modules = {
  'APPOINTMENT': 'service',
  'SERVICE': 'service',
  'PRODUCT': 'retail',
}


export const DirectFlatCard = ({ selected, data, onRemoveOffer, onSelect }) => {
  const state = useSelector(state => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  return (
    <>
      <div
        onClick={() => {
          onSelect && onSelect(data)
        }}
        className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"} border rounded-md p-4 mt-1 cursor-pointer`}>
        <div className="flex justify-between items-center mb-2">
          <div className="text-[#A1A1A1] font-bold">
            {selectedLanguage?.name == "English" ? "Direct or Flat" : translation[878]?.value ? translation[878]?.value : "Direct or Flat"}
          </div>
          {onRemoveOffer && selected &&
            <button
              className="h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
              onClick={() => onRemoveOffer()}
            >
              <Svgs.Delete fill="#767A87" />
            </button>
          }
        </div>
        <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>
        <div className="flex items-center gap-6">

          <div className="flex flex-col gap-2 flex-1 ">
            {data?.category_discount?.map(({ category_type, discount }, index) => {
              return (
                <div key={index} className="flex items-center justify-between gap-2">
                  <div>
                    <p className="text-[#A1A1A1] text-sm">
                      {selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
                    </p>
                    <p className="text-[#101928] font-semibold">
                      {category_type}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#A1A1A1] text-sm">
                      {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}

                    </p>
                    <p className="text-[#101928] font-semibold">{discount} %</p>
                  </div>
                </div>
              );
            })}

            {/* Date duration */}
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-2">
                <p className="text-[#A1A1A1] text-sm">
                  {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                </p>
                <p className="text-[#101928] font-semibold">
                  {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-[#A1A1A1] text-sm">
                  {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                </p>
                <p className="text-[#101928] font-semibold">
                  {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DirectFlat = ({ MODULE_TYPE, setHavDirectOrFlat, onSelect, discount_data, isDelete, selected, cartData, setCartData,
  appointmentServices, setAppointmentService }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)

  const [discountData, setDiscountData] = useState([])

  const handle_select = (data) => {
    if (MODULE_TYPE === 'quickSales') {
      handleSelectMultipleItems(data, ["SERVICE", "PRODUCT"]);
    } else {
      let funcs = {
        'APPOINTMENT': () => handle_select_appointment(data),
      };

      funcs[MODULE_TYPE] && funcs[MODULE_TYPE]();
    }
  };
  const handle_select_appointment = (data) => {
    let discountObj = data?.category_discount?.find(itm => (itm.category_type == "Service" || itm.category_type == "All"))

    if (!discountObj) {
      toast.error("Product promotion can't be applied on this appointment.")
      return
    }
    const updatedAppointmentServices = appointmentServices?.map(prev => {
      return {
        ...prev,
        is_promotion: true,
        is_promotion_availed: true,
        discount_percentage: discountObj?.discount,
        discount_price: (prev?.price * (100 - discountObj?.discount)) / 100,
        discount_price_minus: prev?.price - (prev?.price * (100 - discountObj?.discount)) / 100
      };
    })
    const total_discount_price = updatedAppointmentServices?.reduce((sum, item) => sum + (item?.discount_price_minus || 0), 0);
    setAppointmentService(updatedAppointmentServices);
    setCartData({
      ...cartData,
      promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
      is_promotion: true,
      is_promotion_availed: true,
      selected_promotion_type: data?.type || null,
      selected_promotion_id: data?.id || null,
    })
    onSelect?.()
  }

  const handleSelectMultipleItems = (data, types) => {
    let total_discount_price
    setCartData(prev => {
      let updatedCart = prev && typeof prev === "object" ? { ...prev } : { ids: [] };
      updatedCart.ids = updatedCart.ids || [];

      types?.forEach(type => {
        const categoryType = type === "SERVICE" ? "Service" : "Retail";
        let discountObj = data?.category_discount?.find(itm => itm?.category_type === categoryType || itm?.category_type === "All");

        const filteredItems = cartData?.ids?.filter(item => item?.selection_type === type) || [];
        let excludedItems = type === "SERVICE" ? data?.excluded_services?.map(itm => itm?.id) || [] : data?.excluded_products?.map(itm => itm?.id) || [];

        const remainingItems = filteredItems?.filter(item => !excludedItems?.includes(item.id));

        if (discountObj?.discount) {
          const discountedItems = remainingItems?.map(itm => ({
            ...itm,
            discount_percentage: discountObj?.discount,
            discount_price: (itm.price * (100 - discountObj?.discount)) / 100,
            discount_price_minus: itm.price - (itm.price * (100 - discountObj?.discount)) / 100
          }));
          updatedCart.ids = [...updatedCart?.ids?.filter(itm => itm?.selection_type !== type), ...discountedItems];
        }
      });
      // Calculate total discount price
      total_discount_price = updatedCart?.ids?.reduce((sum, item) => sum + (item?.discount_price_minus || 0), 0);

      return {
        ...updatedCart,
        selected_promotion_type: data?.type || null,
        selected_promotion_id: data?.id || null,
        is_promotion: true,
        is_promotion_availed: true,
        promotion_discount_sum: total_discount_price
      };
    });

    dispatch({
      type: "UPDATE_CART_DISCOUNTS",
      payload: {
        cart_items: state?.cartItem?.cart_items?.map(item => {
          if (types.includes(item?.selection_type)) {
            const categoryType = item?.selection_type === "SERVICE" ? "Service" : "Retail";
            let discountObj = data?.category_discount?.find(itm => itm?.category_type === categoryType || itm?.category_type === "All");
            if (discountObj?.discount) {
              return {
                ...item,
                discount_percentage: discountObj?.discount || 0,
                discount_price: discountObj?.discount ? (item?.price * (100 - discountObj?.discount)) / 100 : item?.price,
                is_promotion: true
              };
            } else {
              return item;
            }
          }
          return item;
        }),
        is_promotion: true,
        selected_promotion_type: data?.type || null,
        selected_promotion_id: data?.id || null,
        is_promotion_availed: true,
        promotion_discount_sum: parseFloat(total_discount_price.toFixed(2)),
      }
    });

    onSelect?.()
  };

  useEffect(() => {
    let category = modules[MODULE_TYPE]?.toLowerCase();

    // If MODULE_TYPE is quickSales, include both 'service' and 'retail'
    let validCategories = MODULE_TYPE === 'quickSales' ? ['service', 'retail'] : [category];

    let new_data = [...discount_data]?.filter(itm_disc => {
      let filtered_data = itm_disc?.category_discount?.filter(itm =>
        validCategories?.includes(itm?.category_type?.toLowerCase()) || itm?.category_type === 'All'
      );
      return filtered_data?.length > 0;
    });

    if (new_data?.length > 0) {
      setDiscountData(new_data);
    } else {
      setHavDirectOrFlat(false);
    }
  }, []);

  return (
    <>
      {
        discountData?.map((dicount, index) => {
          return (
            <DirectFlatCard
              key={index}
              selected={selected}
              data={dicount}
              isDelete={isDelete}
              onSelect={handle_select}
            />
          )
        })
      }
    </>
  );
};


DirectFlat.defaultProps = {
  discount_data: []
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(DirectFlat);