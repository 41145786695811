import axios from "axios";

import { addLocations } from "Redux/TenantRedux/Actions/locationActions";
import { BUSINESS_SETTING } from "Redux/TenantRedux/ActionTypes/businessSettingTypes";
import { tenantDetail } from "Hooks/useTenant";
import { LOCATION } from "Redux/TenantRedux/ActionTypes/locationTypes";
import { UTITLY_LOCATION } from "../../Redux/Utility/ActionTypes/LocationTypes";
import { apiErrorMessage } from "Constants/Data/Errors";
import { BaseUrlWithoutV1, business_categories, get_all_appointments_url, get_language_translation_url, help_center_detail_url, help_center_url } from "./api_variables";

const { is_tenant, tenant } = tenantDetail();

// /server
const tenant_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${tenant}.${process.env.REACT_APP_SERVER_BASEURL_END}`;
const tenant_base_url_without_tenant = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;
const nstyle_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;

// const tenant_base_url = `http://${tenant}.192.168.18.230:8000/api/v1`;
// const nstyle_base_url = "http://192.168.18.230:8000/api/v1";

let BASE_URL = is_tenant ? tenant_base_url : nstyle_base_url;

//create user
export const createUserAccount = async (form_data) => {
  try {
    const result = await axios.post(
      `${BASE_URL}/auth/create_tenant_business_user/`,
      form_data
    );
    return result;
  } catch (error) {
    return error;
  }
};

// user login
export const userLogin = async (login_credentials, success, fail) => {
  const login_url = is_tenant ? "tenant_login" : "nstyle_login";
  try {
    const result = await axios.post(
      `${BASE_URL}/auth/${login_url}/`,
      login_credentials
    );
    success && success(result);
    return result;
  } catch (error) {
    fail && fail(error);
    return error.response;
  }
};

// reset or change password
export const resetPassword = async (new_password) => {
  try {
    const result = await axios.put(
      `${BASE_URL}/auth/nstyle_change_password/`,
      new_password
    );
    return result;
  } catch (error) {
    return error;
  }
};

//get all country
export const getAllCountry = async () => {
  try {
    const result = await axios.get(`${BASE_URL}/utility/get_countries/`);
    return result;
  } catch (error) {
    return error;
  }
};

// get states on the basis of country id
export const getAllStateOfSelectedCountry = async (country_id) => {
  try {
    const result = await axios.get(
      `${BASE_URL}/utility/get_states/?country_unique_id=${country_id}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

// get city on the basis of state id
export const getAllCityOfSelectedState = async (state_id) => {
  try {
    const result = await axios.get(
      `${BASE_URL}/utility/get_cities/?state=${state_id}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

// verified your mobile number or email
export const verificationOfPhoneNumberOrEmail = async (verification_data) => {
  try {
    const result = await axios.post(
      `${BASE_URL}/auth/verify_otp/`,
      verification_data
    );
    return result;
  } catch (error) {
    return error;
  }
};

// send or resend verification code to your mobile or email
export const resendVerificationCodeToPhoneNumberOrEmail = async (
  resend_verification_data
) => {
  try {
    const result = await axios.post(
      `${BASE_URL}/auth/send_verification_otp/`,
      resend_verification_data
    );
    return result;
  } catch (error) {
    return error;
  }
};

//get business types
export const getBusinessTypes = (data, success, fail) => (dispatch) => {
  fetch(`${BASE_URL}/business/get_business_types/`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      let data = result.response.data;
      success && success(data);
      dispatch({
        type: BUSINESS_SETTING.GET_BUSINESS_TYPES,
        payload: data,
      });
    });
};

// get all softwares
export const getSoftwares = async () => {
  try {
    const result = await axios.get(`${BASE_URL}/utility/get_softwares/`);
    return result;
  } catch (error) {
    return error;
  }
};

// create business account
export const createBusinessAccount = async (business_information) => {
  let form_data = {
    user: "7ccb835c-0a97-477f-9bf1-9ad2c15c42c8",
    how_find_us: business_information.findUs,
    team_size: business_information.teamSize,
    opening_hours: business_information.OpeningHours,
    business_types: business_information.businessType,
    software_used: business_information.softwareUsing,
    ...business_information.businessDetails,
  };

  try {
    const result = await axios.post(
      `${BASE_URL}/business/create_user_business/`,
      form_data
    );
    return result;
  } catch (error) {
    return error;
  }
};

// get business by domain name
export const getBusinessByDomain = async (domain_name) => {
  let token = localStorage.getItem("access_token")
  if (token) {
    token = token?.replaceAll('"', "");
  }
  try {
    const result = await axios.get(
      `${tenant_base_url_without_tenant}/business/get_business_by_domain/?domain=${domain_name}&auth_token=${token}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

// get business by user id
export const getBusinessById = async (user_id, isEmployee, access_token) => {
  let token = localStorage.getItem("access_token")
  if (token) {
    token = token?.replaceAll('"', "");
  }
  token = access_token ? access_token : token
  try {
    const result = await axios.get(
      `${BASE_URL}/business/get_business/?user=${user_id}&employee=${isEmployee}`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

// create business locations
export const createBusinessLocation = async (location_data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}/business/add_business_location/`,
      location_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

// get location
export const getLocations = (business_id, success, fail) => async (dispatch) => {
  try {
    const result = await axios.get(
      `${BASE_URL}/business/${business_id}/get_business_locations/`
    );
    if (result?.status === 200) {
      let locations = result?.data?.response?.locations;
      //THIS IS FOR TENANT REDUCER
      dispatch({
        type: LOCATION.ADD_LOCATION,
        payload: locations,
      });

      // THIS ONE FOR UTILITY REDUCER
      dispatch({
        type: UTITLY_LOCATION.GET_LOCATION,
        payload: locations,
      });
      success && success(locations)
    } else {
      fail && fail()
    }
    return result;
  } catch (error) {
    fail && fail()
    return error;
  }
};

export const getMarketPlaceLocations = (business_id, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  try {
    const result = await axios.get(
      `${BASE_URL}/business/${business_id}/get_business_locations/`
    );
    if (result?.status === 200) {
      let locations = result?.data?.response?.locations;
      //THIS IS FOR TENANT REDUCER
      dispatch({
        type: LOCATION.ADD_LOCATION,
        payload: locations,
      });

      // THIS ONE FOR UTILITY REDUCER
      dispatch({
        type: UTITLY_LOCATION.GET_LOCATION,
        payload: locations,
      });
      setLoader && setLoader(false)
    }
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

//delete business locations
export const deleteBusinessLocation = async (location_id, access_token) => {
  try {
    const result = await axios.delete(
      `${BASE_URL}/business/delete_location/?location=${location_id}`,
      { headers: { Authorization: `Token ${access_token}` } }
    );
    return result;
  } catch (error) {
    return error;
  }
};

// update locations
export const updateLocations = async (update_location, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}/business/update_location/`,
      update_location,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

// update buisness details
export const updateBusiness = async (updated_business_data, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}/business/update_business/`,
      updated_business_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

// get all laguages
export const getAllLanguages = async (business_languages) => {
  const specific_language = business_languages ? `?business_languages=true` : ''
  try {
    const result = await axios.get(`${BASE_URL}/business/get_all_languages/${specific_language}`);
    return result;
  } catch (error) {
    return error;
  }
};

// get all business laguages
export const getAllBusinessLanguages = async (business_id) => {
  try {
    const result = await axios.get(
      `${BASE_URL}/business/get_business_languages/?business=${business_id}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

// update buisness details
export const addLanguage = async (language_data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}/business/add_business_language/`,
      language_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

// get login user data
export const getUser = async (user_id, isEmployee) => {
  try {
    const result = await axios.get(
      `${BASE_URL}/auth/get_user/?user=${user_id}&employee=${isEmployee}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

// get business
export const getBusiness = async (user_id) => {
  try {
    const result = await axios.get(
      `${BASE_URL}/business/get_business/?user=${user_id}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

//get tanent data

export const getTanentData = (data, success, fail) => {
  let s_code;
  let form_data = new FormData();
  form_data.append("email", data.email);
  fetch(`${BASE_URL}/auth/get_tenant_detail/`, {
    body: form_data,
    method: "POST",
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result?.response?.data);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail(apiErrorMessage);
    });
};


export const get_help_center = async (data, success, fail) => {
  try {
    const search = data?.search ? `?search=${data?.search}` : ''
    const response = await axios.get(
      // `${help_center_url}${search}`
      `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}${help_center_url}${search}`
    );

    if (response?.data?.status_code == 200) {
      const data = response?.data?.response?.response
      success && success(data)
    } else {
      fail && fail()
    }
  } catch (error) {
    fail && fail()
  }
}
export const get_help_center_details = async (data, success, fail) => {
  try {
    if (data?.id) {
      const response = await axios.get(
        // `${help_center_detail_url}?id=${data?.id}`
        `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}${help_center_detail_url}?id=${data?.id}`
      );

      if (response?.data?.success) {
        const data = response?.data?.response?.data
        success && success(data)
      } else {
        fail && fail()
      }
    }
  } catch (error) {
    fail && fail()
  }
}

export const get_language_translation = async (language) => {
  try {
    const result = await axios.get(
      // `${get_language_translation_url}?language=${language}`
      `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}${get_language_translation_url}?language=${language}`
    );
    return result;
  } catch (error) {
    return error;
  }
};


export const getBusinessCategories = async (success, fail) => {
  try {
    const result = await axios.get(`${BaseUrlWithoutV1}${business_categories}`);
    success?.(result?.data?.data || [])
    return result;
  } catch (error) {
    fail?.()
    return error;
  }
};