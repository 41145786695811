import Svgs from 'Assets/svgs';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Footer from 'Components/Utility/Footer';
import Navbar from 'Components/Utility/Navbar';
// import Textarea from 'new-components/text-area';
import React from 'react';
import useContactUsHelper from './helper';
import Textarea from 'Components/TenantApp/Elements/Textarea';

const ContactUs = () => {
  const { submitLoading, onChangeHandler, onSubmitHandler, errors, formData } = useContactUsHelper()
  return (
    <>
      <div className="flex flex-col w-full font-manrope landing_page">
        <Navbar />
        <div className="flex relative flex-col">
          <div className="flex bg-secondary-white relative pt-11 pb-24 flex-col">
            <div className="flex gap-5 max-w-[1200px] mx-auto w-full">
              <div className="flex relative items-center w-full justify-center gap-4 flex-col">
                <h1 className="text-6xl font-bold text-center">Contact Us</h1>
                <p className="text-lg font-semibold">We’d  love to talk to you</p>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-3 gap-4 -mt-10 mb-[4.5rem] z-10 max-w-[1200px] mx-auto w-[95%]">
            <div className="flex gap-2.5 bg-white shadow-xl py-5 md:py-10 px-4 md:px-8 hover:text-white hover:bg-primary-blue rounded-[20px]  items-center">
              <div className="flex"><Svgs.ContactIcon /></div>
              <div className="flex flex-col gap-1.5">
                <h1 className='text-sm font-medium'>CALL US</h1>
                <p className='text-xl font-semibold '>+ 123455678890</p>
              </div>
            </div>
            <div className="flex gap-2.5 bg-white shadow-xl py-5 md:py-10 px-4 md:px-8 hover:text-white hover:bg-primary-blue rounded-[20px]  items-center">
              <div className="flex"><Svgs.MailIcon /></div>
              <div className="flex flex-col gap-1.5">
                <h1 className='text-sm font-medium'>EMAIL</h1>
                <p className='text-xl font-semibold '>hello@nstyle.com</p>
              </div>
            </div>
            <div className="flex gap-2.5 bg-white shadow-xl py-5 md:py-10 px-4 md:px-8 hover:text-white hover:bg-primary-blue rounded-[20px]  items-center">
              <div className="flex"><Svgs.AddressIcon /></div>
              <div className="flex flex-col gap-1.5">
                <h1 className='text-sm font-medium'>ADDRESS</h1>
                <p className='text-xl font-semibold '>Near XYZ Mall, Opposite ABC Café</p>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-3 gap-4 mb-[4.5rem] z-10 max-w-[1200px] mx-auto w-[95%]">
            <div className="flex flex-col gap-5">
              <h1 className='text-3xl font-bold'>Need an Appointment? Have a Question? We’re Here to Help!</h1>
              <p className='text-lg font-medium'>Get in touch with us for bookings, inquiries, or expert beauty advice.</p>
            </div>
            <div className="col-span-2 flex flex-col w-full gap-5">
              <div className="grid grid-cols-2 gap-5">
                <LoginInput
                  name={'full_name'}
                  charactersMaxLength={50}
                  onChange={onChangeHandler}
                  error={errors?.full_name}
                  value={formData?.full_name}
                  title={"Full Name"}
                  placeholder={"Enter full name"}
                />
                <LoginInput
                  name={'email'}
                  charactersMaxLength={50}
                  onChange={onChangeHandler}
                  error={errors?.email}
                  value={formData?.email}
                  title={"Email"}
                  placeholder={"Enter email address"}
                />
                <LoginInput
                  name={'position'}
                  charactersMaxLength={50}
                  onChange={onChangeHandler}
                  error={errors?.position}
                  value={formData?.position}
                  title={"Position"}
                  placeholder={"Enter Position"}
                />
                <LoginInput
                  name={'subject'}
                  charactersMaxLength={50}
                  onChange={onChangeHandler}
                  error={errors?.subject}
                  value={formData?.subject}
                  title={"Subject"}
                  placeholder={"Enter Subject"}
                />
                <div className="col-span-2">
                  <Textarea
                    errorClass={'flex-row-reverse justify-start'}
                    name={'message'}
                    required={true}
                    onChange={onChangeHandler}
                    error={errors?.message}
                    value={formData?.message}
                    limit={500}
                    title="Message"
                    infoFill={'#eb3b3b'}
                    placeholder={"Enter Message"}
                    rows={5}
                  />
                </div>
              </div>
              <LoginBtn
                onClick={onSubmitHandler}
                loading={submitLoading}
                customThemeBtn='bg_gradient-blue-tr flex min-h-14 justify-center items-center w-full rounded-full p-4 text-white text-xl font-semibold'
                text={'Submit'}
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ContactUs;