import React from 'react'
import { CSVLink } from "react-csv";
import useUpsellDownsell from '../useUpsellDownsell';
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer';
import moment from 'moment';
import IconButton from 'Components/TenantApp/Elements/IconButton';
import Svgs from 'Assets/svgs';

import TableShimer from "Components/Utility/Shimmers/TableShimer";
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import NoPayrollFound from '../../Elements/NoPayrollFound';
import Pagination from 'new-components/Pagination';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';

const StoreUpsellDownSell = () => {
    const {
        loader,
        up_down_sell_store,
        // store_pages,
        loadingPermission,
        month, setMonth,
        translation,
        selectedLanguage,
        setToggleDateRange, toggleDateRange,
        startDate, endDate,
        setStartDate, setEndDate, employee, payrollData,
        exportLoader, handleExportClick, reduxlocationsList,
        selectedLocation, setSelectedLocation
    } = useUpsellDownsell()
    return (
        <>
            <div className="">

                <div className="border rounded-lg bg-white">
                    <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b sm:flex-row flex-col gap-3">
                        <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
                            <div className="flex items-center sm:gap-4 gap-2 ">
                                <h2 className="font-semibold text-xl">
                                    {selectedLanguage?.name == "English" ? "Store Upsell and Downsell Details" : translation[1783]?.value ? translation[1783]?.value : "Store Upsell and Downsell Details"}
                                </h2>
                                {loader ?
                                    <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                        <p className="rounded-md h-5 w-12 mx-1"> </p>
                                    </div>
                                    :
                                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                        <p className="text-primary">
                                            {up_down_sell_store?.length ? up_down_sell_store?.length : 0}{" "}
                                            {up_down_sell_store?.length && up_down_sell_store?.length > 1 ?
                                                selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                                : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                            }
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        {loadingPermission ?
                            <EmployeesHeadShimmer parentClass={'sm:w-fit w-full'} classOn1={'!w-full lg:!w-44 sm:!w-40'} classOn2={'!w-full lg:!w-44 sm:!w-40'} classOn3={'!w-full lg:!w-44 sm:!w-40'} classOn4={'!w-12 lg:!16'} has4Itm />
                            :
                            <div className="flex items-center sm:justify-center justify-end gap-3 sm:w-fit w-full flex-col xs:flex-row flex-nowrap">
                                <div className="grid grid-cols-2 gap-3 items-center sm:flex w-full">
                                    <Dropwdown
                                        className={''}
                                        showTitle={false}
                                        name={"selectedLocation"}
                                        value={selectedLocation}
                                        onChange={(e) => { setSelectedLocation(e.target.value) }}
                                        options={[
                                            { label: selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations", value: "" },
                                            ...reduxlocationsList?.map((adrs) => {
                                                return {
                                                    label: adrs?.address_name,
                                                    value: adrs?.id,
                                                };
                                            })]}
                                        title={''}
                                        placeholder={selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations"}
                                    />
                                    <Dropwdown
                                        className={''}
                                        name="month"
                                        title=""
                                        showTitle={false}
                                        placeholder={'Select Month'}
                                        options={[{ value: '', label: 'Select Month' },
                                        { value: '1', label: 'January' },
                                        { value: '2', label: 'February' },
                                        { value: '3', label: 'March' },
                                        { value: '4', label: 'April' },
                                        { value: '5', label: 'May' },
                                        { value: '6', label: 'June' },
                                        { value: '7', label: 'July' },
                                        { value: '8', label: 'August' },
                                        { value: '9', label: 'September' },
                                        { value: '10', label: 'October' },
                                        { value: '11', label: 'November' },
                                        { value: '12', label: 'December' }
                                        ]}
                                        // onBlur={() => onBlurHandler({ country_name: country_name.value })}
                                        value={month}
                                        onChange={(e) => { setMonth(e.target.value) }}
                                    // error={errors.country_name}
                                    />

                                </div>

                                {exportLoader ? (
                                    <div className="h-[4rem] flex items-center justify-center">
                                        <PreloaderSm />
                                    </div>
                                ) :
                                    <div className="flex items-center sm:gap-4 gap-2 xs:w-fit w-full">
                                        <div className='border border-[#A1A1A1] w-full text-sm rounded-md font-semibold flex items-center select-none'>
                                            <div className='p-2 relative gap-4 flex justify-between items-center w-full'>
                                                <p onClick={() => {
                                                    setToggleDateRange(!toggleDateRange)
                                                }} className='cursor-pointer whitespace-nowrap'>
                                                    {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/---"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/---"}`}
                                                </p>
                                                <span className='cursor-pointer' onClick={() => {
                                                    setStartDate()
                                                    setEndDate()
                                                }}>Clear</span>

                                                {
                                                    toggleDateRange && <>
                                                        <div className='fixed inset-0 z-1 w-full' onClick={() => { setToggleDateRange(false) }}></div>
                                                        <div className='absolute top-full -right-16 xs:right-0 z-[2]'>
                                                            <DateRangePicker
                                                                start={startDate}
                                                                end={endDate}
                                                                setStartDate={setStartDate}
                                                                setEndDate={setEndDate}
                                                                setToggleDateRange={setToggleDateRange}

                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <CSVLink
                                            {...payrollData}
                                            target="_blank"
                                            onClick={handleExportClick}
                                        >
                                            <LoginBtn
                                                className="rounded-lg !py-[7px]"
                                                bg="bg-white"
                                                animation={false}
                                                customThemeBtn={'theme-btn2'}
                                            >
                                                <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                    <Svgs.ExportSvg />
                                                </div>
                                            </LoginBtn>
                                        </CSVLink>
                                    </div>}

                            </div>
                        }
                    </div>
                    {loader ?
                        <TableShimer cols={5} />
                        :
                        <div className="overflow-x-auto">
                            <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(165px,1fr))] px-12 py-3 bg-[#EFF2F9] text-sm font-semibold min-w-full w-fit">
                                <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
                                <p>Upsell</p>
                                <p>Downsell</p>
                            </div>
                            <div className={`odd-bg text-[#3A3A3A] transition-all`}>
                                {up_down_sell_store?.length > 0
                                    ? up_down_sell_store?.map((item, i) => {
                                        return (
                                            <>
                                                <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(165px,1fr))] min-w-full w-fit even:bg-[#FBFCFF]">
                                                    <div className="h-full flex items-center">
                                                        <p className="text-sm">{item?.location_name}</p>
                                                    </div>
                                                    <div className="h-full flex items-center">
                                                        <p className="text-sm">{item?.up_sell}</p>
                                                    </div>


                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-1 pr-3">
                                                            {item?.old_price?.toFixed(2)} {item?.down_sell}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    : <NoPayrollFound />
                                }
                            </div>
                        </div>
                    }
                    {/* Pagination */}
                    {/* {(!loader && up_down_sell_store?.length > 0) &&
                        <Pagination
                            currentPage={currentPage}
                            totalCount={Math.ceil(store_pages)}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    } */}
                </div>

            </div>
        </>
    )
}

export default StoreUpsellDownSell