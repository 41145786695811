import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";


const useMembershipSoldHelper = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const { storeDataInLocalStorage } = useSetDataInLocalStorage();

    // redux states
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return {
        translation, selectedLanguage, storeDataInLocalStorage
    }
}

export default useMembershipSoldHelper