import Svgs from 'Assets/svgs'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Popup from 'Components/TenantApp/Elements/Popup'
import NoReportDataFound from 'Components/Utility/NoReportDataFound'
import { complaintStatus, statusClasses } from 'Constants/Data/Months'
import moment from 'moment'
import React from 'react'

const ComplaintDetailPopup = ({ open, close, complaintDetail, setComplaintDetail, updateComplaintStatus, updateComplaintLoading, complaintDetailLoading, viewLogs, setViewLogs }) => {
    return (
        <>
            <Popup
                open={open}
                close={close}
                heading={"Complaint Detail"}
                customSizeStyle={"lg:w-[55vw]"}
            >
                {complaintDetailLoading
                    ? <div className="grid grid-cols-2 gap-4 bg-secondary-white p-4 rounded-lg  animate-pulse">
                        <div className="h-16 w-full bg-gray-300 rounded-lg" />
                        <div className="h-16 w-full bg-gray-300 rounded-lg" />
                        <div className="h-16 w-full bg-gray-300 rounded-lg" />
                        <div className="h-16 w-full bg-gray-300 rounded-lg" />
                        <div className="h-16 w-full bg-gray-300 rounded-lg" />
                        <div className="h-16 w-full bg-gray-300 rounded-lg" />
                        <div className="h-20 col-span-2 w-full bg-gray-300 rounded-lg" />
                        <div className="h-10 col-span-2 w-full bg-gray-300 rounded-lg" />

                    </div>
                    :
                    <div className="flex flex-col gap-4">
                        <div className="grid grid-cols-2 gap-4 bg-secondary-white p-4 rounded-lg">
                            <div className="flex flex-col gap-2">
                                <p className="text-lg font-semibold">Customer Name</p>
                                <p className="text-base">{complaintDetail?.customer_name || "-----"}</p>
                            </div>

                            <div className="flex flex-col gap-2">
                                <p className="text-lg font-semibold">Complaint Category</p>
                                <p className="text-base">{complaintDetail?.category_name || "-----"}</p>
                            </div>

                            <div className="flex flex-col gap-2">
                                <p className="text-lg font-semibold">Employee</p>
                                <p className="text-base">{complaintDetail?.assigned_employee_name || "-----"}</p>
                            </div>

                            <div className="flex flex-col gap-2">
                                <p className="text-lg font-semibold">Invoice</p>
                                <p className="text-base">{complaintDetail?.invoice_id || "-----"}</p>
                            </div>

                            <div className="flex flex-col gap-2">
                                <p className="text-lg font-semibold">Service</p>
                                <p className="text-base">{complaintDetail?.service_name || "-----"}</p>
                            </div>


                            <div className="flex flex-col gap-2">
                                <p className="text-lg font-semibold">Requested By</p>
                                <p className="text-base">{complaintDetail?.requested_by_name || "-----"}</p>
                            </div>

                            <div className="flex flex-col gap-2 col-span-2">
                                <p className="text-lg font-semibold">Description</p>
                                <p className="text-base bg-secondary-lightBlue p-2 rounded-lg">{complaintDetail?.description || "-----"}</p>
                            </div>

                            <div className="flex col-span-2 gap-4">
                                <Dropwdown
                                    title={""}
                                    value={complaintDetail?.status}
                                    options={complaintStatus}
                                    placeholder={"Select Status"}
                                    onChange={(e) => {
                                        setComplaintDetail({ ...complaintDetail, status: e.target.value })
                                    }}
                                />
                                <LoginBtn
                                    text={"Update Status"}
                                    title={"Update Status"}
                                    onClick={() => { updateComplaintStatus && updateComplaintStatus() }}
                                    loading={updateComplaintLoading && updateComplaintLoading !== 'comment'}
                                />
                            </div>
                            <div className="flex justify-start">
                                <LoginBtn onClick={() => { setViewLogs(!viewLogs) }}>
                                    {viewLogs ? "Hide" : "View"} Logs
                                </LoginBtn>
                            </div>

                            {viewLogs &&
                                <div className="overflow-x-auto col-span-2 bg-white border rounded-lg overflow-hidden">
                                    <h1 className='normal-case font-semibold text-xl px-4 py-3'>Complaint Logs</h1>
                                    <div className={`inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] px-4 py-3 bg-[#EFF2F9] text-xs text-primary-text uppercase font-semibold min-w-full w-fit`}>
                                        <p className=''>Updated By</p>
                                        <p className='col-span-2'>Comment</p>
                                        <p className=''>Old Status</p>
                                        <p className=''>Updated Status</p>
                                        <p className=''>Date</p>
                                    </div>
                                    {complaintDetail?.status_logs?.length > 0 ? (
                                        complaintDetail?.status_logs?.map((item, index) => {
                                            return (
                                                <div key={index} className={`text-xs items-center  px-4 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))]  min-w-full w-fit`}>
                                                    <p className='pr-2'>
                                                        {item?.updated_by?.username || "------"}
                                                    </p>
                                                    <p className='pr-2 col-span-2'>
                                                        {item?.comment || "------"}
                                                    </p>
                                                    <p className={`mr-2 px-2 py-1 rounded text-sm font-bold ${statusClasses[item?.old_status] || 'bg-gray-100 text-gray-800'}`}>
                                                        {item?.old_status || '----'}
                                                    </p>
                                                    <p className={`mr-2 px-2 py-1 rounded text-sm font-bold ${statusClasses[item?.new_status] || 'bg-gray-100 text-gray-800'}`}>
                                                        {item?.new_status || '----'}
                                                    </p>
                                                    <p className='pr-2'>
                                                        {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY') : "------"}
                                                    </p>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <NoReportDataFound />
                                    )}
                                </div>
                            }
                        </div>

                        <div className="flex border  items-center justify-between gap-2 bg-secondary-white col-span-2 p-2 rounded-md">
                            {complaintDetail?.status == 'resolved'
                                ? ""
                                : <textarea
                                    placeholder={'Type a Message here...'}
                                    rows={1}
                                    limit={500}
                                    className={'bg-transparent !px-2 resize-none !border-none !outline-none flex-1'}
                                    value={complaintDetail?.comment}
                                    onChange={(e) => {
                                        setComplaintDetail({ ...complaintDetail, comment: e?.target?.value })
                                    }}
                                    counter={false}
                                />
                            }

                            {complaintDetail?.status == 'resolved'
                                ? <LoginBtn
                                    loading={updateComplaintLoading}
                                    onClick={() => { updateComplaintStatus && updateComplaintStatus("", "pending") }}
                                >
                                    Re-open
                                </LoginBtn>
                                :
                                <LoginBtn
                                    loading={updateComplaintLoading === 'comment'}
                                    onClick={() => {
                                        updateComplaintStatus && updateComplaintStatus('comment')
                                    }}
                                >
                                    {complaintDetail?.status == 'resolved'
                                        ? "Re-open"
                                        : <Svgs.ChatIcon fill={'#fff'} height={24} width={24} />
                                    }
                                </LoginBtn>
                            }
                        </div>
                    </div>
                }
            </Popup>
        </>
    )
}

export default ComplaintDetailPopup
